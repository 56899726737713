import { HTTP_METHODS } from '@studio/constants/axios';
import { MARATHON_API_V1 } from '@studio/constants/env-variables';
import type { Marathon } from '@studio/types';

export const root = {};

export const id = {
  [HTTP_METHODS.GET]: {
    url: `${MARATHON_API_V1}/automation/@customerId/status/@id`,
    params: {} as { id: string },
    response: {} as Marathon.Get.Status,
    method: HTTP_METHODS.GET,
  },
};
