import { HTTP_METHODS } from '@studio/constants/axios';
import { CENTRAL_API_V2 } from '@studio/constants/env-variables';

export const root = {
  [HTTP_METHODS.PUT]: {
    method: HTTP_METHODS.PUT,
    params: {} as { id: string },
    request: {} as {
      customerId: string;
    },
    url: `${CENTRAL_API_V2}/encoders/@id/move`,
  },
};

export const id = {};
