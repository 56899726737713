import * as React from 'react';
import { Box } from '@resi-media/resi-ui';

type _Props = Box.Props;

const ContentPane = React.forwardRef<HTMLDivElement, _Props>(({ children, ...props }, ref) => {
  return (
    <Box ref={ref} pb="xl" pt="m" px={{ xs: 'm', lg: 'l', xxl: 'xl' }} {...props}>
      {children}
    </Box>
  );
});

ContentPane.displayName = 'ContentPane';

export default ContentPane;
