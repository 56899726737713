import * as idEndpoints from './id';
import { root, id } from './vod';

export const vod = {
  ...root,
  id: {
    ...id,
    ...idEndpoints,
  },
};
