import * as React from 'react';
import { produce } from 'immer';
import { createContainer } from 'react-tracked';
import { useIsAuthorized } from '@studio/hooks';
import Permissions from '@studio/store/authentication/permissions';

type AdminContextState = {
  showAdminView: boolean;
};

const initialAdminState: AdminContextState = {
  showAdminView: false,
};

enum ADMIN_ACTION_TYPES {
  SET_VIEW,
}

type AdminActions = {
  payload: boolean;
  type: ADMIN_ACTION_TYPES.SET_VIEW;
};

const adminReducer = (state: AdminContextState, action: AdminActions) => {
  switch (action.type) {
    case ADMIN_ACTION_TYPES.SET_VIEW:
      state.showAdminView = action.payload;
      break;
  }
};

type AdminContextHook = AdminContextState & {
  isResiAdmin: boolean;
  setView: (v: boolean) => void;
};

const curriedReducerFunction = produce(adminReducer);

const useValues = (): readonly [AdminContextHook, () => void] => {
  const [localState, dispatch] = React.useReducer(curriedReducerFunction, initialAdminState);
  const isResiAdmin = useIsAuthorized([Permissions.RESI_ADMIN_ONLY]);

  React.useEffect(() => {
    dispatch({ type: ADMIN_ACTION_TYPES.SET_VIEW, payload: isResiAdmin });
  }, [isResiAdmin]);

  const setView = React.useCallback((toggleAdminView: boolean) => {
    dispatch({ type: ADMIN_ACTION_TYPES.SET_VIEW, payload: toggleAdminView });
  }, []);

  const state = React.useMemo(
    () => ({
      showAdminView: isResiAdmin && localState.showAdminView,
      isResiAdmin,
      setView,
    }),
    [isResiAdmin, localState, setView]
  );

  return [
    state,
    () => {
      throw new Error('use functions in the state');
    },
  ];
};

const {
  Provider: AdminProvider,
  useTrackedState: useAdmin,
  useUpdate: useAdminUpdate,
} = createContainer<AdminContextHook, () => void, { children: React.ReactNode }>(useValues);

export { AdminProvider, useAdmin, useAdminUpdate };
export type { AdminContextHook };
