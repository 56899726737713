import type { Decoders, Encoders } from '@studio/types';

export enum ActionTypes {
  FETCH_DECODERS_REQUEST = '@@monitors/FETCH_DECODERS_REQUEST',
  FETCH_DECODERS_REQUEST_CANCEL = '@@monitors/FETCH_DECODERS_REQUEST_CANCEL',
  FETCH_DECODERS_REQUEST_ERROR = '@@monitors/FETCH_DECODERS_REQUEST_ERROR',
  FETCH_DECODERS_REQUEST_SUCCESS = '@@monitors/FETCH_DECODERS_REQUEST_SUCCESS',
  FETCH_ENCODERS_REQUEST = '@@monitors/FETCH_ENCODERS_REQUEST',
  FETCH_ENCODERS_REQUEST_CANCEL = '@@monitors/FETCH_ENCODERS_REQUEST_CANCEL',
  FETCH_ENCODERS_REQUEST_ERROR = '@@monitors/FETCH_ENCODERS_REQUEST_ERROR',
  FETCH_ENCODERS_REQUEST_SUCCESS = '@@monitors/FETCH_ENCODERS_REQUEST_SUCCESS',
  FETCH_REQUEST = '@@monitors/FETCH_REQUEST',
  FETCH_REQUEST_CANCEL = '@@monitors/FETCH_REQUEST_CANCEL',
  FETCH_REQUEST_ERROR = '@@monitors/FETCH_REQUEST_ERROR',
  FETCH_REQUEST_SUCCESS = '@@monitors/FETCH_REQUEST_SUCCESS',
}

export type EncodersState = {
  data: Encoders.Get.V2.Status[];
  error: Error | null;
  isFetching: boolean;
};

export type DecodersState = {
  data: Decoders.Get.Status[];
  error: Error | null;
  isFetching: boolean;
};

export type MonitorState = {
  decoders: DecodersState;
  encoders: EncodersState;
};
