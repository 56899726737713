import { HTTP_METHODS } from '@studio/constants/axios';
import { CENTRAL_API_V3 } from '@studio/constants/env-variables';
import type { ResetPassword } from '@studio/types';

export const root = {
  [HTTP_METHODS.POST]: {
    url: `${CENTRAL_API_V3}/resetpassword/verify`,
    request: {} as ResetPassword.Post.Verify,
    response: {} as ResetPassword.Post.VerifyResponse,
    method: HTTP_METHODS.POST,
  },
};
