import { HTTP_METHODS } from '@studio/constants/axios';
import { CENTRAL_API_V3 } from '@studio/constants/env-variables';
import type { Actions } from '@studio/types';

export const loadEventOnDecoders = {
  [HTTP_METHODS.PATCH]: {
    url: `${CENTRAL_API_V3}/customers/@customerId/actions/loadeventondecoders`,
    request: {} as Actions.Patch.LoadOnDecoder,
    method: HTTP_METHODS.PATCH,
  },
};
