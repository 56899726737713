import { combineReducers } from 'redux';
import { default as hardware, initialState as initialHardwareState } from './hardware/reducer';
import * as _Hardware from './hardware/types';
import { default as monitors, initialState as initialMonitorsState } from './monitors/reducer';
import * as _Monitors from './monitors/types';
import { default as search, initialState as initialSearchState } from './search/reducer';
import * as _Search from './search/types';

const adminReducer = combineReducers({
  hardware,
  monitors,
  search,
});

export namespace Admin {
  export type State = ReturnType<typeof adminReducer>;
  export import Hardware = _Hardware;
  export import Monitors = _Monitors;
  export import Search = _Search;
}

export const initialState: Admin.State = {
  hardware: initialHardwareState,
  monitors: initialMonitorsState,
  search: initialSearchState,
};

export default adminReducer;
