import { HTTP_METHODS } from '@studio/constants/axios';
import { CENTRAL_API_V1 } from '@studio/constants/env-variables';

export const root = {
  [HTTP_METHODS.GET]: {
    url: `${CENTRAL_API_V1}/encoders/@id/teamviewer`,
    response: {} as { id: string; password: string },
    params: {} as { id: string },
    method: HTTP_METHODS.GET,
  },
};

export const id = {};
