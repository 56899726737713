import { HTTP_METHODS } from '@studio/constants/axios';
import { CENTRAL_API_V3 } from '@studio/constants/env-variables';
import type { HistoricalWebEvent } from '@studio/types';

export const root = {
  [HTTP_METHODS.GET]: {
    method: HTTP_METHODS.GET,
    query: {} as {
      id?: string;
    },
    response: [] as HistoricalWebEvent.Get.Base[],
    url: `${CENTRAL_API_V3}/customers/@customerId/historicalwebevents`,
  },
};
