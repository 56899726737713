import * as React from 'react';
import { css } from '@emotion/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Draft, Progress, isEmpty, useBreakpoint } from '@resi-media/resi-ui';
import { useForm, FormProvider } from 'react-hook-form';
import { useCues } from '@studio/contexts';
import { formatSecondsToTimeString } from '@studio/helpers/formatSecondsToTimeString';
import { usePrefix } from '@studio/hooks';
import type { Cues } from '@studio/types';
import { useFormValidation } from '../../helpers';
import { S } from '../../styles';
import { CueFields } from '../CueFields';
import { TimelineSegment } from '../TimelineSegment';

type _Props = Cues.Derived.CueWithUser & { segmentProps: TimelineSegment.Props };

const CueEdit = ({ name: cueName, position: timestamp, segmentProps, uuid, visibility }: _Props) => {
  const { copyCue, editId, patchCue, postCue } = useCues();
  const { commonT } = usePrefix('components:', 'cuesTimeline');
  const mediaQuery = useBreakpoint();

  const methods = useForm<Cues.Components.FormState>({
    mode: 'all',
    defaultValues: {
      name: cueName,
      position: formatSecondsToTimeString(timestamp),
      visibility,
    },
    resolver: yupResolver(useFormValidation()),
  });
  const { formState, getValues } = methods;
  const { errors } = formState;

  const handleSubmit = React.useCallback(() => {
    if (!isEmpty(errors)) return;

    if (copyCue) {
      postCue.callApi(getValues());
    } else {
      patchCue.callApi(uuid, getValues());
    }
  }, [copyCue, errors, getValues, patchCue, postCue, uuid]);

  const submitNode = React.useMemo(() => {
    return (
      <Draft.Button
        data-testid="cue-edit__btn--submit"
        disabled={postCue.isLoading || patchCue.isLoading}
        form="cue-edit__form"
        isFullWidth={!mediaQuery.lg}
        label={commonT('save')}
        onClick={handleSubmit}
        type="button"
        {...((patchCue.isLoading || postCue.isLoading) && {
          startNode: <Progress colorVariant="inherit" dataTestId="cue-edit__progress" sizeVariant="inherit" />,
        })}
      />
    );
  }, [commonT, handleSubmit, mediaQuery.lg, patchCue.isLoading, postCue.isLoading]);

  return (
    <FormProvider {...methods}>
      <div
        css={(theme) => css([S.segment(theme), editId === uuid && S.segmentHighlighted(theme)])}
        data-testid="cue-edit__form"
        id="cue-edit__form"
        // onSubmit={handleFormSubmit(handleSubmit)}
        // Must be a next role form until Events is refactored to not have nested forms
        // src/pages/Events/pages/ViewEditPane/view-edit-pane.tsx
        role="form"
      >
        <TimelineSegment {...segmentProps} />
        <CueFields onSubmitForm={handleSubmit} submitNode={submitNode} />
      </div>
    </FormProvider>
  );
};

CueEdit.displayName = 'CueEdit';

export default CueEdit;
