import * as React from 'react';
import { css } from '@emotion/react';
import {
  Draft,
  Inline,
  Stack,
  Text,
  useKeyDown,
  RadioGroup,
  Radio,
  useBreakpoint,
  TimeInput,
} from '@resi-media/resi-ui';
import { Controller, useFormContext } from 'react-hook-form';
import { ErrorBlock } from '@studio/components/ErrorBlock';
import { useCues, usePlayer } from '@studio/contexts';
import { formatSecondsToTimeString } from '@studio/helpers/formatSecondsToTimeString';
import { usePrefix } from '@studio/hooks';
import { Cues } from '@studio/types';
import type { Cues as CuesType } from '@studio/types';
import { MAX_CUE_TITLE } from '../../constants';

type _Props = {
  onSubmitForm: () => void;
  submitNode: React.ReactNode;
};

const CueFields = ({ onSubmitForm, submitNode }: _Props) => {
  const { canShareCues, patchCue, postCue, reset, type } = useCues();
  const { videoElement } = usePlayer();
  const mediaQuery = useBreakpoint();
  const { control } = useFormContext<CuesType.Components.FormState>();
  const { commonT, prefixNS, PrefixTrans } = usePrefix('components:', 'cuesTimeline');
  const timeInputRef = React.useRef({} as TimeInput.Ref);

  const handleKeyDown = useKeyDown(['Enter'], onSubmitForm);

  return (
    <Stack dataTestId="cue-fields__info" gap="m" pb="m" pl="l" pr="m" pt="m">
      <ErrorBlock error={patchCue.error || postCue.error} />
      <Inline alignItems="flex-end" data-testid="cue-fields__position" gap="xs">
        <Controller
          control={control}
          name="position"
          render={({
            field: { name, onBlur, onChange, value },
            fieldState: { error, isTouched },
            formState: { isSubmitted },
          }) => {
            return (
              <Draft.FormField
                fieldLabel={commonT('timestamp')}
                htmlFor={name}
                touched={isTouched || isSubmitted}
                w="auto"
              >
                <TimeInput
                  ref={timeInputRef}
                  dataTestId="cue-fields__time"
                  disableMousewheel={false}
                  hasError={Boolean(error)}
                  name="time-input-1"
                  onBlur={onBlur}
                  onChange={onChange}
                  shouldRollover
                  showMs
                  time={value}
                />
              </Draft.FormField>
            );
          }}
        />
        <Draft.Button
          customCss={{
            container: css`
              max-width: 100%;
            `,
          }}
          dataTestId="cue-fields__btn--time"
          label={prefixNS('setToNow')}
          onClick={() => {
            timeInputRef.current.setTime(formatSecondsToTimeString(videoElement?.currentTime ?? 0));
          }}
          variant="text"
        />
      </Inline>
      <Controller
        control={control}
        name="name"
        render={({
          field: { name, onBlur, onChange, value },
          fieldState: { error, isTouched },
          formState: { isSubmitted },
        }) => {
          const computedCharacterCount = MAX_CUE_TITLE - value.length;
          return (
            <Draft.FormField
              error={error?.message}
              fieldLabel={prefixNS('cueName')}
              helpText={commonT('charactersRemaining', { count: computedCharacterCount })}
              htmlFor={name}
              touched={isTouched || isSubmitted}
            >
              <Draft.TextInput<'input'>
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus
                dataTestId="cue-fields__name"
                hasError={Boolean(error)}
                id={name}
                maxLength={MAX_CUE_TITLE}
                name={name}
                onBlur={onBlur}
                onChange={onChange}
                onKeyDown={handleKeyDown}
                value={value}
              />
            </Draft.FormField>
          );
        }}
      />
      <Controller
        control={control}
        name="visibility"
        render={({ field: { name, onChange, ref, value } }) => {
          return (
            <Draft.FormField
              dataTestId={name}
              fieldLabel={prefixNS('cueVisibility')}
              hint={
                canShareCues ? (
                  <Stack dataTestId="cue-fields__visibility--tooltip" gap="m">
                    <span>
                      <PrefixTrans i18nKey="tooltip.organization">
                        <Text colorVariant="inherit" isInline variant="body6" weightVariant="bold">
                          fill
                        </Text>
                        fill
                      </PrefixTrans>
                    </span>
                    <span>
                      <PrefixTrans i18nKey="tooltip.onlyMe">
                        <Text colorVariant="inherit" isInline variant="body6" weightVariant="bold">
                          fill
                        </Text>
                        fill
                      </PrefixTrans>
                    </span>
                    {type === 'saved' && (
                      <span data-testid="visibility__tooltip--public">
                        <PrefixTrans i18nKey="tooltip.public">
                          <Text colorVariant="inherit" isInline variant="body6" weightVariant="bold">
                            fill
                          </Text>
                          fill
                        </PrefixTrans>
                      </span>
                    )}
                  </Stack>
                ) : (
                  <span data-testid="cue-fields__visibility--perms-tooltip">{prefixNS('shareCuePermission')}</span>
                )
              }
              hintPosition="right"
              htmlFor={name}
            >
              <RadioGroup
                ref={ref}
                dataTestId="cue-fields__visibility"
                name={name}
                onChange={(val) => {
                  onChange(val);
                }}
                value={value}
              >
                {canShareCues && (
                  <Radio
                    dataTestId="cue-fields__visibility--org"
                    endNode={commonT('organization')}
                    id="cue-fields__visibility--org"
                    value={Cues.CUE_VISIBILITY.SHARED}
                  />
                )}
                <Radio
                  dataTestId="cue-fields__visibility--private"
                  endNode={prefixNS('onlyMe')}
                  id="cue-fields__visibility--private"
                  value={Cues.CUE_VISIBILITY.PRIVATE}
                />
                {canShareCues && type === 'saved' && (
                  <Radio
                    dataTestId="cue-fields__visibility--public"
                    endNode={commonT('public')}
                    id="cue-fields__visibility--public"
                    value={Cues.CUE_VISIBILITY.WEB_PLAYER}
                  />
                )}
              </RadioGroup>
            </Draft.FormField>
          );
        }}
      />
      <Inline
        alignItems={mediaQuery.lg ? 'center' : 'flex-start'}
        dataTestId="cue-fields__buttons"
        flexDirection={mediaQuery.lg ? 'row' : 'column-reverse'}
        gap="m"
        justifyContent="flex-end"
      >
        <Draft.Button
          data-testid="cancel-add-cue"
          isFullWidth={!mediaQuery.lg}
          label={commonT('cancel')}
          onClick={reset}
          variant="outlined"
        />
        {submitNode}
      </Inline>
    </Stack>
  );
};

CueFields.displayName = 'CueFields';

export default CueFields;
