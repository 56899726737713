import { useSelector } from 'react-redux';
import { selectCustomerData } from '@studio/store';
import { useIsPlanType } from '../use-is-plan-type';

const useLibraryTrialAccess = (): boolean => {
  const { isSocialOnly } = useIsPlanType();
  const customerInfo = useSelector(selectCustomerData);
  const isMultiSiteOnly = !customerInfo?.vodPlanId && !customerInfo?.webPlanId;
  const isMultiOrSocialOnly = isSocialOnly || isMultiSiteOnly;

  return !isMultiOrSocialOnly && !customerInfo.vodPlanId;
};

export default useLibraryTrialAccess;
