import { DESTINATION, DESTINATION_GROUP } from '@studio/constants/content-destination';
import { PUBLISH_STATUS } from '@studio/constants/schedules';

const getInitialValues = ({ defaultTitle }: { defaultTitle: string }) => {
  const sharedDefaultValues = {
    autoArchive: false,
    autoArchivePlaylists: [],
    title: defaultTitle,
    webEncoderProfileId: '',
  };
  return {
    destinationGroup: {
      id: '',
      type: DESTINATION_GROUP,
    },
    fb: {
      ...sharedDefaultValues,
      type: DESTINATION.FACEBOOK,
    },
    rtmp: {
      ...sharedDefaultValues,
      title: undefined,
      type: DESTINATION.RTMP,
    },
    web: {
      ...sharedDefaultValues,
      description: '',
      type: DESTINATION.EMBED,
    },
    yt: {
      ...sharedDefaultValues,
      publishStatus: PUBLISH_STATUS.PUBLISHED,
      type: DESTINATION.YOUTUBE,
    },
  };
};

export default getInitialValues;
