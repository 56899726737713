import { HTTP_METHODS } from '@studio/constants/axios';
import { ONBOARDING_API_V1 } from '@studio/constants/env-variables';
import type { Onboarding } from '@studio/types';

export const root = {};

export const id = {
  [HTTP_METHODS.GET]: {
    url: `${ONBOARDING_API_V1}/salesforce/plans/@planId`,
    params: {} as { planId: string },
    response: {} as Onboarding.Get.PlanDetailsSalesforce,
    method: HTTP_METHODS.GET,
  },
};
