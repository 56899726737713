import * as React from 'react';
import { useElementSize, usePrevious } from '@resi-media/resi-ui';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';
import { useDimensions, type DimensionSetterHook, type DimensionsState } from '../../dimensions';

// TODO: Delete these and import them from resi-ui once they're exported correctly
type _ResizeHandler = (size: DimensionsState) => void;
type _RoundingFunction = (n: number) => number;

type _Props<T extends Element> = {
  /** Optional box style */
  box?: ResizeObserverBoxOptions;
  /** Child render updates upon resize are delayed until `debounceTime` milliseconds
   * _after_ the last resize event is observed.
   * @default 300
   */
  debounceTime?: number;
  /** Optional flag to disable observer by returning a false ref callback
   */
  disableObserver?: boolean;
  /** Optional flag to toggle leading debounce calls.
   * When set to true this will ensure that the component always renders immediately.
   * @default true
   */
  enableDebounceLeadingCall?: boolean;
  /** Optional dimensions provided won't trigger a state change when changed.
   * @default ['top', 'left', 'height', 'right', 'bottom']
   */
  ignoreDimensions?: (keyof DimensionsState)[] | keyof DimensionsState;
  /** Optional onResize handler */
  onResize?: _ResizeHandler;
  /** Optional ref of element to measure */
  ref?: React.RefObject<T> | T | null | undefined;
  /** Optional round method for returned dimensions */
  round?: _RoundingFunction;
};
// Delete this comment block once the types are exported correctly

const useDimensionsSetter = <T extends Element>(id: string, opts?: _Props<T>): DimensionSetterHook<T> => {
  const dimensions = useElementSize(opts ?? {});
  const previousDimensions = usePrevious(dimensions);
  const { addDimension } = useDimensions();

  React.useLayoutEffect(() => {
    if (!isEqual(omit(dimensions, 'setRef'), omit(previousDimensions, 'setRef'))) {
      addDimension(id, omit(dimensions, 'setRef'));
    }
  }, [addDimension, dimensions, id, previousDimensions]);

  return dimensions;
};

export { useDimensionsSetter };
