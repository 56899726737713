import * as React from 'react';
import { css } from '@emotion/react';
import type { Theme } from '@resi-media/resi-ui';
import { FieldMessage, Inline, Stack, Text } from '@resi-media/resi-ui';
import { usePrefix } from '@studio/hooks';

const passwordStrengthSegment = css`
  height: 6px;
  border-radius: 5px;
  flex: 1 1 25%;
`;

const passwordStrengthColor = (theme: Theme, passwordStrength: number): string => {
  switch (passwordStrength) {
    case 0:
      return theme.palette.border;
    case 1:
      return theme.palette.negative.main;
    case 2:
      return theme.palette.warning.main;
    case 3:
      return theme.palette.primary.main;
    case 4:
      return theme.palette.positive.main;
    default:
      return theme.palette.border;
  }
};

type _Props = {
  errorMessage?: string;
  helpMessage?: string;
  passwordStrength: number;
};

const PasswordStrengthBar = ({ errorMessage, helpMessage, passwordStrength }: _Props) => {
  const { prefixNS } = usePrefix('components:', 'passwordStrengthBar');

  const passwordStrengthDescriptorText = (): string => {
    switch (passwordStrength) {
      case 0:
        return prefixNS('veryWeak');
      case 1:
        return prefixNS('weak');
      case 2:
        return prefixNS('fair');
      case 3:
        return prefixNS('strong');
      case 4:
        return prefixNS('veryStrong');
      default:
        return '';
    }
  };

  return (
    <Stack data-testid="password-strength-bar" gap="xs">
      <Inline gap="xs" justifyContent="space-between">
        <div
          css={(theme) => css`
            ${passwordStrengthSegment}
            background-color: ${passwordStrengthColor(theme, passwordStrength)};
          `}
          data-testid="password-strength-color"
        />
        <div
          css={(theme) => css`
            ${passwordStrengthSegment}
            background-color: ${passwordStrength >= 2
              ? passwordStrengthColor(theme, passwordStrength)
              : theme.palette.border};
          `}
        />
        <div
          css={(theme) => css`
            ${passwordStrengthSegment}
            background-color: ${passwordStrength >= 3
              ? passwordStrengthColor(theme, passwordStrength)
              : theme.palette.border};
          `}
        />
        <div
          css={(theme) => css`
            ${passwordStrengthSegment}
            background-color: ${passwordStrength === 4
              ? passwordStrengthColor(theme, passwordStrength)
              : theme.palette.border};
          `}
        />
      </Inline>
      <Inline justifyContent="flex-end" style={{ width: '100%' }}>
        <FieldMessage
          css={css`
            flex: 1 1 100%;
          `}
          error={errorMessage}
          isVisible
          {...(!errorMessage && passwordStrength === 0 && { helpText: helpMessage })}
        />
        <Text
          css={(theme) => css`
            color: ${passwordStrength === 0
              ? theme.palette.text.disabled
              : passwordStrengthColor(theme, passwordStrength)};
          `}
          data-testid="password-strength-descriptor"
          textAlign="right"
          variant="body4"
          weightVariant="semiBold"
          whiteSpace="nowrap"
        >
          {passwordStrengthDescriptorText()}
        </Text>
      </Inline>
    </Stack>
  );
};

PasswordStrengthBar.displayName = 'PasswordStrengthBar';

export default PasswordStrengthBar;
