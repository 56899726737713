export const LAUNCHDARKLY_TOGGLES = {
  APP_LIBRARY: 'appLibrary',
  ARCHIVE_VIDEO_TO_PLAYLISTS: 'archiveVideoToPlaylists',
  MORE_UPLOAD_VIDEO_CODECS: 'moreUploadVideoCodecs',
  DECODER_REMOTE_LOAD: 'decoderRemoteLoad',
  ENCODER_AUTO_INPUT: 'encoderAutoInput',
  ENCODER_CUSTOMER_UPDATE: 'encoderCustomerUpdate',
  ENCODER_4K_INPUT: 'encoder4KInput',
  OBS_VISIBLE: 'obsVisible',
  SIMPLE_ENCODER_PRESETS: 'simpleEncoderPresets',
  STUDIO_ANALYTICS_YOUTUBE: 'studioAnalyticsYoutube',
  STUDIO_UIUX: 'studioUiux',
  SYNC_EVENT_DETAILS: 'syncEventDetails',
  UDG_SCHEDULING: 'udgScheduling',
} as const;
