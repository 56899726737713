import { HTTP_METHODS } from '@studio/constants/axios';
import { CENTRAL_API_V2 } from '@studio/constants/env-variables';
import type { Encoders } from '@studio/types';

export const root = {
  [HTTP_METHODS.GET]: {
    url: `${CENTRAL_API_V2}/encoders`,
    method: HTTP_METHODS.GET,
    response: {} as Encoders.Get.V2.Encoder[],
    query: { wide: true } as { wide?: boolean },
  },
};

export const id = {
  [HTTP_METHODS.DELETE]: {
    url: `${CENTRAL_API_V2}/encoders/@id`,
    params: {} as { id: string },
    method: HTTP_METHODS.DELETE,
  },
  [HTTP_METHODS.GET]: {
    url: `${CENTRAL_API_V2}/encoders/@id`,
    response: {} as Encoders.Get.V2.Encoder,
    params: {} as { id: string },
    method: HTTP_METHODS.GET,
  },
  [HTTP_METHODS.PATCH]: {
    url: `${CENTRAL_API_V2}/encoders/@id`,
    params: {} as { id: string },
    headers: {} as { 'X-Encoder-Version'?: string },
    request: {} as Encoders.Patch.V2.Encoder,
    method: HTTP_METHODS.PATCH,
  },
};
