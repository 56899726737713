import type { SerializedStyles, Theme } from '@emotion/react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const SearchWrapper = styled.div`
  width: 40%;
`;

export const HeaderForDesktopOnly = styled.span`
  ${({ theme }) => `
  display: none;

  ${theme.mq.lg} {
    display: inherit;
  }
  `}
`;

// shared styles among pages
export const CenterFlexFullWidth = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  overflow-x: hidden;
`;

export const truncateRow = (_: Theme, lineCount: number): SerializedStyles => css`
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: ${lineCount};
  -webkit-box-orient: vertical;
  overflow: hidden;
`;
