import { HTTP_METHODS } from '@studio/constants/axios';
import { CENTRAL_API_V3 } from '@studio/constants/env-variables';
import type { EncoderChannels } from '@studio/types';

export const root = {
  [HTTP_METHODS.GET]: {
    url: `${CENTRAL_API_V3}/customers/@customerId/eventprofiles/@id/users`,
    response: [] as EncoderChannels.Get.EncoderChannelUser[],
    params: {} as { id: string },
    method: HTTP_METHODS.GET,
  },
  [HTTP_METHODS.PATCH]: {
    url: `${CENTRAL_API_V3}/customers/@customerId/eventprofiles/@id/users`,
    request: [] as EncoderChannels.Patch.EncoderChannelUser[],
    params: {} as { id: string },
    method: HTTP_METHODS.PATCH,
  },
};
