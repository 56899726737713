import { produce } from 'immer';
import type { ActionType, PayloadAction } from 'typesafe-actions';
import { createReducer } from 'typesafe-actions';
import type { Media } from '@studio/types';
import {
  fetchLibraryItemsAsync,
  fetchMoreLibraryItemsAsync,
  fetchLibraryProcessingItemsAsync,
  setSortOrder,
  deleteSavedVideo,
} from './actions';
import { SortOptions } from './types';
import type { ActionTypes, LibraryState, SavedVideoListInfo } from './types';

type Action = ActionType<
  | typeof deleteSavedVideo
  | typeof fetchLibraryItemsAsync
  | typeof fetchLibraryProcessingItemsAsync
  | typeof fetchMoreLibraryItemsAsync
  | typeof setSortOrder
>;

export const initialState: LibraryState = {
  isFetching: false,
  isFetchingMore: false,
  isFetchingInitial: false,
  listInfo: {
    videos: null,
  },
  error: null,
  sortBy: SortOptions.MOST_RECENT,
};

const reducer = createReducer<LibraryState, Action>(initialState)
  .handleAction(
    fetchLibraryItemsAsync.request,
    produce((draft: LibraryState) => {
      draft.isFetching = true;
      draft.isFetchingInitial = !draft.listInfo.videos;
      draft.error = null;
    })
  )
  .handleAction(
    fetchLibraryItemsAsync.success,
    produce((draft: LibraryState, action: PayloadAction<ActionTypes.FETCH_REQUEST_SUCCESS, SavedVideoListInfo>) => {
      draft.isFetching = false;
      draft.isFetchingInitial = false;
      draft.listInfo = action.payload;
    })
  )
  .handleAction(
    fetchLibraryItemsAsync.failure,
    produce((draft: LibraryState, action: PayloadAction<ActionTypes.FETCH_REQUEST_ERROR, Error>) => {
      draft.isFetching = false;
      draft.isFetchingInitial = false;
      draft.error = action.payload;
    })
  )
  .handleAction(
    fetchLibraryItemsAsync.cancel,
    produce((draft: LibraryState) => {
      draft.isFetching = false;
      draft.isFetchingInitial = false;
    })
  )
  .handleAction(
    fetchMoreLibraryItemsAsync.request,
    produce((draft: LibraryState) => {
      draft.isFetchingMore = true;
      draft.error = null;
    })
  )
  .handleAction(
    fetchMoreLibraryItemsAsync.success,
    produce(
      (draft: LibraryState, action: PayloadAction<ActionTypes.FETCH_MORE_REQUEST_SUCCESS, SavedVideoListInfo>) => {
        draft.isFetchingMore = false;
        draft.listInfo = action.payload;
      }
    )
  )
  .handleAction(
    fetchMoreLibraryItemsAsync.failure,
    produce((draft: LibraryState, action: PayloadAction<ActionTypes.FETCH_MORE_REQUEST_ERROR, Error>) => {
      draft.isFetchingMore = false;
      draft.error = action.payload;
    })
  )
  .handleAction(
    fetchLibraryProcessingItemsAsync.success,
    produce(
      (
        draft: LibraryState,
        action: PayloadAction<ActionTypes.FETCH_REQUEST_PROCESSING_ONLY_SUCCESS, Media.Get.LibraryItem[]>
      ) => {
        if (draft.listInfo.videos) {
          const savedVideos = [...draft.listInfo.videos];
          // find the "processing" items in our list and update them
          action.payload.forEach((updatedItem) => {
            const index = savedVideos.findIndex((existingItem) => existingItem.mediaId === updatedItem.mediaId);
            if (index !== -1) {
              savedVideos[index] = updatedItem;
            }
          });
          draft.listInfo.videos = savedVideos;
        }
      }
    )
  )
  .handleAction(
    fetchLibraryProcessingItemsAsync.failure,
    produce((draft: LibraryState, _action: PayloadAction<ActionTypes.FETCH_REQUEST_PROCESSING_ONLY_ERROR, Error>) => {
      // create a new object, which will cause our UI component to re-render and check to see if any media is processing.
      // this will allow us to continue checking with server for processing items, even if we have network issues.
      draft.listInfo = { ...draft.listInfo };
    })
  )
  .handleAction(
    deleteSavedVideo,
    produce((draft: LibraryState, action: PayloadAction<ActionTypes.DELETE_ITEM, string>) => {
      if (action.payload && draft.listInfo.videos?.length) {
        const index = draft.listInfo.videos.findIndex((item) => item.mediaId === action.payload);
        if (index !== -1) {
          const updatedItems = [...draft.listInfo.videos];
          updatedItems.splice(index, 1);
          draft.listInfo.videos = updatedItems;
        }
      }
    })
  )
  .handleAction(
    setSortOrder,
    produce((draft: LibraryState, action: PayloadAction<ActionTypes.SORT_RESULTS, SortOptions>) => {
      draft.sortBy = action.payload;
    })
  );

export default reducer;
