import { HTTP_METHODS } from '@studio/constants/axios';
import { CENTRAL_API_V3 } from '@studio/constants/env-variables';
import type { UserInvites } from '@studio/types';

export const root = {
  [HTTP_METHODS.POST]: {
    url: `${CENTRAL_API_V3}/userinvites/@id/accept`,
    request: {} as UserInvites.Post.AcceptInvite,
    params: {} as { id: string },
    method: HTTP_METHODS.POST,
  },
};
