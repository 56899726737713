import type * as React from 'react';
import type { SerializedStyles, Theme } from '@emotion/react';
import { css } from '@emotion/react';
import type { CSSObject } from '@emotion/serialize';
import styled from '@emotion/styled';

const MOBILE_FOOTER_HEIGHT = '80px';
const DESKTOP_FOOTER_HEIGHT = '70px';

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100vh;
  min-height: 100dvh;
`;

export const KeepFooterDown = styled.div`
  ${({ theme }) => `
  flex: 1 1 100%;
  min-height: calc(100vh - ${MOBILE_FOOTER_HEIGHT});
  @supports (height: 100dvh) {
    min-height: calc(100dvh - ${MOBILE_FOOTER_HEIGHT});
  }

  ${theme.mq.lg} {
    min-height: calc(100vh - ${DESKTOP_FOOTER_HEIGHT});
    @supports (height: 100dvh) {
      min-height: calc(100dvh - ${DESKTOP_FOOTER_HEIGHT});
    }
  }`}
`;

export interface IconWrapperProps extends React.ComponentPropsWithoutRef<'span'> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  customCss?: CSSObject | SerializedStyles | string | ((...args: any[]) => CSSObject);
  variant?: 'default' | 'negative' | 'positive' | 'primary' | 'warning';
}

export const IconWrapper = styled.span<IconWrapperProps>`
  display: inline-flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  color: ${(props) => {
    if (props.variant) {
      return props.variant === 'default' ? props.theme.palette.text.primary : props.theme.palette[props.variant].main;
    }

    return 'inherit';
  }};
  ${(props) => props.customCss}
`;

export const FooterContainer = styled.div`
  ${({ theme }) => `
  display: flex;
  flex-direction: column-reverse;
  max-height: ${MOBILE_FOOTER_HEIGHT};

  ${theme.mq.lg} {
    max-height: ${DESKTOP_FOOTER_HEIGHT};
  }
  `}
`;

export const responsiveInset = (theme: Theme): SerializedStyles => css`
  ${theme.mixins.inset('m')(theme)}

  ${theme.mq.lg} {
    ${theme.mixins.inset('l')(theme)}
  }

  ${theme.mq.xxl} {
    ${theme.mixins.inset('xl')(theme)}
  }
`;
