import * as React from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { CloseAlt, Draft, usePrevious } from '@resi-media/resi-ui';
import debounce from 'lodash/debounce';

type _Props = Draft.TextInput.Props & {
  initialValue?: string;
  onCancel?: () => void;
  onSearch: (output: string) => void;
};

const SearchInput = ({ initialValue = '', onCancel, onSearch, ...rest }: _Props) => {
  const [state, setState] = React.useState(initialValue);
  const previousInitialValue = usePrevious(initialValue);

  React.useEffect(() => {
    if (initialValue !== previousInitialValue) {
      setState(initialValue);
    }
  }, [initialValue, previousInitialValue]);

  const clearInput = () => {
    onSearch('');
    setState('');
    onCancel?.();
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceSearch = React.useCallback(debounce(onSearch, 400), [onSearch]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    debounceSearch(event.currentTarget.value);
    setState(event.target.value);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Escape') {
      clearInput();
    }
  };

  return (
    <Draft.TextInput
      autoComplete="off"
      dataTestId="search-input"
      endNode={
        state && (
          <CloseAlt
            data-testid="clear-input"
            wrapperProps={{
              onClick: clearInput,
            }}
          />
        )
      }
      onChange={handleInputChange}
      onKeyDown={handleKeyDown}
      startNode={<SearchOutlined />}
      value={state}
      {...rest}
    />
  );
};

SearchInput.displayName = 'SearchInput';

export default SearchInput;
