// pixel positions that describe the top/bottom/height position of each colored section in the audio meter.
// So the red portion of the bar runs from 0px to 5px. Canvas is drawn from top to bottom. So zero is the
// top of the canvas, and the fill occurs downwards.
const TOP_Y_RED = 0;
const BTM_Y_RED = 5;
export const RED_HEIGHT = BTM_Y_RED - TOP_Y_RED;

const TOP_Y_ORANGE = 5;
const BTM_Y_ORANGE = 41;
export const ORANGE_HEIGHT = BTM_Y_ORANGE - TOP_Y_ORANGE;

const TOP_Y_YELLOW = 41;
const BTM_Y_YELLOW = 104;
export const YELLOW_HEIGHT = BTM_Y_YELLOW - TOP_Y_YELLOW;

const TOP_Y_GREEN = 104;
export const BTM_Y_GREEN = 208;
export const GREEN_HEIGHT = BTM_Y_GREEN - TOP_Y_GREEN;

// So we want the green portion of the meter to end at -14dB. A reference video was made with an audio that was at
// -14dB and the data value that was returned was 0.203125. Similarly we wanted the yellow portion of the meter to
// end at -3dB, and reference video with that volume of audio returned a data value of 0.7109375. So that explains
// where these numbers come from.
// If you strictly displayed -14dB based on it's value -- so it would fill approx 20% of the bar, then the audio
// meter would end up looking strange because you'd only really be using the very bottom part of the bar.
export const MAX_GREEN = 0.203125; // value that will result in full green bar being displayed (but no yellow)
export const MAX_YELLOW = 0.7109375; // value that will result in full yellow bar being displayed (but no orange)

const GREEN_FILL_STYLE = '#4AA728';
const YELLOW_FILL_STYLE = '#F4CD01';
const ORANGE_FILL_STYLE = '#EF8B2A';
const RED_FILL_STYLE = '#C95C4B';

export interface CanvasRect {
  height: number;
  width: number;
  x: number;
  y: number;
}

export function drawGreenBar(ctx: CanvasRenderingContext2D, width: number, value: number): void {
  const rect = calculateGreenRect(value, width);
  if (rect !== null) {
    ctx.fillStyle = GREEN_FILL_STYLE;
    ctx.fillRect(rect.x, rect.y, rect.width, rect.height);
  }
}

export function drawYellowBar(ctx: CanvasRenderingContext2D, width: number, value: number): void {
  const rect = calculateYellowRect(value, width);
  if (rect !== null) {
    ctx.fillStyle = YELLOW_FILL_STYLE;
    ctx.fillRect(rect.x, rect.y, rect.width, rect.height);
  }
}

export function drawOrangeBar(ctx: CanvasRenderingContext2D, width: number, value: number): void {
  const rect = calculateOrangeRect(value, width);
  if (rect !== null) {
    ctx.fillStyle = ORANGE_FILL_STYLE;
    ctx.fillRect(rect.x, rect.y, rect.width, rect.height);
  }
}

export function drawRedBar(ctx: CanvasRenderingContext2D, width: number, value: number): void {
  const rect = calculateRedRect(value, width);
  if (rect !== null) {
    ctx.fillStyle = RED_FILL_STYLE;
    ctx.fillRect(rect.x, rect.y, rect.width, rect.height);
  }
}

export function calculateGreenRect(value: number, width: number): CanvasRect | null {
  if (value >= MAX_GREEN) {
    // draw full green
    return {
      x: 0,
      y: TOP_Y_GREEN,
      width,
      height: GREEN_HEIGHT,
    };
  } else if (value > 0) {
    // draw partial green
    const percent = value / MAX_GREEN;
    const partialHeight = GREEN_HEIGHT * percent;
    const topPosition = BTM_Y_GREEN - partialHeight;
    return {
      x: 0,
      y: topPosition,
      width,
      height: partialHeight,
    };
  }
  return null;
}

export function calculateYellowRect(value: number, width: number): CanvasRect | null {
  if (value >= MAX_YELLOW) {
    // draw full yellow
    return {
      x: 0,
      y: TOP_Y_YELLOW,
      width,
      height: YELLOW_HEIGHT,
    };
  } else if (value > MAX_GREEN) {
    // draw partial yellow
    const yellowPortion = value - MAX_GREEN;
    const percent = yellowPortion / (MAX_YELLOW - MAX_GREEN);
    const partialHeight = YELLOW_HEIGHT * percent;
    const topPosition = BTM_Y_YELLOW - partialHeight;
    return {
      x: 0,
      y: topPosition,
      width,
      height: partialHeight,
    };
  }
  return null;
}

export function calculateOrangeRect(value: number, width: number): CanvasRect | null {
  if (value >= 1) {
    // draw full orange
    return {
      x: 0,
      y: TOP_Y_ORANGE,
      width,
      height: ORANGE_HEIGHT,
    };
  } else if (value > MAX_YELLOW) {
    // draw partial orange
    const orangePortion = value - MAX_YELLOW;
    const percent = orangePortion / (1 - MAX_YELLOW);
    const partialHeight = ORANGE_HEIGHT * percent;
    const topPosition = BTM_Y_ORANGE - partialHeight;
    return {
      x: 0,
      y: topPosition,
      width,
      height: partialHeight,
    };
  }
  return null;
}

export function calculateRedRect(value: number, width: number): CanvasRect | null {
  if (value >= 1) {
    return {
      x: 0,
      y: TOP_Y_RED,
      width,
      height: RED_HEIGHT,
    };
  }
  return null;
}
