import type { CombinedState } from 'redux';
import { createSelector } from 'reselect';
import type { Encoders } from '@studio/types';
import type { RootState } from '../root-reducer';
import type { EncodersState } from './types';

export const selectEncodersState = (state: RootState): CombinedState<EncodersState> => state.encoders;

export const select = {
  encoders: createSelector(selectEncodersState, (state): Encoders.Derived.CombinedEncoder[] => state.items),
  updatingEncoders: createSelector(
    selectEncodersState,
    (state): Map<string, Encoders.Derived.CombinedEncoder> => state.updatingEncoders
  ),
};
