/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import { useTheme } from '@emotion/react';
import type { MergeFirst } from '@resi-media/resi-ui';
import { mergeDefaultProps } from '@resi-media/resi-ui';
import { LinearGradient as VisxLinearGradient } from '@visx/gradient';

const defaultProps = {
  x1: '0%',
  x2: '100%',
  y1: '50%',
  y2: '50%',
};

type _Props = MergeFirst<
  React.SVGProps<SVGLinearGradientElement>,
  {
    /** Override of linearGradient children. */
    children?: React.ReactNode;
    /** Unique testing id. */
    dataTestId?: string;
    /** Start color of gradient. */
    from?: string;
    /** Number or percent defining the where the 'from' starting color is placed along the gradient. */
    fromOffset?: number | string;
    /** Opacity of the 'from' starting color. */
    fromOpacity?: number | string;
    /** Unique id for the gradient. Should be unique across all page elements. */
    id: string;
    /** Rotation to apply to gradient. */
    rotate?: number | string;
    /** End color of gradient. */
    to?: string;
    /** Number or percent defining the where the 'to' ending color is placed along the gradient. */
    toOffset?: number | string;
    /** Opacity of the 'to' ending color. */
    toOpacity?: number | string;
    /** Transform to apply to linearGradient, overrides rotate. */
    transform?: string;
    /** (When no x or y values are passed), will orient the gradient vertically instead of horizontally. */
    vertical?: boolean;
    /**
     * The x coordinate of the starting point along which the linear gradient is drawn.
     * @default 0%
     **/
    x1?: number | string;
    /**
     * The x coordinate of the ending point along which the linear gradient is drawn.
     * @default 100%
     **/
    x2?: number | string;
    /**
     * The y coordinate of the starting point along which the linear gradient is drawn.
     * @default 50%
     **/
    y1?: number | string;
    /**
     * The y coordinate of the ending point along which the linear gradient is drawn.
     * @default 50%
     **/
    y2?: number | string;
  }
>;

type _InternalProps = MergeFirst<_Props, typeof defaultProps>;

const LinearGradientInternal = (props: _Props, ref: React.ForwardedRef<SVGLinearGradientElement>) => {
  const propsInternal: _InternalProps = mergeDefaultProps(props, defaultProps);
  const { dataTestId, ...rest } = propsInternal;
  const theme = useTheme();

  return (
    <VisxLinearGradient data-testid={dataTestId} {...rest} ref={ref}>
      <stop offset="0.5" stopColor={theme.palette.divider} stopOpacity="1">
        <animate
          attributeName="offset"
          dur="2s"
          keyTimes="0; 0.25; 1"
          repeatCount="indefinite"
          values="-2; -2; 1"
        ></animate>
      </stop>
      <stop offset="1.5" stopColor={theme.palette.background.table} stopOpacity="1">
        <animate
          attributeName="offset"
          dur="2s"
          keyTimes="0; 0.25; 1"
          repeatCount="indefinite"
          values="-1; -1; 2"
        ></animate>
      </stop>
      <stop offset="2.5" stopColor={theme.palette.divider} stopOpacity="1">
        <animate
          attributeName="offset"
          dur="2s"
          keyTimes="0; 0.25; 1"
          repeatCount="indefinite"
          values="0; 0; 3"
        ></animate>
      </stop>
    </VisxLinearGradient>
  );
};

LinearGradientInternal.displayName = 'LinearGradient';

export const LinearGradient = React.forwardRef(LinearGradientInternal);

export default LinearGradient;
