import * as React from 'react';
import { useTheme } from '@emotion/react';
import { Bookmark, Draft, Inline, Stack, Text, useBreakpoint } from '@resi-media/resi-ui';
import { match } from 'ts-pattern';
import { useCues, usePlayer } from '@studio/contexts';
import { parseTimeIntoSeconds } from '@studio/helpers';
import { usePrefix } from '@studio/hooks';
import { Cues } from '@studio/types';
import { CueCreate, CueEdit, CueView } from './components';

const CuesTimeline = () => {
  const { addId, copyCue, cues, editId, setAddId, setHoverId } = useCues();
  const { prefixNS } = usePrefix('components:', 'cuesTimeline');
  const { currentTime } = usePlayer();
  const mediaQuery = useBreakpoint();
  const firstEntry = cues?.[0];
  const cuesToRender = cues?.concat(copyCue ?? []).sort((a, b) => a.position.localeCompare(b.position));
  const cueLength = cuesToRender?.length ?? 0;
  const theme = useTheme();

  const addNewCue = React.useCallback(() => {
    setAddId('newEntry');
  }, [setAddId]);

  return (
    <Stack dataTestId="cues-timeline" gap="m">
      <Inline
        alignItems={mediaQuery.lg ? 'center' : 'flex-start'}
        dataTestId="cues-timeline__header"
        flexDirection={mediaQuery.lg ? 'row' : 'column'}
        gap="m"
        justifyContent="space-between"
      >
        <Inline alignItems="center" gap="m">
          <div data-testid="cues-timeline__icon" style={{ color: theme.palette.text.title }}>
            <Bookmark />
          </div>
          <Text colorVariant="heading" data-testid="cues-timeline__title" variant="body1" weightVariant="semiBold">
            {prefixNS('cueTimeline')}
          </Text>
        </Inline>
        <Draft.Button
          dataTestId="cues-timeline__btn"
          disabled={Boolean(addId)}
          isFullWidth={!mediaQuery.lg}
          label={prefixNS('addCue')}
          onClick={addNewCue}
          sizeVariant="m"
          variant="contained"
        />
      </Inline>
      <div>
        {addId && <CueCreate />}
        <div
          data-testid="cues-timeline__entry--start"
          onMouseEnter={() => setHoverId('start-of-video')}
          onMouseLeave={() => setHoverId('')}
        >
          <CueView
            name={prefixNS('startOfVideo')}
            position="00:00:00.000"
            segmentProps={{
              isHighlighted: true,
              showBottomLine: cueLength > 0,
              showBottomLineHighlighted: firstEntry && parseTimeIntoSeconds(firstEntry.position) <= currentTime,
              showTopLine: false,
            }}
            user={prefixNS('automatic')}
            userId="automatic-userid"
            uuid="start-of-video"
            visibility={Cues.CUE_VISIBILITY.SHARED}
          />
        </div>
        {cuesToRender?.map((entry, i) => {
          const Component = editId === entry.uuid || copyCue?.uuid === entry.uuid ? CueEdit : CueView;
          const segmentInPast = parseTimeIntoSeconds(entry.position) <= currentTime;
          const nextEntry = cuesToRender[i + 1] ? cuesToRender[i + 1] : undefined;

          const segmentProps = {
            isHighlighted: segmentInPast,
            showBottomLine: cueLength > 1 && i !== cueLength - 1,
            showBottomLineHighlighted:
              i !== cueLength - 1 &&
              segmentInPast &&
              nextEntry &&
              parseTimeIntoSeconds(nextEntry.position) <= currentTime,
            showTopLine: true,
          };

          const suffix = match(entry.uuid)
            .with(editId, () => 'edit')
            .with(copyCue?.uuid ?? '', () => 'copy')
            .otherwise(() => 'view');

          return (
            <div
              key={`${entry.uuid}-${suffix}`}
              data-testid={`cues-timeline__entry--${suffix}`}
              onMouseEnter={() => setHoverId(entry.uuid)}
              onMouseLeave={() => setHoverId('')}
            >
              <Component {...entry} segmentProps={segmentProps} />
            </div>
          );
        })}
      </div>
    </Stack>
  );
};

CuesTimeline.displayName = 'CuesTimeline';

export default CuesTimeline;
