import type { UnionOf } from 'ts-toolbelt/out/Object/UnionOf';

export const PROFILE_TYPES = {
  SOCIAL: 'social',
  PUBLIC: 'public',
  RETURN_FEED: 'return_feed',
} as const;

export const OFFLINE_OPTIONS = {
  REPLAY_EVENT: 'replay_event',
  UPLOADED_IMAGE: 'uploaded_image',
  UPLOADED_PLAYLIST: 'uploaded_playlist',
  UPLOADED_VIDEO: 'uploaded_video',
} as const;

export const MAX_DESCRIPTION_LENGTH = 1000;
export const MIN_STREAM_DELAY = 90; //90 seconds .. 1:30 minutes
export const MAX_STREAM_DELAY = 600; //600 seconds .. 10:00 minutes

export type PlanTypeState = {
  isEnterprise: boolean;
  isEnterpriseUBP: boolean;
  isPro: boolean;
  isProLegacyEmbedOnly: boolean;
  isProfessionalUBP: boolean;
  isSocialOnly: boolean;
  isSocialOnlyUBP: boolean;
  isStarterLegacy: boolean;
  isStarterLite: boolean;
  isStarterLiteUBP: boolean;
  isUBP: boolean;
  isVideoReview: boolean;
  webPlanId: string;
};

export type ProfileTypes = UnionOf<typeof PROFILE_TYPES>;

export interface WebChannelPayload {
  branded?: boolean;
  delaySeconds: number;
  deleteAfter: number;
  description: string;
  detectQrCodes: boolean;
  loadBalPrefix?: string;
  name: string;
  offlineImageEnabled?: boolean;
  offlineImageUrl?: string | null;
  offlinePlaylistEnabled: boolean;
  offlinePlaylistId: string | null;
  offlineVideoEnabled: boolean;
  offlineVideoId: string | null;
  persistentUrls: {
    dashUrlEnabled: boolean;
    hlsUrlEnabled: boolean;
  };
  regionId: string;
  subtitlesEnabled: boolean;
  type?: string;
}
