import type { SerializedStyles, Theme } from '@emotion/react';
import { css } from '@emotion/react';
import type { BarChart } from './bar-chart';

const tooltipArrowScale = 0.8;
const tooltipArrowSize = 16;
const tooltipHypot = Math.hypot(tooltipArrowSize, tooltipArrowSize);
const tooltipTransformOrientations = (value: number) => ({
  bottom: `translate(calc(-50% + ${value ? 0 : tooltipArrowSize}px + ${value}px), calc(-100% + ${
    tooltipArrowSize * 0.625
  }px))`,
  left: `translate(calc(${tooltipArrowSize}px), calc(-50% + ${tooltipArrowSize * 0.625}px))`,
});

export const S = {
  tooltipArrowSize,
  tooltipArrowScale,
  tooltipHypot,
  arrow: (theme: Theme): SerializedStyles => css`
    visibility: hidden;
    position: absolute;
    background: inherit;
    box-sizing: border-box;
    width: ${tooltipArrowSize}px;
    height: ${tooltipArrowSize}px;

    &::before {
      transform: scale(${tooltipArrowScale}, 1) rotate(45deg);
      background-color: ${theme.palette.background.tooltip};
      position: absolute;
      content: '';
      visibility: visible;
      height: 100%;
      width: 100%;
      margin: auto;
      border-radius: 0.5px;
    }
  `,
  container: (theme: Theme, props: BarChart.InternalProps): SerializedStyles => css`
    position: relative;
    height: auto;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    gap: ${props.legendGap};

    ${theme.mq.lg} {
      height: ${props.height}px;
    }
  `,
  tooltip: (
    theme: Theme,
    leftPlusTooltip = 0,
    isWrapped = false,
    orientation: 'bottom' | 'left' = 'bottom'
  ): SerializedStyles => css`
    position: absolute;
    visibility: visible;
    left: 0;
    transform: ${tooltipTransformOrientations(leftPlusTooltip)[orientation]};
    border-radius: ${theme.shape.borderRadius.s};
    background-color: ${theme.palette.background.tooltip};
    // Cannot have box-shadow currently with arrow setup in dark mode
    box-shadow: none;
    color: ${theme.palette.background.default};
    padding: ${theme.spacing.m};
    z-index: 1000;
    width: ${isWrapped ? '256px' : '160px'};

    ${theme.mq.lg} {
      width: ${isWrapped ? '320px' : '160px'};
    }
  `,
  tooltipLabel: (): SerializedStyles => css`
    white-space: normal;
    word-break: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  `,
};
