import { HTTP_METHODS } from '@studio/constants/axios';
import { CENTRAL_API_V3 } from '@studio/constants/env-variables';
import type { SocialMediaAccount } from '@studio/store/social-media';

export const id = {
  [HTTP_METHODS.GET]: {
    method: HTTP_METHODS.GET,
    url: `${CENTRAL_API_V3}/customers/@customerId/socialmedia/@id`,
    params: {} as { id: string },
    response: {} as SocialMediaAccount,
  },
};
