import { produce } from 'immer';
import type { ActionType } from 'typesafe-actions';
import { createReducer } from 'typesafe-actions';
import { MEDIA_VIEW, LIBRARY_VIEW_KEY } from '@studio/constants/library';
import { SORT_OPTIONS } from '@studio/constants/library-search';
import type { LibrarySearch } from '@studio/types';
import { fetch, set } from './actions';
import type { LibrarySearchState } from './types';

type Action = ActionType<typeof fetch | typeof set>;

const DEFAULT_PAGE_SIZE = 50;

export const initialSearchRequestState: LibrarySearch.Get.Params = {
  size: DEFAULT_PAGE_SIZE,
  sort: SORT_OPTIONS.MOST_RECENT,
} as const;

const initialFacetCounts: LibrarySearch.Derived.FacetCounts = {
  durationRangeCounts: {
    UNDER_FIVE_MINUTES: 0,
    FIVE_TO_THIRTY_MINUTES: 0,
    OVER_THIRTY_MINUTES: 0,
  },
  thumbnailCounts: {
    false: 0,
    true: 0,
  },
  mediaSourceCounts: {
    LIVE: 0,
    UPLOADED: 0,
  },
  tags: [],
};

export const getInitialState = (): LibrarySearchState => {
  return {
    activeThumbnailFilter: '',
    error: null,
    isFetching: false,
    isFetchingMore: false,
    nextUrl: null,
    request: initialSearchRequestState,
    results: { response: null, facetCounts: initialFacetCounts },
    selectedTags: [],
    view: localStorage.getItem(LIBRARY_VIEW_KEY) === MEDIA_VIEW.LIST ? MEDIA_VIEW.LIST : MEDIA_VIEW.GALLERY,
  };
};

const reducer = createReducer<LibrarySearchState, Action>(getInitialState())
  .handleAction(
    fetch.media.request,
    produce((draft: LibrarySearchState, action: ActionType<typeof fetch.media.request>) => {
      draft.request = action.payload;
      draft.isFetching = true;
      draft.error = null;
    })
  )
  .handleAction(
    fetch.media.success,
    produce((draft: LibrarySearchState, action: ActionType<typeof fetch.media.success>) => {
      draft.isFetching = false;
      draft.results = action.payload;
    })
  )
  .handleAction(
    fetch.media.failure,
    produce((draft: LibrarySearchState, action: ActionType<typeof fetch.media.failure>) => {
      draft.isFetching = false;
      draft.error = action.payload;
    })
  )
  .handleAction(
    fetch.media.cancel,
    produce((draft: LibrarySearchState) => {
      draft.isFetching = false;
    })
  )
  .handleAction(
    fetch.nextPage.request,
    produce((draft: LibrarySearchState) => {
      draft.isFetchingMore = true;
      draft.error = null;
    })
  )
  .handleAction(
    fetch.nextPage.success,
    produce((draft: LibrarySearchState, action: ActionType<typeof fetch.nextPage.success>) => {
      draft.isFetchingMore = false;
      draft.results = action.payload;
    })
  )
  .handleAction(
    fetch.nextPage.failure,
    produce((draft: LibrarySearchState, action: ActionType<typeof fetch.nextPage.failure>) => {
      draft.isFetchingMore = false;
      draft.error = action.payload;
    })
  )
  .handleAction(
    fetch.nextPage.cancel,
    produce((draft: LibrarySearchState) => {
      draft.isFetchingMore = false;
    })
  )
  .handleAction(
    set.view,
    produce((draft: LibrarySearchState, action: ActionType<typeof set.view>) => {
      draft.view = action.payload;
      localStorage.setItem(LIBRARY_VIEW_KEY, action.payload);
    })
  )
  .handleAction(
    set.thumbnailFilter,
    produce((draft: LibrarySearchState, action: ActionType<typeof set.thumbnailFilter>) => {
      draft.activeThumbnailFilter = action.payload;
    })
  )
  .handleAction(
    set.resetSearchRequest,
    produce((draft: LibrarySearchState) => {
      draft.activeThumbnailFilter = '';
      draft.request = initialSearchRequestState;
    })
  )
  .handleAction(
    set.selectedTags,
    produce((draft: LibrarySearchState, action: ActionType<typeof set.selectedTags>) => {
      draft.selectedTags = action.payload;
    })
  )
  .handleAction(
    fetch.processingMedia.success,
    produce((draft: LibrarySearchState, action: ActionType<typeof fetch.processingMedia.success>) => {
      if (draft.results.response?.mediaItems) {
        const savedVideos = [...draft.results.response.mediaItems];
        // find the "processing" items in our list and update them
        action.payload.forEach((updatedItem) => {
          const index = savedVideos.findIndex((existingItem) => existingItem.mediaId === updatedItem.mediaId);
          if (index !== -1) {
            savedVideos[index] = updatedItem;
          }
        });
        draft.results.response.mediaItems = savedVideos;
      }
    })
  )
  .handleAction(
    fetch.processingMedia.failure,
    produce((draft: LibrarySearchState, _action: ActionType<typeof fetch.processingMedia.failure>) => {
      // create a new object, which will cause our UI component to re-render and check to see if any media is processing.
      // this will allow us to continue checking with server for processing items, even if we have network issues.
      if (draft.results.response?.mediaItems) {
        draft.results.response.mediaItems = { ...draft.results.response.mediaItems };
      }
    })
  );

export default reducer;
