import { HTTP_METHODS } from '@studio/constants/axios';
import { STREAM_USAGE_API_V1 } from '@studio/constants/env-variables';

export const root = {};

export const id = {
  [HTTP_METHODS.GET]: {
    method: HTTP_METHODS.GET,
    query: {} as {
      endDate: string;
      startDate: string;
    },
    response: {} as { minutes: number },
    url: `${STREAM_USAGE_API_V1}/customers/@customerId`,
  },
};
