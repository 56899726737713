import { createAction, createAsyncAction } from 'typesafe-actions';
import type { FetchOptions } from '@studio/types';
import type { SocialMediaAccount, SocialMediaAccountStatusResponse, FBAuthInfo } from './types';
import { ActionTypes } from './types';

export const fetchSocialMediaAccountsAsync = createAsyncAction(
  ActionTypes.FETCH_REQUEST,
  ActionTypes.FETCH_REQUEST_SUCCESS,
  ActionTypes.FETCH_REQUEST_ERROR,
  ActionTypes.FETCH_REQUEST_CANCEL
)<(FetchOptions & { omitStatusSync?: boolean }) | undefined, SocialMediaAccount[], Error>();

export const fetchStatusForSocialMediaAccountsAsync = createAsyncAction(
  ActionTypes.FETCH_STATUS_REQUEST,
  ActionTypes.FETCH_STATUS_SUCCESS,
  ActionTypes.FETCH_STATUS_ERROR
)<undefined, SocialMediaAccountStatusResponse[], Error>();

export const setFetchRequestTimestamp = createAction(ActionTypes.FETCH_REQUEST_TIMESTAMP)<number>();
export const setFilter = createAction(ActionTypes.FILTER_ITEMS)<string>();
export const setFbAuthSuccess = createAction(ActionTypes.FB_AUTH_SUCCESS)<FBAuthInfo>();
export const setFbAuthError = createAction(ActionTypes.FB_AUTH_ERROR)<Error>();
export const resetFbAuth = createAction(ActionTypes.FB_AUTH_RESET)<undefined>();
