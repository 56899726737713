import { HTTP_METHODS } from '@studio/constants/axios';
import { CENTRAL_API_V1 } from '@studio/constants/env-variables';
import type { Hardware } from '@studio/types';

export const root = {
  [HTTP_METHODS.GET]: {
    url: `${CENTRAL_API_V1}/hardwareunits/count`,
    query: {} as {
      buildDateEnd?: string;
      buildDateStart?: string;
    },
    response: [] as Hardware.Get.HardwareACCReport[],
    method: HTTP_METHODS.GET,
  },
};
