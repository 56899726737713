export enum BackOffType {
  EXPONENTIAL = 'EXPONENTIAL',
  STATIC = 'STATIC',
}

export const HTTP_METHODS = {
  GET: 'GET',
  PUT: 'PUT',
  PATCH: 'PATCH',
  POST: 'POST',
  DELETE: 'DELETE',
} as const;

export const CONFIG_DEFAULTS = {
  BACKOFF_TYPE: BackOffType.EXPONENTIAL,
  INITIAL_RETRY_DELAY: 1000,
  CURRENT_RETRY_ATTEMPT: 0,
  IS_RESUMABLE: true,
  IGNORE_NETWORK_ERRORS: false,
} as const;
