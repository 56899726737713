import * as React from 'react';
import { Inline, Stack, Text } from '@resi-media/resi-ui';
import { S } from './styles';

type _Props = {
  contentHeading: React.ReactNode;
  ctaButton?: React.ReactNode;
  header?: React.ReactNode;
  paragraph1: React.ReactNode;
  paragraph2: React.ReactNode;
  svg: React.ReactNode;
};

const RerouteInfo = ({ contentHeading, ctaButton, header, paragraph1, paragraph2, svg }: _Props) => {
  return (
    <div data-testid="reroute-container">
      {!!header && <div data-testid="header-container">{header}</div>}
      <Inline alignItems="center" css={(theme) => S.rerouteContainer(theme, !!header)} justifyContent="center">
        <div css={S.svgContainer} data-testid="svg-container">
          {svg}
        </div>
        <Stack css={S.infoContainer} gap="m">
          <Text colorVariant="heading" data-testid="content-heading" variant="h4">
            {contentHeading}
          </Text>
          <div>
            <Text css={S.paragraph} data-testid="paragraph1" variant="body2">
              {paragraph1}
            </Text>
            <Text css={S.paragraph} data-testid="paragraph2" variant="body2">
              {paragraph2}
            </Text>
          </div>
          {!!ctaButton && <div data-testid="button-container">{ctaButton}</div>}
        </Stack>
      </Inline>
    </div>
  );
};

RerouteInfo.displayName = 'RerouteInfo';

export default RerouteInfo;
