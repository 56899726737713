import { HTTP_METHODS } from '@studio/constants/axios';
import { CENTRAL_API_V2 } from '@studio/constants/env-variables';
import type { Encoders } from '@studio/types';

export const root = {
  [HTTP_METHODS.GET]: {
    url: `${CENTRAL_API_V2}/encoders/@id/network`,
    response: [] as Encoders.Get.V2.Network[],
    params: {} as { id: string },
    method: HTTP_METHODS.GET,
  },
  [HTTP_METHODS.PATCH]: {
    url: `${CENTRAL_API_V2}/encoders/@id/network`,
    request: {} as Encoders.Patch.V2.Network,
    params: {} as { id: string },
    method: HTTP_METHODS.PATCH,
  },
};

export const id = {};
