import * as React from 'react';
import { Draft, Stack, Text } from '@resi-media/resi-ui';
import { P, match } from 'ts-pattern';
import { isAxiosError } from '@studio/api/api-client/is-axios-error';
import { extractErrors } from '@studio/helpers/error-handling';
import { usePrefix } from '@studio/hooks';

type _Props = Draft.AlertBanner.Props & {
  customErrorMessage?: React.ReactNode;
  error: unknown;
  hideDetailedMessage?: boolean;
  scrollIntoView?: boolean;
};

const ErrorBlock = ({ customErrorMessage, error, hideDetailedMessage = false, scrollIntoView, ...rest }: _Props) => {
  const { commonT, errorT } = usePrefix('');
  const errors = React.useMemo(() => (error ? extractErrors(error) : []), [error]);
  const errorCode = errors[0]?.code;
  const errorStatus = isAxiosError(error) ? error.response?.status : undefined;

  const errorMessage = errors[0]?.message;
  const errorPrefixString = match({ errorCode, errorStatus })
    .with({ errorCode: P.when((ec) => Boolean(ec)) }, ({ errorCode: code }) => code)
    .with({ errorStatus: P.when((status) => status && status === 400) }, () => 'badRequest')
    .with({ errorStatus: P.when((status) => status && status === 401) }, () => 'unauthorized')
    .with({ errorStatus: P.when((status) => status && status === 403) }, () => 'forbidden')
    .with({ errorStatus: P.when((status) => status && status === 404) }, () => 'notFound')
    .with({ errorStatus: P.when((status) => status && status === 409) }, () => 'conflict')
    .with({ errorStatus: P.when((status) => status && status === 412) }, () => 'invalidHeader')
    .with({ errorStatus: P.when((status) => status && status === 429) }, () => 'tooManyRequests')
    .with({ errorStatus: P.when((status) => status && status >= 400 && status < 500) }, () => 'default400')
    .with({ errorStatus: P.when((status) => status && status === 500) }, () => 'internalServerError')
    .with({ errorStatus: P.when((status) => status && status === 503) }, () => 'serviceUnavailable')
    .with({ errorStatus: P.when((status) => status && status >= 500) }, () => 'defaultServerError')
    .otherwise(() => '');
  const mainErrorMessage = customErrorMessage ? customErrorMessage : errorT(errorPrefixString);

  const errorDetailNode = React.useMemo(() => {
    return hideDetailedMessage || !errorMessage ? null : (
      <Stack dataTestId="error-detail-container">
        {errorStatus && (
          <Text dataTestId="error-status-code">
            {commonT('errors.statusCode')}: {errorStatus}
          </Text>
        )}
        <Text dataTestId="error-detail-message">{errorMessage}</Text>
      </Stack>
    );
  }, [hideDetailedMessage, errorMessage, errorStatus, commonT]);

  return error ? (
    <Draft.AlertBanner
      dataTestId="error-block-alert-banner"
      messageDetailNode={errorDetailNode}
      scrollIntoView={scrollIntoView}
      toggleCloseNode={commonT('viewLess')}
      toggleOpenNode={commonT('viewMore')}
      {...rest}
    >
      {mainErrorMessage}
    </Draft.AlertBanner>
  ) : null;
};

ErrorBlock.displayName = 'ErrorBlock';

export default ErrorBlock;
