import type { AxiosError, AxiosResponse } from 'axios';
import axios from 'axios';
import type { CentralError, Error } from '@studio/types';

export function extractCentralErrors(error: CentralError): Error[] {
  const errors: Error[] = [];
  if (error.code || error.message) {
    errors.push({ code: error.code ?? '', message: error.message });
  }
  if (error.errors) {
    return [...error.errors, ...errors];
  }
  return errors;
}

type GenericErrorWithErrors = Error & {
  errors: Error[];
};

export function extractErrors(error: unknown): Error[] {
  function isAxiosError(error: unknown): error is AxiosError {
    return Boolean(error) && axios.isAxiosError(error);
  }
  function isAxiosErrorWithResponse(error: unknown): error is AxiosError {
    return isAxiosError(error) && error.response !== undefined;
  }
  function isAxiosErrorWithGenericErrors(error: unknown): error is AxiosError<GenericErrorWithErrors> {
    return Boolean(
      isAxiosError(error) &&
        error.response?.data &&
        typeof error.response.data === 'object' &&
        'errors' in error.response.data &&
        Array.isArray(error.response.data.errors)
    );
  }
  function isCentralResponse(response: AxiosResponse): response is AxiosResponse<CentralError> {
    return Boolean(response.data?.errors !== undefined);
  }
  function isCentralAxiosError(error: unknown): error is AxiosError<CentralError> {
    return Boolean(isAxiosErrorWithResponse(error) && error.response && isCentralResponse(error.response));
  }
  if (!isAxiosError(error) || !error.response) return [];

  if (isAxiosErrorWithGenericErrors(error)) {
    return error.response.data.errors.map((err: Error) => ({
      code: err.code,
      message: err.message,
    }));
  }

  if (isCentralAxiosError(error)) {
    return extractCentralErrors(error.response.data);
  }

  return [];
}
