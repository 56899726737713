import common_en from './common.json';
import components_en from './components.json';
import countries_en from './countries.json';
import errors_en from './errors.json';
import links_en from './links.json';
import navigation_en from './navigation.json';
import pages_en from './pages.json';
import plan_info_en from './plan-info.json';
import regions_en from './regions.json';

export default {
  common: common_en,
  components: components_en,
  countries: countries_en,
  errors: errors_en,
  links: links_en,
  navigation: navigation_en,
  pages: pages_en,
  plan_info: plan_info_en,
  regions: regions_en,
};
