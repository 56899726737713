import * as React from 'react';
import { Draft } from '@resi-media/resi-ui';
import { enUS } from 'date-fns/locale';

const useTimeMode = () => {
  const { setLocale } = Draft.useLocale<Date>();

  const setTimeMode = React.useCallback(
    (use24hTime: boolean) => {
      if (!use24hTime) {
        setLocale(enUS);
        return;
      }
      const formatLong24 = {
        full: 'HH:mm:ss zzzz',
        long: 'HH:mm:ss z',
        medium: 'HH:mm:ss',
        short: 'HH:mm',
      } as const;
      type FormatLong24Type = keyof typeof formatLong24;
      if (enUS.formatLong) {
        setLocale({
          ...enUS,
          formatLong: {
            ...enUS.formatLong,
            time: (options: { width?: FormatLong24Type } = {}) => {
              const width = options.width ? (String(options.width) as FormatLong24Type) : 'full';
              return formatLong24[width];
            },
          },
        });
      }
    },
    [setLocale]
  );

  return { setTimeMode };
};

export default useTimeMode;
