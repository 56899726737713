import type { SystemEvents } from '@studio/types';

export type SystemEventsState = {
  subjectEvents: {
    data: Map<string, SystemEvents.Get.Event[]>;
    error: Error | null;
    hasFetched: boolean;
    isFetching: boolean;
  };
};

export type SystemEventsFetchOptions = {
  subjectId: string;
};

export enum ActionTypes {
  FETCH_SUBJECT_EVENTS_REQUEST = '@@systemevents/FETCH_SUBJECT_EVENTS_REQUEST',
  FETCH_SUBJECT_EVENTS_REQUEST_CANCEL = '@@systemevents/FETCH_SUBJECT_EVENTS_REQUEST_CANCEL',
  FETCH_SUBJECT_EVENTS_REQUEST_ERROR = '@@systemevents/FETCH_SUBJECT_EVENTS_REQUEST_ERROR',
  FETCH_SUBJECT_EVENTS_REQUEST_SUCCESS = '@@systemevents/FETCH_SUBJECT_EVENTS_REQUEST_SUCCESS',
}
