import i18next from 'i18next';

const createCueOptions = () => ({
  start: {
    label: i18next.t('common:startOfVideo'),
    value: -1,
    position: '',
    uuid: 'start-of-video',
  },
  end: {
    label: i18next.t('common:endOfVideo'),
    value: -1,
    position: '',
    uuid: 'end-of-video',
  },
});

export default createCueOptions;
