import { createAction, createAsyncAction } from 'typesafe-actions';
import type { Customer } from '@studio/types';
import type { FirstTimeSetupStatus, CustomerNames } from './types';
import { CustomerActionTypes } from './types';

export const fetch = {
  customer: createAsyncAction(
    CustomerActionTypes.FETCH_REQUEST,
    CustomerActionTypes.FETCH_REQUEST_SUCCESS,
    CustomerActionTypes.FETCH_REQUEST_ERROR,
    CustomerActionTypes.FETCH_REQUEST_CANCEL
  )<Customer.Get.FetchOptions, Customer.Get.Customer, Error, undefined>(),
  customerNames: createAsyncAction(
    CustomerActionTypes.FETCH_NAMES,
    CustomerActionTypes.FETCH_NAMES_SUCCESS,
    CustomerActionTypes.FETCH_NAMES_ERROR,
    CustomerActionTypes.FETCH_NAMES_CANCEL
  )<undefined, CustomerNames[], Error, undefined>(),
  customerRefresh: createAsyncAction(
    CustomerActionTypes.FETCH_REFRESH,
    CustomerActionTypes.FETCH_REFRESH_SUCCESS,
    CustomerActionTypes.FETCH_REFRESH_ERROR,
    CustomerActionTypes.FETCH_REFRESH_CANCEL
  )<
    | {
        uuid?: string;
      }
    | undefined,
    Customer.Get.Customer,
    unknown,
    undefined
  >(),
  fts: createAsyncAction(
    CustomerActionTypes.FETCH_REQUEST_FTS,
    CustomerActionTypes.FETCH_REQUEST_SUCCESS_FTS,
    CustomerActionTypes.FETCH_REQUEST_ERROR_FTS,
    CustomerActionTypes.FETCH_REQUEST_CANCEL_FTS
  )<undefined, FirstTimeSetupStatus, Error, undefined>(),
  toggles: createAsyncAction(
    CustomerActionTypes.FETCH_REQUEST_TOGGLES,
    CustomerActionTypes.FETCH_REQUEST_TOGGLES_SUCCESS,
    CustomerActionTypes.FETCH_REQUEST_TOGGLES_ERROR,
    CustomerActionTypes.FETCH_REQUEST_TOGGLES_CANCEL
  )<undefined, Customer.Get.Toggle[], Error, undefined>(),
  togglesRegistered: createAsyncAction(
    CustomerActionTypes.FETCH_REQUEST_TOGGLES_REGISTERED,
    CustomerActionTypes.FETCH_REQUEST_TOGGLES_REGISTERED_SUCCESS,
    CustomerActionTypes.FETCH_REQUEST_TOGGLES_REGISTERED_ERROR,
    CustomerActionTypes.FETCH_REQUEST_TOGGLES_REGISTERED_CANCEL
  )<undefined, Customer.Get.TogglesRegistered, Error, undefined>(),
};

export const set = {
  timestamp: createAction(CustomerActionTypes.FETCH_REQUEST_TIMESTAMP)<number>(),
  trialCancelled: createAction(CustomerActionTypes.TRIAL_CANCELLED)<undefined>(),
};
