import * as React from 'react';
import { useTheme } from '@emotion/react';
import { Inline, Stack, Text } from '@resi-media/resi-ui';
import { usePrefix } from '@studio/hooks';
import { BTM_Y_GREEN, drawGreenBar, drawYellowBar, drawOrangeBar, drawRedBar } from './helpers';

const BACKGROUND_FILL_STYLE = '#BFBFBF';
const METER_WIDTH = 8;

type _Props = {
  channelNumber: number;
  showLabels: boolean;
  value: number;
};

const AudioMeter = ({ channelNumber, showLabels, value }: _Props) => {
  const { prefixNS } = usePrefix('components:', 'audioMeter');
  const theme = useTheme();
  const canvasRef = React.createRef<HTMLCanvasElement>();

  React.useEffect(() => {
    if (canvasRef.current) {
      const canvasCtx = canvasRef.current.getContext('2d');
      if (canvasCtx !== null) {
        canvasCtx.fillStyle = BACKGROUND_FILL_STYLE;
        canvasCtx.lineWidth = 1;
        canvasCtx.fillRect(0, 0, canvasRef.current.width, canvasRef.current.height);

        drawRedBar(canvasCtx, canvasRef.current.width, value);
        drawOrangeBar(canvasCtx, canvasRef.current.width, value);
        drawYellowBar(canvasCtx, canvasRef.current.width, value);
        drawGreenBar(canvasCtx, canvasRef.current.width, value);
      }
    }
  }, [canvasRef, value]);

  return (
    <Inline>
      {showLabels && (
        <Stack dataTestId="audio-meter-labels" gap="unset" textAlign="right">
          <Text
            colorVariant="secondary"
            style={{ marginTop: theme.typography.pxToRem(-3) }}
            variant="body4"
            weightVariant="semiBold"
          >
            0
          </Text>
          <Text
            colorVariant="secondary"
            style={{ marginTop: theme.typography.pxToRem(20) }}
            variant="body4"
            weightVariant="semiBold"
          >
            -3
          </Text>
          <Text
            colorVariant="secondary"
            style={{ marginTop: theme.typography.pxToRem(47) }}
            variant="body4"
            weightVariant="semiBold"
          >
            -14
          </Text>
          <Text
            colorVariant="secondary"
            style={{ marginTop: theme.typography.pxToRem(84) }}
            variant="body4"
            weightVariant="semiBold"
          >
            {prefixNS('decibel')}
          </Text>
        </Stack>
      )}
      <Stack alignItems="center" dataTestId="audio-meter-bar" gap="xs">
        <canvas ref={canvasRef} height={BTM_Y_GREEN} width={METER_WIDTH} />
        <Text variant="body4" weightVariant="semiBold">
          {prefixNS('channel')}
        </Text>
        <Text variant="body4" weightVariant="semiBold">
          {channelNumber}
        </Text>
      </Stack>
    </Inline>
  );
};

AudioMeter.displayName = 'AudioMeter';

export default AudioMeter;
