import * as React from 'react';
import { useClient } from '@studio/hooks';
import type { Shared } from '@studio/types';

type _Props = {
  channelId: string;
  partnerData?: Shared.PartnerData.Base;
};

const ContentDestinationName = ({ channelId, partnerData }: _Props): React.JSX.Element => {
  const { callApi: getFacebookDestinations } = useClient({
    config: useClient.central.v3.socialMedia.id.destinations.GET,
    params: { channelId: '' },
  });
  const { callApi: fetchSocialMediaChannel } = useClient({
    config: useClient.central.v3.socialMedia.id.GET,
    params: { id: '' },
  });

  const isMounted = React.useRef(false);

  React.useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
    }
    return () => {
      isMounted.current = false;
    };
  }, []);

  const [channelName, setChannelName] = React.useState<string>();
  const [destinationName, setDestinationName] = React.useState<string>();

  const fetchChannelInfo = React.useCallback(async () => {
    try {
      const channel = await fetchSocialMediaChannel({}, { params: { id: channelId } });
      if (isMounted.current) {
        setChannelName(channel.name);
      }
    } catch (error) {
      console.error(error);
    }
  }, [channelId, fetchSocialMediaChannel]);

  const fetchFacebookDestinations = React.useCallback(
    async (partnerDataProp: Shared.PartnerData.Base) => {
      try {
        const res = await getFacebookDestinations({}, { params: { channelId: channelId } });
        const fbTitle = res.find((dest) => dest.id === partnerDataProp.facebookPartnerData.destinationId)?.title;
        if (isMounted.current) {
          setDestinationName(fbTitle);
        }
      } catch (error) {
        console.error(error);
      }
    },
    [channelId, getFacebookDestinations]
  );

  React.useEffect(() => {
    if (partnerData) {
      fetchFacebookDestinations(partnerData);
    } else {
      fetchChannelInfo();
    }
  }, [fetchChannelInfo, fetchFacebookDestinations, partnerData]);

  return <>{partnerData ? destinationName : channelName}</>;
};

ContentDestinationName.displayName = 'ContentDestinationName';

export default ContentDestinationName;
