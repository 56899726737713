import type { SagaIterator } from 'redux-saga';
import type { AllEffect, ForkEffect } from 'redux-saga/effects';
import { all, call, cancelled, fork, put, select, take, takeLatest } from 'redux-saga/effects';
import { client } from '@studio/api/api-client/client';
import { STALE_DATA_TIMEOUT } from '@studio/constants/api-constants';
import { CENTRAL_API_V2, CENTRAL_API_V3 } from '@studio/constants/env-variables';
import type { Customer } from '@studio/types';
import type { ApiClientConfig } from '@studio/types/axios';
import { selectAccessToken, selectCustomerId } from '../authentication';
import { fetch, set } from './actions';
import { selectCustomerTimestamp } from './selectors';
import type { FirstTimeSetupStatus, CustomerNames } from './types';
import { CustomerActionTypes } from './types';

function* fetchCustomer(action: ReturnType<typeof fetch.customer.request>): SagaIterator {
  const timestamp: number = yield select(selectCustomerTimestamp);
  if (!action.payload?.force && Date.now() - timestamp < STALE_DATA_TIMEOUT) {
    yield put(fetch.customer.cancel());
    return;
  }
  const customerId: string = action.payload?.uuid || (yield select(selectCustomerId));
  const token: string = yield select(selectAccessToken);
  const url = `${CENTRAL_API_V3}/customers/${customerId}`;
  const config: ApiClientConfig = { token };

  try {
    const response: Customer.Get.Customer = yield call(client, url, config);

    const { multisiteStartDate, planName, subscriptionDetails, vodPlanName, vodStartDate, webPlanName, webStartDate } =
      response;

    try {
      yield call(pendo.identify, {
        visitor: { id: pendo.getVisitorId() },
        account: {
          id: pendo.getAccountId(),
          multi_plan: planName ?? '',
          multi_start_date: multisiteStartDate ?? '',
          live_plan: webPlanName ?? '',
          live_start_date: webStartDate ?? '',
          content_library_plan: vodPlanName ?? '',
          content_library_start_date: vodStartDate ?? '',
          subtitles: Boolean(subscriptionDetails.subtitlesHoursPerMonth),
        },
      });
    } catch (pendoError) {
      console.error('error calling pendo.identify', pendoError);
    }

    yield put(fetch.customer.success(response));
    yield put(set.timestamp(Date.now()));
    yield put(fetch.fts.request());
    yield put(fetch.toggles.request());
    yield put(fetch.togglesRegistered.request());
    yield take(CustomerActionTypes.FETCH_REQUEST_SUCCESS_FTS);
    yield take(CustomerActionTypes.FETCH_REQUEST_TOGGLES_SUCCESS);
    yield take(CustomerActionTypes.FETCH_REQUEST_TOGGLES_REGISTERED_SUCCESS);
  } catch (error) {
    console.log(error);
    if (error instanceof Error) {
      yield put(fetch.customer.failure(error));
    }
  }
}

function* fetchCustomerNames(): SagaIterator {
  const source = new AbortController();
  const token: string = yield select(selectAccessToken);
  const url = `${CENTRAL_API_V2}/customers/names`;
  const config: ApiClientConfig = { token };

  try {
    const response: CustomerNames[] = yield call(client, url, config);
    yield put(fetch.customerNames.success(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(fetch.customerNames.failure(error));
    }
  } finally {
    if (yield cancelled()) {
      source.abort('cancelled');
      yield put(fetch.customerNames.cancel());
    }
  }
}

function* fetchCustomerRefresh(action: ReturnType<typeof fetch.customerRefresh.request>): SagaIterator {
  const source = new AbortController();
  const customerId: string = action.payload?.uuid || (yield select(selectCustomerId));
  const token: string = yield select(selectAccessToken);
  const url = `${CENTRAL_API_V3}/customers/${customerId}`;
  const config: ApiClientConfig = { token };

  try {
    const response: Customer.Get.Customer = yield call(client, url, config);

    yield put(fetch.customerRefresh.success(response));
    yield put(set.timestamp(Date.now()));
  } catch (error) {
    console.log(error);
    yield put(fetch.customerRefresh.failure(error));
  } finally {
    if (yield cancelled()) {
      source.abort('cancelled');
      yield put(fetch.customerRefresh.cancel());
    }
  }
}

function* fetchFtsStatus(): SagaIterator {
  const source = new AbortController();
  const customerId: string = yield select(selectCustomerId);
  const token: string = yield select(selectAccessToken);
  const url = `${CENTRAL_API_V3}/customers/${customerId}/fts`;
  const config: ApiClientConfig = { token };

  try {
    const response: FirstTimeSetupStatus = yield call(client, url, config);
    yield put(fetch.fts.success(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(fetch.fts.failure(error));
    }
  } finally {
    if (yield cancelled()) {
      source.abort('cancelled');
      yield put(fetch.fts.cancel());
    }
  }
}

function* fetchToggles(): SagaIterator {
  const source = new AbortController();
  const customerId: string = yield select(selectCustomerId);
  const token: string = yield select(selectAccessToken);
  const url = `${CENTRAL_API_V3}/customers/${customerId}/toggles`;
  const config: ApiClientConfig = { token };

  try {
    const response: Customer.Get.Toggle[] = yield call(client, url, config);
    yield put(fetch.toggles.success(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(fetch.toggles.failure(error));
    }
  } finally {
    if (yield cancelled()) {
      source.abort('cancelled');
      yield put(fetch.toggles.cancel());
    }
  }
}

function* fetchTogglesRegistered(): SagaIterator {
  const source = new AbortController();
  const customerId: string = yield select(selectCustomerId);
  const token: string = yield select(selectAccessToken);
  const url = `${CENTRAL_API_V3}/customers/${customerId}/toggleregs`;
  const config: ApiClientConfig = { token };

  try {
    const response: Customer.Get.TogglesRegistered = yield call(client, url, config);
    yield put(fetch.togglesRegistered.success(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(fetch.togglesRegistered.failure(error));
    }
  } finally {
    if (yield cancelled()) {
      source.abort('cancelled');
      yield put(fetch.togglesRegistered.cancel());
    }
  }
}

export function* watchFetchCustomer<T>(): Generator<ForkEffect<never>, void, T> {
  yield takeLatest(CustomerActionTypes.FETCH_REQUEST, fetchCustomer);
}

export function* watchFetchCustomerNames<T>(): Generator<ForkEffect<never>, void, T> {
  yield takeLatest(CustomerActionTypes.FETCH_NAMES, fetchCustomerNames);
}

export function* watchFetchCustomerRefresh<T>(): Generator<ForkEffect<never>, void, T> {
  yield takeLatest(CustomerActionTypes.FETCH_REFRESH, fetchCustomerRefresh);
}

export function* watchFetchFtsStatus<T>(): Generator<ForkEffect<never>, void, T> {
  yield takeLatest(CustomerActionTypes.FETCH_REQUEST_FTS, fetchFtsStatus);
}

export function* watchFetchToggles<T>(): Generator<ForkEffect<never>, void, T> {
  yield takeLatest(CustomerActionTypes.FETCH_REQUEST_TOGGLES, fetchToggles);
}

export function* watchFetchTogglesRegistered<T>(): Generator<ForkEffect<never>, void, T> {
  yield takeLatest(CustomerActionTypes.FETCH_REQUEST_TOGGLES_REGISTERED, fetchTogglesRegistered);
}

export function* customerSaga<T>(): Generator<AllEffect<ForkEffect<void>>, void, T> {
  yield all([
    fork(watchFetchCustomer),
    fork(watchFetchCustomerNames),
    fork(watchFetchCustomerRefresh),
    fork(watchFetchFtsStatus),
    fork(watchFetchToggles),
    fork(watchFetchTogglesRegistered),
  ]);
}
