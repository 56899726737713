import * as React from 'react';
import { CloudUploadOutlined } from '@ant-design/icons';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { Draft, Inline, Link, Progress, Stack, Text } from '@resi-media/resi-ui';
import { FILE_VALIDATION_ERRORS } from '@studio/constants/shared';
import { fileListToArray, isFileValid } from '@studio/helpers';
import { usePrefix } from '@studio/hooks';

const DragAndDropZone = styled.div<{ isHovering: boolean }>`
  width: 100%;
  height: 313px;
  border: 1px dashed ${(props) => props.theme.palette.border};
  border-radius: ${(props) => props.theme.shape.borderRadius.xl};
  display: grid;
  place-items: center;
  background-color: ${({ isHovering, theme }) =>
    isHovering ? theme.palette.primary.background : theme.palette.background.default};
`;

type _Props = {
  accept?: string;
  isLoading: boolean;
  multiple?: boolean;
  onCancel?: () => void;
  onFilesAdded?: (files: File[]) => void;
  style?: React.CSSProperties;
};

const FileDropzone = ({ accept, isLoading, multiple, onCancel, onFilesAdded, style }: _Props) => {
  const theme = useTheme();
  const [highlight, setHighlight] = React.useState(false);
  const fileInputRef = React.createRef<HTMLInputElement>();
  const { prefixNS, PrefixTrans } = usePrefix('components:', 'fileDropzone');
  const [error, setError] = React.useState<string>();

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>): void => {
    event.preventDefault();

    setHighlight(true);
  };

  const handleDragLeave = (event: React.DragEvent<HTMLDivElement>): void => {
    event.preventDefault();

    setHighlight(false);
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>): void => {
    event.preventDefault();
    setError(undefined);

    try {
      const files = event.dataTransfer.files;
      if (isFileValid(files[0])) {
        const filesArray = fileListToArray(files);
        onFilesAdded?.(filesArray);
      }
    } catch (error) {
      if (error instanceof Error) {
        setError(error.message);
      }
    } finally {
      setHighlight(false);
    }
  };

  const openFileDialog = (): void => {
    fileInputRef.current?.click();
  };

  const handleFilesAdded = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setError(undefined);

    try {
      const files = event.target.files;
      const filesArray = fileListToArray(files);

      if (isFileValid(filesArray[0])) {
        onFilesAdded?.(filesArray);
      }
    } catch (error) {
      if (error instanceof Error) {
        setError(error.message);
      }
    }
  };

  return (
    <>
      <Stack dataTestId="file-dropzone">
        <Inline justifyContent="space-between">
          <Text>{prefixNS('selectAFileToUpload')}</Text>
          {onCancel && (
            <Draft.Button
              dataTestId="cancel-upload-button"
              label={prefixNS('cancelUpload')}
              onClick={onCancel}
              sizeVariant="s"
              variant="text"
            />
          )}
        </Inline>
        {error === FILE_VALIDATION_ERRORS.FILE_TYPE && (
          <Draft.AlertBanner>{prefixNS('invalidFileTypeError')}</Draft.AlertBanner>
        )}
        {error === FILE_VALIDATION_ERRORS.FILE_SIZE && (
          <Draft.AlertBanner>{prefixNS('fileSizeError')}</Draft.AlertBanner>
        )}
        <DragAndDropZone
          isHovering={highlight}
          onClick={openFileDialog}
          onDragLeave={handleDragLeave}
          onDragOver={handleDragOver}
          onDrop={handleDrop}
          style={{ cursor: 'pointer', ...style }}
        >
          {isLoading ? (
            <Progress sizeVariant="m" />
          ) : (
            <Stack alignItems="center" gap="l" w="x44">
              <CloudUploadOutlined style={{ fontSize: '64px', color: theme.palette.text.secondary }} />
              <Text isInline textAlign="center">
                <PrefixTrans i18nKey="dropImageHere">
                  fill <Link weightVariant="bold">fill</Link>
                </PrefixTrans>
              </Text>
            </Stack>
          )}
        </DragAndDropZone>
      </Stack>
      <input
        ref={fileInputRef}
        accept={accept}
        data-testid="file-dropzone-input"
        multiple={multiple}
        onChange={handleFilesAdded}
        style={{ display: 'none' }}
        type="file"
      />
    </>
  );
};

FileDropzone.displayName = 'FileDropzone';

export default FileDropzone;
