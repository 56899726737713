import { produce } from 'immer';
import type { ActionType, PayloadAction } from 'typesafe-actions';
import { createReducer } from 'typesafe-actions';
import type { WebChannels } from '@studio/types';
import { fetchWebChannelsAsync, setFetchRequestTimestamp, setFilter } from './actions';
import type { WebChannelsState, ActionTypes } from './types';

type Action = ActionType<typeof fetchWebChannelsAsync | typeof setFetchRequestTimestamp | typeof setFilter>;

export const initialState: WebChannelsState = {
  isFetching: false,
  items: [],
  timestamp: 0,
  error: null,
  filter: '',
};

const reducer = createReducer<WebChannelsState, Action>(initialState)
  .handleAction(
    fetchWebChannelsAsync.request,
    produce((draft: WebChannelsState) => {
      draft.isFetching = true;
      draft.error = null;
    })
  )
  .handleAction(
    fetchWebChannelsAsync.success,
    produce(
      (
        draft: WebChannelsState,
        action: PayloadAction<ActionTypes.FETCH_REQUEST_SUCCESS, WebChannels.Get.WebChannel[]>
      ) => {
        draft.isFetching = false;
        draft.items = action.payload;
      }
    )
  )
  .handleAction(
    fetchWebChannelsAsync.failure,
    produce((draft: WebChannelsState, action: PayloadAction<ActionTypes.FETCH_REQUEST_ERROR, Error>) => {
      draft.isFetching = false;
      draft.error = action.payload;
    })
  )
  .handleAction(
    fetchWebChannelsAsync.cancel,
    produce((draft: WebChannelsState) => {
      draft.isFetching = false;
    })
  )
  .handleAction(
    setFetchRequestTimestamp,
    produce((draft: WebChannelsState, action: PayloadAction<ActionTypes.FETCH_REQUEST_TIMESTAMP, number>) => {
      draft.timestamp = action.payload;
    })
  )
  .handleAction(
    setFilter,
    produce((draft: WebChannelsState, action: PayloadAction<ActionTypes.FILTER_RESULTS, string>) => {
      draft.filter = action.payload;
    })
  );

export default reducer;
