import { HTTP_METHODS } from '@studio/constants/axios';
import { WEBPLAYER_CLOUDFLARE } from '@studio/constants/env-variables';
import type { WebPlayer } from '@studio/types';

export const root = {};

export const id = {
  [HTTP_METHODS.GET]: {
    url: `${WEBPLAYER_CLOUDFLARE}/config/@id`,
    response: {} as WebPlayer.Get.WebPlayer,
    params: {} as { id: string },
    method: HTTP_METHODS.GET,
  },
};
