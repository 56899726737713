import type { Option } from '@resi-media/resi-ui';

const getDefaultChannel = (tracks: Option[], defaultChannel: number): number => {
  if (tracks.length > 0 && !tracks.find((v) => v.value === defaultChannel)) {
    return tracks[0].value;
  }
  return defaultChannel;
};

export default getDefaultChannel;
