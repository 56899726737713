import { produce } from 'immer';
import type { ActionType } from 'typesafe-actions';
import { createReducer } from 'typesafe-actions';
import { fetch, set } from './actions';
import type { SiteState } from './types';

type Action = ActionType<typeof fetch | typeof set>;

export const initialSiteState: SiteState = {
  error: null,
  filter: '',
  isFetching: false,
  items: [],
  timestamp: 0,
};

const reducer = createReducer<SiteState, Action>(initialSiteState)
  .handleAction(
    fetch.sites.request,
    produce((draft: SiteState) => {
      draft.isFetching = true;
      draft.error = null;
    })
  )
  .handleAction(
    fetch.sites.success,
    produce((draft: SiteState, action: ActionType<typeof fetch.sites.success>) => {
      draft.isFetching = false;
      draft.items = action.payload;
    })
  )
  .handleAction(
    fetch.sites.failure,
    produce((draft: SiteState, action: ActionType<typeof fetch.sites.failure>) => {
      draft.isFetching = false;
      draft.error = action.payload;
    })
  )
  .handleAction(
    fetch.sites.cancel,
    produce((draft: SiteState) => {
      draft.isFetching = false;
    })
  )
  .handleAction(
    set.filter,
    produce((draft: SiteState, action: ActionType<typeof set.filter>) => {
      draft.filter = action.payload;
    })
  )
  .handleAction(
    set.timestamp,
    produce((draft: SiteState, action: ActionType<typeof set.timestamp>) => {
      draft.timestamp = action.payload;
    })
  );

export default reducer;
