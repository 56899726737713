import { createAction, createAsyncAction } from 'typesafe-actions';
import type { Sites } from '@studio/types';
import { ActionTypes } from './types';

export const fetch = {
  sites: createAsyncAction(
    ActionTypes.FETCH_REQUEST,
    ActionTypes.FETCH_REQUEST_SUCCESS,
    ActionTypes.FETCH_REQUEST_ERROR,
    ActionTypes.FETCH_REQUEST_CANCEL
  )<undefined, Sites.Get.Site[], Error, undefined>(),
};

export const set = {
  filter: createAction(ActionTypes.FILTER_RESULTS)<string>(),
  timestamp: createAction(ActionTypes.FETCH_REQUEST_TIMESTAMP)<number>(),
};
