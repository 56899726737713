import type { AxiosResponse } from 'axios';
import type { SagaIterator } from 'redux-saga';
import type { AllEffect, ForkEffect } from 'redux-saga/effects';
import { all, call, cancelled, fork, put, select as sagaSelect, takeLatest } from 'redux-saga/effects';
import { client } from '@studio/api/api-client/client';
import { MEDIA_METADATA_API_V1, SEARCH_API_V1 } from '@studio/constants/env-variables';
import { LINKS } from '@studio/constants/shared';
import { parseHeaderLink } from '@studio/helpers';
import type { LibrarySearch } from '@studio/types';
import type { ApiClientConfig } from '@studio/types/axios';
import { selectAccessToken, selectCustomerId } from '../authentication';
import { fetch } from './actions';
import { buildQueryParams, getFacetCounts } from './helpers';
import { select } from './selectors';
import { ActionTypes } from './types';

function* handleFetch(action: ReturnType<typeof fetch.media.request>): SagaIterator {
  const source = new AbortController();
  const customerId: string = yield sagaSelect(selectCustomerId);
  const selectedTags: string[] = yield sagaSelect(select.tags);

  const token: string = yield sagaSelect(selectAccessToken);
  const config: ApiClientConfig = { token, returnResponse: true };

  try {
    const url = `${SEARCH_API_V1}/vod/${customerId}?${buildQueryParams(action.payload)}`;
    const response: AxiosResponse<LibrarySearch.Get.Response> = yield call(client, url, config);
    const nextUrl = parseHeaderLink(response.headers.link);

    yield put(
      fetch.media.success({
        nextUrl: nextUrl[LINKS.NEXT],
        response: response.data,
        facetCounts: getFacetCounts(response.data.facets, selectedTags),
      })
    );
  } catch (error) {
    if (error instanceof Error) {
      yield put(fetch.media.failure(error));
    }
  } finally {
    if (yield cancelled()) {
      source.abort('cancelled');
      yield put(fetch.media.cancel());
    }
  }
}

function* handleFetchBackground(): SagaIterator {
  const source = new AbortController();
  const customerId: string = yield sagaSelect(selectCustomerId);
  const token: string = yield sagaSelect(selectAccessToken);
  const previousSearchRequest: LibrarySearch.Get.Params = yield sagaSelect(select.request);
  const previousSelectedTags: string[] = yield sagaSelect(select.tags);
  const config: ApiClientConfig = { token, returnResponse: true };

  try {
    const url = `${SEARCH_API_V1}/vod/${customerId}?${buildQueryParams(previousSearchRequest)}`;
    const response: AxiosResponse<LibrarySearch.Get.Response> = yield call(client, url, config);
    const nextUrl = parseHeaderLink(response.headers.link);

    yield put(
      fetch.media.success({
        nextUrl: nextUrl[LINKS.NEXT],
        response: response.data,
        facetCounts: getFacetCounts(response.data.facets, previousSelectedTags),
      })
    );
  } catch (error) {
    if (error instanceof Error) {
      yield put(fetch.media.failure(error));
    }
  } finally {
    if (yield cancelled()) {
      source.abort('cancelled');
      yield put(fetch.media.cancel());
    }
  }
}

function* handleFetchMore(): SagaIterator {
  const source = new AbortController();
  const searchResults: LibrarySearch.Derived.Results = yield sagaSelect(select.results);

  try {
    if (searchResults.nextUrl) {
      const token: string = yield sagaSelect(selectAccessToken);
      const config: ApiClientConfig = { token, returnResponse: true };
      const selectedTags: string[] = yield sagaSelect(select.tags);
      const response: AxiosResponse<LibrarySearch.Get.Response> = yield call(client, searchResults.nextUrl, config);
      const nextUrl = parseHeaderLink(response.headers.link);

      const mediaItems = searchResults.response?.mediaItems.concat(response.data.mediaItems) ?? [];

      yield put(
        fetch.nextPage.success({
          nextUrl: nextUrl[LINKS.NEXT],
          response: { ...response.data, mediaItems },
          facetCounts: getFacetCounts(response.data.facets, selectedTags),
        })
      );
    }
  } catch (error) {
    if (error instanceof Error) {
      yield put(fetch.nextPage.failure(error));
    }
  } finally {
    if (yield cancelled()) {
      source.abort('cancelled');
      yield put(fetch.nextPage.cancel());
    }
  }
}

// fetches updated info only for the library items that are "processing"; payload.items contains the list of mediaId's that need updating
function* handleFetchProcessing(action: ReturnType<typeof fetch.processingMedia.request>) {
  const customerId: string = yield sagaSelect(selectCustomerId);
  const baseUrl = `${MEDIA_METADATA_API_V1}/customers/${customerId}/media`;
  const token: string = yield sagaSelect(selectAccessToken);
  const config: ApiClientConfig = { token };
  const updatedItems = [];
  try {
    if (action.payload.items) {
      for (const mediaId of action.payload.items) {
        const mediaUrl = `${baseUrl}/${mediaId}`;
        const response: LibrarySearch.Get.MediaItem = yield call(client, mediaUrl, config);
        updatedItems.push(response);
      }
    }
    yield put(fetch.processingMedia.success(updatedItems));
  } catch (error) {
    if (error instanceof Error) {
      yield put(fetch.processingMedia.failure(error));
    }
  }
}

export function* watchFetchLibrarySearch<T>(): Generator<ForkEffect<never>, void, T> {
  yield takeLatest(ActionTypes.FETCH_REQUEST, handleFetch);
}

export function* watchFetchLibrarySearchBackground<T>(): Generator<ForkEffect<never>, void, T> {
  yield takeLatest(ActionTypes.FETCH_REQUEST_BACKGROUND, handleFetchBackground);
}

export function* watchFetchLibrarySearchFetchMore<T>(): Generator<ForkEffect<never>, void, T> {
  yield takeLatest(ActionTypes.FETCH_MORE_REQUEST, handleFetchMore);
}

export function* watchFetchProcessing<T>(): Generator<ForkEffect<never>, void, T> {
  yield takeLatest(ActionTypes.FETCH_PROCESSING, handleFetchProcessing);
}

export function* librarySearchSaga<T>(): Generator<AllEffect<ForkEffect<void>>, void, T> {
  yield all([
    fork(watchFetchLibrarySearch),
    fork(watchFetchLibrarySearchBackground),
    fork(watchFetchLibrarySearchFetchMore),
    fork(watchFetchProcessing),
  ]);
}
