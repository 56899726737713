import * as React from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Draft, Stack, Text } from '@resi-media/resi-ui';

type _Props = {
  buttonLabel: string;
  children?: React.ReactNode;
  headerText: string;
  onClick: () => void;
};

const TableListNoData = ({ buttonLabel, children, headerText, onClick }: _Props) => (
  <Stack data-testid="no-data" gap="xl">
    <Stack gap="m">
      <Text as="h4" weightVariant="bold">
        {headerText}
      </Text>
      {children}
    </Stack>
    <div>
      <Draft.Button label={buttonLabel} onClick={onClick} startNode={<PlusOutlined />} />
    </div>
  </Stack>
);

TableListNoData.displayName = 'TableListNoData';

export default TableListNoData;
