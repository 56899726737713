import { Draft, Stack, Text } from '@resi-media/resi-ui';
import { ErrorBlock } from '@studio/components/ErrorBlock';
import { useClient, usePrefix } from '@studio/hooks';
import { useTypedSelector } from '@studio/store';
import { ConfirmationModal } from '../ConfirmationModal';

const VerifyEmailModal = () => {
  const { prefixNS, PrefixTrans } = usePrefix('components:', 'verifyEmailModal');
  const { user } = useTypedSelector((state) => state.authentication);
  const userId = user?.userId ?? '';
  const { onCloseReset } = Draft.ModalContext.useModal();

  const postRequestVerifyEmail = useClient({
    config: useClient.central.v3.users.id.email.verify.request.POST,
    params: { userId },
  });

  const handleRequestVerifyEmail = async () => {
    try {
      await postRequestVerifyEmail.callApi();
      onCloseReset();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <ConfirmationModal
      handleClose={onCloseReset}
      handleSubmit={handleRequestVerifyEmail}
      isHandlingSubmit={postRequestVerifyEmail.isFetching}
      isOpen
      submitLabel={prefixNS('sendVerificationEmail')}
      title={prefixNS('title')}
    >
      <Stack dataTestId="verify-email-modal" gap="m">
        <ErrorBlock error={postRequestVerifyEmail.error} />
        <Text as="div">
          <PrefixTrans i18nKey="descriptionOne">
            filler text
            <Text isInline variant="body2" weightVariant="bold">
              <>{{ email: user?.userName }}</>
            </Text>
            filler text
          </PrefixTrans>
        </Text>
        <Text variant="body2">{prefixNS('descriptionTwo')}</Text>
      </Stack>
    </ConfirmationModal>
  );
};

VerifyEmailModal.displayName = 'VerifyEmailModal';

export default VerifyEmailModal;
