import { createAction, createAsyncAction } from 'typesafe-actions';
import type { WebPresets } from '@studio/types';
import { WebPresetActionTypes } from './types';

export const fetch = createAsyncAction(
  WebPresetActionTypes.FETCH_REQUEST,
  WebPresetActionTypes.FETCH_REQUEST_SUCCESS,
  WebPresetActionTypes.FETCH_REQUEST_ERROR,
  WebPresetActionTypes.FETCH_REQUEST_CANCEL
)<undefined, WebPresets.Get.WebPreset[], Error, undefined>();

export const set = {
  filter: createAction(WebPresetActionTypes.FILTER_RESULTS)<string>(),
  timestamp: createAction(WebPresetActionTypes.FETCH_REQUEST_TIMESTAMP)<number>(),
  fetchComplete: createAction(WebPresetActionTypes.FETCH_COMPLETE)<boolean>(),
};
