import type { SagaIterator } from 'redux-saga';
import type { AllEffect, ForkEffect } from 'redux-saga/effects';
import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';
import { client } from '@studio/api/api-client/client';
import { STALE_DATA_TIMEOUT } from '@studio/constants/api-constants';
import { CENTRAL_API_V3 } from '@studio/constants/env-variables';
import { selectCustomerId, selectAccessToken } from '@studio/store/authentication/selectors';
import type { Roles, Users } from '@studio/types';
import type { ApiClientConfig } from '@studio/types/axios';
import { fetch, set } from './actions';
import { selectUserRolesTimestamp, selectUsersTimestamp, selectUserInvitesTimestamp } from './selectors';
import { UserInvitesActionTypes, UserRolesActionTypes, UsersActionTypes } from './types';

function* handleUserInvitesFetch(): SagaIterator {
  const timestamp: number = yield select(selectUserInvitesTimestamp);
  if (Date.now() - timestamp < STALE_DATA_TIMEOUT) {
    yield put(fetch.invites.cancel());
    return;
  }
  const customerId: string = yield select(selectCustomerId);
  const url = `${CENTRAL_API_V3}/customers/${customerId}/userinvites`;
  const token: string = yield select(selectAccessToken);
  const config: ApiClientConfig = { token };
  try {
    const response: Users.Get.UserInvite[] = yield call(client, url, config);
    yield put(fetch.invites.success(response));
    yield put(set.invitesTimestamp(Date.now()));
  } catch (error) {
    if (error instanceof Error) {
      yield put(fetch.invites.failure(error));
    }
  }
}

function* handleUsersFetch() {
  const timestamp: number = yield select(selectUsersTimestamp);
  if (Date.now() - timestamp < STALE_DATA_TIMEOUT) {
    yield put(fetch.users.cancel());
    return;
  }
  const customerId: string = yield select(selectCustomerId);
  const url = `${CENTRAL_API_V3}/customers/${customerId}/users`;
  const token: string = yield select(selectAccessToken);
  const config: ApiClientConfig = { token };
  try {
    const response: Users.Get.User[] = yield call(client, url, config);
    yield put(fetch.users.success(response));
    yield put(set.usersTimestamp(Date.now()));
  } catch (error) {
    if (error instanceof Error) {
      yield put(fetch.users.failure(error));
    }
  }
}

function* handleUserRolesFetch() {
  const customerId: string = yield select(selectCustomerId);
  const timestamp: number = yield select(selectUserRolesTimestamp);
  if (Date.now() - timestamp < STALE_DATA_TIMEOUT) {
    yield put(fetch.roles.cancel());
    return;
  }

  const url = `${CENTRAL_API_V3}/customers/${customerId}/roles`;
  const token: string = yield select(selectAccessToken);
  const config: ApiClientConfig = { token };
  try {
    const response: Roles.Get.Role[] = yield call(client, url, config);
    yield put(fetch.roles.success(response));
    yield put(set.rolesTimestamp(Date.now()));
  } catch (error) {
    if (error instanceof Error) {
      yield put(fetch.roles.failure(error));
    }
  }
}

export function* watchFetchUserInvitesRequest<T>(): Generator<ForkEffect<never>, void, T> {
  yield takeLatest(UserInvitesActionTypes.FETCH_REQUEST, handleUserInvitesFetch);
}

export function* watchFetchUsersRequest<T>(): Generator<ForkEffect<never>, void, T> {
  yield takeLatest(UsersActionTypes.FETCH_REQUEST, handleUsersFetch);
}

export function* watchFetchUserRolesRequest<T>(): Generator<ForkEffect<never>, void, T> {
  yield takeLatest(UserRolesActionTypes.FETCH_REQUEST, handleUserRolesFetch);
}

export function* allUsersSaga<T>(): Generator<AllEffect<ForkEffect<void>>, void, T> {
  yield all([fork(watchFetchUserInvitesRequest), fork(watchFetchUsersRequest), fork(watchFetchUserRolesRequest)]);
}
