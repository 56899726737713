import * as React from 'react';
import { CaretRightOutlined } from '@ant-design/icons';
import { useTheme } from '@emotion/react';
import { Badge, Draft, Inline, SimLive, Stack } from '@resi-media/resi-ui';
import { useSelector } from 'react-redux';
import { VIDEO_STATUS } from '@studio/constants/content-destination';
import { PROFILE_TYPES } from '@studio/constants/web-channels';
import { getStatusColor, isStatusTransitioning } from '@studio/helpers';
import { usePrefix } from '@studio/hooks';
import { selectCustomerData } from '@studio/store';
import { WebVideoStatus } from '@studio/store/content-destinations/types';

type _Props = {
  active?: boolean;
  nextSimLive?: boolean;
  profileType: string;
  status: string;
};

const WebVideoStatusBadges = ({ active, nextSimLive, profileType, status }: _Props) => {
  const theme = useTheme();
  const { prefixNS } = usePrefix('pages:', 'library');
  const customer = useSelector(selectCustomerData);
  status = status.toLowerCase();

  const getDisplayStatusText = React.useCallback(
    (mediaStatus: string): string => {
      let translatedStatus = prefixNS(`status.${mediaStatus}`);
      // transitioning status should receive "decoration"
      if (isStatusTransitioning(mediaStatus)) {
        translatedStatus += '...';
      }
      return translatedStatus;
    },
    [prefixNS]
  );

  const getStatusWarning = React.useCallback(
    (displayStatus: string): React.ReactNode => {
      if (displayStatus === WebVideoStatus.ABORTED) {
        return (
          <Stack>
            <div>{prefixNS('webBroadcast.tooltips.abortedSummary')}</div>
            <ul style={{ listStyle: 'disc', paddingLeft: theme.spacing.m }}>
              <li>{prefixNS('webBroadcast.tooltips.abortedReason1')}</li>
              <li>{prefixNS('webBroadcast.tooltips.abortedReason2')}</li>
              <li>{prefixNS('webBroadcast.tooltips.abortedReason3')}</li>
            </ul>
          </Stack>
        );
      }
      return null;
    },
    [prefixNS, theme.spacing.m]
  );

  const renderStatus = React.useMemo(() => {
    const displayText = getDisplayStatusText(status);
    const statusColor = getStatusColor(status);
    const statusWarning = getStatusWarning(status);
    if (status.toUpperCase() === VIDEO_STATUS.STOPPED) {
      return '';
    }

    if (statusWarning !== null) {
      return (
        <Draft.Tooltip content={statusWarning}>
          <Badge colorVariant={statusColor} data-testid="web-video-status-badge-with-tooltip">
            {displayText}
          </Badge>
        </Draft.Tooltip>
      );
    }

    return (
      <Badge colorVariant={statusColor} data-testid="web-video-status-badge">
        {displayText}
      </Badge>
    );
  }, [getDisplayStatusText, getStatusWarning, status]);

  const renderActive = React.useMemo(() => {
    if (active && profileType !== PROFILE_TYPES.SOCIAL) {
      return (
        <Badge colorVariant="primary" data-testid="web-video-active-badge">
          <Draft.Tooltip content={prefixNS('webBroadcast.tooltips.active')}>
            <Inline alignItems="center">
              <CaretRightOutlined />
              <span>{prefixNS('webBroadcast.active')}</span>
            </Inline>
          </Draft.Tooltip>
        </Badge>
      );
    }
    return null;
  }, [active, profileType, prefixNS]);

  const renderNextSimLive = React.useMemo(() => {
    if (nextSimLive && customer?.webPlan?.allowSimLive) {
      return (
        <Badge colorVariant="purple" data-testid="web-video-simlive-badge">
          <Draft.Tooltip content={prefixNS('webBroadcast.tooltips.nextSimLive')}>
            <Inline alignItems="center">
              <SimLive />
              <span>{prefixNS('webBroadcast.nextReplay')}</span>
            </Inline>
          </Draft.Tooltip>
        </Badge>
      );
    }
    return null;
  }, [nextSimLive, customer, prefixNS]);

  return (
    <Inline flexWrap="wrap" gap="xs">
      {renderStatus} {renderActive} {renderNextSimLive}
    </Inline>
  );
};

WebVideoStatusBadges.displayName = 'WebVideoStatusBadges';

export default WebVideoStatusBadges;
