import * as Yup from 'yup';
import { getByteSizeFromString } from '@studio/helpers';

export const VALIDATION = {
  INCLUDE: 'include',
  IN_USE: 'in-use',
  MAX_BYTES: 'maxBytes',
  REQUIRED: 'required',
} as const;

export const validationSchema = Yup.object({
  destinationGroup: Yup.object({
    include: Yup.boolean(),
    id: Yup.string().when('include', {
      is: true,
      then: Yup.string().required(),
    }),
  }),
  fb: Yup.object({
    include: Yup.boolean(),
    channelId: Yup.string().when('include', {
      is: true,
      then: Yup.string().required(),
    }),
    destinationId: Yup.string().when('include', {
      is: true,
      then: Yup.string().required(),
    }),
    privacy: Yup.string().when('include', {
      is: true,
      then: Yup.string().required(),
    }),
    title: Yup.string().when('include', {
      is: true,
      then: Yup.string()
        .required()
        .matches(/^[^><]*$/),
    }),
    description: Yup.string().when('include', {
      is: true,
      then: Yup.string().matches(/^[^><]*$/),
    }),
  }),
  rtmp: Yup.object({
    include: Yup.boolean(),
    rtmpDestinationId: Yup.string().when('include', {
      is: true,
      then: Yup.string().required(),
    }),
  }),
  web: Yup.object({
    include: Yup.boolean(),
    webEventProfileId: Yup.string().when('include', {
      is: true,
      then: Yup.string().required(),
    }),
    title: Yup.string().when('include', {
      is: true,
      then: Yup.string().required(),
    }),
  }),
  yt: Yup.object({
    include: Yup.boolean(),
    channelId: Yup.string().when('include', {
      is: true,
      then: Yup.string().required(),
    }),
    privacy: Yup.string().when('include', {
      is: true,
      then: Yup.string().required(),
    }),
    publishStatus: Yup.string().when('include', {
      is: true,
      then: Yup.string().required(),
    }),
    title: Yup.string().when('include', {
      is: true,
      then: Yup.string()
        .test('maxBytes', 'max bytes allowed', (val) => getByteSizeFromString(val) <= 100)
        .required()
        .matches(/^[^><]*$/),
    }),
    description: Yup.string().when('include', {
      is: true,
      then: Yup.string().matches(/^[^><]*$/),
    }),
  }),
});
