import type { Option } from '@resi-media/resi-ui';
import type { CombinedState } from 'redux';
import { createSelector } from 'reselect';
import { Venues } from '@studio/types';
import type { Customer, PrefixCallback } from '@studio/types';
import type { RootState } from '../root-reducer';
import type { CustomerStore, CustomerState, CustomerNames } from './types';

export const selectCustomerState = (state: RootState): CombinedState<CustomerState> => state.customer;

export const selectCustomerTimestamp = createSelector(selectCustomerState, (state): number => state.customer.timestamp);

export const selectCustomer = createSelector(selectCustomerState, (state): CustomerStore => state.customer);

export const selectCustomerData = createSelector(
  selectCustomerState,
  (state): Customer.Get.Customer | null => state.customer.data
);

export const selectCustomerNameData = createSelector(
  selectCustomerState,
  (state): CustomerNames[] | null => state.customerNames.data
);

export const selectCustomerNames = createSelector(
  selectCustomerState,
  (state): string[] | null => state.customerNames.data?.map((e) => e.name) ?? null
);

export const selectCustomerUsageInfo = createSelector(
  selectCustomerData,
  (customer): Customer.Components.Usage | null => {
    if (!customer) return null;

    const { usageSummary } = customer;
    const broadcast = Math.max(0, usageSummary.nonProductionSiteLicenses - usageSummary.inUseNonProductionSiteLicenses);
    const ent = Math.max(0, usageSummary.enterpriseLicenses - usageSummary.inUseEnterpriseLicenses);
    const cloud = Math.max(0, usageSummary.lanCloudSiteLicenses - usageSummary.inUseLanCloudSiteLicenses);
    const pro = Math.max(0, usageSummary.professionalLicenses - usageSummary.inUseProfessionalLicenses);

    return {
      [Venues.LICENSE_TYPES.BROADCAST]: broadcast,
      [Venues.LICENSE_TYPES.CLOUD_ENT]: ent,
      [Venues.LICENSE_TYPES.LAN_CLOUD]: cloud,
      [Venues.LICENSE_TYPES.CLOUD_PRO]: pro,
      availableLicenses: broadcast + ent + cloud + pro,
    };
  }
);

export const selectWebPlan = createSelector(selectCustomerState, (state) => state.customer.data?.webPlan);

export const selectWebPlanId = createSelector(selectCustomerState, (state) => state.customer.data?.webPlanId);

export const selectWebPlanName = createSelector(selectCustomerState, (state) => state.customer.data?.webPlanName);

export const selectVodPlanId = createSelector(selectCustomerState, (state) => state.customer.data?.vodPlanId);

export const selectMultisitePlanId = createSelector(selectCustomerState, (state) => state.customer.data?.planId);

export const selectFtsStatus = createSelector(selectCustomerState, (state) => state.customer.ftsStatus);

export const selectDeleteAfterOptions = createSelector(
  selectCustomerData,
  (_: RootState, prefixT: PrefixCallback) => prefixT,
  (customer, prefixT): Option[] => {
    const deleteAfterArray = customer?.webMaxDeleteAfter
      ? Array.from(Array(customer.webMaxDeleteAfter + 1).keys()).slice(1)
      : [];

    return deleteAfterArray.map((n) => ({
      value: n,
      label: prefixT('fields.deleteAfter.label', { count: n }),
    }));
  }
);

export const selectSubscriptionDetails = createSelector(
  selectCustomerState,
  (state) => state.customer.data?.subscriptionDetails
);

export const selectCustomerNameDataOptions = createSelector(selectCustomerNameData, (customers): Option[] => {
  if (!customers) {
    return [];
  }
  return customers.map(({ name, uuid }) => ({
    label: name,
    value: uuid,
  }));
});

export const selectCustomerMasterEcg = createSelector(selectCustomerState, (state) => state.customer.data?.masterEcg);

export const selectToggles = createSelector(selectCustomerState, (state) => state.customer.toggles);

export const selectTogglesRegistered = createSelector(selectCustomerState, (state) => state.customer.togglesRegistered);

export const selectMatchingToggles = createSelector(
  selectToggles,
  selectTogglesRegistered,
  (toggles, togglesRegistered) => {
    return toggles?.filter((toggle) => togglesRegistered?.toggleIds?.some((uuid) => toggle.uuid === uuid));
  }
);
