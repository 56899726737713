import type { SagaIterator } from 'redux-saga';
import type { AllEffect, ForkEffect } from 'redux-saga/effects';
import { all, call, cancelled, fork, put, select, takeEvery } from 'redux-saga/effects';
import { client } from '@studio/api/api-client/client';
import { SYS_EVENT_API_V1 } from '@studio/constants/env-variables';
import type { SystemEvents } from '@studio/types';
import { selectAccessToken, selectCustomerId } from '../authentication/selectors';
import { fetch } from './actions';
import { ActionTypes } from './types';

function* handleFetchSubjectEvents(action: ReturnType<typeof fetch.subjectEvents.request>): SagaIterator {
  const source = new AbortController();
  const token: string = yield select(selectAccessToken);
  const customerId: string = yield select(selectCustomerId);

  const url = `${SYS_EVENT_API_V1}/customers/${customerId}/subjects/${action.payload.subjectId}/events`;

  try {
    const response: SystemEvents.Get.Event[] = yield call(client, url, { token });
    yield put(fetch.subjectEvents.success({ subjectId: action.payload.subjectId, events: response }));
  } catch (error) {
    if (error instanceof Error) {
      yield put(fetch.subjectEvents.failure(error));
    }
  } finally {
    if (yield cancelled()) {
      source.abort('cancelled');
      yield put(fetch.subjectEvents.cancel());
    }
  }
}

export function* watchFetchSubjectEventsRequest<T>(): Generator<ForkEffect<void>, void, T> {
  yield takeEvery(ActionTypes.FETCH_SUBJECT_EVENTS_REQUEST, handleFetchSubjectEvents);
}

export function* systemEventsSaga<T>(): Generator<AllEffect<ForkEffect<void>>, void, T> {
  yield all([fork(watchFetchSubjectEventsRequest)]);
}
