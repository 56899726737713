import * as React from 'react';
import { LeftOutlined } from '@ant-design/icons';
import { Link as RUILink, Stack, Text } from '@resi-media/resi-ui';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { S } from './styles';

type _Props = {
  backButtonLink: string;
  header: string;
  subheader?: string;
};

const OnboardingHeader = ({ backButtonLink, header, subheader }: _Props) => {
  const { t } = useTranslation();
  return (
    <Stack alignItems="center" gap="xs">
      <S.ReviewOrderHeaderDiv>
        <S.BackButtonDiv>
          <RUILink as={Link} isLink to={backButtonLink} variant="body3">
            <LeftOutlined />
            {t(`common:back`)}
          </RUILink>
        </S.BackButtonDiv>
        <Text as="h4" textAlign="center">
          {header}
        </Text>
      </S.ReviewOrderHeaderDiv>
      <Text style={{ maxWidth: '75%' }} textAlign="center" variant="body3">
        {subheader}
      </Text>
    </Stack>
  );
};

OnboardingHeader.displayName = 'OnboardingHeader';

export default OnboardingHeader;
