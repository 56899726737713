import { v1 } from './v1';
import { v2 } from './v2';
import { v3 } from './v3';
import { v4 } from './v4';

export const central = {
  v1,
  v2,
  v3,
  v4,
};
