import { HTTP_METHODS } from '@studio/constants/axios';
import { CENTRAL_API_V2 } from '@studio/constants/env-variables';
import type { Encoders } from '@studio/types';

export const root = {
  [HTTP_METHODS.GET]: {
    url: `${CENTRAL_API_V2}/encoders/@id/status`,
    response: {} as Encoders.Get.V2.Status,
    params: {} as { id: string },
    method: HTTP_METHODS.GET,
  },
};

export const id = {};
