import type { Option } from '@resi-media/resi-ui';
import type { Immutable } from 'immer';
import type { ContentDestinations } from '@studio/types';
import { sortByNumber } from '../sortByNumber';

export const formatFrameRate = (frameRate: string): string => {
  if (!frameRate) {
    return '';
  }

  const frameRateValues = frameRate.split('/');
  if (frameRateValues.length !== 2) {
    return '';
  } else {
    if (
      frameRateValues[0] &&
      !isNaN(parseInt(frameRateValues[0])) &&
      frameRateValues[1] &&
      !isNaN(parseInt(frameRateValues[1]))
    ) {
      const frames = parseInt(frameRateValues[0]);
      const frequency = parseInt(frameRateValues[1]);
      const framesPerSecond = frames / frequency;
      const decimal = frames % frequency !== 0;
      return `${decimal ? framesPerSecond.toFixed(2) : framesPerSecond}fps`;
    }
  }
  return '';
};

export const formatBitsPerSecond = (bytes: number, decimals?: number): string => {
  if (bytes === 0) {
    return '0 bps';
  }

  const unitMultiple = 1000;
  const unitNames = ['bps', 'Kbps', 'Mbps', 'Gbps', 'Tbps', 'Pbps'];
  const unitChanges = Math.floor(Math.log(bytes) / Math.log(unitMultiple));
  // force decimal precision to 0 for Kbps and lower
  const precision = unitChanges <= 1 ? 0 : decimals || 0;
  return `${parseFloat((bytes / Math.pow(unitMultiple, unitChanges)).toFixed(precision))} ${unitNames[unitChanges]}`;
};

export const extractAudioTracks = (
  audioAdaptationSets: Immutable<ContentDestinations.Get.AudioAdaptationSet[]>
): Option[] => {
  const adaptationSets: Option[] = [];
  const includeTrackLabel = audioAdaptationSets.length > 1;

  audioAdaptationSets.forEach((adaptation: Immutable<ContentDestinations.Get.AudioAdaptationSet>, index: number) => {
    adaptation.representations.forEach((representation: ContentDestinations.Get.AudioRepresentation) => {
      adaptationSets.push({
        value: representation.id,
        label: includeTrackLabel
          ? `Track ${index + 1}: ${representation.channels} Ch / ${representation.bandwidth}`
          : `${representation.channels} Ch / ${formatBitsPerSecond(representation.bandwidth, 0)}`,
      });
    });
  });
  return adaptationSets;
};

export const extractVideoTracks = (
  videoAdaptationSets: Immutable<ContentDestinations.Get.VideoAdaptationSet[]>
): Option[] => {
  const adaptationSets: Option[] = [];
  const includeTrackLabel = videoAdaptationSets.length > 1;

  videoAdaptationSets.forEach((adaptation: Immutable<ContentDestinations.Get.VideoAdaptationSet>, index: number) => {
    const sortedAdaption = adaptation.representations
      .slice()
      .sort(sortByNumber((item: ContentDestinations.Get.VideoRepresentation) => item.height, true));
    sortedAdaption.forEach((representation: ContentDestinations.Get.VideoRepresentation) => {
      adaptationSets.push({
        value: representation.id,
        label: includeTrackLabel
          ? `Track ${index + 1}: ${representation.width}x${representation.height} ${formatFrameRate(
              representation.frameRate
            )} / ${representation.bandwidth}`
          : `${representation.width}x${representation.height}  ${formatFrameRate(
              representation.frameRate
            )} /  ${formatBitsPerSecond(representation.bandwidth, 1)}`,
      });
    });
  });
  return adaptationSets;
};

// example: "00:01:03.480" to 63480 ms
export const convertTimeStringToMs = (durationString: string): number => {
  const regExTime = /([0-9]?[0-9]):([0-9][0-9]):([0-9][0-9]).([0-9][0-9][0-9])/;
  const regExTimeArr = regExTime.exec(durationString);

  if (regExTimeArr) {
    // Convert HH, MM and SS to milliseconds
    const timeHr = Number(regExTimeArr[1]) * 3600 * 1000;
    const timeMin = Number(regExTimeArr[2]) * 60 * 1000;
    const timeSec = Number(regExTimeArr[3]) * 1000;
    const timeMillisec = Number(regExTimeArr[4]) * 1;
    const timeMs = timeHr + timeMin + timeSec + timeMillisec;
    return timeMs;
  }
  return 0;
};
