import { produce } from 'immer';
import type { ActionType } from 'typesafe-actions';
import { createReducer } from 'typesafe-actions';
import { fetch, set } from './actions';
import type { VenueState } from './types';

type Action = ActionType<typeof fetch | typeof set>;

export const initialVenueState: VenueState = {
  error: null,
  filter: '',
  isFetching: false,
  items: [],
  timestamp: 0,
  venueLicenses: 0,
};

const reducer = createReducer<VenueState, Action>(initialVenueState)
  .handleAction(
    fetch.logins.request,
    produce((draft: VenueState) => {
      draft.isFetching = true;
      draft.error = null;
    })
  )
  .handleAction(
    fetch.logins.success,
    produce((draft: VenueState, action: ActionType<typeof fetch.logins.success>) => {
      draft.isFetching = false;
      draft.items = action.payload.map((item) => ({
        ...item,
        decoder: {
          averageBandwidth: null,
          buffer: null,
          eventId: null,
          eventName: null,
          fkCustomer: '',
          freeHeap: null,
          freeMemory: null,
          lanMode: null,
          lastUpdate: '',
          logEntries: null,
          nics: null,
          playerHardwareDesc: null,
          playerHardwareId: null,
          playerVersion: null,
          position: null,
          sensorData: null,
          serialNumber: null,
          status: null,
          streamUrl: null,
          totalHeap: null,
          totalMemory: null,
          userName: '',
        },
      }));
    })
  )
  .handleAction(
    fetch.logins.failure,
    produce((draft: VenueState, action: ActionType<typeof fetch.logins.failure>) => {
      draft.isFetching = false;
      draft.error = action.payload;
    })
  )
  .handleAction(
    fetch.logins.cancel,
    produce((draft: VenueState) => {
      draft.isFetching = false;
    })
  )
  .handleAction(
    set.filter,
    produce((draft: VenueState, action: ActionType<typeof set.filter>) => {
      draft.filter = action.payload;
    })
  )
  .handleAction(
    set.timestamp,
    produce((draft: VenueState, action: ActionType<typeof set.timestamp>) => {
      draft.timestamp = action.payload;
    })
  )
  .handleAction(
    fetch.licenses.success,
    produce((draft: VenueState, action: ActionType<typeof fetch.licenses.success>) => {
      draft.venueLicenses = action.payload;
    })
  )
  .handleAction(
    fetch.licenses.failure,
    produce((draft: VenueState, action: ActionType<typeof fetch.licenses.failure>) => {
      draft.error = action.payload;
    })
  );

export default reducer;
