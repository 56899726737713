import { produce } from 'immer';
import { match } from 'ts-pattern';
import { EMBED_MODAL_ACTIONS, SHARE_TYPE } from './constants';
import type { EmbedModalAction, EmbedModalState } from './types';

export const initialState: EmbedModalState = {
  isSingleEvent: false,
  shareType: SHARE_TYPE.EMBED,
  startPosition: '00:00',
};

export const embedModalReducer = produce(
  (draft: EmbedModalState, action: EmbedModalAction): EmbedModalState =>
    match<EmbedModalAction, EmbedModalState>(action)
      .with({ type: EMBED_MODAL_ACTIONS.CUE }, ({ payload }) => {
        draft.selectedCueOption = payload;
        return draft;
      })
      .with({ type: EMBED_MODAL_ACTIONS.PLAYER_PROFILE }, ({ payload }) => {
        draft.selectedPlayerProfile = payload;
        return draft;
      })
      .with({ type: EMBED_MODAL_ACTIONS.SHARE_TYPE }, ({ payload }) => {
        draft.shareType = payload;
        return draft;
      })
      .with({ type: EMBED_MODAL_ACTIONS.SINGLE_EVENT }, ({ payload }) => {
        draft.isSingleEvent = payload;
        return draft;
      })
      .with({ type: EMBED_MODAL_ACTIONS.START_POSITION }, ({ payload }) => {
        draft.startPosition = payload;
        return draft;
      })
      .exhaustive()
);
