import { HTTP_METHODS } from '@studio/constants/axios';
import { MEDIA_METADATA_API_V1 } from '@studio/constants/env-variables';
import type { Playlists } from '@studio/types';

export const root = {
  [HTTP_METHODS.PUT]: {
    url: `${MEDIA_METADATA_API_V1}/customers/@customerId/playlists/@playlistId/media/@mediaPlaylistId/order`,
    request: {} as Playlists.Put.PlaylistReorder,
    params: {} as { mediaPlaylistId: string; playlistId: string },
    method: HTTP_METHODS.PUT,
  },
};

export const id = {};
