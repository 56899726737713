import * as React from 'react';
import { Badge, Draft, Inline, Stack, Text } from '@resi-media/resi-ui';
import { useDispatch } from 'react-redux';
import { ErrorBlock } from '@studio/components/ErrorBlock';
import { InvalidNotice } from '@studio/components/InvalidNotice';
import { INTERVAL_MS, MAX_TIMEOUT_COUNT } from '@studio/constants/destination-groups';
import { PUBLISH_STATUS } from '@studio/constants/schedules';
import { SOCIAL_DESTINATION_TYPES } from '@studio/constants/social';
import { getStringForPrivacyOption, getStringForPublishStatus } from '@studio/helpers';
import { useDestinations, usePrefix } from '@studio/hooks';
import { ActionTypes } from '@studio/store/social-media';
import type { Crosspost, DestinationDetails, DestinationGroups, Schedules } from '@studio/types';
import { BroadcastTime } from '../BroadcastTime';

const FacebookSubcomponent = ({
  broadcastFields,
  cachedCrossposts,
  cachedFacebookDestinations,
  crosspostErrors,
  destinationErrors,
  member,
  refetchFacebookDetails,
}: {
  broadcastFields?: DestinationGroups.Derived.BroadcastFields;
  cachedCrossposts?: Map<string, Crosspost[]>;
  cachedFacebookDestinations?: Map<string, DestinationDetails[]>;
  crosspostErrors?: Map<string, unknown>;
  destinationErrors?: Map<string, unknown>;
  member: Schedules.Derived.PartialContentDestinationSchedule;
  refetchFacebookDetails?: () => void;
}) => {
  const { commonT, prefixNS } = usePrefix('pages:', 'destinationGroups');
  const dispatch = useDispatch();
  const { socialMediaOptions, webPresetOptions } = useDestinations();
  const partnerData = member.partnerData;
  const invalidStatus = socialMediaOptions.fb.get(member.channelId || '')?.invalidStatus;
  const facebookDestinations = cachedFacebookDestinations?.get(member.channelId ?? '');
  const destinationId = partnerData?.facebookPartnerData.destinationId;
  const facebookDestinationName = facebookDestinations?.find((dest) => dest.id === destinationId)?.title;

  const crossposts = destinationId && cachedCrossposts?.get(destinationId);
  const crosspostError = crosspostErrors?.get(destinationId ?? '');
  const destinationError = destinationErrors?.get(member.channelId ?? '');
  const selectedCrossposts = crossposts
    ? crossposts.filter((cp) =>
        partnerData.facebookPartnerData.crossposts.some((crossposts) => crossposts.destinationId === cp.destinationId)
      )
    : [];

  const webPreset = webPresetOptions.find((option) => option.value === member.webEncoderProfileId);

  const [timeoutCount, setTimeoutCount] = React.useState(0);
  const pollingRef = React.useRef<NodeJS.Timeout | null>(null);

  const handleGrantAccessPolling = () => {
    pollingRef.current = setInterval(() => {
      dispatch({ type: ActionTypes.FETCH_STATUS_REQUEST });
      setTimeoutCount((prev) => prev + 1);
    }, INTERVAL_MS);
  };

  React.useEffect(() => {
    if (timeoutCount >= MAX_TIMEOUT_COUNT || !invalidStatus) {
      if (pollingRef.current) {
        clearInterval(pollingRef.current);
        refetchFacebookDetails?.();
      }
    }
  }, [invalidStatus, refetchFacebookDetails, timeoutCount]);

  React.useEffect(() => {
    return () => {
      if (pollingRef.current) {
        clearInterval(pollingRef.current);
      }
    };
  }, []);

  return (
    <Stack dataTestId="facebook-subcomponent" gap="l">
      <InvalidNotice
        channelId={member.channelId}
        invalidStatus={invalidStatus}
        onCloseCallback={handleGrantAccessPolling}
      />
      <Inline>
        {member.privacy && (
          <Draft.FormField fieldLabel={commonT('privacy')}>
            <Badge
              colorVariant={['private', 'unlisted'].includes(member.privacy) ? 'warning' : 'primary'}
              dataTestId="privacy-badge"
            >
              {getStringForPrivacyOption(member.privacy)}
            </Badge>
          </Draft.FormField>
        )}
        {member.publishStatus && (
          <Draft.FormField fieldLabel={commonT('publishStatus')}>
            <Badge
              colorVariant={member.publishStatus === PUBLISH_STATUS.UNPUBLISHED ? 'warning' : 'primary'}
              dataTestId="publish-badge"
            >
              {getStringForPublishStatus(member.publishStatus)}
            </Badge>
          </Draft.FormField>
        )}
      </Inline>
      <ErrorBlock customErrorMessage={prefixNS('destinationError')} error={destinationError} />
      {facebookDestinationName && (
        <Draft.FormField fieldLabel={commonT('facebookDestination')}>
          <Text dataTestId="facebook-destination-name" variant="body1">
            {facebookDestinationName}
          </Text>
        </Draft.FormField>
      )}
      <ErrorBlock customErrorMessage={prefixNS('crosspostError')} error={crosspostError} />
      {selectedCrossposts.length > 0 && (
        <Draft.FormField fieldLabel={commonT('crosspostTo')}>
          <Stack dataTestId="crosspost-container">
            {selectedCrossposts.map((crosspost) => (
              <Inline key={crosspost.destinationId} dataTestId="crosspost">
                <Text dataTestId="crosspost-label" truncate>
                  {crosspost.type === SOCIAL_DESTINATION_TYPES.FB_PAGE ? `${commonT('page')}:` : `${commonT('group')}:`}
                </Text>
                <Text truncate>{crosspost.destinationName}</Text>
              </Inline>
            ))}
          </Stack>
        </Draft.FormField>
      )}
      <Draft.FormField fieldLabel={commonT('webPreset')}>
        <Text dataTestId="web-preset-name" variant="body1">
          {webPreset?.label ?? commonT('default')}
        </Text>
      </Draft.FormField>
      <BroadcastTime broadcastFields={broadcastFields} member={member} />
      <Draft.FormField fieldLabel={commonT('facebookVideoTitle')}>
        <Text dataTestId="facebook-video-title" variant="body1">
          {member.title}
        </Text>
      </Draft.FormField>
      <Draft.FormField fieldLabel={commonT('facebookVideoDescription')}>
        <Text dataTestId="facebook-video-description" variant="body1">
          {member.description ?? '-'}
        </Text>
      </Draft.FormField>
    </Stack>
  );
};

FacebookSubcomponent.displayName = 'FacebookSubcomponent';

export default FacebookSubcomponent;
