import { captureMessage } from '@sentry/react';
import type { Immutable } from 'immer';
import { useFlags } from 'launchdarkly-react-client-sdk';

const logAndReport = (toggle: string) => {
  const errorMessage = `Toggle [${toggle}] does not exist`;
  console.warn(errorMessage);
  captureMessage(errorMessage);
};

const useIsLaunchDarklyAuthorized = (requiredToggles: Immutable<string[]>, includesAny = false): boolean => {
  const toggles = useFlags();

  if (requiredToggles.length === 0) {
    return true;
  }

  const predicate = (toggle: string) => {
    const isEnabled = toggles[toggle];
    if (isEnabled == null) {
      logAndReport(toggle);
      return false;
    }
    return isEnabled;
  };

  if (includesAny) {
    return requiredToggles.some(predicate);
  }
  return requiredToggles.every(predicate);
};

export default useIsLaunchDarklyAuthorized;
