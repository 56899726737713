import * as React from 'react';
import { Draft, ListItem } from '@resi-media/resi-ui';
import { usePrefix } from '@studio/hooks';
import { Cues, Library } from '@studio/types';
import type { ActionMenu } from '../ActionMenu';
import { DownloadMediaModal } from '../DownloadMediaModal';

type _Props = ListItem.Props & {
  anchorRef: React.ForwardedRef<ActionMenu.Ref>;
  mediaId: string;
  title: string;
};

const DownloadMediaListItem = ({ anchorRef, mediaId, title, ...otherProps }: _Props) => {
  const { commonT } = usePrefix('pages:', 'library.downloadVideo');
  const { setModal, setModalState } = Draft.ModalContext.useModal();

  const renderDownloadModal = React.useCallback(
    (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
      event.stopPropagation();
      if (anchorRef != null && typeof anchorRef !== 'function') {
        if (anchorRef.current) {
          anchorRef.current.forceClose();
        }
      }
      setModalState({
        uuid: mediaId,
        title,
        eventType: Library.EVENT_TYPE.LIBRARY,
      });
      setModal(() => <DownloadMediaModal type={Cues.CUE_TYPES.SAVED} />, { dataTestId: 'library-download-modal' });
    },
    [anchorRef, setModalState, mediaId, title, setModal]
  );

  return (
    <ListItem onClick={renderDownloadModal} {...otherProps}>
      {commonT('download')}
    </ListItem>
  );
};

DownloadMediaListItem.displayName = 'DownloadMediaListItem';

export default DownloadMediaListItem;
