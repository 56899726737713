import { HTTP_METHODS } from '@studio/constants/axios';
import { CENTRAL_API_V3 } from '@studio/constants/env-variables';
import type { Library } from '@studio/types';

export const root = {
  [HTTP_METHODS.POST]: {
    url: `${CENTRAL_API_V3}/customers/@customerId/library/@mediaId/download`,
    params: {} as { mediaId: string },
    request: {} as Library.Post.Download,
    response: {} as Library.Post.Response.Download,
    method: HTTP_METHODS.POST,
  },
};

export const id = {};
