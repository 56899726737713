export default function buildQueryParams(paramsObj: object | null, nesting = ''): string {
  if (!paramsObj) {
    return '';
  }

  const pairs = Object.entries(paramsObj).map(([key, val]) => {
    if (val === undefined) {
      return;
    } else if (Array.isArray(val)) {
      return val.map((subVal) => [nesting + key, encodeURIComponent(subVal)].join('=')).join('&');
    } else if (typeof val === 'object') {
      return buildQueryParams(val, nesting + `${key}.`);
    } else {
      return [nesting + key, encodeURIComponent(val)].join('=');
    }
  });

  return pairs.filter((pair) => pair).join('&');
}
