import type { Option } from '@resi-media/resi-ui';
import type { CombinedState } from 'redux';
import { createSelector } from 'reselect';
import type { RootState } from '../root-reducer';
import type { Timezone, TimezonesInitialState } from './types';

export const selectTimezonesState = (state: RootState): CombinedState<TimezonesInitialState> => state.timezones;

export const selectItems = createSelector(
  selectTimezonesState,
  (state: TimezonesInitialState): Timezone[] => state.items
);

export const selectTimezonesAsOptions = createSelector(
  selectTimezonesState,
  (state: TimezonesInitialState): Option[] => {
    return state.items.map(({ name, offset }) => ({
      label: `(GMT${offset}) ${name}`,
      value: name,
    }));
  }
);
