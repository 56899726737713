import { Inline, useBreakpoint } from '@resi-media/resi-ui';

type _Props = Inline.Props & {
  breakpoint?: string;
};

const InlineToFragment = ({ breakpoint = 'lg', dataTestId, ...rest }: _Props) => {
  const mediaQuery = useBreakpoint();
  return mediaQuery[breakpoint] ? <Inline dataTestId={dataTestId} {...rest} /> : <>{rest.children}</>;
};

InlineToFragment.displayName = 'InlineToFragment';

export default InlineToFragment;
