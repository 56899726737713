import { HTTP_METHODS } from '@studio/constants/axios';
import { DATA_USAGE_API_V1 } from '@studio/constants/env-variables';
import type { Analytics } from '@studio/types';

export const root = {
  [HTTP_METHODS.GET]: {
    method: HTTP_METHODS.GET,
    response: {} as Analytics.Get.AvgMonthlyDataUsage,
    url: `${DATA_USAGE_API_V1}/customers/@customerId/average/statistics`,
  },
};
