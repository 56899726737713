import type { ContentDestinations } from '@studio/types';

export interface ContentDestinationsState {
  error: Error | null;
  isFetching: boolean;
  isFetchingInitial: boolean;
  items: ContentDestinations.Get.ContentDestination[];
  sortBy: string;
  stopped: string[]; // video IDs that have been manually stopped by the user
}

export enum SortOptions {
  MOST_RECENT = 'mostRecent',
  NAME_AtoZ = 'alphabetical',
  NAME_ZtoA = 'alphaReverse',
  OLDEST = 'oldest',
}

export enum WebVideoStatus {
  ABORTED = 'aborted',
  ERROR = 'error',
  IDLE = 'idle',
  STARTED = 'started',
  STARTING = 'starting',
  STOPPED = 'stopped',
  STOPPING = 'stopping',
  WARNING = 'warning',
}

export interface TransitioningContentDestinationFetchOptions {
  items?: string[];
}

export enum ActionTypes {
  ADD_STOPPED_ITEM = '@@contentdestinations/ADD_STOPPED_ITEM',
  DELETE_ITEM = '@@contentdestinations/DELETE_ITEM',
  FETCH_REQUEST = '@@contentdestinations/FETCH_REQUEST',
  FETCH_REQUEST_CANCEL = '@@contentdestinations/FETCH_REQUEST_CANCEL',
  FETCH_REQUEST_ERROR = '@@contentdestinations/FETCH_REQUEST_ERROR',
  FETCH_REQUEST_SUCCESS = '@@contentdestinations/FETCH_REQUEST_SUCCESS',
  FETCH_REQUEST_TIMESTAMP = '@@contentdestinations/FETCH_REQUEST_TIMESTAMP',
  FETCH_REQUEST_TRANSITIONING_ONLY = '@@contentdestinations/FETCH_REQUEST_TRANSITIONING_ONLY',
  FETCH_REQUEST_TRANSITIONING_ONLY_CANCEL = '@@contentdestinations/FETCH_REQUEST_TRANSITIONING_ONLY_CANCEL',
  FETCH_REQUEST_TRANSITIONING_ONLY_ERROR = '@@contentdestinations/FETCH_REQUEST_TRANSITIONING_ONLY_ERROR',
  FETCH_REQUEST_TRANSITIONING_ONLY_SUCCESS = '@@contentdestinations/FETCH_REQUEST_TRANSITIONING_ONLY_SUCCESS',
  SORT_RESULTS = '@@contentdestinations/SORT_RESULTS',
  UPDATE_SINGLE_ITEM = '@@contentdestinations/UPDATE_SINGLE_ITEM',
}
