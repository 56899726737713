import type { AllEffect, ForkEffect } from 'redux-saga/effects';
import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';
import { client } from '@studio/api/api-client/client';
import type { ApiClientConfig } from '@studio/types/axios';
import { selectAccessToken } from '../../authentication/selectors';
import { fetch } from './actions';
import { getOrgSearchUrl, getContactSearchUrl } from './helpers';
import { selectFilter, selectSearchBy } from './selectors';
import type { Organization, SearchBy, Contact } from './types';
import { ActionTypes, SEARCH_BY } from './types';

function* handleFetchOrgs(action: ReturnType<typeof fetch.orgs.request>) {
  if (action.payload.length < 3) {
    yield put(fetch.orgs.cancel());
    return;
  }

  const url = getOrgSearchUrl(action.payload);

  const token: string = yield select(selectAccessToken);
  const config: ApiClientConfig = { token };
  try {
    const response: Organization | Organization[] = yield call(client, url, config);
    // `${CENTRAL_API_V2}/customers/masterecg/${searchString}` returns {} instead of []
    const formattedResponse = Array.isArray(response) ? response : [response];

    yield put(fetch.orgs.success(formattedResponse));
  } catch (error) {
    if (error instanceof Error) {
      yield put(fetch.orgs.failure(error));
    }
  }
}

function* handleFetchContacts(action: ReturnType<typeof fetch.contacts.request>) {
  if (action.payload.length < 3) {
    yield put(fetch.contacts.cancel());
    return;
  }

  const searchBy: SearchBy = yield select(selectSearchBy);
  const url = getContactSearchUrl(searchBy, action.payload);

  const token: string = yield select(selectAccessToken);
  const config: ApiClientConfig = { token };
  try {
    const response: Contact[] = yield call(client, url, config);
    yield put(fetch.contacts.success(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(fetch.contacts.failure(error));
    }
  }
}

function* handleSearch() {
  const searchCriteria: string = yield select(selectFilter);
  const searchBy: SearchBy = yield select(selectSearchBy);

  switch (searchBy) {
    case SEARCH_BY.USER_NAME:
      yield put(fetch.contacts.request(searchCriteria));
      break;
    case SEARCH_BY.PHONE_NUMBER:
      yield put(fetch.contacts.request(searchCriteria));
      break;
    default:
      yield put(fetch.orgs.request(searchCriteria));
  }
}

export function* watchSearch<T>(): Generator<ForkEffect<never>, void, T> {
  yield takeLatest(ActionTypes.SEARCH, handleSearch);
}

export function* watchFetchRequestOrgs<T>(): Generator<ForkEffect<never>, void, T> {
  yield takeLatest(ActionTypes.FETCH_REQUEST_ORGS, handleFetchOrgs);
}

export function* watchFetchRequestContacts<T>(): Generator<ForkEffect<never>, void, T> {
  yield takeLatest(ActionTypes.FETCH_REQUEST_CONTACTS, handleFetchContacts);
}

export function* searchSaga<T>(): Generator<AllEffect<ForkEffect<void>>, void, T> {
  yield all([fork(watchFetchRequestOrgs), fork(watchFetchRequestContacts), fork(watchSearch)]);
}
