import { HTTP_METHODS } from '@studio/constants/axios';
import { MEDIA_METADATA_API_V1 } from '@studio/constants/env-variables';
import type { Sites } from '@studio/types';

export const root = {
  [HTTP_METHODS.GET]: {
    url: `${MEDIA_METADATA_API_V1}/customers/@customerId/sites`,
    response: [] as Sites.Get.Sites[],
    query: {} as { url?: string },
    method: HTTP_METHODS.GET,
  },
  [HTTP_METHODS.POST]: {
    url: `${MEDIA_METADATA_API_V1}/customers/@customerId/sites`,
    request: {} as Sites.Post.Site,
    method: HTTP_METHODS.POST,
  },
};

export const id = {
  [HTTP_METHODS.DELETE]: {
    method: HTTP_METHODS.DELETE,
    params: {} as { id: string },
    url: `${MEDIA_METADATA_API_V1}/customers/@customerId/sites/@id`,
  },
  [HTTP_METHODS.GET]: {
    url: `${MEDIA_METADATA_API_V1}/customers/@customerId/sites/@id`,
    response: {} as Sites.Get.Site,
    params: {} as { id: string },
    method: HTTP_METHODS.GET,
  },
  [HTTP_METHODS.PUT]: {
    url: `${MEDIA_METADATA_API_V1}/customers/@customerId/sites/@id`,
    request: {} as Sites.Put.Site,
    params: {} as { id: string },
    method: HTTP_METHODS.PUT,
  },
};
