import * as React from 'react';
import { css } from '@emotion/react';
import { Draft, Inline, Progress, Stack, Text } from '@resi-media/resi-ui';
import { usePrefix } from '@studio/hooks';
import { FileDropzone } from '../FileDropzone';
import type { CropArea } from '../ImageCropper';
import { ImageCropper } from '../ImageCropper';

export type CropAndUploadProps = {
  errorMessage?: string;
  fileSrc?: string;
  isLoading: boolean;
  onCancel: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onCropComplete: (croppedAreaPixels: CropArea) => void;
  onFileUpload: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onFilesAdded: (files: File[]) => void;
  showFileDropzone: boolean;
};

const CropAndUpload = ({
  errorMessage,
  fileSrc,
  isLoading,
  onCancel,
  onCropComplete,
  onFilesAdded,
  onFileUpload,
  showFileDropzone,
}: CropAndUploadProps) => {
  const { commonT, prefixNS } = usePrefix('components:', 'cropAndUpload');

  if (showFileDropzone) {
    return (
      <Stack dataTestId="crop-and-upload" gap="m">
        {errorMessage && <Draft.AlertBanner dataTestId="crop-and-upload-error">{errorMessage}</Draft.AlertBanner>}
        <Stack gap="xl">
          {fileSrc ? (
            <Stack>
              <div
                css={css`
                  position: relative;
                  width: 100%;
                  height: 400px;
                `}
              >
                <ImageCropper aspect={16 / 9} fileSrc={fileSrc} onCropComplete={onCropComplete} />
              </div>
              <Text colorVariant="secondary" isUppercase textAlign="center" variant="body4" weightVariant="bold">
                {prefixNS('imagePreview')}
              </Text>
            </Stack>
          ) : (
            <Stack>
              <FileDropzone accept="image/jpeg, image/png" isLoading={isLoading} onFilesAdded={onFilesAdded} />
              <Text colorVariant="secondary" isUppercase textAlign="center" variant="body4" weightVariant="bold">
                {prefixNS('fileRequirements')}
              </Text>
            </Stack>
          )}
          <Inline justifyContent="flex-end">
            <Draft.Button label={commonT('cancel')} onClick={onCancel} variant="text" />
            <Draft.Button
              disabled={isLoading || !fileSrc}
              label={commonT('upload')}
              onClick={onFileUpload}
              {...(isLoading && {
                startNode: <Progress colorVariant="inherit" sizeVariant="inherit" />,
              })}
            />
          </Inline>
        </Stack>
      </Stack>
    );
  }

  return null;
};

CropAndUpload.displayName = 'DisplayName';

export default CropAndUpload;
