export class TrackUserProp {
  static readonly USER_NAME = 'username';
  static readonly USER_PERMISSIONS = 'user_permissions';
  static readonly USER_UUID = 'userId';
}

export class TrackOrgProp {
  static readonly ORG_NAME = 'org_name';
  static readonly ORG_UUID = 'org_uuid';
}

export class TrackEventName {
  static readonly ACTIVE_CONTENT_REMOVE = 'Active Content Remove';
  static readonly BILLING_PORTAL_VIEW = 'Billing Portal View';
  static readonly CLIPBOARD_COPY = 'Clipboard Copy';
  static readonly DESTINATION_GROUP_ADD = 'Destination Group Add';
  static readonly DESTINATION_GROUP_DELETE = 'Destination Group Delete';
  static readonly DESTINATION_GROUP_EDIT = 'Destination Group Edit';
  static readonly ENCODER_ADD_CANCEL = 'Encoder Add Cancel';
  static readonly ENCODER_ADD_COMPLETE = 'Encoder Add Complete';
  static readonly ENCODER_CHANNEL_ADD = 'Encoder Channel Add';
  static readonly ENCODER_CHANNEL_DELETE = 'Encoder Channel Delete';
  static readonly ENCODER_CHANNEL_EDIT = 'Encoder Channel Edit';
  static readonly ENCODER_DELETE = 'Encoder Delete';
  static readonly ENCODER_EDIT = 'Encoder Edit';
  static readonly ENCODER_PRESET_ADD = 'Encoder Preset Add';
  static readonly ENCODER_PRESET_DELETE = 'Encoder Preset Delete';
  static readonly ENCODER_PRESET_EDIT = 'Encoder Preset Edit';
  static readonly ENCODER_RESTART = 'Encoder Restart';
  static readonly ENCODER_START = 'Encoder Start';
  static readonly ENCODER_STOP = 'Encoder Stop';
  static readonly EVENT_DOWNLOAD = 'Event Download';
  static readonly LIBRARY_SAVE_COMPLETE = 'Library Save Complete';
  static readonly PASSWORD_CHANGE = 'Password Change';
  static readonly PASSWORD_SET = 'Password Set';
  static readonly PLAYER_CONFIG_ADD = 'Player Config Add';
  static readonly PLAYER_CONFIG_EDIT = 'Player Config Edit';
  static readonly SOCIAL_DESTINATION_ADD = 'Social Destination Add';
  static readonly SOCIAL_DESTINATION_DELETE = 'Social Destination Delete';
  static readonly SOCIAL_DESTINATION_EDIT = 'Social Destination Edit';
  static readonly SOCIAL_DESTINATION_EXTEND = 'Social Destination Extend';
  static readonly SOCIAL_DESTINATION_GRANT = 'Social Destination Grant';
  static readonly USER_EDIT = 'User Edit';
  static readonly USER_INVITE_RESEND = 'User Invite Resend';
  static readonly USER_INVITE_REVOKE = 'User Invite Revoke';
  static readonly USER_INVITE_SEND = 'User Invite Send';
  static readonly USER_LOGIN = 'User Login';
  static readonly USER_LOGOUT = 'User Logout';
  static readonly USER_REMOVE = 'User Remove';
  static readonly VENUE_CREATE = 'Venue Add';
  static readonly VENUE_DELETE = 'Venue Delete';
  static readonly VENUE_EDIT = 'Venue Edit';
  static readonly VIDEO_UPLOAD_ANALYZE_RESULTS = 'Video Upload Analysis Results';
  static readonly VIDEO_UPLOAD_COMPLETE = 'Video Upload Complete';
  static readonly VIDEO_UPLOAD_START = 'Video Upload Start';
  static readonly SAVE_VIDEO_START = 'Save Video Start';
  static readonly SAVE_TO_LIBRARY_START = 'Save to Library Start';
  static readonly SAVED_VIDEO_DOWNLOAD = 'Saved Video Download';
  static readonly SAVED_VIDEO_REMOVE = 'Saved Video Remove';
  static readonly SAVED_VIDEO_SORT_BY = 'Saved Video Sort By';
  static readonly WEB_CHANNEL_ADD = 'Web Channel Add';
  static readonly WEB_CHANNEL_DELETE = 'Web Channel Delete';
  static readonly WEB_CHANNEL_EDIT = 'Web Channel Edit';
  static readonly WEB_PRESET_ADD = 'Web Preset Add';
  static readonly WEB_PRESET_DELETE = 'Web Preset Delete';
  static readonly WEB_PRESET_EDIT = 'Web Preset Edit';
}

export class TrackEventProp {
  static readonly ACCESS_EXPIRES = 'access_expires';
  static readonly ACCOUNT_MODIFIED = 'account_modified';
  static readonly ACCOUNT_MODIFIED_NAME = 'account_modified_name';
  static readonly ACCOUNT_MODIFIED_UUID = 'account_modified_uuid';
  static readonly ACCOUNT_STATUS = 'account_status';
  static readonly ACTIVATION_CODE = 'activation_code';
  static readonly AUDIO_BITRATE_KBPS = 'audio_bitrate_kbps';
  static readonly AUDIO_CHANNEL_LEFT = 'audio_channel_left';
  static readonly AUDIO_CHANNEL_RIGHT = 'audio_channel_right';
  static readonly AUDIO_CHANNELS = 'audio_channels';
  static readonly AVAILABLE_IN_PROPRESENTER = 'available_in_propresenter';
  static readonly BILLING = 'billing';
  static readonly CODEC = 'codec';
  static readonly COPIED = 'copied';
  static readonly COPY_TYPE = 'copy_type';
  static readonly DEINTERLACE = 'deinterlace';
  static readonly DELETE_AFTER = 'delete_after';
  static readonly DESTINATION_GROUP_NAME = 'destination_group_name';
  static readonly DESTINATION_GROUP_UUID = 'destination_group_uuid';
  static readonly EMAIL_INVITED = 'email_invited';
  static readonly ENCODER_CHANNEL_NAME = 'encoder_channel_name';
  static readonly ENCODER_CHANNEL_UUID = 'encoder_channel_uuid';
  static readonly ENCODER_CHANNEL_VENUES = 'encoder_channel_venues';
  static readonly ENCODER_CHANNEL_USERS = 'encoder_channel_users';
  static readonly ENCODER_MODEL = 'encoder_model';
  static readonly ENCODER_NAME = 'encoder_name';
  static readonly ENCODER_PRESET_NAME = 'encoder_preset_name';
  static readonly ENCODER_PRESET_UUID = 'encoder_preset_uuid';
  static readonly ENCODER_SERIAL = 'encoder_serial';
  static readonly ENCODER_UUID = 'encoder_uuid';
  static readonly ENCODER_VENUE_CHANNELS = 'encoder_venue_channels';
  static readonly ENCODER_VERSION = 'encoder_version';
  static readonly ENCODER_VIDEO_CHANNEL = 'encoder_video_channel';
  static readonly ENCODER_VIDEO_INPUT_SOURCE = 'encoder_video_input_source';
  static readonly ERROR = 'error';
  static readonly FACEBOOK_CROSSPOSTS = 'facebook_crossposts';
  static readonly FACEBOOK_DESTINATION = 'facebook_destination';
  static readonly FILE_NAME = 'file_name';
  static readonly FILE_SIZE = 'file_size';
  static readonly FIRST_NAME = 'first_name';
  static readonly HARDWARE_ACCELERATION = 'hardware_acceleration';
  static readonly INPUT_FORMAT = 'input_format';
  static readonly LAST_NAME = 'last_name';
  static readonly LICENSE_TYPE = 'license_type';
  static readonly NETWORK_ALERTS = 'network_alerts';
  static readonly OUTPUT_FORMAT = 'output_format';
  static readonly PHONE = 'phone';
  static readonly PLAYER_CONFIG_TYPE = 'player_config_type';
  static readonly PLAYER_CTA_1_ENABLED = 'player_cta_1_enabled';
  static readonly PLAYER_CTA_1_LABEL = 'player_cta_1_label';
  static readonly PLAYER_CTA_1_URL = 'player_cta_1_url';
  static readonly PRIVACY = 'privacy';
  static readonly PUBLISH_STATUS = 'publish_status';
  static readonly REGION_NAME = 'region_name';
  static readonly REGION_RESPONSE_TIME = 'region_response_time';
  static readonly RESI_LOGO_ENABLED = 'resi_logo_enabled';
  static readonly SAVED_VIDEO_UUID = 'saved_video_uuid';
  static readonly SOCIAL_ACCOUNT_NAME = 'social_account_name';
  static readonly SOCIAL_ACCOUNT_TYPE = 'social_account_type';
  static readonly SORT_BY = 'sort_by';
  static readonly STREAM_URLS_ENABLED = 'stream_urls_enabled';
  static readonly STUDIO_VERSION = 'studio_version';
  static readonly SUBTITLES_ENABLED = 'subtitles_enabled';
  static readonly SUCCESS = 'success';
  static readonly TITLE = 'title';
  static readonly TRANSCODED_EVENT_UUID = 'transcoded_event_uuid';
  static readonly USER_EMAIL_INVITED = 'user_email_invited';
  static readonly USER_ROLES = 'user_roles';
  static readonly VIDEO_BITRATE_MBPS = 'video_bitrate_mbps';
  static readonly VIDEO_CHANNELS = 'video_channels';
  static readonly VIDEO_OFFSET = 'video_offset';
  static readonly VIDEO_UPLOAD_AUDIO_BITRATE_KBPS = 'audio_bitrate_kbps';
  static readonly VIDEO_UPLOAD_AUDIO_SAMPLE_RATE_KHZ = 'audio_sample_rate_khz';
  static readonly VIDEO_UPLOAD_AUDIO_CHANNELS = 'audio_channels';
  static readonly VIDEO_UPLOAD_ERROR_CODES = 'error_codes';
  static readonly VIDEO_UPLOAD_FRAMERATE = 'framerate';
  static readonly VIDEO_UPLOAD_GOP_DURATION = 'gop_duration';
  static readonly VIDEO_UPLOAD_KEYFRAME_MBPS = 'keyframe_mbps';
  static readonly VIDEO_UPLOAD_RESOLUTION_HEIGHT = 'resolution_height';
  static readonly VIDEO_UPLOAD_RESOLUTION_WIDTH = 'resolution_width';
  static readonly VIDEO_UPLOAD_SAMPLE_DURATION = 'sample_duration';
  static readonly VIDEO_UPLOAD_TIMESCALE = 'timescale';
  static readonly VIDEO_UPLOAD_VIDEO_BITRATE_MBPS = 'video_bitrate_mpbs';
  static readonly VIDEO_UPLOAD_VIDEO_CHANNELS = 'video_channels';
  static readonly WEB_CHANNEL_NAME = 'web_channel_name';
  static readonly WEB_CHANNEL_TYPE = 'web_channel_type';
  static readonly WEB_CHANNEL_UUID = 'web_channel_uuid';
  static readonly WEB_EVENT_DELAY = 'web_event_delay';
  static readonly WEB_PRESET_NAME = 'web_preset_name';
  static readonly WEB_PRESET_UUID = 'web_preset_uuid';
  static readonly WEB_VIDEO_UUID = 'web_video_uuid';
  static readonly WEBPLAN_NAME = 'webplan_name';
  static readonly YOUTUBE_DESTINATION = 'youtube_destination';
}

export class TrackEventValue {
  static readonly PLAYER_CONFIG_LIBRARY = 'library';
  static readonly PLAYER_CONFIG_CHANNEL = 'channel';
}
