import * as React from 'react';
import { css } from '@emotion/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Draft, Progress, isEmpty, useBreakpoint } from '@resi-media/resi-ui';
import { useForm, FormProvider } from 'react-hook-form';
import { useCues, usePlayer } from '@studio/contexts';
import { formatSecondsToTimeString } from '@studio/helpers/formatSecondsToTimeString';
import { usePrefix } from '@studio/hooks';
import { Cues } from '@studio/types';
import { useFormValidation } from '../../helpers';
import { S } from '../../styles';
import { CueFields } from '../CueFields';

/* Requires being nested in the PlayerProvider to get currentTime */
const CueCreate = () => {
  const { canShareCues, postCue } = useCues();
  const mediaQuery = useBreakpoint();
  const { currentTime } = usePlayer();
  const { commonT } = usePrefix('components:', 'cuesTimeline');

  const methods = useForm<Cues.Components.FormState>({
    mode: 'all',
    defaultValues: {
      name: '',
      position: formatSecondsToTimeString(currentTime),
      visibility: canShareCues ? Cues.CUE_VISIBILITY.SHARED : Cues.CUE_VISIBILITY.PRIVATE,
    },
    resolver: yupResolver(useFormValidation()),
  });
  const { formState, getValues } = methods;
  const { errors, isSubmitting: isFormSubmitting } = formState;

  const handleSubmit = React.useCallback(() => {
    if (!isEmpty(errors)) return;

    postCue.callApi(getValues());
  }, [errors, getValues, postCue]);

  const submitNode = React.useMemo(() => {
    return (
      <Draft.Button
        data-testid="cue-create__btn--submit"
        disabled={postCue.isLoading || isFormSubmitting}
        form="cue-create__form"
        isFullWidth={!mediaQuery.lg}
        label={commonT('save')}
        onClick={handleSubmit}
        type="button"
        {...(postCue.isLoading && {
          startNode: <Progress colorVariant="inherit" data-testid="cue-create__progress" sizeVariant="inherit" />,
        })}
      />
    );
  }, [commonT, handleSubmit, isFormSubmitting, mediaQuery.lg, postCue.isLoading]);

  return (
    <FormProvider {...methods}>
      <div
        css={(theme) => css([S.segment(theme), S.segmentCreate(theme), S.segmentHighlighted(theme)])}
        data-testid="cue-create__form"
        id="cue-create__form"
        // Must be a next role form until Events is refactored to not have nested forms
        // src/pages/Events/pages/ViewEditPane/view-edit-pane.tsx
        role="form"
      >
        <CueFields onSubmitForm={handleSubmit} submitNode={submitNode} />
      </div>
    </FormProvider>
  );
};

CueCreate.displayName = 'CueCreate';

export default CueCreate;
