import type { WebPresets } from '@studio/types';

export enum WebPresetActionTypes {
  FETCH_COMPLETE = '@@encoderchannels/FETCH_COMPLETE',
  FETCH_REQUEST = '@@webpresets/FETCH_REQUEST',
  FETCH_REQUEST_CANCEL = '@@webpresets/FETCH_REQUEST_CANCEL',
  FETCH_REQUEST_ERROR = '@@webpresets/FETCH_REQUEST_ERROR',
  FETCH_REQUEST_SUCCESS = '@@webpresets/FETCH_REQUEST_SUCCESS',
  FETCH_REQUEST_TIMESTAMP = '@@webpresets/FETCH_REQUEST_TIMESTAMP',
  FILTER_RESULTS = '@@encoderchannels/FILTER_RESULTS',
}

export interface WebPresetState {
  error: Error | null;
  filter: string;
  isFetching: boolean;
  items: WebPresets.Get.WebPreset[];
  timestamp: number;
}
