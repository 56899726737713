import { HTTP_METHODS } from '@studio/constants/axios';
import { CENTRAL_API_V3 } from '@studio/constants/env-variables';
import type { Cues } from '@studio/types';

export const root = {
  [HTTP_METHODS.GET]: {
    url: `${CENTRAL_API_V3}/customers/@customerId/contentdestinations/@mediaId/cues`,
    response: [] as Cues.Get.DestinationCue[],
    params: {} as { mediaId: string },
    query: {} as {
      canISetCues?: number;
    },
    method: HTTP_METHODS.GET,
  },
  [HTTP_METHODS.POST]: {
    url: `${CENTRAL_API_V3}/customers/@customerId/contentdestinations/@mediaId/cues`,
    params: {} as { mediaId: string },
    request: {} as Cues.Post.DestinationCue,
    method: HTTP_METHODS.POST,
  },
};

export const id = {
  [HTTP_METHODS.PATCH]: {
    url: `${CENTRAL_API_V3}/customers/@customerId/contentdestinations/@mediaId/cues/@id`,
    params: {} as { id: string; mediaId: string },
    request: {} as Cues.Patch.DestinationCue,
    method: HTTP_METHODS.PATCH,
  },
  [HTTP_METHODS.DELETE]: {
    url: `${CENTRAL_API_V3}/customers/@customerId/contentdestinations/@mediaId/cues/@id`,
    params: {} as { id: string; mediaId: string },
    method: HTTP_METHODS.DELETE,
  },
};
