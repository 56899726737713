import * as React from 'react';

const useIsOverflow = (containerEntry?: ResizeObserverEntry, contentEntry?: ResizeObserverEntry): boolean => {
  const [isOverflow, setIsOverflow] = React.useState(false);
  React.useEffect(() => {
    if (containerEntry && contentEntry) {
      const contentHeight = contentEntry.contentRect.height;
      const containerHeight = containerEntry.contentRect.height;
      setIsOverflow(contentHeight > containerHeight);
    }
  }, [containerEntry, contentEntry]);

  return isOverflow;
};

export default useIsOverflow;
