/**
 * Meant to be used to easily replicate KeyDown Handlers with existing MouseEvent Handlers
 * If using event in return - need to use the following type: React.KeyboardEvent<any> | React.MouseEvent<any>
 * Use of this hook with non-keyboard events will result in a console error as the event.key needs to be accessible
 *
 * Example: handleClick = (e: React.KeyboardEvent<any> | React.MouseEvent<any>) => something;
 *          handleKeyDown = useKeyDown(['Enter', ' '], handleClick);
 *
 */
/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';

type EventObject = React.KeyboardEvent<any> | React.MouseEvent<any>;
type Options = any[];
type KeyHandlers =
  | ((e: EventObject, options?: any | unknown) => void)
  | { [key: string]: (e?: EventObject, options?: any | unknown) => void };
type KeyOptions = string[] | string;

export const runKeyHandler = (event: EventObject, keys: KeyOptions, keyHandlers: KeyHandlers, opts: Options): void => {
  if ('key' in event) {
    const handler = keyHandlers instanceof Function ? keyHandlers : keyHandlers[event.key];

    handler(event, ...opts);
    event.preventDefault();
  } else {
    console.error('[hooks/use-key-down]: must be used with keyboard events only');
    return;
  }
};

const useKeyDownHandler = (
  keys: KeyOptions,
  keyHandlers: KeyHandlers
): ((event: EventObject, ...opts: Options) => void) => {
  const keyHandlersRef = React.useRef(keyHandlers);
  keyHandlersRef.current = keyHandlers;

  return React.useCallback(
    (event: EventObject, ...opts: Options) => {
      runKeyHandler(event, keys, keyHandlersRef.current, opts);
    },
    [keys]
  );
};

export default useKeyDownHandler;
