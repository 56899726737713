import { LinearGradient } from '../LinearGradient';

const LoadingUsageCard = () => {
  return (
    <div style={{ marginTop: '2rem' }}>
      <svg>
        <LinearGradient data-testid="loader" id="data-card-loading" />
        <defs>
          <clipPath id="data-card-clip-path">
            <rect height="16" width="30%" />
            <rect height="26" width="20%" y="32" />
            <rect height="40" width="100%" y="75" />
            <rect height="22" width="25%" y="130" />
          </clipPath>
        </defs>
        <rect
          clipPath="url(#data-card-clip-path)"
          fill="url(#data-card-loading)"
          height="100%"
          width="100%"
          x={0}
          y={0}
        />
      </svg>
    </div>
  );
};

LoadingUsageCard.displayName = 'LoadingUsageCard';

export default LoadingUsageCard;
