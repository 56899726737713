type LinkHeader = {
  [key: string]: string;
};

const parseHeaderLink = (headerLinks?: string): LinkHeader => {
  let links: LinkHeader = {};

  if (headerLinks) {
    const linkRegex = /<([^>]+)/g;
    const relRegex = /rel="([^"]+)/g;
    const linkArray: string[] = [];
    const relArray: string[] = [];
    let temp: RegExpExecArray | null;

    while ((temp = linkRegex.exec(headerLinks)) !== null) {
      linkArray.push(temp[1]);
    }
    while ((temp = relRegex.exec(headerLinks)) !== null) {
      relArray.push(temp[1]);
    }

    links = relArray.reduce<LinkHeader>((object, value, index) => {
      object[value] = linkArray[index];
      return object;
    }, {});
  }

  return links;
};

export default parseHeaderLink;
