import { isStringOnlyNumbers } from '../is-string-only-numbers';

const isValidEcgString = (str: string): boolean => {
  if (str.startsWith('ECG-') || str.startsWith('ecg-')) {
    return isStringOnlyNumbers(str.slice(4));
  }
  return false;
};

export default isValidEcgString;
