import type { CombinedState } from 'redux';
import { createSelector } from 'reselect';
import type { SystemEvents } from '@studio/types';
import type { RootState } from '../root-reducer';
import type { SystemEventsState } from './types';

export const selectSystemEventsState = (state: RootState): CombinedState<SystemEventsState> => state.systemEvents;

const subjectEvents = {
  data: createSelector(
    selectSystemEventsState,
    (state): Map<string, SystemEvents.Get.Event[]> => state.subjectEvents.data
  ),
  hasFetched: createSelector(selectSystemEventsState, (state) => state.subjectEvents.hasFetched),
  isFetching: createSelector(selectSystemEventsState, (state) => state.subjectEvents.isFetching),
  error: createSelector(selectSystemEventsState, (state) => state.subjectEvents.error),
  bySubjectId: createSelector(
    (state: RootState) => state.systemEvents.subjectEvents.data,
    (_: unknown, subjectId: string) => subjectId,
    (events, subId) => {
      return events.get(subId);
    }
  ),
};

export const select = {
  subjectEvents,
};
