import * as React from 'react';

const useIsMounted = (): (() => boolean) => {
  const mountedRef = React.useRef(false);

  React.useEffect(() => {
    mountedRef.current = true;

    return () => {
      mountedRef.current = false;
    };
  }, []);

  return React.useCallback(() => mountedRef.current, []);
};

export default useIsMounted;
