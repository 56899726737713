import styled from '@emotion/styled';

export const S = {
  BackButtonDiv: styled.div`
    ${({ theme }) => `
    ${theme.mq.lg} {
      position: absolute;
      left: 0px;
    }
    `}
  `,
  ReviewOrderHeaderDiv: styled.div`
    ${({ theme }) => `
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;

  ${theme.mq.lg} {
    flex-direction: row;
    align-items: center;
  }
  `}
  `,
};
