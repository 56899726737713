import type { WebChannels } from '@studio/types';

export enum ActionTypes {
  FETCH_REQUEST = '@@webchannels/FETCH_REQUEST',
  FETCH_REQUEST_CANCEL = '@@webchannels/FETCH_REQUEST_CANCEL',
  FETCH_REQUEST_ERROR = '@@webchannels/FETCH_REQUEST_ERROR',
  FETCH_REQUEST_SUCCESS = '@@webchannels/FETCH_REQUEST_SUCCESS',
  FETCH_REQUEST_TIMESTAMP = '@@webchannels/FETCH_REQUEST_TIMESTAMP',
  FILTER_RESULTS = '@@webchannels/FILTER_RESULTS',
}

export interface WebChannelsState {
  error: Error | null;
  filter: string;
  isFetching: boolean;
  items: WebChannels.Get.WebChannel[];
  timestamp: number;
}
