import type { Option } from '@resi-media/resi-ui';
import { P, match } from 'ts-pattern';
import { DESTINATION } from '@studio/constants/content-destination';
import type { SocialMediaAccountOptions } from '@studio/store/social-media';
import type { DestinationGroups } from '@studio/types';

const getDestinationMemberAccountName = ({
  member,
  rtmpOptions,
  socialMediaOptions,
  webChannelOptions,
}: {
  member: DestinationGroups.Post.Member;
  rtmpOptions?: Option[];
  socialMediaOptions?: Record<'fb' | 'yt', Map<string, SocialMediaAccountOptions>>;
  webChannelOptions?: Option[];
}) =>
  match({
    channelId: member.channelId,
    rtmpDestinationId: member.rtmpDestinationId,
    type: member.type,
    rtmpOptions,
    socialMediaOptions,
    webChannelOptions,
  })
    .with(
      { type: DESTINATION.EMBED, webChannelOptions: P.when((options) => Boolean(options)) },
      ({ webChannelOptions: webOptions }) =>
        webOptions.find((option) => option.value === member.webEventProfileId)?.label ?? ''
    )
    .with(
      {
        type: DESTINATION.FACEBOOK,
        channelId: P.when((chanId) => Boolean(chanId)),
        socialMediaOptions: P.when((opts) => Boolean(opts)),
      },
      ({ channelId: chanId, socialMediaOptions: socialOptions }) => socialOptions.fb.get(chanId)?.label ?? ''
    )
    .with(
      {
        type: DESTINATION.YOUTUBE,
        channelId: P.when((chanId) => Boolean(chanId)),
        socialMediaOptions: P.when((opts) => Boolean(opts)),
      },
      ({ channelId: chanId, socialMediaOptions: socialOptions }) => socialOptions.yt.get(chanId)?.label ?? ''
    )
    .with(
      {
        type: DESTINATION.RTMP,
        rtmpDestinationId: P.when((rtmpId) => Boolean(rtmpId)),
        rtmpOptions: P.when((options) => Boolean(options)),
      },
      ({ rtmpDestinationId: rtmpId, rtmpOptions: rtmpOpts }) =>
        rtmpOpts.find((option) => option.value === rtmpId)?.label ?? ''
    )
    .otherwise(() => '');

export default getDestinationMemberAccountName;
