import i18next from 'i18next';
import type { Users } from '@studio/types';
import { Cues } from '@studio/types';

function formatSavedVideoCuesResponse({
  cues,
  users,
}: {
  cues: Readonly<Cues.Derived.Cue[]>;
  users: Users.Get.User[];
}): Cues.Derived.CueWithUser[] {
  return cues.map(({ ...cue }) => {
    const foundUser = users.find((user) => user.uuid === cue.userId);
    return {
      ...cue,
      user: foundUser ? foundUser.userName : i18next.t('common:unknown'),
    };
  });
}

function formatCentralCuesResponse({
  cues,
  users,
}: {
  cues: Readonly<Cues.Derived.DestinationCue[]> | Readonly<Cues.Derived.EncoderCue[]>;
  users: Users.Get.User[];
}): Cues.Derived.CueWithUser[] {
  return cues.map(({ privateCue, ...cue }) => {
    const foundUser = users.find((user) => user.userName === cue.user);
    return {
      ...cue,
      userId: foundUser ? foundUser.uuid : i18next.t('common:unknown'),
      visibility: privateCue ? Cues.CUE_VISIBILITY.PRIVATE : Cues.CUE_VISIBILITY.SHARED,
    };
  });
}

const formatCuesResponse = Object.assign(formatCentralCuesResponse, {
  saved: formatSavedVideoCuesResponse,
});

export default formatCuesResponse;
