import { createAction, createAsyncAction } from 'typesafe-actions';
import type { DestinationGroups } from '@studio/types';
import { ActionTypes } from './types';

export const fetch = {
  destinationGroups: createAsyncAction(
    ActionTypes.FETCH_REQUEST,
    ActionTypes.FETCH_REQUEST_SUCCESS,
    ActionTypes.FETCH_REQUEST_ERROR,
    ActionTypes.FETCH_REQUEST_CANCEL
  )<undefined, DestinationGroups.Get.DestinationGroup[], Error, undefined>(),
  destinationGroup: createAsyncAction(
    ActionTypes.FETCH_DESTINATION_REQUEST,
    ActionTypes.FETCH_DESTINATION_REQUEST_SUCCESS,
    ActionTypes.FETCH_DESTINATION_REQUEST_ERROR,
    ActionTypes.FETCH_DESTINATION_REQUEST_CANCEL
  )<
    { destinationId: string },
    { destinationId: string; response: DestinationGroups.Get.DestinationGroup },
    Error,
    undefined
  >(),
};

export const set = {
  filter: createAction(ActionTypes.FILTER_RESULTS)<string>(),
  timestamp: createAction(ActionTypes.FETCH_REQUEST_TIMESTAMP)<number>(),
};
