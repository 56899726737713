import * as React from 'react';
import { DeleteOutlined, ShareAltOutlined, WarningOutlined } from '@ant-design/icons';
import { css, useTheme } from '@emotion/react';
import {
  Badge,
  EditAltIcon,
  ErrorAlt,
  IconButton,
  Inline,
  RightAlt,
  Stack,
  Text,
  useBreakpoint,
} from '@resi-media/resi-ui';
import { useExpanded, type CustomCellProps, type ResiColumn } from 'react-table';
import { DestinationIcons } from '@studio/components/DestinationIcons';
import { DestinationsTableSubcomponent } from '@studio/components/DestinationsTableSubcomponent';
import { TableList } from '@studio/components/TableList';
import { DESTINATION } from '@studio/constants/content-destination';
import { getDestinationMemberAccountName } from '@studio/helpers';
import { useClient, useDestinations, usePrefix } from '@studio/hooks';
import type { Crosspost, DestinationDetails, DestinationGroups, Playlists, Schedules } from '@studio/types';

type _Props = {
  broadcastFields?: DestinationGroups.Derived.BroadcastFields;
  groupMembers: Schedules.Derived.PartialContentDestinationSchedule[];
  idsAllowedToDelete?: string[];
  idsAllowedToEdit?: string[];
  onEdit?: ({ editMemberIndex }: { editMemberIndex: number }) => void;
  onRemove?: ({ memberIndex, memberName }: { memberIndex: number; memberName: string }) => void;
  onShare?: ({
    contentDestinationScheduleId,
    embedId,
  }: {
    contentDestinationScheduleId?: string | null;
    embedId?: string;
  }) => void;
};

const DestinationsTable = ({
  broadcastFields,
  groupMembers,
  idsAllowedToDelete,
  idsAllowedToEdit,
  onEdit,
  onRemove,
  onShare,
}: _Props) => {
  const { commonT } = usePrefix('pages:', 'destinationGroups');
  const showButtonGroup = Boolean(onEdit || onRemove || onShare);
  const [cachedFacebookDestinations, setCachedFacebookDestinations] = React.useState<Map<string, DestinationDetails[]>>(
    new Map()
  );
  const [facebookDestinationErrors, setFacebookDestinationErrors] = React.useState<Map<string, unknown>>(new Map());
  const [cachedCrossposts, setCachedCrossposts] = React.useState<Map<string, Crosspost[]>>(new Map());
  const [crosspostErrors, setCrosspostErrors] = React.useState<Map<string, unknown>>(new Map());
  const [cachedPlaylistDetails, setCachedPlaylistDetails] = React.useState<Map<string, Playlists.Get.Playlist>>(
    new Map()
  );

  const { rtmpOptions, socialMediaOptions, webChannelOptions } = useDestinations();
  const mediaQuery = useBreakpoint();
  const theme = useTheme();
  const { dispatchGroup } = useDestinations();
  const facebookMembers = React.useMemo(
    () => groupMembers.filter((member) => member.type === DESTINATION.FACEBOOK),
    [groupMembers]
  );

  const uniqueChannelIds = React.useMemo(() => {
    return Array.from(
      new Set(
        groupMembers
          .filter((member) => member.type === DESTINATION.FACEBOOK)
          .flatMap((member) => member.channelId ?? '')
      )
    );
  }, [groupMembers]);

  const uniquePlaylistIds = React.useMemo(() => {
    return Array.from(
      new Set(
        groupMembers
          .filter((member) => member.type === DESTINATION.EMBED)
          .flatMap((member) => member.autoArchivePlaylists ?? [])
      )
    );
  }, [groupMembers]);

  const { callApi: fetchPlaylistDetails } = useClient({
    config: useClient.mediaMetadata.v1.playlists.GET,
    query: {},
  });

  const { callApi: fetchFacebookDestinations } = useClient({
    config: useClient.central.v3.socialMedia.id.destinations.GET,
    params: { channelId: '' },
  });

  const { callApi: fetchCrossposts } = useClient({
    config: useClient.central.v3.socialMedia.id.destinations.id.crosspostDestinations.GET,
    params: { accountId: '', destinationId: '' },
  });

  const getFacebookDestinations = React.useCallback(
    async (channelId: string) => {
      try {
        const destinations = await fetchFacebookDestinations({}, { params: { channelId } });
        setCachedFacebookDestinations((prev) => new Map(prev).set(channelId, destinations));
        setFacebookDestinationErrors((prev) => {
          const updatedErrors = new Map(prev);
          updatedErrors.delete(channelId);
          return updatedErrors;
        });
      } catch (error) {
        setFacebookDestinationErrors((prev) => new Map(prev).set(channelId, error));
      }
    },
    [fetchFacebookDestinations]
  );

  const getCrossposts = React.useCallback(
    async ({ channelId, partnerData }: Schedules.Derived.PartialContentDestinationSchedule) => {
      const destinationId = partnerData?.facebookPartnerData.destinationId;
      if (
        channelId &&
        destinationId &&
        partnerData.facebookPartnerData.crossposts.length &&
        !cachedCrossposts.get(destinationId)
      ) {
        try {
          const crossposts = await fetchCrossposts({}, { params: { accountId: channelId, destinationId } });
          setCachedCrossposts((prev) => new Map(prev).set(destinationId, crossposts));
        } catch (error) {
          setCrosspostErrors((prev) => new Map(prev).set(destinationId, error));
        }
      }
    },
    [cachedCrossposts, fetchCrossposts]
  );

  const getPlaylistDetails = React.useCallback(async () => {
    const hasNewPlaylistId = uniquePlaylistIds.some((uniquePlaylistId) => !cachedPlaylistDetails.has(uniquePlaylistId));
    if (hasNewPlaylistId) {
      const playlistDetails = await fetchPlaylistDetails('', {
        query: { playlistIds: uniquePlaylistIds.join('&playlistIds=') },
      });
      setCachedPlaylistDetails((prev) => {
        const updatedPlaylistDetails = new Map(prev);
        playlistDetails.forEach((playlist) => {
          updatedPlaylistDetails.set(playlist.id, playlist);
        });
        return updatedPlaylistDetails;
      });
    }
  }, [cachedPlaylistDetails, fetchPlaylistDetails, uniquePlaylistIds]);

  React.useEffect(() => {
    uniqueChannelIds.forEach((channelId) => {
      if (!cachedFacebookDestinations.has(channelId)) {
        getFacebookDestinations(channelId);
      }
    });
  }, [cachedFacebookDestinations, getFacebookDestinations, uniqueChannelIds]);

  React.useEffect(() => {
    Promise.all(facebookMembers.map(getCrossposts));
  }, [cachedCrossposts, facebookMembers, getCrossposts]);

  React.useEffect(() => {
    dispatchGroup();
  }, [dispatchGroup]);

  React.useEffect(() => {
    getPlaylistDetails();
  }, [getPlaylistDetails]);

  const headers: ResiColumn<Schedules.Derived.PartialContentDestinationSchedule>[] = [
    {
      accessor: 'type',
      Cell: ({ cellData, row }: CustomCellProps<Schedules.Derived.PartialContentDestinationSchedule>) => {
        const destinations = cachedFacebookDestinations.get(cellData.channelId ?? '');
        const destinationId = cellData.partnerData?.facebookPartnerData.destinationId;
        const destinationName = destinations?.find((dest) => dest.id === destinationId)?.title;
        const crosspostCount = cellData.partnerData?.facebookPartnerData.crossposts.length ?? 0;
        const crosspostString = crosspostCount
          ? `, +${crosspostCount} ${commonT('crosspost', { count: crosspostCount }).toLowerCase()}`
          : '';
        const hasInvalidStatus = Boolean(
          socialMediaOptions.fb.get(cellData.channelId || '')?.invalidStatus ||
            socialMediaOptions.yt.get(cellData.channelId || '')?.invalidStatus
        );
        const hasError = Boolean(
          facebookDestinationErrors.get(cellData.channelId || '') ||
            crosspostErrors.get(cellData.partnerData?.facebookPartnerData.destinationId || '')
        );

        const accountName = getDestinationMemberAccountName({
          member: cellData,
          rtmpOptions,
          socialMediaOptions,
          webChannelOptions,
        });
        const facebookDisplayName =
          accountName && destinationName ? `${accountName}, ${destinationName}${crosspostString}` : '';
        const memberDisplayName =
          cellData.type === DESTINATION.FACEBOOK && facebookDisplayName ? facebookDisplayName : accountName;

        return (
          <Inline alignItems="center" dataTestId="destination-component">
            <RightAlt
              css={css`
                ${row.isExpanded ? 'transform: rotate(90deg);' : ''}
                font-size: ${theme.typography.pxToRem(24)};
                padding-right: ${theme.spacing.xs};
                color: ${theme.palette.text.title};
                transition: transform ${theme.transitions.defaults.standard};
              `}
            />
            <Inline alignItems="center" justifyContent="space-between" overflow="hidden" w="scale">
              <Stack overflow="hidden">
                <Inline alignItems="center" gap="m">
                  <DestinationIcons destinations={[{ type: cellData.type }]} />
                  <Text
                    colorVariant="heading"
                    dataTestId="destination-display-name"
                    truncate
                    variant="body2"
                    weightVariant="bold"
                  >
                    {memberDisplayName}
                  </Text>
                  {hasInvalidStatus &&
                    (mediaQuery.md ? (
                      <Badge colorVariant="warning">
                        <Inline alignItems="center" dataTestId="no-access__badge" gap="xs">
                          <WarningOutlined />
                          {commonT('noAccess')}
                        </Inline>
                      </Badge>
                    ) : (
                      <WarningOutlined
                        data-testid="no-access__icon-only"
                        style={{ color: theme.palette.warning.main }}
                      />
                    ))}
                  {hasError && (
                    <ErrorAlt
                      css={css`
                        color: ${theme.palette.negative.main};
                      `}
                      data-testid="error-icon"
                    />
                  )}
                </Inline>
                {cellData.title && (
                  <Text dataTestId="destination-title" truncate>
                    {cellData.title}
                  </Text>
                )}
              </Stack>
              {showButtonGroup && (
                <Inline>
                  {onShare && cellData.type === DESTINATION.EMBED && (
                    <IconButton
                      dataTestId="icon-button__share"
                      label="share-icon-button"
                      onClick={(e) => {
                        e.stopPropagation();
                        onShare({ contentDestinationScheduleId: cellData.id, embedId: cellData.webEventProfileId });
                      }}
                    >
                      <ShareAltOutlined />
                    </IconButton>
                  )}
                  {onEdit &&
                    ((cellData.id && idsAllowedToEdit?.includes(cellData.id)) ||
                      idsAllowedToEdit === undefined ||
                      !cellData.id) && (
                      <IconButton
                        dataTestId="icon-button__edit"
                        label="edit-icon-button"
                        onClick={(e) => {
                          e.stopPropagation();
                          onEdit({ editMemberIndex: row.index });
                        }}
                      >
                        <EditAltIcon />
                      </IconButton>
                    )}
                  {onRemove &&
                    ((cellData.id && idsAllowedToDelete?.includes(cellData.id)) ||
                      idsAllowedToDelete === undefined ||
                      !cellData.id) && (
                      <IconButton
                        colorVariant="negative"
                        dataTestId="icon-button__delete"
                        label="delete-icon-button"
                        onClick={(e) => {
                          e.stopPropagation();
                          onRemove({ memberIndex: row.index, memberName: memberDisplayName });
                        }}
                      >
                        <DeleteOutlined />
                      </IconButton>
                    )}
                </Inline>
              )}
            </Inline>
          </Inline>
        );
      },
    },
  ];

  return (
    <TableList<Schedules.Derived.PartialContentDestinationSchedule>
      columnHeaders={headers}
      dataTestId="destinations-table"
      pluginHooks={[useExpanded]}
      rowData={groupMembers}
      rowSpacing="m"
      SubComponent={(member) => (
        <DestinationsTableSubcomponent
          broadcastFields={broadcastFields}
          {...(member.type === DESTINATION.FACEBOOK && {
            cachedCrossposts,
            cachedFacebookDestinations,
            crosspostErrors,
            facebookDestinationErrors,
            refetchFacebookDetails: () => {
              const channelId = member.channelId;
              if (channelId) {
                getFacebookDestinations(channelId);
                getCrossposts(member);
              }
            },
          })}
          member={member}
          {...(member.type === DESTINATION.EMBED &&
            member.autoArchivePlaylists && {
              playlistDetails: member.autoArchivePlaylists
                .filter((playlistId: string) => cachedPlaylistDetails.has(playlistId))
                .map((playlistId: string) => cachedPlaylistDetails.get(playlistId)),
            })}
        />
      )}
      tableOptions={{
        autoResetExpanded: false,
      }}
    />
  );
};

DestinationsTable.displayName = 'DestinationsTable';

export default DestinationsTable;
