import { default as i18n } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { makeTitleCase } from '@studio/helpers';
import { en, es } from '../i18n';

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: 'en',
    debug: false,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
      format: function (value, format) {
        if (format === 'uppercase') return value.toUpperCase();
        if (format === 'lowercase') return value.toLowerCase();
        // Not truly sentenceCase.  Needs revisiting for periods, abbreviations, etc..
        if (format === 'sentenceCase') return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
        if (format === 'titlecase') return makeTitleCase(value);

        return value;
      },
    },
    // pull in our resource files
    resources: {
      en,
      es,
    },
  });

export default i18n;
