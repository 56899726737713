export class ActionTypes {
  static readonly TRACK_SEGMENT_REQUEST = '@@tracking/TRACK_SEGMENT_REQUEST';
  static readonly TRACK_SEGMENT_REQUEST_SUCCESS = '@@tracking/TRACK_SEGMENT_REQUEST_SUCCESS';
  static readonly TRACK_SEGMENT_REQUEST_ERROR = '@@tracking/TRACK_SEGMENT_REQUEST_ERROR';
}

export interface TrackSegmentPayload {
  eventName: string;
  eventProps?: Record<string, unknown>;
}
