import { produce } from 'immer';
import type { ActionType, PayloadAction } from 'typesafe-actions';
import { createReducer } from 'typesafe-actions';
import { fetch, clear, set } from './actions';
import type { Organization, ActionTypes, State, SearchBy, Contact } from './types';
import { SEARCH_BY } from './types';

type Action = ActionType<typeof clear | typeof fetch | typeof set>;

export const initialState: State = {
  isFetching: false,
  orgs: [],
  contacts: [],
  error: null,
  filter: '',
  searchBy: SEARCH_BY.ORG_NAME,
};

const reducer = createReducer<State, Action>(initialState)
  .handleAction(
    fetch.orgs.request,
    produce((draft: State) => {
      draft.isFetching = true;
      draft.error = null;
    })
  )
  .handleAction(
    fetch.orgs.success,
    produce((draft: State, action: PayloadAction<ActionTypes.FETCH_REQUEST_ORGS_SUCCESS, Organization[]>) => {
      draft.isFetching = false;
      draft.orgs = action.payload;
    })
  )
  .handleAction(
    fetch.orgs.failure,
    produce((draft: State, action: PayloadAction<ActionTypes.FETCH_REQUEST_ORGS_ERROR, Error>) => {
      draft.isFetching = false;
      draft.error = action.payload;
      draft.orgs = [];
    })
  )
  .handleAction(
    fetch.orgs.cancel,
    produce((draft: State) => {
      draft.isFetching = false;
    })
  )
  .handleAction(
    fetch.contacts.request,
    produce((draft: State) => {
      draft.isFetching = true;
      draft.error = null;
    })
  )
  .handleAction(
    fetch.contacts.success,
    produce((draft: State, action: PayloadAction<ActionTypes.FETCH_REQUEST_CONTACTS_SUCCESS, Contact[]>) => {
      draft.isFetching = false;
      draft.contacts = action.payload;
    })
  )
  .handleAction(
    fetch.contacts.failure,
    produce((draft: State, action: PayloadAction<ActionTypes.FETCH_REQUEST_CONTACTS_ERROR, Error>) => {
      draft.isFetching = false;
      draft.error = action.payload;
    })
  )
  .handleAction(
    fetch.contacts.cancel,
    produce((draft: State) => {
      draft.isFetching = false;
    })
  )
  .handleAction(
    set.filter,
    produce((draft: State, action: PayloadAction<ActionTypes.FILTER_RESULTS, string>) => {
      draft.filter = action.payload;
    })
  )
  .handleAction(
    clear,
    produce((draft: State) => {
      draft.orgs = [];
      draft.filter = '';
    })
  )
  .handleAction(
    set.searchBy,
    produce((draft: State, action: PayloadAction<ActionTypes.SEARCH_BY, SearchBy>) => {
      draft.searchBy = action.payload;
    })
  );

export default reducer;
