import { CalendarOutlined } from '@ant-design/icons';
import { useTheme } from '@emotion/react';
import type { Inline } from '@resi-media/resi-ui';
import { Draft, PlayFolder, SimLive } from '@resi-media/resi-ui';
import { P, match } from 'ts-pattern';
import { ORIGINATION, STREAM_TYPE } from '@studio/constants/schedules';
import type { Schedules } from '@studio/types';
import GoLiveIcon from '@svg/GoLiveIcon.svg';

type _Props = Inline.Props & {
  encoderName?: string;
  isEnabled: boolean;
  isNoColor?: boolean;
  origination?: Schedules.Get.Origination;
  size?: number;
  streamType: Schedules.Get.StreamType;
};

const EventTypeIcon = ({ encoderName, isEnabled, isNoColor, origination, size, streamType }: _Props) => {
  const theme = useTheme();
  const iconSize = size ? theme.typography.pxToRem(size) : theme.typography.body2.fontSize;
  const iconColor = match({ isEnabled, isNoColor, streamType })
    .with({ isNoColor: true }, () => theme.palette.text.secondary)
    .with(
      { isEnabled: true, streamType: P.union(STREAM_TYPE.LIVE, STREAM_TYPE.EXISTING_ENCODER_EVENT) },
      () => theme.palette.primary.main
    )
    .with({ isEnabled: true, streamType: STREAM_TYPE.SIMLIVE }, () => theme.palette.purple[500])
    .with({ isEnabled: true, streamType: STREAM_TYPE.EXISTING_MEDIA }, () => theme.palette.positive.main)
    .otherwise(() => theme.palette.text.disabled);

  return match({ streamType, origination })
    .with({ streamType: STREAM_TYPE.LIVE }, () => (
      <Draft.Tooltip content={encoderName} placement="top">
        <CalendarOutlined
          data-testid="live-icon"
          style={{
            color: iconColor,
            fontSize: iconSize,
          }}
        />
      </Draft.Tooltip>
    ))
    .with({ streamType: STREAM_TYPE.EXISTING_ENCODER_EVENT, origination: ORIGINATION.GO_LIVE }, () => (
      <GoLiveIcon
        data-testid="go-live-icon"
        style={{
          color: iconColor,
          fontSize: iconSize,
        }}
      />
    ))
    .with({ streamType: STREAM_TYPE.EXISTING_ENCODER_EVENT }, () => (
      <CalendarOutlined
        data-testid="manual-start-live-icon"
        style={{
          color: iconColor,
          fontSize: iconSize,
        }}
      />
    ))
    .with({ streamType: STREAM_TYPE.SIMLIVE }, () => (
      <SimLive color={iconColor} data-testid="replay-icon" fontSize={iconSize} />
    ))
    .with({ streamType: STREAM_TYPE.EXISTING_MEDIA }, () => (
      <PlayFolder
        data-testid="saved-video-icon"
        style={{
          color: iconColor,
          fontSize: iconSize,
        }}
      />
    ))
    .otherwise(() => null);
};

EventTypeIcon.displayName = 'EventTypeIcon';

export default EventTypeIcon;
