import { createAction, createAsyncAction } from 'typesafe-actions';
import type { EncoderLogs } from '@studio/types';
import { ActionTypes } from './types';

export const fetch = {
  logs: createAsyncAction(
    ActionTypes.FETCH_REQUEST,
    ActionTypes.FETCH_REQUEST_SUCCESS,
    ActionTypes.FETCH_REQUEST_ERROR,
    ActionTypes.FETCH_REQUEST_CANCEL
  )<EncoderLogs.Get.FetchOptions, EncoderLogs.Derived.EncoderLogEntry, Error, undefined>(),
};

export const set = {
  logs: {
    encoderId: createAction(ActionTypes.CURRENT_ENCODER_ID)<string>(),
    filter: createAction(ActionTypes.FILTER_RESULTS)<string>(),
  },
};
