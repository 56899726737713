export enum ActionTypes {
  FETCH_REQUEST = '@@timezones/FETCH_REQUEST',
  FETCH_REQUEST_CANCEL = '@@timezones/FETCH_REQUEST_CANCEL',
  FETCH_REQUEST_ERROR = '@@timezones/FETCH_REQUEST_ERROR',
  FETCH_REQUEST_SUCCESS = '@@timezones/FETCH_REQUEST_SUCCESS',
}

export interface TimezonesInitialState {
  error: Error | null;
  isFetching: boolean;
  items: Timezone[];
}

export interface Timezone {
  name: string;
  offset: string;
}
