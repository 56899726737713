import { HTTP_METHODS } from '@studio/constants/axios';
import { ONBOARDING_API_V1 } from '@studio/constants/env-variables';
import type { Onboarding } from '@studio/types';

export const root = {
  [HTTP_METHODS.GET]: {
    url: `${ONBOARDING_API_V1}/salesforce/products`,
    response: [] as Onboarding.Get.Product[],
    method: HTTP_METHODS.GET,
  },
};
