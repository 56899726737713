import type { Badge } from '@resi-media/resi-ui';
import { VIDEO_STATUS } from '@studio/constants/content-destination';
import { WebVideoStatus } from '@studio/store/content-destinations/types';
import type { ContentDestinations } from '@studio/types';

export const isContentDestinationTransitioning = (status: string): boolean => {
  const uppercaseStatus = status.toUpperCase();
  return (
    uppercaseStatus === VIDEO_STATUS.STARTING ||
    uppercaseStatus === VIDEO_STATUS.STOPPING ||
    uppercaseStatus === VIDEO_STATUS.IDLE
  );
};

export const isAnyContentDestinationTransitioning = (
  items: ContentDestinations.Get.ContentDestination[],
  stoppedVideoIds: string[]
): boolean => {
  return items.some((item) => {
    const updatedStatus = getUpdatedStatus(item.id, item.status, stoppedVideoIds);
    return isContentDestinationTransitioning(updatedStatus);
  });
};

export const getTransitioningContentDestinationIds = (
  items: ContentDestinations.Get.ContentDestination[],
  stoppedVideoIds: string[]
): string[] => {
  const videos = items.filter((item) => {
    const updatedStatus = getUpdatedStatus(item.id, item.status, stoppedVideoIds);
    return isContentDestinationTransitioning(updatedStatus);
  });
  return videos.map((video) => video.id);
};

export const isStatusTransitioning = (mediaStatus: string): boolean => {
  const lowercaseStatus = mediaStatus.toLowerCase();
  return (
    lowercaseStatus === WebVideoStatus.STARTING ||
    lowercaseStatus === WebVideoStatus.STOPPING ||
    lowercaseStatus === WebVideoStatus.IDLE
  );
};

// returns true if status will not change; Ex: once a web video is in "stopped" state, that status will never change.
export const isStatusFinal = (mediaStatus: string): boolean => {
  const lowercaseStatus = mediaStatus.toLowerCase();
  return (
    lowercaseStatus === WebVideoStatus.ABORTED ||
    lowercaseStatus === WebVideoStatus.ERROR ||
    lowercaseStatus === WebVideoStatus.STOPPED
  );
};

export const canStopWebVideo = (mediaStatus: string): boolean => {
  return mediaStatus.toLowerCase() !== WebVideoStatus.STOPPED;
};

export const getStatusColor = (mediaStatus: string): Badge.ColorOptions => {
  const lowercaseStatus = mediaStatus.toLowerCase();
  switch (lowercaseStatus) {
    case WebVideoStatus.IDLE:
    case WebVideoStatus.STARTED:
    case WebVideoStatus.STARTING:
      return 'positive';
    case WebVideoStatus.STOPPED:
    case WebVideoStatus.STOPPING:
      return 'grey';
    case WebVideoStatus.ABORTED:
    case WebVideoStatus.ERROR:
    case WebVideoStatus.WARNING:
      return 'negative';
    default:
      return 'primary';
  }
};

// currently the backend does not return a status of "Stopping". The front end simply has to keep track of what web videos
// have been manually stopped by the user. If that item is not yet in a stopped state, then a status of "stopping" should be used.
export const getUpdatedStatus = (
  videoId: string,
  status: ContentDestinations.Get.VideoStatus,
  stoppedWebVideoIds: string[]
): ContentDestinations.Get.VideoStatus => {
  if (status !== VIDEO_STATUS.STOPPED && stoppedWebVideoIds.includes(videoId)) {
    return VIDEO_STATUS.STOPPING;
  }
  if (status === VIDEO_STATUS.IDLE) {
    return VIDEO_STATUS.STARTING;
  }
  return status;
};
