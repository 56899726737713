export enum EmbedType {
  // string needs to match value webplayer expects
  LIBRARY = 'library',
  // string needs to match value webplayer expects
  PLAYLIST = 'playlist',
  // webplayer defaults to this, so it does not need to be specified
  WEB_BROADCAST = 'event',
  WEB_CHANNEL = 'web_channel', // string needs to match value webplayer expects
}

export enum WebPlayerKind {
  EMBED_ID = 'embed_id',
  MANIFEST = 'manifest',
  MANIFESTS = 'manifests',
  PLAYLIST = 'playlist',
}
