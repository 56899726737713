import * as React from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import { css, useTheme } from '@emotion/react';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Checkbox,
  Draft,
  FieldLabel,
  Hr,
  Inline,
  Link,
  Progress,
  Stack,
  Text,
  ToggleInput,
  useToast,
} from '@resi-media/resi-ui';
import isString from 'lodash/isString';
import sortBy from 'lodash/sortBy';
import type { FieldError } from 'react-hook-form';
import { useFieldArray, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { sortVenues } from '@studio/helpers';
import { useClient, usePrefix } from '@studio/hooks';
import { venues as venuesStore, monitors as monitorsStore } from '@studio/store';
import type { Actions } from '@studio/types';
import { DecoderStatusBadge } from '../DecoderStatusBadge';
import { ErrorBlock } from '../ErrorBlock';
import { isVenueDisabled } from './helpers';

type _ModalState = {
  eventId: string;
  name: string;
};

const LoadOnDecoderModalInternal = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const loadEventOnDecoders = useClient({ config: useClient.central.v3.actions.loadEventOnDecoders.PATCH });
  const { trigger } = useToast();
  const [decoderValidationError, setDecoderValidationError] = React.useState<string>();
  const { eventId, name, onCloseReset } = Draft.ModalContext.useModal<_ModalState>();
  const { commonT, prefixNS } = usePrefix('components:', 'loadOnDecoder');
  const venues = useSelector(venuesStore.combineVenueMonitor);
  const isFetchingDecoders = useSelector(monitorsStore.select.decoders.isFetching);

  const { setValue, ...form } = useForm<Actions.Components.LoadOnDecoderState>({
    defaultValues: {
      autoPlay: true,
      clearCache: false,
      userIds: [],
    },
    mode: 'all',
    resolver: yupResolver(
      yup.object({
        userIds: yup.array().test('has-one-selected', prefixNS('noDecodersError'), (decoderFields) => {
          return decoderFields?.map((f) => f.checked).find(Boolean) ?? false;
        }),
      })
    ),
  });

  const { fields, update } = useFieldArray({ control: form.control, name: 'userIds' });
  const enabledFields = fields.filter((field) => !isVenueDisabled(field.venue));
  const allSelected = enabledFields.every((field) => field.checked);

  React.useEffect(() => {
    dispatch(venuesStore.fetch.logins.request());
    dispatch(monitorsStore.fetch.decoders.request());
  }, [dispatch]);

  React.useEffect(() => {
    const sorted = sortBy(venues, [(venue) => sortVenues(venue), (venue) => venue.userName.toLocaleLowerCase()]);

    if (!isFetchingDecoders) {
      setValue(
        'userIds',
        sorted.map((venue) => ({ venue, checked: false }))
      );
    }
  }, [venues, setValue, isFetchingDecoders]);

  const onSubmit = React.useCallback(
    async (data: Actions.Components.LoadOnDecoderState) => {
      await loadEventOnDecoders.callApi({
        ...data,
        eventId,
        userIds: data.userIds.map((field) => (field.checked ? field.venue.uuid : undefined)).filter(isString),
      });

      onCloseReset();
      trigger({ content: prefixNS('loadedOnDecoder') });
    },
    [loadEventOnDecoders, eventId, onCloseReset, trigger, prefixNS]
  );

  const decodersOfflineError =
    (!venues.find((venue) => !isVenueDisabled(venue)) && prefixNS('decodersOfflineError')) || undefined;

  const apiError = (loadEventOnDecoders.error && prefixNS('unableToLoadDecoder')) || undefined;

  const getDataTestID = () => {
    if (decodersOfflineError) {
      return 'decoders-offline-banner';
    }
    return decoderValidationError ? 'decoder-validation-banner' : 'decoder-api-banner';
  };

  const renderTooltip = React.useMemo(
    () => (
      <Stack gap="m">
        <div>
          <Text colorVariant="inherit" variant="body4" weightVariant="bold">
            {prefixNS('whatHappens')}
          </Text>
          <Text colorVariant="inherit" variant="body4">
            {prefixNS('whatHappensDocumentation')}
          </Text>
        </div>
        <div>
          <Text colorVariant="inherit" variant="body4" weightVariant="bold">
            {prefixNS('howMuchContent')}
          </Text>
          <Text colorVariant="inherit" variant="body4">
            {prefixNS('howMuchContentDocumentation')}
          </Text>
        </div>
      </Stack>
    ),
    [prefixNS]
  );

  const renderDecoders = React.useMemo(
    () =>
      fields
        .filter((field) => field.venue.active)
        .map(({ checked, venue }, index) => (
          <React.Fragment key={`${venue.uuid}:${index}`}>
            <Inline justifyContent="space-between" position="relative">
              <Checkbox
                checked={checked}
                dataTestId={`checkbox-${venue.uuid}`}
                disabled={isVenueDisabled(venue)}
                endNode={venue.userName}
                onChange={(event) => {
                  update(index, {
                    venue,
                    checked: event.currentTarget.checked,
                  });
                }}
              />
              <DecoderStatusBadge active={venue.active} status={venue.decoder.status ?? 'offline'} />
            </Inline>
            <Hr />
          </React.Fragment>
        )),
    [fields, update]
  );

  return (
    <form
      autoComplete="off"
      css={css`
        display: contents;
      `}
      data-testid="load-on-decoders-modal-form"
      onSubmit={form.handleSubmit(
        (data: Actions.Components.LoadOnDecoderState) => {
          setDecoderValidationError(undefined);
          onSubmit(data);
        },
        (errors) => {
          const error = errors.userIds as FieldError | undefined;
          setDecoderValidationError(error?.message?.toString());
        }
      )}
    >
      <Draft.ModalHeader
        dataTestId="load-on-decoders-modal-header"
        header={prefixNS('title')}
        onClose={onCloseReset}
        showBorder
      />
      <Draft.ModalBody dataTestId="load-on-decoders-modal-body">
        <Stack gap="l">
          <ErrorBlock
            customErrorMessage={decodersOfflineError || decoderValidationError || apiError}
            dataTestId={getDataTestID()}
            error={decodersOfflineError || decoderValidationError || apiError}
          />
          <Stack gap="unset">
            <Text>{prefixNS('selectDecoders')}</Text>
            <Inline alignItems="center">
              <Text>{prefixNS('whatHappens')}</Text>
              <Draft.Tooltip content={renderTooltip} placement="right">
                <InfoCircleOutlined style={{ color: theme.palette.primary.main }} />
              </Draft.Tooltip>
            </Inline>
          </Stack>
          <Stack>
            <FieldLabel>{commonT('eventName')}</FieldLabel>
            <Draft.TextInput dataTestId="event-name-field" readOnly tabIndex={-1} value={name} />
          </Stack>
          <Stack>
            <FieldLabel>{commonT('decodersOneOrMore')}</FieldLabel>
            {enabledFields.length > 1 && (
              <Link
                css={css`
                  width: min-content;
                  white-space: nowrap;
                `}
                dataTestId={allSelected ? 'unselect-all-button' : 'select-all-button'}
                onClick={() => {
                  setValue(
                    'userIds',
                    fields.map((field) => ({
                      ...field,
                      checked: isVenueDisabled(field.venue) ? field.checked : !allSelected,
                    }))
                  );
                }}
                role="button"
              >
                {commonT(allSelected ? 'unselectAll' : 'selectAll')}
              </Link>
            )}
            {renderDecoders}
          </Stack>
          <Stack position="relative">
            <ToggleInput defaultChecked endNode={prefixNS('autoplay')} {...form.register('autoPlay')} />
            <ToggleInput endNode={prefixNS('clearCache')} {...form.register('clearCache')} />
          </Stack>
        </Stack>
      </Draft.ModalBody>
      <Draft.ModalFooter>
        <Inline>
          <Draft.Button label={commonT('cancel')} onClick={onCloseReset} variant="text" />
          <Draft.Button
            data-trackid="decoder-load-submit"
            dataTestId="submit-button"
            disabled={Boolean(decodersOfflineError) || loadEventOnDecoders.isFetching}
            label={prefixNS('load')}
            startNode={
              loadEventOnDecoders.isFetching ? (
                <Progress colorVariant="inherit" dataTestId="decoder-loading-spinner" sizeVariant="inherit" />
              ) : null
            }
            type="submit"
          />
        </Inline>
      </Draft.ModalFooter>
    </form>
  );
};

LoadOnDecoderModalInternal.displayName = 'LoadOnDecoderModalInternal';

/* eslint-disable import/export */
export const LoadOnDecoderModal = Object.assign(React.forwardRef(LoadOnDecoderModalInternal), {
  displayName: 'LoadOnDecoderModal',
});

// eslint-disable-next-line no-redeclare
export namespace LoadOnDecoderModal {
  export type ModalState = _ModalState;
}
