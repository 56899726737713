import { startOfDay, subDays } from 'date-fns';
import type { Analytics } from '@studio/types';
import { END_NOW_UTC, CONTENT_LIBRARY_LIFE_TIME_DATE, LIFE_TIME_DATE, NOW_LOCAL } from './date-constants';

export const EVENT_ANALYTICS_SESSION_STORAGE = 'eventAnalyticsState';
export const LIBRARY_ANALYTICS_SESSION_STORAGE = 'analyticsMediaState';
export const MAX_FETCH_LIMIT_FOR_CSV_EXPORT = 1000;
export const MAX_FETCH_CALLS_FOR_CSV = 100;

export const MANUALLY_DOWNLOADED_EVENT = '00000000-0000-0000-0000-000000000000';
export const STUDIO_USAGE_EVENT = '11111111-1111-1111-1111-111111111111';
export const STUDIO_USAGE_NOT_AVAILABLE_DATE = new Date('05/11/2023');

export const COLOR_VARIANTS = [
  '#475D7A',
  '#E38B36',
  '#FFE27B',
  '#A38BE9',
  '#4F9C33',
  '#BFBFBF',
  '#66BBDF',
  '#FF585D',
  '#AD3F2D',
  '#F8B9FA',
];

export const dateRangeValues = (isContentLibrary?: boolean): Analytics.Derived.RangeTypes => {
  return {
    d0: {
      startDate: startOfDay(isContentLibrary ? CONTENT_LIBRARY_LIFE_TIME_DATE : LIFE_TIME_DATE).toISOString(),
      endDate: END_NOW_UTC,
      isFullMonth: false,
    },
    d7: {
      startDate: startOfDay(subDays(NOW_LOCAL, 6)).toISOString(),
      endDate: END_NOW_UTC,
      isFullMonth: false,
    },
    d28: {
      startDate: startOfDay(subDays(NOW_LOCAL, 27)).toISOString(),
      endDate: END_NOW_UTC,
      isFullMonth: false,
    },
    d365: {
      startDate: startOfDay(subDays(NOW_LOCAL, 364)).toISOString(),
      endDate: END_NOW_UTC,
      isFullMonth: false,
    },
    c1: {
      startDate: '',
      endDate: '',
      isFullMonth: false,
    },
  };
};

export const API_ROOTS = {
  CONTENT_LIBRARY: 'contentLibrary',
  WEB_EVENTS: 'webevents',
  FACEBOOK: 'facebook',
  YOUTUBE: 'youTube',
} as const;

export const CHART_TAB = {
  EMBED_PLAYER: 'embedPlayer',
  STREAM_URL: 'streamUrl',
  FACEBOOK: 'facebook',
  YOUTUBE: 'youtube',
} as const;

export const CONTENT_LIBRARY_EXPORT_FIELDS = {
  SAVED_VIDEO_ID: 'savedVideoId',
  CLIENT_ID: 'clientId',
  SESSION_ID: 'sessionId',
  TIMESTAMP: 'timestamp',
  IP_ADDRESS: 'ipAddress',
  CITY: 'city',
  REGION: 'region',
  COUNTRY: 'country',
  LATITUDE: 'latitude',
  LONGITUDE: 'longitude',
  TOTAL_TIME_WATCHED_SECONDS: 'totalTimeWatchedSeconds',
  USER_AGENT: 'userAgent',
  RESOLUTION: 'resolution',
  DEVICE_CLASS: 'deviceClass',
  DEVICE_BRAND: 'deviceBrand',
  OS_CLASS: 'osClass',
  OS_NAME_VERSION: 'osNameVersion',
} as const;

export const COUNT_STAT_KEYS = {
  VIEWERS: {
    COUNT_KEY: 'viewers',
    PERCENTAGE_KEY: 'viewersPercentageInc',
    ORDER: 1,
  },
  VIEWS: {
    COUNT_KEY: 'views',
    PERCENTAGE_KEY: 'viewsPercentageInc',
    ORDER: 2,
  },
  NEW_VIEWERS: {
    COUNT_KEY: 'newViewers',
    PERCENTAGE_KEY: 'newViewersPercentageInc',
    ORDER: 5,
  },
  RETURN_VIEWERS: {
    COUNT_KEY: 'returnViewers',
    PERCENTAGE_KEY: 'returnViewersPercentageInc',
    ORDER: 6,
  },
  PEAK_CONCURRENT: {
    COUNT_KEY: 'peakConcurrentViewers',
    PERCENTAGE_KEY: 'peakConcurrentPercentageViewersInc',
    ORDER: 7,
  },
  PEAK_CONCURRENT_LIVE_VIEWS: {
    COUNT_KEY: 'peakConcurrentLiveViews',
    PERCENTAGE_KEY: 'peakConcurrentLiveViewsPercentageInc',
    ORDER: 10,
  },
  TOTAL_MINUTES_WATCHED: {
    COUNT_KEY: 'totalMinutesWatched',
    PERCENTAGE_KEY: 'totalMinutesWatchedPercentageInc',
    ORDER: 9,
  },
} as const;

export const DATE_SELECT = {
  LAST_7: 'd7',
  LAST_28: 'd28',
  LAST_365: 'd365',
  LIFETIME: 'd0',
  CUSTOM: 'c1',
} as const;

export const DEFAULT_CSV_EXPORT_FIELDS = {
  EVENT_ID: 'eventId',
  CLIENT_ID: 'clientId',
  SESSION_ID: 'sessionId',
  TIMESTAMP: 'timestamp',
  EVENT_TYPE: 'eventType',
  IP_ADDRESS: 'ipAddress',
  CITY: 'city',
  STATE: 'state',
  COUNTRY: 'country',
  LATITUDE: 'latitude',
  LONGITUDE: 'longitude',
  TOTAL_TIME_WATCHED_SECONDS: 'totalTimeWatchedSeconds',
  USER_AGENT: 'userAgent',
  RESOLUTION: 'resolution',
  DEVICE_CLASS: 'deviceClass',
  DEVICE_BRAND: 'deviceBrand',
  OS_CLASS: 'osClass',
  OS_NAME_VERSION: 'osNameVersion',
} as const;

export const DEVICE_TYPES = {
  DESKTOP: 'desktop',
  MOBILE: 'mobile',
  TABLET: 'tablet',
  GAMECONSOLE: 'gameConsole',
  TV: 'tv',
  SET_TOP_BOX: 'setTopBox',
  OTHER: 'other',
} as const;

export const EVENT_ANALYTICS_TYPES = {
  VIEWERS: 'viewers',
  AVGWATCHTIME: 'avgWatchTime',
  LIVEVIEWS: 'liveViews',
  VIEWS: 'views',
} as const;

export const EVENT_TYPE = {
  ON_DEMAND: 'onDemand',
  REPLAY: 'replay',
  LIVE: 'live',
} as const;

export const FACEBOOK_DESTINATION_TYPES = {
  PAGE: 'page',
  TIMELINE: 'timeline',
  GROUP: 'group',
} as const;

export const LEGACY_SIM_LIVE = 'simLive';

export const LOCATION_TYPES = {
  COUNTRY: 'country',
  REGION: 'region',
  CITY: 'city',
} as const;

export const MIN_BY_MIN_EVENT_ANALYTICS_TYPES = {
  VIEWS: 'views',
  VIEWERS: 'viewers',
} as const;

export const MINUTES_WIDGET_TYPES = {
  VIEWER: 'Viewer',
  STREAMING: 'Streaming',
} as const;

export const SEGMENT_TYPES = {
  NONE: 'none',
  EVENT_TYPE: 'event_type',
  DEVICE_TYPE: 'device_type',
} as const;

export const SOCIAL_MEDIA_CSV_EXPORT_FIELDS = {
  EVENT_ID: 'eventId',
  SOCIAL_ID: 'socialId',
  DESTINATION_ID: 'destinationId',
  VIDEO_ID: 'videoId',
  LIVE_VIDEO_ID: 'liveVideoId',
  DESTINATION_TYPE: 'destinationType',
  API_SOURCE: 'apiSource',
  TIMESTAMP: 'timestamp',
  VIEWS: 'views',
  VIEWERS: 'viewers',
  AVERAGE_TIME_WATCHED_SECONDS: 'averageTimeWatchedSeconds',
  TOTAL_TIME_WATCHED_SECONDS: 'totalTimeWatchedSeconds',
  LIVE_VIEWS: 'liveViews',
} as const;

export const TIME_STAT_KEYS = {
  AVG_WATCH_TIME: {
    COUNT_KEY: 'avgTimeWatched',
    PERCENTAGE_KEY: 'avgTimeWatchedPercentageInc',
    ORDER: 3,
  },
  MEDIAN_TIME_WATCHED: {
    COUNT_KEY: 'medianTimeWatched',
    PERCENTAGE_KEY: 'medianTimeWatchedPercentageInc',
    ORDER: 8,
  },
  TOTAL_TIME_WATCHED: {
    COUNT_KEY: 'totalTimeWatched',
    PERCENTAGE_KEY: 'totalTimeWatchedPercentageInc',
    ORDER: 4,
  },
} as const;

export const TIME_WINDOW_TYPES = {
  DAY: 'day',
  WEEK: 'week',
  MONTH: 'month',
  MINUTE: 'minute',
} as const;

export const VIEWER_BREAKDOWN_TYPES = {
  RESOLUTION: 'resolution',
  WATCHTIME: 'watchTime',
  DEVICETYPE: 'deviceType',
} as const;

export const WEB_DESTINATION_TYPES = {
  EMBED: 'embed',
  STREAM: 'stream_url',
} as const;

export const BREAKDOWN_TYPES = {
  ...VIEWER_BREAKDOWN_TYPES,
  ...LOCATION_TYPES,
} as const;

export const CSV_EXPORT_FIELDS = {
  ...CONTENT_LIBRARY_EXPORT_FIELDS,
  ...DEFAULT_CSV_EXPORT_FIELDS,
  ...SOCIAL_MEDIA_CSV_EXPORT_FIELDS,
} as const;

export const STATS_KEYS = {
  ...COUNT_STAT_KEYS,
  ...TIME_STAT_KEYS,
} as const;
