import * as React from 'react';
import { Badge, Draft, Inline, Stack, Text } from '@resi-media/resi-ui';
import { useDispatch } from 'react-redux';
import { InvalidNotice } from '@studio/components/InvalidNotice';
import { INTERVAL_MS, MAX_TIMEOUT_COUNT } from '@studio/constants/destination-groups';
import { getStringForPrivacyOption } from '@studio/helpers';
import { useDestinations, usePrefix } from '@studio/hooks';
import { ActionTypes } from '@studio/store/social-media';
import type { DestinationGroups, Schedules } from '@studio/types';
import { BroadcastTime } from '../BroadcastTime';

const YoutubeSubcomponent = ({
  broadcastFields,
  member,
}: {
  broadcastFields?: DestinationGroups.Derived.BroadcastFields;
  member: Schedules.Derived.PartialContentDestinationSchedule;
}) => {
  const { commonT } = usePrefix('');
  const dispatch = useDispatch();
  const { socialMediaOptions, webPresetOptions } = useDestinations();
  const webPreset = webPresetOptions.find((option) => option.value === member.webEncoderProfileId);
  const invalidStatus = socialMediaOptions.yt.get(member.channelId || '')?.invalidStatus;
  const [timeoutCount, setTimeoutCount] = React.useState(0);
  const pollingRef = React.useRef<NodeJS.Timeout | null>(null);

  const handleGrantAccessPolling = () => {
    pollingRef.current = setInterval(() => {
      dispatch({ type: ActionTypes.FETCH_STATUS_REQUEST });
      setTimeoutCount((prev) => prev + 1);
    }, INTERVAL_MS);
  };

  React.useEffect(() => {
    if (timeoutCount >= MAX_TIMEOUT_COUNT || !invalidStatus) {
      if (pollingRef.current) {
        clearInterval(pollingRef.current);
      }
    }
  }, [invalidStatus, timeoutCount]);

  React.useEffect(() => {
    return () => {
      if (pollingRef.current) {
        clearInterval(pollingRef.current);
      }
    };
  }, []);

  return (
    <Stack dataTestId="youtube-subcomponent" gap="l">
      <InvalidNotice
        channelId={member.channelId}
        invalidStatus={invalidStatus}
        onCloseCallback={handleGrantAccessPolling}
      />
      <Inline>
        {member.privacy && (
          <Draft.FormField fieldLabel={commonT('privacy')}>
            <Badge
              colorVariant={['private', 'unlisted'].includes(member.privacy) ? 'warning' : 'primary'}
              dataTestId="privacy-badge"
            >
              {getStringForPrivacyOption(member.privacy)}
            </Badge>
          </Draft.FormField>
        )}
      </Inline>
      <Draft.FormField fieldLabel={commonT('webPreset')}>
        <Text dataTestId="web-preset-name" variant="body1">
          {webPreset?.label ?? commonT('default')}
        </Text>
      </Draft.FormField>
      <BroadcastTime broadcastFields={broadcastFields} member={member} />
      <Draft.FormField fieldLabel={commonT('youtubeVideoTitle')}>
        <Text dataTestId="youtube-video-title" variant="body1">
          {member.title}
        </Text>
      </Draft.FormField>
      <Draft.FormField fieldLabel={commonT('youtubeVideoDescription')}>
        <Text dataTestId="youtube-video-description" variant="body1">
          {member.description ?? '-'}
        </Text>
      </Draft.FormField>
    </Stack>
  );
};

YoutubeSubcomponent.displayName = 'YoutubeSubcomponent';

export default YoutubeSubcomponent;
