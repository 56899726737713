import type { CombinedState } from 'redux';
import { createSelector } from 'reselect';
import type { RootState } from '@studio/store/root-reducer';
import type { Decoders } from '@studio/types';
import type { MonitorState } from './types';

export const selectMonitorsState = (state: RootState): CombinedState<MonitorState> => state.monitors;

const decoders = {
  data: createSelector(selectMonitorsState, (state) => state.decoders.data),
  isFetching: createSelector(selectMonitorsState, (state) => state.decoders.isFetching),
};

const decodersByUser = createSelector(decoders.data, (decodersData) => {
  if (!decodersData.length) {
    return null;
  }

  return decodersData.reduce((acc: Record<string, (Decoders.Get.Status & { userName?: string })[]> | null, decoder) => {
    if (!acc?.[decoder.userName]) {
      if (!acc) {
        return (acc = { [decoder.userName]: [decoder] });
      } else {
        return (acc = {
          ...acc,
          [decoder.userName]: [decoder],
        });
      }
    }

    acc[decoder.userName].push(decoder);
    return acc;
  }, null);
});

export const select = {
  decoders: {
    ...decoders,
    byUser: decodersByUser,
  },
};
