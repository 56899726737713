import type { AllEffect, ForkEffect } from 'redux-saga/effects';
import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';
import { client } from '@studio/api/api-client/client';
import { CENTRAL_API_V3 } from '@studio/constants/env-variables';
import type { ApiClientConfig } from '@studio/types/axios';
import { selectAccessToken, selectCustomerId } from '../authentication/selectors';
import { fetchEncoderVideosAsync } from './actions';
import type { EncoderVideo } from './types';
import { ActionTypes } from './types';

function* handleFetch() {
  const customerId: string = yield select(selectCustomerId);
  const url = `${CENTRAL_API_V3}/customers/${customerId}/events`;
  const token: string = yield select(selectAccessToken);
  const config: ApiClientConfig = { token };
  try {
    const response: EncoderVideo[] = yield call(client, url, config);
    yield put(fetchEncoderVideosAsync.success(response));
  } catch (error) {
    yield put(fetchEncoderVideosAsync.failure(error as Error));
  }
}

export function* watchFetchRequest<T>(): Generator<ForkEffect<never>, void, T> {
  yield takeLatest(ActionTypes.FETCH_REQUEST, handleFetch);
}

export function* encoderVideoSaga<T>(): Generator<AllEffect<ForkEffect<void>>, void, T> {
  yield all([fork(watchFetchRequest)]);
}
