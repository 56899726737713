import { bucketSize } from './bucketSize';
import { feeds } from './feeds';
import { media } from './media';
import { playlists } from './playlists';
import { sites } from './sites';

export const v1 = {
  bucketSize,
  feeds,
  media,
  playlists,
  sites,
};
