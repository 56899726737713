import { root, id } from './encoders';
import * as idEndpoints from './id';

export const encoders = {
  ...root,
  id: {
    ...id,
    ...idEndpoints,
  },
};
