import * as React from 'react';
import { AppstoreOutlined, CloudServerOutlined, LockOutlined } from '@ant-design/icons';
import { css, useTheme } from '@emotion/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, BrandIcon, Draft, Inline, Link, PopoutAlt, RLogo, Stack, Text } from '@resi-media/resi-ui';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { match } from 'ts-pattern';
import { DESTINATION, DESTINATION_GROUP } from '@studio/constants/content-destination';
import { EMAIL_SUCCESS } from '@studio/constants/external-links-constants';
import { useDestinations, useIsPlanType, usePrefix, useRtmpAccess, useRtmpDiscoverability } from '@studio/hooks';
import { selectCustomerData } from '@studio/store';
import { fetch, selectItems } from '@studio/store/destination-groups';
import type { DestinationGroups } from '@studio/types';
import ResiDestinations from '@svg/ResiDestinations.svg';
import { DestinationGroupFields } from '../DestinationGroupFields';
import { FacebookFields } from './components/FacebookFields';
import { RtmpFields } from './components/RtmpFields';
import { WebFields } from './components/WebFields';
import { YoutubeFields } from './components/YoutubeFields';
import { VALIDATION, validationSchema } from './constants';
import { getInitialValues } from './helpers/getInitialValues';

const DestinationModal = () => {
  const isMounted = React.useRef(false);
  const dispatch = useDispatch();
  const destinationGroups = useSelector(selectItems);
  const { commonT, linkT, prefixNS, t, Trans } = usePrefix('components:', 'destinationModal');
  const theme = useTheme();
  const { isSocialOnly } = useIsPlanType();
  const showSocialDestinations = Boolean(useSelector(selectCustomerData)?.webPlan?.numberOfSocialMediaDestinations);
  const hasRtmpAccess = useRtmpAccess();
  const hasRtmpDiscoverability = useRtmpDiscoverability();

  const {
    currentMembers,
    defaultTitle,
    isEdit = false,
    onCloseReset,
    onSave,
    selectedMember,
    selectedMemberIndex,
    selectedType,
    setModalState,
    showDestinationGroups,
  } = Draft.ModalContext.useModal<DestinationGroups.Derived.DestinationModalState>();

  const headerText = match({ isEdit, selectedType })
    .with({ isEdit: true, selectedType: DESTINATION.FACEBOOK }, () => prefixNS('editFBDestination'))
    .with({ isEdit: true, selectedType: DESTINATION.YOUTUBE }, () => prefixNS('editYTDestination'))
    .with({ isEdit: true, selectedType: DESTINATION.EMBED }, () => prefixNS('editWebChannelDestination'))
    .with({ isEdit: true, selectedType: DESTINATION.RTMP }, () => prefixNS('editRTMPDestination'))
    .otherwise(() => prefixNS('addDestination'));

  const initialValues = getInitialValues({ defaultTitle: defaultTitle || prefixNS('watchOurStream') });
  const methods = useForm<DestinationGroups.Derived.DestinationForm>({
    mode: 'all',
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues,
  });

  const { handleSubmit, reset, setError } = methods;

  const { dispatchGroup } = useDestinations();

  React.useEffect(() => {
    dispatchGroup();
    dispatch(fetch.destinationGroups.request());
  }, [dispatch, dispatchGroup]);

  const handleDestinationChange: Draft.ButtonGroup.OnChange = (_idx, props) => {
    switch (props.id) {
      case DESTINATION.EMBED:
        setModalState({ selectedType: DESTINATION.EMBED });
        reset({ ...initialValues, web: { ...initialValues.web, include: true } });
        break;
      case DESTINATION.FACEBOOK:
        setModalState({ selectedType: DESTINATION.FACEBOOK });
        reset({ ...initialValues, fb: { ...initialValues.fb, include: true } });
        break;
      case DESTINATION.YOUTUBE:
        setModalState({ selectedType: DESTINATION.YOUTUBE });
        reset({ ...initialValues, yt: { ...initialValues.yt, include: true } });
        break;
      case DESTINATION.RTMP:
        setModalState({ selectedType: DESTINATION.RTMP });
        reset({ ...initialValues, rtmp: { ...initialValues.rtmp, include: true } });
        break;
      case DESTINATION_GROUP:
        setModalState({ selectedType: DESTINATION_GROUP });
        reset({ ...initialValues, destinationGroup: { ...initialValues.destinationGroup, include: true } });
    }
  };

  React.useEffect(() => {
    if (isEdit && selectedMember && !isMounted.current) {
      let web: DestinationGroups.Derived.DestinationFormMember = initialValues.web;
      let fb: DestinationGroups.Derived.DestinationFormMember = initialValues.fb;
      let yt: DestinationGroups.Derived.DestinationFormMember = initialValues.yt;
      let rtmp: DestinationGroups.Derived.DestinationFormMember = initialValues.rtmp;
      switch (selectedMember.type) {
        case DESTINATION.EMBED:
          web = {
            autoArchive: selectedMember.autoArchive,
            autoArchivePlaylists: selectedMember.autoArchivePlaylists,
            description: selectedMember.description,
            include: true,
            title: selectedMember.title,
            webEncoderProfileId: selectedMember.webEncoderProfileId,
            webEventProfileId: selectedMember.webEventProfileId,
            type: selectedMember.type,
          };
          break;
        case DESTINATION.FACEBOOK:
          fb = {
            autoArchive: false,
            autoArchivePlaylists: [],
            channelId: selectedMember.channelId,
            description: selectedMember.description,
            destinationId: selectedMember.partnerData?.facebookPartnerData.destinationId,
            include: true,
            title: selectedMember.title,
            crossposts: selectedMember.partnerData?.facebookPartnerData.crossposts.map((cp) => ({
              checked: true,
              data: { ...cp, destinationName: cp.destinationName ?? '' },
              label: cp.destinationName ?? '',
              value: cp.destinationId,
            })),
            privacy: selectedMember.privacy,
            publishStatus: selectedMember.publishStatus,
            type: selectedMember.type,
            webEncoderProfileId: selectedMember.webEncoderProfileId,
            socialDestinationType: selectedMember.partnerData?.facebookPartnerData.type,
          };
          break;
        case DESTINATION.RTMP:
          rtmp = {
            autoArchive: false,
            autoArchivePlaylists: [],
            include: true,
            title: selectedMember.title,
            rtmpDestinationId: selectedMember.rtmpDestinationId,
            type: selectedMember.type,
            webEncoderProfileId: selectedMember.webEncoderProfileId,
          };
          break;
        case DESTINATION.YOUTUBE:
          yt = {
            autoArchive: false,
            autoArchivePlaylists: [],
            channelId: selectedMember.channelId,
            description: selectedMember.description,
            include: true,
            title: selectedMember.title,
            privacy: selectedMember.privacy,
            publishStatus: selectedMember.publishStatus,
            type: selectedMember.type,
            webEncoderProfileId: selectedMember.webEncoderProfileId,
          };
          break;
        default:
      }

      reset({
        ...initialValues,
        web,
        fb,
        rtmp,
        yt,
      });
    }
    isMounted.current = true;
  }, [initialValues, isEdit, reset, selectedMember, selectedType]);

  const handleSave = (data: DestinationGroups.Derived.DestinationForm) => {
    const { destinationGroup, fb, rtmp, web, yt } = data;
    let destinationGroupMember: DestinationGroups.Post.Member | undefined;
    let destinationGroupMembers: DestinationGroups.Post.Member[] | undefined;
    switch (selectedType) {
      case DESTINATION.EMBED:
        if (
          currentMembers.some((member, index) => {
            const isSameWebChannel = member.webEventProfileId === data.web.webEventProfileId;
            const isSameIndexPosition = index === selectedMemberIndex;
            return (!isEdit && isSameWebChannel) || (isEdit && isSameWebChannel && !isSameIndexPosition);
          })
        ) {
          setError('web.webEventProfileId', { type: VALIDATION.IN_USE, message: 'already in use' });
          return;
        }
        destinationGroupMember = {
          autoArchive: web.autoArchive,
          autoArchivePlaylists: web.autoArchivePlaylists,
          description: web.description,
          title: web.title,
          webEncoderProfileId: web.webEncoderProfileId,
          webEventProfileId: web.webEventProfileId,
          type: selectedType,
        };
        break;
      case DESTINATION.FACEBOOK:
        destinationGroupMember = {
          autoArchive: false,
          autoArchivePlaylists: [],
          channelId: fb.channelId,
          description: fb.description,
          title: fb.title,
          partnerData: {
            facebookPartnerData: {
              crossposts:
                fb.crossposts
                  ?.filter((cp) => cp.checked)
                  .map((cp) => ({
                    destinationId: cp.data.destinationId,
                  })) ?? [],
              destinationId: fb.destinationId,
              type: fb.socialDestinationType,
            },
          },
          privacy: fb.privacy,
          publishStatus: fb.publishStatus,
          type: selectedType,
          webEncoderProfileId: fb.webEncoderProfileId,
        };
        break;
      case DESTINATION.RTMP:
        if (
          currentMembers.some((member, index) => {
            const isSameRtmpDestination = member.rtmpDestinationId === data.rtmp.rtmpDestinationId;
            const isSameIndexPosition = index === selectedMemberIndex;
            return (!isEdit && isSameRtmpDestination) || (isEdit && isSameRtmpDestination && !isSameIndexPosition);
          })
        ) {
          setError('rtmp.rtmpDestinationId', { type: VALIDATION.IN_USE, message: 'already in use' });
          return;
        }
        destinationGroupMember = {
          autoArchive: false,
          autoArchivePlaylists: [],
          title: undefined,
          rtmpDestinationId: rtmp.rtmpDestinationId,
          type: selectedType,
          webEncoderProfileId: rtmp.webEncoderProfileId,
        };
        break;
      case DESTINATION.YOUTUBE:
        destinationGroupMember = {
          autoArchive: false,
          autoArchivePlaylists: [],
          channelId: yt.channelId,
          description: yt.description,
          title: yt.title,
          privacy: yt.privacy,
          publishStatus: yt.publishStatus,
          type: selectedType,
          webEncoderProfileId: yt.webEncoderProfileId,
        };
        break;
      case DESTINATION_GROUP:
        destinationGroupMember = undefined;
        destinationGroupMembers = destinationGroups.find((group) => group.id === destinationGroup.id)?.members;
        break;
      default:
        return;
    }

    onSave({
      destination: destinationGroupMember,
      destinationGroupMembers,
      editMemberIndex: selectedMemberIndex,
    });
    onCloseReset();
  };

  return (
    <FormProvider {...methods}>
      <form
        css={css`
          display: contents;
        `}
        data-testid="add-destination-form"
        id="add-destination-form"
        onSubmit={handleSubmit(handleSave)}
      >
        <Draft.ModalHeader dataTestId="modal-header" header={headerText} onClose={onCloseReset} showBorder />
        <Draft.ModalBody data-testid="add-destination-body" id="add-destination-modal">
          {/* NOTE: position: relative and form element with display: contents required for modal fix: https://livingasone.atlassian.net/browse/EVENT-1431 */}
          <Box position="relative">
            <Stack gap="l">
              {!isEdit && (
                <Draft.FormField fieldLabel={commonT('destinationType')} htmlFor="content-destination-options">
                  <Draft.CardIconGroup defaultIndex={showDestinationGroups ? 0 : -1} onChange={handleDestinationChange}>
                    {showDestinationGroups && (
                      <Draft.CardIconButton
                        data-testid="destination-groups-button"
                        fontSize={theme.typography.pxToRem(30)}
                        icon={<AppstoreOutlined />}
                        id={DESTINATION_GROUP}
                        label={commonT('destinationGroup')}
                        value={DESTINATION_GROUP}
                      />
                    )}
                    <Draft.CardIconButton
                      {...(isSocialOnly && {
                        customCss: {
                          container: css`
                            display: none;
                          `,
                        },
                      })}
                      data-testid="web-destination-button"
                      disabled={isSocialOnly}
                      fontSize={theme.typography.pxToRem(30)}
                      icon={<RLogo color={theme.palette.brand.resiRed} />}
                      id={DESTINATION.EMBED}
                      label={commonT('web')}
                      value={DESTINATION.EMBED}
                    />
                    <Draft.CardIconButton
                      data-testid="facebook-destination-button"
                      disabled={!showSocialDestinations}
                      icon={<BrandIcon variant="fb" />}
                      id={DESTINATION.FACEBOOK}
                      label={commonT('facebook')}
                      value={DESTINATION.FACEBOOK}
                    />
                    <Draft.CardIconButton
                      data-testid="youtube-destination-button"
                      disabled={!showSocialDestinations}
                      icon={<BrandIcon variant="yt" />}
                      id={DESTINATION.YOUTUBE}
                      label={commonT('youtube')}
                      value={DESTINATION.YOUTUBE}
                    />
                    {(hasRtmpAccess || hasRtmpDiscoverability) && (
                      <Draft.CardIconButton
                        data-testid="rtmp-destination-button"
                        dataTrackId="rtmp-destination-add"
                        fontSize={theme.typography.pxToRem(30)}
                        icon={
                          hasRtmpAccess ? (
                            <CloudServerOutlined style={{ color: theme.palette.text.primary }} />
                          ) : (
                            <LockOutlined />
                          )
                        }
                        id={DESTINATION.RTMP}
                        label={commonT('customRTMP')}
                        value={DESTINATION.RTMP}
                      />
                    )}
                  </Draft.CardIconGroup>
                </Draft.FormField>
              )}
              {match({ selectedType, hasRtmpAccess, hasRtmpDiscoverability })
                .with({ selectedType: DESTINATION.EMBED }, () => (
                  <WebFields isPlaylistToggled={Boolean(selectedMember?.autoArchivePlaylists?.length)} />
                ))
                .with({ selectedType: DESTINATION.FACEBOOK }, () => <FacebookFields />)
                .with({ selectedType: DESTINATION_GROUP }, () => <DestinationGroupFields />)
                .with({ selectedType: DESTINATION.YOUTUBE }, () => <YoutubeFields />)
                .with({ selectedType: DESTINATION.RTMP, hasRtmpAccess: true }, () => <RtmpFields />)
                .with({ selectedType: DESTINATION.RTMP, hasRtmpDiscoverability: true }, () => (
                  <Stack gap="m">
                    <Text dataTestId="nudge-title" variant="h4">
                      {t('pages:rtmpDestinations.nudge.title')}
                    </Text>
                    <Text dataTestId="nudge-description">{t('pages:rtmpDestinations.nudge.description')}</Text>
                    <Text dataTestId="add-feature-prompt">
                      <Trans i18nKey="pages:rtmpDestinations.nudge.emailSuccess">
                        fill
                        <Link dataTestId="email-success-link" href={`${EMAIL_SUCCESS}?subject=Request to add RTMP`}>
                          fill
                        </Link>
                      </Trans>
                    </Text>
                    <Box
                      css={(theme) => css`
                        font-size: ${theme.typography.pxToRem(125)};
                      `}
                      dataTestId="resi-destinations-svg"
                      display="flex"
                      justifyContent="center"
                    >
                      <ResiDestinations />
                    </Box>
                  </Stack>
                ))
                .otherwise(() => null)}
            </Stack>
          </Box>
        </Draft.ModalBody>
        <Draft.ModalFooter>
          <Inline>
            {selectedType === DESTINATION.RTMP && hasRtmpDiscoverability ? (
              <Draft.Button
                as="a"
                dataTestId="learn-more-button"
                endNode={<PopoutAlt />}
                href={linkT('rtmpFeaturePage')}
                label={commonT('learnMore')}
                target="_blank"
              />
            ) : (
              <React.Fragment>
                <Draft.Button
                  dataTestId="cancel-button"
                  label={commonT('cancel')}
                  onClick={onCloseReset}
                  variant="text"
                />
                <Draft.Button
                  data-trackid="destination-group__add-destination-confirm"
                  dataTestId="confirm-submit"
                  disabled={!selectedType}
                  form="add-destination-form"
                  label={isEdit ? commonT('save') : commonT('add')}
                  type="submit"
                />
              </React.Fragment>
            )}
          </Inline>
        </Draft.ModalFooter>
      </form>
    </FormProvider>
  );
};

DestinationModal.displayName = 'DestinationModal';

export default DestinationModal;
