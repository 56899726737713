import { createAction, createAsyncAction } from 'typesafe-actions';
import type { Hardware } from '@studio/types';
import { ActionTypes } from './types';

export const fetch = {
  models: createAsyncAction(
    ActionTypes.FETCH_MODELS_REQUEST,
    ActionTypes.FETCH_MODELS_REQUEST_SUCCESS,
    ActionTypes.FETCH_MODELS_REQUEST_ERROR,
    ActionTypes.FETCH_MODELS_REQUEST_CANCEL
  )<undefined, Hardware.Get.HardwareModel[], Error, undefined>(),
  locations: createAsyncAction(
    ActionTypes.FETCH_LOCATIONS_REQUEST,
    ActionTypes.FETCH_LOCATIONS_REQUEST_SUCCESS,
    ActionTypes.FETCH_LOCATIONS_REQUEST_ERROR,
    ActionTypes.FETCH_LOCATIONS_REQUEST_CANCEL
  )<undefined, Hardware.Get.HardwareLocation[], Error, undefined>(),
  status: createAsyncAction(
    ActionTypes.FETCH_STATUS_REQUEST,
    ActionTypes.FETCH_STATUS_REQUEST_SUCCESS,
    ActionTypes.FETCH_STATUS_REQUEST_ERROR,
    ActionTypes.FETCH_STATUS_REQUEST_CANCEL
  )<undefined, Hardware.Get.HardwareStatus[], Error, undefined>(),
  cycles: createAsyncAction(
    ActionTypes.FETCH_CYCLES_REQUEST,
    ActionTypes.FETCH_CYCLES_REQUEST_SUCCESS,
    ActionTypes.FETCH_CYCLES_REQUEST_ERROR,
    ActionTypes.FETCH_CYCLES_REQUEST_CANCEL
  )<undefined, Hardware.Get.HardwareCycles[], Error, undefined>(),
};

export const set = {
  modelsTimestamp: createAction(ActionTypes.FETCH_MODELS_REQUEST_TIMESTAMP)<number>(),
  locationsTimestamp: createAction(ActionTypes.FETCH_LOCATIONS_REQUEST_TIMESTAMP)<number>(),
  statusTimestamp: createAction(ActionTypes.FETCH_STATUS_REQUEST_TIMESTAMP)<number>(),
  cyclesTimestamp: createAction(ActionTypes.FETCH_CYCLES_REQUEST_TIMESTAMP)<number>(),
};
