import { request } from './request';
import { root } from './reset-password';
import { verify } from './verify';

export const resetPassword = {
  ...root,
  request: {
    ...request,
  },
  verify: {
    ...verify,
  },
};
