import type { AllEffect, ForkEffect } from 'redux-saga/effects';
import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects';
import { client } from '@studio/api/api-client/client';
import { LOGS_GATEWAY_API_V1 } from '@studio/constants/env-variables';
import type { EncoderLogs } from '@studio/types';
import { selectCustomerId, selectAccessToken } from '../authentication';
import { fetch } from './actions';
import { ActionTypes } from './types';

function* handleFetch(action: ReturnType<typeof fetch.logs.request>) {
  const token: string = yield select(selectAccessToken);
  const customerId: string = yield select(selectCustomerId);
  const url = `${LOGS_GATEWAY_API_V1}/logs/${customerId}/logs/${action.payload.encoderId}?start=${action.payload.start}&end=${action.payload.end}&minlevel=${action.payload.minLevel}`;
  try {
    const response: EncoderLogs.Get.LogEntry[] = yield call(client, url, { token });
    yield put(fetch.logs.success({ encoderId: action.payload.encoderId, logs: response }));
  } catch (error) {
    if (error instanceof Error) {
      yield put(fetch.logs.failure(error));
    }
  }
}

export function* watchFetchRequest<T>(): Generator<ForkEffect<void>, void, T> {
  yield takeEvery(ActionTypes.FETCH_REQUEST, handleFetch);
}

export function* encoderLogsSaga<T>(): Generator<AllEffect<ForkEffect<void>>, void, T> {
  yield all([fork(watchFetchRequest)]);
}
