import * as React from 'react';
import { useTheme } from '@emotion/react';
import { Draft, Inline, Progress } from '@resi-media/resi-ui';
import { useIsOverflow, usePrefix, useResizeObserver } from '@studio/hooks';
import { ErrorBlock } from '../ErrorBlock';

type _Props = {
  cancelLabel?: string;
  cancelTrackId?: string;
  children: React.ReactNode;
  colorVariant?: Draft.ButtonBase.Color;
  dataTrackId?: string;
  errorMessage?: string | unknown;
  handleClose?: (event?: React.SyntheticEvent) => void;
  handleSubmit?: (event?: React.SyntheticEvent) => void;
  isDisabled?: boolean;
  isHandlingSubmit?: boolean;
  isOpen?: boolean;
  isUsingContext?: boolean;
  shouldReturnFocusAfterClose?: boolean;
  submitButtonProps?: Omit<Draft.Button.Props, 'label'>;
  submitLabel?: string;
  title: string;
  /**
   * alert: will only display the cancel button
   * confirmation: will display both submit and cancel buttons
   * info: will hide both submit and cancel buttons
   * @default confirmation
   */
  variant?: 'alert' | 'confirmation' | 'info';
};

const ConfirmationModal = ({
  cancelLabel,
  cancelTrackId,
  children,
  colorVariant = 'primary',
  dataTrackId,
  errorMessage,
  handleClose,
  handleSubmit,
  isDisabled = false,
  isHandlingSubmit,
  isOpen = false,
  isUsingContext = false,
  shouldReturnFocusAfterClose = true,
  submitButtonProps = {},
  submitLabel,
  title,
  variant = 'confirmation',
}: _Props) => {
  const theme = useTheme();
  const { commonT } = usePrefix('');

  const [contentNode, contentObserverEntry] = useResizeObserver();
  const [containerNode, containerObserverEntry] = useResizeObserver();
  const isOverflow = useIsOverflow(containerObserverEntry, contentObserverEntry);

  const modalChildren = () => (
    <React.Fragment>
      <Draft.ModalHeader dataTestId="remove-title" header={title} onClose={handleClose} showBorder />
      <Draft.ModalBody ref={containerNode}>
        <div ref={contentNode} data-testid="submit-content">
          <ErrorBlock
            {...(typeof errorMessage === 'string' && Boolean(errorMessage) && { customErrorMessage: errorMessage })}
            error={errorMessage}
            marginBottom={theme.spacing.m}
          />
          <span>{children}</span>
        </div>
      </Draft.ModalBody>
      {variant !== 'info' && (
        <Draft.ModalFooter dataTestId="modal-footer" showBorder={isOverflow}>
          <Inline alignItems="center" gap="l" justifyContent="flex-end">
            {handleClose && (
              <Draft.Button
                data-testid="submit-cancel"
                dataTrackId={cancelTrackId}
                label={cancelLabel || commonT(variant !== 'alert' ? 'cancel' : 'close')}
                onClick={handleClose}
                variant={variant !== 'alert' ? 'text' : undefined}
              />
            )}
            {variant !== 'alert' && (handleSubmit || submitButtonProps.type === 'submit') && (
              <Draft.Button
                colorVariant={colorVariant}
                data-testid="confirm-submit"
                data-trackid={dataTrackId}
                disabled={isHandlingSubmit || isDisabled}
                label={submitLabel || commonT('submit')}
                onClick={handleSubmit}
                {...(isHandlingSubmit && {
                  startNode: <Progress colorVariant="inherit" dataTestId="remove-spinner" sizeVariant="inherit" />,
                })}
                {...submitButtonProps}
              />
            )}
          </Inline>
        </Draft.ModalFooter>
      )}
    </React.Fragment>
  );

  return isUsingContext ? (
    modalChildren()
  ) : (
    <Draft.Modal
      dataTestId="confirmation-modal"
      isOpen={isOpen}
      minHVariant="s"
      onClose={handleClose}
      shouldReturnFocusAfterClose={shouldReturnFocusAfterClose}
    >
      {modalChildren()}
    </Draft.Modal>
  );
};

ConfirmationModal.displayName = 'ConfirmationModal';

export default ConfirmationModal;
