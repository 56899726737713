import * as React from 'react';
import { Draft } from '@resi-media/resi-ui';
import { useNavigate } from 'react-router-dom';
import UrlPaths from '@studio/constants/url-paths';
import { useIsAuthorized, usePrefix } from '@studio/hooks';
import { permissions } from '@studio/store';

const InvalidEmailBanner = () => {
  const { prefixNS, PrefixTrans } = usePrefix('components:', 'invalidEmailBanner');
  const hasInviteUserPermission = useIsAuthorized([permissions.INVITED_USERS_ADD]);
  const navigate = useNavigate();

  return (
    <Draft.AlertBanner
      {...(hasInviteUserPermission && {
        buttonLabel: prefixNS('createNewAccount'),
        buttonProps: {
          onClick: () => navigate(UrlPaths.SETTINGS.USERS_INVITE),
          dataTestId: 'create-new-account-button',
        },
      })}
      dataTestId="invalid-email-banner"
      marginBottom="m"
      variant="negative"
    >
      <PrefixTrans i18nKey="descriptionOne">
        filler text
        {!hasInviteUserPermission && <>{{ additionalDescription: prefixNS('descriptionTwo') }}</>}
      </PrefixTrans>
    </Draft.AlertBanner>
  );
};

InvalidEmailBanner.displayName = 'InvalidEmailBanner';

export default InvalidEmailBanner;
