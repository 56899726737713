import { useIsPlanType } from '../use-is-plan-type';
import { useRtmpAccess } from '../use-rtmp-access';

const useRtmpDiscoverability = () => {
  const hasRtmpAccess = useRtmpAccess();
  const { isSocialOnly } = useIsPlanType();
  return !hasRtmpAccess && !isSocialOnly;
};

export default useRtmpDiscoverability;
