import * as React from 'react';
import { Draft } from '@resi-media/resi-ui';
import type { Option } from '@resi-media/resi-ui';
import { Controller, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { NoSelectionDisplay } from '@studio/components/NoSelectionDisplay';
import { VALIDATION } from '@studio/constants/destination-groups';
import UrlPaths from '@studio/constants/url-paths';
import { sortDestinationMembers } from '@studio/helpers';
import { useDestinations, usePrefix } from '@studio/hooks';
import { selectItems } from '@studio/store/destination-groups';
import type { DestinationGroups } from '@studio/types';
import { DestinationsTable } from '../DestinationsTable';

const DestinationGroupFields = () => {
  const { commonT, prefixNS } = usePrefix('components:', 'destinationModal');
  const destinationGroups = useSelector(selectItems);
  const { control, setValue } = useFormContext<DestinationGroups.Derived.DestinationForm>();
  const { onCloseReset } = Draft.ModalContext.useModal<DestinationGroups.Derived.DestinationModalState>();
  const { rtmpOptions, socialMediaOptions, webChannelOptions } = useDestinations();

  const destinationGroupOptions = destinationGroups
    .map(({ id, name }) => ({
      label: name,
      value: id,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  const hasDestinationGroups = Boolean(destinationGroups.length);

  const noDestinationGroup = {
    label: prefixNS('fields.destinationGroup.errors.noDestinationGroup'),
    value: '',
  };

  React.useEffect(() => {
    if (destinationGroupOptions.length === 1) {
      setValue('destinationGroup.id', destinationGroupOptions[0].value, {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
    }
  }, [setValue, destinationGroupOptions]);

  return (
    <Controller
      control={control}
      name="destinationGroup.id"
      render={({
        field: { name, onBlur, onChange, ref, value: selectedDestinationGroupId },
        fieldState: { error, isTouched },
        formState: { isSubmitted },
      }) => {
        const isRequired = error?.type === VALIDATION.REQUIRED && prefixNS('fields.destinationGroup.errors.required');
        const noDestinationGroupError =
          !hasDestinationGroups && prefixNS('fields.destinationGroup.errors.noDestinationGroup');
        const selectedDestinationGroup = destinationGroups.find(({ id }) => id === selectedDestinationGroupId);
        return (
          <React.Fragment>
            <Draft.FormField
              dataTestId="destination-group-select-field"
              error={
                isRequired ||
                (noDestinationGroupError && (
                  <NoSelectionDisplay
                    linkText={prefixNS('fields.destinationGroup.add')}
                    onClose={onCloseReset}
                    urlPath={UrlPaths.SETTINGS.DESTINATION_GROUPS_CREATE_PANE}
                    warningText={prefixNS('fields.destinationGroup.errors.noDestinationGroup')}
                  />
                ))
              }
              fieldLabel={prefixNS('fields.destinationGroup.label')}
              htmlFor={name}
              touched={isTouched || isSubmitted || !hasDestinationGroups}
            >
              <Draft.Select<Option>
                ref={ref}
                appendToBody
                dataTestId={name}
                disabled={!hasDestinationGroups}
                hasError={Boolean(error?.message) || !hasDestinationGroups}
                onBlur={onBlur}
                onChange={(option) => {
                  if (option) {
                    onChange(option.value);
                  }
                }}
                options={destinationGroupOptions}
                placeholder={commonT('selectDestination')}
                value={
                  !hasDestinationGroups
                    ? noDestinationGroup
                    : destinationGroupOptions.find((v) => v.value === selectedDestinationGroupId)
                }
              />
            </Draft.FormField>
            {selectedDestinationGroup && (
              <DestinationsTable
                groupMembers={sortDestinationMembers({
                  members: selectedDestinationGroup.members,
                  rtmpOptions,
                  socialMediaOptions,
                  webChannelOptions,
                })}
              />
            )}
          </React.Fragment>
        );
      }}
    />
  );
};

DestinationGroupFields.displayName = 'DestinationGroupFields';

export default DestinationGroupFields;
