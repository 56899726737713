import { HTTP_METHODS } from '@studio/constants/axios';
import { CENTRAL_API_V3 } from '@studio/constants/env-variables';
import type { Client } from '@studio/types';

export const root = {
  [HTTP_METHODS.GET]: {
    url: `${CENTRAL_API_V3}/customers/@customerId/clients`,
    response: [] as Client.Get.Client[],
    method: HTTP_METHODS.GET,
  },
  [HTTP_METHODS.POST]: {
    url: `${CENTRAL_API_V3}/customers/@customerId/clients`,
    request: {} as Client.Post.Client,
    response: {} as Client.Post.ClientResponse,
    method: HTTP_METHODS.POST,
  },
};

export const id = {
  [HTTP_METHODS.DELETE]: {
    url: `${CENTRAL_API_V3}/customers/@customerId/clients/@id`,
    params: {} as { id: string },
    method: HTTP_METHODS.DELETE,
  },
  [HTTP_METHODS.PATCH]: {
    url: `${CENTRAL_API_V3}/customers/@customerId/clients/@id`,
    params: {} as { id: string },
    method: HTTP_METHODS.PATCH,
  },
};
