type MapReturn<T> = (
  | {
      reason: unknown;
      status: string;
      value?: undefined;
    }
  | {
      reason?: undefined;
      status: string;
      value: T;
    }
)[];

type OptionalNestedParam<U> = U extends string[] ? string[] : string[][];
type OptionalParam<U> = U extends string[] ? string : string[];
type MapFn<T, U> = (currentValue: OptionalParam<U>, index: number, array: OptionalNestedParam<U>) => Promise<T>;

const arrayGenerator = function* <U>(array: OptionalNestedParam<U>) {
  for (let index = 0; index < array.length; index++) {
    yield { currentValue: array[index] as OptionalParam<U>, index, array };
  }
};

const mapItem = async <T, U>(
  mapFn: MapFn<T, U>,
  currentValue: OptionalParam<U>,
  index: number,
  array: OptionalNestedParam<U>
) => {
  try {
    return {
      status: 'fulfilled',
      value: await mapFn(currentValue, index, array),
    };
  } catch (reason) {
    return {
      status: 'rejected',
      reason,
    };
  }
};

const worker = async <T, U>(
  id: number,
  gen: Generator<
    {
      array: OptionalNestedParam<U>;
      currentValue: OptionalParam<U>;
      index: number;
    },
    void,
    unknown
  >,
  mapFn: MapFn<T, U>,
  result: MapReturn<T>
) => {
  for (const { array, currentValue, index } of gen) {
    // uncomment the following lines to see the time taken by each worker
    // console.time(`Worker ${id} --- index ${index} item ${currentValue}`);
    result[index] = await mapItem<T, U>(mapFn, currentValue, index, array);
    // console.timeEnd(`Worker ${id} --- index ${index} item ${currentValue}`);
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
async function asyncMap<T, U extends Array<any> = string[]>(
  arr: OptionalNestedParam<U>,
  mapFn: MapFn<T, U>,
  limit = arr.length
): Promise<MapReturn<T>> {
  const result: MapReturn<T> = [];

  if (arr.length === 0) {
    return [];
  }

  const gen = arrayGenerator<U>(arr);

  limit = Math.min(limit, arr.length);

  const workers = new Array(limit);
  for (let i = 0; i < limit; i++) {
    workers.push(worker<T, U>(i, gen, mapFn, result));
  }

  await Promise.all(workers);

  return result;
}

async function asyncMapChunk<T>(
  arr: string[][],
  mapFn: MapFn<T, string[][]>,
  limit = arr.length
): Promise<MapReturn<T>> {
  return asyncMap<T, string[][]>(arr, mapFn, limit);
}

export { asyncMap, asyncMapChunk };
export type { MapReturn };
