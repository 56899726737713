import type { SerializedStyles, Theme } from '@emotion/react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

const TRANSITION_TIME = '200ms';

export const UploadSquareButton = styled.button`
  width: 131px;
  height: 85px;
  border: 1px dashed ${(props) => props.theme.palette.border};
  background-color: ${(props) => props.theme.palette.background.table};
  &:hover {
    cursor: pointer;
    border: 1px solid ${(props) => props.theme.palette.primary.hover};
  }
`;

export const UploadedThumbnailImage = styled.img<{ isHovering: boolean }>`
  height: 85px;
  background-color: black;
  border: 2px solid
    ${({ isHovering, theme }) => (isHovering ? theme.palette.negative.main : theme.palette.primary.main)};
  border-radius: ${(props) => props.theme.shape.borderRadius.s};
  box-sizing: border-box;
  transition: border ${TRANSITION_TIME};
`;

export const NoThumbnailPlaceholder = styled.div`
  height: 85px;
  width: 148px;
  background-color: black;
  border: 2px solid ${({ theme }) => theme.palette.primary.main};
  border-radius: ${(props) => props.theme.shape.borderRadius.s};
  box-sizing: border-box;
`;

export const RemoveThumbnailMask = styled.div<{ isHovering: boolean }>`
  background-color: ${({ isHovering, theme }) => (isHovering ? theme.palette.common.black : 'transparent')};
  opacity: ${({ isHovering }) => (isHovering ? '0.5' : '0')};
  transition:
    opacity ${TRANSITION_TIME},
    background-color ${TRANSITION_TIME};
  height: 81px;
  width: 144px;
  box-sizing: border-box;
  position: absolute;
  top: 2px;
  left: 2px;
`;

export const deleteIconStyles = (theme: Theme): SerializedStyles => css`
  color: ${theme.palette.negative.main};
  font-size: 30px;
`;

export const DeleteIconWrapper = styled.div<{ isHovering: boolean }>`
  position: absolute;
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
  top: 0;
  left: 0;

  opacity: ${({ isHovering }) => (isHovering ? '1' : '0')};
  transition: opacity ${TRANSITION_TIME};
`;

export const ThumbnailParentDiv = styled.div`
  position: relative;
  height: 85px;
  width: 144px;
`;
