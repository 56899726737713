import * as React from 'react';
import { produce } from 'immer';
import type { SideMenuCategory } from '@studio/types/navigation';

export interface NavContextState {
  isHamburgerMenuOpen: boolean;
  menuCategories: SideMenuCategory[];
  showMobileNav: boolean;
  showTabletNav: boolean;
}

export const initialNavState: NavContextState = {
  isHamburgerMenuOpen: false,
  menuCategories: [],
  showMobileNav: false,
  showTabletNav: false,
};

interface NavContextProps {
  dispatch: React.Dispatch<NavAction>;
  state: NavContextState;
}

export enum NAV_ACTION {
  DISPLAY_MOBILE_NAV,
  DISPLAY_TABLET_NAV,
  SECTION,
  TOGGLE_HAMBURGER_MENU,
}

export type NavAction =
  | { payload: boolean; type: NAV_ACTION.DISPLAY_MOBILE_NAV }
  | { payload: boolean; type: NAV_ACTION.DISPLAY_TABLET_NAV }
  | { payload: SideMenuCategory[]; type: NAV_ACTION.SECTION }
  | { type: NAV_ACTION.TOGGLE_HAMBURGER_MENU };

export const navReducer = produce((draft: NavContextState, action: NavAction): NavContextState => {
  switch (action.type) {
    case NAV_ACTION.DISPLAY_MOBILE_NAV:
      draft.showMobileNav = action.payload;
      return draft;
    case NAV_ACTION.DISPLAY_TABLET_NAV:
      draft.showTabletNav = action.payload;
      return draft;
    case NAV_ACTION.SECTION:
      draft.menuCategories = action.payload;
      return draft;
    case NAV_ACTION.TOGGLE_HAMBURGER_MENU:
      draft.isHamburgerMenuOpen = !draft.isHamburgerMenuOpen;
      return draft;
  }
});

const NavContext: React.Context<NavContextProps> = React.createContext<NavContextProps>({} as NavContextProps);

export default NavContext;
