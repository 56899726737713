const downloadAsCsv = (data: string, exportedFilename = 'export-data.csv'): void => {
  const blob = new Blob([data], { type: 'text/csv;charset=utf-8;' });
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, exportedFilename);
  }
  const link = document.createElement('a');
  // feature detection
  // Browsers that support HTML5 download attribute
  const url = URL.createObjectURL(blob);
  link.setAttribute('href', url);
  link.setAttribute('download', exportedFilename);
  link.style.visibility = 'hidden';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export { downloadAsCsv };
