import * as React from 'react';
import { Draft, Link, Stack, Text } from '@resi-media/resi-ui';
import { usePrefix } from '@studio/hooks';
import { ConfirmationModal } from '../ConfirmationModal';

const WrongProfileTypeModal = () => {
  const { commonT, linkT, prefixNS } = usePrefix('pages:', 'library');
  const { onClose } = Draft.ModalContext.useModal();

  return (
    <ConfirmationModal
      handleClose={onClose}
      isOpen
      title={prefixNS('webBroadcast.wrongWebEventProfileType.title')}
      variant="alert"
    >
      <Stack gap="m">
        <Text>
          {prefixNS('webBroadcast.wrongWebEventProfileType.message')}
          &nbsp;
          <Link href={linkT('exportVideoSettings')} rel="noopener noreferrer" target="_blank">
            {commonT('sentenceCase', { value: commonT('learnMore') })}
          </Link>
        </Text>
      </Stack>
    </ConfirmationModal>
  );
};

WrongProfileTypeModal.displayName = 'WrongProfileTypeModal';

export default WrongProfileTypeModal;
