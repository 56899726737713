import type { Encoders } from '@studio/types';

export enum ActionTypes {
  REMOVE_UPDATING_ENCODER_ID = '@@encoders/REMOVE_UPDATING_ENCODER_ID',
  SET_ENCODERS = '@@encoders/SET_ENCODERS',
  SET_UPDATING_ENCODERS = '@@encoders/SET_UPDATING_ENCODERS',
}
export type EncodersState = {
  items: Encoders.Derived.CombinedEncoder[];
  updatingEncoders: Map<string, Encoders.Derived.CombinedEncoder>;
};
