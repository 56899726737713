export namespace Components {
  export type AudioAdaptationSet = {
    language?: string | null;
    left: number;
    right: number | null;
  };

  export type VideoRepresentation = {
    frameRate: string;
    height: number;
    width: number;
  };

  export type WebPresetModalState = {
    deinterlace?: boolean;
    leftAudioChannel?: AudioChannel;
    name?: string;
    rightAudioChannel?: AudioChannel;
    videoChannel?: VIDEO_CHANNEL;
  };

  export type RemoveModalState = {
    error: unknown;
    isSubmitting: boolean;
    name: string;
    onSuccess: () => void;
    uuid: string;
  };

  export type AudioOption = {
    label: string;
    value: number | null;
  };

  export enum AudioChannel {
    None,
    Ch1,
    Ch2,
    Ch3,
    Ch4,
    Ch5,
    Ch6,
    Ch7,
    Ch8,
    Ch9,
    Ch10,
    Ch11,
    Ch12,
    Ch13,
    Ch14,
    Ch15,
    Ch16,
  }

  export enum VIDEO_CHANNEL {
    None,
    Ch1,
    Ch2,
  }
}
