import * as React from 'react';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { LD_ANONYMOUS_CONTEXT } from '@studio/constants/api-constants';
import { SESSION_STRINGS } from '@studio/constants/software-encoder';
import { TrackEventName } from '@studio/constants/tracking-constants';
import UrlPaths from '@studio/constants/url-paths';
import { AuthenticationActionTypes, tracking } from '@studio/store';

const useSignOut = (): (() => void) => {
  const reduxDispatch = useDispatch();
  const ldClient = useLDClient();
  const navigate = useNavigate();

  return React.useCallback(() => {
    reduxDispatch({
      type: tracking.ActionTypes.TRACK_SEGMENT_REQUEST,
      payload: {
        eventName: TrackEventName.USER_LOGOUT,
      },
    });
    window.analytics.reset();
    ldClient?.identify(LD_ANONYMOUS_CONTEXT)?.catch((error) => {
      console.error('error initializing Launch Darkly:', error.message);
    });
    const resiSoftwareUrl = sessionStorage.getItem(SESSION_STRINGS.HREF);
    // TODO: Get rid of this trash as soon as possible
    // Needed so the navigation doesn't occur before the logout process is complete
    // https://redux.js.org/style-guide/#do-not-put-non-serializable-values-in-state-or-actions
    reduxDispatch({
      type: AuthenticationActionTypes.LOGOUT_REQUEST,
      payload: {
        onSuccess: resiSoftwareUrl
          ? () => (window.location.href = resiSoftwareUrl)
          : () => navigate(UrlPaths.ROOT, { replace: true }),
      },
    });
  }, [ldClient, navigate, reduxDispatch]);
};

export default useSignOut;
