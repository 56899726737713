import type { CombinedState } from 'redux';
import { createSelector } from 'reselect';
import type { Schedules } from '@studio/types';
import type { RootState } from '../root-reducer';
import type { CachedItems, ContentScheduleInitialState } from './types';

export const selectContentScheduleState = (state: RootState): CombinedState<ContentScheduleInitialState> =>
  state.contentSchedule;

export const selectCachedItems = createSelector(
  selectContentScheduleState,
  (state): Map<string, CachedItems> => state.cachedItems
);

export const selectFilter = createSelector(selectContentScheduleState, (state): string => state.filter);

export const selectItems = createSelector(selectContentScheduleState, (state): Schedules.Get.ContentSchedule[] =>
  Array.from(state.cachedItems.values()).flatMap((x) => x.items)
);

export const filterItems = createSelector(selectItems, selectFilter, (items, filter): Schedules.Get.ContentSchedule[] =>
  items.filter((item) => item.title.toLowerCase().includes(filter.toLowerCase()))
);

export const selectTimestamp = createSelector(selectContentScheduleState, (state): number => state.timestamp);
export const contentScheduleIsFetching = createSelector(selectContentScheduleState, (state) => state.isFetching);
export const selectHasFetched = createSelector(selectContentScheduleState, (state) => state.hasFetched);
