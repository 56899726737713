import * as React from 'react';
import { Draft, Progress, Stack, Text } from '@resi-media/resi-ui';
import type { UserOnboardingErrorType } from '@studio/types/email-validation';
import { HeaderlessContent } from '../HeaderlessContent';
import { LogoHeader } from '../LogoHeader';
import { S } from './styles';

type _Props = {
  error?: UserOnboardingErrorType;
  header: string;
  subheader: string;
};

const Loading = ({ error, header, subheader }: _Props) => {
  return (
    <HeaderlessContent showFooter version={process.env.VERSION}>
      <Stack alignItems="center">
        <LogoHeader />
        <div css={S.boxWrap}>
          <Text as="h4">{header}</Text>
          <Text>{subheader}</Text>
          <div css={S.spinnerWrapper}>
            {error?.errorMessage ? (
              <Draft.AlertBanner buttonLabel={error.errorLinkText} buttonProps={{ onClick: error.onClick }}>
                {error.errorMessage}
              </Draft.AlertBanner>
            ) : (
              <Progress dataTestId="new-user-spinner" sizeVariant="m" />
            )}
          </div>
        </div>
      </Stack>
    </HeaderlessContent>
  );
};

Loading.displayName = 'Loading';

export default Loading;
