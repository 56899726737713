import { HTTP_METHODS } from '@studio/constants/axios';
import { SYS_EVENT_API_V1 } from '@studio/constants/env-variables';
import type { SystemEvents } from '@studio/types';

export const root = {
  [HTTP_METHODS.GET]: {
    method: HTTP_METHODS.GET,
    query: {} as SystemEvents.Get.Params,
    response: [] as SystemEvents.Get.Event[],
    url: `${SYS_EVENT_API_V1}/customers/@customerId/events`,
  },
};
