import type { CombinedState } from 'redux';
import { createSelector } from 'reselect';
import type { EncoderProfiles } from '@studio/types';
import type { RootState } from '../root-reducer';
import type { EncoderPresetState } from './types';

export const selectEncoderPresetState = (state: RootState): CombinedState<EncoderPresetState> => state.encoderPresets;

export const selectFilter = createSelector(selectEncoderPresetState, (state): string => state.presets.filter);

export const selectPresets = createSelector(
  selectEncoderPresetState,
  (state): EncoderProfiles.Get.Preset[] => state.presets.items
);

export const selectOptions = createSelector(
  selectEncoderPresetState,
  (state): EncoderProfiles.Get.Options | null => state.options.items
);

export const filterEncoderPresets = createSelector(
  selectPresets,
  selectFilter,
  (items, filter): EncoderProfiles.Get.Preset[] => {
    return items.filter((item) => {
      return (
        item.name.toLowerCase().includes(filter.toLowerCase()) ||
        item.customName?.toLowerCase().includes(filter.toLowerCase())
      );
    });
  }
);

export const selectPreset = createSelector(
  selectEncoderPresetState,
  (state): EncoderProfiles.Get.Preset | null => state.presets.preset
);

export const selectOptionsTimestamp = createSelector(
  selectEncoderPresetState,
  (state): number => state.options.timestamp
);
export const selectListTimestamp = createSelector(selectEncoderPresetState, (state): number => state.presets.timestamp);
