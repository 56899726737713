import type { SagaIterator } from 'redux-saga';
import type { AllEffect, ForkEffect } from 'redux-saga/effects';
import { all, call, cancelled, fork, put, select, takeLatest } from 'redux-saga/effects';
import { client } from '@studio/api/api-client/client';
import { STALE_DATA_TIMEOUT } from '@studio/constants/api-constants';
import { CENTRAL_API_V4 } from '@studio/constants/env-variables';
import type { DestinationGroups } from '@studio/types';
import type { ApiClientConfig } from '@studio/types/axios';
import { selectCustomerId, selectAccessToken } from '../authentication';
import { fetch, set } from './actions';
import { selectTimestamp } from './selectors';
import { ActionTypes } from './types';

function* handleFetch(): SagaIterator {
  const source = new AbortController();
  const timestamp: number = yield select(selectTimestamp);
  if (Date.now() - timestamp < STALE_DATA_TIMEOUT) {
    yield put(fetch.destinationGroups.cancel());
    return;
  }

  const customerId: string = yield select(selectCustomerId);
  const url = `${CENTRAL_API_V4}/customers/${customerId}/destinationgroups`;
  const token: string = yield select(selectAccessToken);
  const config: ApiClientConfig = { token };
  try {
    const response: DestinationGroups.Get.DestinationGroup[] = yield call(client, url, config);
    yield put(fetch.destinationGroups.success(response));
    yield put(set.timestamp(Date.now()));
  } catch (error) {
    if (error instanceof Error) {
      yield put(fetch.destinationGroups.failure(error));
    }
  } finally {
    if (yield cancelled()) {
      source.abort('cancelled');
      yield put(fetch.destinationGroups.cancel());
    }
  }
}

function* handleFetchDestinationGroup(action: ReturnType<typeof fetch.destinationGroup.request>): SagaIterator {
  const source = new AbortController();
  const destinationId = action.payload.destinationId;
  const customerId: string = yield select(selectCustomerId);
  const token: string = yield select(selectAccessToken);
  const config: ApiClientConfig = { token };
  const url = `${CENTRAL_API_V4}/customers/${customerId}/destinationgroups/${destinationId}`;

  try {
    const response: DestinationGroups.Get.DestinationGroup = yield call(client, url, config);
    yield put(fetch.destinationGroup.success({ destinationId, response }));
  } catch (error) {
    if (error instanceof Error) {
      yield put(fetch.destinationGroup.failure(error));
    }
  } finally {
    if (yield cancelled()) {
      source.abort('cancelled');
      yield put(fetch.destinationGroup.cancel());
    }
  }
}

export function* watchFetchRequest<T>(): Generator<ForkEffect<never>, void, T> {
  yield takeLatest(ActionTypes.FETCH_REQUEST, handleFetch);
}

export function* watchFetchDestinationRequest<T>(): Generator<ForkEffect<never>, void, T> {
  yield takeLatest(ActionTypes.FETCH_DESTINATION_REQUEST, handleFetchDestinationGroup);
}

export function* destinationGroupsSaga<T>(): Generator<AllEffect<ForkEffect<void>>, void, T> {
  yield all([fork(watchFetchRequest), fork(watchFetchDestinationRequest)]);
}
