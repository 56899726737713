import { produce } from 'immer';
import type { ActionType, PayloadAction } from 'typesafe-actions';
import { createReducer } from 'typesafe-actions';
import type { ContentDestinations } from '@studio/types';
import {
  addStoppedContentDestination,
  fetchContentDestinationsAsync,
  fetchTransitioningContentDestinationsAsync,
  setSortOrder,
  updateSingleContentDestination,
  deleteContentDestination,
} from './actions';
import type { ContentDestinationsState, ActionTypes } from './types';
import { SortOptions } from './types';

type Action = ActionType<
  | typeof addStoppedContentDestination
  | typeof deleteContentDestination
  | typeof fetchContentDestinationsAsync
  | typeof fetchTransitioningContentDestinationsAsync
  | typeof setSortOrder
  | typeof updateSingleContentDestination
>;

export const initialState: ContentDestinationsState = {
  isFetching: false,
  isFetchingInitial: false,
  items: [],
  error: null,
  sortBy: SortOptions.MOST_RECENT,
  stopped: [], // video IDs that have been manually stopped by the user
};

const reducer = createReducer<ContentDestinationsState, Action>(initialState)
  .handleAction(
    fetchContentDestinationsAsync.request,
    produce((draft: ContentDestinationsState) => {
      draft.isFetching = true;
      draft.isFetchingInitial = draft.items.length === 0;
      draft.error = null;
    })
  )
  .handleAction(
    fetchContentDestinationsAsync.success,
    produce(
      (
        draft: ContentDestinationsState,
        action: PayloadAction<ActionTypes.FETCH_REQUEST_SUCCESS, ContentDestinations.Get.ContentDestination[]>
      ) => {
        draft.isFetching = false;
        draft.isFetchingInitial = false;
        draft.items = action.payload;
      }
    )
  )
  .handleAction(
    fetchContentDestinationsAsync.failure,
    produce((draft: ContentDestinationsState, action: PayloadAction<ActionTypes.FETCH_REQUEST_ERROR, Error>) => {
      draft.isFetching = false;
      draft.isFetchingInitial = false;
      draft.error = action.payload;
    })
  )
  .handleAction(
    fetchContentDestinationsAsync.cancel,
    produce((draft: ContentDestinationsState) => {
      draft.isFetching = false;
      draft.isFetchingInitial = false;
    })
  )
  .handleAction(
    fetchTransitioningContentDestinationsAsync.success,
    produce(
      (
        draft: ContentDestinationsState,
        action: PayloadAction<
          ActionTypes.FETCH_REQUEST_TRANSITIONING_ONLY_SUCCESS,
          ContentDestinations.Get.ContentDestination[]
        >
      ) => {
        const updatedItems = [...draft.items];
        // find the "transitioning" items in our list and update them
        action.payload.forEach((updatedItem) => {
          const index = updatedItems.findIndex((existingItem) => existingItem.id === updatedItem.id);
          if (index !== -1) {
            updatedItems[index] = updatedItem;
          }
        });
        draft.items = updatedItems;
      }
    )
  )
  .handleAction(
    fetchTransitioningContentDestinationsAsync.failure,
    produce(
      (
        draft: ContentDestinationsState,
        _action: PayloadAction<ActionTypes.FETCH_REQUEST_TRANSITIONING_ONLY_ERROR, Error>
      ) => {
        // create a new reference array, which will cause our UI component to re-render and check to see if any items are transitioning.
        // this will allow us to continue checking with server for transitioning items, even if we have network issues.
        draft.items = [...draft.items];
      }
    )
  )
  .handleAction(
    updateSingleContentDestination,
    produce(
      (
        draft: ContentDestinationsState,
        action: PayloadAction<ActionTypes.UPDATE_SINGLE_ITEM, ContentDestinations.Get.ContentDestination>
      ) => {
        if (draft.items.length > 0) {
          const index = draft.items.findIndex((item) => item.id === action.payload.id);
          if (index !== -1) {
            const updatedItems = [...draft.items];
            updatedItems[index] = action.payload;
            draft.items = updatedItems;
          }
        }
      }
    )
  )
  .handleAction(
    deleteContentDestination,
    produce((draft: ContentDestinationsState, action: PayloadAction<ActionTypes.DELETE_ITEM, string>) => {
      if (action.payload && draft.items.length > 0) {
        const index = draft.items.findIndex((item) => item.id === action.payload);
        if (index !== -1) {
          const updatedItems = [...draft.items];
          updatedItems.splice(index, 1);
          draft.items = updatedItems;
        }
      }
    })
  )
  .handleAction(
    setSortOrder,
    produce((draft: ContentDestinationsState, action: PayloadAction<ActionTypes.SORT_RESULTS, string>) => {
      draft.sortBy = action.payload;
    })
  )
  .handleAction(
    addStoppedContentDestination,
    produce((draft: ContentDestinationsState, action: PayloadAction<ActionTypes.ADD_STOPPED_ITEM, string>) => {
      draft.stopped = [...draft.stopped, action.payload];
    })
  );

export default reducer;
