import * as React from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import { css, useTheme } from '@emotion/react';
import type { MergeElementProps, ResponsiveValue, Spacing } from '@resi-media/resi-ui';
import { Draft, Inline, Stack, Text, sanitizeProps } from '@resi-media/resi-ui';

type _Props = MergeElementProps<
  'div',
  {
    dataTestId?: string;
    gap?: ResponsiveValue<
      // eslint-disable-next-line @typescript-eslint/ban-types
      keyof Spacing | (number & {}) | (string & {})
    >;
    helpTooltip?: React.ReactNode;
    icon?: React.ReactNode;
    isFullWidth?: boolean;
    title?: string;
    titleButtonCallback?: () => void;
    titleButtonLabel?: string;
    titleTestId?: string;
    variant?: 'create' | 'default';
  }
>;

const FormSectionInternal = (
  {
    children,
    dataTestId,
    gap = 's',
    helpTooltip,
    icon,
    isFullWidth,
    title,
    titleButtonCallback,
    titleButtonLabel,
    titleTestId,
    variant = 'default',
    ...rest
  }: _Props,
  ref: React.ForwardedRef<HTMLDivElement>
) => {
  const theme = useTheme();

  const renderContent = React.useMemo(() => {
    if (variant === 'create') {
      return (
        <Stack dataTestId="form-section-create" gap="xs">
          {children}
        </Stack>
      );
    } else {
      return (
        <React.Fragment>
          {(title || icon) && (
            <Inline justifyContent="space-between">
              <Inline
                alignItems="center"
                customCss={{
                  container: css`
                    margin-bottom: ${theme.spacing.l};
                  `,
                }}
                gap="m"
              >
                <div data-testid="form-section-icon" style={{ color: theme.palette.text.title }}>
                  {icon}
                </div>
                <Text colorVariant="heading" data-testid={titleTestId} variant="body1" weightVariant="semiBold">
                  {title}
                </Text>
                {helpTooltip && (
                  <Draft.Tooltip content={helpTooltip} placement="right">
                    <InfoCircleOutlined
                      data-testid="form-section-help-icon"
                      style={{ color: theme.palette.primary.main }}
                    />
                  </Draft.Tooltip>
                )}
              </Inline>
              {titleButtonLabel && (
                <Draft.Button
                  dataTestId="title-button"
                  label={titleButtonLabel}
                  onClick={titleButtonCallback}
                  sizeVariant="s"
                  variant="text"
                />
              )}
            </Inline>
          )}
          <Stack data-testid="form-section-container" gap={gap} ml={{ xs: 0, lg: 'xl' }}>
            {children}
          </Stack>
        </React.Fragment>
      );
    }
  }, [children, icon, gap, helpTooltip, theme, title, titleButtonCallback, titleButtonLabel, titleTestId, variant]);

  return (
    <div
      ref={ref}
      css={css`
        width: ${isFullWidth ? '100%' : '680px'};
      `}
      data-testid={dataTestId}
      {...sanitizeProps(rest)}
    >
      {renderContent}
    </div>
  );
};

FormSectionInternal.displayName = 'FormSection';

export const FormSection = React.forwardRef(FormSectionInternal);
