import { match } from 'ts-pattern';
import { NavCategory } from '@studio/constants/nav-constants';
import UrlPaths from '@studio/constants/url-paths';

function getCurrentNavCategory(path: string, isHamburgerMenu?: boolean): NavCategory | -1 {
  const isPathInCategory = (categoryRoot: string) => path.startsWith(categoryRoot);

  return (
    match(path)
      .when(
        () => isPathInCategory(UrlPaths.MEDIA.ROOT),
        () => NavCategory.MEDIA
      )
      .when(
        () => isPathInCategory(UrlPaths.SCHEDULE.ROOT),
        () => NavCategory.SCHEDULE
      )
      .when(
        () => isPathInCategory(UrlPaths.ANALYTICS.ROOT),
        () => NavCategory.ANALYTICS
      )
      .when(
        () => path === UrlPaths.SETTINGS.ACCOUNT && isHamburgerMenu,
        () => NavCategory.ACCOUNT
      )
      .when(
        () => isPathInCategory(UrlPaths.SETTINGS.ROOT),
        () => NavCategory.SETTINGS
      )
      .when(
        () => isPathInCategory(UrlPaths.ADMIN.ROOT),
        () => NavCategory.ADMIN
      )
      .when(
        () => isPathInCategory(UrlPaths.HELP.ROOT),
        () => NavCategory.HELP
      )
      .when(
        () => isPathInCategory(UrlPaths.OPEN_SOURCE_INFO),
        () => -1
      )
      // TODO: (j$ 9/29/2021) figure out a better way to match urls
      // that have tokens, ie. /settings/social-media/:uuid
      // Dashboard check MUST be last since the root ='/'
      .when(
        () => isPathInCategory(UrlPaths.DASHBOARD.ROOT),
        () => NavCategory.DASHBOARD
      )
      .otherwise(() => -1)
  );
}

export default getCurrentNavCategory;
