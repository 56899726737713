import { useSelector } from 'react-redux';
import { ENTERPRISE_VOD_PLAN, PREMIUM_VOD_PLAN, PRO_VOD_PLAN, STARTER_VOD_PLAN } from '@studio/constants/library';
import { selectVodPlanId } from '@studio/store';
import type { Library } from '@studio/types';

const useIsVodPlanType = (): Library.Derived.VodPlanType => {
  const vodPlanId = useSelector(selectVodPlanId) ?? '';

  return {
    isEnterprise: vodPlanId === ENTERPRISE_VOD_PLAN,
    isPremium: vodPlanId === PREMIUM_VOD_PLAN,
    isPro: vodPlanId === PRO_VOD_PLAN,
    isStarter: vodPlanId === STARTER_VOD_PLAN,
    vodPlanId,
  };
};

export default useIsVodPlanType;
