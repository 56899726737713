import { produce } from 'immer';
import type { ActionType } from 'typesafe-actions';
import { createReducer } from 'typesafe-actions';
import { fetch, set } from './actions';
import type { EncoderPresetState } from './types';

type Action = ActionType<typeof fetch | typeof set>;

export const initialState: EncoderPresetState = {
  options: {
    isFetching: false,
    items: null,
    timestamp: 0,
    error: null,
  },
  presets: {
    isFetching: false,
    items: [],
    timestamp: 0,
    error: null,
    preset: null,
    filter: '',
  },
};

const reducer = createReducer<EncoderPresetState, Action>(initialState)
  // start options
  .handleAction(
    fetch.options.request,
    produce((draft: EncoderPresetState) => {
      draft.options.isFetching = true;
      draft.options.error = null;
    })
  )
  .handleAction(
    fetch.options.success,
    produce((draft: EncoderPresetState, action: ActionType<typeof fetch.options.success>) => {
      draft.options.isFetching = false;
      draft.options.items = action.payload;
    })
  )
  .handleAction(
    fetch.options.failure,
    produce((draft: EncoderPresetState, action: ActionType<typeof fetch.options.failure>) => {
      draft.options.isFetching = false;
      draft.options.error = action.payload;
    })
  )
  .handleAction(
    fetch.options.cancel,
    produce((draft: EncoderPresetState) => {
      draft.options.isFetching = false;
    })
  )
  .handleAction(
    set.options.timestamp,
    produce((draft: EncoderPresetState, action: ActionType<typeof set.options.timestamp>) => {
      draft.options.timestamp = action.payload;
    })
  )
  // start list
  .handleAction(
    fetch.presets.request,
    produce((draft: EncoderPresetState) => {
      draft.presets.isFetching = true;
      draft.presets.error = null;
    })
  )
  .handleAction(
    fetch.presets.success,
    produce((draft: EncoderPresetState, action: ActionType<typeof fetch.presets.success>) => {
      draft.presets.isFetching = false;
      draft.presets.items = action.payload;
    })
  )
  .handleAction(
    fetch.presets.failure,
    produce((draft: EncoderPresetState, action: ActionType<typeof fetch.presets.failure>) => {
      draft.presets.isFetching = false;
      draft.presets.error = action.payload;
    })
  )
  .handleAction(
    fetch.presets.cancel,
    produce((draft: EncoderPresetState) => {
      draft.presets.isFetching = false;
    })
  )
  .handleAction(
    set.presets.timestamp,
    produce((draft: EncoderPresetState, action: ActionType<typeof set.presets.timestamp>) => {
      draft.presets.timestamp = action.payload;
    })
  )
  .handleAction(
    set.presets.preset,
    produce((draft: EncoderPresetState, action: ActionType<typeof set.presets.preset>) => {
      draft.presets.preset = action.payload;
    })
  )
  .handleAction(
    set.presets.filter,
    produce((draft: EncoderPresetState, action: ActionType<typeof set.presets.filter>) => {
      draft.presets.filter = action.payload;
    })
  );
export default reducer;
