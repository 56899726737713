import * as React from 'react';
import { css, useTheme } from '@emotion/react';
import { Inline, SecurityShield, Text, ToggleInput, Stack, useThemeConfig } from '@resi-media/resi-ui';
import { createPortal } from 'react-dom';
import { useAdmin } from '@studio/contexts/admin';
import { usePrefix } from '@studio/hooks/use-prefix';
import { useTimeMode } from '@studio/hooks/use-time-mode';

type _Props = object;

/* eslint-disable import/export */
const AdminActionMenuInternal = (_props: _Props, ref: React.ForwardedRef<HTMLDivElement>) => {
  const { prefixNS } = usePrefix('navigation:');
  const { isResiAdmin, setView, showAdminView } = useAdmin();
  const { setMode } = useThemeConfig();
  const { setTimeMode } = useTimeMode();
  const theme = useTheme();
  const [isHidden, setIsHidden] = React.useState(false);
  const [timeMode, setLocalTimeMode] = React.useState(localStorage.getItem('studio-time-mode') === '24hour');

  const handleKeyPress = React.useCallback(
    (event: KeyboardEvent) => {
      if (event.altKey && !event.ctrlKey && !event.shiftKey && !event.metaKey) {
        if (event.code === 'KeyX') {
          setIsHidden(!isHidden);
        } else if (event.code === 'KeyV') {
          setMode(theme.palette.activeMode === 'dark' ? 'light' : 'dark');
        } else if (event.code === 'KeyZ') {
          setLocalTimeMode(!timeMode);
          setTimeMode(!timeMode);
        } else if (event.code === 'KeyC') {
          setView(!showAdminView);
        }
      }
    },
    [isHidden, setMode, setTimeMode, setView, showAdminView, theme.palette.activeMode, timeMode]
  );

  React.useEffect(() => {
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleKeyPress, isHidden]);

  const CONTENT_SIZE = 220;
  const TAB_SIZE = 10;

  return (
    isResiAdmin &&
    createPortal(
      <div
        ref={ref}
        css={css`
          position: fixed;
          top: calc(50% - 28px);
          right: -${TAB_SIZE}px;
          transform: translateX(${TAB_SIZE + CONTENT_SIZE}px);
          background-color: ${theme.palette.brand.resiRed};
          color: white;
          text-align: center;
          border-top-left-radius: ${theme.shape.borderRadius.l};
          border-bottom-left-radius: ${theme.shape.borderRadius.l};
          display: flex;
          align-items: center;
          justify-content: center;
          gap: ${theme.spacing.s};
          opacity: 0.7;
          transition: transform 0.2s ease-out;
          z-index: ${theme.zIndex.tooltip + 1};

          :hover {
            ${!isHidden &&
            `
            transform: translateX(0px);
            right: 0px;
            opacity: 0.9;
            `}
          }

          ${isHidden &&
          `
              right: -100%;
            `}
        `}
        data-testid="admin-action-menu"
      >
        <Inline alignItems="center" gap="m" px="s" py="s">
          <SecurityShield
            color="white"
            style={{
              fontSize: theme.typography.pxToRem(20),
            }}
          />
          <Stack
            css={css`
              width: ${CONTENT_SIZE}px;
            `}
            gap="xs"
          >
            <ToggleInput
              checked={!showAdminView}
              data-testid="admin-action-menu__toggle-admin"
              endNode={
                <Inline alignItems="center" gap="xs">
                  <Text colorVariant="inherit" variant="body2" weightVariant="medium">
                    {prefixNS('customerView')}
                  </Text>
                  <Text colorVariant="inherit" isItalic variant="body4" weightVariant="medium">
                    (Alt + C)
                  </Text>
                </Inline>
              }
              onChange={() => setView(!showAdminView)}
            />
            <ToggleInput
              checked={theme.palette.activeMode === 'dark'}
              data-testid="admin-action-menu__toggle-theme"
              endNode={
                <Inline alignItems="center" gap="xs">
                  <Text colorVariant="inherit" variant="body2" weightVariant="medium">
                    {prefixNS('darkMode')}
                  </Text>
                  <Text colorVariant="inherit" isItalic variant="body4" weightVariant="medium">
                    (Alt + V)
                  </Text>
                </Inline>
              }
              onChange={() => {
                setMode(theme.palette.activeMode === 'dark' ? 'light' : 'dark');
              }}
            />
            <ToggleInput
              checked={timeMode}
              data-testid="admin-action-menu__toggle-time"
              endNode={
                <Inline alignItems="center" gap="xs">
                  <Text colorVariant="inherit" variant="body2" weightVariant="medium">
                    {prefixNS('24HourView')}
                  </Text>
                  <Text colorVariant="inherit" isItalic variant="body4" weightVariant="medium">
                    (Alt + Z)
                  </Text>
                </Inline>
              }
              onChange={() => {
                setLocalTimeMode(!timeMode);
                setTimeMode(!timeMode);
              }}
            />
            <ToggleInput
              checked={isHidden}
              data-testid="admin-action-menu__toggle-view"
              endNode={
                <Inline alignItems="center" gap="xs">
                  <Text colorVariant="inherit" variant="body2" weightVariant="medium">
                    {prefixNS('hideAdminMenu')}
                  </Text>
                  <Text colorVariant="inherit" isItalic variant="body4" weightVariant="medium">
                    (Alt + X)
                  </Text>
                </Inline>
              }
              onChange={() => {
                setIsHidden(!isHidden);
              }}
            />
          </Stack>
        </Inline>
      </div>,
      document.body
    )
  );
};

AdminActionMenuInternal.displayName = 'AdminActionMenuInternal';

export const AdminActionMenu = React.forwardRef(AdminActionMenuInternal);

// eslint-disable-next-line no-redeclare
export namespace AdminActionMenu {
  export type Props = _Props;
}
