import * as React from 'react';
import { produce } from 'immer';

export type GlobalContextTypes = Breakpoint;

export enum Breakpoint {
  MOBILE_SMALL,
  MOBILE,
  TABLET,
  DESKTOP,
}

export interface GlobalContextState {
  currentBreakpoint: Breakpoint;
}

export const initialGlobalState: GlobalContextState = {
  currentBreakpoint: Breakpoint.MOBILE_SMALL,
};

interface GlobalContextProps {
  dispatch: React.Dispatch<GlobalAction>;
  state: GlobalContextState;
}

export enum GLOBAL_ACTION_TYPES {
  CURRENT_BREAKPOINT = 'CURRENT_BREAKPOINT',
}

export type GlobalAction = { payload: Breakpoint; type: GLOBAL_ACTION_TYPES.CURRENT_BREAKPOINT };

export const globalReducer = produce((draft: GlobalContextState, action: GlobalAction): GlobalContextState => {
  switch (action.type) {
    case GLOBAL_ACTION_TYPES.CURRENT_BREAKPOINT:
      draft.currentBreakpoint = action.payload;
      return draft;
  }
});

const GlobalContext: React.Context<GlobalContextProps> = React.createContext<GlobalContextProps>(
  {} as GlobalContextProps
);

export default GlobalContext;
