export enum FILE_VALIDATION_ERRORS {
  FILE_PROCESSING = 'FILE_PROCESSING',
  FILE_SIZE = 'FILE_SIZE',
  FILE_TYPE = 'FILE_TYPE',
  NO_FILE = 'NO_FILE',
}

export enum LINKS {
  FIRST = 'first',
  NEXT = 'next',
}
