// webapp/src/main/java/com/livingasone/backend/jaxrs/bean/Encoder.java
export const OPERATIONAL_STATE = {
  AWAIT_INPUT: 'awaitInput',
  RESTART: 'restart',
  START: 'start',
  STOP: 'stop',
  UPDATE: 'update',
  UPLOAD_LOGS: 'uploadLogs',
  UNKNOWN: 'unknown',
} as const;

// webapp/src/main/java/io/resi/central/services/DeviceStatusUtils.java
export const OFFLINE = 'offline';

export const ERROR = 'error';
export const ENCODER_DELAY_MINUTES = 5; //  NOTE: accounts for enough encoder shutdown time where override status is after shutdown window
export const UPDATE_REQUIRED = 'updateRequired';

export enum ENCODER_SETTINGS {
  AUTOMATIC,
  ADVANCED,
}

// webapp/src/main/java/io/resi/central/services/EncoderStatusService.java
// webapp/src/main/java/com/livingasone/backend/jaxrs/bean/EncoderStatus.java
export const ENCODER_STATUS = {
  APPLY_NETWORK: 'applyNetwork',
  APPLY_VIDEO_SOURCE: 'applyVideoSource',
  ERROR,
  NETWORK_APPLIED: 'networkApplied',
  NO_STREAM_PROFILE: 'no stream profile',
  OFFLINE,
  RESTARTING: 'restarting',
  STARTED: 'started',
  STARTING: 'starting',
  STOPPED: 'stopped',
  STOPPING: 'stopping',
  UPDATE_REQUIRED,
  UPDATING: 'updating',
  UPLOADING_LOGS: 'uploadedLogs',
  ...OPERATIONAL_STATE,
} as const;

// webapp/src/main/java/com/livingasone/backend/jaxrs/bean/v3/encoderonboard/EncoderOnboard.java
export const VIDEO_INPUT_SOURCE = {
  SDI: 'SDI',
  HDMI: 'HDMI',
} as const;

// webapp/src/main/java/io/resi/central/enums/EncoderFeature.java
export const ENCODER_FEATURE = {
  INPUT_DETECTION: 'INPUT_DETECTION',
  V3_PROFILE: 'V3_PROFILE',
};

// capture card description formatting
// webapp/src/main/java/com/livingasone/backend/jaxrs/bean/FrameRate.java
export const CAPTURE_CARD_DESC_FRONT_DELIMITER = 'at ';
export const CAPTURE_CARD_DESC_BACK_DELIMITER = ' fps';
export const FPS_LOOKUP = {
  '24000/1001': '23.98',
  '24000/1000': '24',
  '25000/1000': '25',
  '30000/1001': '29.97',
  '30000/1000': '30',
  '50000/1000': '50',
  '60000/1000': '60',
  '60000/1001': '59.94',
} as const;

export const TEMP_LEVEL = {
  CRITICAL: 'Critical',
  HIGH: 'High',
  NORMAL: 'Normal',
} as const;

export const ENCODER_MODEL = {
  E1210: 'E1210',
  E1300: 'E1300',
  SOFTWARE_UPLOADER: 'softwareUploader',
  OBS: 'obs',
} as const;

export const AVAILABLE_NETWORK_BANDWIDTH = {
  MIN: 5,
  MAX: 15,
} as const;
