import { carts } from './carts';
import { customers } from './customers';
import { salesforce } from './salesforce';
import { subscriptions } from './subscriptions';
import { zuora } from './zuora';

export const v1 = {
  carts,
  customers,
  salesforce,
  subscriptions,
  zuora,
};
