class Permissions {
  static readonly API_ACCESS_GET = 'getApiApps';
  static readonly API_ACCESS_ADD = 'addApiApps';
  static readonly API_ACCESS_UPDATE = 'updateApiApps';
  static readonly API_ACCESS_DELETE = 'deleteApiApps';

  static readonly CAMPUS_REVIEW_GET = 'getCampusReview';

  static readonly CONTACTS_ADD = 'addContacts';
  static readonly CONTACTS_DELETE = 'deleteContacts';
  static readonly CONTACTS_GET = 'getContacts';
  static readonly CONTACTS_IMPORT = 'deleteCustomers'; //la1only
  static readonly CONTACTS_UPDATE = 'updateContacts';

  static readonly CONTACT_SEARCH_GET = 'deleteCustomers'; //la1only

  static readonly CUES_ADD = 'addCues';
  static readonly CUES_DELETE = 'deleteCues';
  static readonly CUES_GET = 'getCues';
  static readonly CUES_UPDATE = 'updateCues';

  static readonly CUSTOMERS_ADD = 'addCustomers';
  static readonly CUSTOMERS_DELETE = 'deleteCustomers'; //la1only
  static readonly CUSTOMERS_GET_ALL = 'getCustomers';
  static readonly CUSTOMERS_GET_ONE = 'getCustomer';
  static readonly CUSTOMERS_UPDATE = 'updateCustomers';

  static readonly DESTINATION_GROUPS_ADD = 'addDestinationGroups';
  static readonly DESTINATION_GROUPS_DELETE = 'deleteDestinationGroups';
  static readonly DESTINATION_GROUPS_GET = 'getDestinationGroups';
  static readonly DESTINATION_GROUPS_UPDATE = 'updateDestinationGroups';

  static readonly ENCODERS_ADD = 'addEncoders';
  static readonly ENCODERS_DELETE = 'deleteEncoders';
  static readonly ENCODERS_GET = 'getEncoders';
  static readonly ENCODERS_UPDATE = 'updateEncoders';
  static readonly ENCODERS_UPLOAD_LOGS = 'uploadEncodersLogs';

  static readonly SOFTWARE_ENCODERS_DELETE = 'deleteSoftwareEncoders';

  static readonly ENCODER_PROFILES_ADD = 'addEncoderProfiles';
  static readonly ENCODER_PROFILES_DELETE = 'deleteEncoderProfiles';
  static readonly ENCODER_PROFILES_GET = 'getEncoderProfiles';
  static readonly ENCODER_PROFILES_UPDATE = 'updateEncoderProfiles';

  static readonly EVENTS_ADD = 'addEvents';
  static readonly EVENTS_DELETE = 'deleteEvents';
  static readonly EVENTS_DOWNLOAD = 'downloadEvents';
  static readonly EVENTS_GET = 'getEvents';
  static readonly EVENTS_START_TRANSCODING = 'startTranscoder';
  static readonly EVENTS_UPDATE = 'updateEvents';

  static readonly EVENT_PROFILES_ADD = 'addEventProfiles';
  static readonly EVENT_PROFILES_DELETE = 'deleteEventProfiles';
  static readonly EVENT_PROFILES_GET = 'getEventProfiles';
  static readonly EVENT_PROFILES_LAN_ONLY = 'deleteEventProfiles';
  static readonly EVENT_PROFILES_UPDATE = 'updateEventProfiles';

  static readonly EVENT_PROFILES_USERS_ADD = 'addEventProfileUsers';
  static readonly EVENT_PROFILES_USERS_ADD_BY_ORG = 'deleteEventProfiles';
  static readonly EVENT_PROFILES_USERS_DELETE = 'deleteEventProfileUsers';
  static readonly EVENT_PROFILES_USERS_GET = 'getEventProfileUsers';
  static readonly EVENT_PROFILES_USERS_UPDATE = 'updateEventProfiles';
  static readonly EVENT_PROFILES_VIEW_BUCKET = 'deleteEventProfiles';

  static readonly FEED_ADD = 'addFeed';
  static readonly FEED_DELETE = 'deleteFeed';
  static readonly FEED_GET = 'getFeed';
  static readonly FEED_UPDATE = 'updateFeed';

  static readonly HARDWARE_UNITS_GET = 'getHardware';
  static readonly HARDWARE_UNITS_DASHBOARD = 'getHardwareDashboard';
  static readonly HARDWARE_OPTIONS_GET = 'getHardware';
  static readonly HARDWARE_OPTIONS_UPDATE = 'updateHardwareOptions';
  static readonly HARDWARE_ORDERS_GET = 'getHardwareOrders';

  static readonly INVITED_USERS_ADD = 'addUserInvites';
  static readonly INVITED_USERS_GET = 'getUserInvites';
  static readonly INVITED_USERS_RESEND = 'resendUserInvites';
  static readonly INVITED_USERS_REVOKE = 'revokeUserInvites';

  static readonly LIBRARY_ADD = 'addMediaLibrary';
  static readonly LIBRARY_DELETE = 'deleteMediaLibrary';
  static readonly LIBRARY_DOWNLOAD = 'downloadMediaLibrary';
  static readonly LIBRARY_GET = 'getMediaLibrary';
  static readonly LIBRARY_UPDATE = 'updateMediaLibrary';

  static readonly MANAGE_SUBSCRIPTION_ADD = 'addManageSubscription';
  static readonly MANAGE_SUBSCRIPTION_DELETE = 'deleteManageSubscription';
  static readonly MANAGE_SUBSCRIPTION_GET = 'getManageSubscription';
  static readonly MANAGE_SUBSCRIPTION_UPDATE = 'updateManageSubscription';

  static readonly ORGANIZATION_DETAILS_GET = 'getOrganizationDetails';
  static readonly ORGANIZATION_DETAILS_UPDATE = 'updateOrganizationAccessibleDetails';

  static readonly PLAYER_PROFILES_ADD = 'addPlayerConfig';
  static readonly PLAYER_PROFILES_DELETE = 'deletePlayerConfig';
  static readonly PLAYER_PROFILES_GET = 'getPlayerConfig';
  static readonly PLAYER_PROFILES_UPDATE = 'updatePlayerConfig';

  static readonly PLAYLISTS_ADD = 'addPlaylists';
  static readonly PLAYLISTS_DELETE = 'deletePlaylists';
  static readonly PLAYLISTS_GET = 'getPlaylists';
  static readonly PLAYLISTS_UPDATE = 'updatePlaylists';

  static readonly RESI_ADMIN_ONLY = 'deleteCustomers'; // la1only

  static readonly SCHEDULES_ADD = 'addSchedules';
  static readonly SCHEDULES_DELETE = 'deleteSchedules';
  static readonly SCHEDULES_GET = 'getSchedules';
  static readonly SCHEDULES_UPDATE = 'updateSchedules';

  static readonly SHARED_CUES_ADD = 'addSharedCues';

  static readonly SITE_ADD = 'addSite';
  static readonly SITE_DELETE = 'deleteSite';
  static readonly SITE_GET = 'getSite';
  static readonly SITE_UPDATE = 'updateSite';

  static readonly SOCIAL_ACCTS_ADD = 'addSocialMedia';
  static readonly SOCIAL_ACCTS_DELETE = 'deleteSocialMedia';
  static readonly SOCIAL_ACCTS_GET = 'getSocialMedia';
  static readonly SOCIAL_ACCTS_UPDATE = 'updateSocialMedia';

  static readonly SOCIAL_MEDIA_ADD = 'addSocialMediaEvents';
  static readonly SOCIAL_MEDIA_DELETE = 'deleteSocialMediaEvents';
  static readonly SOCIAL_MEDIA_GET = 'getSocialMediaEvents';
  static readonly SOCIAL_MEDIA_PUBLISH = 'addSocialMediaEvents';
  static readonly SOCIAL_MEDIA_UPDATE = 'updateSocialMediaEvents';

  static readonly TOGGLES_UPDATE = 'addCustomers';
  static readonly TOGGLES_VIEW = 'getCustomers';

  static readonly TRANSCODER_SCHEDULES_ADD = 'addTranscodedEventSchedules';
  static readonly TRANSCODER_SCHEDULES_DELETE = 'deleteTranscodedEventSchedules';
  static readonly TRANSCODER_SCHEDULES_GET = 'getTranscodedEventSchedules';
  static readonly TRANSCODER_SCHEDULES_UPDATE = 'updateTranscodedEventSchedules';

  static readonly UPLOADERS_DELETE = 'deleteUploaders';

  static readonly UPLOAD_VIDEO_ADD = 'addVideoUpload';

  static readonly USERS_ADD = 'addUsers';
  static readonly USERS_BILLING = 'updateUsersBilling';
  static readonly USERS_DECODER_STATUS = 'addUsers';
  static readonly USERS_DELETE = 'deleteUsers';
  static readonly USERS_GET = 'getUsers';
  static readonly USERS_PLAYER_LOGS = 'getPlayerLogs';
  static readonly USERS_UPDATE = 'updateUsers';

  static readonly WEB_ENCODER_PROFILES_ADD = 'addWebEncoderProfiles';
  static readonly WEB_ENCODER_PROFILES_DELETE = 'deleteWebEncoderProfiles';
  static readonly WEB_ENCODER_PROFILES_GET = 'getWebEncoderProfiles';
  static readonly WEB_ENCODER_PROFILES_UPDATE = 'updateWebEncoderProfiles';

  static readonly WEB_EVENTS_DELETE = 'deleteTranscodedEvents';
  static readonly WEB_EVENTS_DOWNLOAD = 'downloadWebEvents';
  static readonly WEB_EVENTS_GET = 'getTranscodedEvents';
  static readonly WEB_EVENTS_UPDATE = 'updateTranscodedEvents';

  static readonly WEB_EVENT_PROFILES_ADD = 'addTranscodedEventProfiles';
  static readonly WEB_EVENT_PROFILES_BANDWIDTH_COST = 'getWebEventProfileCost';
  static readonly WEB_EVENT_PROFILES_BANDWIDTH_USAGE = 'getWebEventProfileUsage';
  static readonly WEB_EVENT_PROFILES_DELETE = 'deleteTranscodedEventProfiles';
  static readonly WEB_EVENT_PROFILES_GET = 'getTranscodedEventProfiles';
  static readonly WEB_EVENT_PROFILES_UPDATE = 'updateTranscodedEventProfiles';
  static readonly WEB_EVENT_PROFILES_UPDATE_STREAM_URLS = 'deleteTranscodedEventProfiles';
}

export default Permissions;
