import type { CombinedState } from 'redux';
import { createSelector } from 'reselect';
import type { Library, LibrarySearch } from '@studio/types';
import type { RootState } from '../root-reducer';
import type { LibrarySearchState } from './types';

const selectLibrarySearchState = (state: RootState): CombinedState<LibrarySearchState> => state.librarySearch;

const selectResults = createSelector(selectLibrarySearchState, (state): LibrarySearch.Derived.Results => state.results);

const selectRequest = createSelector(selectLibrarySearchState, (state): LibrarySearch.Get.Params => state.request);

const selectTags = createSelector(selectLibrarySearchState, (state): string[] => state.selectedTags);

const selectView = createSelector(selectLibrarySearchState, (state): Library.Derived.MediaView => state.view);

const selectThumbnailFilter = createSelector(selectLibrarySearchState, (state) => state.activeThumbnailFilter);

const selectFilterCount = createSelector(selectRequest, (state) => {
  const { createTimeRange, durationRange, hasThumbnail, mediaSource, startTimeRange, tags } = state;

  let count = 0;

  if (createTimeRange?.start || createTimeRange?.end) count++;
  if (durationRange) count++;
  if (hasThumbnail || hasThumbnail === false) count++;
  if (mediaSource) count++;
  if (startTimeRange?.start || startTimeRange?.end) count++;
  if (tags) count++;

  return count;
});

export const select = {
  filterCount: selectFilterCount,
  request: selectRequest,
  results: selectResults,
  state: selectLibrarySearchState,
  tags: selectTags,
  thumbnailFilter: selectThumbnailFilter,
  view: selectView,
};
