import { HTTP_METHODS } from '@studio/constants/axios';
import { CENTRAL_API_V3 } from '@studio/constants/env-variables';
import type { UploadPOSTUrls } from '@studio/types';

export const root = {
  [HTTP_METHODS.POST]: {
    method: HTTP_METHODS.POST,
    request: {} as {
      currentDownloadUrl?: string;
      fileExtension: string;
    },
    response: {} as UploadPOSTUrls,
    params: {} as { id: string },
    url: `${CENTRAL_API_V3}/customers/@customerId/webeventprofiles/@id/offlineimage/upload`,
  },
};

export const id = {};
