import { HTTP_METHODS } from '@studio/constants/axios';
import { CUES_API_V1 } from '@studio/constants/env-variables';
import type { Cues } from '@studio/types';

export const root = {
  [HTTP_METHODS.GET]: {
    url: `${CUES_API_V1}/customers/@customerId/media/@mediaId/cues`,
    response: [] as Cues.Get.Cue[],
    params: {} as { mediaId: string },
    method: HTTP_METHODS.GET,
  },
  [HTTP_METHODS.POST]: {
    url: `${CUES_API_V1}/customers/@customerId/media/@mediaId/cues`,
    request: {} as Cues.Post.Cue,
    params: {} as { mediaId: string },
    method: HTTP_METHODS.POST,
  },
};

export const id = {
  [HTTP_METHODS.DELETE]: {
    url: `${CUES_API_V1}/customers/@customerId/media/@mediaId/cues/@id`,
    params: {} as { id: string; mediaId: string },
    method: HTTP_METHODS.DELETE,
  },
  [HTTP_METHODS.PATCH]: {
    url: `${CUES_API_V1}/customers/@customerId/media/@mediaId/cues/@id`,
    request: {} as Cues.Patch.Cue,
    params: {} as { id: string; mediaId: string },
    method: HTTP_METHODS.PATCH,
  },
};
