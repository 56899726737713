import { HTTP_METHODS } from '@studio/constants/axios';
import { SEARCH_API_V1 } from '@studio/constants/env-variables';
import type { LibrarySearch } from '@studio/types';

export const root = {
  [HTTP_METHODS.GET]: {
    url: `${SEARCH_API_V1}/vod/@customerId`,
    response: {} as LibrarySearch.Get.Response,
    method: HTTP_METHODS.GET,
    paramOptions: { allowDots: true, encode: true },
    query: {} as LibrarySearch.Get.Params,
  },
};

export const id = {};
