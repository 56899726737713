export const GO_LIVE_STATUS = {
  LIVE: 'LIVE',
  NO_INPUT: 'NO_INPUT',
  OFFLINE: 'OFFLINE',
  STOPPED: 'STOPPED',
  STOPPING: 'STOPPING',
} as const;

export const GO_LIVE_POST_STATUS = {
  CLEAN_UP: 'CLEAN_UP',
  ENCODER_START: 'ENCODER_START',
  SCHEDULE_CREATED: 'SCHEDULE_CREATED',
} as const;

export const ORIGINATION = {
  API: 'API',
  EVENT_UPLOAD_CALLBACK: 'EVENT_UPLOAD_CALLBACK',
  GO_LIVE: 'GO_LIVE',
  MANUAL_START_FROM_ENCODER_EVENT: 'MANUAL_START_FROM_ENCODER_EVENT',
  PRO_PRESENTER: 'PRO_PRESENTER',
  RSP_EGRESS_CALLBACK: 'RSP_EGRESS_CALLBACK',
} as const;

export const SCHEDULE_DISPLAY_KEY = 'schedule-display-key';

export const STREAM_TYPE = {
  EXISTING_ENCODER_EVENT: 'EXISTING_ENCODER_EVENT',
  EXISTING_MEDIA: 'EXISTING_MEDIA',
  LIVE: 'LIVE',
  REPUBLISH: 'REPUBLISH',
  SIMLIVE: 'SIMLIVE',
} as const;

export const PUBLISH_STATUS = {
  PUBLISHED: 'published',
  SCHEDULED_PUBLISHED: 'scheduled_published',
  UNPUBLISHED: 'unpublished',
  CREATED: 'created',
  READY: 'ready',
  SCHEDULED_UNPUBLISHED: 'scheduled_unpublished',
  COMPLETED: 'completed',
} as const;

export const REPEAT_END_TYPE = {
  AFTER: 'AFTER',
  NEVER: 'NEVER',
  ON: 'ON',
} as const;

export const SCHEDULE_VIEW_KEY = 'schedule-view-key';

export const SCHEDULE_VIEW = {
  CALENDAR: 'CALENDAR',
  LIST: 'LIST',
} as const;

export const TIME_STATUS = {
  FUTURE: 'FUTURE',
  LIVE: 'LIVE',
  PAST: 'PAST',
} as const;
