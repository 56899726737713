import { avgWatchTime } from './avg-watch-time';
import { medianWatchTime } from './median-watch-time';
import { newViewers } from './new-viewers';
import { peakConcurrentViewers } from './peak-concurrent-viewers';
import { returnViewers } from './return-viewers';
import { totalTimeWatched } from './total-time-watched';
import { uniqueViewers } from './unique-viewers';
import { views } from './views';

export const kpis = {
  avgWatchTime,
  medianWatchTime,
  newViewers,
  peakConcurrentViewers,
  returnViewers,
  totalTimeWatched,
  uniqueViewers,
  views,
};
