import { produce } from 'immer';
import type { ActionType, PayloadAction } from 'typesafe-actions';
import { createReducer } from 'typesafe-actions';
import type { Roles, Users } from '@studio/types';
import { fetch, set } from './actions';
import type { UserInvitesActionTypes, UserRolesActionTypes, UsersActionTypes, UsersState } from './types';

type Action = ActionType<typeof fetch | typeof set>;

export const initialState: UsersState = {
  isFetchingUsers: false,
  isFetchingInvites: false,
  isFetchingRoles: false,
  roles: [],
  invites: [],
  users: [],
  timestamp: 0,
  invitesTimestamp: 0,
  rolesTimestamp: 0,
  error: null,
  filter: '',
};

const reducer = createReducer<UsersState, Action>(initialState)
  .handleAction(
    fetch.users.request,
    produce((draft: UsersState) => {
      draft.isFetchingUsers = true;
      draft.error = null;
    })
  )
  .handleAction(
    fetch.users.success,
    produce((draft: UsersState, action: PayloadAction<UsersActionTypes.FETCH_REQUEST_SUCCESS, Users.Get.User[]>) => {
      draft.isFetchingUsers = false;
      draft.users = action.payload;
    })
  )
  .handleAction(
    fetch.users.failure,
    produce((draft: UsersState, action: PayloadAction<UsersActionTypes.FETCH_REQUEST_ERROR, Error>) => {
      draft.isFetchingUsers = false;
      draft.error = action.payload;
    })
  )
  .handleAction(
    fetch.users.cancel,
    produce((draft: UsersState) => {
      draft.isFetchingUsers = false;
    })
  )
  .handleAction(
    fetch.invites.request,
    produce((draft: UsersState) => {
      draft.isFetchingInvites = true;
      draft.error = null;
    })
  )
  .handleAction(
    fetch.invites.success,
    produce(
      (
        draft: UsersState,
        action: PayloadAction<UserInvitesActionTypes.FETCH_REQUEST_SUCCESS, Users.Get.UserInvite[]>
      ) => {
        draft.isFetchingInvites = false;
        draft.invites = action.payload;
      }
    )
  )
  .handleAction(
    fetch.invites.failure,
    produce((draft: UsersState, action: PayloadAction<UserInvitesActionTypes.FETCH_REQUEST_ERROR, Error>) => {
      draft.isFetchingInvites = false;
      draft.error = action.payload;
    })
  )
  .handleAction(
    fetch.invites.cancel,
    produce((draft: UsersState) => {
      draft.isFetchingInvites = false;
    })
  )
  .handleAction(
    fetch.roles.request,
    produce((draft: UsersState) => {
      draft.isFetchingRoles = true;
      draft.error = null;
    })
  )
  .handleAction(
    fetch.roles.success,
    produce(
      (draft: UsersState, action: PayloadAction<UserRolesActionTypes.FETCH_REQUEST_SUCCESS, Roles.Get.Role[]>) => {
        draft.isFetchingRoles = false;
        draft.roles = action.payload;
      }
    )
  )
  .handleAction(
    fetch.roles.failure,
    produce((draft: UsersState, action: PayloadAction<UserRolesActionTypes.FETCH_REQUEST_ERROR, Error>) => {
      draft.isFetchingRoles = false;
      draft.error = action.payload;
    })
  )
  .handleAction(
    fetch.roles.cancel,
    produce((draft: UsersState) => {
      draft.isFetchingRoles = false;
    })
  )
  .handleAction(
    set.usersTimestamp,
    produce((draft: UsersState, action: PayloadAction<UsersActionTypes.FETCH_REQUEST_TIMESTAMP, number>) => {
      draft.timestamp = action.payload;
    })
  )
  .handleAction(
    set.invitesTimestamp,
    produce((draft: UsersState, action: PayloadAction<UserInvitesActionTypes.FETCH_REQUEST_TIMESTAMP, number>) => {
      draft.invitesTimestamp = action.payload;
    })
  )
  .handleAction(
    set.rolesTimestamp,
    produce((draft: UsersState, action: PayloadAction<UserRolesActionTypes.FETCH_REQUEST_TIMESTAMP, number>) => {
      draft.rolesTimestamp = action.payload;
    })
  )
  .handleAction(
    set.filter,
    produce((draft: UsersState, action: PayloadAction<UsersActionTypes.FILTER_RESULTS, string>) => {
      draft.filter = action.payload;
    })
  );

export default reducer;
