import { HTTP_METHODS } from '@studio/constants/axios';
import { STATUS_V1 } from '@studio/constants/env-variables';
import type { Encoders } from '@studio/types';

export const root = {
  [HTTP_METHODS.GET]: {
    url: `${STATUS_V1}/customers/@customerId/encoders/@id/minimum/option`,
    response: {} as Encoders.Get.Option,
    params: {} as { id: string },
    method: HTTP_METHODS.GET,
  },
};

export const id = {};
