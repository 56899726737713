import { HTTP_METHODS } from '@studio/constants/axios';
import { CENTRAL_API_V3 } from '@studio/constants/env-variables';
import type { Schedules } from '@studio/types';

export const root = {
  [HTTP_METHODS.GET]: {
    method: HTTP_METHODS.GET,
    url: `${CENTRAL_API_V3}/customers/@customerId/socialmedia/@channelId/schedules`,
    params: {} as { channelId: string },
    response: [] as Schedules.Get.ContentDestinationSchedule[],
  },
};
