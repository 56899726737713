const FFT_SIZE = 2048;
const TRANSFERS_PER_SECOND = 30;
const MIN_TRANSFER_TIME_MILLISECONDS = 1000 / TRANSFERS_PER_SECOND;

class AudioParser {
  video: HTMLVideoElement;
  ch1Data: Uint8Array;
  ch2Data: Uint8Array;

  constructor(video: HTMLVideoElement) {
    this.ch1Data = new Uint8Array(FFT_SIZE);
    this.ch2Data = new Uint8Array(FFT_SIZE);
    this.video = video;

    this.setupContext();
  }

  audioContext: AudioContext | null = null;
  timeoutId: NodeJS.Timeout | null = null;
  static sourceNode: MediaElementAudioSourceNode | null = null;
  static splitterNode: ChannelSplitterNode | null = null;
  static mergerNode: ChannelMergerNode | null = null;
  static ch1Analyser: AnalyserNode | null = null;
  static ch2Analyser: AnalyserNode | null = null;

  setupContext() {
    const audioContext = new AudioContext();
    this.audioContext = audioContext;

    if (audioContext.state === 'suspended') {
      const resumeIfSuspended = () => {
        if (audioContext.state === 'suspended') {
          audioContext.resume();
        }
        this.video.removeEventListener('play', resumeIfSuspended);
      };

      this.video.addEventListener('play', resumeIfSuspended);
    }

    AudioParser.sourceNode = audioContext.createMediaElementSource(this.video);
    AudioParser.splitterNode = audioContext.createChannelSplitter(2);
    AudioParser.sourceNode.connect(AudioParser.splitterNode);
  }

  setupChannels() {
    if (this.audioContext) {
      AudioParser.ch1Analyser = this.audioContext.createAnalyser();
      AudioParser.ch1Analyser.fftSize = FFT_SIZE;
      AudioParser.splitterNode?.connect(AudioParser.ch1Analyser, 0);

      AudioParser.ch2Analyser = this.audioContext.createAnalyser();
      AudioParser.ch2Analyser.fftSize = FFT_SIZE;
      AudioParser.splitterNode?.connect(AudioParser.ch2Analyser, 1);

      AudioParser.mergerNode = this.audioContext.createChannelMerger();
      AudioParser.ch1Analyser.connect(AudioParser.mergerNode, 0, 0);
      AudioParser.ch2Analyser.connect(AudioParser.mergerNode, 0, 1);
      AudioParser.mergerNode.connect(this.audioContext.destination);
    }
  }

  getMaxVolume(data: Uint8Array): number {
    return Math.max.apply(
      null,
      Array.from(data).map((x) => Math.abs((x - 128) / 128))
    );
  }

  setupAudioAnalyzer(setter: (channelData: number[]) => void) {
    this.timeoutId = setTimeout(() => this.setupAudioAnalyzer(setter), MIN_TRANSFER_TIME_MILLISECONDS);
    AudioParser.ch1Analyser?.getByteTimeDomainData(this.ch1Data);
    AudioParser.ch2Analyser?.getByteTimeDomainData(this.ch2Data);
    const ch1MaxVolume = this.getMaxVolume(this.ch1Data);
    const ch2MaxVolume = this.getMaxVolume(this.ch2Data);

    return setter([ch1MaxVolume, ch2MaxVolume]);
  }

  clearAudioAnalyser() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
      this.timeoutId = null;
    }
  }
}
export default AudioParser;
