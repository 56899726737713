import type { AllEffect, ForkEffect } from 'redux-saga/effects';
import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';
import { client } from '@studio/api/api-client/client';
import { STALE_DATA_TIMEOUT } from '@studio/constants/api-constants';
import { CENTRAL_API_V3 } from '@studio/constants/env-variables';
import type { WebChannels } from '@studio/types';
import type { ApiClientConfig } from '@studio/types/axios';
import { selectCustomerId, selectAccessToken } from '../authentication';
import { setFetchRequestTimestamp, fetchWebChannelsAsync } from './actions';
import { selectTimestamp } from './selectors';
import { ActionTypes } from './types';

function* handleFetch() {
  const timestamp: number = yield select(selectTimestamp);
  if (Date.now() - timestamp < STALE_DATA_TIMEOUT) {
    yield put(fetchWebChannelsAsync.cancel());
    return;
  }

  const customerId: string = yield select(selectCustomerId);
  const url = `${CENTRAL_API_V3}/customers/${customerId}/webeventprofiles`;
  const token: string = yield select(selectAccessToken);
  const config: ApiClientConfig = { token };
  try {
    const response: WebChannels.Get.WebChannel[] = yield call(client, url, config);
    yield put(fetchWebChannelsAsync.success(response));
    yield put(setFetchRequestTimestamp(Date.now()));
  } catch (error) {
    if (error instanceof Error) {
      yield put(fetchWebChannelsAsync.failure(error));
    }
  }
}

export function* watchFetchRequest<T>(): Generator<ForkEffect<never>, void, T> {
  yield takeLatest(ActionTypes.FETCH_REQUEST, handleFetch);
}

export function* webChannelsSaga<T>(): Generator<AllEffect<ForkEffect<void>>, void, T> {
  yield all([fork(watchFetchRequest)]);
}
