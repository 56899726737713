import { noop } from '@resi-media/resi-ui';
import type { SagaIterator } from 'redux-saga';
import type { AllEffect, ForkEffect } from 'redux-saga/effects';
import { all, call, cancelled, fork, put, select, take, takeLatest } from 'redux-saga/effects';
import { authenticate } from '@studio/api/api-client/authenticate';
import { client } from '@studio/api/api-client/client';
import { login } from '@studio/api/api-client/login';
import { proxyAuthenticate } from '@studio/api/api-client/proxy-authenticate';
import { CENTRAL_API_V2, CENTRAL_API_V3 } from '@studio/constants/env-variables';
import { ErrorConstants } from '@studio/constants/error-constants';
import { TrackEventName, TrackEventProp, TrackOrgProp, TrackUserProp } from '@studio/constants/tracking-constants';
import { extractCentralErrors } from '@studio/helpers/error-handling';
import { CentralResponseError, WrongUserTypeError } from '@studio/types';
import type { ApiClientConfig } from '@studio/types/axios';
import { fetch as customerFetch } from '../customer/actions';
import { CustomerActionTypes } from '../customer/types';
import { fetch as regionsFetch } from '../regions/actions';
import { ActionTypes as RegionsActionTypes } from '../regions/types';
import { trackSegmentAsync } from '../tracking/actions';
import {
  fetchMeAsync,
  fetchTokenAsync,
  loginAsync,
  logoutAsync,
  setAccessTokenTimestamp,
  setAuthToken,
  setIsLoggingIn,
  setIsRefreshing,
  setTokenExpired,
} from './actions';
import { selectAccessToken, selectCustomerId } from './selectors';
import type { AuthenticatedUser, LoginResponse, TokenResponse } from './types';
import { AuthenticationActionTypes, USER_TYPE } from './types';

const tokenUrl = `${CENTRAL_API_V3}/auth/token`;

function* handleAuthentication(action: ReturnType<typeof loginAsync.request>) {
  try {
    yield put(setIsLoggingIn(true));
    let response: TokenResponse;
    let loginResponse: LoginResponse;
    if (action.payload.customerId) {
      response = yield call(
        proxyAuthenticate,
        action.payload.customerId,
        action.payload.username,
        action.payload.password
      );
      // TODO: remove the call to login when Control dies a horrible death
      loginResponse = yield call(login, action.payload.username, action.payload.password, action.payload.customerId);
      yield put({ type: AuthenticationActionTypes.SWITCHED_ORGS });
    } else {
      response = yield call(authenticate, action.payload.username, action.payload.password);
      // TODO: remove the call to login when Control dies a horrible death
      loginResponse = yield call(login, action.payload.username, action.payload.password);
    }

    yield put(loginAsync.success(response));
    yield put(setAccessTokenTimestamp(Date.now()));
    yield put(fetchMeAsync.request());
    yield take(AuthenticationActionTypes.FETCH_ME_REQUEST_SUCCESS);
    yield put(customerFetch.customer.request({ uuid: loginResponse.customerId, force: true }));
    yield take(CustomerActionTypes.FETCH_REQUEST_SUCCESS);
    yield put(regionsFetch.regions.request());
    yield take(RegionsActionTypes.FETCH_REQUEST_SUCCESS);
    yield put(
      trackSegmentAsync.request({
        eventName: TrackEventName.USER_LOGIN,
        eventProps: {
          [TrackUserProp.USER_NAME]: action.payload.username,
          [TrackOrgProp.ORG_NAME]: loginResponse.customerName,
          [TrackOrgProp.ORG_UUID]: loginResponse.customerId,
        },
      })
    );
  } catch (err) {
    if (err instanceof Error) {
      yield put(loginAsync.failure(err));
    }
  } finally {
    yield put(setIsLoggingIn(false));
  }
}

function* handleLogout(action: ReturnType<typeof logoutAsync.request>): SagaIterator {
  const source = new AbortController();
  const url = `${CENTRAL_API_V3}/logout`;
  const token: string = yield select(selectAccessToken);
  const config: ApiClientConfig = { method: 'POST', token, withCredentials: true };

  try {
    yield call(client, url, config);
    yield put(logoutAsync.success());
    yield call(action.payload?.onSuccess ?? noop);
  } catch (err) {
    if (err instanceof Error) {
      yield put(logoutAsync.failure(err));
    }
    yield call(action.payload?.onError ?? noop);
  } finally {
    if (yield cancelled()) {
      source.abort('cancelled');
      yield put(logoutAsync.cancel());
    }
    yield call(action.payload?.onFinally ?? noop);
  }
}

function* handleRefreshToken() {
  try {
    yield put(setIsRefreshing(true));
    const config: ApiClientConfig = { data: { grant_type: 'refresh_token_cookie' }, withCredentials: true };
    const response: TokenResponse = yield call(client, tokenUrl, config);
    yield put(fetchTokenAsync.success(response));
    yield put(setAccessTokenTimestamp(Date.now()));
    yield put(fetchMeAsync.request());
    yield take(AuthenticationActionTypes.FETCH_ME_REQUEST_SUCCESS);

    const customerId: string = yield select(selectCustomerId);
    yield put(customerFetch.customer.request({ uuid: customerId, force: true }));
    yield take(CustomerActionTypes.FETCH_REQUEST_SUCCESS);
    yield put(regionsFetch.regions.request());
    yield take(RegionsActionTypes.FETCH_REQUEST_SUCCESS);
  } catch (err) {
    if (err instanceof CentralResponseError) {
      const errors = extractCentralErrors(err.response.data);
      if (errors.some((error) => error.code === ErrorConstants.CENTRAL_INVALID_REFRESH_TOKEN)) {
        yield put(setTokenExpired());
      }
    } else if (err instanceof Error) {
      yield put(fetchTokenAsync.failure(err));
    }
  } finally {
    yield put(setIsRefreshing(false));
  }
}

function* handleSetAuthToken(action: ReturnType<typeof setAuthToken>) {
  yield put(setAccessTokenTimestamp(Date.now()));
  yield put(setAuthToken(action.payload));
}

function* handleFetchMe() {
  const url = `${CENTRAL_API_V2}/users/me`;
  try {
    const token: string = yield select(selectAccessToken);
    const config: ApiClientConfig = { token };
    const response: AuthenticatedUser = yield call(client, url, config);

    const { capabilities, customerId, customerName, firstName, lastName, userId, userName, userType } = response;

    if (userType === USER_TYPE.SITE) {
      throw new WrongUserTypeError('Venues shall not pass!!');
    }

    //@ts-expect-error idk why ts complains about this
    yield call(pendo.initialize, {
      visitor: { id: userId, username: userName, first_name: firstName, last_name: lastName, capabilities },
      account: { id: customerId, org_name: customerName },
    });

    yield call(window.analytics.identify, userId, {
      [TrackUserProp.USER_NAME]: userName,
      [TrackOrgProp.ORG_NAME]: customerName,
      [TrackOrgProp.ORG_UUID]: customerId,
      [TrackUserProp.USER_PERMISSIONS]: capabilities,
      [TrackEventProp.STUDIO_VERSION]: import.meta.env.VITE_APP_VERSION,
    });

    yield call(window.analytics.group, customerId, {
      [TrackOrgProp.ORG_NAME]: customerName,
      [TrackOrgProp.ORG_UUID]: customerId,
    });

    yield put(fetchMeAsync.success(response));
  } catch (err) {
    if (err instanceof Error) {
      console.error(err);
      yield put(fetchMeAsync.failure(err));
      yield put(logoutAsync.request());
    }
  }
}

function* handleReload() {
  yield put({ type: AuthenticationActionTypes.FETCH_TOKEN_REQUEST });
}

export function* watchLoginRequest<T>(): Generator<ForkEffect<never>, void, T> {
  yield takeLatest(AuthenticationActionTypes.AUTH_REQUEST, handleAuthentication);
}

export function* watchLogoutRequest<T>(): Generator<ForkEffect<never>, void, T> {
  yield takeLatest(AuthenticationActionTypes.LOGOUT_REQUEST, handleLogout);
}

export function* watchRefreshToken<T>(): Generator<ForkEffect<never>, void, T> {
  yield takeLatest(AuthenticationActionTypes.FETCH_TOKEN_REQUEST, handleRefreshToken);
}

export function* watchFetchMe<T>(): Generator<ForkEffect<never>, void, T> {
  yield takeLatest(AuthenticationActionTypes.FETCH_ME_REQUEST, handleFetchMe);
}

export function* watchAppReload<T>(): Generator<ForkEffect<never>, void, T> {
  yield takeLatest(AuthenticationActionTypes.APP_RELOAD, handleReload);
}

export function* watchSetAuthToken<T>(): Generator<ForkEffect<never>, void, T> {
  yield takeLatest(AuthenticationActionTypes.ACCESS_TOKEN_REFRESH, handleSetAuthToken);
}

export function* authenticationSaga<T>(): Generator<AllEffect<ForkEffect<void>>, void, T> {
  yield all([
    fork(watchLoginRequest),
    fork(watchLogoutRequest),
    fork(watchRefreshToken),
    fork(watchFetchMe),
    fork(watchAppReload),
    fork(watchSetAuthToken),
  ]);
}
