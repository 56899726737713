import { useSelector } from 'react-redux';
import { WEB_PLANS } from '@studio/constants/plans';
import type { PlanTypeState } from '@studio/constants/web-channels';
import { selectWebPlanId } from '@studio/store';

const useIsPlanType = (): PlanTypeState => {
  const webPlanId = useSelector(selectWebPlanId) ?? '';

  const isUBP =
    webPlanId === WEB_PLANS.ENTERPRISE_UBP ||
    webPlanId === WEB_PLANS.PROFESSIONAL_UBP ||
    webPlanId === WEB_PLANS.SOCIAL_ONLY_UBP ||
    webPlanId === WEB_PLANS.STARTER_LITE_UBP;

  return {
    isEnterprise: webPlanId === WEB_PLANS.ENTERPRISE,
    isEnterpriseUBP: webPlanId === WEB_PLANS.ENTERPRISE_UBP,
    isPro: webPlanId === WEB_PLANS.PROFESSIONAL,
    isProfessionalUBP: webPlanId === WEB_PLANS.PROFESSIONAL_UBP,
    isProLegacyEmbedOnly: webPlanId === WEB_PLANS.PRO_LEGACY_EMBED_ONLY,
    isSocialOnly: WEB_PLANS.SOCIAL_ONLY_LIST.includes(webPlanId),
    isSocialOnlyUBP: webPlanId === WEB_PLANS.SOCIAL_ONLY_UBP,
    isStarterLegacy: webPlanId === WEB_PLANS.STARTER_LEGACY,
    isStarterLite: webPlanId === WEB_PLANS.STARTER_LITE,
    isStarterLiteUBP: webPlanId === WEB_PLANS.STARTER_LITE_UBP,
    isUBP,
    isVideoReview: webPlanId === WEB_PLANS.VIDEO_REVIEW,
    webPlanId,
  };
};

export default useIsPlanType;
