import * as React from 'react';
import { CheckCircleFilled, CloudUploadOutlined, DownloadOutlined } from '@ant-design/icons';
import { useTheme } from '@emotion/react';
import { Draft, Inline, Link, Progress, Stack, Text } from '@resi-media/resi-ui';
import { FILE_VALIDATION_ERRORS } from '@studio/constants/shared';
import { fileListToArray, isVideoFileValid } from '@studio/helpers';
import { usePrefix } from '@studio/hooks';
import { S, AnalysisComplete, DragAndDropZone, ErrorList } from './styles';

export enum ANALYSIS_STATUS {
  COMPLETE = 'COMPLETE',
  IN_PROGRESS = 'IN_PROGRESS',
  READY = 'READY',
}

type _Props = {
  accept?: string;
  analysisProgress: number;
  analysisStatus: ANALYSIS_STATUS;
  display4KRequirements: boolean;
  onFileReady?: (file: File) => Promise<void>;
  style?: React.CSSProperties;
  validationErrors?: React.ReactNode[] | string[];
  validationWarnings?: string[];
};

type _Requirement = {
  field: string;
  title: string;
};

/* eslint-disable import/export */
export const VideoDropzone = ({
  accept,
  analysisProgress,
  analysisStatus,
  display4KRequirements,
  onFileReady,
  style,
  validationErrors,
  validationWarnings,
}: _Props) => {
  const theme = useTheme();
  const { linkT, prefixNS, PrefixTrans, t } = usePrefix('components:', 'fileDropzone');
  const fileInputRef = React.useRef<HTMLInputElement>({} as HTMLInputElement);
  const [highlight, setHighlight] = React.useState(false);
  const [fileRequirements, setFileRequirements] = React.useState(false);
  const [error, setError] = React.useState<string>();
  const [filename, setFilename] = React.useState('');

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>): void => {
    event.preventDefault();
    setHighlight(true);
  };

  const handleDragLeave = (event: React.DragEvent<HTMLDivElement>): void => {
    event.preventDefault();
    setHighlight(false);
  };

  const openFileDialog = (): void => {
    fileInputRef.current.click();
  };

  const processFiles = async (files: FileList | null): Promise<void> => {
    if (files && isVideoFileValid(files[0])) {
      const filesArray = fileListToArray(files);
      setFilename(filesArray[0].name);

      try {
        await onFileReady?.(filesArray[0]);
      } catch (error) {
        if (error instanceof Error) {
          setError(error.message);
        }
      } finally {
        setHighlight(false);
      }
    }
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>): void => {
    event.preventDefault();
    setError(undefined);

    processFiles(event.dataTransfer.files);
  };

  const handleFilesAdded = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setError(undefined);

    processFiles(event.target.files);
  };

  const handleFileRequirements = () => {
    setFileRequirements(!fileRequirements);
  };

  const requirementList = React.useMemo(() => {
    const requirements = {
      ...t<Record<string, _Requirement>>('components:fileDropzone.reqs', {
        returnObjects: true,
      }),
      ...(display4KRequirements &&
        t<Record<string, _Requirement>>('components:fileDropzone.reqs4K', {
          returnObjects: true,
        })),
    };
    const arrayOfRequirements = Object.entries(requirements).map(([, value]) => ({
      title: value.title,
      field: value.field,
    }));
    return arrayOfRequirements;
  }, [t, display4KRequirements]);

  const errorCode = React.useMemo(() => {
    switch (error) {
      case FILE_VALIDATION_ERRORS.FILE_TYPE:
        return 'invalidVideoFileTypeError';
      case FILE_VALIDATION_ERRORS.FILE_SIZE:
        return 'videoFileSizeError';
      case FILE_VALIDATION_ERRORS.FILE_PROCESSING:
        return 'videoProcessingError';
      default:
        return '';
    }
  }, [error]);

  return (
    <>
      <Stack>
        <Inline alignItems="flex-end" justifyContent="space-between">
          <Text>{prefixNS('selectAFileToUpload')}</Text>
          <Link dataTestId="video-dropzone__link--info" onClick={handleFileRequirements} role="button">
            {fileRequirements ? prefixNS('hideFileRequirements') : prefixNS('showFileRequirements')}
          </Link>
        </Inline>

        {fileRequirements && (
          <>
            <div css={S.requirementsContainer}>
              <Text as="h6" style={{ textDecoration: 'underline' }}>
                {prefixNS('fileRequirements')}
              </Text>
              <div css={S.requirementsListWrapper}>
                {requirementList.map((req) => (
                  <div key={req.title} style={{ display: 'flex', overflow: 'hidden' }}>
                    <Text as="h6" style={{ width: '214px', flexShrink: 0 }} weightVariant="semiBold">
                      {req.title}
                    </Text>
                    <span aria-label={req.title}>
                      <Text whiteSpace="pre-line">{req.field}</Text>
                    </span>
                  </div>
                ))}
              </div>
            </div>

            <div css={S.premiereContainer} style={{ margin: '0' }}>
              <Text as="h6" style={{ textDecoration: 'underline' }}>
                {prefixNS('premierePresetFiles')}
              </Text>

              <Inline style={{ marginTop: theme.spacing.m }}>
                <Draft.Button
                  as="a"
                  data-testid="download-1080p30-button"
                  href="https://drive.google.com/uc?export=download&id=1dUkCMHS6OWTcrVw_BRHyVgy9lQ1aXJfX"
                  isFullWidth
                  label={prefixNS('1080p30')}
                  rel="noopener noreferrer"
                  sizeVariant="s"
                  startNode={<DownloadOutlined />}
                  target="_blank"
                  variant="outlined"
                />

                <Draft.Button
                  as="a"
                  data-testid="download-1080p60-button"
                  href="https://drive.google.com/uc?export=download&id=16SY2dRloN2tQVHYa5Jul8qoEiUM2JUCA"
                  isFullWidth
                  label={prefixNS('1080p60')}
                  rel="noopener noreferrer"
                  sizeVariant="s"
                  startNode={<DownloadOutlined />}
                  target="_blank"
                  variant="outlined"
                />
              </Inline>
            </div>
          </>
        )}

        {errorCode && (
          <Draft.AlertBanner dataTestId="video-dropzone__banner--error">{prefixNS(errorCode)}</Draft.AlertBanner>
        )}

        {(analysisStatus === ANALYSIS_STATUS.READY || analysisStatus === ANALYSIS_STATUS.COMPLETE) &&
          !error &&
          validationWarnings &&
          validationWarnings.length > 0 && (
            <Draft.AlertBanner dataTestId="video-dropzone__banner--validation-warning" variant="warning">
              {prefixNS('videoWarningTitle')}
              <ul>
                {validationWarnings.map((warning) => (
                  <li key={warning}>- {warning}</li>
                ))}
              </ul>
            </Draft.AlertBanner>
          )}

        {analysisStatus === ANALYSIS_STATUS.READY && !error && validationErrors && validationErrors.length > 0 && (
          <Draft.AlertBanner dataTestId="video-dropzone__banner--validation-error">
            {prefixNS('videoErrorTitle')}
            <ErrorList data-testid="video-dropzone__list--error">
              {validationErrors.map((errorProp) =>
                typeof errorProp === 'string' ? <li key={errorProp}>{errorProp}</li> : errorProp
              )}
            </ErrorList>
          </Draft.AlertBanner>
        )}

        {analysisStatus !== ANALYSIS_STATUS.COMPLETE && (
          <DragAndDropZone
            data-testid="video-dropzone__dnd-zone"
            isHovering={highlight}
            onClick={openFileDialog}
            onDragLeave={handleDragLeave}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
            style={{ cursor: 'pointer', ...style }}
          >
            {analysisStatus === ANALYSIS_STATUS.READY ? (
              <Stack alignItems="center" gap="l" w="x44">
                <CloudUploadOutlined style={{ fontSize: '64px', color: theme.palette.text.disabled }} />
                <Text isInline textAlign="center">
                  <PrefixTrans i18nKey="dropVideoHere">
                    fill <Link weightVariant="bold">fill</Link>
                  </PrefixTrans>
                </Text>
              </Stack>
            ) : (
              <Stack alignItems="center" color="text.title" marginTop="m">
                <Progress
                  determinateDuration="minimal"
                  labelVariant="percentage"
                  value={analysisProgress}
                  variant="determinate"
                />
                <Text variant="body3">{prefixNS('analyzingVideo')}</Text>
                <Text variant="body3">{filename}</Text>
              </Stack>
            )}
          </DragAndDropZone>
        )}
        {/* Show green check if video successfully passes analysis */}
        {analysisStatus === ANALYSIS_STATUS.COMPLETE && (
          <AnalysisComplete>
            <Stack alignItems="center" color="text.title" marginTop="m">
              <CheckCircleFilled style={{ fontSize: '4rem', color: theme.palette.positive.main }} />
              <Text variant="body3">{prefixNS('analyzingVideoDone')}</Text>
              <Text variant="body3">{filename}</Text>
            </Stack>
          </AnalysisComplete>
        )}

        <Stack alignItems="center">
          <Text as="label" colorVariant="secondary">
            {prefixNS('mp4FilesOnly')}
          </Text>
          <Link
            href={linkT('uploadAVideoFile')}
            isUppercase
            rel="noopener noreferrer"
            target="_blank"
            variant="body4"
            weightVariant="semiBold"
          >
            {prefixNS('seeUploadVideoDocumentation')}
          </Link>
        </Stack>
      </Stack>
      <input
        ref={fileInputRef}
        accept={accept}
        data-testid="video-dropzone__file-input"
        onChange={handleFilesAdded}
        style={{ display: 'none' }}
        type="file"
      />
    </>
  );
};

VideoDropzone.displayName = 'VideoDropzone';

// eslint-disable-next-line no-redeclare
export namespace VideoDropzone {
  export type Props = _Props;
}
