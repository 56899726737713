import * as React from 'react';
import { CheckOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { css, useTheme } from '@emotion/react';
import { Draft, Inline, Stack, Text } from '@resi-media/resi-ui';
import { PlaylistsDisplay } from '@studio/components/PlaylistsDisplay';
import { useDestinations, usePrefix } from '@studio/hooks';
import type { DestinationGroups, Playlists, Schedules } from '@studio/types';
import { BroadcastTime } from '../BroadcastTime';

const WebSubcomponent = ({
  broadcastFields,
  member,
  playlistDetails,
}: {
  broadcastFields?: DestinationGroups.Derived.BroadcastFields;
  member: Schedules.Derived.PartialContentDestinationSchedule;
  playlistDetails?: Playlists.Get.Playlist[];
}) => {
  const { webPresetOptions } = useDestinations();
  const { commonT, prefixNS } = usePrefix('components:', 'destinationModal');
  const theme = useTheme();
  const webPreset = webPresetOptions.find((option) => option.value === member.webEncoderProfileId);

  return (
    <Stack gap="l">
      <Draft.FormField fieldLabel={commonT('webPreset')}>
        <Text dataTestId="web-preset-name" variant="body1">
          {webPreset?.label ?? commonT('default')}
        </Text>
      </Draft.FormField>
      <BroadcastTime broadcastFields={broadcastFields} member={member} />
      <Draft.FormField fieldLabel={commonT('webVideoTitle')}>
        <Text dataTestId="web-video-title" variant="body1">
          {member.title}
        </Text>
      </Draft.FormField>
      <Draft.FormField fieldLabel={commonT('webVideoDescription')}>
        <Text dataTestId="web-video-description" variant="body1">
          {member.description ? member.description : '-'}
        </Text>
      </Draft.FormField>
      {member.autoArchive && (
        <Inline alignItems="center" dataTestId="autosave-display">
          <CheckOutlined
            css={(theme) => css`
              color: ${theme.palette.positive.main};
            `}
          />
          <Text colorVariant="positive">{commonT('autosaveToLibrary')}</Text>
          <Draft.Tooltip content={prefixNS('fields.webChannel.autoArchiveTip')} placement="right">
            <QuestionCircleOutlined style={{ color: theme.palette.primary.main }} />
          </Draft.Tooltip>
        </Inline>
      )}
      <PlaylistsDisplay playlistDetails={playlistDetails} />
    </Stack>
  );
};

WebSubcomponent.displayName = 'WebSubcomponent';

export default WebSubcomponent;
