import * as React from 'react';
import { boolOrBoolString, usePrevious } from '@resi-media/resi-ui';
import { produce } from 'immer';
import { useDispatch, useSelector } from 'react-redux';
import { createContainer } from 'react-tracked';
import type { Union } from 'ts-toolbelt';
import { isAxiosError } from '@studio/api/api-client/is-axios-error';
import { useIsAuthorized, usePrefix } from '@studio/hooks';
import { selectUserId, selectUserName } from '@studio/store/authentication';
import Permissions from '@studio/store/authentication/permissions';
import { selectUserItems } from '@studio/store/users';
import { UsersActionTypes } from '@studio/store/users/types';
import type { Shared } from '@studio/types';
import { Cues } from '@studio/types';
import { filterCuesByTimeRange, formatCuesResponse, sortCuesByPosition, useCuesEndpoint } from './helpers';

type CuesFetchParams = Union.Strict<
  | {
      mediaId: string;
      profileId: string;
      type: Extract<Cues.Components.Types, 'encoder'>;
    }
  | {
      mediaId: string;
      type: Exclude<Cues.Components.Types, 'encoder'>;
    }
>;

type CuesContextState = CuesFetchParams & {
  addId: string;
  blinkId: string;
  copyCue: Cues.Derived.CueWithUser | null;
  cues?: Readonly<Cues.Derived.CueWithUser[]>;
  editId: string;
  hoverId: string;
  timeRange: Shared.Player.TimeRange | null;
};

export const initialCuesState: CuesContextState = {
  addId: '',
  blinkId: '',
  copyCue: null,
  editId: '',
  hoverId: '',
  mediaId: '',
  timeRange: null,
  type: Cues.CUE_TYPES.SAVED,
};

enum CUES_ACTION_TYPES {
  SET_ADD_ID,
  SET_BLINK_ID,
  SET_COPY_CUE,
  SET_EDIT_ID,
  SET_HOVER_ID,
  SET_FETCH_PARAMS,
  SET_TIME_RANGE,
  SET_CUES_IMPLICIT,
  SET_CUES_EXPLICIT,
}

type CuesActions =
  | {
      payload: Cues.Derived.CueWithUser | null;
      type: CUES_ACTION_TYPES.SET_COPY_CUE;
    }
  | {
      payload: Cues.Derived.CueWithUser[];
      type: CUES_ACTION_TYPES.SET_CUES_EXPLICIT;
    }
  | {
      payload: Cues.Derived.CueWithUser[];
      type: CUES_ACTION_TYPES.SET_CUES_IMPLICIT;
    }
  | {
      payload: CuesFetchParams;
      type: CUES_ACTION_TYPES.SET_FETCH_PARAMS;
    }
  | {
      payload: Shared.Player.TimeRange;
      type: CUES_ACTION_TYPES.SET_TIME_RANGE;
    }
  | {
      payload: string;
      type: CUES_ACTION_TYPES.SET_ADD_ID;
    }
  | {
      payload: string;
      type: CUES_ACTION_TYPES.SET_BLINK_ID;
    }
  | {
      payload: string;
      type: CUES_ACTION_TYPES.SET_EDIT_ID;
    }
  | {
      payload: string;
      type: CUES_ACTION_TYPES.SET_HOVER_ID;
    };

const cuesReducer = (state: CuesContextState, action: CuesActions) => {
  switch (action.type) {
    case CUES_ACTION_TYPES.SET_FETCH_PARAMS:
      state.type = action.payload.type;
      state.mediaId = action.payload.mediaId;
      state.profileId = action.payload.profileId ?? '';
      break;
    case CUES_ACTION_TYPES.SET_TIME_RANGE:
      state.timeRange = action.payload;
      break;
    case CUES_ACTION_TYPES.SET_BLINK_ID:
      state.blinkId = action.payload;
      break;
    case CUES_ACTION_TYPES.SET_ADD_ID:
      state.addId = action.payload;
      state.editId = '';
      break;
    case CUES_ACTION_TYPES.SET_COPY_CUE:
      state.addId = '';
      state.copyCue = action.payload;
      state.editId = '';
      break;
    case CUES_ACTION_TYPES.SET_EDIT_ID:
      state.editId = action.payload;
      state.addId = '';
      break;
    case CUES_ACTION_TYPES.SET_HOVER_ID:
      state.hoverId = action.payload;
      break;
    case CUES_ACTION_TYPES.SET_CUES_IMPLICIT:
      state.cues = action.payload;
      break;
    case CUES_ACTION_TYPES.SET_CUES_EXPLICIT:
      state.cues = action.payload;
      state.editId = '';
      state.copyCue = null;
      state.addId = '';
      break;
  }
};

type CuesContextHook = CuesContextState & {
  canShareCues?: boolean;
  cancel: () => void;
  data?: Readonly<Cues.Get.Cue[]> | Readonly<Cues.Get.DestinationCue[]> | Readonly<Cues.Get.EncoderCue[]>;
  deleteCue: {
    callApi: (v: string) => void;
    error: Error | null;
    isLoading: boolean;
  };
  fetchCues: {
    callApi: (silent?: boolean, paramsObj?: CuesFetchParams) => Promise<void>;
    callInterval: (v: CuesFetchParams) => void;
    error: Error | null;
    isLoading: boolean;
    isLoadingInterval: boolean;
  };
  notAvailable: boolean;
  patchCue: {
    callApi: (v: string, cue: Cues.Components.FormState) => void;
    error: Error | null;
    isLoading: boolean;
  };
  postCue: {
    callApi: (v: Cues.Components.FormState) => void;
    error: Error | null;
    isLoading: boolean;
  };
  reset: () => void;
  setAddId: (v: string) => void;
  setCopyCue: (v: Cues.Derived.CueWithUser | null) => void;
  setEditId: (v: string) => void;
  setHoverId: (v: string) => void;
  setTimeRange: (v: Shared.Player.TimeRange) => void;
};

const curriedReducerFunction = produce(cuesReducer);

const useValues = ({
  cues: cuesProp,
  type: typeProp,
}: {
  cues?: Readonly<Cues.Derived.CueWithUser[]>;
  type: Cues.Components.Types;
}): readonly [CuesContextHook, () => void] => {
  const [localState, dispatch] = React.useReducer(curriedReducerFunction, {
    ...initialCuesState,
    cues: cuesProp,
  });
  const previousCues: Cues.Derived.CueWithUser[] | undefined = usePrevious(localState.cues);
  const usersList = useSelector(selectUserItems);
  const user = useSelector(selectUserName) ?? '';
  const userId = useSelector(selectUserId) ?? '';
  const canShareCuesRef = React.useRef<boolean | null>(null);
  const visibilityTimer = React.useRef<ReturnType<typeof setTimeout>>();

  const cuesEndpoint = useCuesEndpoint(typeProp);
  const { fetch } = cuesEndpoint;
  const { callApi, callInterval, error, isFetching, isFetchingInterval, query, responseHeaders } = fetch;
  const canShareSavedVideoCues = useIsAuthorized([Permissions.SHARED_CUES_ADD]);
  const globalDispatch = useDispatch();

  React.useEffect(() => {
    if (typeProp === Cues.CUE_TYPES.SAVED) {
      globalDispatch({ type: UsersActionTypes.FETCH_REQUEST });
    }
  }, [globalDispatch, typeProp]);

  React.useEffect(() => {
    if (typeProp === Cues.CUE_TYPES.SAVED) {
      canShareCuesRef.current = canShareSavedVideoCues;
    } else {
      if (canShareCuesRef.current === null && responseHeaders?.cansetcues) {
        query({ canISetCues: undefined });
        canShareCuesRef.current = boolOrBoolString(responseHeaders.cansetcues);
      }
    }
  }, [canShareSavedVideoCues, query, responseHeaders?.cansetcues, typeProp]);

  const setAddId = React.useCallback((addId: string) => {
    dispatch({
      type: CUES_ACTION_TYPES.SET_ADD_ID,
      payload: addId,
    });
  }, []);

  const setupBlinkTimer = React.useCallback((blinkId: string) => {
    dispatch({
      type: CUES_ACTION_TYPES.SET_BLINK_ID,
      payload: blinkId,
    });
    visibilityTimer.current = setTimeout(() => {
      dispatch({
        type: CUES_ACTION_TYPES.SET_BLINK_ID,
        payload: '',
      });
    }, 7000);
  }, []);

  const setCopyCue = React.useCallback((cue: Cues.Derived.CueWithUser | null) => {
    dispatch({
      type: CUES_ACTION_TYPES.SET_COPY_CUE,
      payload: cue,
    });
  }, []);

  const setCues = React.useCallback((cues: Cues.Derived.CueWithUser[], explicit = false) => {
    dispatch({
      type: explicit ? CUES_ACTION_TYPES.SET_CUES_EXPLICIT : CUES_ACTION_TYPES.SET_CUES_IMPLICIT,
      payload: cues,
    });
  }, []);

  const setEditId = React.useCallback((isEditMode: string) => {
    dispatch({
      type: CUES_ACTION_TYPES.SET_EDIT_ID,
      payload: isEditMode,
    });
  }, []);

  const setFetchParams = React.useCallback((details: CuesFetchParams) => {
    dispatch({
      type: CUES_ACTION_TYPES.SET_FETCH_PARAMS,
      payload: details,
    });
  }, []);

  const setHoverId = React.useCallback((hoverId: string) => {
    dispatch({
      type: CUES_ACTION_TYPES.SET_HOVER_ID,
      payload: hoverId,
    });
  }, []);

  const setTimeRange = React.useCallback((range: Shared.Player.TimeRange) => {
    dispatch({
      type: CUES_ACTION_TYPES.SET_TIME_RANGE,
      payload: range,
    });
  }, []);

  const loadCues = React.useCallback(
    async (silent?: boolean, paramsObj?: CuesFetchParams) => {
      if (paramsObj) {
        setFetchParams(paramsObj);
      }
      try {
        await callApi(undefined, {
          silent,
          params: {
            profileId: paramsObj?.profileId ?? localState.profileId,
            mediaId: paramsObj?.mediaId ?? localState.mediaId,
          },
        });
      } catch (e) {
        console.error('Failed to load cues', e);
      }
    },
    [callApi, localState.mediaId, localState.profileId, setFetchParams]
  );

  const reset = React.useCallback(() => {
    setAddId('');
    setCopyCue(null);
    setEditId('');
  }, [setAddId, setCopyCue, setEditId]);

  const notAvailableError = React.useMemo(() => {
    if (error && isAxiosError(error)) {
      return error.response?.status === 412;
    }
    return false;
  }, [error]);
  const { commonT } = usePrefix('');

  React.useEffect(() => {
    if (cuesEndpoint.fetch.data && !isFetching) {
      const formattedCues =
        cuesEndpoint.type === Cues.CUE_TYPES.SAVED
          ? formatCuesResponse.saved({
              cues: cuesEndpoint.fetch.data,
              users: usersList,
            })
          : formatCuesResponse({ cues: cuesEndpoint.fetch.data, users: usersList });

      if (localState.timeRange) {
        setCues(sortCuesByPosition(filterCuesByTimeRange(formattedCues, localState.timeRange)));
      }

      setCues(sortCuesByPosition(formattedCues));
    }
  }, [
    commonT,
    localState.timeRange,
    setCues,
    typeProp,
    usersList,
    isFetching,
    cuesEndpoint.type,
    cuesEndpoint.fetch.data,
  ]);

  React.useEffect(() => {
    if (localState.cues && previousCues) {
      const newCue = localState.cues.filter((c) => !previousCues.some((previousCue) => previousCue.uuid === c.uuid));
      if (newCue[0]) {
        setupBlinkTimer(newCue[0].uuid);
      }
    }
  }, [localState.cues, previousCues, setupBlinkTimer]);

  const loadCuesInit = React.useCallback(
    async (params: CuesFetchParams) => {
      const { mediaId, profileId } = params;
      setFetchParams(params);
      try {
        await callInterval(null, {
          params: { mediaId, profileId },
        });
      } catch (e) {
        console.error('Failed to load cues', e);
      }
    },
    [callInterval, setFetchParams]
  );

  const deleteCue = React.useCallback(
    async (cueId: string) => {
      await cuesEndpoint.delete.callApi(null, {
        params: { profileId: localState.profileId, mediaId: localState.mediaId, id: cueId },
      });

      // Optimistically delete cue from list (would prefer to do this prior to the call but ui doesn't lend towards that)
      const entriesClone = localState.cues?.slice().filter((e) => e.uuid !== cueId) ?? [];
      setCues(entriesClone, true);

      loadCues(true);
    },
    [localState.cues, localState.profileId, localState.mediaId, setCues, cuesEndpoint.delete, loadCues]
  );

  const patchCue = React.useCallback(
    async (cueId: string, { name, position, visibility }: Cues.Components.FormState) => {
      if (cuesEndpoint.type === Cues.CUE_TYPES.SAVED) {
        await cuesEndpoint.patch.callApi(
          {
            position,
            name,
            visibility,
            userId,
          },
          { params: { mediaId: localState.mediaId, id: cueId } }
        );
      } else {
        await cuesEndpoint.patch.callApi(
          {
            position,
            name,
            privateCue: visibility === Cues.CUE_VISIBILITY.PRIVATE ? true : false,
            user,
          },
          { params: { profileId: localState.profileId, mediaId: localState.mediaId, id: cueId } }
        );
      }

      // Optimistically patch cue from list (would prefer to do this prior to the call but ui doesn't lend towards that)
      const entriesClone =
        localState.cues
          ?.slice()
          .map((e) => (e.uuid !== cueId ? e : { position, name, visibility, uuid: cueId, user, userId })) ?? [];
      setCues(sortCuesByPosition(entriesClone), true);

      reset();
      loadCues(true);
    },
    [cuesEndpoint, loadCues, localState.cues, localState.mediaId, localState.profileId, reset, setCues, user, userId]
  );

  const postCue = React.useCallback(
    async ({ name, position, visibility }: Cues.Components.FormState) => {
      if (cuesEndpoint.type === Cues.CUE_TYPES.SAVED) {
        await cuesEndpoint.post.callApi(
          {
            position,
            name,
            visibility,
            userId,
          },
          { params: { mediaId: localState.mediaId } }
        );
      } else {
        await cuesEndpoint.post.callApi(
          {
            position,
            name,
            privateCue: visibility === Cues.CUE_VISIBILITY.PRIVATE,
            user,
          },
          { params: { profileId: localState.profileId, mediaId: localState.mediaId } }
        );
      }

      // Optimistically post cue from list (would prefer to do this prior to the call but ui doesn't lend towards that)
      const entriesClone = localState.cues?.slice() ?? [];
      setCues(sortCuesByPosition([...entriesClone, { position, name, visibility, uuid: 'temp', user, userId }]), true);

      reset();
      loadCues(true);
    },
    [
      cuesEndpoint.post,
      cuesEndpoint.type,
      loadCues,
      localState.cues,
      localState.mediaId,
      localState.profileId,
      reset,
      setCues,
      user,
      userId,
    ]
  );

  const state = React.useMemo(
    () => ({
      ...localState,
      data: cuesEndpoint.fetch.data,
      fetchCues: {
        callApi: loadCues,
        callInterval: loadCuesInit,
        error: error,
        isLoading: isFetching,
        isLoadingInterval: isFetchingInterval,
      },
      patchCue: {
        callApi: patchCue,
        error: cuesEndpoint.patch.error,
        isLoading: cuesEndpoint.patch.isFetching,
      },
      postCue: {
        callApi: postCue,
        error: cuesEndpoint.post.error,
        isLoading: cuesEndpoint.post.isFetching,
      },
      deleteCue: {
        callApi: deleteCue,
        error: cuesEndpoint.delete.error,
        isLoading: cuesEndpoint.delete.isFetching,
      },
      reset,
      setHoverId,
      canShareCues: Boolean(canShareCuesRef.current),
      cancel: cuesEndpoint.fetch.cancelInterval,
      notAvailable: notAvailableError,
      setAddId,
      setCopyCue,
      setEditId,
      setTimeRange,
    }),
    [
      localState,
      cuesEndpoint.fetch.data,
      cuesEndpoint.fetch.cancelInterval,
      cuesEndpoint.patch.error,
      cuesEndpoint.patch.isFetching,
      cuesEndpoint.post.error,
      cuesEndpoint.post.isFetching,
      cuesEndpoint.delete.error,
      cuesEndpoint.delete.isFetching,
      loadCues,
      loadCuesInit,
      error,
      isFetching,
      isFetchingInterval,
      patchCue,
      postCue,
      deleteCue,
      reset,
      setHoverId,
      notAvailableError,
      setAddId,
      setCopyCue,
      setEditId,
      setTimeRange,
    ]
  );

  return [
    state,
    () => {
      throw new Error('use functions in the state');
    },
  ];
};

const {
  Provider: CuesProvider,
  useTrackedState: useCues,
  useUpdate: useCuesUpdate,
} = createContainer<
  CuesContextHook,
  () => void,
  { cues?: Readonly<Cues.Derived.CueWithUser[]>; type: Cues.Components.Types }
>(useValues);

export { CuesProvider, useCues, useCuesUpdate };
export type { CuesContextHook };
