import type { SagaIterator } from 'redux-saga';
import type { AllEffect, ForkEffect } from 'redux-saga/effects';
import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';
import { client } from '@studio/api/api-client/client';
import { STALE_DATA_TIMEOUT } from '@studio/constants/api-constants';
import { CENTRAL_API_V3 } from '@studio/constants/env-variables';
import { selectAccessToken, selectCustomerId } from '@studio/store/authentication/selectors';
import type { WebPresets } from '@studio/types';
import type { ApiClientConfig } from '@studio/types/axios';
import { fetch, set } from './actions';
import { selectTimestamp } from './selectors';
import { WebPresetActionTypes } from './types';

function* handleFetch(): SagaIterator {
  const timestamp: number = yield select(selectTimestamp);
  if (Date.now() - timestamp < STALE_DATA_TIMEOUT) {
    yield put(fetch.cancel());
    return;
  }

  const customerId: string = yield select(selectCustomerId);
  const url = `${CENTRAL_API_V3}/customers/${customerId}/webencoderprofiles`;
  const token: string = yield select(selectAccessToken);
  const config: ApiClientConfig = { token };

  try {
    const response: WebPresets.Get.WebPreset[] = yield call(client, url, config);
    yield put(fetch.success(response));
    yield put(set.timestamp(Date.now()));
  } catch (error) {
    if (error instanceof Error) {
      yield put(fetch.failure(error));
    }
  }
}

export function* watchFetchRequest<T>(): Generator<ForkEffect<never>, void, T> {
  yield takeLatest(WebPresetActionTypes.FETCH_REQUEST, handleFetch);
}

export function* webPresetsSaga<T>(): Generator<AllEffect<ForkEffect<void>>, void, T> {
  yield all([fork(watchFetchRequest)]);
}
