export interface EncoderVideo {
  bucket: string;
  cloudUrl: string;
  customerName: string;
  encoderId: string;
  eventProfileId: string;
  folderPath: string;
  format: string | null;
  hlsUrl: string;
  name: string;
  removeTime: string;
  scheduleId: string;
  startTime: string;
  url: string;
  uuid: string;
}

export enum SortOptions {
  MOST_RECENT = 'mostRecent',
  NAME_AtoZ = 'alphabetical',
  NAME_ZtoA = 'alphaReverse',
  OLDEST = 'oldest',
}

export interface EncoderVideoState {
  error: Error | null;
  isFetching: boolean;
  isFetchingInitial: boolean;
  items: EncoderVideo[];
  sortBy: string;
}

export enum ActionTypes {
  DELETE_ITEM = '@@encodervideos/DELETE_ITEM',
  FETCH_REQUEST = '@@encodervideos/FETCH_REQUEST',
  FETCH_REQUEST_CANCEL = '@@encodervideos/FETCH_REQUEST_CANCEL',
  FETCH_REQUEST_ERROR = '@@encodervideos/FETCH_REQUEST_ERROR',
  FETCH_REQUEST_SUCCESS = '@@encodervideos/FETCH_REQUEST_SUCCESS',
  FETCH_REQUEST_TIMESTAMP = '@@encodervideos/FETCH_REQUEST_TIMESTAMP',
  SORT_RESULTS = '@@encodervideos/SORT_RESULTS',
}
