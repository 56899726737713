import { createAction, createAsyncAction } from 'typesafe-actions';
import type { FetchOptions } from '@studio/types';
import type { EncoderVideo } from './types';
import { ActionTypes } from './types';

export const fetchEncoderVideosAsync = createAsyncAction(
  ActionTypes.FETCH_REQUEST,
  ActionTypes.FETCH_REQUEST_SUCCESS,
  ActionTypes.FETCH_REQUEST_ERROR,
  ActionTypes.FETCH_REQUEST_CANCEL
)<FetchOptions | undefined, EncoderVideo[], Error, undefined>();

export const deleteEncoderVideo = createAction(ActionTypes.DELETE_ITEM)<string>();

export const setSortOrder = createAction(ActionTypes.SORT_RESULTS)<string>();
