function sortByAlpha<T>(getTextProperty: (object: T) => string, isDescending?: boolean) {
  return (objectA: T, objectB: T): number => {
    const a = getTextProperty(objectA);
    const b = getTextProperty(objectB);

    if (isDescending) {
      if (b < a) {
        return -1;
      }
      if (b > a) {
        return 1;
      }
      return 0;
    }

    if (a < b) {
      return -1;
    }
    if (a > b) {
      return 1;
    }
    return 0;
  };
}

export default sortByAlpha;
