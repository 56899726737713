import type { EncoderLogs } from '@studio/types';

export enum ActionTypes {
  CURRENT_ENCODER_ID = '@@encoderLogs/CURRENT_ENCODER_ID',
  FETCH_REQUEST = '@@encoderlogs/FETCH_REQUEST',
  FETCH_REQUEST_CANCEL = '@@encoderlogs/FETCH_REQUEST_CANCEL',
  FETCH_REQUEST_ERROR = '@@encoderlogs/FETCH_REQUEST_ERROR',
  FETCH_REQUEST_SUCCESS = '@@encoderlogs/FETCH_REQUEST_SUCCESS',
  FILTER_RESULTS = '@@encoderlogs/FILTER_RESULTS',
}

export type EncoderLogsState = {
  currentEncoderId: string;
  error: Error | null;
  filter: string;
  isFetching: boolean;
  items: Map<string, EncoderLogs.Get.LogEntry[]>;
  logLevel: EncoderLogs.Get.LogLevel;
};
