import i18next from 'i18next';
import type { CombinedState } from 'redux';
import { createSelector } from 'reselect';
import { getIsAccessExpired } from '@studio/helpers/social-media';
import { sortByAlphaIgnoreCase } from '@studio/helpers/sortByAlphaIgnoreCase';
import type { RootState } from '../root-reducer';
import type { SocialMediaAccount, SocialMediaAccountState, SocialMediaAccountOptions } from './types';
import { ACCOUNT_ACCESS_STATUS, ACCOUNT_SIMULCAST_CODE } from './types';

export const selectSocialMediaAccountsState = (state: RootState): CombinedState<SocialMediaAccountState> =>
  state.socialMediaAccounts;

export const selectItems = createSelector(
  selectSocialMediaAccountsState,
  (state: SocialMediaAccountState): SocialMediaAccount[] => state.items
);

export const selectFilter = createSelector(selectSocialMediaAccountsState, (state): string => state.filter);

export const selectFacebookAccounts = createSelector(
  selectItems,
  (accounts: SocialMediaAccount[]): SocialMediaAccount[] => {
    return accounts.filter((account) => account.type === 'fb');
  }
);

export const selectYouTubeAccounts = createSelector(
  selectItems,
  (accounts: SocialMediaAccount[]): SocialMediaAccount[] => {
    return accounts.filter((account) => account.type === 'yt');
  }
);

export const sortSocialMediaAccounts = createSelector(
  selectItems,
  (accounts: SocialMediaAccount[]): SocialMediaAccount[] => {
    const sortedByName = [...accounts].sort(sortByAlphaIgnoreCase((account: SocialMediaAccount) => account.name));

    return sortedByName.sort(sortByAlphaIgnoreCase((account: SocialMediaAccount) => account.type, true));
  }
);

const getStatusStringForAccount = (status = '', expiresAt?: string | null): string => {
  switch (status) {
    case ACCOUNT_SIMULCAST_CODE.TOKEN_INVALID:
    case ACCOUNT_SIMULCAST_CODE.NOT_ENABLED:
      return ACCOUNT_ACCESS_STATUS.NO_ACCESS;
    case ACCOUNT_SIMULCAST_CODE.OK:
      // Token is OK and contains no expiration
      if (!expiresAt) {
        return '';
      }

      // Is expiration before current date?
      if (getIsAccessExpired(expiresAt)) {
        return ACCOUNT_ACCESS_STATUS.EXPIRED;
      }

      return '';
    default:
      return '';
  }
};

export const selectOptions = createSelector([sortSocialMediaAccounts], (items: SocialMediaAccount[]) => {
  return items.reduce(
    (agg, profile) => {
      const status = getStatusStringForAccount(profile.status, profile.expiresAt);
      agg[profile.type].set(profile.uuid, {
        label: `${profile.name}${status && ` (${i18next.t(`pages:socialMediaAccounts.${status}`)})`}`,
        value: profile.uuid,
        invalidStatus: status,
        data: profile,
      });
      return agg;
    },
    {
      fb: new Map() as Map<string, SocialMediaAccountOptions>,
      yt: new Map() as Map<string, SocialMediaAccountOptions>,
    }
  );
});

export const filterItems = createSelector(
  sortSocialMediaAccounts,
  selectFilter,
  (items, filter): SocialMediaAccount[] => {
    return items.filter((item) => item.name.toLowerCase().includes(filter.toLowerCase()));
  }
);

export const selectSocialTimestamp = createSelector(selectSocialMediaAccountsState, (state): number => state.timeStamp);

export const selectFbAuthInfo = createSelector(selectSocialMediaAccountsState, (state) => state.fbAuthInfo);

export const selectFbAuthError = createSelector(selectSocialMediaAccountsState, (state) => state.fbAuthError);
