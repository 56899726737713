import * as React from 'react';
import { css } from '@emotion/react';
import { Badge, Draft, Inline, Progress, useToast } from '@resi-media/resi-ui';
import copy from 'copy-to-clipboard';
import { useDispatch } from 'react-redux';
import { match, P } from 'ts-pattern';
import { TrackEventName, TrackEventProp } from '@studio/constants/tracking-constants';
import { CuesProvider, useCues } from '@studio/contexts';
import { useClient, useIsFirstRender, usePrefix } from '@studio/hooks';
import { tracking } from '@studio/store';
import { Cues, Library } from '@studio/types';
import { ErrorBlock } from '../ErrorBlock';
import { DownloadLink, Form } from './components';
import type { DownloadMediaForm as _DownloadMediaForm, ModalState as _ModalState } from './types';

const DownloadMediaModalInternal = () => {
  const { eventProfileId, eventType, onCloseReset, title, uuid } = Draft.ModalContext.useModal<_ModalState>();
  const { commonT, prefixNS } = usePrefix('components:', 'downloadMedia');
  const dispatch = useDispatch();
  const { cues, fetchCues } = useCues();
  const { trigger } = useToast();
  const [downloadUrl, setDownloadUrl] = React.useState('');

  /* Summary Endpoints */
  const getMediaSummary = useClient({
    config: useClient.mediaMetadata.v1.media.id.summary.GET,
    params: { mediaId: uuid },
  });
  const getContentDestinationSummary = useClient({
    config: useClient.central.v3.contentDestinations.id.summary.GET,
    params: { destinationId: uuid },
  });
  const getEncoderEventSummary = useClient({
    config: useClient.central.v3.events.id.summary.GET,
    params: { eventId: uuid },
  });
  /* Download Url Endpoints */
  const getLibraryDownloadUrl = useClient({
    config: useClient.central.v3.library.id.download.POST,
    params: { mediaId: uuid },
  });
  const getEventsDownloadUrl = useClient({
    config: useClient.central.v3.events.id.download.POST,
    params: { eventId: uuid },
  });
  const getContentDestinationsDownloadUrl = useClient({
    config: useClient.central.v3.contentDestinations.id.download.POST,
    params: { mediaId: uuid },
  });

  const summaryEndpoint = React.useMemo(() => {
    if (eventType === Library.EVENT_TYPE.EVENT) {
      return getEncoderEventSummary;
    } else if (eventType === Library.EVENT_TYPE.CONTENT_DESTINATION) {
      return getContentDestinationSummary;
    }

    return getMediaSummary;
  }, [eventType, getContentDestinationSummary, getEncoderEventSummary, getMediaSummary]);
  const isFirstRender = useIsFirstRender();

  const { callApi: getDownloadUrl, isFetching: isLoadingDownloadUrl } = React.useMemo(() => {
    if (eventType === Library.EVENT_TYPE.LIBRARY) {
      return getLibraryDownloadUrl;
    } else if (eventType === Library.EVENT_TYPE.EVENT) {
      return getEventsDownloadUrl;
    }

    return getContentDestinationsDownloadUrl;
  }, [eventType, getContentDestinationsDownloadUrl, getEventsDownloadUrl, getLibraryDownloadUrl]);

  const fetchDownloadLink = React.useCallback(
    async ({ audioChannel, startCue, stopCue, submit, videoBitrate }: _DownloadMediaForm & { submit?: boolean }) => {
      try {
        const response = await getDownloadUrl({
          audioRepresentationId: audioChannel,
          videoRepresentationId: videoBitrate,
          ...(startCue.value !== -1 && { startTimeMs: startCue.value }),
          ...(stopCue?.value && stopCue.value !== -1 && { endTimeMs: stopCue.value }),
        });

        if (submit) {
          return response;
        } else {
          setDownloadUrl(response.downloadUrl);
          copy(response.downloadUrl);
          dispatch({
            type: tracking.ActionTypes.TRACK_SEGMENT_REQUEST,
            payload: {
              eventName: TrackEventName.EVENT_DOWNLOAD,
              eventProps: {
                [TrackEventProp.TRANSCODED_EVENT_UUID]: uuid,
              },
            },
          });
          return response;
        }
      } catch (e) {
        console.error(e);
        trigger({
          content: prefixNS('downloadFailure', {
            mediaTitle: title,
          }),
        });
      }
    },
    [dispatch, getDownloadUrl, prefixNS, title, trigger, uuid]
  );

  const fetchCuesData = React.useCallback(() => {
    if (!cues) {
      if (eventType === Library.EVENT_TYPE.EVENT) {
        fetchCues.callApi(false, { mediaId: uuid, profileId: eventProfileId ?? '', type: Cues.CUE_TYPES.ENCODER });
        return;
      } else if (eventType === Library.EVENT_TYPE.CONTENT_DESTINATION) {
        fetchCues.callApi(false, { mediaId: uuid, type: Cues.CUE_TYPES.WEB });
        return;
      }
      fetchCues.callApi(false, { mediaId: uuid, type: Cues.CUE_TYPES.SAVED });
    }
  }, [cues, eventProfileId, eventType, fetchCues, uuid]);

  React.useEffect(() => {
    if (isFirstRender) {
      summaryEndpoint.callApi();
      fetchCuesData();
    }
  }, [fetchCuesData, isFirstRender, summaryEndpoint]);

  const { data, error, isLoading } = React.useMemo(() => {
    return {
      data: summaryEndpoint.data,
      isLoading: summaryEndpoint.isFetching || fetchCues.isLoading,
      error: summaryEndpoint.error || fetchCues.error,
    };
  }, [fetchCues.error, fetchCues.isLoading, summaryEndpoint.data, summaryEndpoint.error, summaryEndpoint.isFetching]);

  return (
    <>
      <Draft.ModalHeader
        dataTestId="download-media__header"
        header={
          <Inline gap="m">
            {prefixNS('title')}
            {data?.live && (
              <Badge colorVariant="red" dataTestId="download-media__badge">
                {commonT('live')}
              </Badge>
            )}
          </Inline>
        }
        onClose={onCloseReset}
        showBorder
      />
      {match({ data, downloadUrl, isLoading })
        .with({ isLoading: true }, () => (
          <Progress
            customCss={{
              container: css`
                margin: auto;
              `,
            }}
            dataTestId="download-media__progress"
          />
        ))
        .with({ data: undefined }, () => (
          <Draft.ModalBody dataTestId="download-media__body--error">
            <ErrorBlock error={error} />
          </Draft.ModalBody>
        ))
        .with({ data: P.not(undefined), downloadUrl: '' }, ({ data: d }) => (
          <Form eventSummary={d} isLoadingDownloadUrl={isLoadingDownloadUrl} onClickDownloadLink={fetchDownloadLink} />
        ))
        .otherwise(() => (
          <DownloadLink downloadUrl={downloadUrl} />
        ))}
    </>
  );
};

DownloadMediaModalInternal.displayName = 'DownloadMediaModalInternal';

type _Props = {
  cues?: Readonly<Cues.Derived.CueWithUser[]>;
  type: Cues.Components.Types;
};

/* eslint-disable import/export */
export const DownloadMediaModal = ({ cues, type }: _Props) => {
  return (
    <CuesProvider cues={cues} type={type}>
      <DownloadMediaModalInternal />
    </CuesProvider>
  );
};

DownloadMediaModal.displayName = 'DownloadMediaModal';

// eslint-disable-next-line no-redeclare
export namespace DownloadMediaModal {
  export type Form = _DownloadMediaForm;
  export type ModalState = _ModalState;
  export type Props = _Props;
}
