import { match } from 'ts-pattern';
import { INVALID_STATUS } from '@studio/constants/social';

const getInvalidStatus = (invalidStatus: string) =>
  match(invalidStatus)
    .with(INVALID_STATUS.EXPIRED, () => INVALID_STATUS.EXPIRED)
    .with(INVALID_STATUS.FETCH_ERROR, () => INVALID_STATUS.FETCH_ERROR)
    .with(INVALID_STATUS.NO_ACCESS, () => INVALID_STATUS.NO_ACCESS)
    .otherwise(() => undefined);

export default getInvalidStatus;
