import type { AxiosResponse } from 'axios';
import type { AllEffect, ForkEffect } from 'redux-saga/effects';
import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';
import { client } from '@studio/api/api-client/client';
import { STALE_DATA_TIMEOUT } from '@studio/constants/api-constants';
import { MEDIA_METADATA_API_V1 } from '@studio/constants/env-variables';
import { LINKS } from '@studio/constants/shared';
import { parseHeaderLink } from '@studio/helpers';
import { selectAccessToken, selectCustomerId } from '../authentication/selectors';
import { fetchMorePlaylistsAsync, fetchPlaylistsAsync, setFetchRequestTimestamp } from './actions';
import { selectListInfo, selectTimestamp } from './selectors';
import type { PlaylistListInfo } from './types';
import { ActionTypes } from './types';

const PAGE_SIZE = 50;

function* handleFetch(action: ReturnType<typeof fetchPlaylistsAsync.request>) {
  const timestamp: number = yield select(selectTimestamp);
  if (!action.payload?.force && Date.now() - timestamp < STALE_DATA_TIMEOUT) {
    yield put(fetchPlaylistsAsync.cancel());
    return;
  }

  const token: string = yield select(selectAccessToken);
  const customerId: string = yield select(selectCustomerId);

  const url = `${MEDIA_METADATA_API_V1}/customers/${customerId}/playlists?size=${PAGE_SIZE}&sort=${action.payload?.sort}`;

  try {
    const response: AxiosResponse = yield call(client, url, { token, returnResponse: true });
    const nextUrl = parseHeaderLink(response.headers.link);

    yield put(
      fetchPlaylistsAsync.success({
        playlists: response.data,
        nextUrl: nextUrl[LINKS.NEXT],
      })
    );
    yield put(setFetchRequestTimestamp(Date.now()));
  } catch (error) {
    if (error instanceof Error) {
      yield put(fetchPlaylistsAsync.failure(error));
    }
  }
}

function* handleFetchMore() {
  const listInfo: PlaylistListInfo = yield select(selectListInfo);

  if (listInfo.nextUrl) {
    try {
      const token: string = yield select(selectAccessToken);
      const response: AxiosResponse = yield call(client, listInfo.nextUrl, { token, returnResponse: true });
      const nextUrl = parseHeaderLink(response.headers.link);
      yield put(fetchMorePlaylistsAsync.success({ playlists: response.data, nextUrl: nextUrl[LINKS.NEXT] }));
      yield put(setFetchRequestTimestamp(Date.now()));
    } catch (error) {
      if (error instanceof Error) {
        yield put(fetchMorePlaylistsAsync.failure(error));
      }
    }
  }
}

export function* watchFetchRequest<T>(): Generator<ForkEffect<void>, void, T> {
  yield takeLatest(ActionTypes.FETCH_REQUEST, handleFetch);
}

export function* watchFetchMoreRequest<T>(): Generator<ForkEffect<never>, void, T> {
  yield takeLatest(ActionTypes.FETCH_MORE_REQUEST, handleFetchMore);
}

export function* playlistsSaga<T>(): Generator<AllEffect<ForkEffect<void>>, void, T> {
  yield all([fork(watchFetchRequest), fork(watchFetchMoreRequest)]);
}
