import type { Customer } from '@studio/types';

export enum CustomerActionTypes {
  FETCH_NAMES = '@@customer/FETCH_NAMES',
  FETCH_NAMES_CANCEL = '@@customer/FETCH_NAMES_CANCEL',
  FETCH_NAMES_ERROR = '@@customer/FETCH_NAMES_ERROR',
  FETCH_NAMES_SUCCESS = '@@customer/FETCH_NAMES_SUCCESS',
  FETCH_REFRESH = '@@customer/FETCH_REFRESH',
  FETCH_REFRESH_CANCEL = '@@customer/FETCH_REFRESH_CANCEL',
  FETCH_REFRESH_ERROR = '@@customer/FETCH_REFRESH_ERROR',
  FETCH_REFRESH_SUCCESS = '@@customer/FETCH_REFRESH_SUCCESS',
  FETCH_REQUEST = '@@customer/FETCH_REQUEST',
  FETCH_REQUEST_CANCEL = '@@customer/FETCH_REQUEST_CANCEL',
  FETCH_REQUEST_CANCEL_FTS = '@@customer/FETCH_REQUEST_CANCEL_FTS',
  FETCH_REQUEST_ERROR = '@@customer/FETCH_REQUEST_ERROR',
  FETCH_REQUEST_ERROR_FTS = '@@customer/FETCH_REQUEST_ERROR_FTS',
  FETCH_REQUEST_FTS = '@@customer/FETCH_REQUEST_FTS',
  FETCH_REQUEST_SUCCESS = '@@customer/FETCH_REQUEST_SUCCESS',
  FETCH_REQUEST_SUCCESS_FTS = '@@customer/FETCH_REQUEST_SUCCESS_FTS',
  FETCH_REQUEST_TIMESTAMP = '@@customer/FETCH_REQUEST_TIMESTAMP',
  FETCH_REQUEST_TOGGLES = '@@customer/FETCH_REQUEST_TOGGLES',
  FETCH_REQUEST_TOGGLES_CANCEL = '@@customer/FETCH_REQUEST_TOGGLES_CANCEL',
  FETCH_REQUEST_TOGGLES_ERROR = '@@customer/FETCH_REQUEST_TOGGLES_ERROR',
  FETCH_REQUEST_TOGGLES_REGISTERED = '@@customer/FETCH_REQUEST_TOGGLES_REGISTERED',
  FETCH_REQUEST_TOGGLES_REGISTERED_CANCEL = '@@customer/FETCH_REQUEST_TOGGLES_REGISTERED_CANCEL',
  FETCH_REQUEST_TOGGLES_REGISTERED_ERROR = '@@customer/FETCH_REQUEST_TOGGLES_REGISTERED_ERROR',
  FETCH_REQUEST_TOGGLES_REGISTERED_SUCCESS = '@@customer/FETCH_REQUEST_TOGGLES_REGISTERED_SUCCESS',
  FETCH_REQUEST_TOGGLES_SUCCESS = '@@customer/FETCH_REQUEST_TOGGLES_SUCCESS',
  TRIAL_CANCELLED = '@@customer/TRIAL_CANCELLED',
}

export type CustomerNames = {
  name: string;
  uuid: string;
};

export type CustomerNamesState = {
  data: CustomerNames[] | null;
  error: Error | null;
  isFetching: boolean;
};

export type CustomerStore = {
  data: Customer.Get.Customer | null;
  error: Error | null;
  ftsStatus: FirstTimeSetupStatus | null;
  isFetching: boolean;
  isFetchingFts: boolean;
  isFetchingToggles: boolean;
  isFetchingTogglesRegistered: boolean;
  timestamp: number;
  toggles: Customer.Get.Toggle[] | null;
  togglesRegistered: Customer.Get.TogglesRegistered | null;
};

export type CustomerState = {
  customer: CustomerStore;
  customerNames: CustomerNamesState;
};

export type FirstTimeSetupStatus = {
  hasContact: boolean;
  hasEncoder: boolean;
  hasEventProfile: boolean;
  hasWebEventProfile: boolean;
  hasWebPlan: boolean;
};
