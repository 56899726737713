import * as React from 'react';
import { usePrefix } from '@studio/hooks';

type _Props = {
  hours: number;
  minutes: number;
  seconds: number;
};

const VideoDuration = ({ hours, minutes, seconds }: _Props) => {
  const { commonT } = usePrefix('');

  return (
    <>
      {hours > 0 && ` ${hours}${commonT('duration.hr')}`}
      {minutes > 0 && ` ${minutes}${commonT('duration.min')}`}
      {seconds > 0 && ` ${seconds}${commonT('duration.sec')}`}
    </>
  );
};

VideoDuration.displayName = 'VideoDuration';

export default VideoDuration;
