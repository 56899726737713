import * as React from 'react';
import { RELOAD_CUES_TIME } from '@studio/constants/library';
import { useClient } from '@studio/hooks';
import { Cues } from '@studio/types';

function useCuesEndpoint(type: Cues.Components.Types) {
  /* Saved Video Section */
  const addSavedVideoCue = useClient({
    config: useClient.cues.v1.media.id.cues.POST,
    params: { mediaId: '' },
  });
  const getSavedVideoCues = useClient({
    config: useClient.cues.v1.media.id.cues.GET,
    params: { mediaId: '' },
    interval: RELOAD_CUES_TIME,
  });
  const updateSavedVideoCue = useClient({
    config: useClient.cues.v1.media.id.cues.id.PATCH,
    params: { id: '', mediaId: '' },
  });
  const deleteSavedVideoCue = useClient({
    config: useClient.cues.v1.media.id.cues.id.DELETE,
    params: { id: '', mediaId: '' },
  });

  /* Web Video Section */
  const addWebVideoCue = useClient({
    config: useClient.central.v3.contentDestinations.id.cues.POST,
    params: { mediaId: '' },
  });
  const getWebVideoCues = useClient({
    config: useClient.central.v3.contentDestinations.id.cues.GET,
    params: { mediaId: '' },
    query: { canISetCues: 1 },
    interval: RELOAD_CUES_TIME,
  });
  const updateWebVideoCue = useClient({
    config: useClient.central.v3.contentDestinations.id.cues.id.PATCH,
    params: { id: '', mediaId: '' },
  });
  const deleteWebVideoCue = useClient({
    config: useClient.central.v3.contentDestinations.id.cues.id.DELETE,
    params: { id: '', mediaId: '' },
  });

  /* Encoder Video Section */
  const addEncoderVideoCue = useClient({
    config: useClient.central.v2.streamProfiles.id.events.id.cues.POST,
    params: { profileId: '', mediaId: '' },
  });
  const getEncoderVideoCues = useClient({
    config: useClient.central.v2.streamProfiles.id.events.id.cues.GET,
    params: { profileId: '', mediaId: '' },
    query: { canISetCues: 1 },
    interval: RELOAD_CUES_TIME,
  });
  const updateEncoderVideoCue = useClient({
    config: useClient.central.v2.streamProfiles.id.events.id.cues.id.PATCH,
    params: { id: '', profileId: '', mediaId: '' },
  });
  const deleteEncoderVideoCue = useClient({
    config: useClient.central.v2.streamProfiles.id.events.id.cues.id.DELETE,
    params: { id: '', profileId: '', mediaId: '' },
  });

  return React.useMemo(() => {
    if (type === Cues.CUE_TYPES.WEB) {
      return {
        fetch: getWebVideoCues,
        patch: updateWebVideoCue,
        post: addWebVideoCue,
        delete: deleteWebVideoCue,
        type,
      };
    } else if (type === Cues.CUE_TYPES.ENCODER) {
      return {
        fetch: getEncoderVideoCues,
        patch: updateEncoderVideoCue,
        post: addEncoderVideoCue,
        delete: deleteEncoderVideoCue,
        type,
      };
    }

    return {
      fetch: getSavedVideoCues,
      patch: updateSavedVideoCue,
      post: addSavedVideoCue,
      delete: deleteSavedVideoCue,
      type,
    };
  }, [
    addEncoderVideoCue,
    addSavedVideoCue,
    addWebVideoCue,
    deleteEncoderVideoCue,
    deleteSavedVideoCue,
    deleteWebVideoCue,
    getEncoderVideoCues,
    getSavedVideoCues,
    getWebVideoCues,
    type,
    updateEncoderVideoCue,
    updateSavedVideoCue,
    updateWebVideoCue,
  ]);
}
export default useCuesEndpoint;
