import type { AllEffect, ForkEffect } from 'redux-saga/effects';
import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';
import { TrackOrgProp, TrackUserProp } from '@studio/constants/tracking-constants';
import { selectCustomerId, selectCustomerName, selectUserName } from '../authentication/selectors';
import { trackSegmentAsync } from './actions';
import { ActionTypes } from './types';

function* handleTrackSegment(action: ReturnType<typeof trackSegmentAsync.request>) {
  try {
    const customerId: string = yield select(selectCustomerId);
    const customerName: string = yield select(selectCustomerName);
    const userName: string = yield select(selectUserName);

    yield call(window.analytics.track, action.payload.eventName, {
      ...action.payload.eventProps,
      [TrackUserProp.USER_NAME]: userName,
      [TrackOrgProp.ORG_NAME]: customerName,
      [TrackOrgProp.ORG_UUID]: customerId,
    });

    yield put(trackSegmentAsync.success());
  } catch (err) {
    if (err instanceof Error) {
      yield put(trackSegmentAsync.failure(err));
    }
  }
}

export function* watchTrackSegment<T>(): Generator<ForkEffect<never>, void, T> {
  yield takeLatest(ActionTypes.TRACK_SEGMENT_REQUEST, handleTrackSegment);
}

export function* trackingSaga<T>(): Generator<AllEffect<ForkEffect<void>>, void, T> {
  yield all([fork(watchTrackSegment)]);
}
