import axios from 'axios';
import { HTTP_METHODS } from '@studio/constants/axios';
import { CENTRAL_API_V3 } from '@studio/constants/env-variables';
import { fetchImage } from '@studio/helpers';
import type { SocialMediaAccount } from '@studio/store/social-media';
import type { Crosspost, DestinationDetails } from '@studio/types';
import type { ApiClientConfig } from '@studio/types/axios';
import { client } from '../api-client/client';

export function editSocialMediaChannel(
  channelId: string,
  payload: Partial<SocialMediaAccount>,
  customerId: string,
  token = ''
): Promise<void> {
  const url = `${CENTRAL_API_V3}/customers/${customerId}/socialmedia/${channelId}`;
  return client<void>(url, { method: HTTP_METHODS.PATCH, data: payload, token });
}

export function fetchSocialMediaChannel(
  channelId: string,
  customerId: string,
  token = ''
): Promise<SocialMediaAccount> {
  const url = `${CENTRAL_API_V3}/customers/${customerId}/socialmedia/${channelId}`;
  return client<SocialMediaAccount>(url, { token });
}

export const getFacebookCrossposts = (
  customerId: string,
  accountId: string,
  destinationId: string,
  token: string
): Promise<Crosspost[]> => {
  return client(
    `${CENTRAL_API_V3}/customers/${customerId}/socialmedia/${accountId}/destinations/${destinationId}/crosspostdestinations`,
    { token }
  );
};

export function getSocialMediaDestinations(
  customerId: string,
  channelId: string,
  token: string
): Promise<DestinationDetails[]> {
  const url = `${CENTRAL_API_V3}/customers/${customerId}/socialmedia/${channelId}/destinations`;
  const config: ApiClientConfig = { token };
  return client<DestinationDetails[]>(url, config);
}

export const uploadSocialMediaThumbnail = async (url: string, fileUrl: string): Promise<void> => {
  const file = await fetchImage(fileUrl);
  const formData = new FormData();
  formData.append('file', file);
  return axios.post(url, formData);
};
