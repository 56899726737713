import { Inline, Link, Text } from '@resi-media/resi-ui';
import { Link as RouterLink } from 'react-router-dom';

type _Props = {
  linkText: string;
  onClose?: () => void;
  urlPath: string;
  warningText: string;
};

const NoSelectionDisplay = ({ linkText, onClose, urlPath, warningText }: _Props) => {
  return (
    <Inline alignItems="center" dataTestId="no-selection-display">
      <Text as="span" colorVariant="negative" dataTestId="warning-text" variant="body4">
        {warningText}
      </Text>
      <Link as={RouterLink} dataTestId="link-route" isLink onClick={onClose} to={urlPath} variant="body4">
        {linkText}
      </Link>
    </Inline>
  );
};

NoSelectionDisplay.displayName = 'NoSelectionDisplay';

export default NoSelectionDisplay;
