import type { OpenSource } from '@studio/types';

export const OPEN_SOURCE_BUCKET_URL = 'https://storage.googleapis.com/resi-licenses';

export const OPEN_SOURCE_PRODUCT_SORT_ORDER = ['encoder', 'decoder', 'stream_upload'];

export const PRODUCT_NAME_ENCODER = 'encoder';
export const PRODUCT_NAME_DECODER = 'decoder';
export const PRODUCT_NAME_STREAM_UPLOAD = 'stream_upload';

export const OPEN_SOURCE_MANIFEST_FILES: OpenSource.Components.Product[] = [
  { name: PRODUCT_NAME_ENCODER, manifest: 'encoder_manifest.json' },
  { name: PRODUCT_NAME_DECODER, manifest: 'decoder_manifest.json' },
  { name: PRODUCT_NAME_STREAM_UPLOAD, manifest: 'stream-upload_manifest.json' },
];

export const DEPENDENCY_FFMPEG: OpenSource.Get.Dependency = {
  moduleLicenses: [
    {
      moduleLicense: 'GPLv2',
      moduleLicenseUrl: 'https://www.gnu.org/licenses/old-licenses/gpl-2.0.html',
    },
  ],
  moduleName: 'FFmpeg',
  moduleUrls: ['https://github.com/FFmpeg/FFmpeg'],
};

export const DEPENDENCY_GSTREAMER: OpenSource.Get.Dependency = {
  moduleLicenses: [
    {
      moduleLicense: 'LGPLv2',
      moduleLicenseUrl: 'https://www.gnu.org/licenses/old-licenses/lgpl-2.0-standalone.html',
    },
  ],
  moduleName: 'GStreamer',
  moduleUrls: ['https://gitlab.freedesktop.org/gstreamer/gstreamer'],
};
