import * as React from 'react';
import { CaretDownFilled, CaretUpFilled } from '@ant-design/icons';
import { useTheme } from '@emotion/react';
import { Inline } from '@resi-media/resi-ui';

type _Props = {
  children: React.ReactNode;
  isHover: boolean;
  isSortedDesc?: boolean;
};

const SortableColumnHeader = ({ children, isHover, isSortedDesc }: _Props) => {
  const theme = useTheme();
  const defaultColor = theme.palette.border;
  const sortColor = theme.palette.primary.main;
  // Scale such that the icon (<path>) takes up 7 by 5 pixels
  const transform = `scale(${7 / 5.5}, ${5 / 3.31})`;

  return (
    <Inline alignItems="center" gap="xs" justifyContent="flex-end">
      <span data-testid="sortable-column-header">{children}</span>
      <span
        data-testid="double-caret"
        style={{
          display: 'flex',
          flexDirection: 'column',
          fontSize: theme.typography.pxToRem(8),
          gap: theme.typography.pxToRem(2),
          visibility: isSortedDesc !== undefined || isHover ? 'visible' : 'hidden',
          width: theme.typography.pxToRem(16),
        }}
      >
        <CaretUpFilled
          css={{ color: isSortedDesc === false ? sortColor : defaultColor, transform }}
          data-testid="table-up-caret"
        />
        <CaretDownFilled
          css={{ color: isSortedDesc === true ? sortColor : defaultColor, transform }}
          data-testid="table-down-caret"
        />
      </span>
    </Inline>
  );
};

SortableColumnHeader.displayName = 'SortableColumnHeader';

export default SortableColumnHeader;
