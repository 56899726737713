import type { Option } from '@resi-media/resi-ui';
import type { CombinedState } from 'redux';
import { createSelector } from 'reselect';
import type { Hardware } from '@studio/types';
import type { RootState } from '../../root-reducer';
import type { State } from './types';

export const selectHardwareState = (state: RootState): CombinedState<State> => state.admin.hardware;

export const selectLocationsData = createSelector(selectHardwareState, (state) => state.locations.data);

export const selectModelsData = createSelector(selectHardwareState, (state) => state.models.data);

export const selectStatusesData = createSelector(selectHardwareState, (state) => state.status.data);

export const selectCyclesData = createSelector(selectHardwareState, (state) => state.cycles.data);

export const selectLocationsTimestamp = createSelector(selectHardwareState, (state) => state.locations.timestamp);

export const selectModelsTimestamp = createSelector(selectHardwareState, (state) => state.models.timestamp);

export const selectCyclesTimestamp = createSelector(selectHardwareState, (state) => state.cycles.timestamp);

export const selectStatusTimestamp = createSelector(selectHardwareState, (state) => state.status.timestamp);

export const selectLocationsOptions = createSelector(selectLocationsData, (data): Option[] =>
  data.map((item): Option => ({ label: item.name, value: item.uuid }))
);

export const selectModelsOptions = createSelector(selectModelsData, (data): Option[] =>
  data.map((item): Option => ({ label: item.name, value: item.uuid }))
);

export const selectModelsDecoderOptions = createSelector(selectModelsData, (data): Option[] =>
  data.filter((m) => m.type === 'decoder').map((item): Option => ({ label: item.name, value: item.uuid }))
);

export const selectModelsEncoderOptions = createSelector(selectModelsData, (data): Option[] =>
  data.filter((m) => m.type === 'encoder').map((item): Option => ({ label: item.name, value: item.uuid }))
);

export const selectStatusesOptions = createSelector(selectStatusesData, (data): Option[] =>
  data.map((item): Option => ({ label: item.name, value: item.uuid }))
);

export const selectCyclesById = createSelector(selectCyclesData, (data) => {
  const cyclesById: Hardware.Derived.HardwareCycleGeneric = {};
  data.forEach((cycle) => (cyclesById[cycle.uuid] = cycle.name));
  return cyclesById;
});

export const selectModelsByName = createSelector(selectModelsData, (data) => {
  const modelsByName: Hardware.Derived.HardwareModelGeneric = {};
  data.forEach((model) => (modelsByName[model.name] = model));
  return modelsByName;
});
