import { ACCOUNT_ACCESS_STATUS } from '@studio/store/social-media';

export const MAX_TITLE = 100;
export const MAX_DESCRIPTION_LENGTH = 5_000;

const FB_STREAM_URL_ROOTS = ['fbcdn.net', 'facebook.com'];
const YT_STREAM_URL_ROOTS = ['youtube.com'];
export const rtmpFbYtRoots = [...FB_STREAM_URL_ROOTS, ...YT_STREAM_URL_ROOTS];

export const INVALID_STATUS = {
  NO_ACCESS: ACCOUNT_ACCESS_STATUS.NO_ACCESS,
  EXPIRED: ACCOUNT_ACCESS_STATUS.EXPIRED,
  FETCH_ERROR: 'fetchError',
} as const;

export const SOCIAL_DESTINATION_TYPES = {
  FB_EVENT: 'fb_event',
  FB_GROUP: 'fb_group',
  FB_PAGE: 'fb_page',
  FB_TIMELINE: 'fb_timeline',
  YT_CREATE_NEW: 'yt_create_new',
} as const;
