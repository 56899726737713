import { isSafari } from 'react-device-detect';
import type { CropArea } from '@studio/components';

const _createImage = (url: string): Promise<HTMLImageElement> =>
  new Promise<HTMLImageElement>((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', (error) => reject(error));
    image.src = url;
  });

const _limitSize = (
  imageSize: Pick<HTMLImageElement, 'height' | 'width'>,
  maximumPixels = 16777216
): { height: number; width: number } => {
  const { height, width } = imageSize;

  const requiredPixels = width * height;
  if (requiredPixels <= maximumPixels) return { width, height };

  const scalar = Math.sqrt(maximumPixels) / Math.sqrt(requiredPixels);
  return {
    width: Math.floor(width * scalar),
    height: Math.floor(height * scalar),
  };
};

const getCroppedImg = async (imageSrc: string, pixelCrop: CropArea): Promise<string> => {
  const image = await _createImage(imageSrc);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  if (!ctx) {
    throw new Error('Unable to get context from the canvas.');
  }

  // rare edge-case fix for safari canvas memory limit
  if (isSafari) {
    if (image.width > 4096 || image.height > 4096) {
      const safariScaledImage = _limitSize(image);
      image.width = safariScaledImage.width;
      image.height = safariScaledImage.height;
    }
  }

  // set canvas size
  canvas.width = image.width;
  canvas.height = image.height;

  // draw image and store data.
  ctx.drawImage(image, 0, 0);

  // croppedAreaPixels values are bounding box relative
  // extract the cropped image using these values
  const data = ctx.getImageData(pixelCrop.x, pixelCrop.y, pixelCrop.width, pixelCrop.height);

  // set canvas width to final desired crop size - this will clear existing context
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  // paste generated image with correct offsets for x,y crop values.
  ctx.putImageData(data, 0, 0);

  const blob = await new Promise<Blob | null>((resolve) => canvas.toBlob(resolve, 'image/jpeg'));

  return blob ? URL.createObjectURL(blob) : '';
};

export default getCroppedImg;
