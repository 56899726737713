import type { EncoderProfiles } from '@studio/types';

export enum ActionTypes {
  FETCH_ENCODER_OPTIONS = '@@encoderoptions/FETCH_ENCODER_OPTIONS',
  FETCH_ENCODER_OPTIONS_CANCEL = '@@encoderoptions/FETCH_ENCODER_OPTIONS_CANCEL',
  FETCH_ENCODER_OPTIONS_ERROR = '@@encoderoptions/FETCH_ENCODER_OPTIONS_ERROR',
  FETCH_ENCODER_OPTIONS_SUCCESS = '@@encoderoptions/FETCH_ENCODER_OPTIONS_SUCCESS',
  FETCH_ENCODER_OPTIONS_TIMESTAMP = '@@encoderoptions/FETCH_ENCODER_OPTIONS_TIMESTAMP',
  FETCH_REQUEST = '@@encoderprofiles/FETCH_REQUEST',
  FETCH_REQUEST_CANCEL = '@@encoderprofiles/FETCH_REQUEST_CANCEL',
  FETCH_REQUEST_ERROR = '@@encoderprofiles/FETCH_REQUEST_ERROR',
  FETCH_REQUEST_SUCCESS = '@@encoderprofiles/FETCH_REQUEST_SUCCESS',
  FETCH_REQUEST_TIMESTAMP = '@@encoderprofiles/FETCH_REQUEST_TIMESTAMP',
  FILTER_RESULTS = '@@encoderprofiles/FILTER_RESULTS',
  SET_PRESET = '@@encoderprofiles/SET_PRESET',
}

export interface EncoderPresetState {
  options: {
    error: Error | null;
    isFetching: boolean;
    items: EncoderProfiles.Get.Options | null;
    timestamp: number;
  };
  presets: {
    error: Error | null;
    filter: string;
    isFetching: boolean;
    items: EncoderProfiles.Get.Preset[];
    preset: EncoderProfiles.Get.Preset | null;
    timestamp: number;
  };
}
