import { HTTP_METHODS } from '@studio/constants/axios';
import { CENTRAL_API_V3 } from '@studio/constants/env-variables';
import type { Schedules } from '@studio/types';

export const root = {
  [HTTP_METHODS.POST]: {
    url: `${CENTRAL_API_V3}/customers/@customerId/schedules/validate`,
    query: {} as { contentScheduleId?: string },
    request: {} as Schedules.Post.Schedule,
    response: {} as Schedules.Post.ScheduleConflicts,
    method: HTTP_METHODS.POST,
  },
};
