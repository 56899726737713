function sortByAlphaIgnoreCase<T>(getTextProperty: (object: T) => string, isDescending?: boolean) {
  return (objectA: T, objectB: T): number => {
    const upperA = getTextProperty(objectA).toUpperCase();
    const upperB = getTextProperty(objectB).toUpperCase();

    if (isDescending) {
      if (upperB < upperA) {
        return -1;
      }
      if (upperB > upperA) {
        return 1;
      }
      return 0;
    }

    if (upperA < upperB) {
      return -1;
    }
    if (upperA > upperB) {
      return 1;
    }
    return 0;
  };
}

export default sortByAlphaIgnoreCase;
