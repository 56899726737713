import type { CombinedState } from 'redux';
import { createSelector } from 'reselect';
import type { RootState } from '../root-reducer';
import type { AuthenticationState } from './types';

export const selectAuthenticationState = (state: RootState): CombinedState<AuthenticationState> => state.authentication;

export const selectAccessToken = createSelector(selectAuthenticationState, (state) => state.accessToken);

export const selectCustomerId = createSelector(selectAuthenticationState, (state) => state.user?.customerId);

export const selectCustomerName = createSelector(selectAuthenticationState, (state) => state.user?.customerName);

export const selectCapabilities = createSelector(selectAuthenticationState, (state) => state.user?.capabilities);

export const selectUserId = createSelector(selectAuthenticationState, (state) => state.user?.userId);

export const selectUserName = createSelector(selectAuthenticationState, (state) => state.user?.userName);

export const selectUserImage = createSelector(selectAuthenticationState, (state) => state.user?.userImage);

export const selectUser = createSelector(selectAuthenticationState, (state) => state.user);

export const selectUserToggles = createSelector(selectAuthenticationState, (state) => state.user?.toggles);
