import UrlPaths from '@studio/constants/url-paths';
import type { Customer } from '@studio/types';

const getLibraryDefaultPath = (
  customer: Customer.Get.Customer | null,
  canShowWebVideos: boolean,
  canShowEncoderVideos: boolean
): string => {
  if (!customer?.vodPlanId) {
    if (canShowWebVideos) {
      return UrlPaths.MEDIA.EXPIRING_WEB_VIDEOS;
    }
    if (canShowEncoderVideos) {
      return UrlPaths.MEDIA.EXPIRING_ENCODER_VIDEOS;
    }
  }
  return UrlPaths.MEDIA.LIBRARY;
};

export default getLibraryDefaultPath;
