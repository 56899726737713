import { HTTP_METHODS } from '@studio/constants/axios';
import { CENTRAL_API_V3 } from '@studio/constants/env-variables';
import type { Customer } from '@studio/types';

export const root = {
  [HTTP_METHODS.PATCH]: {
    url: `${CENTRAL_API_V3}/customers/@id/region`,
    request: {} as Customer.Patch.Region,
    params: {} as { id: string },
    method: HTTP_METHODS.PATCH,
  },
};
