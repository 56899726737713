import { createAsyncAction } from 'typesafe-actions';
import type { FetchOptions } from '@studio/types';
import type { Timezone } from './types';
import { ActionTypes } from './types';

export const fetchTimezonesAsync = createAsyncAction(
  ActionTypes.FETCH_REQUEST,
  ActionTypes.FETCH_REQUEST_SUCCESS,
  ActionTypes.FETCH_REQUEST_ERROR,
  ActionTypes.FETCH_REQUEST_CANCEL
)<FetchOptions, Timezone[], Error>();
