import * as React from 'react';
import * as Sentry from '@sentry/react';

// exclude values that would lock us into Sentry such as their feedback dialog
type _Props = Omit<Sentry.ErrorBoundary['props'], 'dialogOptions' | 'showDialog'>;

const ErrorBoundary = (props: _Props) => {
  return <Sentry.ErrorBoundary {...props}>{props.children}</Sentry.ErrorBoundary>;
};

export default ErrorBoundary;
