import type { SagaIterator } from 'redux-saga';
import type { AllEffect, ForkEffect } from 'redux-saga/effects';
import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';
import { client } from '@studio/api/api-client/client';
import { STALE_DATA_TIMEOUT } from '@studio/constants/api-constants';
import { CENTRAL_API_V3 } from '@studio/constants/env-variables';
import type { Venues } from '@studio/types';
import type { ApiClientConfig } from '@studio/types/axios';
import { selectCustomerId, selectAccessToken } from '../authentication';
import { fetch, set } from './actions';
import { selectVenueTimestamp } from './selectors';
import { ActionTypes } from './types';

export function* fetchVenues(): SagaIterator {
  const timestamp: number = yield select(selectVenueTimestamp);
  if (Date.now() - timestamp < STALE_DATA_TIMEOUT) {
    yield put(fetch.logins.cancel());
    return;
  }

  const customerId: string = yield select(selectCustomerId);
  const url = `${CENTRAL_API_V3}/customers/${customerId}/venues`;
  const token: string = yield select(selectAccessToken);
  const config: ApiClientConfig = { token };
  try {
    const response: Venues.Get.Login[] = yield call(client, url, config);
    const filteredResponse = response.filter((e: Venues.Get.Login) => e.planName !== 'User');
    yield put(fetch.logins.success(filteredResponse));
    yield put(set.timestamp(Date.now()));
    yield put(fetch.licenses.request());
  } catch (error) {
    if (error instanceof Error) {
      yield put(fetch.logins.failure(error));
    }
  }
}

export function* watchFetchVenues<T>(): Generator<ForkEffect<never>, void, T> {
  yield takeLatest(ActionTypes.FETCH_REQUEST, fetchVenues);
}

function* fetchVenueLicenses() {
  const customerId: string = yield select(selectCustomerId);
  const url = `${CENTRAL_API_V3}/customers/${customerId}/licenses/venues`;
  const token: string = yield select(selectAccessToken);
  const config: ApiClientConfig = { token };
  try {
    const response: Venues.Get.Licenses = yield call(client, url, config);

    yield put(fetch.licenses.success(response.availableVenueLicenses));
  } catch (error) {
    if (error instanceof Error) {
      yield put(fetch.licenses.failure(error));
    }
  }
}

export function* watchFetchVenueLicense<T>(): Generator<ForkEffect<never>, void, T> {
  yield takeLatest(ActionTypes.FETCH_VENUE_LICENSES, fetchVenueLicenses);
}

export function* venueSaga<T>(): Generator<AllEffect<ForkEffect<void>>, void, T> {
  yield all([fork(watchFetchVenues), fork(watchFetchVenueLicense)]);
}
