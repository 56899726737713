import * as React from 'react';
import type { SerializedStyles, Theme } from '@emotion/react';
import { css } from '@emotion/react';
import { Progress } from '@resi-media/resi-ui';

const S = {
  container: (theme: Theme): SerializedStyles => {
    return css`
      background: ${theme.palette.background.default};
      height: 100vh;
      height: 100dvh;
    `;
  },
};

const Loading = () => (
  <div css={S.container}>
    <Progress
      customCss={{
        container: css`
          height: 100%;
        `,
      }}
      dataTestId="page-spinner"
    />
  </div>
);

Loading.displayName = 'Loading';

export default Loading;
