import { HTTP_METHODS } from '@studio/constants/axios';
import { CENTRAL_API_V2 } from '@studio/constants/env-variables';
import type { Decoders } from '@studio/types';

export const root = {
  [HTTP_METHODS.GET]: {
    url: `${CENTRAL_API_V2}/users/@id/players`,
    params: {} as { id: string },
    response: [] as Decoders.Get.Status[],
    method: HTTP_METHODS.GET,
  },
};

export const id = {};
