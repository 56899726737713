import type { O } from 'ts-toolbelt';
import type { PLAN_ENUM } from '@studio/constants/onboarding';
import type { Derived } from './derived';

export namespace Components {
  export type PlanDetails = {
    addOns: Readonly<Derived.AddOn[]>;
    monthlyId: string;
    monthlyNonProfitPrice: number;
    monthlyPrice: number;
    sort: number;
    yearlyId: string;
    yearlyNonProfitPrice: number;
    yearlyPrice: number;
  };

  export type PlanType = O.UnionOf<typeof PLAN_ENUM>;

  export type WebPlan = WebPlanTranslation & {
    addOns: Readonly<Derived.AddOn[]>;
    isRecommended: boolean;
    monthlyId: string;
    monthlyNonProfitPrice: number;
    monthlyPrice: number;
    product: Readonly<Derived.Product>;
    sort: number;
    yearlyId: string;
    yearlyNonProfitPrice: number;
    yearlyPrice: number;
  };

  export type PlanData = {
    label: string;
    value: number;
  };

  export type PlanDataIncluded = {
    data: PlanData[];
    tooltip: string;
  };

  export type FeaturesText = {
    smallText?: string;
    text: string;
    tooltip?: string;
  };

  export type WebPlanTranslation = {
    dataIncluded: PlanDataIncluded | null;
    featuresOverview: FeaturesText[];
    isFeatured: boolean;
    monthlyId: string;
    name: string;
    yearlyId: string;
  };

  export const FEATURE_ENUM = {
    ALL: 'all',
    AUTO: 'automatedScheduling',
    SOCIAL: 'facebookAndYoutube',
    SIMLIVE: 'simulatedLive',
    SUPPORT: 'emailAndPhone',
    DVR: 'dvr',
    ANALYTICS: 'analytics',
    TRANSCODING: 'transcoding',
    URLS: 'urls',
    STORAGE: 'videoStorage',
  } as const;

  export type FeatureType = (typeof FEATURE_ENUM)[keyof typeof FEATURE_ENUM];
}
