import * as React from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useTheme } from '@emotion/react';
import { FieldMessage, Inline, Link, Progress, Text } from '@resi-media/resi-ui';
import { produce } from 'immer';
import { getClosestRegion } from '@studio/api/data-center-latency';
import type { LatencyDetails } from '@studio/constants/data-center-constants';
import { usePrefix } from '@studio/hooks';
import type { Regions } from '@studio/types';

export enum DATA_TESTID {
  BEST_LOCATION_HEADER = 'best-location-header',
  LOADING_SPINNER_WRAP = 'loading-spinner-wrap',
  TIMED_OUT_HEADER = 'timed-out-header',
}

type _Props = {
  existingRegion?: LatencyDetails | null;
  onRegionFound: (bestLatency: LatencyDetails | null) => void;
  onTimeout?: () => void;
  regionIdToInclude?: string[];
  regions: Regions.Get.Region[];
};

const DataCenterLatency = ({ existingRegion = null, onRegionFound, onTimeout, regionIdToInclude, regions }: _Props) => {
  const theme = useTheme();
  const mountedRef = React.useRef(true);
  const canRetry = React.useRef(true);
  const [{ bestLatency, isTimedout, loading, retryMode }, setState] = React.useState<{
    bestLatency: LatencyDetails | null;
    isTimedout: boolean;
    loading: boolean;
    retryMode: boolean;
  }>({
    bestLatency: existingRegion,
    loading: !existingRegion,
    isTimedout: false,
    retryMode: false,
  });
  const { prefixNS, PrefixTrans } = usePrefix('components:', 'dataCenterLatency');
  const source: AbortController = React.useMemo(() => new AbortController(), []);

  const checkDataCenterLatencies = React.useCallback(async (): Promise<void> => {
    setState(
      produce((draft) => {
        draft.loading = true;
      })
    );

    const bestLatencyResponse = await getClosestRegion(regionIdToInclude ?? [], regions, source);

    if (!mountedRef.current) return;

    if (bestLatencyResponse === null) {
      setState(
        produce((draft) => {
          draft.loading = false;
          draft.bestLatency = bestLatencyResponse;
          draft.isTimedout = true;
          draft.retryMode = false;
        })
      );
      if (onTimeout) {
        onTimeout();
      }
    } else if (bestLatencyResponse.isPassing) {
      onRegionFound(bestLatencyResponse);
      setState(
        produce((draft) => {
          draft.loading = false;
          draft.bestLatency = bestLatencyResponse;
          draft.retryMode = false;
        })
      );
    }
  }, [onRegionFound, onTimeout, regionIdToInclude, regions, source]);

  const RetryOrContactText = () => {
    if (canRetry.current) {
      return (
        <PrefixTrans i18nKey="failure">
          fill
          <Link
            onClick={() =>
              setState(
                produce((draft) => {
                  draft.retryMode = true;
                })
              )
            }
            role="button"
            variant="body4"
          >
            fill
          </Link>
        </PrefixTrans>
      );
    }
    return (
      <PrefixTrans i18nKey="failureFinal">
        fill
        <Link href="https://resi.io/support/" rel="noopener noreferrer" target="_blank" variant="body4">
          fill
        </Link>
        fill
      </PrefixTrans>
    );
  };

  React.useEffect(() => {
    if (!existingRegion && !retryMode) {
      checkDataCenterLatencies();
    } else if (canRetry.current && retryMode) {
      canRetry.current = false;
      checkDataCenterLatencies();
    }
  }, [checkDataCenterLatencies, existingRegion, retryMode]);

  React.useEffect(() => {
    return () => {
      mountedRef.current = false;
      source.abort('axios request cancelled');
    };
  }, [source]);

  return (
    <>
      {loading && (
        <Inline alignItems="center" data-testid={DATA_TESTID.LOADING_SPINNER_WRAP} gap="m">
          <Text as="h3" colorVariant="primary" variant="body3">
            {prefixNS(`locating`)}
          </Text>
          <Progress sizeVariant="xs" />
        </Inline>
      )}
      {!loading && bestLatency && (
        <>
          {bestLatency.isPassing && (
            <>
              <Text
                as="h3"
                colorVariant="primary"
                data-testid={DATA_TESTID.BEST_LOCATION_HEADER}
                variant="body3"
                weightVariant="semiBold"
              >
                {bestLatency.region.name}
                {bestLatency.duration > 0 && (
                  <>
                    {' '}
                    -{' '}
                    <Text
                      as="span"
                      colorVariant="positive"
                      dataTestId="latency-duration"
                      variant="body3"
                      weightVariant="semiBold"
                    >
                      {bestLatency.duration}ms
                    </Text>
                  </>
                )}
              </Text>
              {bestLatency.duration > 0 && (
                <Inline>
                  <InfoCircleOutlined style={{ color: theme.palette.text.secondary }} />
                  <Text colorVariant="secondary" variant="body4">
                    <PrefixTrans i18nKey="descriptionPassing">
                      fill
                      <Link
                        dataTestId="try-again"
                        onClick={() => {
                          canRetry.current = true;
                          setState(
                            produce((draft) => {
                              draft.retryMode = true;
                            })
                          );
                        }}
                        role="button"
                        variant="body4"
                      >
                        fill
                      </Link>
                      fill
                      <Link
                        dataTestId="contact-us"
                        href="https://resi.io/support/"
                        rel="noopener noreferrer"
                        target="_blank"
                        variant="body4"
                      >
                        fill
                      </Link>
                    </PrefixTrans>
                  </Text>
                </Inline>
              )}
            </>
          )}
          {!bestLatency.isPassing && <FieldMessage error={RetryOrContactText()} isVisible />}
        </>
      )}
      {!loading && isTimedout && (
        <>
          <Inline alignItems="center" gap="m">
            <Text
              as="h3"
              colorVariant="negative"
              data-testid={DATA_TESTID.TIMED_OUT_HEADER}
              variant="body3"
              weightVariant="semiBold"
            >
              {prefixNS(`timeout`)}
            </Text>
          </Inline>
          <FieldMessage error={RetryOrContactText()} isVisible />
        </>
      )}
    </>
  );
};

DataCenterLatency.displayName = 'DataCenterLatency';

export default React.memo(DataCenterLatency);
