import { FILE_VALIDATION_ERRORS } from '@studio/constants/shared';

const MAX_FILE_SIZE_BYTES = 2_097_152; // 2MB

const isFileValid = (file?: File, maxFileSizeBytes = MAX_FILE_SIZE_BYTES): boolean => {
  const validFileTypes = ['image/png', 'image/jpeg'];
  if (!file) {
    console.error('A file is required for uploading.');
    throw new Error(FILE_VALIDATION_ERRORS.NO_FILE);
  }

  if (validFileTypes.indexOf(file.type) === -1) {
    console.error('File must be a JPG or PNG');
    throw new Error(FILE_VALIDATION_ERRORS.FILE_TYPE);
  }

  if (file.size > maxFileSizeBytes) {
    const maxFileSizeMb = maxFileSizeBytes / 1024 / 1024;
    console.error(`File size must not exceed ${maxFileSizeMb} MB.`);
    throw new Error(FILE_VALIDATION_ERRORS.FILE_SIZE);
  }

  return true;
};

export default isFileValid;
