import type { AxiosError, AxiosResponse } from 'axios';
import axios from 'axios';
import { axiosInstance } from '@studio/api/api-client/axios-instance';
import { resetTokenReattempt } from '@studio/api/api-client/reset-token-reattempt';
import { CENTRAL_API_V3 } from '@studio/constants/env-variables';

const setupInterceptors = (currentStore: Store) => {
  axiosInstance.interceptors.response.use(
    (resp: AxiosResponse) => {
      return resp;
    },
    (error: AxiosError) => {
      if (axios.isCancel(error)) {
        return Promise.reject(error);
      }
      if (axios.isAxiosError(error)) {
        const currentUrl = error.config.url;
        if (error.response?.status === 401 && currentUrl !== `${CENTRAL_API_V3}/auth/token`) {
          return resetTokenReattempt(error, currentStore);
        }
      }
      return Promise.reject(error);
    }
  );
};

export { setupInterceptors };
