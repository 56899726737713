export const ADD_ON = {
  SOFTWARE_ENCODER: 'software encoder',
  CONCURRENT_STREAM: 'concurrent stream',
  CHANNEL_AUDIO: 'channel audio',
  TB_DATA: 'tb data',
} as const;

export const BILLING_PERIOD = {
  MONTHLY: 'monthly',
  ANNUALLY: 'annually',
} as const;

export const ORG_CATEGORY = {
  NON_PROFIT: 'non profit',
  CORPORATE: 'corporate',
} as const;

export const PLAN_ENUM = {
  ENTERPRISE: 'enterprise',
  PRO: 'pro',
  STARTER: 'starter',
  BASIC: 'basic',
  SOCIAL: 'social',
  NONE: '',
} as const;

export const TRANSLATION_IDS = {
  BASIC_EMBED_MONTHLY: 'live-basic-embed-monthly',
  BASIC_EMBED_YEARLY: 'live-basic-embed-yearly',
  ENTERPRISE_MONTHLY: 'web-enterprise-monthly',
  ENTERPRISE_YEARLY: 'web-enterprise-yearly',
  PRO_MONTHLY: 'web-pro-monthly',
  PRO_YEARLY: 'web-pro-yearly',
  SOCIAL_ONLY_MONTHLY: 'live-social-only-monthly',
  SOCIAL_ONLY_YEARLY: 'live-social-only-yearly',
  STARTER_LITE_MONTHLY: 'live-starter-lite-monthly',
  STARTER_LITE_YEARLY: 'live-starter-lite-yearly',
} as const;
