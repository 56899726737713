import { CloudServerOutlined } from '@ant-design/icons';
import { useTheme } from '@emotion/react';
import { BrandIcon, Inline, RLogo, Stack, Text } from '@resi-media/resi-ui';
import { match } from 'ts-pattern';
import { DESTINATION } from '@studio/constants/content-destination';
import { sortDestinations } from '@studio/helpers';
import { usePrefix } from '@studio/hooks';
import type { Schedules } from '@studio/types';

const MAX_ICONS = 5;

type _Props = {
  destinationSchedules: Schedules.Get.ContentDestinationSchedule[];
};
const DestinationsTooltip = ({ destinationSchedules }: _Props) => {
  const { commonT } = usePrefix('');
  const theme = useTheme();
  const count = destinationSchedules.length;

  return (
    <Stack px="s">
      {sortDestinations(destinationSchedules)
        .filter((cd, i) => cd.type !== DESTINATION.SAVEONLY && i <= MAX_ICONS - 1)
        .map((destinationSchedule) => (
          <Stack key={destinationSchedule.id} dataTestId="destination-item">
            <Inline alignItems="center" dataTestId="icon-display" style={{ maxWidth: '254px' }}>
              {match(destinationSchedule.type)
                .with(DESTINATION.FACEBOOK, () => <BrandIcon data-testid="fb-logo" variant="fb" />)
                .with(DESTINATION.EMBED, () => (
                  <RLogo
                    color={theme.palette.brand.resiRed}
                    data-testid="resi-logo"
                    fontSize={theme.typography.pxToRem(20)}
                  />
                ))
                .with(DESTINATION.YOUTUBE, () => <BrandIcon data-testid="yt-logo" variant="yt" />)
                .with(DESTINATION.RTMP, () => (
                  <CloudServerOutlined
                    data-testid="rtmp-icon"
                    style={{
                      fontSize: theme.typography.pxToRem(22),
                      color: theme.palette.background.paper,
                    }}
                  />
                ))
                .otherwise(() => null)}
              <Text colorVariant="inverted" dataTestId="destination-title" truncate>
                {destinationSchedule.title}
              </Text>
            </Inline>
          </Stack>
        ))}
      {destinationSchedules.length > MAX_ICONS && (
        <Text colorVariant="inverted" data-testid="more-text" variant="body2">
          + {count - MAX_ICONS} {commonT('more').toLowerCase()}
        </Text>
      )}
    </Stack>
  );
};

DestinationsTooltip.displayName = 'DestinationsTooltip';

export default DestinationsTooltip;
