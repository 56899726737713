export enum AuthenticationActionTypes {
  ACCESS_TOKEN_EXPIRED = '@@authentication/ACCESS_TOKEN_EXPIRED',
  ACCESS_TOKEN_REFRESH = '@@authentication/ACCESS_TOKEN_REFRESH',
  ACCESS_TOKEN_SET = '@@authentication/ACCESS_TOKEN_SET',
  ACCESS_TOKEN_TIMESTAMP = '@@authentication/ACCESS_TOKEN_TIMESTAMP',
  APP_REFRESHING = '@@authentication/APP_REFRESHING',
  APP_RELOAD = '@@authentication/APP_RELOAD',
  AUTH_REQUEST = '@@authentication/AUTH_REQUEST',
  AUTH_REQUEST_CANCEL = '@@authentication/AUTH_REQUEST_CANCEL',
  AUTH_REQUEST_ERROR = '@@authentication/AUTH_REQUEST_ERROR',
  AUTH_REQUEST_SUCCESS = '@@authentication/AUTH_REQUEST_SUCCESS',
  FETCH_ME_REQUEST = '@@authentication/FETCH_ME_REQUEST',
  FETCH_ME_REQUEST_ERROR = '@@authentication/FETCH_ME_REQUEST_ERROR',
  FETCH_ME_REQUEST_SUCCESS = '@@authentication/FETCH_ME_REQUEST_SUCCESS',
  FETCH_TOKEN_REQUEST = '@@authentication/FETCH_TOKEN_REQUEST',
  FETCH_TOKEN_REQUEST_CANCEL = '@@authentication/FETCH_TOKEN_REQUEST_CANCEL',
  FETCH_TOKEN_REQUEST_ERROR = '@@authentication/FETCH_TOKEN_REQUEST_ERROR',
  FETCH_TOKEN_REQUEST_SUCCESS = '@@authentication/FETCH_TOKEN_REQUEST_SUCCESS',
  IS_LOGGING_IN = '@@authentication/IS_LOGGING_IN',
  LOGOUT_REQUEST = '@@authentication/LOGOUT_REQUEST',
  LOGOUT_REQUEST_CANCEL = '@@authentication/LOGOUT_REQUEST_CANCEL',
  LOGOUT_REQUEST_ERROR = '@@authentication/LOGOUT_REQUEST_ERROR',
  LOGOUT_REQUEST_SUCCESS = '@@authentication/LOGOUT_REQUEST_SUCCESS',
  SWITCHED_ORGS = '@@authentication/SWITCHED_ORGS',
}

export interface AuthenticationState {
  accessToken?: string;
  error: Error | null;
  expired: boolean;
  expiresIn: number;
  isLoggingIn: boolean;
  isLoggingOut: boolean;
  isRefreshing: boolean;
  isVenue: boolean;
  refreshTokenError: Error | null;
  user: AuthenticatedUser | null;
}

export interface AuthenticatedUser {
  actualCustomer?: string;
  capabilities: string[];
  customerId: string;
  customerName: string;
  emailVerified: boolean;
  features: string[];
  firstName?: string;
  lastName?: string;
  toggles: string[];
  userId: string;
  userImage: string;
  userName: string;
  userType: UserType;
}

export interface TokenResponse {
  access_token: string;
  expires_in: number;
}

export interface LoginResponse {
  capabilities: string[];
  customerId: string;
  customerName: string;
  features: string[];
  toggles: string[];
  token: string;
  userId: string;
  userName: string;
  userType: string;
}

export interface Credentials {
  customerId?: string;
  password: string;
  username: string;
}

export const USER_TYPE = {
  USER: 'USER',
  SITE: 'SITE',
};
export type UserType = (typeof USER_TYPE)[keyof typeof USER_TYPE];
