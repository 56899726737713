import { createAsyncAction, createAction } from 'typesafe-actions';
import type { Library, LibrarySearch } from '@studio/types';
import { ActionTypes } from './types';
import type { LibraryFetchOptions } from './types';

export const fetch = {
  media: createAsyncAction(
    ActionTypes.FETCH_REQUEST,
    ActionTypes.FETCH_REQUEST_SUCCESS,
    ActionTypes.FETCH_REQUEST_ERROR,
    ActionTypes.FETCH_REQUEST_CANCEL
  )<LibrarySearch.Get.Params, LibrarySearch.Derived.Results, Error, undefined>(),

  mediaBackground: createAction(ActionTypes.FETCH_REQUEST_BACKGROUND)<undefined>(),

  nextPage: createAsyncAction(
    ActionTypes.FETCH_MORE_REQUEST,
    ActionTypes.FETCH_MORE_REQUEST_SUCCESS,
    ActionTypes.FETCH_MORE_REQUEST_ERROR,
    ActionTypes.FETCH_MORE_REQUEST_CANCEL
  )<undefined, LibrarySearch.Derived.Results, Error, undefined>(),

  processingMedia: createAsyncAction(
    ActionTypes.FETCH_PROCESSING,
    ActionTypes.FETCH_PROCESSING_SUCCESS,
    ActionTypes.FETCH_PROCESSING_ERROR
  )<LibraryFetchOptions, LibrarySearch.Get.MediaItem[], Error>(),
};

export const set = {
  queryParams: createAction(ActionTypes.SET_QUERY_PARAMS)<LibrarySearch.Get.Params>(),
  resetSearchRequest: createAction(ActionTypes.RESET_SEARCH_REQUEST)<undefined>(),
  selectedTags: createAction(ActionTypes.SET_SELECTED_TAGS)<string[]>(),
  thumbnailFilter: createAction(ActionTypes.SET_THUMBNAIL_FILTER)<LibrarySearch.Derived.ThumbnailFilter | undefined>(),
  view: createAction(ActionTypes.SET_LIST_TYPE)<Library.Derived.MediaView>(),
};
