import { produce } from 'immer';
import type { ActionType, PayloadAction } from 'typesafe-actions';
import { createReducer } from 'typesafe-actions';
import type { Customer } from '@studio/types';
import { fetch, set } from './actions';
import type { CustomerActionTypes, CustomerNames, CustomerState, FirstTimeSetupStatus } from './types';

type Action = ActionType<typeof fetch | typeof set>;

export const initialCustomerState: CustomerState = {
  customer: {
    data: null,
    error: null,
    ftsStatus: null,
    isFetching: false,
    isFetchingFts: false,
    isFetchingToggles: false,
    isFetchingTogglesRegistered: false,
    timestamp: 0,
    toggles: null,
    togglesRegistered: null,
  },
  customerNames: {
    data: null,
    error: null,
    isFetching: false,
  },
};

const reducer = createReducer<CustomerState, Action>(initialCustomerState)
  .handleAction(
    fetch.customer.request,
    produce((draft: CustomerState) => {
      draft.customer.isFetching = true;
      draft.customer.error = null;
    })
  )
  .handleAction(
    fetch.customer.success,
    produce(
      (
        draft: CustomerState,
        action: PayloadAction<CustomerActionTypes.FETCH_REQUEST_SUCCESS, Customer.Get.Customer>
      ) => {
        draft.customer.isFetching = false;
        draft.customer.data = action.payload;
      }
    )
  )
  .handleAction(
    fetch.customer.failure,
    produce((draft: CustomerState, action: PayloadAction<CustomerActionTypes.FETCH_REQUEST_ERROR, Error>) => {
      draft.customer.isFetching = false;
      draft.customer.error = action.payload;
    })
  )
  .handleAction(
    fetch.customer.cancel,
    produce((draft: CustomerState) => {
      draft.customer.isFetching = false;
    })
  )
  .handleAction(
    fetch.customerNames.request,
    produce((draft: CustomerState) => {
      draft.customerNames.isFetching = true;
      draft.customerNames.error = null;
    })
  )
  .handleAction(
    fetch.customerNames.success,
    produce((draft: CustomerState, action: PayloadAction<CustomerActionTypes.FETCH_NAMES_SUCCESS, CustomerNames[]>) => {
      draft.customerNames.isFetching = false;
      draft.customerNames.data = action.payload;
    })
  )
  .handleAction(
    fetch.customerNames.failure,
    produce((draft: CustomerState, action: PayloadAction<CustomerActionTypes.FETCH_NAMES_ERROR, Error>) => {
      draft.customerNames.isFetching = false;
      draft.customerNames.error = action.payload;
    })
  )
  .handleAction(
    fetch.customerNames.cancel,
    produce((draft: CustomerState) => {
      draft.customerNames.isFetching = false;
    })
  )
  .handleAction(
    fetch.customerRefresh.success,
    produce(
      (
        draft: CustomerState,
        action: PayloadAction<CustomerActionTypes.FETCH_REFRESH_SUCCESS, Customer.Get.Customer>
      ) => {
        draft.customer.data = action.payload;
      }
    )
  )
  .handleAction(
    [fetch.customerRefresh.cancel, fetch.customerRefresh.failure, fetch.customerRefresh.request],
    produce((_draft) => {
      return;
    })
  )
  .handleAction(
    set.timestamp,
    produce((draft: CustomerState, action: PayloadAction<CustomerActionTypes.FETCH_REQUEST_TIMESTAMP, number>) => {
      draft.customer.timestamp = action.payload;
    })
  )
  .handleAction(
    set.trialCancelled,
    produce((draft: CustomerState) => {
      if (draft.customer.data) {
        draft.customer.data.subscriptionDetails.contentLibraryTrialCancelled = true;
      }
    })
  )
  .handleAction(
    fetch.fts.request,
    produce((draft: CustomerState) => {
      draft.customer.isFetchingFts = true;
      draft.customer.error = null;
    })
  )
  .handleAction(
    fetch.fts.success,
    produce(
      (
        draft: CustomerState,
        action: PayloadAction<CustomerActionTypes.FETCH_REQUEST_SUCCESS_FTS, FirstTimeSetupStatus>
      ) => {
        draft.customer.isFetchingFts = false;
        draft.customer.ftsStatus = action.payload;
      }
    )
  )
  .handleAction(
    fetch.fts.failure,
    produce((draft: CustomerState, action: PayloadAction<CustomerActionTypes.FETCH_REQUEST_ERROR_FTS, Error>) => {
      draft.customer.isFetchingFts = false;
      draft.customer.error = action.payload;
    })
  )
  .handleAction(
    fetch.fts.cancel,
    produce((draft: CustomerState) => {
      draft.customer.isFetchingFts = false;
    })
  )
  .handleAction(
    fetch.toggles.request,
    produce((draft: CustomerState) => {
      draft.customer.isFetchingToggles = true;
      draft.customer.error = null;
    })
  )
  .handleAction(
    fetch.toggles.success,
    produce(
      (
        draft: CustomerState,
        action: PayloadAction<CustomerActionTypes.FETCH_REQUEST_TOGGLES_SUCCESS, Customer.Get.Toggle[]>
      ) => {
        draft.customer.isFetchingToggles = false;
        draft.customer.toggles = action.payload;
      }
    )
  )
  .handleAction(
    fetch.toggles.failure,
    produce((draft: CustomerState, action: PayloadAction<CustomerActionTypes.FETCH_REQUEST_TOGGLES_ERROR, Error>) => {
      draft.customer.isFetchingToggles = false;
      draft.customer.error = action.payload;
    })
  )
  .handleAction(
    fetch.toggles.cancel,
    produce((draft: CustomerState) => {
      draft.customer.isFetchingToggles = false;
    })
  )
  .handleAction(
    fetch.togglesRegistered.request,
    produce((draft: CustomerState) => {
      draft.customer.isFetchingTogglesRegistered = true;
      draft.customer.error = null;
    })
  )
  .handleAction(
    fetch.togglesRegistered.success,
    produce(
      (
        draft: CustomerState,
        action: PayloadAction<
          CustomerActionTypes.FETCH_REQUEST_TOGGLES_REGISTERED_SUCCESS,
          Customer.Get.TogglesRegistered
        >
      ) => {
        draft.customer.isFetchingTogglesRegistered = false;
        draft.customer.togglesRegistered = action.payload;
      }
    )
  )
  .handleAction(
    fetch.togglesRegistered.failure,
    produce(
      (
        draft: CustomerState,
        action: PayloadAction<CustomerActionTypes.FETCH_REQUEST_TOGGLES_REGISTERED_ERROR, Error>
      ) => {
        draft.customer.isFetchingTogglesRegistered = false;
        draft.customer.error = action.payload;
      }
    )
  )
  .handleAction(
    fetch.togglesRegistered.cancel,
    produce((draft: CustomerState) => {
      draft.customer.isFetchingTogglesRegistered = false;
    })
  );
export default reducer;
