import { produce } from 'immer';
import type { ActionType, PayloadAction } from 'typesafe-actions';
import { createReducer } from 'typesafe-actions';
import { fetchEncoderVideosAsync, setSortOrder, deleteEncoderVideo } from './actions';
import type { ActionTypes, EncoderVideo, EncoderVideoState } from './types';
import { SortOptions } from './types';

type Action = ActionType<typeof deleteEncoderVideo | typeof fetchEncoderVideosAsync | typeof setSortOrder>;

export const initialState: EncoderVideoState = {
  isFetching: false,
  isFetchingInitial: false,
  items: [],
  error: null,
  sortBy: SortOptions.MOST_RECENT,
};

const reducer = createReducer<EncoderVideoState, Action>(initialState)
  .handleAction(
    fetchEncoderVideosAsync.request,
    produce((draft: EncoderVideoState) => {
      draft.isFetching = true;
      draft.isFetchingInitial = draft.items.length === 0;
      draft.error = null;
    })
  )
  .handleAction(
    fetchEncoderVideosAsync.success,
    produce((draft: EncoderVideoState, action: PayloadAction<ActionTypes.FETCH_REQUEST_SUCCESS, EncoderVideo[]>) => {
      draft.isFetching = false;
      draft.isFetchingInitial = false;
      draft.items = action.payload;
    })
  )
  .handleAction(
    fetchEncoderVideosAsync.failure,
    produce((draft: EncoderVideoState, action: PayloadAction<ActionTypes.FETCH_REQUEST_ERROR, Error>) => {
      draft.isFetching = false;
      draft.isFetchingInitial = false;
      draft.error = action.payload;
    })
  )
  .handleAction(
    fetchEncoderVideosAsync.cancel,
    produce((draft: EncoderVideoState) => {
      draft.isFetchingInitial = false;
      draft.isFetching = false;
    })
  )
  .handleAction(
    deleteEncoderVideo,
    produce((draft: EncoderVideoState, action: PayloadAction<ActionTypes.DELETE_ITEM, string>) => {
      if (action.payload && draft.items.length > 0) {
        const index = draft.items.findIndex((item) => item.uuid === action.payload);
        if (index !== -1) {
          const updatedItems = [...draft.items];
          updatedItems.splice(index, 1);
          draft.items = updatedItems;
        }
      }
    })
  )
  .handleAction(
    setSortOrder,
    produce((draft: EncoderVideoState, action: PayloadAction<ActionTypes.SORT_RESULTS, string>) => {
      draft.sortBy = action.payload;
    })
  );

export default reducer;
