import type { Theme } from '@emotion/react';
import { css } from '@emotion/react';
import type { StyledPanelProps } from './styles';
import { StyledPanel } from './styles';

const Panel = ({ children, maxWidth }: StyledPanelProps) => {
  const scale = window.innerWidth < 1024 ? 'm' : 'xl';
  return (
    <StyledPanel maxWidth={maxWidth}>
      <div
        css={(theme: Theme) => css`
          ${theme.mixins.inset(scale)(theme)}
        `}
      >
        {children}
      </div>
    </StyledPanel>
  );
};

Panel.displayName = 'Panel';

export default Panel;
