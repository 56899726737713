import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectPlaylistsState, ActionTypes as PlaylistActions } from '@studio/store/playlists';
import { ActionTypes as RtmpActions, selectFilteredItems as rtmpItems } from '@studio/store/rtmp-destinations';
import type { SocialMediaAccountOptions } from '@studio/store/social-media';
import { selectOptions as socialMediaSelectOptions, ActionTypes as SocialActions } from '@studio/store/social-media';
import { ActionTypes as WebChannelActions, selectPublicChannels } from '@studio/store/web-channels';
import { fetch as fetchWebPresets, selectItems as selectedWebPresets } from '@studio/store/web-presets';
import { usePrefix } from '../use-prefix';

type Options = Map<string, SocialMediaAccountOptions>;

const useDestinations = () => {
  const dispatch = useDispatch();
  const { commonT } = usePrefix('');
  const { listInfo, sortBy } = useSelector(selectPlaylistsState);
  const rtmpOptions = useSelector(rtmpItems).map(({ id, name }) => ({
    label: name,
    value: id,
  }));
  const socialMediaOptions = useSelector(socialMediaSelectOptions) as Record<'fb' | 'yt', Options>;
  const webChannelOptions = useSelector(selectPublicChannels).map(({ name, regionId, uuid }) => ({
    label: name,
    value: uuid,
    regionId,
  }));
  const webPresets = useSelector(selectedWebPresets);
  const webPresetOptions = [
    { label: commonT('default'), value: '' },
    ...webPresets.map(({ name, uuid }) => ({
      label: name,
      value: uuid,
    })),
  ];

  const handleDispatchGroup = React.useCallback(() => {
    dispatch({ type: PlaylistActions.FETCH_REQUEST, payload: { sort: sortBy } });
    dispatch({ type: RtmpActions.FETCH_RTMP_DESTINATIONS });
    dispatch({ type: SocialActions.FETCH_REQUEST });
    dispatch(fetchWebPresets.request());
    dispatch({ type: WebChannelActions.FETCH_REQUEST });
  }, [dispatch, sortBy]);

  return {
    dispatchGroup: handleDispatchGroup,
    webChannelOptions,
    webPresetOptions,
    rtmpOptions,
    playlists: listInfo.playlists,
    socialMediaOptions,
  };
};
export default useDestinations;
