import * as React from 'react';
import { ClockCircleOutlined } from '@ant-design/icons';
import { useTheme } from '@emotion/react';
import { Draft, Inline, Text } from '@resi-media/resi-ui';
import { DAY_TO_MILLIS } from '@studio/constants/content-destination';
import { STREAM_TYPE } from '@studio/constants/schedules';
import { createDateTime } from '@studio/helpers';
import { usePrefix } from '@studio/hooks';
import type { DestinationGroups, Schedules } from '@studio/types';

const BroadcastTime = ({
  broadcastFields,
  member,
}: {
  broadcastFields?: DestinationGroups.Derived.BroadcastFields;
  member: Schedules.Derived.PartialContentDestinationSchedule;
}) => {
  const { commonT, prefixNS } = usePrefix('pages:', 'events.contentDestinations');
  const { addDays, addSeconds, format, formatByString } = Draft.useUtils<Date>();
  const theme = useTheme();

  const timeDisplay = React.useMemo(() => {
    if (!broadcastFields) return undefined;
    const { eventDurationSeconds, eventStartDateTime, shouldAdjustForTimezone, startTime, streamType } =
      broadcastFields;

    const secondsToAdd = (member.startTimeOffsetMillis || 0) / 1_000;
    const destinationStartDateTime = addSeconds(
      shouldAdjustForTimezone ? eventStartDateTime : createDateTime(eventStartDateTime, startTime),
      secondsToAdd
    );
    const fullTime = format(destinationStartDateTime, 'fullTime');
    const weekdayMonthYear = formatByString(destinationStartDateTime, 'EEEE, LLL d, yyyy');
    const destinationStartTime = `${weekdayMonthYear} ${fullTime}`;
    const durationSeconds = streamType === STREAM_TYPE.LIVE ? member.durationSeconds : eventDurationSeconds;

    const destinationEndTime = durationSeconds
      ? format(addSeconds(destinationStartDateTime, durationSeconds), 'fullTime')
      : undefined;
    const leadTimeDays = member.leadTimeMillis ? member.leadTimeMillis / DAY_TO_MILLIS : undefined;

    return {
      broadcast: `${destinationStartTime}${(streamType === STREAM_TYPE.LIVE || streamType === STREAM_TYPE.EXISTING_MEDIA) && durationSeconds ? ` - ${destinationEndTime}` : ''}`,
      leadTimeDays,
      leadTime: leadTimeDays
        ? `(${formatByString(
            addDays(destinationStartDateTime, -leadTimeDays),
            'EEEE, LLL d, yyyy'
          )} ${format(addDays(destinationStartDateTime, -leadTimeDays), 'fullTime')})`
        : undefined,
    };
  }, [
    addDays,
    addSeconds,
    broadcastFields,
    format,
    formatByString,
    member.durationSeconds,
    member.leadTimeMillis,
    member.startTimeOffsetMillis,
  ]);

  return (
    <React.Fragment>
      {timeDisplay?.broadcast && (
        <Draft.FormField fieldLabel={commonT('broadcastTime')}>
          <Text dataTestId="broadcast-time" variant="body1">
            {timeDisplay.broadcast}
          </Text>
        </Draft.FormField>
      )}
      {timeDisplay?.leadTime && timeDisplay.leadTimeDays && (
        <Inline alignItems="center" dataTestId="lead-time" gap="xs">
          <ClockCircleOutlined style={{ color: theme.palette.text.secondary }} />
          <Text colorVariant="secondary" isInline variant="body3">
            {prefixNS('daysBefore', { count: timeDisplay.leadTimeDays })}
          </Text>
          <Text colorVariant="secondary" dataTestId="scheduled-post-date" isInline variant="body3">
            {timeDisplay.leadTime}
          </Text>
        </Inline>
      )}
    </React.Fragment>
  );
};

BroadcastTime.displayName = 'BroadcastTime';

export default BroadcastTime;
