import type { DestinationGroups } from '@studio/types';

export enum ActionTypes {
  FETCH_DESTINATION_REQUEST = '@@destinationgroups/FETCH_DESTINATION_REQUEST',
  FETCH_DESTINATION_REQUEST_CANCEL = '@@destinationgroups/FETCH_DESTINATION_REQUEST_CANCEL',
  FETCH_DESTINATION_REQUEST_ERROR = '@@destinationgroups/FETCH_DESTINATION_REQUEST_ERROR',
  FETCH_DESTINATION_REQUEST_SUCCESS = '@@destinationgroups/FETCH_DESTINATION_REQUEST_SUCCESS',
  FETCH_REQUEST = '@@destinationgroups/FETCH_REQUEST',
  FETCH_REQUEST_CANCEL = '@@destinationgroups/FETCH_REQUEST_CANCEL',
  FETCH_REQUEST_ERROR = '@@destinationgroups/FETCH_REQUEST_ERROR',
  FETCH_REQUEST_SUCCESS = '@@destinationgroups/FETCH_REQUEST_SUCCESS',
  FETCH_REQUEST_TIMESTAMP = '@@destinationgroups/FETCH_REQUEST_TIMESTAMP',
  FILTER_RESULTS = '@@destinationgroups/FILTER_RESULTS',
}

export type DestinationGroupsState = {
  error: Error | null;
  filter: string;
  isFetching: boolean;
  items: DestinationGroups.Get.DestinationGroup[];
  timestamp: number;
};
