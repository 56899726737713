import { createAsyncAction } from 'typesafe-actions';
import type { Regions } from '@studio/types';
import { ActionTypes } from './types';

export const fetch = {
  regions: createAsyncAction(
    ActionTypes.FETCH_REQUEST,
    ActionTypes.FETCH_REQUEST_SUCCESS,
    ActionTypes.FETCH_REQUEST_ERROR,
    ActionTypes.FETCH_REQUEST_CANCEL
  )<undefined, Regions.Get.Region[], Error, undefined>(),
};
