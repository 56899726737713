import { fileUpload } from './file-upload';
import * as idEndpoints from './id';
import { id } from './social-media';

export const socialMedia = {
  fileUpload,
  id: {
    ...id,
    ...idEndpoints,
  },
};
