import type { AxiosResponse } from 'axios';
import { HTTP_METHODS } from '@studio/constants/axios';
import { CENTRAL_API_V3 } from '@studio/constants/env-variables';
import type { Schedules } from '@studio/types';

export const root = {
  [HTTP_METHODS.POST]: {
    method: HTTP_METHODS.POST,
    request: {} as Schedules.Post.Schedule,
    response: {} as AxiosResponse,
    query: {} as {
      exDate: string;
    },
    url: `${CENTRAL_API_V3}/customers/@customerId/schedules/@contentScheduleId/exdate`,
  },
};
