import * as React from 'react';
import { LinearGradient } from '../LinearGradient';

type _Props = {
  clipWidth?: string;
  dataTestId?: string;
  height: string;
  path: number;
  svgWidth?: string;
  width?: string;
};

const LoadingBar = ({ clipWidth = '100%', dataTestId, height, path, svgWidth = '100%', width = '100%' }: _Props) => {
  return (
    <svg style={{ height: height, width: svgWidth }}>
      <LinearGradient data-testid={dataTestId} id="loading-bar" />
      <defs>
        <clipPath id={`clip-path-${path}`}>
          <rect height="100%" width={clipWidth} />
        </clipPath>
      </defs>
      <rect clipPath={`url(#clip-path-${path}`} fill="url(#loading-bar)" height="100%" width={width} x={0} y={0} />
    </svg>
  );
};

LoadingBar.displayName = 'LoadingBar';

export default LoadingBar;
