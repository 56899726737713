import type { SerializedStyles, Theme } from '@emotion/react';
import { css } from '@emotion/react';
import { SIDE_MENU_WIDTH_DESKTOP } from '@studio/constants/dashboard-style-constants';

export const S = {
  container: (theme: Theme, showMobile: boolean, showTablet: boolean): SerializedStyles => css`
    width: 100vw;
    ${theme.mixins.insetSquash('m')(theme)};
    background-color: ${theme.palette.background.paper};
    margin-top: 0.125em;
    height: 100%;
    box-sizing: border-box;
    display: ${showMobile ? 'block' : 'none'};
    position: absolute;
    z-index: 1;
    overflow-y: auto;
    border-right: 1px solid ${theme.palette.border};

    ${theme.mq.md} {
      width: ${SIDE_MENU_WIDTH_DESKTOP}px;
      display: block;
      position: absolute;
      left: ${showTablet ? 0 : `-${SIDE_MENU_WIDTH_DESKTOP}px`};
      box-shadow: ${theme.shadows[1]};
      transition: ${theme.transitions.create('all')};
    }

    ${theme.mq.xl} {
      width: ${SIDE_MENU_WIDTH_DESKTOP}px;
      position: static;
      display: block;
      box-shadow: none;
    }
  `,

  menuIcon: (theme: Theme, isActive = true): SerializedStyles => css`
    font-size: ${theme.typography.pxToRem(16)};
    color: ${isActive ? theme.palette.primary.main : theme.palette.text.secondary};
  `,
};
