import { HTTP_METHODS } from '@studio/constants/axios';
import { CENTRAL_API_V3 } from '@studio/constants/env-variables';

export const root = {
  [HTTP_METHODS.POST]: {
    url: `${CENTRAL_API_V3}/customers/@customerId/webeventprofiles/@id/clear`,
    params: {} as { id: string },
    method: HTTP_METHODS.POST,
  },
};

export const id = {};
