import * as idEndpoints from './id';
import { root, id } from './schedules';
import { validate } from './validate';

export const schedules = {
  ...root,
  id: {
    ...id,
    ...idEndpoints,
  },
  validate,
};
