import { HTTP_METHODS } from '@studio/constants/axios';
import { CENTRAL_API_V3 } from '@studio/constants/env-variables';
import type { Client } from '@studio/types';

export const root = {
  [HTTP_METHODS.POST]: {
    url: `${CENTRAL_API_V3}/customers/@customerId/clients/@id/regenerateSecret`,
    params: {} as { id: string },
    response: {} as Client.Post.RegenerateSecretResponse,
    method: HTTP_METHODS.POST,
  },
};
