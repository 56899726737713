import { Stack } from '@resi-media/resi-ui';
import { match } from 'ts-pattern';
import { FacebookSubcomponent } from '@studio/components/FacebookSubcomponent';
import { RtmpSubcomponent } from '@studio/components/RtmpSubcomponent';
import { WebSubcomponent } from '@studio/components/WebSubcomponent';
import { YoutubeSubcomponent } from '@studio/components/YoutubeSubcomponent';
import { DESTINATION } from '@studio/constants/content-destination';
import type { Crosspost, DestinationDetails, DestinationGroups, Playlists, Schedules } from '@studio/types';

const DestinationsTableSubcomponent = ({
  broadcastFields,
  cachedCrossposts,
  cachedFacebookDestinations,
  crosspostErrors,
  facebookDestinationErrors,
  member,
  playlistDetails,
  refetchFacebookDetails,
}: {
  broadcastFields?: DestinationGroups.Derived.BroadcastFields;
  cachedCrossposts?: Map<string, Crosspost[]>;
  cachedFacebookDestinations?: Map<string, DestinationDetails[]>;
  crosspostErrors?: Map<string, unknown>;
  facebookDestinationErrors?: Map<string, unknown>;
  member: Schedules.Derived.PartialContentDestinationSchedule;
  playlistDetails?: Playlists.Get.Playlist[];
  refetchFacebookDetails?: () => void;
}) => {
  return (
    <Stack bgColor="background.table" p="l">
      {match(member.type)
        .with(DESTINATION.EMBED, () => (
          <WebSubcomponent broadcastFields={broadcastFields} member={member} playlistDetails={playlistDetails} />
        ))
        .with(DESTINATION.FACEBOOK, () => (
          <FacebookSubcomponent
            broadcastFields={broadcastFields}
            cachedCrossposts={cachedCrossposts}
            cachedFacebookDestinations={cachedFacebookDestinations}
            crosspostErrors={crosspostErrors}
            destinationErrors={facebookDestinationErrors}
            member={member}
            refetchFacebookDetails={refetchFacebookDetails}
          />
        ))
        .with(DESTINATION.YOUTUBE, () => <YoutubeSubcomponent broadcastFields={broadcastFields} member={member} />)
        .with(DESTINATION.RTMP, () => <RtmpSubcomponent broadcastFields={broadcastFields} member={member} />)
        .otherwise(() => null)}
    </Stack>
  );
};

DestinationsTableSubcomponent.displayName = 'DestinationsTableSubcomponent';

export default DestinationsTableSubcomponent;
