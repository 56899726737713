import type { CombinedState } from 'redux';
import { createSelector } from 'reselect';
import { sortByAlpha } from '@studio/helpers/sortByAlpha';
import { sortByAlphaIgnoreCase } from '@studio/helpers/sortByAlphaIgnoreCase';
import type { RootState } from '../root-reducer';
import type { EncoderVideo, EncoderVideoState } from './types';
import { SortOptions } from './types';

export const selectEncoderVideoState = (state: RootState): CombinedState<EncoderVideoState> => state.encoderVideos;

export const selectItems = createSelector(selectEncoderVideoState, (state): EncoderVideo[] => state.items);

export const selectSortBy = createSelector(selectEncoderVideoState, (state): string => state.sortBy);

export const sortItems = createSelector(selectItems, selectSortBy, (items, sortBy): EncoderVideo[] => {
  if (sortBy === SortOptions.NAME_AtoZ) {
    return items.slice().sort(sortByAlphaIgnoreCase((video: EncoderVideo) => video.name));
  } else if (sortBy === SortOptions.NAME_ZtoA) {
    return items.slice().sort(sortByAlphaIgnoreCase((video: EncoderVideo) => video.name, true));
  } else if (sortBy === SortOptions.MOST_RECENT) {
    return items.slice().sort(sortByAlpha((video: EncoderVideo) => video.startTime, true));
  } else if (sortBy === SortOptions.OLDEST) {
    return items.slice().sort(sortByAlpha((video: EncoderVideo) => video.startTime));
  }

  return items;
});
