import type { SerializedStyles, Theme } from '@emotion/react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const S = {
  requirementsContainer: (theme: Theme): SerializedStyles => css`
    background: ${theme.palette.background.table};
    border: 1px solid ${theme.palette.border};
    box-sizing: border-box;
    border-radius: ${theme.shape.borderRadius.m} ${theme.shape.borderRadius.m} 0 0;
    padding: ${theme.spacing.m};
  `,

  requirementsListWrapper: (theme: Theme): SerializedStyles => css`
    margin-top: ${theme.spacing.s};

    div:nth-of-type(even) {
      background-color: ${theme.palette.divider};
    }
  `,

  premiereContainer: (theme: Theme): SerializedStyles => css`
    margin: 0;
    background: ${theme.palette.background.table};
    border: 1px solid ${theme.palette.border};
    border-width: 0 1px 1px 1px;
    box-sizing: border-box;
    border-radius: 0 0 ${theme.shape.borderRadius.m} ${theme.shape.borderRadius.m};
    padding: ${theme.spacing.m};
  `,
};

export const DragAndDropZone = styled.div<{ isHovering: boolean }>`
  width: 100%;
  height: 313px;
  border: 1px dashed ${(props) => props.theme.palette.border};
  border-radius: ${(props) => props.theme.shape.borderRadius.xl};
  display: grid;
  place-items: center;
  background-color: ${({ isHovering, theme }) =>
    isHovering ? theme.palette.primary.background : theme.palette.background.paper};
`;

export const AnalysisComplete = styled.div`
  width: 100%;
  height: 313px;
  border: 1px solid ${(props) => props.theme.palette.border};
  border-radius: ${(props) => props.theme.shape.borderRadius.xl};
  display: grid;
  place-items: center;
  background-color: ${(props) => props.theme.palette.background.table};
`;

export const ErrorList = styled.ul`
  list-style-type: '- ';
  padding-left: 10px;
`;
