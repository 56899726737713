import type { AxiosResponse } from 'axios';
import { HTTP_METHODS } from '@studio/constants/axios';
import { CENTRAL_API_V2 } from '@studio/constants/env-variables';
import type { EncoderProfiles } from '@studio/types';

export const root = {
  [HTTP_METHODS.POST]: {
    url: `${CENTRAL_API_V2}/encoderprofiles`,
    request: {} as EncoderProfiles.Post.V2.Preset,
    response: {} as AxiosResponse,
    method: HTTP_METHODS.POST,
  },
};

export const id = {
  [HTTP_METHODS.GET]: {
    url: `${CENTRAL_API_V2}/encoderprofiles/@id`,
    response: {} as EncoderProfiles.Get.V2.Preset,
    params: {} as { id: string },
    method: HTTP_METHODS.GET,
  },
  [HTTP_METHODS.PATCH]: {
    url: `${CENTRAL_API_V2}/encoderprofiles/@id`,
    request: {} as EncoderProfiles.Patch.V2.Preset,
    params: {} as { id: string },
    method: HTTP_METHODS.PATCH,
  },
};
