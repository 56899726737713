import axios from 'axios';
import { CENTRAL_API_V3 } from '@studio/constants/env-variables';
import type { LoginResponse } from '@studio/store/authentication/types';

// This is only here to set the auth cookie for Control
// TODO: When we don't have to navigate back to Control, NUKE IT!
export async function login(
  username: string,
  password: string,
  customerId?: string
): Promise<LoginResponse | undefined> {
  const loginUrl = `${CENTRAL_API_V3}/login?newToken=true`;

  try {
    const response = await axios.post(
      loginUrl,
      { userName: username, password, sudoCustomer: customerId },
      { withCredentials: true }
    );
    return response.data;
  } catch (err) {
    console.error('Unable to login', err);
  }
}
