export type RtmpDestination = {
  customerId: string;
  id: string;
  name: string;
  rtmpViewUrl?: string;
  streamKey: string;
  streamUrl: string;
};

export enum ActionTypes {
  FETCH_RTMP_DESTINATIONS = '@@rtmp-destinations/FETCH_RTMP_DESTINATIONS',
  FETCH_RTMP_DESTINATIONS_ERROR = '@@rtmp-destinations/FETCH_RTMP_DESTINATIONS_ERROR',
  FETCH_RTMP_DESTINATIONS_SUCCESS = '@@rtmp-destinations/FETCH_RTMP_DESTINATIONS_SUCCESS',
  FILTER_ITEMS = '@@rtmp-destinations/FILTER_ITEMS',
}

export interface RtmpDestinationsState {
  fetchDestinationsError: Error | null;
  filter: string;
  isFetchingDestinations: boolean;
  rtmpDestinations: RtmpDestination[];
  timestamp: number;
}
