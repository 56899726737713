import type { CombinedState } from 'redux';
import { createSelector } from 'reselect';
import type { RootState } from '../root-reducer';
import type { LibraryState, SavedVideoListInfo, SortOptions } from './types';

export const selectLibraryState = (state: RootState): CombinedState<LibraryState> => state.libraryItems;

export const selectListInfo = createSelector(selectLibraryState, (state): SavedVideoListInfo | null => state.listInfo);

export const selectSortBy = createSelector(selectLibraryState, (state): SortOptions => state.sortBy);
