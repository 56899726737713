import type { AllEffect, ForkEffect } from 'redux-saga/effects';
import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';
import { client } from '@studio/api/api-client/client';
import { CENTRAL_API_V3 } from '@studio/constants/env-variables';
import { selectAccessToken, selectCustomerId } from '../authentication';
import { fetchRtmpDestinations } from './actions';
import type { RtmpDestination } from './types';
import { ActionTypes } from './types';

function* handleFetch() {
  const customerId: string = yield select(selectCustomerId);
  const url = `${CENTRAL_API_V3}/customers/${customerId}/rtmp/destination`;

  const token: string = yield select(selectAccessToken);

  try {
    const res: RtmpDestination[] = yield call(client, url, { token });
    yield put(fetchRtmpDestinations.success(res));
  } catch (e) {
    if (e instanceof Error) {
      yield put(fetchRtmpDestinations.failure(e));
    }
  }
}

export function* watchFetchRequest<T>(): Generator<ForkEffect<never>, void, T> {
  yield takeLatest(ActionTypes.FETCH_RTMP_DESTINATIONS, handleFetch);
}

export function* rtmpDestinationsSaga<T>(): Generator<AllEffect<ForkEffect<void>>, void, T> {
  yield all([fork(watchFetchRequest)]);
}
