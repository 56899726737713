import type { SerializedStyles, Theme } from '@emotion/react';
import { css } from '@emotion/react';

export const S = {
  // Initial props prevent scroll issue, waiting for Popper.js to add this style once initiated.
  popupContainer: (theme: Theme, fullWidthMobile?: boolean): SerializedStyles => css`
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: flex-end;
    background-color: ${theme.palette.background.paper};

    width: ${fullWidthMobile ? '100%' : 'auto'};
    margin: 0 !important;
    z-index: ${theme.zIndex.popover};

    ${fullWidthMobile &&
    `
      ${theme.mq.md} {
        display: flex;
        justify-content: flex-end;
        width: auto;
      }
      `}
  `,

  popup: (theme: Theme, fullWidthMobile?: boolean): SerializedStyles => css`
    width: ${fullWidthMobile ? '100%' : 'auto'};

    ${theme.mq.md} {
      width: auto;
    }
  `,
};
