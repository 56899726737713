import {
  API_ROOTS,
  CHART_TAB,
  CONTENT_LIBRARY_EXPORT_FIELDS,
  DEFAULT_CSV_EXPORT_FIELDS,
  EVENT_ANALYTICS_TYPES,
  FACEBOOK_DESTINATION_TYPES,
  LOCATION_TYPES,
  SOCIAL_MEDIA_CSV_EXPORT_FIELDS,
  WEB_DESTINATION_TYPES,
} from '@studio/constants/analytics';
import { LAUNCHDARKLY_TOGGLES } from '@studio/constants/launchdarkly-toggle-constants';
import type { Analytics } from '@studio/types';

declare global {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface Map<K, V> {
    grab<V>(this: Map<K, V>, key: K): V;
  }
  interface Navigator {
    msSaveBlob?: (blob: unknown, defaultName?: string) => boolean;
  }
}

// Method:Grab when key is known to exist unlike get method
// eslint-disable-next-line no-extend-native
Map.prototype.grab = function <K, V>(this: Map<K, V>, key: K): V {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return this.get(key)!;
};

type TabConfig = {
  breakdown: boolean;
  data: {
    api: Analytics.Derived.ApiRootTypes;
    destinationType?: Analytics.Derived.DestinationType;
    viewAllData: boolean;
  };
  defaults: {
    eventAnalytics?: Analytics.Derived.EventAnalyticsTypes;
    location?: Analytics.Derived.LocationTypes;
  };
  deviceType: boolean;
  exportConfig?: {
    /* Object keys on response that determine if additional values should be fetched */
    validationKey1: Analytics.Derived.CsvExportFieldsTypes;
    validationKey2?: Analytics.Derived.CsvExportFieldsTypes;
  };
  exportFileName?: string;
  exportKeys?: Analytics.Derived.CsvExportFieldsTypes[];
  heatMap: boolean;
  ldFlag: string;
  location: boolean;
  minByMin: boolean;
  resolution: boolean;
  segmentBy: boolean;
  statKeys: Analytics.Derived.StatsKeys[];
  urlRoot: string;
  watchTime: boolean;
};

const contentLibraryEmbedPlayerConfig: TabConfig = {
  exportConfig: {
    validationKey1: 'clientId',
    validationKey2: 'sessionId',
  },
  exportFileName: 'Library_Analytics_EmbedPlayer',
  exportKeys: Object.values(CONTENT_LIBRARY_EXPORT_FIELDS),
  heatMap: true,
  breakdown: true,
  resolution: true,
  deviceType: true,
  watchTime: true,
  ldFlag: '',
  location: true,
  minByMin: false,
  segmentBy: true,
  urlRoot: 'savedVideoId',
  statKeys: [
    'AVG_WATCH_TIME',
    'MEDIAN_TIME_WATCHED',
    'NEW_VIEWERS',
    'VIEWERS',
    'RETURN_VIEWERS',
    'TOTAL_TIME_WATCHED',
    'VIEWS',
  ],
  data: {
    api: API_ROOTS.CONTENT_LIBRARY,
    destinationType: WEB_DESTINATION_TYPES.EMBED,
    viewAllData: false,
  },
  defaults: {
    eventAnalytics: EVENT_ANALYTICS_TYPES.VIEWERS,
    location: LOCATION_TYPES.CITY,
  },
};
const contentLibraryStreamUrlConfig: TabConfig = {
  exportConfig: {
    validationKey1: 'clientId',
    validationKey2: 'sessionId',
  },
  exportFileName: 'Library_Analytics_StreamUrl',
  exportKeys: Object.values(CONTENT_LIBRARY_EXPORT_FIELDS).filter(
    (field) => !['totalTimeWatchedSeconds'].includes(field)
  ),
  heatMap: true,
  breakdown: true,
  ldFlag: '',
  resolution: true,
  deviceType: true,
  watchTime: false,
  location: true,
  minByMin: true,
  segmentBy: true,
  urlRoot: 'savedVideoId',
  statKeys: ['NEW_VIEWERS', 'VIEWERS', 'RETURN_VIEWERS'],
  data: {
    api: API_ROOTS.CONTENT_LIBRARY,
    destinationType: WEB_DESTINATION_TYPES.STREAM,
    viewAllData: false,
  },
  defaults: {
    eventAnalytics: EVENT_ANALYTICS_TYPES.VIEWERS,
    location: LOCATION_TYPES.CITY,
  },
};

export const getContentLibraryTabs = (allowStreamUrls: boolean): Map<Analytics.Derived.ChartTabTypes, TabConfig> => {
  const tabs = new Map<Analytics.Derived.ChartTabTypes, TabConfig>();
  tabs.set(CHART_TAB.EMBED_PLAYER, contentLibraryEmbedPlayerConfig);
  if (allowStreamUrls) {
    tabs.set(CHART_TAB.STREAM_URL, contentLibraryStreamUrlConfig);
  }
  return tabs;
};

export const contentLibraryTabs = new Map<Analytics.Derived.ChartTabTypes, TabConfig>([
  [CHART_TAB.EMBED_PLAYER, contentLibraryEmbedPlayerConfig],
  [CHART_TAB.STREAM_URL, contentLibraryStreamUrlConfig],
]);

const chartEmbedPlayerConfig: TabConfig = {
  exportConfig: {
    validationKey1: 'clientId',
    validationKey2: 'sessionId',
  },
  exportFileName: 'Analytics_EmbedPlayer',
  exportKeys: Object.values(DEFAULT_CSV_EXPORT_FIELDS),
  heatMap: true,
  breakdown: true,
  ldFlag: '',
  resolution: true,
  deviceType: true,
  watchTime: true,
  location: true,
  minByMin: true,
  segmentBy: true,
  urlRoot: 'eventId',
  statKeys: [
    'AVG_WATCH_TIME',
    'MEDIAN_TIME_WATCHED',
    'NEW_VIEWERS',
    'PEAK_CONCURRENT',
    'VIEWERS',
    'RETURN_VIEWERS',
    'TOTAL_TIME_WATCHED',
    'VIEWS',
  ],
  data: {
    api: API_ROOTS.WEB_EVENTS,
    destinationType: WEB_DESTINATION_TYPES.EMBED,
    viewAllData: false,
  },
  defaults: {
    eventAnalytics: EVENT_ANALYTICS_TYPES.VIEWERS,
    location: LOCATION_TYPES.CITY,
  },
};
const chartStreamUrlConfig: TabConfig = {
  exportConfig: {
    validationKey1: 'clientId',
    validationKey2: 'sessionId',
  },
  exportFileName: 'Analytics_StreamUrl',
  exportKeys: Object.values(DEFAULT_CSV_EXPORT_FIELDS).filter((field) => !['totalTimeWatchedSeconds'].includes(field)),
  heatMap: true,
  breakdown: true,
  ldFlag: '',
  resolution: true,
  deviceType: true,
  watchTime: false,
  location: true,
  minByMin: true,
  segmentBy: true,
  urlRoot: 'eventId',
  statKeys: ['NEW_VIEWERS', 'VIEWERS', 'RETURN_VIEWERS', 'TOTAL_TIME_WATCHED'],
  data: {
    api: API_ROOTS.WEB_EVENTS,
    destinationType: WEB_DESTINATION_TYPES.STREAM,
    viewAllData: false,
  },
  defaults: {
    eventAnalytics: EVENT_ANALYTICS_TYPES.VIEWERS,
    location: LOCATION_TYPES.CITY,
  },
};
const chartFacebookConfig: TabConfig = {
  exportConfig: {
    validationKey1: 'videoId',
    validationKey2: 'timestamp',
  },
  exportFileName: 'Analytics_Facebook',
  exportKeys: Object.values(SOCIAL_MEDIA_CSV_EXPORT_FIELDS),
  heatMap: false,
  breakdown: false,
  ldFlag: '',
  resolution: false,
  deviceType: false,
  watchTime: false,
  location: false,
  minByMin: false,
  segmentBy: false,
  urlRoot: 'eventId',
  statKeys: ['AVG_WATCH_TIME', 'TOTAL_TIME_WATCHED', 'VIEWERS', 'VIEWS'],
  data: {
    api: API_ROOTS.FACEBOOK,
    destinationType: FACEBOOK_DESTINATION_TYPES.PAGE,
    viewAllData: false,
  },
  defaults: {
    eventAnalytics: EVENT_ANALYTICS_TYPES.VIEWS,
  },
};
const chartYouTubeConfig: TabConfig = {
  exportKeys: [],
  exportFileName: '',
  heatMap: false,
  breakdown: false,
  ldFlag: LAUNCHDARKLY_TOGGLES.STUDIO_ANALYTICS_YOUTUBE,
  resolution: false,
  deviceType: false,
  watchTime: false,
  location: false,
  minByMin: false,
  segmentBy: false,
  urlRoot: 'eventId',
  statKeys: ['AVG_WATCH_TIME', 'TOTAL_TIME_WATCHED', 'VIEWS'],
  data: {
    api: API_ROOTS.YOUTUBE,
    destinationType: undefined,
    viewAllData: false,
  },
  defaults: {
    eventAnalytics: EVENT_ANALYTICS_TYPES.VIEWS,
  },
};

export const getChartTabs = (allowStreamUrls: boolean): Map<Analytics.Derived.ChartTabTypes, TabConfig> => {
  const tabs = new Map<Analytics.Derived.ChartTabTypes, TabConfig>();
  tabs.set(CHART_TAB.EMBED_PLAYER, chartEmbedPlayerConfig);
  if (allowStreamUrls) {
    tabs.set(CHART_TAB.STREAM_URL, chartStreamUrlConfig);
  }
  tabs.set(CHART_TAB.FACEBOOK, chartFacebookConfig);
  tabs.set(CHART_TAB.YOUTUBE, chartYouTubeConfig);
  return tabs;
};

export const chartTabs = new Map<Analytics.Derived.ChartTabTypes, TabConfig>([
  [CHART_TAB.EMBED_PLAYER, chartEmbedPlayerConfig],
  [CHART_TAB.STREAM_URL, chartStreamUrlConfig],
  [CHART_TAB.FACEBOOK, chartFacebookConfig],
  [CHART_TAB.YOUTUBE, chartYouTubeConfig],
]);

export const getContentLibraryTabIndex = (tab: Analytics.Derived.ChartTabTypes): number => {
  return [...contentLibraryTabs.keys()].indexOf(tab);
};

export const getTabIndex = (tab: Analytics.Derived.ChartTabTypes): number => {
  return [...chartTabs.keys()].indexOf(tab);
};
