export const DAY_TO_MILLIS = 86_400_000; // 1_000 ms * 60 s * 60 m * 24 h

export const DESTINATION = {
  EMBED: 'EMBED',
  FACEBOOK: 'FACEBOOK',
  RSP_SOURCE: 'RSP_SOURCE',
  RTMP: 'RTMP',
  SAVEONLY: 'SAVEONLY',
  YOUTUBE: 'YOUTUBE',
} as const;

export const DESTINATION_GROUP = 'DESTINATION_GROUP';

export const VIDEO_STATUS = {
  IDLE: 'IDLE',
  SET_UP: 'SET_UP',
  STARTING: 'STARTING',
  STARTED: 'STARTED',
  STOPPING: 'STOPPING',
  STOPPED: 'STOPPED',
  ABORTED: 'ABORTED',
  ERROR: 'ERROR',
} as const;

export const VIDEO_EVENT_TYPE = {
  ...DESTINATION,
  ENCODER: 'ENCODER',
} as const;
