import * as React from 'react';
import { css, useTheme } from '@emotion/react';
import {
  Box,
  Checkbox,
  Draft,
  ErrorAlt,
  Inline,
  ListItem,
  Progress,
  Stack,
  Text,
  useBreakpoint,
} from '@resi-media/resi-ui';
import { useDispatch, useSelector } from 'react-redux';
import { match } from 'ts-pattern';
import { PLAYLIST_MAX_VIDEOS_ALLOWED } from '@studio/constants/library';
import { usePrefix } from '@studio/hooks';
import DefaultThumbnail from '@studio/images/default-thumbnail.jpg';
import { playlists as playlistsStore } from '@studio/store';
import { selectPlaylistsState } from '@studio/store/playlists';
import { InfiniteScrollPaged } from '../InfiniteScrollPaged';

type _Props = {
  includeBorder?: boolean;
  onChange: (playlistIds: string[]) => void;
  playlistIds: string[];
  scrollableTarget: string;
};

const PlaylistSelection = ({ includeBorder, onChange, playlistIds, scrollableTarget }: _Props): React.JSX.Element => {
  const { commonT, prefixNS } = usePrefix('pages:', 'library.saveMedia');
  const theme = useTheme();
  const dispatch = useDispatch();
  const mediaQuery = useBreakpoint();

  const { error: playlistsError, isFetching, listInfo: playlistsInfo, sortBy } = useSelector(selectPlaylistsState);

  const sortOptions = React.useMemo(
    () => [
      { label: commonT('sort.mostRecent'), value: playlistsStore.SortOptions.MOST_RECENT },
      { label: commonT('sort.oldest'), value: playlistsStore.SortOptions.OLDEST },
      { label: commonT('sort.alpha'), value: playlistsStore.SortOptions.NAME_AtoZ },
      { label: commonT('sort.alphaReverse'), value: playlistsStore.SortOptions.NAME_ZtoA },
    ],
    [commonT]
  );

  React.useEffect(() => {
    dispatch({ type: playlistsStore.ActionTypes.FETCH_REQUEST, payload: { sort: sortBy } });
  }, [dispatch, sortBy]);

  return (
    <Box dataTestId="playlist-selection-container">
      <Inline
        alignItems="center"
        css={(theme) =>
          css({
            ...(!includeBorder && {
              borderBottom: `1px solid ${theme.palette.divider}`,
            }),
          })
        }
        justifyContent="space-between"
        px="l"
        py="m"
      >
        <Text weightVariant="semiBold">
          {commonT('selectedPlaylist', {
            count: playlistIds.length,
          })}
        </Text>
        <Draft.Select
          customCss={{
            container: css`
              width: 160px;
            `,
          }}
          dataTestId="sort-select"
          onChange={(option) => {
            if (option) {
              dispatch({
                type: playlistsStore.ActionTypes.FETCH_REQUEST_TIMESTAMP,
                payload: 0,
              });
              dispatch({
                type: playlistsStore.ActionTypes.SORT_RESULTS,
                payload: option.value,
              });
            }
          }}
          options={sortOptions}
          value={sortOptions.find((v) => v.value === sortBy)}
        />
      </Inline>
      {match({
        isFetching,
        hasNoPlaylists: !isFetching && !playlistsInfo.playlists.length,
      })
        .with({ isFetching: true }, () => (
          <Box display="flex" justifyContent="center" style={{ height: '445px' }}>
            <Progress dataTestId="playlist-selection__progress" />
          </Box>
        ))
        .with({ hasNoPlaylists: true }, () => (
          <Box mt="xl">
            <Text dataTestId="playlist-selection__empty" textAlign="center">
              {commonT('noPlaylistsFound')}
            </Text>
          </Box>
        ))
        .otherwise(() => (
          <InfiniteScrollPaged
            error={playlistsError}
            getMore={() => {
              dispatch({ type: playlistsStore.ActionTypes.FETCH_MORE_REQUEST });
            }}
            hasMore={() => Boolean(playlistsInfo.nextUrl)}
            items={playlistsInfo.playlists}
            renderList={(renderedPlaylists) => {
              return (
                <Stack
                  css={(theme) =>
                    css({
                      ...(includeBorder && {
                        border: `1px solid ${theme.palette.border}`,
                      }),
                    })
                  }
                  dataTestId="playlists-list"
                  gap="1px"
                >
                  {renderedPlaylists.map((playlist) => {
                    const thumbnailUrl = playlist.thumbnail
                      ? playlist.thumbnail.representations[0].publicUrl
                      : DefaultThumbnail;
                    const isSelected = playlistIds.some((listId) => listId === playlist.id);
                    return (
                      <ListItem
                        key={playlist.id}
                        css={() => css`
                          height: auto;
                          > span {
                            width: 100%;
                          }
                        `}
                        isSelected={isSelected}
                      >
                        <Checkbox
                          checked={isSelected}
                          customCss={{
                            container: css`
                              height: 100% !important;
                              overflow: hidden;
                            `,
                          }}
                          dataTestId="playlist-checkbox"
                          endNode={
                            <Inline alignItems="center" overflow="hidden" w="scale">
                              <img
                                alt={playlist.name}
                                css={css`
                                  max-height: 85px;
                                  width: ${mediaQuery.md ? '126px' : '75px'};
                                  border-radius: ${theme.shape.borderRadius.s};
                                  object-fit: contain;
                                `}
                                data-testid="thumbnail"
                                loading="lazy"
                                src={thumbnailUrl}
                              />
                              <Inline
                                alignItems="center"
                                dataTestId="playlist-info-container"
                                flexWrap={mediaQuery.md ? 'nowrap' : 'wrap'}
                                gap={{ md: 'm' }}
                                justifyContent="space-between"
                                overflow="hidden"
                                w="scale"
                              >
                                <Text
                                  css={css`
                                    flex: 1 1 100%;
                                  `}
                                  truncate
                                  weightVariant="semiBold"
                                >
                                  {playlist.name}
                                </Text>
                                <Inline alignItems="center" ml={{ md: 'xs' }}>
                                  {playlist.size >= PLAYLIST_MAX_VIDEOS_ALLOWED && (
                                    <Draft.Tooltip content={prefixNS('playlistFull')}>
                                      <ErrorAlt
                                        data-testid="max-playlist-size-icon"
                                        style={{
                                          color: theme.palette.negative.main,
                                          fontSize: theme.typography.pxToRem(18),
                                        }}
                                      />
                                    </Draft.Tooltip>
                                  )}
                                  <Text
                                    colorVariant={playlist.size >= PLAYLIST_MAX_VIDEOS_ALLOWED ? 'negative' : 'primary'}
                                  >
                                    {prefixNS('videoCount', {
                                      count: playlist.size,
                                    }).toLowerCase()}
                                  </Text>
                                </Inline>
                              </Inline>
                            </Inline>
                          }
                          inlineProps={{ width: 'scale' }}
                          onChange={() => {
                            onChange(
                              isSelected
                                ? playlistIds.filter((listId) => listId !== playlist.id)
                                : [...playlistIds, playlist.id]
                            );
                          }}
                          value={playlist.id}
                        />
                      </ListItem>
                    );
                  })}
                </Stack>
              );
            }}
            scrollableTarget={scrollableTarget}
          />
        ))}
    </Box>
  );
};

PlaylistSelection.displayName = 'PlaylistSelection';

export default PlaylistSelection;
