import { HTTP_METHODS } from '@studio/constants/axios';
import { CENTRAL_API_V3 } from '@studio/constants/env-variables';
import type { SoftwareEncoders } from '@studio/types';

export const root = {};

export const id = {
  [HTTP_METHODS.GET]: {
    method: HTTP_METHODS.GET,
    url: `${CENTRAL_API_V3}/customers/@customerId/uploaders/registration/details/@id`,
    params: {} as { id: string },
    response: {} as SoftwareEncoders.Get.Uploader,
  },
};
