import type { TypedUseSelectorHook } from 'react-redux';
import { useSelector } from 'react-redux';
import type { AnyAction } from 'redux';
import { combineReducers } from 'redux';
import type { Reducer } from 'typesafe-actions';
import { default as admin, initialState as initialAdminState } from './admin/reducer';
import { default as analytics, initialState as initialAnalyticsState } from './analytics/reducer';
import { default as authentication, initialState as initialAuthenticationTokensState } from './authentication/reducer';
import { AuthenticationActionTypes } from './authentication/types';
import {
  default as contentDestinations,
  initialState as initialContentDestinationsState,
} from './content-destinations/reducer';
import { default as contentSchedule, initialState as initialContentScheduleState } from './content-schedule/reducer';
import { default as customer, initialCustomerState } from './customer/reducer';
import {
  default as destinationGroups,
  initialState as initialDestinationGroupsState,
} from './destination-groups/reducer';
import { default as encoderChannels, initialState as initialEncoderChannelsState } from './encoder-channels/reducer';
import { initialState as initialEncoderLogsState, default as encoderLogs } from './encoder-logs/reducer';
import { default as encoderPresets, initialState as initialEncoderPresetsState } from './encoder-presets/reducer';
import { default as encoderVideos, initialState as initialEncoderVideosState } from './encoder-videos/reducer';
import { default as encoders, initialState as initialEncodersState } from './encoders/reducer';
import { default as libraryItems, initialState as initialLibraryItemsState } from './library/reducer';
import { default as librarySearch, getInitialState as initialLibrarySearchState } from './library-search/reducer';
import { default as monitors, initialState as initialCustomerMonitorsState } from './monitors/reducer';
import { initialPlayerProfileState, default as playerProfiles } from './player-profiles/reducer';
import { default as playlists, initialState as initialPlaylistsState } from './playlists/reducer';
import { default as regions, initialState as initialRegionsState } from './regions/reducer';
import { default as rtmpDestinations, initialState as initialRtmpDestinationsState } from './rtmp-destinations/reducer';
import { initialSiteState, default as sites } from './sites/reducer';
import {
  default as socialMediaAccounts,
  initialState as initialSocialMediaAccountsState,
} from './social-media/reducer';
import { default as systemEvents, initialState as initialSystemEventsState } from './system-events/reducer';
import { initialState as initialTimezonesState, default as timezones } from './timezones/reducer';
import { default as users, initialState as initialUsersState } from './users/reducer';
import { initialVenueState, default as venues } from './venues/reducer';
import { default as webChannels, initialState as initialWebChannelsState } from './web-channels/reducer';
import { default as webPresets, initialState as initialWebPresetsState } from './web-presets/reducer';

const appReducer = combineReducers({
  admin,
  analytics,
  authentication,
  contentDestinations,
  contentSchedule,
  customer,
  destinationGroups,
  encoders,
  encoderChannels,
  encoderLogs,
  encoderPresets,
  encoderVideos,
  libraryItems,
  librarySearch,
  monitors,
  playerProfiles,
  playlists,
  regions,
  rtmpDestinations,
  sites,
  socialMediaAccounts,
  systemEvents,
  timezones,
  users,
  venues,
  webChannels,
  webPresets,
});

export type RootState = ReturnType<typeof appReducer>;
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;

export const initialRootState: RootState = {
  admin: initialAdminState,
  analytics: initialAnalyticsState,
  authentication: initialAuthenticationTokensState,
  contentDestinations: initialContentDestinationsState,
  contentSchedule: initialContentScheduleState,
  customer: initialCustomerState,
  destinationGroups: initialDestinationGroupsState,
  encoderPresets: initialEncoderPresetsState,
  encoders: initialEncodersState,
  encoderLogs: initialEncoderLogsState,
  encoderChannels: initialEncoderChannelsState,
  encoderVideos: initialEncoderVideosState,
  libraryItems: initialLibraryItemsState,
  librarySearch: initialLibrarySearchState(),
  monitors: initialCustomerMonitorsState,
  playlists: initialPlaylistsState,
  playerProfiles: initialPlayerProfileState,
  regions: initialRegionsState,
  rtmpDestinations: initialRtmpDestinationsState,
  sites: initialSiteState,
  socialMediaAccounts: initialSocialMediaAccountsState,
  systemEvents: initialSystemEventsState,
  timezones: initialTimezonesState,
  users: initialUsersState,
  venues: initialVenueState,
  webChannels: initialWebChannelsState,
  webPresets: initialWebPresetsState,
};

const rootReducer: Reducer<RootState, AnyAction> = (state, action) => {
  if (
    action.type === AuthenticationActionTypes.LOGOUT_REQUEST_SUCCESS &&
    !state?.authentication.expired &&
    !state?.authentication.isVenue
  ) {
    state = undefined;
  } else if (action.type === AuthenticationActionTypes.SWITCHED_ORGS && state) {
    // Reset all state objects back to initial state.
    // EXCEPT for [adminSearch, authentication, customer, monitors, timezones, regions]
    state.admin = initialAdminState;
    state.analytics = initialAnalyticsState;
    state.encoders = initialEncodersState;
    state.contentDestinations = initialContentDestinationsState;
    state.contentSchedule = initialContentScheduleState;
    state.destinationGroups = initialDestinationGroupsState;
    state.encoderChannels = initialEncoderChannelsState;
    state.encoderPresets = initialEncoderPresetsState;
    state.encoders = initialEncodersState;
    state.encoderVideos = initialEncoderVideosState;
    state.libraryItems = initialLibraryItemsState;
    state.librarySearch = initialLibrarySearchState();
    state.monitors = initialCustomerMonitorsState;
    state.playerProfiles = initialPlayerProfileState;
    state.playlists = initialPlaylistsState;
    state.rtmpDestinations = initialRtmpDestinationsState;
    state.sites = initialSiteState;
    state.socialMediaAccounts = initialSocialMediaAccountsState;
    state.systemEvents = initialSystemEventsState;
    state.users = initialUsersState;
    state.venues = initialVenueState;
    state.webChannels = initialWebChannelsState;
    state.webPresets = initialWebPresetsState;
  }

  return appReducer(state, action);
};

export default rootReducer;
