import { assigned } from './assigned';
import { count } from './count';
import { root, id } from './hardware-units';
import { summary } from './summary';

export const hardwareUnits = {
  ...root,
  assigned,
  count,
  id,
  summary,
};
