import type { Venues } from '@studio/types';

export default function sortVenues(venue: Venues.Derived.Response): number {
  if (!venue.active) return 6;

  switch (venue.decoder.status) {
    case 'playing':
      return 0;
    case 'paused':
      return 1;
    case 'idle':
      return 2;
    case 'offline':
      return 3;
    default:
      return 4;
  }
}
