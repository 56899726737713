import { createAction, createAsyncAction } from 'typesafe-actions';
import type { Monitors } from '@studio/types';
import type { CacheData } from './types';
import { ActionTypes } from './types';

export const fetch = {
  encoders: createAsyncAction(
    ActionTypes.FETCH_ENCODERS_REQUEST,
    ActionTypes.FETCH_ENCODERS_REQUEST_SUCCESS,
    ActionTypes.FETCH_ENCODERS_REQUEST_ERROR,
    ActionTypes.FETCH_ENCODERS_REQUEST_CANCEL
  )<undefined, CacheData<Monitors.Get.V2.Encoders>, Error, undefined>(),
  erroredEncoders: createAsyncAction(
    ActionTypes.FETCH_ERRORED_ENCODERS_REQUEST,
    ActionTypes.FETCH_ERRORED_ENCODERS_REQUEST_SUCCESS,
    ActionTypes.FETCH_ERRORED_ENCODERS_REQUEST_ERROR,
    ActionTypes.FETCH_ERRORED_ENCODERS_REQUEST_CANCEL
  )<undefined, CacheData<Monitors.Get.V2.ErroredEncoders>, Error, undefined>(),
  decoders: createAsyncAction(
    ActionTypes.FETCH_DECODERS_REQUEST,
    ActionTypes.FETCH_DECODERS_REQUEST_SUCCESS,
    ActionTypes.FETCH_DECODERS_REQUEST_ERROR,
    ActionTypes.FETCH_DECODERS_REQUEST_CANCEL
  )<undefined, CacheData<Monitors.Get.V2.Decoders>, Error, undefined>(),
};

export const set = {
  encoders: {
    version: createAction(ActionTypes.FILTER_ENCODER_VERSIONS)<string>(),
    filter: createAction(ActionTypes.FILTER_ENCODER_RESULTS)<string>(),
  },
  decoders: {
    filter: createAction(ActionTypes.FILTER_DECODER_RESULTS)<string>(),
    version: createAction(ActionTypes.FILTER_DECODER_VERSIONS)<string>(),
  },
  erroredEncoders: {},
};
