import * as React from 'react';
import type { Options, OptionsGeneric, Instance } from '@popperjs/core';
import { createPopper } from '@popperjs/core';
import { sanitizeProps } from '@resi-media/resi-ui';
import { S } from './styles';

type _Props = {
  anchorEl?: HTMLElement | null;
  children?: React.ReactNode;
  closeOnClick?: boolean;
  closeOnClickOutside?: boolean;
  dataTestId?: string;
  fullWidthMobile?: boolean;
  isOpen?: boolean;
  /**
   * Popper.js is based on a "plugin-like" architecture,
   * most of its features are fully encapsulated "modifiers".
   *
   * A modifier is a function that is called each time Popper.js needs to
   * compute the position of the popper.
   * To learn how to create a modifier, [read the modifiers documentation](https://popper.js.org/docs/v2/modifiers/).
   */
  modifiers?: Options['modifiers'];
  onClose?: (e: KeyboardEvent | MouseEvent) => void;
  placement?: Options['placement'];
  /**
   * Options provided to the [`Popper.js`](https://popper.js.org/docs/v2/constructors/#options) instance.
   * @default {}
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  popperOptions?: Partial<OptionsGeneric<any>>;
};

const PopUpMenu = ({
  anchorEl,
  children,
  closeOnClick = false,
  closeOnClickOutside = true,
  dataTestId,
  fullWidthMobile,
  isOpen = false,
  modifiers = [],
  onClose,
  placement: initialPlacement = 'bottom-end',
  popperOptions = {},
  ...rest
}: _Props) => {
  // REF FOR RENDERED DOM POPPER
  const tooltipRef = React.useRef<HTMLElement | null>(null);
  // REF FOR INTERNAL POPPER INSTANCE
  const popperRef = React.useRef<Instance | null>(null);

  React.useEffect(() => {
    if (popperRef.current) {
      popperRef.current.forceUpdate();
    }
  });
  const handleOpen = React.useCallback(() => {
    if (!tooltipRef.current || !anchorEl || !isOpen) {
      return;
    }

    if (popperRef.current) {
      popperRef.current.destroy();
    }

    const resolvedAnchorEl = anchorEl;

    if (process.env.NODE_ENV !== 'production') {
      if (resolvedAnchorEl.nodeType === 1) {
        const box = resolvedAnchorEl.getBoundingClientRect();

        if (process.env.NODE_ENV !== 'test' && box.top === 0 && box.left === 0 && box.right === 0 && box.bottom === 0) {
          console.warn(
            [
              'The `anchorEl` prop provided to the component is invalid.',
              'The anchor element should be part of the document layout.',
              "Make sure the element is present in the document or that it's not display none.",
            ].join('\n')
          );
        }
      }
    }

    let popperModifiers = [
      {
        name: 'offset',
        options: {
          offset: [0, 4],
        },
      },
      {
        name: 'preventOverflow',
        options: {
          enabled: true,
          altBoundary: true,
          rootBoundary: 'document',
        },
      },
      {
        name: 'flip',
        options: {
          altBoundary: true,
          rootBoundary: 'document',
          enabled: true,
        },
      },
    ] as Options['modifiers'];

    if (modifiers.length) {
      popperModifiers = popperModifiers.concat(modifiers);
    }
    if (popperOptions.modifiers) {
      popperModifiers = popperModifiers.concat(popperOptions.modifiers);
    }

    const popper = createPopper(anchorEl, tooltipRef.current, {
      placement: initialPlacement,
      ...popperOptions,
      modifiers: popperModifiers,
    });

    popperRef.current = popper;
  }, [anchorEl, initialPlacement, isOpen, modifiers, popperOptions]);

  const handleRef = React.useCallback(
    (node: HTMLElement | null) => {
      tooltipRef.current = node;
      handleOpen();
    },
    [handleOpen]
  );

  const handleClose = () => {
    if (!popperRef.current) {
      return;
    }

    popperRef.current.destroy();
  };

  React.useEffect(() => {
    return () => {
      handleClose();
    };
  }, []);

  const handleClickListener = React.useCallback(
    (e: MouseEvent): void => {
      if (!closeOnClickOutside) return;
      const clickedElement = e.target as HTMLElement;
      if (clickedElement !== tooltipRef.current && (closeOnClick || !tooltipRef.current?.contains(clickedElement))) {
        onClose?.(e);
      }
    },
    [closeOnClick, closeOnClickOutside, onClose]
  );

  React.useEffect(() => {
    if (!isOpen) {
      handleClose();
    } else {
      window.addEventListener('click', handleClickListener);
      // Currently needs to be this specific or React-Table stops propagation of the event to higher levels
      document.getElementById('root')?.addEventListener('click', handleClickListener);
    }

    return () => {
      window.removeEventListener('click', handleClickListener);
      document.getElementById('root')?.removeEventListener('click', handleClickListener);
    };
  }, [isOpen, handleClickListener]);

  return (
    <>
      {isOpen && (
        <div
          ref={handleRef}
          css={(theme) => S.popupContainer(theme, fullWidthMobile)}
          data-testid={dataTestId ?? 'popup'}
          {...sanitizeProps(rest)}
        >
          <div css={(theme) => S.popup(theme, fullWidthMobile)}>{children}</div>
        </div>
      )}
    </>
  );
};

PopUpMenu.displayName = 'PopUpMenu';

export default PopUpMenu;
