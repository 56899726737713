import { isFileValid } from '../isFileValid';

const getFileSource = async (file: File): Promise<string | void> => {
  if (isFileValid(file)) {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.addEventListener('load', () => resolve(reader.result as string), false);
      reader.readAsDataURL(file);
    });
  }
};

export default getFileSource;
