import type { AxiosResponse } from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { HTTP_METHODS } from '@studio/constants/axios';
import type { ApiClientConfig } from '@studio/types/axios';
import { axiosInstance } from '../axios-instance';
import { getDefaultRaxConfig } from '../get-default-rax-config';

async function client<T>(
  url: string,
  {
    contentType,
    data,
    headers: customHeaders,
    includeTrackingId = true,
    raxConfig,
    returnResponse,
    token,
    ...customConfig
  }: ApiClientConfig
): Promise<T> {
  let response: AxiosResponse;
  let headerContentType;
  try {
    if (contentType) {
      headerContentType = contentType;
    } else {
      headerContentType = data ? 'application/json' : undefined;
    }
    const trackingId = `STUDIO_${uuidv4()}`;
    response = await axiosInstance(url, {
      method: data ? HTTP_METHODS.POST : HTTP_METHODS.GET,
      data,
      headers: {
        ...(headerContentType && { 'Content-Type': headerContentType }),
        ...(token && { Authorization: `X-Bearer ${token}` }),
        ...customHeaders,
        ...(includeTrackingId && { 'X-Request-ID': trackingId, TrackingID: trackingId }),
      },
      ...customConfig,
      raxConfig: raxConfig ? raxConfig : getDefaultRaxConfig(),
    });
  } catch (error) {
    return Promise.reject(error);
  }

  return returnResponse ? response : response.data;
}

export { client };
