import { produce } from 'immer';
import type { ActionType, PayloadAction } from 'typesafe-actions';
import { createReducer } from 'typesafe-actions';
import type { EncoderChannels } from '@studio/types';
import {
  fetchEncoderChannelsAsync,
  fetchEncoderChannelUserAsync,
  forceFetchEncoderChannelsAsync,
  setFetchRequestTimestamp,
  setFilter,
} from './actions';
import type { EncoderChannelsState, ActionTypes } from './types';

type Action = ActionType<
  | typeof fetchEncoderChannelsAsync
  | typeof fetchEncoderChannelUserAsync
  | typeof forceFetchEncoderChannelsAsync
  | typeof setFetchRequestTimestamp
  | typeof setFilter
>;

export const initialState: EncoderChannelsState = {
  isFetching: false,
  items: [],
  timestamp: 0,
  error: null,
  filter: '',
};

const reducer = createReducer<EncoderChannelsState, Action>(initialState)
  .handleAction(
    fetchEncoderChannelsAsync.request,
    produce((draft: EncoderChannelsState) => {
      draft.isFetching = true;
      draft.error = null;
    })
  )
  .handleAction(
    fetchEncoderChannelsAsync.success,
    produce(
      (
        draft: EncoderChannelsState,
        action: PayloadAction<ActionTypes.FETCH_REQUEST_SUCCESS, EncoderChannels.Derived.EncoderChannel[]>
      ) => {
        draft.isFetching = false;
        draft.items = action.payload;
      }
    )
  )
  .handleAction(
    fetchEncoderChannelsAsync.failure,
    produce((draft: EncoderChannelsState, action: PayloadAction<ActionTypes.FETCH_REQUEST_ERROR, Error>) => {
      draft.isFetching = false;
      draft.error = action.payload;
    })
  )
  .handleAction(
    fetchEncoderChannelsAsync.cancel,
    produce((draft: EncoderChannelsState) => {
      draft.isFetching = false;
    })
  )
  .handleAction(
    forceFetchEncoderChannelsAsync.request,
    produce((draft: EncoderChannelsState) => {
      draft.isFetching = true;
      draft.error = null;
    })
  )
  .handleAction(
    forceFetchEncoderChannelsAsync.success,
    produce(
      (
        draft: EncoderChannelsState,
        action: PayloadAction<ActionTypes.FORCE_FETCH_REQUEST_SUCCESS, EncoderChannels.Derived.EncoderChannel[]>
      ) => {
        draft.isFetching = false;
        draft.items = action.payload;
      }
    )
  )
  .handleAction(
    forceFetchEncoderChannelsAsync.failure,
    produce((draft: EncoderChannelsState, action: PayloadAction<ActionTypes.FORCE_FETCH_REQUEST_ERROR, Error>) => {
      draft.isFetching = false;
      draft.error = action.payload;
    })
  )
  .handleAction(
    setFetchRequestTimestamp,
    produce((draft: EncoderChannelsState, action: PayloadAction<ActionTypes.FETCH_REQUEST_TIMESTAMP, number>) => {
      draft.timestamp = action.payload;
    })
  )
  .handleAction(
    fetchEncoderChannelUserAsync.success,
    produce(
      (
        draft: EncoderChannelsState,
        action: PayloadAction<ActionTypes.FETCH_USERS_SUCCESS, EncoderChannels.Derived.FetchUsersResponse>
      ) => {
        const updatedItems = [...draft.items];
        let iteration = 0;
        let updated = false;

        while (!updated) {
          if (updatedItems[iteration].uuid === action.payload.streamProfileId) {
            updatedItems[iteration].users = action.payload.users;
            updated = true;
            break;
          }

          iteration++;
        }

        draft.items = updatedItems;
      }
    )
  )
  .handleAction(
    fetchEncoderChannelUserAsync.failure,
    produce((draft: EncoderChannelsState, action: PayloadAction<ActionTypes.FETCH_USERS_ERROR, Error>) => {
      draft.isFetching = false;
      draft.error = action.payload;
    })
  )
  .handleAction(
    setFilter,
    produce((draft: EncoderChannelsState, action: PayloadAction<ActionTypes.FILTER_RESULTS, string>) => {
      draft.filter = action.payload;
    })
  );

export default reducer;
