export const EMBED_MODAL_ACTIONS = {
  CUE: 'CUE',
  PLAYER_PROFILE: 'PLAYER_PROFILE',
  SHARE_TYPE: 'SHARE_TYPE',
  SINGLE_EVENT: 'SINGLE_EVENT',
  START_POSITION: 'START_POSITION',
} as const;

export const SHARE_TYPE = {
  EMBED: 'EMBED',
  SHARE: 'SHARE',
} as const;
