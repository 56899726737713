import type { AllEffect, ForkEffect } from 'redux-saga/effects';
import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';
import { client } from '@studio/api/api-client/client';
import { CENTRAL_API_V3 } from '@studio/constants/env-variables';
import type { Regions } from '@studio/types';
import type { ApiClientConfig } from '@studio/types/axios';
import { selectAccessToken } from '../authentication';
import { fetch } from './actions';
import { ActionTypes } from './types';

function* handleRegionsFetch() {
  const url = `${CENTRAL_API_V3}/regions`;
  const token: string = yield select(selectAccessToken);
  const config: ApiClientConfig = { token };
  try {
    const response: Regions.Get.Region[] = yield call(client, url, config);
    yield put(fetch.regions.success(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(fetch.regions.failure(error));
    }
  }
}

export function* watchFetchRegionsRequest<T>(): Generator<ForkEffect<never>, void, T> {
  yield takeLatest(ActionTypes.FETCH_REQUEST, handleRegionsFetch);
}

export function* regionsSaga<T>(): Generator<AllEffect<ForkEffect<void>>, void, T> {
  yield all([fork(watchFetchRegionsRequest)]);
}
