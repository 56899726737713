import { parseTimeIntoSeconds } from '@studio/helpers';
import type { Cues, Shared } from '@studio/types';

const filterCuesByTimeRange = (
  cues: Cues.Derived.CueWithUser[],
  timeRange: Shared.Player.TimeRange
): Cues.Derived.CueWithUser[] => {
  return cues.filter((cue) => {
    const cuePosition = parseTimeIntoSeconds(cue.position);
    return cuePosition >= timeRange.start && cuePosition <= timeRange.end;
  });
};

export default filterCuesByTimeRange;
