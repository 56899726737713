import { createAction, createAsyncAction } from 'typesafe-actions';
import type { Analytics } from '@studio/types';
import type { AnalyticsState } from './types';
import { ACTION_TYPES } from './types';

export const clearData = createAction(ACTION_TYPES.CLEAR_DATA)<keyof AnalyticsState>();

export const eventAnalytics = {
  request: createAction(ACTION_TYPES.FETCH_EVENT_ANALYTICS)(),
  success: createAction(ACTION_TYPES.FETCH_EVENT_ANALYTICS_SUCCESS)<Analytics.Derived.Generic[]>(),
  failure: createAction(ACTION_TYPES.FETCH_EVENT_ANALYTICS_ERROR)<Error>(),
};

export const fetch = {
  contentLibraryLatLong: createAsyncAction(
    ACTION_TYPES.FETCH_CONTENT_LIBRARY_LATLONG,
    ACTION_TYPES.FETCH_CONTENT_LIBRARY_LATLONG_SUCCESS,
    ACTION_TYPES.FETCH_CONTENT_LIBRARY_LATLONG_ERROR,
    ACTION_TYPES.FETCH_CONTENT_LIBRARY_LATLONG_CANCEL
  )<Analytics.Get.DefaultParams, Analytics.Derived.HeatMap[], unknown, undefined>(),

  export: createAsyncAction(
    ACTION_TYPES.FETCH_EXPORT,
    ACTION_TYPES.FETCH_EXPORT_SUCCESS,
    ACTION_TYPES.FETCH_EXPORT_ERROR,
    ACTION_TYPES.FETCH_EXPORT_CANCEL
  )<Analytics.Get.ExportParams, Analytics.Get.Export[], unknown, undefined>(),

  latLong: createAsyncAction(
    ACTION_TYPES.FETCH_LATLONG,
    ACTION_TYPES.FETCH_LATLONG_SUCCESS,
    ACTION_TYPES.FETCH_LATLONG_ERROR,
    ACTION_TYPES.FETCH_LATLONG_CANCEL
  )<Analytics.Get.DefaultParams, Analytics.Derived.HeatMap[], unknown, undefined>(),

  summary: createAsyncAction(
    ACTION_TYPES.FETCH_SUMMARY,
    ACTION_TYPES.FETCH_SUMMARY_SUCCESS,
    ACTION_TYPES.FETCH_SUMMARY_ERROR,
    ACTION_TYPES.FETCH_SUMMARY_CANCEL
  )<Analytics.Get.DefaultParams, Analytics.Get.Summary[], unknown, undefined>(),
} as const;

export const setIsCsvEnabled = createAction(ACTION_TYPES.SET_IS_CSV_ENABLED)<boolean>();
