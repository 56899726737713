export class CentralError extends Error {
  constructor(
    message: string,
    public code?: string | null,
    public errors?: Error[]
  ) {
    super(message);
    this.code = code;
    this.name = 'CentralError';
    this.errors = errors;
    // Set the prototype explicitly.
    Object.setPrototypeOf(this, CentralError.prototype);
  }
}
export class CentralResponseError extends Error {
  constructor(data: { data: CentralError }) {
    super();
    // Set the prototype explicitly.
    this.response = data;
  }

  public response: { data: CentralError };
}

export interface Error {
  code: string;
  message: string;
}

export class WrongUserTypeError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'WrongUserTypeError';
  }
}
