import { produce } from 'immer';
import type { ActionType } from 'typesafe-actions';
import { createReducer } from 'typesafe-actions';
import { fetch, set } from './actions';
import type { State } from './types';

type Action = ActionType<typeof fetch | typeof set>;

export const initialState: State = {
  locations: {
    error: null,
    isFetching: false,
    data: [],
    timestamp: 0,
  },
  models: {
    error: null,
    isFetching: false,
    data: [],
    timestamp: 0,
  },
  status: {
    error: null,
    isFetching: false,
    data: [],
    timestamp: 0,
  },
  cycles: {
    error: null,
    isFetching: false,
    data: [],
    timestamp: 0,
  },
};

const reducer = createReducer<State, Action>(initialState)
  .handleAction(
    fetch.locations.request,
    produce((draft: State) => {
      draft.locations.isFetching = true;
      draft.locations.error = null;
    })
  )
  .handleAction(
    fetch.locations.success,
    produce((draft: State, action: ActionType<typeof fetch.locations.success>) => {
      draft.locations.isFetching = false;
      draft.locations.data = action.payload;
    })
  )
  .handleAction(
    fetch.locations.failure,
    produce((draft: State, action: ActionType<typeof fetch.locations.failure>) => {
      draft.locations.isFetching = false;
      draft.locations.error = action.payload;
    })
  )
  .handleAction(
    fetch.locations.cancel,
    produce((draft: State) => {
      draft.locations.isFetching = false;
    })
  )
  .handleAction(
    set.locationsTimestamp,
    produce((draft: State, action: ActionType<typeof set.locationsTimestamp>) => {
      draft.locations.timestamp = action.payload;
    })
  )
  .handleAction(
    fetch.models.request,
    produce((draft: State) => {
      draft.models.isFetching = true;
      draft.models.error = null;
    })
  )
  .handleAction(
    fetch.models.success,
    produce((draft: State, action: ActionType<typeof fetch.models.success>) => {
      draft.models.isFetching = false;
      draft.models.data = action.payload;
    })
  )
  .handleAction(
    fetch.models.failure,
    produce((draft: State, action: ActionType<typeof fetch.models.failure>) => {
      draft.models.isFetching = false;
      draft.models.error = action.payload;
    })
  )
  .handleAction(
    fetch.models.cancel,
    produce((draft: State) => {
      draft.models.isFetching = false;
    })
  )
  .handleAction(
    set.modelsTimestamp,
    produce((draft: State, action: ActionType<typeof set.modelsTimestamp>) => {
      draft.models.timestamp = action.payload;
    })
  )
  .handleAction(
    fetch.status.request,
    produce((draft: State) => {
      draft.status.isFetching = true;
      draft.status.error = null;
    })
  )
  .handleAction(
    fetch.status.success,
    produce((draft: State, action: ActionType<typeof fetch.status.success>) => {
      draft.status.isFetching = false;
      draft.status.data = action.payload;
    })
  )
  .handleAction(
    fetch.status.failure,
    produce((draft: State, action: ActionType<typeof fetch.status.failure>) => {
      draft.status.isFetching = false;
      draft.status.error = action.payload;
    })
  )
  .handleAction(
    fetch.status.cancel,
    produce((draft: State) => {
      draft.status.isFetching = false;
    })
  )
  .handleAction(
    set.statusTimestamp,
    produce((draft: State, action: ActionType<typeof set.statusTimestamp>) => {
      draft.status.timestamp = action.payload;
    })
  )
  .handleAction(
    fetch.cycles.request,
    produce((draft: State) => {
      draft.models.isFetching = true;
      draft.models.error = null;
    })
  )
  .handleAction(
    fetch.cycles.success,
    produce((draft: State, action: ActionType<typeof fetch.cycles.success>) => {
      draft.cycles.isFetching = false;
      draft.cycles.data = action.payload;
    })
  )
  .handleAction(
    fetch.cycles.failure,
    produce((draft: State, action: ActionType<typeof fetch.cycles.failure>) => {
      draft.cycles.isFetching = false;
      draft.cycles.error = action.payload;
    })
  )
  .handleAction(
    fetch.cycles.cancel,
    produce((draft: State) => {
      draft.cycles.isFetching = false;
    })
  )
  .handleAction(
    set.cyclesTimestamp,
    produce((draft: State, action: ActionType<typeof set.cyclesTimestamp>) => {
      draft.cycles.timestamp = action.payload;
    })
  );

export default reducer;
