import * as React from 'react';
import { FieldLabel, Stack } from '@resi-media/resi-ui';
import { useFormContext } from 'react-hook-form';
import type { LatencyDetails } from '@studio/constants/data-center-constants';
import { usePrefix } from '@studio/hooks';
import type { Regions } from '@studio/types';
import { DataCenterLatency } from '../DataCenterLatency';

type _Props = {
  ignoreInitialRegion?: boolean;
  onRegionFound?: (bestLatency: LatencyDetails) => void;
  regions: Regions.Get.Region[];
};

const LatencyField = ({ ignoreInitialRegion, onRegionFound, regions }: _Props) => {
  const { prefixNS } = usePrefix('pages:', 'fields.regionId');
  const { getValues, setValue, trigger } = useFormContext();
  const { regionDetails, regionId } = getValues();

  const handleRegionFound = React.useCallback(
    (latencyDetails: LatencyDetails | null): void => {
      setValue('regionId', latencyDetails?.region.uuid);
      setValue('regionDetails', latencyDetails);
      // Trigger Validation if form depends on it
      trigger(['regionId', 'regionDetails']);

      if (onRegionFound && latencyDetails) {
        onRegionFound(latencyDetails);
      }
      setFoundRegion(latencyDetails);
    },
    [onRegionFound, setValue, trigger]
  );

  const existingRegions = React.useMemo(() => {
    return (
      regionDetails ||
      (regionId && {
        dataCenter: regions.find((region) => regionId === region.uuid) ?? {},
        duration: 0,
        isPassing: true,
      })
    );
  }, [regionDetails, regionId, regions]);

  const [foundRegion, setFoundRegion] = React.useState(ignoreInitialRegion ? undefined : existingRegions);
  return (
    <Stack dataTestId="latency-field">
      <FieldLabel dataTestId="latency-field-title" fieldLabel={prefixNS('title')} />
      <DataCenterLatency
        data-testid="data-center-latency"
        existingRegion={foundRegion}
        onRegionFound={handleRegionFound}
        regions={regions}
      />
    </Stack>
  );
};

export default React.memo(LatencyField);
