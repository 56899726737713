import type { Media, Playlists, Shared } from '@studio/types';

export interface PlaylistListInfo {
  nextUrl?: string;
  playlists: Playlists.Get.Playlist[];
}

export interface PlaylistsState {
  error: Error | null;
  isFetching: boolean;
  listInfo: PlaylistListInfo;
  sortBy: string;
  timestamp: number;
}

export interface PlaylistMediaItem {
  cdnManifestUrls?: Media.Get.ManifestUrls[];
  description: string;
  embedId: string;
  id: string;
  length: string;
  mediaId: string;
  startTime: string;
  thumbnails?: Shared.Thumbnail.Base[];
  title: string;
}

export interface WebplayerPlaylistMediaItem {
  cdnManifestUrls?: Media.Get.ManifestUrls[];
  description: string;
  encodedId: string;
  thumbnails?: Shared.Thumbnail.Base[];
  title: string;
}

export interface FetchOptions {
  force?: boolean;
  sort?: SortOptions;
}

export enum SortOptions {
  MOST_RECENT = 'desc.created_date',
  NAME_AtoZ = 'asc.name',
  NAME_ZtoA = 'desc.name',
  OLDEST = 'asc.created_date',
}

export enum ActionTypes {
  DELETE_ITEM = '@@playlists/DELETE_ITEM',
  FETCH_MORE_REQUEST = '@@playlists/FETCH_MORE_REQUEST',
  FETCH_MORE_REQUEST_ERROR = '@@playlists/FETCH_MORE_REQUEST_ERROR',
  FETCH_MORE_REQUEST_SUCCESS = '@@playlists/FETCH_MORE_REQUEST_SUCCESS',
  FETCH_REQUEST = '@@playlists/FETCH_REQUEST',
  FETCH_REQUEST_CANCEL = '@@playlists/FETCH_REQUEST_CANCEL',
  FETCH_REQUEST_ERROR = '@@playlists/FETCH_REQUEST_ERROR',
  FETCH_REQUEST_SUCCESS = '@@playlists/FETCH_REQUEST_SUCCESS',
  FETCH_REQUEST_TIMESTAMP = '@@playlists/FETCH_REQUEST_TIMESTAMP',
  SORT_RESULTS = '@@playlists/SORT_RESULTS',
}
