import { FILE_VALIDATION_ERRORS } from '@studio/constants/shared';

const MAX_VIDEO_FILE_SIZE_GB = 68_719_476_736; // 64GB

const isVideoFileValid = (file?: File, maxVideoFileSizeGb = MAX_VIDEO_FILE_SIZE_GB): boolean => {
  const validVideoFileType = ['mp4', 'video/mp4'];

  if (!file) {
    console.error('A file is required for uploading.');
    throw new Error(FILE_VALIDATION_ERRORS.NO_FILE);
  }

  if (validVideoFileType.indexOf(file.type) === -1) {
    console.error('File must be an mp4 file');
    throw new Error(FILE_VALIDATION_ERRORS.FILE_TYPE);
  }

  if (file.size > maxVideoFileSizeGb) {
    const maxFileSizeGb = maxVideoFileSizeGb / 1024 / 1024 / 1024;
    console.error(`File size must not exceed ${maxFileSizeGb} GB.`);
    throw new Error(FILE_VALIDATION_ERRORS.FILE_SIZE);
  }

  return true;
};

export default isVideoFileValid;
