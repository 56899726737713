import { HTTP_METHODS } from '@studio/constants/axios';
import { PLAYER_CONFIG_API_V1 } from '@studio/constants/env-variables';
import type { PlayerProfiles } from '@studio/types';

export const root = {
  [HTTP_METHODS.POST]: {
    url: `${PLAYER_CONFIG_API_V1}/customers/@customerId/configs`,
    request: {} as PlayerProfiles.Post.Profile,
    method: HTTP_METHODS.POST,
  },
};

export const id = {
  [HTTP_METHODS.DELETE]: {
    url: `${PLAYER_CONFIG_API_V1}/customers/@customerId/configs/@id`,
    params: {} as { id: string },
    method: HTTP_METHODS.DELETE,
  },
  [HTTP_METHODS.GET]: {
    url: `${PLAYER_CONFIG_API_V1}/customers/@customerId/configs/@id`,
    response: {} as PlayerProfiles.Get.Profile,
    params: {} as { id: string },
    method: HTTP_METHODS.GET,
  },
  [HTTP_METHODS.PATCH]: {
    url: `${PLAYER_CONFIG_API_V1}/customers/@customerId/configs/@id`,
    request: {} as PlayerProfiles.Patch.Profile,
    params: {} as { id: string },
    method: HTTP_METHODS.PATCH,
  },
};
