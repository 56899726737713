import * as React from 'react';
import { useTheme } from '@emotion/react';
import Cropper from 'react-easy-crop';
import type { Area } from 'react-easy-crop/types';

export type CropArea = {
  height: number;
  width: number;
  x: number;
  y: number;
};

type _Props = {
  aspect?: number;
  fileSrc: string;
  onCropComplete?: (croppedAreaPixels: CropArea) => void;
};

const ImageCropper = ({ aspect, fileSrc, onCropComplete }: _Props) => {
  const theme = useTheme();
  const [crop, setCrop] = React.useState({ x: 0, y: 0 });
  const [zoom, setZoom] = React.useState(1);
  const handleCropComplete = React.useCallback(
    (_croppedArea: Area, croppedAreaPixels: Area) => {
      if (onCropComplete) {
        onCropComplete(croppedAreaPixels);
      }
    },
    [onCropComplete]
  );
  return (
    <Cropper
      aspect={aspect}
      crop={crop}
      image={fileSrc}
      onCropChange={setCrop}
      onCropComplete={handleCropComplete}
      onZoomChange={setZoom}
      style={{ containerStyle: { borderRadius: theme.shape.borderRadius.xl } }}
      zoom={zoom}
    />
  );
};

ImageCropper.displayName = 'ImageCropper';

export default ImageCropper;
