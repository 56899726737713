import { HTTP_METHODS } from '@studio/constants/axios';
import { CENTRAL_API_V2 } from '@studio/constants/env-variables';

type MultisitePlan = {
  name: string;
  uuid: string;
};

export const root = {
  [HTTP_METHODS.GET]: {
    url: `${CENTRAL_API_V2}/plans`,
    response: {} as MultisitePlan[],
    method: HTTP_METHODS.GET,
  },
};
