import { produce } from 'immer';
import type { ActionType, PayloadAction } from 'typesafe-actions';
import { createReducer } from 'typesafe-actions';
import type { Regions } from '@studio/types';
import { fetch } from './actions';
import type { ActionTypes, RegionsState } from './types';

type Action = ActionType<typeof fetch>;

export const initialState: RegionsState = {
  isFetching: false,
  items: [],
  error: null,
};

const reducer = createReducer<RegionsState, Action>(initialState)
  .handleAction(
    fetch.regions.request,
    produce((draft: RegionsState) => {
      draft.isFetching = true;
      draft.error = null;
    })
  )
  .handleAction(
    fetch.regions.success,
    produce((draft: RegionsState, action: PayloadAction<ActionTypes.FETCH_REQUEST_SUCCESS, Regions.Get.Region[]>) => {
      draft.isFetching = false;
      draft.items = action.payload;
    })
  )
  .handleAction(
    fetch.regions.failure,
    produce((draft: RegionsState, action: PayloadAction<ActionTypes.FETCH_REQUEST_ERROR, Error>) => {
      draft.isFetching = false;
      draft.error = action.payload;
    })
  )
  .handleAction(
    fetch.regions.cancel,
    produce((draft: RegionsState) => {
      draft.isFetching = false;
    })
  );

export default reducer;
