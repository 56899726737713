import * as React from 'react';
import { useTheme } from '@emotion/react';
import { Text } from '@resi-media/resi-ui';
import type { ResiColumnProps, ResiHeaderGroupProps } from 'react-table';
import { S } from '../styles';

type _Props<T> = {
  column: ResiHeaderGroupProps<T>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getPropsForColumn: (column: ResiColumnProps<T>) => any;
  isHeaderFixed: boolean | undefined;
};

const Header = <T extends object>({ column, getPropsForColumn, isHeaderFixed }: _Props<T>) => {
  const resiTheme = useTheme();
  const [isHover, setIsHover] = React.useState(false);

  return (
    <div
      {...column.getHeaderProps(getPropsForColumn(column))}
      className="th"
      css={() => [S.header(resiTheme), isHeaderFixed && S.stickyHeader(resiTheme), column.headerProps?.css]}
      data-testid="table-list-header"
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <Text colorVariant="primary" isInline variant="label">
        {column.render('Header', { isHover: isHover })}
      </Text>
    </div>
  );
};

export { Header };
