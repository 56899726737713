import * as React from 'react';
import { useDispatch } from 'react-redux';
import UrlPaths from '@studio/constants/url-paths';
import { AuthenticationActionTypes } from '@studio/store/authentication/types';

const AuthProvider = ({ children }: { children?: React.ReactNode }) => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (
      !(
        [
          UrlPaths.UPLOADER.ROOT,
          UrlPaths.UPLOADER.RESET_PASSWORD,
          UrlPaths.UPLOADER.SUCCESS,
          UrlPaths.UNREGISTER.ROOT,
          UrlPaths.UNREGISTER.SUCCESS,
        ] as string[]
      ).some((url) => window.location.pathname.endsWith(url))
    ) {
      dispatch({ type: AuthenticationActionTypes.APP_RELOAD });
    }
  }, [dispatch]);

  return <React.Fragment>{children}</React.Fragment>;
};

AuthProvider.displayName = 'AuthProvider';

export default AuthProvider;
