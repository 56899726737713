import { createAction, createAsyncAction } from 'typesafe-actions';
import type { Roles, Users } from '@studio/types';
import { UserInvitesActionTypes, UserRolesActionTypes, UsersActionTypes } from './types';

export const fetch = {
  invites: createAsyncAction(
    UserInvitesActionTypes.FETCH_REQUEST,
    UserInvitesActionTypes.FETCH_REQUEST_SUCCESS,
    UserInvitesActionTypes.FETCH_REQUEST_ERROR,
    UserInvitesActionTypes.FETCH_REQUEST_CANCEL
  )<undefined, Users.Get.UserInvite[], Error, undefined>(),
  users: createAsyncAction(
    UsersActionTypes.FETCH_REQUEST,
    UsersActionTypes.FETCH_REQUEST_SUCCESS,
    UsersActionTypes.FETCH_REQUEST_ERROR,
    UsersActionTypes.FETCH_REQUEST_CANCEL
  )<undefined, Users.Get.User[], Error, undefined>(),
  roles: createAsyncAction(
    UserRolesActionTypes.FETCH_REQUEST,
    UserRolesActionTypes.FETCH_REQUEST_SUCCESS,
    UserRolesActionTypes.FETCH_REQUEST_ERROR,
    UserRolesActionTypes.FETCH_REQUEST_CANCEL
  )<undefined, Roles.Get.Role[], Error, undefined>(),
};

export const set = {
  filter: createAction(UsersActionTypes.FILTER_RESULTS)<string>(),
  usersTimestamp: createAction(UsersActionTypes.FETCH_REQUEST_TIMESTAMP)<number>(),
  invitesTimestamp: createAction(UserInvitesActionTypes.FETCH_REQUEST_TIMESTAMP)<number>(),
  rolesTimestamp: createAction(UserRolesActionTypes.FETCH_REQUEST_TIMESTAMP)<number>(),
};
