import * as React from 'react';
import { produce } from 'immer';
import { createContainer } from 'react-tracked';
import { useClient } from '@studio/hooks';
import { filterApiClientsByName } from '@studio/pages/ApiAccess/helpers/filterApiClientsByName';
import type { Client } from '@studio/types';

type _State = {
  clientSecret?: string;
  filter: string;
};

const initialApiAccessState: _State = {
  filter: '',
};

enum API_ACCESS_ACTION_TYPES {
  SET_API_ACCESS,
  SET_CLIENT_SECRET,
  SET_FILTER,
}

type ApiAccessActions =
  | {
      payload: string;
      type: API_ACCESS_ACTION_TYPES.SET_CLIENT_SECRET;
    }
  | {
      payload: string;
      type: API_ACCESS_ACTION_TYPES.SET_FILTER;
    };

const apiAccessReducer = (state: _State, action: ApiAccessActions) => {
  switch (action.type) {
    case API_ACCESS_ACTION_TYPES.SET_CLIENT_SECRET:
      state.clientSecret = action.payload;
      break;
    case API_ACCESS_ACTION_TYPES.SET_FILTER:
      state.filter = action.payload;
      break;
  }
};

type ApiAccessContextHook = _State & {
  callApi: () => Promise<Client.Derived.Client[]>;
  data?: Readonly<Client.Derived.Client[]>;
  error: Error | null;
  filteredData?: Readonly<Client.Derived.Client[]>;
  hasData: boolean;
  isLoading: boolean;
  setClientSecret: (secret: string) => void;
  setFilter: (filter: string) => void;
};

const curriedReducerFunction = produce(apiAccessReducer);

const useValues = (): readonly [ApiAccessContextHook, () => void] => {
  const [{ clientSecret, filter, ...localState }, dispatch] = React.useReducer(curriedReducerFunction, {
    ...initialApiAccessState,
  });

  const { callApi, data, error, isFetching } = useClient({
    config: useClient.central.v3.clients.GET,
  });

  const setClientSecret = React.useCallback((secret: string) => {
    dispatch({
      type: API_ACCESS_ACTION_TYPES.SET_CLIENT_SECRET,
      payload: secret,
    });
  }, []);

  const setFilter = React.useCallback((filter: string) => {
    dispatch({
      type: API_ACCESS_ACTION_TYPES.SET_FILTER,
      payload: filter,
    });
  }, []);

  const state = React.useMemo(
    () => ({
      ...localState,
      clientSecret,
      data,
      callApi,
      error,
      filter,
      filteredData: filterApiClientsByName(data ?? [], filter),
      hasData: Boolean(data?.length),
      isLoading: isFetching,
      setClientSecret,
      setFilter,
    }),
    [callApi, clientSecret, data, error, filter, isFetching, localState, setClientSecret, setFilter]
  );

  return [
    state,
    () => {
      throw new Error('use functions in the state');
    },
  ];
};

const {
  Provider: ApiAccessProvider,
  useTrackedState: useApiAccess,
  useUpdate: useApiAccessUpdate,
} = createContainer<ApiAccessContextHook, () => void, { children: React.ReactNode }>(useValues);

export { ApiAccessProvider, useApiAccess, useApiAccessUpdate };
export type { ApiAccessContextHook };
