import type { Option } from '@resi-media/resi-ui';
import { DESTINATION } from '@studio/constants/content-destination';
import { sortByAlphaIgnoreCase } from '@studio/helpers/sortByAlphaIgnoreCase';
import type { SocialMediaAccountOptions } from '@studio/store/social-media';
import type { DestinationGroups } from '@studio/types';
import { getDestinationMemberAccountName } from '../getDestinationMemberAccountName';

const sortDestinationMembers = ({
  members,
  rtmpOptions,
  socialMediaOptions,
  webChannelOptions,
}: {
  members: DestinationGroups.Post.Member[];
  rtmpOptions?: Option[];
  socialMediaOptions?: Record<'fb' | 'yt', Map<string, SocialMediaAccountOptions>>;
  webChannelOptions?: Option[];
}): DestinationGroups.Post.Member[] => {
  const membersWithAccountName = members.map((member) => ({
    ...member,
    accountName: getDestinationMemberAccountName({ member, rtmpOptions, socialMediaOptions, webChannelOptions }),
  }));
  const webDestinations = membersWithAccountName
    .filter((s) => s.type === DESTINATION.EMBED)
    .sort(sortByAlphaIgnoreCase((item) => item.accountName));
  const fbDestinations = membersWithAccountName
    .filter((s) => s.type === DESTINATION.FACEBOOK)
    .sort(sortByAlphaIgnoreCase((item) => item.accountName));
  const ytDestinations = membersWithAccountName
    .filter((s) => s.type === DESTINATION.YOUTUBE)
    .sort(sortByAlphaIgnoreCase((item) => item.accountName));
  const rtmpDestinations = membersWithAccountName
    .filter((s) => s.type === DESTINATION.RTMP)
    .sort(sortByAlphaIgnoreCase((item) => item.accountName));
  return [...webDestinations, ...fbDestinations, ...ytDestinations, ...rtmpDestinations].map((member) => {
    const { accountName, ...rest } = member;
    return rest;
  });
};

export default sortDestinationMembers;
