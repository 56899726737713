import { root, id } from './encoder-profiles';
import * as idEndpoints from './id';

export const encoderProfiles = {
  ...root,
  id: {
    ...id,
    ...idEndpoints,
  },
};
