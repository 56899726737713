export const DATE_FORMAT = {
  STANDARD: 'MM/dd/yy',
  DAY_OF_WEEK: 'iiii',
  TIME: 'hh:mm a',
  LONG_TIME: 'pp',
  MONTH_DATE_YEAR: 'PP',
  DATE_AND_TIME: 'E, MMM d yyyy pp',
  UTC: `yyyy-MM-dd'T'HH:mm:ss'Z'`,
};

// ====================================================
// date CLSV ANALYTICS started inserting data
export const CONTENT_LIBRARY_LIFE_TIME_DATE = new Date(2022, 10, 7);
// Used as common time object for multiple files.
export const NOW_LOCAL = new Date();
export const END_NOW_UTC = NOW_LOCAL.toISOString();
// date analytics were reset
export const LIFE_TIME_DATE = new Date(2021, 5, 7);
// date min by min data is accurate, data lost prior to this date
export const MIN_BY_MIN_START_DATE = new Date(2023, 7, 21);
// ====================================================

export const MILLIS_IN_MIN = 60_000;
