import { produce } from 'immer';
import type { ActionType, PayloadAction } from 'typesafe-actions';
import { createReducer } from 'typesafe-actions';
import type { setFetchRequestTimestamp } from './actions';
import {
  fetchSocialMediaAccountsAsync,
  fetchStatusForSocialMediaAccountsAsync,
  setFilter,
  setFbAuthSuccess,
  setFbAuthError,
  resetFbAuth,
} from './actions';
import type {
  SocialMediaAccount,
  ActionTypes,
  FBAuthInfo,
  SocialMediaAccountState,
  SocialMediaAccountStatusResponse,
} from './types';

type Action = ActionType<
  | typeof fetchSocialMediaAccountsAsync
  | typeof fetchStatusForSocialMediaAccountsAsync
  | typeof resetFbAuth
  | typeof setFbAuthError
  | typeof setFbAuthSuccess
  | typeof setFetchRequestTimestamp
  | typeof setFilter
>;

export const initialState: SocialMediaAccountState = {
  isFetching: false,
  items: [],
  timeStamp: 0,
  error: null,
  filter: '',
  fbAuthInfo: null,
  fbAuthError: null,
};

const reducer = createReducer<SocialMediaAccountState, Action>(initialState)
  //Status Request
  .handleAction(
    fetchStatusForSocialMediaAccountsAsync.success,
    produce(
      (
        draft: SocialMediaAccountState,
        action: PayloadAction<ActionTypes.FETCH_STATUS_SUCCESS, SocialMediaAccountStatusResponse[]>
      ) => {
        action.payload.forEach((_item, index) => {
          draft.items[index].status = action.payload[index].simulcastCode;
          draft.items[index].expiresAt = action.payload[index].expiresAt;
        });
      }
    )
  )
  .handleAction(
    fetchStatusForSocialMediaAccountsAsync.failure,
    produce((draft: SocialMediaAccountState, action: PayloadAction<ActionTypes.FETCH_STATUS_ERROR, Error>) => {
      draft.isFetching = false;
      draft.error = action.payload;
    })
  )
  // Fetch Accounts Request
  .handleAction(
    fetchSocialMediaAccountsAsync.request,
    produce((draft: SocialMediaAccountState) => {
      draft.isFetching = true;
      draft.error = null;
    })
  )
  .handleAction(
    fetchSocialMediaAccountsAsync.success,
    produce(
      (
        draft: SocialMediaAccountState,
        action: PayloadAction<ActionTypes.FETCH_REQUEST_SUCCESS, SocialMediaAccount[]>
      ) => {
        draft.isFetching = false;
        draft.items = action.payload;
        draft.timeStamp = Date.now();
      }
    )
  )
  .handleAction(
    fetchSocialMediaAccountsAsync.failure,
    produce((draft: SocialMediaAccountState, action: PayloadAction<ActionTypes.FETCH_REQUEST_ERROR, Error>) => {
      draft.isFetching = false;
      draft.error = action.payload;
    })
  )
  .handleAction(
    fetchSocialMediaAccountsAsync.cancel,
    produce((draft: SocialMediaAccountState) => {
      draft.isFetching = false;
    })
  )
  .handleAction(
    setFilter,
    produce((draft: SocialMediaAccountState, action: PayloadAction<ActionTypes.FILTER_ITEMS, string>) => {
      draft.filter = action.payload;
    })
  )
  .handleAction(
    setFbAuthSuccess,
    produce((draft: SocialMediaAccountState, action: PayloadAction<ActionTypes.FB_AUTH_SUCCESS, FBAuthInfo>) => {
      draft.fbAuthError = null;
      draft.fbAuthInfo = action.payload;
    })
  )
  .handleAction(
    setFbAuthError,
    produce((draft: SocialMediaAccountState, action: PayloadAction<ActionTypes.FB_AUTH_ERROR, Error>) => {
      draft.fbAuthInfo = null;
      draft.fbAuthError = action.payload;
    })
  )
  .handleAction(
    resetFbAuth,
    produce((draft: SocialMediaAccountState) => {
      draft.fbAuthInfo = null;
      draft.fbAuthError = null;
    })
  );

export default reducer;
