import * as React from 'react';
import type { SerializedStyles } from '@emotion/react';
import DefaultThumbnail from '@studio/images/default-thumbnail.jpg';

type _Props = {
  alt?: string;
  customCss?: SerializedStyles;
  dataTestId?: string;
  defaultImage?: string;
  /**
   * When to use intersectionProps:
      - Lazy load in a scrollable list
      - That scrollable list is not virtualized
   */
  intersectionProps?: IntersectionObserverInit;
  src?: string;
};

const LazyLoadImage = ({
  alt,
  customCss,
  dataTestId = 'lazy-load-image__img',
  defaultImage = DefaultThumbnail,
  intersectionProps,
  src,
}: _Props) => {
  const imageRef = React.useRef<HTMLImageElement>({} as HTMLImageElement);
  const imageSrc = intersectionProps ? defaultImage : src;

  React.useEffect(() => {
    const currentImageRef = imageRef.current;
    const imageObserver = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting && entry.target instanceof HTMLImageElement) {
          const image: HTMLImageElement = entry.target;
          if (image.dataset.src) {
            image.src = image.dataset.src;
          }
          imageObserver.unobserve(image);
        }
      });
    }, intersectionProps);

    imageObserver.observe(currentImageRef);

    return () => {
      imageObserver.unobserve(currentImageRef);
    };
  }, [intersectionProps]);

  return (
    <img
      ref={imageRef}
      alt={alt}
      css={customCss}
      data-src={src}
      data-testid={dataTestId}
      onError={(e) => {
        const image: HTMLImageElement = e.target as HTMLImageElement;
        image.src = defaultImage;
      }}
      src={imageSrc ?? DefaultThumbnail}
    />
  );
};

LazyLoadImage.displayName = 'LazyLoadImage';

export default LazyLoadImage;
