import { createAction, createAsyncAction } from 'typesafe-actions';
import type { TokenResponse, AuthenticatedUser, Credentials } from './types';
import { AuthenticationActionTypes } from './types';

export const loginAsync = createAsyncAction(
  AuthenticationActionTypes.AUTH_REQUEST,
  AuthenticationActionTypes.AUTH_REQUEST_SUCCESS,
  AuthenticationActionTypes.AUTH_REQUEST_ERROR,
  AuthenticationActionTypes.AUTH_REQUEST_CANCEL
)<Credentials, TokenResponse, Error, undefined>();

export const logoutAsync = createAsyncAction(
  AuthenticationActionTypes.LOGOUT_REQUEST,
  AuthenticationActionTypes.LOGOUT_REQUEST_SUCCESS,
  AuthenticationActionTypes.LOGOUT_REQUEST_ERROR,
  AuthenticationActionTypes.LOGOUT_REQUEST_CANCEL
)<{ onError?: () => void; onFinally?: () => void; onSuccess?: () => void } | void, undefined, Error, undefined>();

export const fetchTokenAsync = createAsyncAction(
  AuthenticationActionTypes.FETCH_TOKEN_REQUEST,
  AuthenticationActionTypes.FETCH_TOKEN_REQUEST_SUCCESS,
  AuthenticationActionTypes.FETCH_TOKEN_REQUEST_ERROR,
  AuthenticationActionTypes.FETCH_TOKEN_REQUEST_CANCEL
)<undefined, TokenResponse, Error, undefined>();

export const fetchMeAsync = createAsyncAction(
  AuthenticationActionTypes.FETCH_ME_REQUEST,
  AuthenticationActionTypes.FETCH_ME_REQUEST_SUCCESS,
  AuthenticationActionTypes.FETCH_ME_REQUEST_ERROR
)<undefined, AuthenticatedUser, Error>();

export const setAccessTokenTimestamp = createAction(AuthenticationActionTypes.ACCESS_TOKEN_TIMESTAMP)<number>();
export const setAuthToken = createAction(AuthenticationActionTypes.ACCESS_TOKEN_SET)<string>();
export const setTokenExpired = createAction(AuthenticationActionTypes.ACCESS_TOKEN_EXPIRED)<undefined>();
export const setIsRefreshing = createAction(AuthenticationActionTypes.APP_REFRESHING)<boolean>();
export const setIsLoggingIn = createAction(AuthenticationActionTypes.IS_LOGGING_IN)<boolean>();
