import type { CombinedState } from 'redux';
import { createSelector } from 'reselect';
import type { RootState } from '../root-reducer';
import type { RtmpDestination, RtmpDestinationsState } from './types';

export const selectRtmpDestinationsState = (state: RootState): CombinedState<RtmpDestinationsState> =>
  state.rtmpDestinations;

export const selectItems = createSelector(
  selectRtmpDestinationsState,
  (state: RtmpDestinationsState): RtmpDestination[] => state.rtmpDestinations
);

export const selectFilter = createSelector(selectRtmpDestinationsState, (state): string => state.filter);

export const selectFilteredItems = createSelector(selectItems, selectFilter, (items, filter): RtmpDestination[] => {
  return items.filter((item) => item.name.toLowerCase().includes(filter.toLowerCase()));
});
