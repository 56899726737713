import { CENTRAL_API_V1 } from '@studio/constants/env-variables';
import type { SearchBy } from '../../types';
import { SEARCH_BY } from '../../types';

export const getContactSearchUrl = (searchBy: SearchBy, searchString: string): string => {
  if (searchBy === SEARCH_BY.USER_NAME) {
    return `${CENTRAL_API_V1}/contactsearch?email=${searchString}`;
  }
  return `${CENTRAL_API_V1}/contactsearch?mobile=${searchString}`;
};
