import { actions } from './actions';
import { archive } from './archive';
import { clients } from './clients';
import { contentDestinations } from './content-destinations';
import { contentDestinationsSchedules } from './content-destinations-schedules';
import { customers } from './customers';
import { encoderProfiles } from './encoder-profiles';
import { encoders } from './encoders';
import { eventProfiles } from './event-profiles';
import { events } from './events';
import { goLive } from './go-live';
import { historicalWebEvents } from './historical-web-events';
import { library } from './library';
import { resetPassword } from './reset-password';
import { rtmp } from './rtmp';
import { schedules } from './schedules';
import { socialMedia } from './socialmedia';
import { uploaders } from './uploaders';
import { userInvites } from './user-invites';
import { users } from './users';
import { venues } from './venues';
import { vodPlans } from './vod-plans';
import { webEncoderProfiles } from './web-encoder-profiles';
import { webEventProfiles } from './web-event-profiles';
import { webPlayer } from './web-player';

export const v3 = {
  actions,
  archive,
  clients,
  contentDestinations,
  contentDestinationsSchedules,
  customers,
  encoderProfiles,
  encoders,
  eventProfiles,
  events,
  historicalWebEvents,
  goLive,
  library,
  resetPassword,
  rtmp,
  schedules,
  socialMedia,
  uploaders,
  userInvites,
  users,
  venues,
  vodPlans,
  webEncoderProfiles,
  webEventProfiles,
  webPlayer,
};
