import { HTTP_METHODS } from '@studio/constants/axios';
import { MEDIA_METADATA_API_V1 } from '@studio/constants/env-variables';
import type { Library } from '@studio/types';

export const root = {
  [HTTP_METHODS.POST]: {
    url: `${MEDIA_METADATA_API_V1}/customers/@customerId/media/@mediaId/republish`,
    request: {} as Library.Post.TrimVideo,
    params: {} as { mediaId: string },
    method: HTTP_METHODS.POST,
  },
};
