import * as React from 'react';
import { CloudServerOutlined } from '@ant-design/icons';
import { useTheme } from '@emotion/react';
import { Box, BrandIcon, Draft, Inline, RLogo, Text } from '@resi-media/resi-ui';
import { match } from 'ts-pattern';
import { DESTINATION } from '@studio/constants/content-destination';
import { usePrefix } from '@studio/hooks';
import type { ContentDestinations } from '@studio/types';

type _DestinationIcon = {
  tooltipContent?: React.ReactNode;
  type: ContentDestinations.Get.Destination;
  url?: string;
};

type _Props = {
  destinations: _DestinationIcon[];
  maxIcons?: number;
};

const DestinationIcons = ({ destinations, maxIcons = 5 }: _Props) => {
  const theme = useTheme();
  const count = destinations.length;
  const { commonT, linkT } = usePrefix('');

  const renderIcon = React.useCallback((icon: JSX.Element, tooltipContent?: React.ReactNode) => {
    if (tooltipContent) {
      return (
        <Draft.Tooltip content={tooltipContent} dataTestId="tooltip">
          {icon}
        </Draft.Tooltip>
      );
    }
    return icon;
  }, []);

  return (
    <Inline alignItems="center" gap="m">
      {destinations
        .filter((_, i) => i <= maxIcons - 1)
        .map((destination, index) => (
          <Box key={index} dataTestId="icon-container">
            {match(destination.type)
              .with(DESTINATION.FACEBOOK, () =>
                renderIcon(<BrandIcon data-testid="fb-logo" variant="fb" />, destination.tooltipContent)
              )
              .with(DESTINATION.YOUTUBE, () =>
                renderIcon(
                  <BrandIcon
                    as="a"
                    data-testid="yt-logo"
                    href={destination.url ? destination.url : linkT('youtube')}
                    onClick={(e: React.MouseEvent<HTMLAnchorElement>) => e.stopPropagation()}
                    rel="noreferrer noopener"
                    target="_blank"
                    variant="yt"
                  />,
                  destination.tooltipContent
                )
              )
              .with(DESTINATION.RTMP, () =>
                renderIcon(
                  <CloudServerOutlined
                    data-testid="rtmp-icon"
                    style={{
                      fontSize: theme.typography.pxToRem(30),
                      color: theme.palette.text.primary,
                    }}
                  />,
                  destination.tooltipContent
                )
              )
              .otherwise(() =>
                renderIcon(
                  <RLogo
                    color={theme.palette.brand.resiRed}
                    data-testid="resi-logo"
                    fontSize={theme.typography.pxToRem(30)}
                  />,
                  destination.tooltipContent
                )
              )}
          </Box>
        ))}
      {destinations.length > maxIcons && (
        <Text data-testid="more-text" variant="body2">
          +{count - maxIcons} {commonT('more').toLowerCase()}
        </Text>
      )}
    </Inline>
  );
};

DestinationIcons.displayName = 'DestinationIcons';

export default DestinationIcons;
