import { root, id } from './event-profiles';
import * as idEndpoints from './id';

export const eventProfiles = {
  ...root,
  id: {
    ...id,
    ...idEndpoints,
  },
};
