import { HTTP_METHODS } from '@studio/constants/axios';
import { MEDIA_METADATA_API_V1 } from '@studio/constants/env-variables';
import type { Library } from '@studio/types';

export const root = {
  [HTTP_METHODS.GET]: {
    url: `${MEDIA_METADATA_API_V1}/customer/@customerId/bucketSize`,
    query: {} as { range: number },
    response: {} as Library.Get.BucketSize[],
    method: HTTP_METHODS.GET,
  },
};

export const id = {};
