import type { AxiosResponse } from 'axios';
import { HTTP_METHODS } from '@studio/constants/axios';
import { MEDIA_METADATA_API_V1 } from '@studio/constants/env-variables';
import type { Playlists } from '@studio/types';

export const root = {
  [HTTP_METHODS.GET]: {
    url: `${MEDIA_METADATA_API_V1}/customers/@customerId/playlists`,
    response: [] as Playlists.Get.Playlist[],
    method: HTTP_METHODS.GET,
    query: {} as {
      playlistIds?: string;
    },
  },
  [HTTP_METHODS.POST]: {
    url: `${MEDIA_METADATA_API_V1}/customers/@customerId/playlists`,
    request: {} as Playlists.Post.Playlist,
    response: {} as AxiosResponse,
    method: HTTP_METHODS.POST,
  },
};

export const id = {
  [HTTP_METHODS.GET]: {
    url: `${MEDIA_METADATA_API_V1}/customers/@customerId/playlists/@id`,
    response: {} as Playlists.Get.PlaylistDetails,
    params: {} as { id: string },
    method: HTTP_METHODS.GET,
  },
  [HTTP_METHODS.PATCH]: {
    url: `${MEDIA_METADATA_API_V1}/customers/@customerId/playlists/@id`,
    request: {} as Playlists.Patch.Playlist,
    params: {} as { id: string },
    method: HTTP_METHODS.PATCH,
  },
  [HTTP_METHODS.DELETE]: {
    url: `${MEDIA_METADATA_API_V1}/customers/@customerId/playlists/@id`,
    params: {} as { id: string },
    method: HTTP_METHODS.DELETE,
  },
};
