import * as React from 'react';
import { produce } from 'immer';
import type { Immutable } from 'immer';
import { createContainer } from 'react-tracked';
import { AudioParser } from '../../helpers';

type _SharedState = {
  audio: Immutable<number[]>;
};

type _PrivateState = {
  audioContextState?: AudioParser;
  channelsSetup: boolean;
  isCapturingAudio: boolean;
};

type AudioContextState = Partial<_PrivateState & _SharedState>;

enum AUDIO_CONTEXT_ACTION_TYPES {
  SET_AUDIO,
  SET_IS_CAPTURING,
  SET_CHANNELS_SETUP,
  SET_AUDIO_CONTEXT,
}

type AudioContextAction =
  | {
      payload: AudioParser;
      type: AUDIO_CONTEXT_ACTION_TYPES.SET_AUDIO_CONTEXT;
    }
  | {
      payload: boolean;
      type: AUDIO_CONTEXT_ACTION_TYPES.SET_CHANNELS_SETUP;
    }
  | {
      payload: boolean;
      type: AUDIO_CONTEXT_ACTION_TYPES.SET_IS_CAPTURING;
    }
  | {
      payload: number[];
      type: AUDIO_CONTEXT_ACTION_TYPES.SET_AUDIO;
    };

type AudioContextHook = AudioContextState & {
  startAudioCapture: () => void;
  stopAudioCapture: () => void;
};

const audioContextReducer = (state: AudioContextState, action: AudioContextAction) => {
  switch (action.type) {
    case AUDIO_CONTEXT_ACTION_TYPES.SET_AUDIO:
      state.audio = action.payload;
      break;
    case AUDIO_CONTEXT_ACTION_TYPES.SET_CHANNELS_SETUP:
      state.channelsSetup = action.payload;
      break;
    case AUDIO_CONTEXT_ACTION_TYPES.SET_IS_CAPTURING:
      state.isCapturingAudio = action.payload;
      break;
    case AUDIO_CONTEXT_ACTION_TYPES.SET_AUDIO_CONTEXT:
      state.audioContextState = action.payload;
      break;
  }
};

const curriedReducerFunction = produce(audioContextReducer);

const useValues = ({
  isPlaying,
  videoElement,
}: {
  isPlaying?: boolean;
  videoElement?: HTMLVideoElement | null;
}): readonly [AudioContextHook, () => void] => {
  const [state, dispatch] = React.useReducer(curriedReducerFunction, {
    channelsSetup: false,
    isCapturingAudio: false,
  });
  const { audio, audioContextState, channelsSetup, isCapturingAudio } = state;

  React.useEffect(() => {
    if (videoElement && !audioContextState && isCapturingAudio) {
      const newAudioContext = new AudioParser(videoElement);
      dispatch({
        type: AUDIO_CONTEXT_ACTION_TYPES.SET_AUDIO_CONTEXT,
        payload: newAudioContext,
      });
    }
  }, [audioContextState, isCapturingAudio, isPlaying, videoElement]);

  React.useEffect(() => {
    if (audioContextState && !channelsSetup && !audioContextState.timeoutId) {
      audioContextState.setupChannels();
      dispatch({
        type: AUDIO_CONTEXT_ACTION_TYPES.SET_CHANNELS_SETUP,
        payload: true,
      });
    }
  }, [audioContextState, channelsSetup]);

  React.useEffect(() => {
    if (audioContextState && channelsSetup && isCapturingAudio) {
      audioContextState.setupAudioAnalyzer((v) => {
        dispatch({
          type: AUDIO_CONTEXT_ACTION_TYPES.SET_AUDIO,
          payload: v,
        });
      });
    }
  }, [audioContextState, channelsSetup, isCapturingAudio]);

  React.useEffect(() => {
    if (audioContextState && !isCapturingAudio && audioContextState.timeoutId) {
      audioContextState.clearAudioAnalyser();
    }
  }, [audioContextState, isCapturingAudio]);

  const startAudioCapture = React.useCallback(() => {
    dispatch({
      type: AUDIO_CONTEXT_ACTION_TYPES.SET_IS_CAPTURING,
      payload: true,
    });
  }, []);

  const stopAudioCapture = React.useCallback(() => {
    dispatch({
      type: AUDIO_CONTEXT_ACTION_TYPES.SET_IS_CAPTURING,
      payload: false,
    });
  }, []);

  const stateToPass = React.useMemo(
    () => ({
      startAudioCapture,
      stopAudioCapture,
      audio,
    }),
    [audio, startAudioCapture, stopAudioCapture]
  );

  return [
    stateToPass,
    () => {
      throw new Error('use functions in the state');
    },
  ];
};

const {
  Provider: AudioContextProvider,
  useTrackedState: useAudioContext,
  useUpdate: useAudioContextUpdate,
} = createContainer<
  AudioContextHook,
  () => void,
  {
    isPlaying?: boolean;
    videoElement?: HTMLVideoElement | null;
  }
>(useValues);

export { AudioContextProvider, useAudioContext, useAudioContextUpdate };
export type { AudioContextHook };
