import type { Sites } from '@studio/types';

export enum ActionTypes {
  FETCH_REQUEST = '@@sites/FETCH_REQUEST',
  FETCH_REQUEST_CANCEL = '@@sites/FETCH_REQUEST_CANCEL',
  FETCH_REQUEST_ERROR = '@@sites/FETCH_REQUEST_ERROR',
  FETCH_REQUEST_SUCCESS = '@@sites/FETCH_REQUEST_SUCCESS',
  FETCH_REQUEST_TIMESTAMP = '@@sites/FETCH_REQUEST_TIMESTAMP',
  FILTER_RESULTS = '@@sites/FILTER_RESULTS',
}

export type SiteState = {
  error: Error | null;
  filter: string;
  isFetching: boolean;
  items: Sites.Derived.Site[];
  timestamp: number;
};
