import type { Monitors } from '@studio/types';

export enum ActionTypes {
  FETCH_DECODERS_REQUEST = '@@admin/monitors/FETCH_DECODERS_REQUEST',
  FETCH_DECODERS_REQUEST_CANCEL = '@@admin/monitors/FETCH_DECODERS_REQUEST_CANCEL',
  FETCH_DECODERS_REQUEST_ERROR = '@@admin/monitors/FETCH_DECODERS_REQUEST_ERROR',
  FETCH_DECODERS_REQUEST_SUCCESS = '@@admin/monitors/FETCH_DECODERS_REQUEST_SUCCESS',
  FETCH_ENCODERS_REQUEST = '@@admin/monitors/FETCH_ENCODERS_REQUEST',
  FETCH_ENCODERS_REQUEST_CANCEL = '@@admin/monitors/FETCH_ENCODERS_REQUEST_CANCEL',
  FETCH_ENCODERS_REQUEST_ERROR = '@@admin/monitors/FETCH_ENCODERS_REQUEST_ERROR',
  FETCH_ENCODERS_REQUEST_SUCCESS = '@@admin/monitors/FETCH_ENCODERS_REQUEST_SUCCESS',
  FETCH_ERRORED_ENCODERS_REQUEST = '@@admin/monitors/FETCH_ERRORED_ENCODERS_REQUEST',
  FETCH_ERRORED_ENCODERS_REQUEST_CANCEL = '@@admin/monitors/FETCH_ERRORED_ENCODERS_REQUEST_CANCEL',
  FETCH_ERRORED_ENCODERS_REQUEST_ERROR = '@@admin/monitors/FETCH_ERRORED_ENCODERS_REQUEST_ERROR',
  FETCH_ERRORED_ENCODERS_REQUEST_SUCCESS = '@@admin/monitors/FETCH_ERRORED_ENCODERS_REQUEST_SUCCESS',
  FILTER_DECODER_RESULTS = '@@admin/monitors/FILTER_DECODER_RESULTS',
  FILTER_DECODER_VERSIONS = '@@admin/monitors/FILTER_DECODER_VERSIONS',
  FILTER_ENCODER_RESULTS = '@@admin/monitors/FILTER_ENCODER_RESULTS',
  FILTER_ENCODER_VERSIONS = '@@admin/monitors/FILTER_ENCODER_VERSIONS',
  SET_DECODER_TIMESTAMP = '@@admin/monitors/SET_DECODER_TIMESTAMP',
  SET_ENCODER_ERRORED_TIMESTAMP = '@@admin/monitors/SET_ENCODER_ERRORED_TIMESTAMP',
  SET_ENCODER_TIMESTAMP = '@@admin/monitors/SET_ENCODER_TIMESTAMP',
}

export type CacheData<T> = {
  timestamp: number;
  value: T[];
};

export type EncodersState = {
  data: Monitors.Get.V2.Encoders[];
  dataCache: CacheData<Monitors.Get.V2.Encoders>[];
  error: Error | null;
  filter: string;
  isFetching: boolean;
  version: string;
};

export type DecodersState = {
  data: Monitors.Get.V2.Decoders[];
  dataCache: CacheData<Monitors.Get.V2.Decoders>[];
  error: Error | null;
  filter: string;
  isFetching: boolean;
  version: string;
};

export type ErroredEncodersState = {
  data: Monitors.Get.V2.ErroredEncoders[];
  dataCache: CacheData<Monitors.Get.V2.ErroredEncoders>[];
  error: Error | null;
  isFetching: boolean;
};

export type MonitorState = {
  decoders: DecodersState;
  encoders: EncodersState;
  erroredEncoders: ErroredEncodersState;
};
