import { HTTP_METHODS } from '@studio/constants/axios';
import { CENTRAL_API_V3 } from '@studio/constants/env-variables';
import type { Rtmp } from '@studio/types';

export const root = {
  [HTTP_METHODS.GET]: {
    method: HTTP_METHODS.GET,
    response: [] as Rtmp.Get.RtmpStream[],
    url: `${CENTRAL_API_V3}/customers/@customerId/rtmp`,
  },
};
