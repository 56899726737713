import * as React from 'react';
import { Draft, Text } from '@resi-media/resi-ui';
import { useDestinations, usePrefix } from '@studio/hooks';
import type { DestinationGroups, Schedules } from '@studio/types';
import { BroadcastTime } from '../BroadcastTime';

const RtmpSubcomponent = ({
  broadcastFields,
  member,
}: {
  broadcastFields?: DestinationGroups.Derived.BroadcastFields;
  member: Schedules.Derived.PartialContentDestinationSchedule;
}) => {
  const { commonT } = usePrefix('');
  const { webPresetOptions } = useDestinations();
  const webPreset = webPresetOptions.find((option) => option.value === member.webEncoderProfileId);

  return (
    <React.Fragment>
      <Draft.FormField fieldLabel={commonT('webPreset')}>
        <Text dataTestId="web-preset-name" variant="body1">
          {webPreset?.label ?? commonT('default')}
        </Text>
      </Draft.FormField>
      <BroadcastTime broadcastFields={broadcastFields} member={member} />
    </React.Fragment>
  );
};

RtmpSubcomponent.displayName = 'RtmpSubcomponent';

export default RtmpSubcomponent;
