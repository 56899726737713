import * as React from 'react';
import { Draft, ListItem } from '@resi-media/resi-ui';
import { usePrefix } from '@studio/hooks';
import type { ActionMenu } from '../ActionMenu';
import type { _State } from '../SaveToPlaylistModal';
import { SaveToPlaylistModal } from '../SaveToPlaylistModal';

type _Props = ListItem.Props & {
  anchorRef: React.ForwardedRef<ActionMenu.Ref>;
  mediaId: string;
};

const SaveToPlaylistListItem = ({ anchorRef, mediaId, ...otherProps }: _Props) => {
  const { prefixNS } = usePrefix('pages:', 'library.saveToPlaylist');
  const { setModal, setModalState } = Draft.ModalContext.useModal<_State>();

  const onClick = React.useCallback(
    async (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
      event.stopPropagation();
      if (anchorRef != null && typeof anchorRef !== 'function') {
        if (anchorRef.current) {
          anchorRef.current.forceClose();
        }
      }
      setModalState({ mediaId });
      setModal(() => <SaveToPlaylistModal />, {});
    },
    [anchorRef, mediaId, setModal, setModalState]
  );

  return (
    <ListItem dataTestId="save-to-playlist-list-item" onClick={onClick} {...otherProps}>
      {prefixNS('menu')}
    </ListItem>
  );
};

SaveToPlaylistListItem.displayName = 'SaveToPlaylistListItem';

export default SaveToPlaylistListItem;
