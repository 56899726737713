import type { SerializedStyles, Theme } from '@emotion/react';
import { css } from '@emotion/react';

export const S = {
  rerouteContainer: (theme: Theme, hasHeader: boolean): SerializedStyles => css`
    flex-direction: column;
    padding: 0 ${theme.spacing.m};
    ${theme.mq.lg} {
      flex-direction: row-reverse;
      min-height: ${hasHeader ? '50vh' : '80vh'};
    }
  `,
  infoContainer: (theme: Theme): SerializedStyles => css`
    ${theme.mq.lg} {
      max-width: ${theme.typography.pxToRem(300)};
    }
  `,
  paragraph: (theme: Theme): SerializedStyles => css`
    margin: ${theme.spacing.l} 0;
    ${theme.mq.md} {
      margin: 0;
    }
    ${theme.mq.lg} {
      margin: ${theme.spacing.l} 0;
    }
  `,
  svgContainer: (theme: Theme): SerializedStyles => css`
    font-size: ${theme.typography.pxToRem(300)};
    display: flex;
    justify-content: center;
    ${theme.mq.md} {
      font-size: ${theme.typography.pxToRem(400)};
    }
    ${theme.mq.lg} {
      font-size: ${theme.typography.pxToRem(500)};
    }
  `,
};
