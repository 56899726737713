import { root, id } from './events';
import * as idEndpoints from './id';

export const events = {
  ...root,
  id: {
    ...id,
    ...idEndpoints,
  },
};
