import { Draft, Link, Stack, Text } from '@resi-media/resi-ui';
import { EMAIL_SUCCESS } from '@studio/constants/external-links-constants';
import { usePrefix } from '@studio/hooks';

type _Props = {
  customerName: string;
  dataTestId?: string;
  errorMessage: string;
};

const ModalBodyError = ({ customerName, dataTestId, errorMessage }: _Props) => {
  const { prefixNS, PrefixTrans } = usePrefix('pages:', 'library.noPlan');
  return (
    <Stack dataTestId={dataTestId ?? 'modal-body-error'} gap="l">
      <Draft.AlertBanner variant="warning">
        <Text>{errorMessage}</Text>
      </Draft.AlertBanner>
      <Text>
        <PrefixTrans i18nKey="contactSupport">
          fill
          <Link
            dataTestId="email-success-link"
            href={`${EMAIL_SUCCESS}?subject=Request to add Content Library trial.`}
            variant="body2"
          >
            fill
          </Link>
          {customerName}
        </PrefixTrans>
      </Text>
      <Text>{prefixNS('startStoring')}</Text>
    </Stack>
  );
};

ModalBodyError.displayName = 'ModalBodyError';

export default ModalBodyError;
