import { useSelector } from 'react-redux';
import { isValidEmail } from '@studio/helpers/isValidEmail';
import { selectUser } from '@studio/store';

const useIsEmailUnverified = (): boolean => {
  const user = useSelector(selectUser);
  return user?.emailVerified === false && !user.actualCustomer && isValidEmail(user.userName);
};

export default useIsEmailUnverified;
