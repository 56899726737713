import * as React from 'react';
import type { TFunction } from 'react-i18next';
import { useTranslation, Trans } from 'react-i18next';
import type { PrefixCallback } from '@studio/types';

type PrefixTransTypes = {
  count?: number;
  i18nKey: string;
  opts?: Record<string, unknown>;
};

type _PrefixTransProps = PrefixTransTypes & { children?: React.ReactNode };

type usePrefixTypes = {
  PrefixTrans: (p: _PrefixTransProps) => JSX.Element;
  Trans: typeof Trans;
  commonT: PrefixCallback;
  errorT: PrefixCallback;
  linkExt: PrefixCallback;
  linkT: PrefixCallback;
  prefixNS: PrefixCallback;
  prefixT: PrefixCallback;
  t: TFunction;
};

const usePrefix = (prefix: string, ns = ''): usePrefixTypes => {
  const { t } = useTranslation();

  const PrefixTrans = React.useMemo(
    () =>
      // eslint-disable-next-line react/display-name
      ({ children, i18nKey, ...opts }: _PrefixTransProps) => (
        <Trans i18nKey={`${prefix}${ns}.${i18nKey}`} {...opts}>
          {children}
        </Trans>
      ),
    [prefix, ns]
  );

  return {
    PrefixTrans,
    prefixT: React.useCallback(
      (key: string, opts?: Record<string, unknown>): string =>
        t<string>(`${prefix}${key}`, { ...(ns && { ns }), ...opts }),
      [prefix, ns, t]
    ),
    prefixNS: React.useCallback(
      (key: string, opts?: Record<string, unknown>): string =>
        t<string>(`${prefix}${ns}${ns ? '.' : ''}${key}`, {
          ...(ns && { ns: `${prefix}${ns}` }),
          ...opts,
        }),
      [prefix, ns, t]
    ),
    commonT: React.useCallback(
      (key: string, opts?: Record<string, unknown>): string => t<string>(`common:${key}`, opts),
      [t]
    ),
    linkExt: React.useCallback(
      (key: string, opts?: Record<string, unknown>): string =>
        t<string>(`links:${key}`, opts).replace('https', 'external'),
      [t]
    ),
    linkT: React.useCallback(
      (key: string, opts?: Record<string, unknown>): string => t<string>(`links:${key}`, opts),
      [t]
    ),
    errorT: React.useCallback(
      (key: string, opts?: Record<string, unknown>): string =>
        t<string>([`errors:${key}`, t('common:errors.genericTryAgain')], opts),
      [t]
    ),
    t,
    Trans,
  };
};

export default usePrefix;
