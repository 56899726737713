import type { CombinedState } from 'redux';
import { createSelector } from 'reselect';
import { availableRoles } from '@studio/constants/roles';
import { sortByAlphaIgnoreCase } from '@studio/helpers';
import type { RootState } from '@studio/store/root-reducer';
import type { Roles, Users } from '@studio/types';
import type { UsersState } from './types';

export const selectUsersState = (state: RootState): CombinedState<UsersState> => state.users;

export const selectFilter = createSelector(selectUsersState, (state): string => state.filter);

export const selectUserInviteItems = createSelector(selectUsersState, (state): Users.Get.UserInvite[] => state.invites);
export const selectUserItems = createSelector(selectUsersState, (state): Users.Get.User[] => state.users);
export const selectUserRoles = createSelector(selectUsersState, (state): Roles.Get.Role[] => state.roles);

export const filterUserRoles = createSelector(selectUserRoles, (roles): Roles.Get.Role[] =>
  roles.filter((role: Roles.Get.Role) => availableRoles.some((includedRole) => role.id === includedRole))
);

export const filterUsers = createSelector(
  selectUserItems,
  selectUserInviteItems,
  selectFilter,
  (users, invites, filter): Users.Get.UserOrInvite[] => {
    const sortedInvites = invites
      .filter((item) => item.email.toLowerCase().includes(filter.toLowerCase()))
      .sort(sortByAlphaIgnoreCase((invite: Users.Get.UserInvite) => invite.email));
    const sortedUsers = users
      .filter((item) => item.userName.toLowerCase().includes(filter.toLowerCase()))
      .sort(sortByAlphaIgnoreCase((user: Users.Get.User) => user.userName));
    return [...sortedInvites, ...sortedUsers];
  }
);

export const selectUsersTimestamp = createSelector(selectUsersState, (state): number => state.timestamp);
export const selectUserInvitesTimestamp = createSelector(selectUsersState, (state): number => state.invitesTimestamp);
export const selectUserRolesTimestamp = createSelector(selectUsersState, (state): number => state.rolesTimestamp);
