// global appliction utilities/overrides
import styled from '@emotion/styled';

export const CenterText = styled.div`
  text-align: center;
`;

export const BlueText = styled.span`
  color: ${({ theme }) => theme.palette.primary.main};
`;

export const Float = styled.div<{ direction?: string }>`
  float: ${({ direction }) => direction ?? 'right'};
`;

// spacing
export const SpaceXs = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.xs};
`;

export const SpaceS = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.s};
`;

export const SpaceM = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.m};
`;

export const SpaceL = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.l};
`;

export const SpaceXl = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.xl};
`;

export const HideOnDesktop = styled.div`
  ${({ theme }) => theme.mq.xl} {
    display: none;
  }
`;

export const HideOnSmallMobile = styled.div<{ display?: string }>`
  display: none;
  ${({ theme }) => theme.mq.md} {
    display: ${({ display }) => display ?? 'block'};
  }
`;

export const HideOnMobile = styled.span<{ display?: string }>`
  display: none;
  ${({ theme }) => theme.mq.lg} {
    display: ${({ display }) => display ?? 'block'};
  }
`;

export const MobileOnly = styled.div<{ display?: string }>`
  display: ${({ display }) => display ?? 'block'};
  ${({ theme }) => theme.mq.lg} {
    display: none;
  }
`;

export const HideOnTablet = styled.div<{ display?: string }>`
  display: none;
  ${({ theme }) => theme.mq.xl} {
    display: ${({ display }) => display ?? 'block'};
  }
`;

export const TabletOnly = styled.div<{ display?: string }>`
  display: none;

  ${({ theme }) => theme.mq.md} {
    display: ${({ display }) => display ?? 'block'};
  }

  ${({ theme }) => theme.mq.xl} {
    display: none;
  }
`;
