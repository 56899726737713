import { css } from '@emotion/react';
import type { MergeFirst, ResponsiveValue, Spacing, StyledProps } from '@resi-media/resi-ui';
import { Inline, Text, Link, Stack, useStyledPropResolution, mergeDefaultProps, Hr } from '@resi-media/resi-ui';
import type { To } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import { usePrefix } from '@studio/hooks';

type _Props = StyledProps & {
  children?: React.ReactNode;
  closeNode?: React.ReactNode;
  header: React.ReactNode;
  linkText?: string;
  onClick?: () => void;
  showHr?: boolean;
  to?: To;
};

const defaultProps = {
  px: { xs: 'm', lg: 'xl' } as ResponsiveValue<
    // eslint-disable-next-line @typescript-eslint/ban-types
    keyof Spacing | (number & {}) | (string & {})
  >,
  py: { xs: 's', lg: 'm' } as ResponsiveValue<
    // eslint-disable-next-line @typescript-eslint/ban-types
    keyof Spacing | (number & {}) | (string & {})
  >,
};

type _InternalProps = MergeFirst<_Props, typeof defaultProps>;

const WidgetHeader = (props: _Props) => {
  const propsInternal: _InternalProps = mergeDefaultProps(props, defaultProps);
  const { children, closeNode, header, linkText, onClick, showHr, to, ...rest } = propsInternal;
  const { styleSheet } = useStyledPropResolution('Box', { ...rest }, {});
  const { commonT } = usePrefix('');

  return (
    <Stack gap="0">
      <Inline alignItems="center" css={css(styleSheet)} dataTestId="header-container" justifyContent="space-between">
        <Text colorVariant="heading" variant="body1" weightVariant="bold">
          {header}
        </Text>
        {to && !closeNode && (
          <Link
            as={RouterLink}
            colorVariant="brand"
            css={css`
              display: flex;
              align-items: center;
            `}
            data-trackid="see-more-arrow"
            dataTestId="see-more-link"
            isLink
            {...(onClick && { onClick })}
            to={to}
            variant="body3"
          >
            {linkText ?? commonT('seeAll')}
          </Link>
        )}
        {closeNode}
      </Inline>
      {children}
      {showHr && <Hr dataTestId="widget-header__hr" />}
    </Stack>
  );
};

WidgetHeader.displayName = 'WidgetHeader';

export default WidgetHeader;
