import * as React from 'react';
import { Draft } from '@resi-media/resi-ui';
import { usePrefix } from '@studio/hooks';

// DateIso component expects provided date to be in ISO format
// For formatting options see: https://date-fns.org/v2.22.1/docs/format

type _Props = {
  date: string;
  format: keyof Draft.LocalizationProvider.Formats;
};

const DateIso = ({ date, format }: _Props) => {
  const utils = Draft.useUtils<Date>();
  const { commonT } = usePrefix('components:', 'dateIso');

  const getFormattedDate = () => {
    const parsedDate = utils.parseISO(date);
    if (utils.isValid(parsedDate)) {
      return utils.format(parsedDate, format);
    }
    return commonT('invalidDate');
  };

  return <>{getFormattedDate()}</>;
};

DateIso.displayName = 'DateIso';

export default DateIso;
