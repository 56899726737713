import { HTTP_METHODS } from '@studio/constants/axios';
import { TELEMETRY_API_V1 } from '@studio/constants/env-variables';

export const root = {
  [HTTP_METHODS.GET]: {
    method: HTTP_METHODS.GET,
    query: {} as {
      endDate: string;
      startDate: string;
    },
    response: {} as { minutes: number },
    url: `${TELEMETRY_API_V1}/customers/@customerId/webevents/statistics/minuteCount`,
  },
};
