export const DURATION_RANGE = {
  UNDER_FIVE_MINUTES: 'UNDER_FIVE_MINUTES',
  FIVE_TO_THIRTY_MINUTES: 'FIVE_TO_THIRTY_MINUTES',
  OVER_THIRTY_MINUTES: 'OVER_THIRTY_MINUTES',
} as const;

export const MEDIA_SOURCE = {
  LIVE: 'LIVE',
  UPLOADED: 'UPLOADED',
} as const;

export const SORT_FIELD = {
  SCORE: 'SCORE',
  TITLE: 'TITLE',
  CREATE_TIME: 'CREATE_TIME',
  START_TIME: 'START_TIME',
} as const;

export const SORT_ORDER = {
  ASC: 'ASC',
  DESC: 'DESC',
} as const;

export const THUMBNAIL_FILTER = {
  HAS_THUMBNAIL: 'HAS_THUMBNAIL',
  MISSING_THUMBNAIL: 'MISSING_THUMBNAIL',
};

export const SORT_OPTIONS = {
  MOST_RECENT: { field: SORT_FIELD.START_TIME, order: SORT_ORDER.DESC },
  MOST_RELEVANT: { field: SORT_FIELD.SCORE, order: SORT_ORDER.DESC },
  TITLE_A_TO_Z: { field: SORT_FIELD.TITLE, order: SORT_ORDER.ASC },
  TITLE_Z_TO_A: { field: SORT_FIELD.TITLE, order: SORT_ORDER.DESC },
  OLDEST: { field: SORT_FIELD.START_TIME, order: SORT_ORDER.ASC },
} as const;
