import * as React from 'react';
import { Draft, Stack, Text } from '@resi-media/resi-ui';
import { ConfirmationModal } from '@studio/components/ConfirmationModal';
import { usePrefix } from '@studio/hooks';
import type { Cues } from '@studio/types';

type _Props = Cues.Derived.CueWithUser & {
  apiCall: (v: string) => void;
};

type _State = {
  error: Error | null;
  isLoading: boolean;
};

const DeleteCueModalInternal = ({ apiCall, name, uuid }: _Props) => {
  const { error, isLoading, onCloseReset } = Draft.ModalContext.useModal<_State>();
  const { commonT, prefixNS } = usePrefix('components:', 'cuesTimeline');

  function handleDeleteMedia() {
    apiCall(uuid);
    onCloseReset();
  }

  return (
    <ConfirmationModal
      colorVariant="negative"
      data-testid="delete-cue-modal"
      errorMessage={error ? commonT('errors.genericTryAgain') : null}
      handleClose={onCloseReset}
      handleSubmit={handleDeleteMedia}
      isHandlingSubmit={isLoading}
      isOpen
      isUsingContext
      submitLabel={commonT('remove')}
      title={prefixNS('deleteModalTitle')}
    >
      <Stack gap="m">
        <Text>{prefixNS('deleteModalMsg')}</Text>
        <Text data-testid="delete-cue-modal-cue-name" weightVariant="bold">
          {name}
        </Text>
      </Stack>
    </ConfirmationModal>
  );
};

DeleteCueModalInternal.displayName = 'DeleteCueModalInternal';

/* eslint-disable import/export */
export const DeleteCueModal = Object.assign(DeleteCueModalInternal, {
  displayName: 'DeleteCueModal',
});

// eslint-disable-next-line no-redeclare
export namespace DeleteCueModal {
  export type Props = _Props;
  export type State = _State;
}
