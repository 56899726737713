import type { AtLeast } from '@resi-media/resi-ui';
import type { Immutable } from 'immer';
import type { Player } from '@studio/types/shared';
import { formatDuration } from './helpers';

const playerStatus = {
  loading: 'loading',
  loaded: 'loaded',
  playing: 'playing',
  paused: 'paused',
} as const;
type playerStatusTypes = keyof typeof playerStatus;

type PlayerDetails = {
  audio: Immutable<number[]>;
  currentTime: number;
  duration: number;
  durationString: string;
  timeRange?: Player.TimeRange;
  videoContainer: HTMLElement | Immutable<HTMLElement> | null;
  videoElement: HTMLVideoElement | Immutable<HTMLVideoElement> | null;
};

export type PlayerState =
  | (AtLeast<PlayerDetails, 'currentTime'> & { playerState: 'loading' })
  | (PlayerDetails & {
      playerState: 'loaded' | 'paused' | 'playing';
    });

export enum PLAYER_ACTION_TYPES {
  SET_CURRENT_TIME,
  SET_PLAYER_STATE,
  SET_PLAYER_DETAILS,
  SET_PLAYER_TIME,
}

const playerState = {
  load: 'load',
  play: 'play',
  pause: 'pause',
} as const;
export type playerStateActions = keyof typeof playerState;

type PlayerAction =
  | {
      payload: {
        duration: number;
        timeRange: TimeRanges;
        videoContainer: HTMLElement;
      };
      type: PLAYER_ACTION_TYPES.SET_PLAYER_DETAILS;
    }
  | {
      payload: number;
      type: PLAYER_ACTION_TYPES.SET_CURRENT_TIME;
    }
  | {
      payload: playerStateActions;
      type: PLAYER_ACTION_TYPES.SET_PLAYER_STATE;
    };

function convertTimeRanges(val: TimeRanges) {
  return { start: val.start(0), end: val.end(val.length - 1) };
}

const convertStateToStatus = (playerStateActions: playerStateActions): playerStatusTypes => {
  switch (playerStateActions) {
    case 'load':
      return 'loading';
    case 'pause':
      return 'paused';
    case 'play':
      return 'playing';
  }
};

export const PlayerReducer = (state: PlayerState, action: PlayerAction) => {
  switch (action.type) {
    case PLAYER_ACTION_TYPES.SET_PLAYER_STATE:
      state.playerState = convertStateToStatus(action.payload);
      break;
    case PLAYER_ACTION_TYPES.SET_CURRENT_TIME:
      state.currentTime = action.payload;
      break;
    case PLAYER_ACTION_TYPES.SET_PLAYER_DETAILS:
      state.playerState = 'loaded';
      state.timeRange = convertTimeRanges(action.payload.timeRange);
      state.duration = action.payload.duration;
      state.durationString = formatDuration(action.payload.duration);
      state.videoContainer = action.payload.videoContainer;
      break;
  }
};
