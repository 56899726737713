import { produce } from 'immer';
import type { ActionType, PayloadAction } from 'typesafe-actions';
import { createReducer } from 'typesafe-actions';
import { EncoderLogs } from '@studio/types';
import { fetch, set } from './actions';
import type { ActionTypes, EncoderLogsState } from './types';

type Action = ActionType<typeof fetch | typeof set>;

export const initialState: EncoderLogsState = {
  currentEncoderId: '',
  error: null,
  filter: '',
  isFetching: false,
  items: new Map<string, EncoderLogs.Get.LogEntry[]>(),
  logLevel: EncoderLogs.Get.LOG_LEVEL.INFO,
};

const reducer = createReducer<EncoderLogsState, Action>(initialState)
  .handleAction(
    fetch.logs.request,
    produce((draft: EncoderLogsState) => {
      draft.isFetching = true;
      draft.error = null;
    })
  )
  .handleAction(
    fetch.logs.success,
    produce(
      (
        draft: EncoderLogsState,
        action: PayloadAction<ActionTypes.FETCH_REQUEST_SUCCESS, EncoderLogs.Derived.EncoderLogEntry>
      ) => {
        draft.isFetching = false;
        draft.items = draft.items.set(action.payload.encoderId, action.payload.logs);
      }
    )
  )
  .handleAction(
    fetch.logs.failure,
    produce((draft: EncoderLogsState, action: PayloadAction<ActionTypes.FETCH_REQUEST_ERROR, Error>) => {
      draft.isFetching = false;
      draft.error = action.payload;
    })
  )
  .handleAction(
    fetch.logs.cancel,
    produce((draft: EncoderLogsState) => {
      draft.isFetching = false;
    })
  )
  .handleAction(
    set.logs.encoderId,
    produce((draft: EncoderLogsState, action: PayloadAction<ActionTypes.CURRENT_ENCODER_ID, string>) => {
      draft.currentEncoderId = action.payload;
    })
  )
  .handleAction(
    set.logs.filter,
    produce((draft: EncoderLogsState, action: PayloadAction<ActionTypes.FILTER_RESULTS, string>) => {
      draft.filter = action.payload;
    })
  );

export default reducer;
