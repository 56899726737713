import type { Theme, SerializedStyles } from '@emotion/react';
import { css } from '@emotion/react';

const S = {
  segment: (theme: Theme): SerializedStyles => css`
    label: cues-timeline__segment;
    padding: 0 ${theme.spacing.m} 0 0;
    ${theme.mq.lg} {
      padding: 0 ${theme.spacing.m} 0 ${theme.spacing.xl};
    }
    display: grid;
    grid-template-columns: 22px 1fr auto;
    margin-top: 0 !important;
    background-color: inherit;

    &:hover {
      background-color: ${theme.palette.background.default};
    }
  `,
  segmentHighlighted: (theme: Theme): SerializedStyles => css`
    background-color: ${theme.palette.background.default};
  `,
  segmentCreate: (theme: Theme): SerializedStyles => css`
    ${theme.mq.lg} {
      padding: 0 ${theme.spacing.m} 0 ${theme.spacing.m};
    }
    grid-template-columns: 1fr;
  `,
  segmentFlash: (theme: Theme): SerializedStyles => css`
    animation: blinker 3.5s ease-in-out 1;

    @keyframes blinker {
      0%,
      60% {
        background-color: ${theme.palette.background.default};
      }
      100% {
        background-color: inherit;
      }
    }
  `,
};

export { S };
