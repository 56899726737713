import type { SagaIterator } from 'redux-saga';
import type { AllEffect, ForkEffect } from 'redux-saga/effects';
import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';
import { client } from '@studio/api/api-client/client';
import { STALE_DATA_TIMEOUT } from '@studio/constants/api-constants';
import { MEDIA_METADATA_API_V1 } from '@studio/constants/env-variables';
import type { Sites } from '@studio/types';
import type { ApiClientConfig } from '@studio/types/axios';
import { selectCustomerId, selectAccessToken } from '../authentication';
import { fetch, set } from './actions';
import { selectSiteTimestamp } from './selectors';
import { ActionTypes } from './types';

export function* fetchSites(): SagaIterator {
  const timestamp: number = yield select(selectSiteTimestamp);
  if (Date.now() - timestamp < STALE_DATA_TIMEOUT) {
    yield put(fetch.sites.cancel());
    return;
  }

  const customerId: string = yield select(selectCustomerId);
  const url = `${MEDIA_METADATA_API_V1}/customers/${customerId}/sites`;
  const token: string = yield select(selectAccessToken);
  const config: ApiClientConfig = { token };
  try {
    const response: Sites.Get.Site[] = yield call(client, url, config);
    yield put(fetch.sites.success(response));
    yield put(set.timestamp(Date.now()));
  } catch (error) {
    if (error instanceof Error) {
      yield put(fetch.sites.failure(error));
    }
  }
}

export function* watchFetchSites<T>(): Generator<ForkEffect<never>, void, T> {
  yield takeLatest(ActionTypes.FETCH_REQUEST, fetchSites);
}

export function* siteSaga<T>(): Generator<AllEffect<ForkEffect<void>>, void, T> {
  yield all([fork(watchFetchSites)]);
}
