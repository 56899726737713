import type { AllEffect, ForkEffect } from 'redux-saga/effects';
import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';
import { client } from '@studio/api/api-client/client';
import { CENTRAL_API_V2 } from '@studio/constants/env-variables';
import { fetchTimezonesAsync } from './actions';
import { selectItems } from './selectors';
import type { Timezone } from './types';
import { ActionTypes } from './types';

function* fetchTimezones() {
  const timezones: Timezone[] = yield select(selectItems);

  if (timezones.length) {
    yield put({ type: ActionTypes.FETCH_REQUEST_CANCEL });
    return;
  }

  try {
    const url = `${CENTRAL_API_V2}/timezones`;
    const response: Timezone[] = yield call(client, url, {});
    yield put(fetchTimezonesAsync.success(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(fetchTimezonesAsync.failure(error));
    }
  }
}

export function* watchFetchRequest<T>(): Generator<ForkEffect<never>, void, T> {
  yield takeLatest(ActionTypes.FETCH_REQUEST, fetchTimezones);
}

export function* timezonesSaga<T>(): Generator<AllEffect<ForkEffect<void>>, void, T> {
  yield all([fork(watchFetchRequest)]);
}
