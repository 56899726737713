import { createSelector } from 'reselect';
import type { Regions } from '@studio/types';
import type { RootState } from '../root-reducer';

export const selectRegionsState = (state: RootState) => state.regions;

export const selectRegionItems = createSelector(selectRegionsState, (state): Regions.Get.Region[] => state.items);
export const selectDefaultRegion = createSelector(
  selectRegionItems,
  (regions): Regions.Get.Region =>
    regions.find((region) => region.defaultRegion) ?? {
      uuid: '1290ae83-b319-4cf4-9069-41a7296c8c2c',
      name: 'North America',
      zone: 'us',
      defaultRegion: true,
      restricted: false,
      latencyCheckUrl: 'https://stream-export.resi.io/api/v1/ping',
      hotspotsServiceUrl: 'https://hotspots.resi.io',
    }
);
