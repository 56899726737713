import axios from 'axios';
import { CENTRAL_API_V3 } from '@studio/constants/env-variables';
import type { TokenResponse } from '@studio/store/authentication/types';

const tokenUrl = `${CENTRAL_API_V3}/auth/token`;

export async function authenticate(username: string, password: string): Promise<TokenResponse | undefined> {
  const response = await axios.post(
    tokenUrl,
    { username, password, grant_type: 'password_cookie' },
    { withCredentials: true }
  );
  return response.data;
}
