import * as React from 'react';
import { Stack, FieldLabel, Text } from '@resi-media/resi-ui';
import { usePrefix } from '@studio/hooks';

const EmbedTypeFieldLabel = ({ hintPosition }: FieldLabel.Props) => {
  const { prefixNS, PrefixTrans } = usePrefix('components:', 'embedTypeFieldLabel');
  return (
    <FieldLabel
      data-testid="embed-type-field-label"
      fieldLabel={prefixNS('embedType')}
      hint={
        <Stack gap="xs">
          <div>
            <PrefixTrans i18nKey="tooltip.iFrame">
              fill
              <Text colorVariant="inherit" isInline variant="body4" weightVariant="bold">
                iFrame
              </Text>
            </PrefixTrans>
          </div>
          <div>
            <PrefixTrans i18nKey="tooltip.alternateIframe">
              fill
              <Text colorVariant="inherit" isInline variant="body4" weightVariant="bold">
                alternateIframe
              </Text>
            </PrefixTrans>
          </div>
          <div>
            <PrefixTrans i18nKey="tooltip.scriptTag">
              fill
              <Text colorVariant="inherit" isInline variant="body4" weightVariant="bold">
                scriptTag
              </Text>
            </PrefixTrans>
          </div>
        </Stack>
      }
      hintPosition={hintPosition}
    />
  );
};

export default EmbedTypeFieldLabel;
