import type { SerializedStyles, Theme } from '@emotion/react';
import { css } from '@emotion/react';

export const S = {
  container: (theme: Theme, canHeaderScroll: boolean): SerializedStyles => css`
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    height: 100%;
    width: 100%;
    background-color: ${theme.palette.background.paper};
    overflow: ${canHeaderScroll ? 'auto' : ''};
  `,
  headerContent: (_theme: Theme): SerializedStyles => {
    return css`
      width: 100%;
      max-width: 780px;
    `;
  },
  content: (_theme: Theme, canHeaderScroll: boolean, hideContentScroll: boolean): SerializedStyles => {
    return css`
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      overflow: ${canHeaderScroll ? '' : hideContentScroll ? 'hidden' : 'auto'};
    `;
  },
  body: (_theme: Theme, isBodyMaxWidth: boolean): SerializedStyles => {
    return css`
      position: relative;
      width: 100%;
      max-width: ${isBodyMaxWidth ? '' : '780px'};
    `;
  },
  footerContent: (_theme: Theme): SerializedStyles => {
    return css`
      width: 100%;
      max-width: 844px;
    `;
  },
  icon: (theme: Theme): SerializedStyles => css`
    cursor: pointer;
    color: ${theme.palette.text.primary};
    font-size: ${theme.typography.pxToRem(14)};
  `,
};
