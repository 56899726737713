import { HTTP_METHODS } from '@studio/constants/axios';
import { CENTRAL_API_V3 } from '@studio/constants/env-variables';
import type { Crosspost } from '@studio/types';

export const root = {
  [HTTP_METHODS.GET]: {
    method: HTTP_METHODS.GET,
    params: {} as { accountId: string; destinationId: string },
    response: [] as Crosspost[],
    url: `${CENTRAL_API_V3}/customers/@customerId/socialmedia/@accountId/destinations/@destinationId/crosspostdestinations`,
  },
};
