import type { PlayerProfiles } from '@studio/types';

export enum ActionTypes {
  FETCH_REQUEST = '@@playerProfiles/FETCH_REQUEST',
  FETCH_REQUEST_CANCEL = '@@playerProfiles/FETCH_REQUEST_CANCEL',
  FETCH_REQUEST_ERROR = '@@playerProfiles/FETCH_REQUEST_ERROR',
  FETCH_REQUEST_SUCCESS = '@@playerProfiles/FETCH_REQUEST_SUCCESS',
  FETCH_REQUEST_TIMESTAMP = '@@playerProfiles/FETCH_REQUEST_TIMESTAMP',
  FILTER_RESULTS = '@@playerProfiles/FILTER_RESULTS',
}

export type PlayerProfileState = {
  error: Error | null;
  filter: string;
  isFetching: boolean;
  items: PlayerProfiles.Get.ProfileListItem[];
  timestamp: number;
};
