import { produce } from 'immer';
import type { ActionType, PayloadAction } from 'typesafe-actions';
import { createReducer } from 'typesafe-actions';
import type { SystemEvents } from '@studio/types';
import { fetch } from './actions';
import type { ActionTypes, SystemEventsState } from './types';

type Action = ActionType<typeof fetch>;

export const initialState: SystemEventsState = {
  subjectEvents: {
    data: new Map<string, SystemEvents.Get.Event[]>(),
    error: null,
    hasFetched: false,
    isFetching: false,
  },
};

const reducer = createReducer<SystemEventsState, Action>(initialState)
  .handleAction(
    fetch.subjectEvents.request,
    produce((draft: SystemEventsState) => {
      draft.subjectEvents.isFetching = true;
      draft.subjectEvents.error = null;
    })
  )
  .handleAction(
    fetch.subjectEvents.success,
    produce(
      (
        draft: SystemEventsState,
        action: PayloadAction<ActionTypes.FETCH_SUBJECT_EVENTS_REQUEST_SUCCESS, SystemEvents.Derived.SubjectEvents>
      ) => {
        draft.subjectEvents.isFetching = false;
        draft.subjectEvents.data = draft.subjectEvents.data.set(action.payload.subjectId, action.payload.events);
        draft.subjectEvents.hasFetched = true;
      }
    )
  )
  .handleAction(
    fetch.subjectEvents.failure,
    produce(
      (draft: SystemEventsState, action: PayloadAction<ActionTypes.FETCH_SUBJECT_EVENTS_REQUEST_ERROR, Error>) => {
        draft.subjectEvents.isFetching = false;
        draft.subjectEvents.error = action.payload;
      }
    )
  )
  .handleAction(
    fetch.subjectEvents.cancel,
    produce((draft: SystemEventsState) => {
      draft.subjectEvents.isFetching = false;
    })
  );
export default reducer;
