import type { SerializedStyles } from '@emotion/react';
import { css } from '@emotion/react';

const brushOpacity = '0.2';
const fillOpacity = '0.4';

export const S = {
  brushOpacity,
  fillOpacity,
  tooltipLabel: (): SerializedStyles => css`
    white-space: normal;
    word-break: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  `,
};
