import type { SagaIterator } from 'redux-saga';
import type { ForkEffect, AllEffect } from 'redux-saga/effects';
import { all, put, fork, call, select, takeLatest } from 'redux-saga/effects';
import { client } from '@studio/api/api-client/client';
import { STALE_DATA_TIMEOUT } from '@studio/constants/api-constants';
import { WEBPLAYER_CONFIG } from '@studio/constants/env-variables';
import type { PlayerProfiles } from '@studio/types';
import { selectAccessToken, selectCustomerId } from '../authentication';
import { fetch, set } from './actions';
import { selectTimeStamp } from './selectors';
import { ActionTypes } from './types';

function* handleFetch(): SagaIterator {
  const timestamp: number = yield select(selectTimeStamp);
  if (Date.now() - timestamp < STALE_DATA_TIMEOUT) {
    yield put(fetch.cancel());
    return;
  }

  const token: string = yield select(selectAccessToken);
  const customerId: string = yield select(selectCustomerId);
  const url = `${WEBPLAYER_CONFIG}/customers/${customerId}/configs/list`;
  try {
    const response: PlayerProfiles.Get.ProfileListItem[] = yield call(client, url, { token });
    yield put(fetch.success(response));
    yield put(set.timestamp(Date.now()));
  } catch (error) {
    if (error instanceof Error) {
      yield put(fetch.failure(error));
    }
  }
}

export function* watchFetchRequest<T>(): Generator<ForkEffect<never>, void, T> {
  yield takeLatest(ActionTypes.FETCH_REQUEST, handleFetch);
}

export function* playerProfilesSaga<T>(): Generator<AllEffect<ForkEffect<void>>, void, T> {
  yield all([fork(watchFetchRequest)]);
}
