import { webplayerEnvAttribute, webplayerStudioParam, WEBPLAYER_SOURCE } from '@studio/constants/env-variables';
import { buildQueryString } from '@studio/helpers';
import { EmbedType, WebPlayerKind } from '../types';
import type { EmbedIdOptions, ManifestOptions, MultipleManifestsOptions, PlaylistOptions } from '../web-player';

export const scrubUrl = (urlToScrub: string): string => {
  // replaces "http://" or "https://" with just "//"
  return urlToScrub.replace(/^https?:/i, '');
};

export const scrubAndEncodeManifestUrls = (urls: MultipleManifestsOptions['manifestUrls']): string => {
  const scrubbedUrls = {
    hls: scrubUrl(urls.hls),
    dash: scrubUrl(urls.dash),
  };
  return window.btoa(JSON.stringify(scrubbedUrls));
};

type getEmbedSrcUrlFn = (options: EmbedIdOptions) => string;
export const getEmbedSrcUrl: getEmbedSrcUrlFn = ({ autoplay, embedId, iframeId, startPos, type }) => {
  const queryString = buildQueryString({
    id: embedId,
    type: type !== EmbedType.WEB_CHANNEL ? type : null,
    autoplay,
    startPos,
    studio: webplayerStudioParam,
    iframeId,
    env: webplayerEnvAttribute,
  });
  return `${WEBPLAYER_SOURCE}?${queryString}`;
};

type getManifestSrcUrlFn = (options: ManifestOptions | MultipleManifestsOptions) => string;
export const getManifestSrcUrl: getManifestSrcUrlFn = (options) => {
  const { autoplay, iframeId, kind, startPos } = options;

  const queryString = buildQueryString({
    encoded_manifest: kind !== WebPlayerKind.MANIFEST ? null : window.btoa(scrubUrl(options.manifestUrl)),
    encoded_manifests: kind !== WebPlayerKind.MANIFESTS ? null : scrubAndEncodeManifestUrls(options.manifestUrls),
    autoplay,
    startPos,
    env: webplayerEnvAttribute,
    studio: webplayerStudioParam,
    iframeId,
  });

  return `${WEBPLAYER_SOURCE}?${queryString}`;
};

export type PlaylistUrlOptions = {
  autoplay?: boolean;
  embedId: string;
  iframeId?: string;
  type?: EmbedType;
};

type getPlaylistSrcUrlFn = (options: PlaylistOptions) => string;
export const getPlaylistSrcUrl: getPlaylistSrcUrlFn = ({ autoplay, embedId, iframeId }) => {
  const queryString = buildQueryString({
    type: EmbedType.PLAYLIST,
    id: embedId,
    studio: webplayerStudioParam,
    env: webplayerEnvAttribute,
    iframeId,
    autoplay,
  });
  return `${WEBPLAYER_SOURCE}?${queryString}`;
};
