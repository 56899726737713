import type { Immutable } from 'immer';
import type { PlaylistMediaItem, WebplayerPlaylistMediaItem } from '@studio/store/playlists';
import type { Cues } from '@studio/types';
import { convertTimeStringToMs } from '../download';

export const getSortedCues = (cues: Readonly<Cues.Derived.CueWithUser[]>): Cues.Derived.CueWithUser[] => {
  return cues.slice().sort((a, b) => convertTimeStringToMs(a.position) - convertTimeStringToMs(b.position));
};

export const getUuidFromUrl = (url: string): string | null => {
  const index = url.lastIndexOf('/');
  return index === -1 ? null : url.substring(index + 1);
};

export const getWebplayerPlaylistPayload = (items: Immutable<PlaylistMediaItem[]>): WebplayerPlaylistMediaItem[] => {
  return items.map((media) => {
    return {
      title: media.title,
      description: media.description,
      encodedId: media.embedId,
      cdnManifestUrls: media.cdnManifestUrls
        ? JSON.parse(JSON.stringify(media.cdnManifestUrls))
        : media.cdnManifestUrls,
      thumbnails: media.thumbnails ? JSON.parse(JSON.stringify(media.thumbnails)) : media.thumbnails,
    };
  });
};
