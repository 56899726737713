import type { SerializedStyles, Theme } from '@emotion/react';
import { css } from '@emotion/react';

export const S = {
  image: (theme: Theme, width: number): SerializedStyles => css`
    max-height: 85px;
    width: ${width}px;
    borderradius: ${theme.shape.borderRadius.s};
    objectfit: contain;
  `,
  imageContainer: (theme: Theme, hasThumbnail: boolean): SerializedStyles => css`
    background-color: ${!hasThumbnail && theme.palette.divider};
    display: flex;
    align-items: center;
  `,
};
