import { HTTP_METHODS } from '@studio/constants/axios';
import { CENTRAL_API_V3 } from '@studio/constants/env-variables';
import type { Encoders } from '@studio/types';

export const root = {};

export const id = {
  [HTTP_METHODS.GET]: {
    url: `${CENTRAL_API_V3}/customers/@customerId/encoders/onboard/@id`,
    params: {} as { id: string },
    response: {} as Encoders.Get.Activation,
    method: HTTP_METHODS.GET,
  },
  [HTTP_METHODS.POST]: {
    url: `${CENTRAL_API_V3}/customers/@customerId/encoders/onboard/@id`,
    request: {} as Encoders.Post.Activation,
    params: {} as { id: string },
    method: HTTP_METHODS.POST,
  },
};
