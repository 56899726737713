import { HTTP_METHODS } from '@studio/constants/axios';
import { OPEN_SOURCE_BUCKET_URL } from '@studio/constants/open-source';
import type { OpenSource } from '@studio/types';

export const manifests = {
  [HTTP_METHODS.GET]: {
    url: `${OPEN_SOURCE_BUCKET_URL}/@manifestFile`,
    response: [] as OpenSource.Get.Manifest[],
    method: HTTP_METHODS.GET,
  },
};

export const dependencies = {
  [HTTP_METHODS.GET]: {
    url: `${OPEN_SOURCE_BUCKET_URL}/@dependencyFile`,
    response: {} as OpenSource.Get.Dependencies,
    method: HTTP_METHODS.GET,
  },
};
