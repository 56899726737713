import * as React from 'react';
import { sanitizeProps, useBreakpoint } from '@resi-media/resi-ui';
import { LinearGradient } from '../LinearGradient';

export const AreaChartLoading = (props: { height?: number; width?: number }) => {
  const mediaQuery = useBreakpoint();

  return (
    <svg viewBox={`0 0 ${!mediaQuery.lg ? 431 : 1243} 431`} width={props.width ?? '100%'} {...sanitizeProps(props)}>
      <LinearGradient id="area-chart-loading" />
      <defs>
        <clipPath id="area-chart-clip-path">
          <rect fill="#E8E8E8" height="1" width="1242" x="1" y="307.5" />
          <path
            d="M136.298 216.377L9 272.067V308H1243V115.858L1189.55 95.8339C1095.67 60.6661 990.742 71.5221 906.055 125.164L860.141 154.246C845.415 163.574 829.952 171.682 813.905 178.491L721.866 217.545C662.673 242.663 597.285 249.364 534.227 236.776L423.508 214.674L326.281 195.56C262.311 182.985 196.026 190.248 136.298 216.377Z"
            fill="#E8E8E8"
            stroke="#E8E8E8"
            strokeWidth="2"
          />
          <rect fill="#E8E8E8" height="322" width="1" x="9" />
          <rect fill="#E8E8E8" height="24" rx="4" width="120" y="407" />
          <rect fill="#E8E8E8" height="24" rx="4" width="120" x="152" y="407" />
        </clipPath>
      </defs>
      <rect
        clipPath="url(#area-chart-clip-path)"
        fill="url(#area-chart-loading)"
        height="100%"
        width="100%"
        x={0}
        y={0}
      />
    </svg>
  );
};

AreaChartLoading.displayName = 'AreaChartLoading';
