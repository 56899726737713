import { css } from '@emotion/react';
import { Draft } from '@resi-media/resi-ui';
import { useNavigate } from 'react-router-dom';
import UrlPaths from '@studio/constants/url-paths';
import { useIsAuthorized, usePrefix } from '@studio/hooks';
import Permissions from '@studio/store/authentication/permissions';

const GoLiveButton = ({
  dataTestId = 'go-live-button',
  sizeVariant = 'm',
}: {
  dataTestId?: string;
  sizeVariant?: Draft.ButtonBase.Size;
}) => {
  const navigate = useNavigate();
  const canCreateSchedule = useIsAuthorized([Permissions.SCHEDULES_ADD]);
  const { prefixNS } = usePrefix('pages:', 'goLive');

  return (
    canCreateSchedule && (
      <Draft.Button
        colorVariant="negative"
        css={(theme) => css`
          width: ${sizeVariant === 'm' ? '85px' : '77px'};
          border: 1px solid ${theme.palette.brand.resiRed};
          color: ${theme.palette.brand.resiRed};
        `}
        dataTestId={dataTestId}
        label={prefixNS('title')}
        onClick={() => navigate(UrlPaths.GO_LIVE)}
        sizeVariant={sizeVariant}
        variant="outlined"
      />
    )
  );
};

GoLiveButton.displayName = 'GoLiveButton';

export default GoLiveButton;
