import type { CombinedState } from 'redux';
import { createSelector } from 'reselect';
import { sortByAlphaIgnoreCase } from '@studio/helpers/sortByAlphaIgnoreCase';
import type { DestinationGroups } from '@studio/types';
import type { RootState } from '../root-reducer';
import type { DestinationGroupsState } from './types';

export const selectDestinationGroupsState = (state: RootState): CombinedState<DestinationGroupsState> =>
  state.destinationGroups;

export const selectFilter = createSelector(selectDestinationGroupsState, (state): string => state.filter);

export const selectItems = createSelector(
  selectDestinationGroupsState,
  (state): DestinationGroups.Get.DestinationGroup[] => state.items
);

export const filterItems = createSelector(
  selectItems,
  selectFilter,
  (items, filter): DestinationGroups.Get.DestinationGroup[] => {
    return items
      .filter((item) => item.name.toLowerCase().includes(filter.toLowerCase()))
      .sort(sortByAlphaIgnoreCase((group: DestinationGroups.Get.DestinationGroup) => group.name));
  }
);

export const selectTimestamp = createSelector(selectDestinationGroupsState, (state): number => state.timestamp);

export const selectSoftwareEncodersEnabled = createSelector(
  selectItems,
  (items): DestinationGroups.Get.DestinationGroup[] => {
    return items.filter((item) => item.softwareEncoderEnabled);
  }
);
export const selectIsFetching = createSelector(selectDestinationGroupsState, (state): boolean => state.isFetching);
