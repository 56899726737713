import type { Roles, Users } from '@studio/types';

export enum UserInvitesActionTypes {
  FETCH_COMPLETE = '@@userinvites/FETCH_COMPLETE',
  FETCH_REQUEST = '@@userinvites/FETCH_REQUEST',
  FETCH_REQUEST_CANCEL = '@@userinvites/FETCH_REQUEST_CANCEL',
  FETCH_REQUEST_ERROR = '@@userinvites/FETCH_REQUEST_ERROR',
  FETCH_REQUEST_SUCCESS = '@@userinvites/FETCH_REQUEST_SUCCESS',
  FETCH_REQUEST_TIMESTAMP = '@@userinvites/FETCH_REQUEST_TIMESTAMP',
}

export enum UsersActionTypes {
  FETCH_COMPLETE = '@@user/FETCH_COMPLETE',
  FETCH_REQUEST = '@@user/FETCH_REQUEST',
  FETCH_REQUEST_CANCEL = '@@user/FETCH_REQUEST_CANCEL',
  FETCH_REQUEST_ERROR = '@@user/FETCH_REQUEST_ERROR',
  FETCH_REQUEST_SUCCESS = '@@user/FETCH_REQUEST_SUCCESS',
  FETCH_REQUEST_TIMESTAMP = '@@user/FETCH_REQUEST_TIMESTAMP',
  FILTER_RESULTS = '@@user/FILTER_RESULTS',
}

export enum UserRolesActionTypes {
  FETCH_REQUEST = '@@userroles/FETCH_REQUEST',
  FETCH_REQUEST_CANCEL = '@@userroles/FETCH_REQUEST_CANCEL',
  FETCH_REQUEST_ERROR = '@@userroles/FETCH_REQUEST_ERROR',
  FETCH_REQUEST_SUCCESS = '@@userroles/FETCH_REQUEST_SUCCESS',
  FETCH_REQUEST_TIMESTAMP = '@@userroles/FETCH_REQUEST_TIMESTAMP',
}

export interface UsersState {
  error: Error | null;
  filter: string;
  invites: Users.Get.UserInvite[];
  invitesTimestamp: number;
  isFetchingInvites: boolean;
  isFetchingRoles: boolean;
  isFetchingUsers: boolean;
  roles: Roles.Get.Role[];
  rolesTimestamp: number;
  timestamp: number;
  users: Users.Get.User[];
}
