import type { Regions } from '@studio/types';

export enum ActionTypes {
  FETCH_REQUEST = '@@regions/FETCH_REQUEST',
  FETCH_REQUEST_CANCEL = '@@regions/FETCH_REQUEST_CANCEL',
  FETCH_REQUEST_ERROR = '@@regions/FETCH_REQUEST_ERROR',
  FETCH_REQUEST_SUCCESS = '@@regions/FETCH_REQUEST_SUCCESS',
}

export type RegionsState = {
  error: Error | null;
  isFetching: boolean;
  items: Regions.Get.Region[];
};
