import { root, id } from './content-destinations';
import * as idEndpoints from './id';
import { stop } from './stop';

export const contentDestinations = {
  ...root,
  id: {
    ...id,
    ...idEndpoints,
  },
  stop,
};
