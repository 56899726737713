import axios from 'axios';
import { CENTRAL_API_V3 } from '@studio/constants/env-variables';
import { logoutAsync, setTokenExpired } from '@studio/store/authentication/actions';
import type { TokenResponse } from '@studio/store/authentication/types';
import { AuthenticationActionTypes } from '@studio/store/authentication/types';

const tokenUrl = `${CENTRAL_API_V3}/auth/token`;

async function refreshAuthToken(store: Store): Promise<string> {
  try {
    const response = await axios.post<TokenResponse>(
      tokenUrl,
      { grant_type: 'refresh_token_cookie' },
      { withCredentials: true }
    );
    store.dispatch({ type: AuthenticationActionTypes.ACCESS_TOKEN_REFRESH, payload: response.data.access_token });
    return response.data.access_token;
  } catch (error) {
    store.dispatch(setTokenExpired());
    store.dispatch(logoutAsync.request());
    return Promise.reject(error);
  }
}

export { refreshAuthToken };
