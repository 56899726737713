import * as React from 'react';
import { sanitizeProps } from '@resi-media/resi-ui';
import { LinearGradient } from '../LinearGradient';

export const LineChartBrushLoading = (props: { height?: number; width?: number }) => {
  return (
    <svg viewBox="0 0 1236 70" width={props.width ?? '100%'} {...sanitizeProps(props)}>
      <LinearGradient id="line-chart-brush-loading" />
      <defs>
        <clipPath id="line-chart-brush-clip-path">
          <path
            d="M216.825 33.7831L0 54.4961V69.9323H1236V0L1028.17 4.87363L826.331 22.0421L619.998 41.5367L419.161 26.5835L216.825 33.7831Z"
            fill="#E8E8E8"
            x="0"
            y="0"
          />
        </clipPath>
      </defs>
      <rect
        clipPath="url(#line-chart-brush-clip-path)"
        fill="url(#line-chart-brush-loading)"
        height="100%"
        width="100%"
        x={0}
        y={0}
      />
    </svg>
  );
};

LineChartBrushLoading.displayName = 'LineChartBrushLoading';
