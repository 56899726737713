import type * as React from 'react';
import type { Immutable } from 'immer';
import { useIsAuthorized, useIsLaunchDarklyAuthorized } from '@studio/hooks';
import type Permissions from '@studio/store/authentication/permissions';

type _Props = {
  children: React.ReactNode;
  includesAny?: boolean;
  requiredPerms?: Immutable<Permissions[]>;
  requiredToggles?: Immutable<string[]>;
  requiredTogglesIncludesAny?: boolean;
};

const Authorized = ({
  children,
  includesAny = false,
  requiredPerms = [],
  requiredToggles = [],
  requiredTogglesIncludesAny = false,
}: _Props) => {
  const allowed = useIsAuthorized(requiredPerms, includesAny);
  const ldAllowed = useIsLaunchDarklyAuthorized(requiredToggles, requiredTogglesIncludesAny);

  return allowed && ldAllowed ? children : null;
};

Authorized.displayName = 'Authorized';

export default Authorized;
