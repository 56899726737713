import type { Duration } from 'date-fns';
import { intervalToDuration, secondsToHours } from 'date-fns';
import type { CombinedState } from 'redux';
import { createSelector } from 'reselect';
import { CHART_TAB, COUNT_STAT_KEYS, FACEBOOK_DESTINATION_TYPES, STATS_KEYS } from '@studio/constants/analytics';
import { chartTabs, contentLibraryTabs } from '@studio/pages/Analytics/analytics-config';
import type { Analytics } from '@studio/types';
import type { RootState } from '../root-reducer';
import type { AnalyticsState } from './types';

export const selectAnalyticsState = (state: RootState): CombinedState<AnalyticsState> => state.analytics;

export const selectSummaryData = createSelector(
  selectAnalyticsState,
  (state): Analytics.Get.Summary[] => state.summary.data
);

export const selectHeatMapData = createSelector(
  selectAnalyticsState,
  (state): Analytics.Derived.HeatMap[] => state.latLong.data
);

export const selectStats = createSelector(
  selectSummaryData,
  (_: unknown, isContentLibrary: boolean) => isContentLibrary,
  (_: unknown, _isContentLibrary: boolean, key: Analytics.Derived.ChartTabTypes) => key,
  (_: unknown, _isContentLibrary: boolean, _key: Analytics.Derived.ChartTabTypes, event?: Analytics.Get.AllEvents) =>
    event,
  (data, isContentLibrary, key, event) => {
    return getStatsGroupsFromResponse(key, isContentLibrary, Array.isArray(data) ? data[0] : data, event);
  }
);

// TODO: Setup these helpers so that Locale and Translation utils can be used
export function toCommas(value: string): string {
  if (value === 'null') {
    return '';
  }
  return value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export const getNormalizedDuration = (timeInSeconds: number): string => {
  if (timeInSeconds === 0) return '0s';
  // ROUND UP TO NEAREST SECOND INTEGER
  const normalizedDuration: Duration = intervalToDuration({ start: 0, end: Math.ceil(timeInSeconds) * 1000 });
  const totalDurationString = `${normalizedDuration.years ? `${normalizedDuration.years}y ` : ''}${
    normalizedDuration.days ? `${normalizedDuration.days}d ` : ''
  }${normalizedDuration.hours ? `${normalizedDuration.hours}h ` : ''}${
    normalizedDuration.minutes ? `${normalizedDuration.minutes}m ` : ''
  }${normalizedDuration.seconds ? `${Number(normalizedDuration.seconds || 0).toFixed(0)}s` : ''}`;
  /**
   * If based on totalDurationString string creation above
   * and in combination with if/else guards
   * null values could not occur unless browser didn't support regex
   **/
  /* eslint-disable @typescript-eslint/no-non-null-assertion */
  // ONLY SHOW Y, D, H
  if (normalizedDuration.years) {
    return totalDurationString.match(/^.*(y|d|h)/)![0];
  } else if (normalizedDuration.days) {
    // ONLY SHOW D, H, M
    return totalDurationString.match(/^.*(d|h|m)/)![0];
  } else {
    // ONLY SHOW H, M, S
    return totalDurationString.match(/^.*(h|m|s)/)![0];
  }
  /* eslint-enable @typescript-eslint/no-non-null-assertion */
};

const round = (num: number): number => {
  const m = Number((Math.abs(num) * 100).toPrecision(15));
  return (Math.round(m) / 100) * Math.sign(num);
};

export const formatTotalTimeWatched = (timeInSeconds: number): string => {
  if (timeInSeconds === 0) return '0s';

  if (timeInSeconds >= 3600 && timeInSeconds < 86400) {
    return getNormalizedDuration(timeInSeconds);
  }

  if (timeInSeconds >= 86400) {
    const formatter = Intl.NumberFormat('en', { notation: 'compact', maximumFractionDigits: 2 });
    return `${formatter.format(round(secondsToHours(timeInSeconds)))} hours`;
  }
  const mins = Math.trunc(Number(timeInSeconds) / 60);
  const secs = Math.trunc(Number(timeInSeconds) % 60);

  return `${mins >= 1 ? `${mins}m` : ''} ${secs}s`;
};

export const getStatsGroupsFromResponse = (
  pageKey: Analytics.Derived.ChartTabTypes,
  isContentLibrary: boolean,
  responseObject?: Analytics.Get.Summary,
  event?: Analytics.Get.AllEvents
): Analytics.Components.DataCardProps[] => {
  const isFacebook = pageKey === CHART_TAB.FACEBOOK;
  const isPageEvent = event?.destinationType === FACEBOOK_DESTINATION_TYPES.PAGE;

  const configKeys = isContentLibrary ? contentLibraryTabs.grab(pageKey).statKeys : chartTabs.grab(pageKey).statKeys;
  const keys = (): Analytics.Derived.StatsKeys[] => {
    if (!isFacebook) {
      return configKeys;
    } else if (event?.destinationType) {
      if (isPageEvent) {
        return [...configKeys, 'PEAK_CONCURRENT_LIVE_VIEWS'];
      } else {
        return ['PEAK_CONCURRENT_LIVE_VIEWS'];
      }
    }
    return configKeys;
  };
  const statsKey = keys().sort((a, b) => STATS_KEYS[a].ORDER - STATS_KEYS[b].ORDER);

  let statsGroupArray;
  if (responseObject === undefined) {
    statsGroupArray = statsKey.map((groupName: Analytics.Derived.StatsKeys) => {
      const KEYS = STATS_KEYS[groupName];
      return {
        label: KEYS.COUNT_KEY,
        count: '',
        change: 0,
        trend: 'none',
      };
    });
  } else {
    statsGroupArray = statsKey.reduce(
      (agg: Analytics.Components.DataCardProps[], groupName: Analytics.Derived.StatsKeys) => {
        const KEYS = STATS_KEYS[groupName];
        const changePercentage = responseObject[KEYS.PERCENTAGE_KEY as keyof Analytics.Get.Summary] ?? 0;
        const calculateTrend = () => {
          if (changePercentage === 0 || typeof changePercentage === 'string') {
            return 'none';
          } else if (changePercentage > 0) {
            return 'positive';
          } else {
            return 'negative';
          }
        };

        if (Object.keys(COUNT_STAT_KEYS).includes(groupName)) {
          const count = responseObject[KEYS.COUNT_KEY as keyof Analytics.Get.Summary];
          /* Show card at 0 if 0 or null */
          return [
            ...agg,
            {
              label: KEYS.COUNT_KEY,
              count: toCommas(count?.toString() ?? '0'),
              change: typeof changePercentage === 'number' ? changePercentage : -1,
              trend: calculateTrend(),
            },
          ];
        }

        const timeStampValue = responseObject[KEYS.COUNT_KEY as keyof Analytics.Get.Summary];
        const timeStamp = typeof timeStampValue === 'number' ? timeStampValue : 0;
        /* Show card at 0s if 0 or null */
        return [
          ...agg,
          {
            label: KEYS.COUNT_KEY,
            count:
              groupName === 'TOTAL_TIME_WATCHED' ? formatTotalTimeWatched(timeStamp) : getNormalizedDuration(timeStamp),
            change: typeof changePercentage === 'number' ? changePercentage : -1,
            trend: calculateTrend(),
          },
        ];
      },
      []
    );
  }
  return statsGroupArray;
};
