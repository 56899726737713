import type { AllEffect, ForkEffect } from 'redux-saga/effects';
import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';
import { client } from '@studio/api/api-client/client';
import { STALE_DATA_TIMEOUT } from '@studio/constants/api-constants';
import { CENTRAL_API_V3 } from '@studio/constants/env-variables';
import type { EncoderChannels } from '@studio/types';
import type { ApiClientConfig } from '@studio/types/axios';
import { selectAccessToken, selectCustomerId } from '../authentication';
import { fetchVenues } from '../venues/sagas';
import {
  fetchEncoderChannelsAsync,
  fetchEncoderChannelUserAsync,
  forceFetchEncoderChannelsAsync,
  setFetchRequestTimestamp,
} from './actions';
import { selectTimestamp } from './selectors';
import { ActionTypes } from './types';

function* handleFetch() {
  const timestamp: number = yield select(selectTimestamp);
  const customerId: string = yield select(selectCustomerId);
  if (Date.now() - timestamp < STALE_DATA_TIMEOUT) {
    yield put(fetchEncoderChannelsAsync.cancel());
    return;
  }

  const url = `${CENTRAL_API_V3}/customers/${customerId}/eventprofiles`;
  const token: string = yield select(selectAccessToken);
  const config: ApiClientConfig = { token };
  try {
    const response: EncoderChannels.Derived.EncoderChannel[] = yield call(client, url, config);
    yield put(fetchEncoderChannelsAsync.success(response));
    yield put(setFetchRequestTimestamp(Date.now()));
  } catch (error) {
    if (error instanceof Error) {
      yield put(fetchEncoderChannelsAsync.failure(error));
    }
  }
}

function* handleForceFetch() {
  const token: string = yield select(selectAccessToken);
  const customerId: string = yield select(selectCustomerId);
  const url = `${CENTRAL_API_V3}/customers/${customerId}/eventprofiles`;
  const config: ApiClientConfig = { token };
  try {
    const response: EncoderChannels.Derived.EncoderChannel[] = yield call(client, url, config);
    yield put(forceFetchEncoderChannelsAsync.success(response));
    yield put(setFetchRequestTimestamp(Date.now()));
  } catch (error) {
    if (error instanceof Error) {
      yield put(forceFetchEncoderChannelsAsync.failure(error));
    }
  }
}

function* handleFetchUsers(action: ReturnType<typeof fetchEncoderChannelUserAsync.request>) {
  const streamProfileId = action.payload;
  const customerId: string = yield select(selectCustomerId);
  const url = `${CENTRAL_API_V3}/customers/${customerId}/eventprofiles/${streamProfileId}/users`;
  const token: string = yield select(selectAccessToken);
  yield call(fetchVenues);
  const config: ApiClientConfig = { token };
  try {
    const response: EncoderChannels.Get.EncoderChannelUser[] = yield call(client, url, config);
    yield put(fetchEncoderChannelUserAsync.success({ streamProfileId, users: response }));
  } catch (error) {
    if (error instanceof Error) {
      yield put(fetchEncoderChannelUserAsync.failure(error));
    }
  }
}

export function* watchFetchRequest<T>(): Generator<ForkEffect<never>, void, T> {
  yield takeLatest(ActionTypes.FETCH_REQUEST, handleFetch);
}

export function* watchForceFetchRequest<T>(): Generator<ForkEffect<never>, void, T> {
  yield takeLatest(ActionTypes.FORCE_FETCH_REQUEST, handleForceFetch);
}

export function* watchFetchUsersRequest<T>(): Generator<ForkEffect<never>, void, T> {
  yield takeLatest(ActionTypes.FETCH_USERS_REQUEST, handleFetchUsers);
}

export function* encoderChannelsSaga<T>(): Generator<AllEffect<ForkEffect<void>>, void, T> {
  yield all([fork(watchFetchRequest), fork(watchForceFetchRequest), fork(watchFetchUsersRequest)]);
}
