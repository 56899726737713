import { HTTP_METHODS } from '@studio/constants/axios';
import { MEDIA_METADATA_API_V1 } from '@studio/constants/env-variables';
import type { Media } from '@studio/types';

export const root = {};

export const id = {
  [HTTP_METHODS.DELETE]: {
    url: `${MEDIA_METADATA_API_V1}/customers/@customerId/media/@id`,
    params: {} as { id: string },
    method: HTTP_METHODS.DELETE,
  },
  [HTTP_METHODS.GET]: {
    url: `${MEDIA_METADATA_API_V1}/customers/@customerId/media/@id`,
    response: {} as Media.Get.LibraryItem,
    params: {} as { id: string },
    method: HTTP_METHODS.GET,
  },
  [HTTP_METHODS.PATCH]: {
    url: `${MEDIA_METADATA_API_V1}/customers/@customerId/media/@id`,
    request: {} as Media.Patch.Media,
    params: {} as { id: string },
    method: HTTP_METHODS.PATCH,
  },
};
