import * as React from 'react';
import { useClient } from '@studio/hooks';

const LibraryVideoTitle = ({ id }: { id: string }) => {
  const { callApi: getMedia, data: video } = useClient({
    config: useClient.mediaMetadata.v1.media.id.GET,
    params: { id },
  });
  const [retryCount, setRetryCount] = React.useState(0);

  const fetchMedia = React.useCallback(async () => {
    if (retryCount > 3) {
      return;
    }
    try {
      await getMedia({}, { params: { id } });
    } catch (error) {
      console.error(error);
      setTimeout(() => {
        setRetryCount((count) => count + 1);
      }, 500);
    }
  }, [getMedia, id, retryCount]);

  React.useEffect(() => {
    fetchMedia();
  }, [fetchMedia]);

  return video?.title;
};

LibraryVideoTitle.displayName = 'LibraryVideoTitle';

export default LibraryVideoTitle;
