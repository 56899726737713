import { Draft, Text } from '@resi-media/resi-ui';
import { match } from 'ts-pattern';
import { INVALID_STATUS } from '@studio/constants/social';
import UrlPaths from '@studio/constants/url-paths';
import { usePrefix } from '@studio/hooks';

type _Props = {
  channelId?: string;
  invalidStatus?: string;
  onCloseCallback?: () => void;
  tryAgainCallback?: () => void;
};
const InvalidNotice = ({ channelId, invalidStatus, onCloseCallback, tryAgainCallback }: _Props) => {
  const { commonT, prefixNS } = usePrefix('components:', 'destinationModal');
  return match(invalidStatus)
    .with(INVALID_STATUS.NO_ACCESS, () => (
      <Draft.AlertBanner
        buttonLabel={commonT('grantAccess')}
        buttonProps={{
          ...(channelId && {
            onClick: () => {
              window.open(
                UrlPaths.SETTINGS.SOCIAL_MEDIA_VIEW_PANE.replace(':uuid', channelId),
                '_blank',
                'noopener noreferrer'
              );
              onCloseCallback?.();
            },
          }),
        }}
        dataTestId="warning__no-access"
        variant="warning"
      >
        <Text variant="body3">{prefixNS('fields.account.access')}</Text>
      </Draft.AlertBanner>
    ))
    .with(INVALID_STATUS.EXPIRED, () => (
      <Draft.AlertBanner
        buttonLabel={commonT('renew')}
        buttonProps={{
          ...(channelId && {
            onClick: () => {
              window.open(
                UrlPaths.SETTINGS.SOCIAL_MEDIA_VIEW_PANE.replace(':uuid', channelId),
                '_blank',

                'noopener noreferrer'
              );
              onCloseCallback?.();
            },
          }),
        }}
        dataTestId="warning__expired"
        variant="warning"
      >
        <Text variant="body3">{prefixNS('fields.account.renew')}</Text>
      </Draft.AlertBanner>
    ))
    .with(INVALID_STATUS.FETCH_ERROR, () => (
      <Draft.AlertBanner
        buttonLabel={commonT('tryAgain')}
        buttonProps={{
          onClick: tryAgainCallback,
        }}
        dataTestId="error__fetch-error"
      >
        <Text variant="body3">{prefixNS('fields.account.error')}</Text>
      </Draft.AlertBanner>
    ))
    .otherwise(() => null);
};

InvalidNotice.displayName = 'InvalidNotice';

export default InvalidNotice;
