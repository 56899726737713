import { HTTP_METHODS } from '@studio/constants/axios';
import { CENTRAL_API_V3 } from '@studio/constants/env-variables';
import type { Decoders } from '@studio/types';

export const root = {
  [HTTP_METHODS.GET]: {
    method: HTTP_METHODS.GET,
    params: {} as { id: string },
    response: {} as Decoders.Get.Status[],
    url: `${CENTRAL_API_V3}/customers/@customerId/users/@id/decoders`,
  },
};

export const id = {};
