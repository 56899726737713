import { HTTP_METHODS } from '@studio/constants/axios';
import { CENTRAL_API_V3 } from '@studio/constants/env-variables';
import type { ContentDestinations } from '@studio/types';

export const root = {
  [HTTP_METHODS.POST]: {
    url: `${CENTRAL_API_V3}/customers/@customerId/contentdestinations/stop`,
    request: {} as ContentDestinations.Post.Stop,
    response: [] as ContentDestinations.Get.ContentDestination[], // NOTE: can have response of list of content destinations
    method: HTTP_METHODS.POST,
  },
};
