import type { AxiosResponse } from 'axios';
import { HTTP_METHODS } from '@studio/constants/axios';
import { CENTRAL_API_V1 } from '@studio/constants/env-variables';
import type { Hardware } from '@studio/types';

export const root = {
  [HTTP_METHODS.GET]: {
    method: HTTP_METHODS.GET,
    query: {} as {
      buildDateEnd?: string;
      buildDateStart?: string;
      locationId?: string;
      modelId?: string;
      page?: number;
      serial?: string;
      size?: number;
      sort?: string;
      sortDirection?: 'asc' | 'desc';
      statusId?: string;
      type?: string;
      vendorSaleInvoice?: string;
    },
    response: [] as Hardware.Get.HardwareUnit[],
    url: `${CENTRAL_API_V1}/hardwareunits`,
  },
  [HTTP_METHODS.POST]: {
    url: `${CENTRAL_API_V1}/hardwareunits`,
    request: {} as Hardware.Post.Decoder,
    response: {} as AxiosResponse,
    method: HTTP_METHODS.POST,
  },
};

export const id = {
  [HTTP_METHODS.GET]: {
    url: `${CENTRAL_API_V1}/hardwareunits/@id`,
    response: {} as Hardware.Get.HardwareUnit,
    params: {} as { id: string },
    method: HTTP_METHODS.GET,
  },
  [HTTP_METHODS.DELETE]: {
    method: HTTP_METHODS.DELETE,
    params: {} as { id: string },
    url: `${CENTRAL_API_V1}/hardwareunits/@id`,
  },
  [HTTP_METHODS.PATCH]: {
    url: `${CENTRAL_API_V1}/hardwareunits/@id`,
    params: {} as { id: string },
    request: {} as Hardware.Patch.V1.Hardware,
    method: HTTP_METHODS.PATCH,
  },
};
