import * as idEndpoints from './id';
import { root, id } from './media';

export const media = {
  ...root,
  id: {
    ...id,
    ...idEndpoints,
  },
};
