import * as React from 'react';
import type { SerializedStyles } from '@emotion/react';
import type { Options } from '@popperjs/core';
import { IconButton, More, useForkRef } from '@resi-media/resi-ui';
import type { MergeFirst, Draft } from '@resi-media/resi-ui';
import { useKeyDown } from '@studio/hooks';
import { PopupMenu } from '../PopupMenu';

type _Props = {
  buttonDataTestId?: string;
  children?: React.ReactNode;
  customCss?: SerializedStyles | string;
  dataTestId?: string;
  isAnchorVisible?: boolean;
  onOpen?: () => void;
  placement?: Options['placement'];
  sizeVariant?: Draft.ButtonBase.Size;
  variant?: IconButton.ColorVariant;
};

type _Ref = MergeFirst<
  HTMLButtonElement,
  {
    forceClose: () => void;
  }
>;

/* eslint-disable import/export */
export const ActionMenu = React.forwardRef<_Ref, _Props>(
  (
    {
      buttonDataTestId,
      children,
      customCss,
      dataTestId,
      isAnchorVisible = false,
      onOpen,
      placement = 'bottom-start',
      sizeVariant = 's',
      variant = 'primary',
    },
    ref
  ) => {
    const anchorRef = React.useRef<HTMLButtonElement>({} as HTMLButtonElement);
    const buttonRef = useForkRef(ref, anchorRef);
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const open = Boolean(anchorEl);
    const handleClick = (
      event: React.KeyboardEvent<HTMLButtonElement> | React.MouseEvent<HTMLButtonElement, MouseEvent>
    ): void => {
      if (event.currentTarget === anchorRef.current) {
        event.stopPropagation();
      }
      if (!anchorEl) {
        onOpen?.();
      }
      setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    /** Customize instance ref */
    React.useImperativeHandle(
      ref,
      () => ({
        ...anchorRef.current,
        forceClose: () => setAnchorEl(null),
      }),
      [anchorRef]
    );

    const handleKeyDown = useKeyDown(['Enter', ' '], handleClick);

    React.useEffect(() => {
      if (!isAnchorVisible) {
        anchorRef.current.blur();
        setAnchorEl(null);
      }
    }, [isAnchorVisible]);

    return (
      <>
        <IconButton
          ref={buttonRef}
          colorVariant={variant}
          css={customCss}
          data-icon-button=""
          dataTestId={buttonDataTestId ?? 'ellipsis-button'}
          isToggled={open}
          label="More"
          onClick={handleClick}
          onKeyDown={handleKeyDown}
          sizeVariant={sizeVariant}
        >
          <More />
        </IconButton>
        <PopupMenu
          anchorEl={anchorEl}
          closeOnClick
          dataTestId={dataTestId}
          isOpen={open}
          onClose={() => {
            setAnchorEl(null);
          }}
          placement={placement}
        >
          {children}
        </PopupMenu>
      </>
    );
  }
);

ActionMenu.displayName = 'ActionMenu';

// eslint-disable-next-line no-redeclare
export namespace ActionMenu {
  export type Props = _Props;
  export type Ref = _Ref;
}
