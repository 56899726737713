import * as React from 'react';
import type { SerializedStyles, Theme } from '@emotion/react';
import { css } from '@emotion/react';

const S = {
  container: (theme: Theme): SerializedStyles => {
    return css`
      padding: ${theme.spacing.xl} ${theme.spacing.xl} 0 ${theme.spacing.xl};
    `;
  },
};

type _Props = {
  children?: React.ReactNode;
  dataTestId?: string;
};

const ContentBanner = ({ children, dataTestId }: _Props) => {
  return (
    <div css={S.container} data-testid={dataTestId ?? 'content-banner'}>
      {children}
    </div>
  );
};

ContentBanner.displayName = 'ContentBanner';

export default ContentBanner;
