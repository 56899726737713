import * as React from 'react';

const useResizeObserver = (): [
  React.Dispatch<React.SetStateAction<HTMLDivElement | null>>,
  ResizeObserverEntry | undefined,
] => {
  const [observerEntry, setObserverEntry] = React.useState<ResizeObserverEntry>();
  const [node, setNode] = React.useState<HTMLDivElement | null>(null);
  const observer = React.useRef<ResizeObserver | null>(null);

  const observe = React.useCallback(() => {
    observer.current = new ResizeObserver((entries) => setObserverEntry(entries[0]));

    if (node) {
      observer.current.observe(node);
    }
  }, [node]);

  React.useEffect(() => {
    /* istanbul ignore next */
    return () => observer.current?.disconnect();
  }, []);

  React.useLayoutEffect(() => {
    observe();
  }, [observe]);

  return [setNode, observerEntry];
};

export default useResizeObserver;
