import type { SagaIterator } from 'redux-saga';
import type { AllEffect, ForkEffect } from 'redux-saga/effects';
import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';
import { client } from '@studio/api/api-client/client';
import { STALE_DATA_TIMEOUT } from '@studio/constants/api-constants';
import { CENTRAL_API_V1 } from '@studio/constants/env-variables';
import type { Hardware } from '@studio/types';
import type { ApiClientConfig } from '@studio/types/axios';
import { selectAccessToken } from '../../authentication';
import { fetch, set } from './actions';
import {
  selectCyclesTimestamp,
  selectLocationsTimestamp,
  selectModelsTimestamp,
  selectStatusTimestamp,
} from './selectors';
import { ActionTypes } from './types';

export function* fetchHardwareLocations(): SagaIterator {
  const timestamp: number = yield select(selectLocationsTimestamp);
  if (Date.now() - timestamp < STALE_DATA_TIMEOUT) {
    yield put(fetch.locations.cancel());
    return;
  }

  const url = `${CENTRAL_API_V1}/hardwarelocations`;
  const token: string = yield select(selectAccessToken);
  const config: ApiClientConfig = { token };
  try {
    const response: Hardware.Get.HardwareLocation[] = yield call(client, url, config);
    yield put(fetch.locations.success(response));
    yield put(set.locationsTimestamp(Date.now()));
  } catch (error) {
    if (error instanceof Error) {
      yield put(fetch.locations.failure(error));
    }
  }
}

export function* watchFetchHardwareLocations<T>(): Generator<ForkEffect<never>, void, T> {
  yield takeLatest(ActionTypes.FETCH_LOCATIONS_REQUEST, fetchHardwareLocations);
}

export function* fetchHardwareModels(): SagaIterator {
  const timestamp: number = yield select(selectModelsTimestamp);
  if (Date.now() - timestamp < STALE_DATA_TIMEOUT) {
    yield put(fetch.models.cancel());
    return;
  }

  const url = `${CENTRAL_API_V1}/hardwaremodels`;
  const token: string = yield select(selectAccessToken);
  const config: ApiClientConfig = { token };
  try {
    const response: Hardware.Get.HardwareModel[] = yield call(client, url, config);
    yield put(fetch.models.success(response));
    yield put(set.modelsTimestamp(Date.now()));
  } catch (error) {
    if (error instanceof Error) {
      yield put(fetch.models.failure(error));
    }
  }
}

export function* watchFetchHardwareModels<T>(): Generator<ForkEffect<never>, void, T> {
  yield takeLatest(ActionTypes.FETCH_MODELS_REQUEST, fetchHardwareModels);
}

export function* fetchHardwareStatuses(): SagaIterator {
  const timestamp: number = yield select(selectStatusTimestamp);
  if (Date.now() - timestamp < STALE_DATA_TIMEOUT) {
    yield put(fetch.status.cancel());
    return;
  }

  const url = `${CENTRAL_API_V1}/hardwarestatuses`;
  const token: string = yield select(selectAccessToken);
  const config: ApiClientConfig = { token };
  try {
    const response: Hardware.Get.HardwareStatus[] = yield call(client, url, config);
    yield put(fetch.status.success(response));
    yield put(set.statusTimestamp(Date.now()));
  } catch (error) {
    if (error instanceof Error) {
      yield put(fetch.status.failure(error));
    }
  }
}

export function* watchFetchHardwareStatuses<T>(): Generator<ForkEffect<never>, void, T> {
  yield takeLatest(ActionTypes.FETCH_STATUS_REQUEST, fetchHardwareStatuses);
}

export function* fetchHardwareCycles(): SagaIterator {
  const timestamp: number = yield select(selectCyclesTimestamp);
  if (Date.now() - timestamp < STALE_DATA_TIMEOUT) {
    yield put(fetch.cycles.cancel());
    return;
  }

  const url = `${CENTRAL_API_V1}/hardwarecycles`;
  const token: string = yield select(selectAccessToken);
  const config: ApiClientConfig = { token };
  try {
    const response: Hardware.Get.HardwareCycles[] = yield call(client, url, config);
    yield put(fetch.cycles.success(response));
    yield put(set.cyclesTimestamp(Date.now()));
  } catch (error) {
    if (error instanceof Error) {
      yield put(fetch.cycles.failure(error));
    }
  }
}

export function* watchFetchHardwareCycles<T>(): Generator<ForkEffect<never>, void, T> {
  yield takeLatest(ActionTypes.FETCH_CYCLES_REQUEST, fetchHardwareCycles);
}

export function* hardwareSaga<T>(): Generator<AllEffect<ForkEffect<void>>, void, T> {
  yield all([
    fork(watchFetchHardwareLocations),
    fork(watchFetchHardwareModels),
    fork(watchFetchHardwareStatuses),
    fork(watchFetchHardwareCycles),
  ]);
}
