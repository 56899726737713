import React from 'react';
import { useTheme } from '@emotion/react';
import type { MergeElementProps, MergeFirst } from '@resi-media/resi-ui';
import {
  Draft,
  Inline,
  isNumber,
  isString,
  mergeDefaultProps,
  sanitizeProps,
  Stack,
  Text,
  useBreakpoint,
} from '@resi-media/resi-ui';
import { Brush } from '@visx/brush';
import type BaseBrush from '@visx/brush/lib/BaseBrush';
import type { Bounds } from '@visx/brush/lib/types';
import * as curve from '@visx/curve';
import { LegendOrdinal, LegendLabel } from '@visx/legend';
import { scaleTime, scaleLinear, scaleOrdinal } from '@visx/scale';
import { AreaClosed, LinePath } from '@visx/shape';
import { defaultStyles } from '@visx/tooltip';
import { AreaSeries, AreaStack, LineSeries, Axis, Grid as VisxGrid, Tooltip, XYChart } from '@visx/xychart';
import * as d3 from 'd3-time';
import { COLOR_VARIANTS } from '@studio/constants/analytics';
import { formatDateToSlashes, convertCamelToTitleCase } from '@studio/helpers';
import { useChartColors, usePrefix } from '@studio/hooks';
import { AreaChartBrushLoading } from './area-chart-brush-loading';
import { AreaChartLoading } from './area-chart-loading';
import { LineChartBrushLoading } from './line-chart-brush-loading';
import { LineChartLoading } from './line-chart-loading';
import { S } from './styles';

const brushMargin = { top: 10, bottom: 15, left: 50, right: 40 };
const defaultMargin = { top: 40, left: 50, right: 40, bottom: 60 };
const TOOLTIP_LEFT_OFFSET = 8;
const Y_TICK_BREAKPOINT = 280;

type _OptionTypeBase = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
};

type _Variant = 'area' | 'line';

type _TimeWindow = 'day' | 'minute' | 'month' | 'week';

type _Props<OptionType extends _OptionTypeBase> = MergeElementProps<
  'div',
  {
    colorVariants?: string[];
    data?: OptionType[];
    dataTestId?: string;
    height: number;
    isLoading?: boolean;
    legendFormat?: (val: string) => string;
    margin?: typeof defaultMargin;
    showBrush?: boolean;
    showGridBackground?: boolean;
    showLegend?: boolean;
    showTooltip?: boolean;
    showXAxis?: boolean;
    showYAxis?: boolean;
    showYDecimal?: boolean;
    timeWindow?: _TimeWindow;
    tooltipFormat?: (val: string) => string;
    totalLegendLabel?: () => {
      isAbbreviated: boolean;
      text: string;
      total: number;
    } | null;
    totalNode?: React.ReactNode;
    uniti18n?: string;
    variant?: _Variant;
    width: number;
    xAxisKey?: keyof OptionType;
    xAxisTickFormat?: (val: Date) => string;
    xTooltipNode?: string;
    yAxisTickFormat?: (val: string) => string;
  }
>;

const defaultProps = {
  colorVariants: COLOR_VARIANTS,
  margin: defaultMargin,
  variant: 'area' as _Variant,
  xAxisKey: 'label' as keyof _OptionTypeBase,
  showGridBackground: true,
  showLegend: true,
  showTooltip: true,
  showXAxis: true,
  showYAxis: true,
  showYDecimal: true,
};

type _InternalProps<OptionType extends _OptionTypeBase> = MergeFirst<_Props<OptionType>, typeof defaultProps>;

const AreaLineChartInternal = <OptionType extends _OptionTypeBase>(
  props: _Props<OptionType>,
  ref: React.ForwardedRef<HTMLDivElement>
) => {
  const propsInternal: _InternalProps<OptionType> = mergeDefaultProps(props, defaultProps);
  const {
    colorVariants,
    data: dataProp,
    dataTestId,
    height,
    isLoading,
    legendFormat,
    margin,
    showBrush,
    showGridBackground,
    showLegend,
    showTooltip,
    showXAxis,
    showYAxis,
    showYDecimal,
    timeWindow,
    tooltipFormat,
    totalLegendLabel,
    totalNode: totalNodeProp,
    uniti18n,
    variant,
    width,
    xAxisKey,
    xAxisTickFormat,
    xTooltipNode,
    yAxisTickFormat,
    ...rest
  } = propsInternal;
  const { commonT, prefixNS } = usePrefix('pages:', 'analytics');
  const totalNode = totalNodeProp ?? commonT('total');
  const theme = useTheme();
  const utils = Draft.useUtils<Date>();
  const brushRef = React.useRef<BaseBrush | null>(null);
  const mediaQuery = useBreakpoint();
  const legendTotals = totalLegendLabel?.();

  const normalizeSingleValues = React.useCallback(
    (datum: OptionType[]) => {
      const dateToNormalize = isString(datum[0][xAxisKey])
        ? /** Must be converted for Safari */
          formatDateToSlashes(datum[0][xAxisKey])
        : datum[0][xAxisKey];
      return [
        {
          ...datum[0],
          [xAxisKey]: utils.startOfDay(new Date(dateToNormalize)),
        },
        {
          ...datum[0],
          [xAxisKey]: utils.addSeconds(utils.startOfDay(new Date(dateToNormalize)), 1),
        },
      ];
    },
    [utils, xAxisKey]
  );
  const isSingleValue = dataProp?.length === 1;
  const data = React.useMemo(
    () =>
      isSingleValue
        ? normalizeSingleValues(dataProp)
        : (dataProp?.map((d) => {
            const date = new Date(
              /** Must be converted for Safari */
              isString(d[xAxisKey]) ? formatDateToSlashes(d[xAxisKey]) : d[xAxisKey]
            );

            return {
              ...d,
              [xAxisKey]: timeWindow === 'minute' ? date : utils.startOfDay(date),
            };
          }) ?? []),
    [dataProp, isSingleValue, normalizeSingleValues, timeWindow, utils, xAxisKey]
  );
  const [filteredData, setFilteredData] = React.useState(data);

  React.useEffect(() => {
    setFilteredData(data);
  }, [data]);

  const brushStyles = {
    fill: theme.palette.text.disabled,
    fillOpacity: S.brushOpacity,
    stroke: theme.palette.text.primary,
    strokeWidth: '2',
  };

  const keys = React.useMemo(() => {
    return Object.keys(data[0] || []).filter((k) => k !== xAxisKey);
  }, [data, xAxisKey]);

  const colorScale = scaleOrdinal<keyof OptionType, string>({
    domain: keys,
    range: useChartColors(keys),
  });
  const maxValues = data.reduce((allMaxValues: number[], currentValue) => {
    const totalCount = keys.reduce((total: number, k) => {
      if (variant === 'area') {
        return Math.max(total, Number(currentValue[k]));
      }
      total += Number(currentValue[k]);
      return total;
    }, 0);
    allMaxValues.push(totalCount);
    return allMaxValues;
  }, []);
  const maxValue = Math.max(...maxValues);
  const getMaxYTicks = () => {
    if (height <= Y_TICK_BREAKPOINT) {
      return showYDecimal ? 5 : Math.min(5, maxValue);
    } else if (maxValue >= 1 && maxValue < 9) {
      return maxValue;
    }
    return 9;
  };
  const maxYTicks = getMaxYTicks();
  const isCompactNumberFormat = maxValue >= 10000;

  const innerHeight = height - margin.top - margin.bottom;
  const topChartBottomMargin = 10;
  const topChartHeight = 0.8 * innerHeight - topChartBottomMargin;
  const bottomChartHeight = innerHeight - topChartHeight;

  // accessors
  const getX = React.useCallback((d: _OptionTypeBase) => new Date(d[xAxisKey]), [xAxisKey]);
  const getY = React.useCallback(
    (d: _OptionTypeBase, yKey?: string) => {
      return d[yKey ?? keys[0]];
    },
    [keys]
  );

  // bounds
  const xMax = Math.max(width - margin.left - margin.right, 0);
  const xBrushMax = Math.max(width - brushMargin.left - brushMargin.right, 0);
  const yBrushMax = Math.max(bottomChartHeight - brushMargin.top - brushMargin.bottom, 0);

  // scales
  const xScale = React.useMemo(
    () =>
      scaleTime<number>({
        range: [0, width],
        domain: [filteredData[0]?.[xAxisKey], filteredData[filteredData.length - 1]?.[xAxisKey]],
        clamp: true,
        nice: true,
      }),
    [filteredData, width, xAxisKey]
  );

  const brushXScale = React.useMemo(
    () =>
      scaleTime<number>({
        range: [0, xBrushMax],
        domain: [data[0]?.[xAxisKey], data[data.length - 1]?.[xAxisKey]],
      }),
    [data, xAxisKey, xBrushMax]
  );

  const brushYScale = React.useMemo(
    () =>
      scaleLinear({
        range: [yBrushMax, 0],
        // .01 adds space between brush selection rect and brush chart
        domain: [0, isLoading ? 5 : maxValue + 0.01],
        nice: true,
      }),
    [isLoading, maxValue, yBrushMax]
  );

  const onBrushChange = (domain: Bounds | null) => {
    if (!domain || isSingleValue) return;

    const { x0, x1, y0, y1 } = domain;
    const dataCopy = data.reduce(
      (agg: { closest?: number; closestData: OptionType[]; data: OptionType[] }, d) => {
        const x = getX(d).getTime();
        const y = getY(d);
        const xDiff = Math.abs(utils.getDiff(new Date(x0), new Date(x), 'seconds'));

        if (x > x0 && x < x1 && y > y0 && y < y1) {
          agg.data = agg.data.concat(d);
        }

        if (!agg.closest || xDiff < agg.closest) {
          agg.closest = xDiff;
          agg.closestData = [d];
        }

        return agg;
      },
      { data: [], closestData: [data[0]], closest: undefined }
    );
    /** Use closest value if none fall in selection so graph isn't null */
    const newDataCopy = dataCopy.data.length ? dataCopy.data : dataCopy.closestData;
    const sanitizedDataCopy = newDataCopy.length === 1 ? normalizeSingleValues(newDataCopy) : newDataCopy;

    setFilteredData(sanitizedDataCopy);
  };

  const renderBrushChart = () => {
    const brushToRender = (
      <Brush
        brushDirection="horizontal"
        handleSize={20}
        height={yBrushMax}
        innerRef={brushRef}
        onChange={onBrushChange}
        onClick={() => setFilteredData(data)}
        resetOnEnd={isSingleValue}
        resizeTriggerAreas={['left', 'right']}
        selectedBoxStyle={brushStyles}
        useWindowMoveEvents
        width={xBrushMax}
        xScale={brushXScale}
        yScale={brushYScale}
      />
    );

    if (variant === 'area') {
      return (
        <svg
          data-testid="area-chart-brush"
          height={yBrushMax}
          style={{
            position: 'relative',
            left: margin.left,
            marginTop: theme.spacing.m,
            overflow: 'visible',
          }}
          viewBox={`0 0 ${xMax} ${yBrushMax}`}
          width={xMax}
        >
          <rect fill={theme.palette.background.table} height={yBrushMax} width={xBrushMax} />
          <AreaClosed<OptionType>
            curve={curve.curveMonotoneX}
            data={data}
            fill={theme.palette.text.disabled}
            stroke={theme.palette.text.disabled}
            strokeWidth={1}
            x={(d) => Math.max(0, brushXScale(getX(d)) || 0)}
            y={(_d, i) => {
              return brushYScale(maxValues[i]) || 0;
            }}
            yScale={brushYScale}
          />
          {brushToRender}
        </svg>
      );
    }

    return (
      <svg
        data-testid="line-chart-brush"
        height={yBrushMax}
        style={{ position: 'relative', left: margin.left, marginTop: theme.spacing.m, overflow: 'visible' }}
        viewBox={`0 0 ${xMax} ${yBrushMax}`}
        width={xMax}
      >
        <rect fill={theme.palette.background.table} height={yBrushMax} width={xBrushMax} />
        {keys.map((datum) => {
          return (
            <LinePath
              key={`line-brush-${datum}`}
              curve={curve.curveMonotoneX}
              data={data}
              stroke={colorScale(datum)}
              strokeWidth={2}
              x={(d) => {
                return brushXScale(getX(d)) || 0;
              }}
              y={(d) => {
                return brushYScale(getY(d, datum)) || 0;
              }}
            />
          );
        })}
        {brushToRender}
      </svg>
    );
  };

  const d3Function = React.useMemo(() => {
    switch (timeWindow) {
      case 'week':
        return d3.timeSunday;
      case 'month':
        return d3.timeMonth;
      case 'minute':
        return d3.timeMinute;
      default:
        return d3.timeDay;
    }
  }, [timeWindow]);

  const responsiveTickValue = React.useMemo(() => {
    if (mediaQuery.xxl) {
      return 9;
    }
    if (mediaQuery.xl) {
      return 7;
    }
    if (mediaQuery.lg) {
      return 5;
    }

    return 3;
  }, [mediaQuery.lg, mediaQuery.xl, mediaQuery.xxl]);

  const responsiveTickValues = React.useMemo(() => {
    // NOTE: prevents the expensive internal recursive function from firing when graph won't render anyways under 10px wide
    if (filteredData.length <= 2 || !width) {
      return filteredData.map((e) => e[xAxisKey]);
    }

    const findInterval = (num: number): { found: boolean; interval: number } => {
      if (num === 1) return { interval: 1, found: false };
      const length = filteredData.length - 1;

      if (length % num === 0) {
        return { interval: length / num, found: true };
      }

      return findInterval(num - 1);
    };
    const foundInterval = findInterval(responsiveTickValue);

    /** Because of the brush, we remove the end date for the possibility of prime numbers */
    return filteredData.length <= responsiveTickValue
      ? xScale.ticks(d3Function)
      : [
          ...d3Function.range(
            filteredData[0][xAxisKey],
            filteredData[filteredData.length - 1][xAxisKey],
            foundInterval.found ? foundInterval.interval : Math.floor(filteredData.length / responsiveTickValue)
          ),
          ...(foundInterval.found ? [filteredData[filteredData.length - 1][xAxisKey]] : []),
        ];
  }, [d3Function, filteredData, responsiveTickValue, width, xAxisKey, xScale]);

  const formatViewers = (viewers: number) =>
    isCompactNumberFormat
      ? Intl.NumberFormat('en', { notation: 'compact', maximumFractionDigits: 2 }).format(viewers)
      : viewers;

  const handleXTickFormat = (d: Date, isTooltip?: boolean): string => {
    const isMonth = timeWindow === 'month';
    if (xAxisTickFormat) {
      return xAxisTickFormat(d);
    } else if (timeWindow === 'minute') {
      return `${utils.format(d, 'shortDate')} ${utils.format(d, 'fullTime24h')}`;
    } else if (timeWindow === 'week') {
      return `${utils.format(d, 'shortDate')} - ${utils.format(utils.endOfWeek(d), 'dayOfMonth')}`;
    } else if (isMonth && isTooltip) {
      return `${utils.format(d, 'shortDate')} - ${utils.format(utils.endOfMonth(d), 'dayOfMonth')} ${utils.format(
        d,
        'year'
      )}`;
    } else if (isMonth) {
      return `${utils.format(d, 'monthShort')} ${utils.format(d, 'year')}`;
    }
    return utils.format(d, 'shortDate');
  };

  const numberOfDataSeries = data.length ? Object.keys(data[0]).filter((e) => e !== xAxisKey) : [];
  const isWrapped = window.innerWidth / 2 - 8 > 256 && numberOfDataSeries.length > 5;

  const tooltipStyles = {
    ...defaultStyles,
    borderRadius: theme.shape.borderRadius.s,
    backgroundColor: theme.palette.background.tooltip,
    color: theme.palette.background.default,
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)',
    padding: theme.spacing.m,
    width: isWrapped ? `${mediaQuery.lg ? '320px' : '256px'}` : '160px',
  };

  const renderGraph = () => {
    return width < 10 ? null : (
      <div ref={ref} data-testid={dataTestId} style={{ position: 'relative' }} {...sanitizeProps(rest)}>
        <XYChart
          height={height}
          margin={margin}
          width={width}
          xScale={{
            type: 'time',
            domain: [filteredData[0]?.[xAxisKey], filteredData[filteredData.length - 1]?.[xAxisKey]],
            clamp: true,
          }}
          yScale={{ type: 'linear' }}
        >
          {showGridBackground && (
            <VisxGrid columns={false} lineStyle={{ stroke: theme.palette.divider }} numTicks={getMaxYTicks()} rows />
          )}
          {variant === 'area' ? (
            <AreaStack curve={curve.curveMonotoneX} offset="none" renderLine>
              {keys.map((datum) => (
                <AreaSeries
                  key={`area-${datum}`}
                  data={filteredData}
                  dataKey={datum}
                  fill={colorScale(datum)}
                  fillOpacity={S.fillOpacity}
                  lineProps={{ stroke: colorScale(datum) }}
                  xAccessor={(d) => d[xAxisKey]}
                  yAccessor={(d) => d[datum]}
                />
              ))}
            </AreaStack>
          ) : (
            keys.map((datum) => (
              <LineSeries
                key={`line-${datum}`}
                curve={curve.curveMonotoneX}
                data={filteredData}
                dataKey={datum}
                stroke={colorScale(datum)}
                xAccessor={(d) => d[xAxisKey]}
                yAccessor={(d) => d[datum]}
              />
            ))
          )}
          {showYAxis && (
            <Axis
              numTicks={maxYTicks}
              orientation="left"
              stroke={theme.palette.divider}
              tickFormat={(value) => {
                const isFractionalFormat = maxValue <= 1;
                if (yAxisTickFormat?.(value)) {
                  return yAxisTickFormat(value);
                } else if (isCompactNumberFormat) {
                  const compactStr = Intl.NumberFormat('en', { notation: 'compact', maximumFractionDigits: 2 });
                  return compactStr.format(value);
                } else if (isFractionalFormat && showYDecimal) {
                  if (value === 0) {
                    return `${value}`;
                  }
                  return `${Number(value).toFixed(2)}`;
                } else {
                  return `${Math.trunc(Number(value))}`;
                }
              }}
              tickLabelProps={() => ({
                'data-testid': 'y-axis-tick',
                dx: '-8px',
                fontFamily: theme.typography.fontFamilyBody,
                fontSize: theme.typography.body3.fontSize,
                lineHeight: 1,
                verticalAnchor: 'middle',
                textAnchor: 'end' as const,
                fill: theme.palette.text.primary,
              })}
              tickStroke={theme.palette.divider}
            />
          )}
          {showXAxis && (
            <Axis
              hideTicks
              orientation="bottom"
              stroke={theme.palette.divider}
              tickComponent={({ formattedValue, ...tickProps }) => {
                const splitVal = formattedValue?.split(' ');
                const date = `${splitVal?.[0]} ${splitVal?.[1] ?? ''}`;
                const time = splitVal?.[2];
                return (
                  <svg data-testid="x-axis-tick">
                    <text {...tickProps} x={isSingleValue ? '50%' : tickProps.x}>
                      {timeWindow === 'minute' ? (
                        <>
                          <tspan data-testid={time ? `x-axis-${date} ${time}` : `x-axis-${date}`} dy="24px">
                            {date}
                          </tspan>
                          <tspan dx="-40px" dy="16px">
                            {time}
                          </tspan>
                        </>
                      ) : (
                        <tspan data-testid={`x-axis-${formattedValue}`} dy="16px">
                          {formattedValue}
                        </tspan>
                      )}
                    </text>
                  </svg>
                );
              }}
              tickFormat={(d: Date) => handleXTickFormat(d)}
              tickLabelProps={() => ({
                fill: theme.palette.text.primary,
                fontFamily: theme.typography.fontFamilyBody,
                fontSize: theme.typography.body3.fontSize,
                textAnchor: 'middle',
                verticalAnchor: 'start',
              })}
              tickStroke={theme.palette.divider}
              tickValues={responsiveTickValues}
            />
          )}
          {showTooltip && (
            <Tooltip<OptionType>
              key={Math.random()}
              detectBounds
              offsetLeft={TOOLTIP_LEFT_OFFSET}
              renderTooltip={({ tooltipData }) => {
                const nearestDatum = tooltipData?.nearestDatum;
                const nearestDate = handleXTickFormat(nearestDatum?.datum[xAxisKey], true);

                const totalViewers = nearestDatum?.datum
                  ? Object.values(nearestDatum.datum).reduce((acc, cur) => {
                      if (isNumber(cur)) {
                        return acc + cur;
                      }
                      return acc;
                    }, 0)
                  : 0;

                const tooltipKeys = tooltipData ? Object.keys(tooltipData.datumByKey) : [];
                const datum = tooltipKeys.length ? tooltipData?.datumByKey[tooltipKeys[0]].datum : '';
                const sortedTooltips = datum
                  ? Object.keys(datum)
                      .filter((e) => e !== xAxisKey)
                      .sort((a, b) => datum[b] - datum[a])
                  : [];

                return (
                  <div data-testid="tooltip">
                    <Stack gap="m">
                      <Text as="div" colorVariant="inherit" variant="body4" weightVariant="bold">
                        <Stack gap="unset">
                          {uniti18n && (
                            <Text colorVariant="inherit" variant="body4" weightVariant="bold">
                              {prefixNS(uniti18n)}
                            </Text>
                          )}
                          <Text colorVariant="inherit" dataTestId="tooltip__date" variant="body4" weightVariant="bold">
                            {nearestDate}
                          </Text>
                          {xTooltipNode && (
                            <Text colorVariant="inherit" variant="body4">
                              {xTooltipNode}
                            </Text>
                          )}
                        </Stack>
                      </Text>
                      <Stack
                        gap="unset"
                        style={{
                          justifyContent: 'center',
                          alignItems: 'start',
                          display: 'grid',
                          gridAutoFlow: 'column',
                          gridTemplateColumns: `repeat(${isWrapped ? 2 : 1}, 1fr)`,
                          // NOTE: make each grid item's height equal to 3 lines of text to support truncated labels
                          gridTemplateRows: `repeat(${isWrapped ? 5 : sortedTooltips.length},
                    fit-content(calc(3 * ${theme.typography.body4.lineHeight} * ${theme.typography.body4.fontSize})))`,
                          columnGap: theme.spacing.m,
                          rowGap: theme.spacing.s,
                        }}
                      >
                        {sortedTooltips.map((key) => {
                          const viewers = tooltipData?.datumByKey[key].datum[key];
                          return (
                            key !== xAxisKey && (
                              <div key={key}>
                                <Inline alignItems="center" gap="xs">
                                  <svg height={12} style={{ flexShrink: 0 }} width={12}>
                                    <circle
                                      cx={6}
                                      cy={6}
                                      fill={colorScale(key)}
                                      r={5.5}
                                      stroke={theme.palette.common.white}
                                      strokeWidth="1"
                                    />
                                  </svg>
                                  <Text
                                    colorVariant="inherit"
                                    css={S.tooltipLabel}
                                    variant="body4"
                                    weightVariant="bold"
                                  >
                                    {tooltipFormat ? tooltipFormat(key) : convertCamelToTitleCase(key)}
                                  </Text>
                                </Inline>
                                {isString(key) && (
                                  <Text colorVariant="inherit" variant="body4">
                                    {yAxisTickFormat
                                      ? yAxisTickFormat(viewers)
                                      : formatViewers(viewers).toLocaleString()}
                                  </Text>
                                )}
                              </div>
                            )
                          );
                        })}
                      </Stack>
                      {keys.length > 1 && (
                        <div>
                          {isString(totalNode) ? (
                            <Text as="div" colorVariant="inherit" variant="body4" weightVariant="bold">
                              {totalNode}
                            </Text>
                          ) : (
                            totalNode
                          )}
                          <Text as="div" colorVariant="inherit" variant="body4">
                            {yAxisTickFormat
                              ? yAxisTickFormat(totalViewers)
                              : formatViewers(totalViewers).toLocaleString()}
                          </Text>
                        </div>
                      )}
                    </Stack>
                  </div>
                );
              }}
              showVerticalCrosshair
              snapTooltipToDatumX
              style={tooltipStyles}
              verticalCrosshairStyle={{ stroke: theme.palette.text.title }}
            />
          )}
        </XYChart>
        {showLegend && (
          <LegendOrdinal direction="row" scale={colorScale}>
            {(labels) => (
              <Inline
                dataTestId="legend-container"
                flexWrap="wrap"
                gap={`${theme.spacing.l} ${theme.spacing.xl}`}
                style={{
                  padding: `${theme.spacing.s} ${theme.typography.pxToRem(margin.left)}`,
                }}
                w="auto"
              >
                {labels.map((label, i) => (
                  <Draft.Tooltip
                    content={<React.Fragment>{legendTotals?.total}</React.Fragment>}
                    style={{ display: legendTotals?.isAbbreviated ? 'initial' : 'none' }}
                  >
                    <Inline key={`legend-quantile-${i}`} alignItems="center">
                      <svg height={12} width={12}>
                        <circle cx={6} cy={6} fill={colorScale(label.datum)} r={6} />
                      </svg>
                      <LegendLabel align="left" margin="0 0 0 4px">
                        <Text dataTestId={`legend-text-${i}`} variant="body2">
                          {legendFormat ? legendFormat(label.text) : convertCamelToTitleCase(label.text)}
                          {legendTotals ? <span>{' ' + legendTotals.text}</span> : null}
                        </Text>
                      </LegendLabel>
                    </Inline>
                  </Draft.Tooltip>
                ))}
              </Inline>
            )}
          </LegendOrdinal>
        )}
        {showBrush && (
          <React.Fragment>
            {renderBrushChart()}
            <Text style={{ marginLeft: margin.left, marginTop: theme.spacing.s }} variant="body4">
              {prefixNS('brushHint')}
            </Text>
          </React.Fragment>
        )}
      </div>
    );
  };

  return isLoading ? (
    <div
      ref={ref}
      data-testid={dataTestId}
      style={{
        ...(mediaQuery.lg && { height }),
        width,
        display: 'flex',
        flexFlow: 'column nowrap',
        gap: theme.spacing.m,
      }}
      {...sanitizeProps(rest)}
    >
      {variant === 'area' ? (
        <>
          <AreaChartLoading
            data-testid="area-chart-loading-svg"
            {...(mediaQuery.lg && { height: 0.84 * height })}
            width={width}
          />
          {showBrush && <AreaChartBrushLoading data-testid="area-chart-brush-loading-svg" width={width} />}
        </>
      ) : (
        <>
          <LineChartLoading
            data-testid="line-chart-loading-svg"
            {...(mediaQuery.lg && { height: 0.84 * height })}
            width={width}
          />
          {showBrush && <LineChartBrushLoading data-testid="line-chart-brush-loading-svg" width={width} />}
        </>
      )}
    </div>
  ) : (
    renderGraph()
  );
};

AreaLineChartInternal.displayName = 'AreaLineChart';

/* eslint-disable import/export */
export const AreaLineChart = React.forwardRef(AreaLineChartInternal) as <T extends _OptionTypeBase>(
  props: _Props<T> & { ref?: React.ForwardedRef<HTMLDivElement> }
) => ReturnType<typeof AreaLineChartInternal>;

// eslint-disable-next-line no-redeclare
export namespace AreaLineChart {
  export type InternalProps = _InternalProps<_OptionTypeBase>;
  export type OptionTypeBase = _OptionTypeBase;
  export type Props = _Props<_OptionTypeBase>;
}
