import type { CombinedState } from 'redux';
import { createSelector } from 'reselect';
import type { Venues } from '@studio/types';
import { select } from '../monitors/selectors';
import type { RootState } from '../root-reducer';
import type { VenueState } from './types';

export const selectVenueState = (state: RootState): CombinedState<VenueState> => state.venues;

export const selectVenueLicenses = createSelector(selectVenueState, (state): number => state.venueLicenses);

export const selectVenueFilter = createSelector(selectVenueState, (state): string => state.filter);

export const selectVenueLogins = createSelector(selectVenueState, (state): Venues.Derived.Response[] => state.items);

export const filterVenueLogins = createSelector(
  selectVenueLogins,
  selectVenueFilter,
  (items, filter): Venues.Derived.Response[] => {
    return items
      .filter((item) => item.userName.toLowerCase().includes(filter.toLowerCase()))
      .sort((a, b) => a.userName.localeCompare(b.userName));
  }
);

export const combineVenueMonitor = createSelector(filterVenueLogins, select.decoders.byUser, (logins, monitors) => {
  logins.forEach((login, idx) => {
    logins[idx] = {
      ...login,
      decoder: {
        ...login.decoder,
        ...monitors?.[login.userName]?.[0],
      },
    };
  });

  return logins;
});

export const selectVenueTimestamp = createSelector(selectVenueState, (state): number => state.timestamp);
