import i18next from 'i18next';
import * as Yup from 'yup';

type _FormValidationParams = {
  isYupObject?: boolean;
};

const useFormValidation = (
  params: _FormValidationParams = { isYupObject: true }
): // eslint-disable-next-line @typescript-eslint/no-explicit-any
Yup.ObjectSchema<any, any, any, any> | any => {
  const { isYupObject } = params;
  const schema = {
    password: Yup.string()
      .required(() => i18next.t('common:errors.password.pleaseEnterPassword'))
      .min(10, () => i18next.t('common:errors.password.min'))
      .max(128, () => i18next.t('common:errors.password.max')),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], () => i18next.t('common:errors.password.passwordsMustMatch'))
      .required(() => i18next.t('common:errors.password.pleaseConfirmPassword')),
    passwordStrength: Yup.number().when('password', {
      is: (password: string) => Boolean(password),
      then: Yup.number().test('password-strength-test', (value, { createError, path, resolve }) => {
        const error = resolve<string>(Yup.ref('passwordError'));
        if (!value || value < 3) {
          return createError({
            path,
            message: error
              ? i18next.t(`components:confirmPassword.errors.${error}`)
              : i18next.t('common:errors.password.pleaseChooseStrongerPassword'),
          });
        }
        return true;
      }),
      otherwise: Yup.number(),
    }),
  };

  if (isYupObject) {
    return Yup.object({ ...schema });
  }
  return schema;
};

export default useFormValidation;
