import { subMinutes } from 'date-fns';
import type { SagaIterator } from 'redux-saga';
import type { AllEffect, ForkEffect } from 'redux-saga/effects';
import { all, put, fork, call, cancelled, select, takeLatest } from 'redux-saga/effects';
import { client } from '@studio/api/api-client/client';
import { CENTRAL_API_V3 } from '@studio/constants/env-variables';
import type { Decoders, Encoders, Monitors } from '@studio/types';
import type { ApiClientConfig } from '@studio/types/axios';
import { selectAccessToken, selectCustomerId } from '../authentication';
import { fetch } from './actions';
import { ActionTypes } from './types';

function* handleFetch(): SagaIterator {
  const source = new AbortController();
  const token: string = yield select(selectAccessToken);
  const customerId: string = yield select(selectCustomerId);

  const after = subMinutes(new Date(), 5).toISOString();
  const url = `${CENTRAL_API_V3}/customers/${customerId}/monitors`;
  const config: ApiClientConfig = { token, params: { after } };

  try {
    const response: Monitors.Get.Response = yield call(client, url, config);

    yield put(fetch.all.success(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(fetch.all.failure(error));
    }
  } finally {
    if (yield cancelled()) {
      source.abort('cancelled');
      yield put(fetch.all.cancel());
    }
  }
}

export function* watchFetchRequest<T>(): Generator<ForkEffect<never>, void, T> {
  yield takeLatest(ActionTypes.FETCH_REQUEST, handleFetch);
}

function* handleFetchEncoders(): SagaIterator {
  const source = new AbortController();
  const token: string = yield select(selectAccessToken);
  const customerId: string = yield select(selectCustomerId);

  const url = `${CENTRAL_API_V3}/customers/${customerId}/monitors/encoders`;
  // Limit exists as a param but doesn't perform as expected currently
  const config: ApiClientConfig = { token, params: { sorted: true } };

  try {
    const response: Encoders.Get.V2.Status[] = yield call(client, url, config);

    yield put(fetch.encoders.success(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(fetch.encoders.failure(error));
    }
  } finally {
    if (yield cancelled()) {
      source.abort('cancelled');
      yield put(fetch.encoders.cancel());
    }
  }
}

export function* watchFetchEncodersRequest<T>(): Generator<ForkEffect<never>, void, T> {
  yield takeLatest(ActionTypes.FETCH_ENCODERS_REQUEST, handleFetchEncoders);
}

function* handleFetchDecoders(): SagaIterator {
  const source = new AbortController();
  const token: string = yield select(selectAccessToken);
  const customerId: string = yield select(selectCustomerId);

  const after = subMinutes(new Date(), 5).toISOString();
  const url = `${CENTRAL_API_V3}/customers/${customerId}/monitors/decoders`;
  // Limit exists as a param but doesn't perform as expected currently
  const config: ApiClientConfig = {
    token,
    params: { after, sorted: true },
  };

  try {
    const response: Decoders.Get.Status[] = yield call(client, url, config);

    yield put(fetch.decoders.success(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(fetch.decoders.failure(error));
    }
  } finally {
    if (yield cancelled()) {
      source.abort('cancelled');
      yield put(fetch.decoders.cancel());
    }
  }
}

export function* watchFetchDecodersRequest<T>(): Generator<ForkEffect<never>, void, T> {
  yield takeLatest(ActionTypes.FETCH_DECODERS_REQUEST, handleFetchDecoders);
}

export function* monitorSaga<T>(): Generator<AllEffect<ForkEffect<void>>, void, T> {
  yield all([fork(watchFetchDecodersRequest), fork(watchFetchEncodersRequest), fork(watchFetchRequest)]);
}
