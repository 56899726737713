import { HTTP_METHODS } from '@studio/constants/axios';
import { CENTRAL_API_V2 } from '@studio/constants/env-variables';
import type { Venues } from '@studio/types';

export const root = {
  [HTTP_METHODS.GET]: {
    url: `${CENTRAL_API_V2}/userplans`,
    response: [] as Venues.Get.UserPlan[],
    method: HTTP_METHODS.GET,
  },
};

export const id = {};
