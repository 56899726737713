import * as React from 'react';
import { css, useTheme } from '@emotion/react';
import { Bookmark } from '@resi-media/resi-ui';
import S from './styles';

type _Props = {
  isHighlighted: boolean;
  showBottomLine: boolean;
  showBottomLineHighlighted?: boolean;
  showTopLine?: boolean;
};

const TimelineSegmentInternal = (
  { isHighlighted, showBottomLine, showBottomLineHighlighted = false, showTopLine = false }: _Props,
  forwardedRef: React.ForwardedRef<HTMLDivElement>
) => {
  const theme = useTheme();

  return (
    <div
      ref={forwardedRef}
      css={css`
        margin: auto;
        height: 100%;
        overflow: hidden;
      `}
      data-testid="timeline-segment"
    >
      <div
        css={S.lineContainer}
        data-testid="timeline-segment__line--top"
        style={{ visibility: showTopLine ? 'visible' : 'hidden' }}
      >
        <div css={() => S.lineTop(theme, isHighlighted)} />
      </div>

      <div css={() => S.iconContainer(theme, isHighlighted)} data-testid="timeline-segment__icon">
        <Bookmark style={{ fontSize: 10, color: theme.palette.background.paper }} />
      </div>
      {showBottomLine && (
        <div
          css={[
            S.lineContainer,
            css`
              height: 100%;
            `,
          ]}
          data-testid="timeline-segment__line--bottom"
        >
          <div css={() => S.lineBottom(theme, showBottomLineHighlighted)} />
        </div>
      )}
    </div>
  );
};

TimelineSegmentInternal.displayName = 'TimelineSegmentInternal';

/* eslint-disable import/export */
export const TimelineSegment = Object.assign(React.forwardRef(TimelineSegmentInternal), {
  displayName: 'TimelineSegment',
});

// eslint-disable-next-line no-redeclare
export namespace TimelineSegment {
  export type Props = _Props;
}
