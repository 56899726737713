import { produce } from 'immer';
import type { ActionType } from 'typesafe-actions';
import { createReducer } from 'typesafe-actions';
import { clearData, eventAnalytics, fetch, setIsCsvEnabled } from './actions';
import type { AnalyticsState } from './types';

type Action = ActionType<typeof clearData | typeof eventAnalytics | typeof fetch | typeof setIsCsvEnabled>;

export const initialState: AnalyticsState = {
  eventAnalytics: {
    error: null,
    isFetching: false,
    data: [],
  },
  export: {
    error: null,
    isEnabled: false,
    isFetching: false,
    data: [],
  },
  latLong: {
    error: null,
    isFetching: false,
    data: [],
  },
  summary: {
    error: null,
    isFetching: false,
    data: [],
  },
};

const reducer = createReducer<AnalyticsState, Action>(initialState)
  .handleAction(
    eventAnalytics.request,
    produce((draft: AnalyticsState) => {
      draft.eventAnalytics.isFetching = true;
      draft.eventAnalytics.error = null;
    })
  )
  .handleAction(
    eventAnalytics.success,
    produce((draft: AnalyticsState, action: ActionType<typeof eventAnalytics.success>) => {
      draft.eventAnalytics.isFetching = false;
      draft.eventAnalytics.data = action.payload;
    })
  )
  .handleAction(
    eventAnalytics.failure,
    produce((draft: AnalyticsState, action: ActionType<typeof eventAnalytics.failure>) => {
      draft.eventAnalytics.isFetching = false;
      draft.eventAnalytics.error = action.payload;
    })
  )
  .handleAction(
    fetch.export.request,
    produce((draft: AnalyticsState) => {
      draft.export.isFetching = true;
      draft.export.error = null;
    })
  )
  .handleAction(
    fetch.export.success,
    produce((draft: AnalyticsState, action: ActionType<typeof fetch.export.success>) => {
      draft.export.isFetching = false;
      draft.export.data = action.payload;
    })
  )
  .handleAction(
    fetch.export.failure,
    produce((draft: AnalyticsState, action: ActionType<typeof fetch.export.failure>) => {
      draft.export.isFetching = false;
      draft.export.error = action.payload;
    })
  )
  .handleAction(
    fetch.export.cancel,
    produce((draft: AnalyticsState) => {
      draft.export.isFetching = false;
    })
  )
  .handleAction(
    setIsCsvEnabled,
    produce((draft: AnalyticsState, action: ActionType<typeof setIsCsvEnabled>) => {
      draft.export.isEnabled = action.payload;
    })
  )
  .handleAction(
    [fetch.contentLibraryLatLong.request, fetch.latLong.request],
    produce((draft: AnalyticsState) => {
      draft.latLong.isFetching = true;
      draft.latLong.error = null;
    })
  )
  .handleAction(
    [fetch.contentLibraryLatLong.success, fetch.latLong.success],
    produce((draft: AnalyticsState, action: ActionType<typeof fetch.contentLibraryLatLong.success>) => {
      draft.latLong.isFetching = false;
      draft.latLong.data = action.payload;
    })
  )
  .handleAction(
    [fetch.contentLibraryLatLong.failure, fetch.latLong.failure],
    produce(
      (
        draft: AnalyticsState,
        action: ActionType<typeof fetch.contentLibraryLatLong.failure | typeof fetch.latLong.failure>
      ) => {
        draft.latLong.isFetching = false;
        draft.latLong.error = action.payload;
      }
    )
  )
  .handleAction(
    [fetch.contentLibraryLatLong.cancel, fetch.latLong.cancel],
    produce((draft: AnalyticsState) => {
      draft.latLong.isFetching = false;
    })
  )
  .handleAction(
    fetch.summary.request,
    produce((draft: AnalyticsState) => {
      draft.summary.isFetching = true;
      draft.summary.error = null;
    })
  )
  .handleAction(
    fetch.summary.success,
    produce((draft: AnalyticsState, action: ActionType<typeof fetch.summary.success>) => {
      draft.summary.isFetching = false;
      draft.summary.data = action.payload;
    })
  )
  .handleAction(
    fetch.summary.failure,
    produce((draft: AnalyticsState, action: ActionType<typeof fetch.summary.failure>) => {
      draft.summary.isFetching = false;
      draft.summary.error = action.payload;
    })
  )
  .handleAction(
    clearData,
    produce((draft: AnalyticsState, action: ActionType<typeof clearData>) => {
      draft[action.payload].data = [];
    })
  );

export default reducer;
