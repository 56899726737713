import type { SagaIterator } from 'redux-saga';
import type { AllEffect, ForkEffect } from 'redux-saga/effects';
import { all, call, cancelled, fork, put, select, takeLatest } from 'redux-saga/effects';
import { client } from '@studio/api/api-client/client';
import { CENTRAL_API_V3 } from '@studio/constants/env-variables';
import type { ContentDestinations } from '@studio/types';
import type { ApiClientConfig } from '@studio/types/axios';
import { selectAccessToken, selectCustomerId } from '../authentication/selectors';
import { fetchContentDestinationsAsync, fetchTransitioningContentDestinationsAsync } from './actions';
import { ActionTypes } from './types';

function* handleFetch(): SagaIterator {
  const source = new AbortController();
  const token: string = yield select(selectAccessToken);
  const customerId: string = yield select(selectCustomerId);

  const config: ApiClientConfig = {
    token,
  };

  const url = `${CENTRAL_API_V3}/customers/${customerId}/contentdestinations?startRequested=true`;

  try {
    const response: ContentDestinations.Get.ContentDestination[] = yield call(client, url, config);
    yield put(fetchContentDestinationsAsync.success(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(fetchContentDestinationsAsync.failure(error));
    }
  } finally {
    if (yield cancelled()) {
      source.abort('cancelled');
      yield put(fetchContentDestinationsAsync.cancel());
    }
  }
}

// fetches updated info only for the web videos that are "transitioning" from one state to another; payload.items contains the list of id's that need updating
function* handleFetchTransitioningOnly(
  action: ReturnType<typeof fetchTransitioningContentDestinationsAsync.request>
): SagaIterator {
  const source = new AbortController();
  const customerId: string = yield select(selectCustomerId);
  const baseUrl = `${CENTRAL_API_V3}/customers/${customerId}/contentdestinations`;
  const token: string = yield select(selectAccessToken);
  const config: ApiClientConfig = { token };
  const updatedItems = [];

  try {
    if (action.payload.items) {
      for (const contentDestinationId of action.payload.items) {
        const mediaUrl = `${baseUrl}/${contentDestinationId}`;
        const response: ContentDestinations.Get.ContentDestination = yield call(client, mediaUrl, config);
        updatedItems.push(response);
      }
    }
    yield put(fetchTransitioningContentDestinationsAsync.success(updatedItems));
  } catch (error) {
    if (error instanceof Error) {
      yield put(fetchTransitioningContentDestinationsAsync.failure(error));
    }
  } finally {
    if (yield cancelled()) {
      source.abort('cancelled');
      yield put(fetchTransitioningContentDestinationsAsync.cancel());
    }
  }
}

export function* watchFetchRequest<T>(): Generator<ForkEffect<never>, void, T> {
  yield takeLatest(ActionTypes.FETCH_REQUEST, handleFetch);
}

export function* watchFetchRequestTransitioningOnly<T>(): Generator<ForkEffect<never>, void, T> {
  yield takeLatest(ActionTypes.FETCH_REQUEST_TRANSITIONING_ONLY, handleFetchTransitioningOnly);
}

export function* contentDestinationSaga<T>(): Generator<AllEffect<ForkEffect<void>>, void, T> {
  yield all([fork(watchFetchRequest), fork(watchFetchRequestTransitioningOnly)]);
}
