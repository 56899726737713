import { HTTP_METHODS } from '@studio/constants/axios';
import { ONBOARDING_API_V1 } from '@studio/constants/env-variables';

export const root = {
  [HTTP_METHODS.PUT]: {
    url: `${ONBOARDING_API_V1}/subscriptions/@customerId/salesforce/cancel-trial`,
    query: {} as { masterEcg: string; planId: string },
    method: HTTP_METHODS.PUT,
  },
};

export const id = {};
