import * as React from 'react';
import { css } from '@emotion/react';
import { DownAlt, Inline, Link, Stack, UpAlt } from '@resi-media/resi-ui';

type _Props = {
  children: React.ReactNode;
  dataTestId?: string;
  labelWhenHidden: string;
  labelWhenVisible: string;
};
const CollapsibleSection = ({ children, dataTestId, labelWhenHidden, labelWhenVisible }: _Props) => {
  const [isVisible, setIsVisible] = React.useState(false);

  return (
    <Stack dataTestId={dataTestId} gap="l">
      <Link<'button'>
        css={(theme) => css`
          max-width: ${theme.sizes.x60};
        `}
        data-testid="show-hide-button"
        onClick={() => setIsVisible(!isVisible)}
        role="button"
      >
        <Inline alignItems="center" dataTestId="link-label" gap="m">
          {isVisible ? labelWhenVisible : labelWhenHidden}
          {isVisible ? <UpAlt /> : <DownAlt />}
        </Inline>
      </Link>
      {isVisible && children}
    </Stack>
  );
};

CollapsibleSection.displayName = 'CollapsibleSection';

export default CollapsibleSection;
