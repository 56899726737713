import { HTTP_METHODS } from '@studio/constants/axios';
import { MARATHON_API_V1 } from '@studio/constants/env-variables';

export const root = {};

export const id = {
  [HTTP_METHODS.POST]: {
    url: `${MARATHON_API_V1}/automation/@customerId/update/@id`,
    params: {} as { id: string },
    method: HTTP_METHODS.POST,
  },
};
