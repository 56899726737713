import * as React from 'react';
import { RedoOutlined } from '@ant-design/icons';
import { useTheme } from '@emotion/react';
import type { MergeElementProps, MergeFirst } from '@resi-media/resi-ui';
import { Draft, mergeDefaultProps, ErrorAlt, Inline, Stack, Text } from '@resi-media/resi-ui';
import { usePrefix } from '@studio/hooks';

type _OptionTypeBase = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
};

type _Props<OptionType extends _OptionTypeBase> = MergeElementProps<
  typeof Draft.CardSection,
  {
    data?: OptionType[];
    hasError?: boolean;
    isLoading?: boolean;
    noDataDescription?: string;
    onRetry?: () => void;
    stackProps?: Partial<Stack.Props>;
  }
>;

const defaultProps = {};

type _InternalProps<OptionType extends _OptionTypeBase> = MergeFirst<_Props<OptionType>, typeof defaultProps>;

const CardBodyInternal = <OptionType extends _OptionTypeBase>(
  props: _Props<OptionType>,
  ref: React.ForwardedRef<HTMLDivElement>
) => {
  const propsInternal: _InternalProps<OptionType> = mergeDefaultProps(props, defaultProps);
  const {
    children,
    data,
    dataTestId = 'card-body-container',
    hasError,
    isLoading,
    noDataDescription,
    onRetry,
    stackProps,
    ...rest
  } = propsInternal;
  const { commonT, prefixNS } = usePrefix('pages:', 'analytics');
  const { prefixNS: cardNS } = usePrefix('components:', 'card');
  const theme = useTheme();

  return (
    <Draft.CardSection ref={ref} data-testid={dataTestId} {...rest}>
      <Stack
        alignItems="center"
        gap="m"
        justifyContent="center"
        style={{
          ...((!data?.length || hasError || isLoading) && { backgroundColor: theme.palette.background.table }),
          minHeight: '280px',
          width: '100%',
          padding: hasError || !data?.length ? theme.spacing.m : '0',
        }}
        {...stackProps}
      >
        {hasError && !isLoading && (
          <>
            <Inline alignItems="center">
              <ErrorAlt style={{ color: theme.palette.negative.main, fontSize: theme.typography.pxToRem(18) }} />
              <Text colorVariant="heading" dataTestId="card-body-error-title" variant="body1">
                {cardNS('errorTitle')}
              </Text>
            </Inline>
            <Text colorVariant="secondary" textAlign="center" variant="body2">
              {cardNS('errorDescription')}
            </Text>
            <Draft.Button
              dataTestId="retry-button"
              label={commonT('retry')}
              onClick={onRetry}
              sizeVariant="m"
              startNode={<RedoOutlined rotate={-90} />}
              style={{ marginTop: theme.spacing.xl }}
              variant="outlined"
            />
          </>
        )}
        {!data?.length && !isLoading && !hasError && (
          <>
            <Text
              colorVariant="heading"
              dataTestId="card-body-no-data-title"
              textAlign="center"
              variant="body1"
              weightVariant="semiBold"
            >
              {prefixNS('cardBody.noDataTitle')}
            </Text>
            <Text
              colorVariant="secondary"
              dataTestId="card-body-no-data-description"
              textAlign="center"
              variant="body2"
            >
              {noDataDescription ? noDataDescription : prefixNS('cardBody.noDataDescription')}
            </Text>
          </>
        )}
        {(isLoading || Boolean(data?.length)) && !hasError && children}
      </Stack>
    </Draft.CardSection>
  );
};

CardBodyInternal.displayName = 'CardBody';

export const CardBody = React.forwardRef(CardBodyInternal);
