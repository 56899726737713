import { createAction, createAsyncAction } from 'typesafe-actions';
import type { Contact, Organization, SearchBy } from './types';
import { ActionTypes } from './types';

export const fetch = {
  orgs: createAsyncAction(
    ActionTypes.FETCH_REQUEST_ORGS,
    ActionTypes.FETCH_REQUEST_ORGS_SUCCESS,
    ActionTypes.FETCH_REQUEST_ORGS_ERROR,
    ActionTypes.FETCH_REQUEST_ORGS_CANCEL
  )<string, Organization[], Error, undefined>(),
  contacts: createAsyncAction(
    ActionTypes.FETCH_REQUEST_CONTACTS,
    ActionTypes.FETCH_REQUEST_CONTACTS_SUCCESS,
    ActionTypes.FETCH_REQUEST_CONTACTS_ERROR,
    ActionTypes.FETCH_REQUEST_CONTACTS_CANCEL
  )<string, Contact[], Error, undefined>(),
};

export const set = {
  filter: createAction(ActionTypes.FILTER_RESULTS)<string>(),
  searchBy: createAction(ActionTypes.SEARCH_BY)<SearchBy>(),
};

export const clear = createAction(ActionTypes.CLEAR_RESULTS)<undefined>();
