import * as React from 'react';
import { Stack, Inline, Draft } from '@resi-media/resi-ui';
import { webplayerEnvAttribute, WEBPLAYER_SOURCE } from '@studio/constants/env-variables';
import { buildQueryString } from '@studio/helpers';
import { usePrefix } from '@studio/hooks';
import CopyToClipboard from '../CopyToClipboard/copy-to-clipboard';
import { EmbedType } from '../WebPlayer/types';

type _Props = {
  dashUrl?: string;
  embedId: string;
  hlsUrl?: string;
  isPlayerProfileInBackgroundMode?: boolean;
  playerProfileEmbedId?: string;
  startPos?: string;
  type: EmbedType | null;
};

export enum EMBED_EVENTS {
  IFRAME,
  ALT_IFRAME,
  SCRIPT_TAG,
  AUDIO_ONLY,
  STREAM_URL,
}

const EmbedOptions = ({
  dashUrl,
  embedId,
  hlsUrl,
  isPlayerProfileInBackgroundMode,
  playerProfileEmbedId,
  startPos,
  type,
}: _Props) => {
  const { commonT, prefixNS } = usePrefix('pages:', 'webChannels');
  const [selectedOption, setSelectedOption] = React.useState<number>(EMBED_EVENTS.IFRAME);
  const decodedEmbedId = decodeURIComponent(embedId);
  React.useEffect(() => {
    if (type === null && selectedOption === EMBED_EVENTS.STREAM_URL) {
      setSelectedOption(EMBED_EVENTS.IFRAME);
    }
  }, [selectedOption, type]);

  const getScriptTagEmbedQuery = () => {
    if (!playerProfileEmbedId) return '';
    return isPlayerProfileInBackgroundMode
      ? 'data-background="true"'
      : `data-player-profile-id=${playerProfileEmbedId}`;
  };

  const appendAutoplayIfNoPlayerProfile = (attributeStr: string): string => {
    if (playerProfileEmbedId) {
      return attributeStr;
    }
    return `${attributeStr} data-autoplay="false"`;
  };

  // expiry videos are Web_Broadcast
  const getQueryParamsByEmbedType = (): Record<string, string | null> => {
    if (type === EmbedType.LIBRARY) {
      return { type: EmbedType.LIBRARY, autoplay: playerProfileEmbedId ? null : 'false' };
    } else if (type === EmbedType.WEB_BROADCAST) {
      return { type: EmbedType.WEB_BROADCAST };
    } else if (type === EmbedType.PLAYLIST) {
      return { type: EmbedType.PLAYLIST, autoplay: playerProfileEmbedId ? null : 'false' };
    } else {
      return {}; // web channel
    }
  };

  const getHtmlAttributes = () => {
    if (type === EmbedType.LIBRARY) {
      return appendAutoplayIfNoPlayerProfile('data-type="library"');
    } else if (type === EmbedType.WEB_BROADCAST) {
      return 'data-type="event"';
    } else if (type === EmbedType.PLAYLIST) {
      return appendAutoplayIfNoPlayerProfile('data-type="playlist"');
    } else {
      return ''; // web channel
    }
  };

  const getEmbedCode = () => {
    return `<div style="position:relative;overflow:hidden;padding-top:56.25%;">
  <iframe allow="autoplay; fullscreen" allowfullscreen="true" class="resi-video-frame" 
    src="${WEBPLAYER_SOURCE}?${buildQueryString({
      id: decodedEmbedId,
      playerProfileId: isPlayerProfileInBackgroundMode ? null : playerProfileEmbedId,
      background: isPlayerProfileInBackgroundMode ? 'true' : null,
      ...getQueryParamsByEmbedType(),
      startPos,
      env: webplayerEnvAttribute,
    })}" style="position:absolute;top:0;left:0;width:100%;height:100%;border:none;">
  </iframe>
</div>
<script defer type="text/javascript" src="https://control.resi.io/webplayer/page.min.js"></script>
`;
  };

  const getAudioOnlyEmbedCode = () => {
    return `<div id="resi-video-player" ${getScriptTagEmbedQuery()} data-audio-only="${true}" ${
      startPos ? `data-start-pos="${startPos}"` : ''
    } data-embed-id="${embedId}" ${getHtmlAttributes()} ${
      webplayerEnvAttribute ? `data-env="${webplayerEnvAttribute}"` : ''
    }></div>
  <script defer type="text/javascript" src="https://control.resi.io/webplayer/page.min.js"></script>
  <script type="application/javascript" src="https://control.resi.io/webplayer/loader.min.js"></script>`;
  };

  const getAltEmbedCode = () => {
    return `<iframe allow="autoplay; fullscreen" allowfullscreen="true" class="resi-video-frame" 
      src="${WEBPLAYER_SOURCE}?${buildQueryString({
        id: decodedEmbedId,
        playerProfileId: isPlayerProfileInBackgroundMode ? null : playerProfileEmbedId,
        background: isPlayerProfileInBackgroundMode ? 'true' : null,
        ...getQueryParamsByEmbedType(),
        startPos,
        env: webplayerEnvAttribute,
      })}" style="width:100%;height:100%;border:none;">
    </iframe>
    <script defer type="text/javascript" src="https://control.resi.io/webplayer/page.min.js"></script>
    `;
  };

  const getScriptEmbedCode = () => {
    return `<div id="resi-video-player" ${getScriptTagEmbedQuery()} ${
      startPos ? `data-start-pos="${startPos}"` : ''
    } data-embed-id="${embedId}" ${getHtmlAttributes()} ${
      webplayerEnvAttribute ? `data-env="${webplayerEnvAttribute}"` : ''
    }></div>
  <script defer type="text/javascript" src="https://control.resi.io/webplayer/page.min.js"></script>
  <script type="application/javascript" src="https://control.resi.io/webplayer/loader.min.js"></script>`;
  };

  React.useEffect(() => {
    if (selectedOption === EMBED_EVENTS.AUDIO_ONLY && isPlayerProfileInBackgroundMode) {
      setSelectedOption(EMBED_EVENTS.IFRAME);
    }
  }, [selectedOption, setSelectedOption, isPlayerProfileInBackgroundMode]);

  return (
    <div data-testid="embed-options" style={{ minHeight: '15rem' }}>
      <Stack gap="m">
        <Draft.CardIconGroup index={selectedOption} onChange={(index: number) => setSelectedOption(index)}>
          <Draft.CardIconButton data-testid="iframe-button" id="iframe" label={prefixNS('iFrame')} />
          <Draft.CardIconButton data-testid="alt-iframe-button" id="alt-iframe" label={prefixNS('altIframe')} />
          <Draft.CardIconButton data-testid="script-tag-button" id="script-tag" label={prefixNS('scriptTag')} />
          <Draft.CardIconButton
            data-testid="audio-only-button"
            disabled={isPlayerProfileInBackgroundMode}
            id="audio-only"
            label={prefixNS('audioOnly')}
          />
          {type !== EmbedType.WEB_BROADCAST && type !== EmbedType.PLAYLIST && type !== null && (
            <Draft.CardIconButton
              data-testid="stream-url-button"
              id="stream-url"
              label={prefixNS('streamUrls')}
              //the selected prop can be removed once the AUDIO ONLY flag is removed from wrapping the feature
              selected={selectedOption === 4}
              value={EMBED_EVENTS.STREAM_URL}
            />
          )}
        </Draft.CardIconGroup>

        <Stack gap="m">
          {selectedOption === EMBED_EVENTS.IFRAME && (
            <Draft.FormField fieldLabel={commonT('embedCode')} htmlFor="iframe-embed-code">
              <Inline alignItems="center">
                <Draft.TextInput
                  as="textarea"
                  data-testid="iframe-embed-code"
                  id="iframe-embed-code"
                  readOnly
                  rows={7}
                  value={getEmbedCode()}
                />
                <CopyToClipboard
                  dataTestId="iframe-copy-icon"
                  dataTrackId="copy-iframe"
                  name={prefixNS('iFrame')}
                  text={getEmbedCode()}
                />
              </Inline>
            </Draft.FormField>
          )}

          {selectedOption === EMBED_EVENTS.AUDIO_ONLY && (
            <Draft.FormField fieldLabel={commonT('embedCode')} htmlFor="audio-only-embed-code">
              <Inline alignItems="center">
                <Draft.TextInput
                  as="textarea"
                  data-testid="audio-only-embed-code"
                  id="audio-only-code"
                  readOnly
                  rows={7}
                  value={getAudioOnlyEmbedCode()}
                />
                <CopyToClipboard
                  dataTestId="audio-only-copy-icon"
                  dataTrackId="copy-audio-only"
                  name={prefixNS('audioOnly')}
                  text={getAudioOnlyEmbedCode()}
                />
              </Inline>
            </Draft.FormField>
          )}

          {selectedOption === EMBED_EVENTS.ALT_IFRAME && (
            <Draft.FormField fieldLabel={commonT('embedCode')} htmlFor="alt-iframe-embed-code">
              <Inline alignItems="center">
                <Draft.TextInput
                  as="textarea"
                  data-testid="alt-iframe-embed-code"
                  id="alt-iframe-embed-code"
                  readOnly
                  rows={5}
                  value={getAltEmbedCode()}
                />
                <CopyToClipboard
                  dataTestId="alt-iframe-copy-icon"
                  dataTrackId="copy-alt-iframe"
                  name={prefixNS('altIframe')}
                  text={getAltEmbedCode()}
                />
              </Inline>
            </Draft.FormField>
          )}

          {selectedOption === EMBED_EVENTS.SCRIPT_TAG && (
            <Draft.FormField fieldLabel={commonT('embedCode')} htmlFor="script-tag-embed-code">
              <Inline alignItems="center">
                <Draft.TextInput
                  as="textarea"
                  data-testid="script-tag-embed-code"
                  id="script-tag-embed-code"
                  readOnly
                  rows={6}
                  value={getScriptEmbedCode()}
                />
                <CopyToClipboard
                  dataTestId="script-tag-copy-icon"
                  dataTrackId="copy-script-tag"
                  name={prefixNS('scriptTag')}
                  text={getScriptEmbedCode()}
                />
              </Inline>
            </Draft.FormField>
          )}
          {selectedOption === EMBED_EVENTS.STREAM_URL && dashUrl && hlsUrl && (
            <>
              <Draft.FormField fieldLabel={prefixNS('dashUrl')} htmlFor="dash-url">
                <Inline alignItems="center">
                  <Draft.TextInput data-testid="dash-url" defaultValue={dashUrl} id="dash-url" readOnly />
                  <CopyToClipboard dataTestId="dash-url-copy-icon" name={prefixNS('dashUrl')} text={dashUrl} />
                </Inline>
              </Draft.FormField>
              <Draft.FormField fieldLabel={prefixNS('hlsUrl')} htmlFor="hls-url">
                <Inline alignItems="center">
                  <Draft.TextInput data-testid="hls-url" defaultValue={hlsUrl} id="hls-url" readOnly />
                  <CopyToClipboard dataTestId="hls-url-copy-icon" name={prefixNS('hlsUrl')} text={hlsUrl} />
                </Inline>
              </Draft.FormField>
            </>
          )}
          {selectedOption !== EMBED_EVENTS.STREAM_URL && (
            <Inline alignItems="center" dataTestId="stream-urls">
              <Draft.FormField fieldLabel={prefixNS('embedId')} htmlFor="embed-id">
                <Inline alignItems="center">
                  <Draft.TextInput data-testid="embed-id" id="embed-id" readOnly value={embedId} />
                  <CopyToClipboard
                    dataTestId="embed-id-copy-icon"
                    dataTrackId="copy-embed-id"
                    name={prefixNS('embedId')}
                    text={embedId}
                  />
                </Inline>
              </Draft.FormField>
            </Inline>
          )}
        </Stack>
      </Stack>
    </div>
  );
};

EmbedOptions.displayName = 'EmbedOptions';

export default EmbedOptions;
