import * as React from 'react';
import { useTheme } from '@emotion/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Draft, Inline, useToast, Radio, RadioGroup, Stack, Progress } from '@resi-media/resi-ui';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { MAX_TITLE_LENGTH, PLAYLIST_MAX_VIDEOS_ALLOWED } from '@studio/constants/library';
import { INSERT_LOCATION } from '@studio/constants/playlists';
import UrlPaths from '@studio/constants/url-paths';
import { getUuidFromUrl, sortByAlphaIgnoreCase } from '@studio/helpers';
import { useClient, usePrefix } from '@studio/hooks';
import { playlists as playlistsStore, useTypedSelector } from '@studio/store';
import type { Playlists } from '@studio/types';
import { ErrorBlock } from '../ErrorBlock';

export type _State = {
  mediaId: string;
};

export const PLAYLIST_TYPE = {
  NEW: 'NEW',
  EXISTING: 'EXISTING',
};

interface SaveToPlaylistForm {
  existing: string;
  insertLocation: Playlists.Post.InsertLocation;
  name: string;
  selection: string;
}

const initialFormValues: SaveToPlaylistForm = {
  existing: '',
  insertLocation: INSERT_LOCATION.END,
  name: '',
  selection: PLAYLIST_TYPE.EXISTING,
};

export const SaveToPlaylistModal = () => {
  const dispatch = useDispatch();
  const { mediaId, onCloseReset } = Draft.ModalContext.useModal<_State>();
  const { commonT, prefixNS } = usePrefix('pages:', 'library');
  const navigate = useNavigate();
  const { trigger } = useToast();
  const theme = useTheme();
  const [playlistType, setPlaylistType] = React.useState(initialFormValues.selection);

  const playlistsInfo = useSelector(playlistsStore.selectListInfo);

  const {
    callApi: postPlaylist,
    error: saveToNewPlaylistError,
    isFetching: isSavingToNewPlaylist,
  } = useClient({
    config: useClient.mediaMetadata.v1.playlists.POST,
    returnAxiosResponse: true,
  });

  const {
    callApi: postMediaToPlaylist,
    error: saveToPlaylistError,
    isFetching: isSavingToPlaylist,
  } = useClient({
    config: useClient.mediaMetadata.v1.playlists.id.media.POST,
    params: { playlistId: '' },
  });

  const {
    error: playlistsError,
    isFetching: isFetchingPlaylists,
    sortBy: playlistsSortBy,
  } = useTypedSelector((state) => state.playlists);

  const SaveToPlaylistSchema = Yup.object().shape({
    name: Yup.string().when('selection', {
      is: PLAYLIST_TYPE.NEW,
      then: Yup.string()
        .required(prefixNS('errors.playlistName.required'))
        .max(MAX_TITLE_LENGTH, () => prefixNS('errors.playlistName.maxLength')),
    }),
    existing: Yup.string().when('selection', {
      is: PLAYLIST_TYPE.EXISTING,
      then: Yup.string()
        .test(
          'ensure-playlist-not-full',
          prefixNS('saveToPlaylist.playlistFull', { count: PLAYLIST_MAX_VIDEOS_ALLOWED }),
          (value) => {
            const playlist = playlistsInfo.playlists.find((v) => v.id === value);
            if (playlist && playlist.size >= PLAYLIST_MAX_VIDEOS_ALLOWED) {
              return false;
            }
            return true;
          }
        )
        .required(prefixNS('saveToPlaylist.playlistRequired')),
    }),
  });

  const methods = useForm<SaveToPlaylistForm>({
    mode: 'all',
    defaultValues: initialFormValues,
    resolver: yupResolver(SaveToPlaylistSchema),
  });
  const { control, handleSubmit, setValue } = methods;
  const saveToPlaylist = React.useCallback(
    async (formState: SaveToPlaylistForm): Promise<void> => {
      try {
        if (formState.selection === PLAYLIST_TYPE.NEW) {
          const payload: Playlists.Post.Playlist = {
            name: formState.name,
            insertLocation: formState.insertLocation,
            mediaIds: [mediaId],
          };
          const response = await postPlaylist(payload);
          trigger({ content: prefixNS('saveToPlaylist.savedToNewPlaylist') });

          const uuid = getUuidFromUrl(response.headers.location);
          if (uuid) {
            navigate(UrlPaths.MEDIA.VIEW_PLAYLIST.replace(':uuid', uuid));
          }
        } else {
          const payload = [mediaId];
          if (!formState.existing) return;
          await postMediaToPlaylist(payload, { params: { playlistId: formState.existing } });
          trigger({ content: prefixNS('saveToPlaylist.savedToExistingPlaylist') });
          navigate(UrlPaths.MEDIA.VIEW_PLAYLIST.replace(':uuid', formState.existing));
        }

        dispatch({ type: playlistsStore.ActionTypes.FETCH_REQUEST_TIMESTAMP, payload: 0 });
        onCloseReset();
      } catch (e) {
        console.error(e);
      }
    },
    [dispatch, mediaId, navigate, onCloseReset, postMediaToPlaylist, postPlaylist, prefixNS, trigger]
  );

  React.useEffect(() => {
    dispatch({ type: playlistsStore.ActionTypes.FETCH_REQUEST, payload: { sort: playlistsSortBy } });
  }, [dispatch, playlistsSortBy]);

  const playlistOptions = React.useMemo(() => {
    return playlistsInfo.playlists
      .map((playlist: Playlists.Get.Playlist): { label: string; value: string } => ({
        value: playlist.id,
        label: playlist.name,
      }))
      .sort(sortByAlphaIgnoreCase((item) => item.label));
  }, [playlistsInfo.playlists]);

  return (
    <>
      <Draft.ModalHeader
        dataTestId="header-container"
        header={prefixNS('saveToPlaylist.title')}
        onClose={onCloseReset}
        showBorder
      />
      <Draft.ModalBody dataTestId="save-to-playlist-modal">
        {isFetchingPlaylists ? (
          <Progress sizeVariant="m" style={{ marginTop: theme.spacing.xl }} />
        ) : (
          <FormProvider {...methods}>
            <form
              data-testid="save-to-playlist-form"
              id="save-to-playlist-form"
              onSubmit={handleSubmit(saveToPlaylist)}
            >
              <Stack gap="m">
                <ErrorBlock error={saveToPlaylistError || saveToNewPlaylistError} />
                <Controller
                  control={control}
                  name="selection"
                  render={({ field: { name, onChange, ref, value } }) => (
                    <Draft.FormField fieldLabel={prefixNS('saveToPlaylist.selectPlaylist')} htmlFor={name}>
                      <RadioGroup
                        ref={ref}
                        gap="xs"
                        name={name}
                        onChange={(val) => {
                          onChange(val);
                          setPlaylistType(val);
                        }}
                        value={value}
                      >
                        <Radio
                          data-testid="radio-existing"
                          endNode={prefixNS('saveToPlaylist.typeExisting')}
                          id="existing"
                          value={PLAYLIST_TYPE.EXISTING}
                        />
                        <Radio
                          data-testid="radio-new"
                          endNode={prefixNS('saveToPlaylist.typeNew')}
                          id="new"
                          value={PLAYLIST_TYPE.NEW}
                        />
                      </RadioGroup>
                    </Draft.FormField>
                  )}
                />
                {playlistType === PLAYLIST_TYPE.NEW && (
                  <>
                    <Controller
                      control={control}
                      name="name"
                      render={({
                        field: { name, onBlur, onChange, ref, value },
                        fieldState: { error, isDirty, isTouched },
                        formState: { isSubmitted },
                      }) => {
                        return (
                          <Draft.FormField
                            error={error?.message}
                            fieldLabel={prefixNS('saveToPlaylist.name')}
                            htmlFor={name}
                            touched={isTouched || isSubmitted || isDirty}
                          >
                            <Draft.TextInput
                              ref={ref}
                              data-testid="playlist-name-input"
                              hasError={Boolean(error)}
                              id={name}
                              onBlur={onBlur}
                              onChange={onChange}
                              value={value}
                            />
                          </Draft.FormField>
                        );
                      }}
                    />
                    <Controller
                      control={control}
                      name="insertLocation"
                      render={({ field: { name, onChange, value } }) => {
                        return (
                          <Draft.FormField
                            dataTestId="playlist-entry-selection"
                            fieldLabel={prefixNS('playlist.sort.label')}
                            htmlFor={name}
                          >
                            <RadioGroup name={name} onChange={onChange} value={value}>
                              <Radio
                                dataTestId="sort-option__start"
                                dataTrackId="add-video-to-playlist-entry__beginning"
                                endNode={prefixNS('playlist.sort.start')}
                                id={INSERT_LOCATION.BEGINNING}
                                value={INSERT_LOCATION.BEGINNING}
                              />
                              <Radio
                                dataTestId="sort-option__end"
                                dataTrackId="add-video-to-playlist-entry__end"
                                endNode={prefixNS('playlist.sort.end')}
                                id={INSERT_LOCATION.END}
                                value={INSERT_LOCATION.END}
                              />
                            </RadioGroup>
                          </Draft.FormField>
                        );
                      }}
                    />
                  </>
                )}
                {playlistType === PLAYLIST_TYPE.EXISTING && (
                  <Controller
                    name="existing"
                    render={({
                      field: { name, onBlur, value },
                      fieldState: { error, isTouched },
                      formState: { isSubmitted },
                    }) => {
                      return (
                        <Draft.FormField
                          error={error?.message}
                          fieldLabel={prefixNS('saveToPlaylist.chooseExistingPlaylist')}
                          htmlFor="existing"
                          touched={isTouched || isSubmitted}
                        >
                          {playlistsError ? (
                            <Draft.AlertBanner>{prefixNS('saveToPlaylist.unableToLoadPlaylists')}</Draft.AlertBanner>
                          ) : (
                            <Draft.Select
                              appendToBody
                              dataTestId="playlist-select"
                              hasError={Boolean(error)}
                              inputId="existing"
                              name={name}
                              onBlur={onBlur}
                              onChange={(option) => {
                                if (option) {
                                  setValue('existing', option.value, { shouldValidate: true });
                                }
                              }}
                              options={playlistOptions}
                              value={playlistOptions.find((v) => v.value === value)}
                            />
                          )}
                        </Draft.FormField>
                      );
                    }}
                  />
                )}
              </Stack>
            </form>
          </FormProvider>
        )}
      </Draft.ModalBody>
      <Draft.ModalFooter>
        <Inline alignItems="center" gap="l" justifyContent="flex-end">
          <Draft.Button data-testid="submit-cancel" label={commonT('cancel')} onClick={onCloseReset} variant="text" />
          <Draft.Button
            data-testid="submit-save"
            disabled={isSavingToPlaylist || isSavingToNewPlaylist}
            form="save-to-playlist-form"
            label={prefixNS('saveToPlaylist.menu')}
            type="submit"
            {...((isSavingToPlaylist || isSavingToNewPlaylist) && {
              startNode: <Progress colorVariant="inherit" dataTestId="save-spinner" sizeVariant="inherit" />,
            })}
          />
        </Inline>
      </Draft.ModalFooter>
    </>
  );
};

SaveToPlaylistModal.displayName = 'SaveToPlaylistModal';

export default SaveToPlaylistModal;
