import i18next from 'i18next';
import * as Yup from 'yup';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useFormValidation = (): Yup.ObjectSchema<any, any, any, any> => {
  return Yup.object({
    name: Yup.string().required(i18next.t('common:errors.name.required')),
    position: Yup.string().required(),
  });
};

export default useFormValidation;
