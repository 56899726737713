import { HTTP_METHODS } from '@studio/constants/axios';
import { CENTRAL_API_V3 } from '@studio/constants/env-variables';
import type { Venues } from '@studio/types';

export const root = {
  [HTTP_METHODS.POST]: {
    method: HTTP_METHODS.POST,
    request: {} as Venues.Post.Venue,
    url: `${CENTRAL_API_V3}/customers/@customerId/venues`,
  },
};

export const id = {
  [HTTP_METHODS.GET]: {
    method: HTTP_METHODS.GET,
    params: {} as { id: string },
    response: {} as Venues.Get.Login,
    url: `${CENTRAL_API_V3}/customers/@customerId/venues/@id`,
  },
  [HTTP_METHODS.DELETE]: {
    method: HTTP_METHODS.DELETE,
    params: {} as { id: string },
    url: `${CENTRAL_API_V3}/customers/@customerId/venues/@id`,
  },
  [HTTP_METHODS.PATCH]: {
    method: HTTP_METHODS.PATCH,
    params: {} as { id: string },
    request: {} as Venues.Patch.Venue,
    url: `${CENTRAL_API_V3}/customers/@customerId/venues/@id`,
  },
};
