import type { CombinedState } from 'redux';
import { createSelector } from 'reselect';
import type { RootState } from '@studio/store/root-reducer';
import type { WebPresets } from '@studio/types';
import type { WebPresetState } from './types';

export const selectWebPresetState = (state: RootState): CombinedState<WebPresetState> => state.webPresets;

export const selectFilter = createSelector(selectWebPresetState, (state): string => state.filter);

export const selectItems = createSelector(selectWebPresetState, (state): WebPresets.Get.WebPreset[] => state.items);

export const filterItems = createSelector(selectItems, selectFilter, (items, filter): WebPresets.Get.WebPreset[] => {
  return items.filter((item) => item.name.toLowerCase().includes(filter.toLowerCase()));
});

export const selectTimestamp = createSelector(selectWebPresetState, (state): number => state.timestamp);
