import { Draft, Inline, Stack, Text } from '@resi-media/resi-ui';
import { match } from 'ts-pattern';
import { ConfirmationModal } from '@studio/components/ConfirmationModal';
import { DESTINATION } from '@studio/constants/content-destination';
import { usePrefix } from '@studio/hooks';
import type { DestinationGroups } from '@studio/types';

const RemoveDestinationModal = () => {
  const { member, memberIndex, memberName, onClose, onRemove } =
    Draft.ModalContext.useModal<DestinationGroups.Derived.RemoveDestinationModalState>();
  const { commonT, prefixNS } = usePrefix('pages:', 'destinationGroups');

  return (
    <ConfirmationModal
      colorVariant="negative"
      handleClose={onClose}
      handleSubmit={() => {
        onRemove(memberIndex);
        onClose();
      }}
      isHandlingSubmit={false}
      isOpen
      isUsingContext
      submitLabel={commonT('remove')}
      title={match(member.type)
        .with(DESTINATION.FACEBOOK, () => prefixNS('removeFacebook'))
        .with(DESTINATION.YOUTUBE, () => prefixNS('removeYoutube'))
        .with(DESTINATION.RTMP, () => prefixNS('removeRtmp'))
        .otherwise(() => prefixNS('removeWebChannel'))}
    >
      <Stack dataTestId="remove-destination-modal__body">
        <Text>{prefixNS('confirmRemove')}</Text>
        <Inline>
          <Text dataTestId="destination-prefix" isInline weightVariant="bold">
            {match(member.type)
              .with(DESTINATION.FACEBOOK, () => commonT('facebook'))
              .with(DESTINATION.YOUTUBE, () => commonT('youtube'))
              .with(DESTINATION.RTMP, () => commonT('customRTMP'))
              .otherwise(() => commonT('webChannel'))}
            :
          </Text>
          <Text dataTestId="member-name" isInline>
            {memberName}
          </Text>
        </Inline>
      </Stack>
    </ConfirmationModal>
  );
};

RemoveDestinationModal.displayName = 'RemoveDestinationModal';

export default RemoveDestinationModal;
