import type { CombinedState } from 'redux';
import { createSelector } from 'reselect';
import type { Sites } from '@studio/types';
import type { RootState } from '../root-reducer';
import type { SiteState } from './types';

export const selectSiteState = (state: RootState): CombinedState<SiteState> => state.sites;

export const selectSiteFilter = createSelector(selectSiteState, (state): string => state.filter);

export const selectSites = createSelector(selectSiteState, (state): Sites.Derived.Site[] => state.items);

export const filterSites = createSelector(selectSites, selectSiteFilter, (items, filter): Sites.Derived.Site[] => {
  return items
    .filter((item) => item.name.toLowerCase().includes(filter.toLowerCase()))
    .sort((a, b) => a.name.localeCompare(b.name));
});

export const selectSiteTimestamp = createSelector(selectSiteState, (state): number => state.timestamp);
