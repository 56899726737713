import type { Option } from '@resi-media/resi-ui';
import i18next from 'i18next';
import type { CombinedState } from 'redux';
import { createSelector } from 'reselect';
import { DISPLAY_MODE } from '@studio/constants/player-profiles';
import { sortByAlphaIgnoreCase } from '@studio/helpers';
import type { RootState } from '@studio/store/root-reducer';
import type { PlayerProfiles } from '@studio/types';
import type { PlayerProfileState } from './types';

export const selectPlayerProfiles = (state: RootState): CombinedState<PlayerProfileState> => state.playerProfiles;

const appendBgMode = (list: PlayerProfiles.Get.ProfileListItem[]) => {
  return [
    ...list,
    {
      alias: i18next.t('pages:playerProfiles.backgroundMode'),
      id: DISPLAY_MODE.BACKGROUND,
      displayMode: DISPLAY_MODE.BACKGROUND,
    },
  ];
};

export const selectItems = createSelector(selectPlayerProfiles, (state): PlayerProfiles.Get.ProfileListItem[] =>
  appendBgMode(state.items)
);

export const selectFilter = createSelector(selectPlayerProfiles, (state): string => state.filter);

export const filterItems = createSelector(
  selectItems,
  selectFilter,
  (items, filter): PlayerProfiles.Get.ProfileListItem[] =>
    items
      .filter((item) => item.alias.toLowerCase().includes(filter.toLowerCase()))
      .sort(sortByAlphaIgnoreCase((list: PlayerProfiles.Get.ProfileListItem) => list.alias))
);

export const selectTimeStamp = createSelector(selectPlayerProfiles, (state): number => state.timestamp);

export const selectProfileOptions = () =>
  createSelector(selectItems, (items): Option[] => {
    return items
      .map((item): Option => ({ label: item.alias, value: item.id }))
      .sort(sortByAlphaIgnoreCase((list) => list.label));
  });
