import type { ActionType } from 'typesafe-actions';
import { createReducer } from 'typesafe-actions';
import { fetchTimezonesAsync } from './actions';
import type { TimezonesInitialState } from './types';

type Action = ActionType<typeof fetchTimezonesAsync>;

export const initialState: TimezonesInitialState = {
  isFetching: false,
  items: [],
  error: null,
};

const reducer = createReducer<TimezonesInitialState, Action>(initialState)
  .handleAction(fetchTimezonesAsync.request, (state) => ({
    ...state,
    isFetching: true,
    error: null,
  }))
  .handleAction(fetchTimezonesAsync.success, (state, action) => ({
    ...state,
    isFetching: false,
    items: action.payload,
  }))
  .handleAction(fetchTimezonesAsync.failure, (state, action) => ({
    ...state,
    isFetching: false,
    error: action.payload,
  }))
  .handleAction(fetchTimezonesAsync.cancel, (state) => ({
    ...state,
    isFetching: false,
  }));

export default reducer;
