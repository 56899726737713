import { produce } from 'immer';
import type { ActionType } from 'typesafe-actions';
import { createReducer } from 'typesafe-actions';
import { fetch } from './actions';
import type { MonitorState } from './types';

type Action = ActionType<typeof fetch>;

export const initialState: MonitorState = {
  encoders: {
    isFetching: false,
    error: null,
    data: [],
  },
  decoders: {
    isFetching: false,
    error: null,
    data: [],
  },
};

const reducer = createReducer<MonitorState, Action>(initialState)
  .handleAction(
    fetch.all.request,
    produce((draft: MonitorState) => {
      draft.decoders.isFetching = !draft.decoders.data.length;
      draft.decoders.error = null;
      draft.encoders.isFetching = !draft.encoders.data.length;
      draft.encoders.error = null;
    })
  )
  .handleAction(
    fetch.all.success,
    produce((draft: MonitorState, action: ActionType<typeof fetch.all.success>) => {
      draft.decoders.isFetching = false;
      draft.decoders.data = action.payload.decoderStatus;
      draft.encoders.isFetching = false;
      draft.encoders.data = action.payload.encoderStatus;
    })
  )
  .handleAction(
    fetch.all.failure,
    produce((draft: MonitorState, action: ActionType<typeof fetch.all.failure>) => {
      draft.decoders.isFetching = false;
      draft.decoders.error = action.payload;
      draft.encoders.isFetching = false;
      draft.encoders.error = action.payload;
    })
  )
  .handleAction(
    fetch.all.cancel,
    produce((draft: MonitorState) => {
      draft.decoders.isFetching = false;
      draft.encoders.isFetching = false;
    })
  )
  .handleAction(
    fetch.decoders.request,
    produce((draft: MonitorState) => {
      draft.decoders.isFetching = !draft.decoders.data.length;
      draft.decoders.error = null;
    })
  )
  .handleAction(
    fetch.decoders.success,
    produce((draft: MonitorState, action: ActionType<typeof fetch.decoders.success>) => {
      draft.decoders.isFetching = false;
      draft.decoders.data = action.payload;
    })
  )
  .handleAction(
    fetch.decoders.failure,
    produce((draft: MonitorState, action: ActionType<typeof fetch.decoders.failure>) => {
      draft.decoders.isFetching = false;
      draft.decoders.error = action.payload;
    })
  )
  .handleAction(
    fetch.decoders.cancel,
    produce((draft: MonitorState) => {
      draft.decoders.isFetching = false;
    })
  )
  .handleAction(
    fetch.encoders.request,
    produce((draft: MonitorState) => {
      draft.encoders.isFetching = !draft.encoders.data.length;
      draft.encoders.error = null;
    })
  )
  .handleAction(
    fetch.encoders.success,
    produce((draft: MonitorState, action: ActionType<typeof fetch.encoders.success>) => {
      draft.encoders.isFetching = false;
      draft.encoders.data = action.payload;
    })
  )
  .handleAction(
    fetch.encoders.failure,
    produce((draft: MonitorState, action: ActionType<typeof fetch.encoders.failure>) => {
      draft.encoders.isFetching = false;
      draft.encoders.error = action.payload;
    })
  )
  .handleAction(
    fetch.encoders.cancel,
    produce((draft: MonitorState) => {
      draft.encoders.isFetching = false;
    })
  );

export default reducer;
