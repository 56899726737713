import { HTTP_METHODS } from '@studio/constants/axios';
import { CENTRAL_API_V3 } from '@studio/constants/env-variables';
import type { ContentDestinations } from '@studio/types';

export const root = {
  [HTTP_METHODS.GET]: {
    url: `${CENTRAL_API_V3}/customers/@customerId/contentdestinations/@destinationId/summary`,
    response: {} as ContentDestinations.Get.WebBroadcastSummary,
    params: {} as { destinationId: string },
    method: HTTP_METHODS.GET,
  },
};

export const id = {};
