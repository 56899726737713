import { HTTP_METHODS } from '@studio/constants/axios';
import { CENTRAL_API_V1 } from '@studio/constants/env-variables';
import type { Hardware } from '@studio/types';

export const id = {
  [HTTP_METHODS.GET]: {
    url: `${CENTRAL_API_V1}/hardwareunits/@id/assigned`,
    response: {} as Hardware.Get.HardwareAssignedTo,
    params: {} as { id: string },
    method: HTTP_METHODS.GET,
  },
};
