import { root, id } from './encoders';
import * as idEndpoints from './id';
import { onboard } from './onboard';
import { status } from './status';

export const encoders = {
  ...root,
  id: {
    ...id,
    ...idEndpoints,
  },
  onboard,
  status,
};
