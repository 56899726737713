import type { SerializedStyles, Theme } from '@emotion/react';
import { css } from '@emotion/react';

const TRANSITION_TIME = '200ms';

export const S = {
  uploadButton: (theme: Theme): SerializedStyles => css`
    width: 131px;
    height: 85px;
    border: 1px dashed ${theme.palette.border};
    background-color: ${theme.palette.background.table};
    padding: ${theme.spacing.m};
    &:hover {
      cursor: pointer;
      border: 1px solid ${theme.palette.primary.hover};
    }
  `,
  thumbnailButton: (_: Theme): SerializedStyles => css`
    position: relative;
    height: 85px;
    width: 144px;
  `,
  thumbnailImage: (theme: Theme, isHovering: boolean): SerializedStyles => css`
    height: 85px;
    background-color: black;
    border: 2px solid ${isHovering ? theme.palette.negative.main : theme.palette.primary.main};
    border-radius: ${theme.shape.borderRadius.s};
    box-sizing: border-box;
    transition: border ${TRANSITION_TIME};
  `,
  thumbnailPlaceholder: (_theme: Theme): SerializedStyles => css`
    height: 85px;
    width: 148px;
    box-sizing: border-box;
    background-color: black;
  `,
  removeThumbnailMask: (theme: Theme): SerializedStyles => css`
    position: absolute;
    display: grid;
    place-items: center;
    height: 85px;
    width: 148px;
    box-sizing: border-box;
    opacity: 0;
    transition: ${theme.transitions.create('background-color')};

    &:hover {
      opacity: 1;
      background-color: rgba(0, 0, 0, 0.5);
      transition: ${theme.transitions.create('opacity')};
    }
  `,
};
