import * as React from 'react';

type _Props = {
  children: React.ReactNode;
  disabled?: boolean;
};

const isLocalHost = (hostname: string): boolean =>
  !!(
    hostname === 'localhost' ||
    hostname === '[::1]' ||
    hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
  );

const HttpsRedirect = ({ children, disabled }: _Props) => {
  if (
    !disabled &&
    typeof window !== 'undefined' &&
    window.location.protocol === 'http:' &&
    !isLocalHost(window.location.hostname)
  ) {
    window.location.href = window.location.href.replace(/^http(?!s)/, 'https');
    return null;
  }
  return <>{children}</>;
};

HttpsRedirect.displayName = 'HttpsRedirect';

export default HttpsRedirect;
