import { HTTP_METHODS } from '@studio/constants/axios';
import { CENTRAL_API_V1 } from '@studio/constants/env-variables';
import type { Hardware } from '@studio/types';

export const id = {
  [HTTP_METHODS.PATCH]: {
    url: `${CENTRAL_API_V1}/hardwaremodels/@id`,
    params: {} as { id: string },
    request: {} as Hardware.Patch.V1.HardwareModel,
    method: HTTP_METHODS.PATCH,
  },
};
