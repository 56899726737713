/* eslint-disable jsx-a11y/click-events-have-key-events */
import * as React from 'react';
import { DeleteOutlined, UploadOutlined } from '@ant-design/icons';
import { useTheme } from '@emotion/react';
import { FieldLabel, Stack, Inline, Text } from '@resi-media/resi-ui';
import { useKeyDown, usePrefix } from '@studio/hooks';
import {
  deleteIconStyles,
  DeleteIconWrapper,
  NoThumbnailPlaceholder,
  RemoveThumbnailMask,
  ThumbnailParentDiv,
  UploadedThumbnailImage,
  UploadSquareButton,
} from './style';

export interface UploadThumbnailButtonProps {
  imageUrl?: string;
  onClick: (event: React.KeyboardEvent<HTMLButtonElement> | React.MouseEvent<HTMLButtonElement>) => void;
  onImageRemove: () => void;
}

const UploadThumbnailButton = ({ imageUrl, onClick, onImageRemove }: UploadThumbnailButtonProps) => {
  const { commonT, prefixNS } = usePrefix('components:', 'uploadThumbnailButton');
  const theme = useTheme();
  const [isHoveringImage, setHoveringImage] = React.useState(false);
  const [showImage, setShowImage] = React.useState(false);

  React.useEffect(() => {
    if (imageUrl) {
      const img = new Image();
      img.onerror = handleImageUrlLoadFail;
      img.onabort = handleImageUrlLoadFail;
      img.onload = () => setShowImage(true);
      img.src = imageUrl;
    }
  }, [imageUrl]);

  const handleImageUrlLoadFail = () => {
    setShowImage(false);
    console.warn('Failed to load thumbnail url');
  };

  const handleImageRemove = () => {
    onImageRemove();
    setHoveringImage(false);
  };
  const handleKeyDown = useKeyDown(['Enter', ' '], onClick);

  return (
    <Stack data-testid="upload-thumbnail-button-component" gap="xs">
      <FieldLabel hint={prefixNS('tooltip')} optionalText={commonT('optional')}>
        {commonT('thumbnail')}
      </FieldLabel>
      <Inline>
        <UploadSquareButton data-testid="upload-button" onClick={onClick} onKeyDown={handleKeyDown} type="button">
          <Stack alignItems="center">
            <UploadOutlined style={{ fontSize: '30px', color: theme.palette.text.secondary }} />
            <Text colorVariant="secondary" isUppercase variant="body4">
              {imageUrl ? commonT('replaceThumbnail') : commonT('uploadThumbnail')}
            </Text>
          </Stack>
        </UploadSquareButton>
        {imageUrl && (
          // eslint-disable-next-line jsx-a11y/no-static-element-interactions
          <ThumbnailParentDiv
            data-testid="thumbnail-remove"
            onClick={handleImageRemove}
            onMouseEnter={() => setHoveringImage(true)}
            onMouseLeave={() => setHoveringImage(false)}
          >
            {showImage ? (
              <UploadedThumbnailImage alt={commonT('thumbnail')} isHovering={isHoveringImage} src={imageUrl} />
            ) : (
              <NoThumbnailPlaceholder />
            )}

            <RemoveThumbnailMask isHovering={isHoveringImage} />
            <DeleteIconWrapper data-testid="delete-icon-wrapper" isHovering={isHoveringImage}>
              <DeleteOutlined css={deleteIconStyles} />
            </DeleteIconWrapper>
          </ThumbnailParentDiv>
        )}
      </Inline>
    </Stack>
  );
};

UploadThumbnailButton.displayName = 'UploadThumbnailButton';

export default UploadThumbnailButton;
