import { createAction, createAsyncAction } from 'typesafe-actions';
import type { Venues } from '@studio/types';
import { ActionTypes } from './types';

export const fetch = {
  logins: createAsyncAction(
    ActionTypes.FETCH_REQUEST,
    ActionTypes.FETCH_REQUEST_SUCCESS,
    ActionTypes.FETCH_REQUEST_ERROR,
    ActionTypes.FETCH_REQUEST_CANCEL
  )<undefined, Venues.Get.Login[], Error, undefined>(),
  licenses: createAsyncAction(
    ActionTypes.FETCH_VENUE_LICENSES,
    ActionTypes.FETCH_VENUE_LICENSES_SUCCESS,
    ActionTypes.FETCH_VENUE_LICENSES_ERROR,
    ActionTypes.FETCH_VENUE_LICENSES_CANCEL
  )<undefined, number, Error, undefined>(),
};

export const set = {
  filter: createAction(ActionTypes.FILTER_RESULTS)<string>(),
  timestamp: createAction(ActionTypes.FETCH_REQUEST_TIMESTAMP)<number>(),
};
