import { Stack, Text, Draft } from '@resi-media/resi-ui';
import { ConfirmationModal } from '../ConfirmationModal';

export type _State = {
  errorMessage: string;
  isSubmitting: boolean;
  messageText: string;
  name: string;
  onSubmit: (uuid: string) => void;
  submitLabel: string;
  title: string;
  uuid: string;
};

const VideoModal = () => {
  const { errorMessage, isSubmitting, messageText, name, onCloseReset, onSubmit, submitLabel, title, uuid } =
    Draft.ModalContext.useModal<_State>();

  return (
    <ConfirmationModal
      colorVariant="negative"
      errorMessage={errorMessage}
      handleClose={onCloseReset}
      handleSubmit={() => onSubmit(uuid)}
      isHandlingSubmit={isSubmitting}
      isOpen
      submitLabel={submitLabel}
      title={title}
    >
      <Stack gap="m">
        <Text dataTestId="message-text">{messageText}</Text>
        <Text dataTestId="name" weightVariant="bold">
          {name}
        </Text>
      </Stack>
    </ConfirmationModal>
  );
};

VideoModal.displayName = 'VideoModal';

export default VideoModal;
