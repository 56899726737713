import { HTTP_METHODS } from '@studio/constants/axios';
import { CENTRAL_API_V2 } from '@studio/constants/env-variables';
import type { Roles, Users } from '@studio/types';

export const root = {
  [HTTP_METHODS.GET]: {
    url: `${CENTRAL_API_V2}/users/@id/roles`,
    params: {} as { id: string },
    response: [] as Users.Get.UserRole[],
    method: HTTP_METHODS.GET,
  },
  [HTTP_METHODS.PUT]: {
    url: `${CENTRAL_API_V2}/users/@id/roles`,
    params: {} as { id: string },
    request: {} as Roles.Put.Roles,
    method: HTTP_METHODS.PUT,
  },
};

export const id = {};
