import { match, P } from 'ts-pattern';
import { COLOR_VARIANTS } from '@studio/constants/analytics';

const useChartColors = (keys: string[]) => {
  const getChartColor = (breakdown: string) => {
    return match(breakdown)
      .with(P.union('desktop', 'live'), () => '#475D7A')
      .with('mobile', () => '#E38B36')
      .with('tablet', () => '#FFE27B')
      .with('other', () => '#BFBFBF')
      .with(P.union('settopbox', 'replay'), () => '#A38BE9')
      .with(P.union('gameconsole', 'ondemand'), () => '#4F9C33')
      .with('tv', () => '#66BBDF')
      .otherwise(() => '#FF585D');
  };

  const lowerCaseKeys = keys.map((key) => key.toLowerCase());

  const hasKey =
    lowerCaseKeys.includes('desktop') ||
    lowerCaseKeys.includes('mobile') ||
    lowerCaseKeys.includes('tablet') ||
    lowerCaseKeys.includes('gameconsole') ||
    lowerCaseKeys.includes('settopbox') ||
    lowerCaseKeys.includes('tv') ||
    lowerCaseKeys.includes('ondemand') ||
    lowerCaseKeys.includes('live') ||
    lowerCaseKeys.includes('replay');

  const updatedColors = hasKey ? lowerCaseKeys.map((label: string) => getChartColor(label)) : COLOR_VARIANTS;

  return updatedColors;
};

export default useChartColors;
