import { produce } from 'immer';
import type { ActionType, PayloadAction } from 'typesafe-actions';
import { createReducer } from 'typesafe-actions';
import { fetchContentSchedules, setFetchRequestTimestamp, resetCachedItems, setFilter } from './actions';
import type { ActionTypes, CachedItems, ContentScheduleInitialState } from './types';

type Action = ActionType<
  typeof fetchContentSchedules | typeof resetCachedItems | typeof setFetchRequestTimestamp | typeof setFilter
>;

export const initialState: ContentScheduleInitialState = {
  isFetching: false,
  hasFetched: false,
  cachedItems: new Map(),
  timestamp: 0,
  filter: '',
  error: null,
};

const reducer = createReducer<ContentScheduleInitialState, Action>(initialState)
  .handleAction(
    fetchContentSchedules.request,
    produce((draft: ContentScheduleInitialState) => {
      draft.isFetching = true;
    })
  )
  .handleAction(
    fetchContentSchedules.success,
    produce(
      (
        draft: ContentScheduleInitialState,
        action: PayloadAction<ActionTypes.FETCH_REQUEST_SUCCESS, Map<string, CachedItems>>
      ) => {
        draft.isFetching = false;
        draft.hasFetched = true;
        draft.cachedItems = action.payload;
      }
    )
  )
  .handleAction(
    fetchContentSchedules.failure,
    produce((draft: ContentScheduleInitialState, action: PayloadAction<ActionTypes.FETCH_REQUEST_ERROR, Error>) => {
      draft.isFetching = false;
      draft.hasFetched = true;
      draft.error = action.payload;
    })
  )
  .handleAction(
    setFilter,
    produce((draft: ContentScheduleInitialState, action: PayloadAction<ActionTypes.FILTER_RESULTS, string>) => {
      draft.filter = action.payload;
    })
  )
  .handleAction(
    fetchContentSchedules.cancel,
    produce((draft: ContentScheduleInitialState) => {
      draft.isFetching = false;
      draft.hasFetched = true;
    })
  )
  .handleAction(
    setFetchRequestTimestamp,
    produce(
      (draft: ContentScheduleInitialState, action: PayloadAction<ActionTypes.FETCH_REQUEST_TIMESTAMP, number>) => {
        draft.timestamp = action.payload;
      }
    )
  )
  .handleAction(
    resetCachedItems,
    produce((draft: ContentScheduleInitialState) => {
      draft.cachedItems = new Map();
    })
  );

export default reducer;
