import { HTTP_METHODS } from '@studio/constants/axios';
import { CENTRAL_API_V2 } from '@studio/constants/env-variables';
import type { Cues } from '@studio/types';

export const root = {
  [HTTP_METHODS.GET]: {
    url: `${CENTRAL_API_V2}/streamprofiles/@profileId/events/@mediaId/cues`,
    response: [] as Cues.Get.EncoderCue[],
    params: {} as { mediaId: string; profileId: string },
    query: {} as {
      canISetCues?: number;
    },
    method: HTTP_METHODS.GET,
  },
  [HTTP_METHODS.POST]: {
    url: `${CENTRAL_API_V2}/streamprofiles/@profileId/events/@mediaId/cues`,
    params: {} as { mediaId: string; profileId: string },
    request: {} as Cues.Post.EncoderCue,
    method: HTTP_METHODS.POST,
  },
};

export const id = {
  [HTTP_METHODS.PATCH]: {
    url: `${CENTRAL_API_V2}/streamprofiles/@profileId/events/@mediaId/cues/@id`,
    params: {} as { id: string; mediaId: string; profileId: string },
    request: {} as Cues.Patch.EncoderCue,
    method: HTTP_METHODS.PATCH,
  },
  [HTTP_METHODS.DELETE]: {
    url: `${CENTRAL_API_V2}/streamprofiles/@profileId/events/@mediaId/cues/@id`,
    params: {} as { id: string; mediaId: string; profileId: string },
    method: HTTP_METHODS.DELETE,
  },
};
