import type { Library, LibrarySearch } from '@studio/types';

export enum ActionTypes {
  FETCH_MORE_REQUEST = '@@library-search/FETCH_MORE_REQUEST',
  FETCH_MORE_REQUEST_CANCEL = '@@library-search/FETCH_MORE_REQUEST_CANCEL',
  FETCH_MORE_REQUEST_ERROR = '@@library-search/FETCH_MORE_REQUEST_ERROR',
  FETCH_MORE_REQUEST_SUCCESS = '@@library-search/FETCH_MORE_REQUEST_SUCCESS',
  FETCH_PROCESSING = '@@library/FETCH_PROCESSING',
  FETCH_PROCESSING_ERROR = '@@library/FETCH_PROCESSING_ERROR',
  FETCH_PROCESSING_SUCCESS = '@@library/FETCH_PROCESSING_SUCCESS',
  FETCH_REQUEST = '@@library-search/FETCH_REQUEST',
  FETCH_REQUEST_BACKGROUND = '@@library-search/FETCH_REQUEST_BACKGROUND',
  FETCH_REQUEST_CANCEL = '@@library-search/FETCH_REQUEST_CANCEL',
  FETCH_REQUEST_ERROR = '@@library-search/FETCH_REQUEST_ERROR',
  FETCH_REQUEST_SUCCESS = '@@library-search/FETCH_REQUEST_SUCCESS',
  RESET_SEARCH_REQUEST = '@@library-search/RESET_SEARCH_REQUEST',
  SET_LIST_TYPE = '@@library-search/SET_LIST_TYPE',
  SET_QUERY_PARAMS = '@@library-search/SET_QUERY_PARAMS',
  SET_SELECTED_TAGS = '@@library-search/SET_SELECTED_TAGS',
  SET_THUMBNAIL_FILTER = '@@library-search/SET_THUMBNAIL_FILTER',
}

export type LibraryFetchOptions = {
  items?: string[];
};

export type LibrarySearchState = {
  activeThumbnailFilter?: LibrarySearch.Derived.ThumbnailFilter;
  error: Error | null;
  isFetching: boolean;
  isFetchingMore: boolean;
  nextUrl: string | null;
  request: LibrarySearch.Get.Params;
  results: LibrarySearch.Derived.Results;
  selectedTags: string[];
  view: Library.Derived.MediaView;
};
