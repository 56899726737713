import parse from 'date-fns/parse';
import type { AllEffect, ForkEffect } from 'redux-saga/effects';
import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';
import { client } from '@studio/api/api-client/client';
import { STALE_DATA_TIMEOUT } from '@studio/constants/api-constants';
import { CENTRAL_API_V3 } from '@studio/constants/env-variables';
import type { Schedules } from '@studio/types';
import type { ApiClientConfig } from '@studio/types/axios';
import { selectAccessToken, selectCustomerId } from '../authentication/selectors';
import { fetchContentSchedules, resetCachedItems, setFetchRequestTimestamp } from './actions';
import { selectCachedItems, selectTimestamp } from './selectors';
import type { CachedItems } from './types';
import { ActionTypes } from './types';

export const createKeyFromDates = (startDate: Date, endDate: Date): string =>
  `${startDate.toISOString()}-${endDate.toISOString()}`;

export const updateCache = (
  res: Schedules.Get.ContentSchedule[],
  cachedItems: Map<string, CachedItems>,
  startDate: Date,
  endDate: Date
): Map<string, CachedItems> => {
  const parseDateFromDay = (localDateString: string): Date => parse(localDateString, 'yyyy-MM-dd', new Date());

  return new Map(cachedItems).set(createKeyFromDates(startDate, endDate), {
    startDate,
    endDate,
    items: res.map((r) => {
      return {
        ...r,
        startDate: parseDateFromDay(r.startDay),
        endDate: r.endDay ? parseDateFromDay(r.endDay) : undefined,
      };
    }),
  });
};

function* handleFetch(action: ReturnType<typeof fetchContentSchedules.request>) {
  const timestamp: number = yield select(selectTimestamp);
  const cachedItems: Map<string, CachedItems> = yield select(selectCachedItems);
  const isCacheValid = !action.payload.force && Date.now() - timestamp < STALE_DATA_TIMEOUT;
  const startDate = action.payload.startDate;
  const isSilentUpdate = action.payload.isSilentUpdate;
  const endDate = action.payload.endDate;

  if (isCacheValid && cachedItems.has(createKeyFromDates(startDate, endDate)) && !isSilentUpdate) {
    yield put(fetchContentSchedules.cancel());
    return;
  }

  if (!isCacheValid) {
    yield put(resetCachedItems());
  }

  const token: string = yield select(selectAccessToken);
  const customerId: string = yield select(selectCustomerId);

  const config: ApiClientConfig = {
    token,
  };
  const url = `${CENTRAL_API_V3}/customers/${customerId}/schedules?startTime=${startDate.toISOString()}&endTime=${endDate.toISOString()}`;

  try {
    const response: Schedules.Get.ContentSchedule[] = yield call(client, url, config);
    const updatedCache = updateCache(response, cachedItems, startDate, endDate);
    yield put(fetchContentSchedules.success(updatedCache));
    yield put(setFetchRequestTimestamp(Date.now()));
  } catch (error) {
    if (error instanceof Error) {
      yield put(fetchContentSchedules.failure(error));
    }
  }
}

export function* watchFetchRequest<T>(): Generator<ForkEffect<never>, void, T> {
  yield takeLatest(ActionTypes.FETCH_REQUEST, handleFetch);
}

export function* contentScheduleSaga<T>(): Generator<AllEffect<ForkEffect<void>>, void, T> {
  yield all([fork(watchFetchRequest)]);
}
