import { HTTP_METHODS } from '@studio/constants/axios';
import { MEDIA_METADATA_API_V1 } from '@studio/constants/env-variables';
import type { Playlists } from '@studio/types';

export const root = {
  [HTTP_METHODS.GET]: {
    url: `${MEDIA_METADATA_API_V1}/customers/@customerId/media/@mediaId/playlists`,
    response: {} as Playlists.Get.IncludedPlaylist[],
    params: {} as { mediaId: string },
    method: HTTP_METHODS.GET,
  },
};
