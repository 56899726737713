import type { AxiosResponse } from 'axios';
import axios from 'axios';
import * as rax from 'retry-axios';
import type { LatencyDetails } from '@studio/constants/data-center-constants';
import { REQUEST_CANCELLED } from '@studio/constants/data-center-constants';

const axiosInstance = axios.create();

export async function checkDataCenterLatency(
  url: string,
  source: AbortController
): Promise<AxiosResponse<LatencyDetails> | null> {
  let startTime: number;
  let endTime: number;

  const requestInterceptor = axiosInstance.interceptors.request.use(
    (config) => {
      startTime = new Date().getTime();
      return config;
    },
    (error) => Promise.reject(error)
  );

  const responseInterceptor = axiosInstance.interceptors.response.use(
    (response) => {
      endTime = new Date().getTime();
      response.data = { dataCenterName: response.data, duration: endTime - startTime };
      return response;
    },
    (error) => Promise.reject(error)
  );

  try {
    rax.attach(axiosInstance);
    return await axiosInstance.head(url, {
      signal: source.signal,
      raxConfig: {
        instance: axiosInstance,
        retry: 5,
        noResponseRetries: 5,
        backoffType: 'exponential',
        onRetryAttempt: (err): void => {
          const cfg = rax.getConfig(err);
          console.log(`Retry attempt #${cfg?.currentRetryAttempt}`);
        },
      },
    });
  } catch (e) {
    if (axios.isCancel(e)) {
      return Promise.reject(REQUEST_CANCELLED);
    }
    console.error(e);
  } finally {
    axiosInstance.interceptors.request.eject(requestInterceptor);
    axiosInstance.interceptors.response.eject(responseInterceptor);
  }
  return null;
}
