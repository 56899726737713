interface MasonryState {
  breaks: number;
  height: number;
  width: number;
}

export default function createResizeCallback(
  ref: React.RefObject<HTMLDivElement | null>,
  onResize: React.Dispatch<MasonryState>
): React.Dispatch<ResizeObserverEntry[]> {
  return () => {
    if (!ref.current?.firstElementChild) return;
    const referenceStyle = window.getComputedStyle(ref.current.firstElementChild);
    const columnMargin = parseInt(referenceStyle.marginLeft) + parseInt(referenceStyle.marginRight);
    const columnWidth = ref.current.firstElementChild.clientWidth;
    const columns = Math.round(ref.current.clientWidth / (columnWidth + columnMargin));

    if (!columns || !isFinite(columns)) return;

    const heights = Array.from(ref.current.children).reduce<number[]>((acc, child) => {
      if (child.getAttribute('data-masonry-break')) return acc;

      const style = window.getComputedStyle(child);
      const height = parseInt(style.height) + parseInt(style.marginTop) + parseInt(style.marginBottom);

      const column = acc.indexOf(Math.min(...acc));
      (child as HTMLElement).style.order = String(column + 1);

      if (isFinite(height)) acc[column] += height;
      return acc;
    }, new Array(columns).fill(0));

    onResize({ height: Math.max(...heights), breaks: columns - 1, width: columnWidth });
  };
}
