export const MULTISITE_PLANS = {
  ENTERPRISE: '10341fc9-2e81-4213-8c1a-96097c2fb685',
  ENTERPRISE_RECEIVE_ONLY: 'a087a466-e84f-45b1-bd88-d3ae13525621',
  LAN_PLUS_CLOUD: '40351fc9-de81-4483-8c1a-16097c2fb658',
  PROFESSIONAL: '00342fc9-1e81-4283-8cea-96097c2fb654',
  PROFESSIONAL_RECEIVE_ONLY: '1b654ab7-f6a2-47b7-adb3-98c376267cee',
};

const SOCIAL_ONLY_LEGACY_WEB_PLAN = '50351fc5-be85-5348-8c15-56097c2f5615';
const SOCIAL_ONLY_WEB_PLAN = '35d4ecbc-f39a-488a-9c4f-3d64f8361e9b';
const SOCIAL_ONLY_UBP = '4cb6812b-39d1-4ba3-9333-1aba5952bcda';

export const WEB_PLANS = {
  BASIC_EMBED: '4811a878-2e2c-4c21-b60a-9df5e1183fb6',
  ENTERPRISE: '40351fc9-be81-3483-8c1a-26097c2fb616',
  ENTERPRISE_UBP: 'bf422c73-ae3d-43e9-a4d1-7546c9684599',
  PRO_LEGACY_EMBED_ONLY: '20351fc9-ae81-4483-8c1a-16097c2fb619',
  PROFESSIONAL: 'f8362a41-7958-42ad-9db0-a94b738a86dd',
  PROFESSIONAL_UBP: '30bdf24a-f993-4e7e-aa22-dca5d381da1e',
  SOCIAL_ONLY: SOCIAL_ONLY_WEB_PLAN,
  SOCIAL_ONLY_LEGACY: SOCIAL_ONLY_LEGACY_WEB_PLAN,
  SOCIAL_ONLY_LIST: [SOCIAL_ONLY_LEGACY_WEB_PLAN, SOCIAL_ONLY_WEB_PLAN, SOCIAL_ONLY_UBP],
  SOCIAL_ONLY_UBP: SOCIAL_ONLY_UBP,
  STARTER_LEGACY: 'd7e04d47-e349-4cb6-9072-c2002e48baf2',
  STARTER_LITE: '5b604f38-76a9-43fb-9c7f-4ed11df24d07',
  STARTER_LITE_UBP: '4aff6a87-f349-4d35-a231-c74bd4bd71da',
  VIDEO_REVIEW: '825607ee-1616-4d1f-b206-541eb8400227',
};
