import styled from '@emotion/styled';

// small mobile
const MOBILE_WIDTH = 100;

// tablet/mobile
const TABLET_WIDTH = 140;

// desktop
const DESKTOP_WIDTH = 240;

export const LogoContainer = styled.div`
  ${({ theme }) => `
  color: ${theme.palette.brand.resiRed};
  display: flex;
  font-size: ${MOBILE_WIDTH}px;
  padding: ${theme.spacing.l};

  ${theme.mq.lg} {
    font-size: ${TABLET_WIDTH}px;
    padding: ${theme.spacing.l};
  }

  ${theme.mq.xl} {
    font-size: ${DESKTOP_WIDTH}px;
    padding: ${theme.spacing.xl};
  }
  `}
`;
