import * as rax from 'retry-axios';
import { axiosInstance } from '../axios-instance';

rax.attach(axiosInstance);

function getDefaultRaxConfig(): rax.RetryConfig {
  return {
    instance: axiosInstance,
    retry: 3,
    httpMethodsToRetry: ['GET', 'HEAD', 'OPTIONS', 'DELETE', 'PUT', 'PATCH'],
    statusCodesToRetry: [
      [429, 429],
      [502, 504],
    ],
    backoffType: 'exponential',
    checkRetryAfter: false,
    onRetryAttempt: (err): void => {
      const cfg = rax.getConfig(err);
      /* istanbul ignore next */
      console.error(`Retry attempt #${cfg?.currentRetryAttempt}`);
    },
  };
}

export { getDefaultRaxConfig };
