import type { Immutable } from 'immer';
import { useSelector } from 'react-redux';
import { selectCapabilities } from '@studio/store';
import type Permissions from '@studio/store/authentication/permissions';

const useIsAuthorized = (requiredPerms: Immutable<Permissions[]>, includesAny?: boolean): boolean => {
  const capabilities = useSelector(selectCapabilities);
  if (!capabilities) return false;

  return checkPermissions(capabilities, requiredPerms, includesAny);
};

const checkPermissions = (
  capabilities: Permissions[],
  requiredPerms: Immutable<Permissions[]>,
  includesAny?: boolean
) => {
  if (!requiredPerms.length) {
    return true;
  }
  if (includesAny) {
    return requiredPerms.some((permission) => capabilities.includes(permission));
  }

  return requiredPerms.every((permission) => capabilities.includes(permission));
};

export default useIsAuthorized;
