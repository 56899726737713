import { root, id } from './archive';
import { upload } from './upload';

export const archive = {
  ...root,
  id: {
    ...id,
  },
  upload,
};
