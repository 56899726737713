import * as React from 'react';
import { ResiLogoBanner } from '@resi-media/resi-ui';
import { LogoContainer } from './styles';

const LogoHeader = () => (
  <LogoContainer>
    <ResiLogoBanner />
  </LogoContainer>
);

LogoHeader.displayName = 'LogoHeader';

export default LogoHeader;
