import { createAction, createAsyncAction } from 'typesafe-actions';
import type { RtmpDestination } from './types';
import { ActionTypes } from './types';

export const fetchRtmpDestinations = createAsyncAction(
  ActionTypes.FETCH_RTMP_DESTINATIONS,
  ActionTypes.FETCH_RTMP_DESTINATIONS_SUCCESS,
  ActionTypes.FETCH_RTMP_DESTINATIONS_ERROR
)<never, RtmpDestination[], Error>();

export const setFilter = createAction(ActionTypes.FILTER_ITEMS)<string>();
