import type { AllEffect, ForkEffect } from 'redux-saga/effects';
import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';
import { client } from '@studio/api/api-client/client';
import { STALE_DATA_TIMEOUT } from '@studio/constants/api-constants';
import { CENTRAL_API_V3 } from '@studio/constants/env-variables';
import type { ApiClientConfig } from '@studio/types/axios';
import { selectCustomerId, selectAccessToken } from '../authentication';
import { fetchSocialMediaAccountsAsync, fetchStatusForSocialMediaAccountsAsync } from './actions';
import { selectItems, selectSocialTimestamp } from './selectors';
import type { SocialMediaAccount, SocialMediaAccountStatusResponse } from './types';
import { ActionTypes } from './types';

function* fetchSocialMediaAccounts(action: ReturnType<typeof fetchSocialMediaAccountsAsync.request>) {
  const timestamp: number = yield select(selectSocialTimestamp);
  if (!action.payload?.force && Date.now() - timestamp < STALE_DATA_TIMEOUT) {
    yield put({ type: ActionTypes.FETCH_REQUEST_CANCEL });
    return;
  }

  const customerId: string = yield select(selectCustomerId);
  const url = `${CENTRAL_API_V3}/customers/${customerId}/socialmedia`;
  const token: string = yield select(selectAccessToken);
  const config: ApiClientConfig = { token };

  try {
    const response: SocialMediaAccount[] = yield call(client, url, config);
    yield put(fetchSocialMediaAccountsAsync.success(response));

    if (!action.payload?.omitStatusSync) {
      yield put(fetchStatusForSocialMediaAccountsAsync.request());
    }
  } catch (error) {
    if (error instanceof Error) {
      yield put(fetchSocialMediaAccountsAsync.failure(error));
    }
  }
}

function* fetchStatusForAccounts() {
  const accounts: SocialMediaAccount[] = yield select(selectItems);
  const token: string = yield select(selectAccessToken);
  const config: ApiClientConfig = { token };

  const statusCalls = [];
  for (const account of accounts) {
    const checkliveUrl = `${CENTRAL_API_V3}/customers/${account.customerId}/socialmedia/${account.uuid}/checklive`;
    statusCalls.push(call(client, checkliveUrl, config));
  }

  try {
    const statusResponse: SocialMediaAccountStatusResponse[] = yield all(statusCalls);
    yield put(fetchStatusForSocialMediaAccountsAsync.success(statusResponse));
  } catch (error) {
    if (error instanceof Error) {
      yield put(fetchStatusForSocialMediaAccountsAsync.failure(error));
    }
  }
}

export function* watchFetchRequest<T>(): Generator<ForkEffect<never>, void, T> {
  yield takeLatest(ActionTypes.FETCH_REQUEST, fetchSocialMediaAccounts);
}

export function* watchFetchStatus<T>(): Generator<ForkEffect<never>, void, T> {
  yield takeLatest(ActionTypes.FETCH_STATUS_REQUEST, fetchStatusForAccounts);
}

export function* socialMediaAccountsSaga<T>(): Generator<AllEffect<ForkEffect<void>>, void, T> {
  yield all([fork(watchFetchRequest), fork(watchFetchStatus)]);
}
