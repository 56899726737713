import * as React from 'react';
import {
  CopyOutlined,
  DeleteOutlined,
  RollbackOutlined,
  GlobalOutlined,
  HomeOutlined,
  LockOutlined,
} from '@ant-design/icons';
import { css } from '@emotion/react';
import { Draft, EditAltIcon, IconButton, Inline, Stack, Text, useBreakpoint } from '@resi-media/resi-ui';
import { match } from 'ts-pattern';
import { useCues, usePlayer } from '@studio/contexts';
import { usePrefix } from '@studio/hooks';
import { Cues } from '@studio/types';
import { MAX_CUE_TITLE } from '../../constants';
import { S } from '../../styles';
import { DeleteCueModal } from '../DeleteCueModal';
import { TimelineSegment } from '../TimelineSegment';

type _Props = Cues.Derived.CueWithUser & { segmentProps: TimelineSegment.Props };

const CueView = ({ segmentProps, ...cue }: _Props) => {
  const { name, position, user, uuid, visibility } = cue;
  const { commonT, prefixNS } = usePrefix('components:', 'cuesTimeline');
  const { setModal, setModalState } = Draft.ModalContext.useModal<DeleteCueModal.State>();
  const { setVideoTime } = usePlayer();
  const mediaQuery = useBreakpoint();
  const { blinkId, canShareCues, deleteCue, hoverId, setCopyCue, setEditId } = useCues();
  const showIcons = hoverId && hoverId === uuid;

  React.useEffect(() => {
    setModalState({ isLoading: deleteCue.isLoading, error: deleteCue.error });
  }, [deleteCue.error, deleteCue.isLoading, setModalState]);

  const actionMenu = React.useMemo(() => {
    return (
      <>
        <Draft.Tooltip content={<>{prefixNS('goToPosition')}</>}>
          <IconButton
            cursor="pointer"
            dataTestId="cue-view__icon--view"
            label={prefixNS('goToPosition')}
            onClick={() => setVideoTime(position)}
            sizeVariant={mediaQuery.xl ? 's' : 'm'}
          >
            <RollbackOutlined />
          </IconButton>
        </Draft.Tooltip>
        {name !== prefixNS('startOfVideo') && (
          <>
            <Draft.Tooltip content={<>{prefixNS('copyCue')}</>}>
              <IconButton
                cursor="pointer"
                data-testid="cue-view__icon--copy"
                label={commonT('duplicate')}
                onClick={() => {
                  setCopyCue({
                    ...cue,
                    uuid: `${commonT('copy')}-${uuid}`,
                    name: `${commonT('copyOf')} ${name}`.substring(0, MAX_CUE_TITLE),
                    visibility: canShareCues ? cue.visibility : Cues.CUE_VISIBILITY.PRIVATE,
                  });
                }}
                sizeVariant={mediaQuery.xl ? 's' : 'm'}
              >
                <CopyOutlined />
              </IconButton>
            </Draft.Tooltip>
            <Draft.Tooltip content={<>{prefixNS('editCue')}</>}>
              <IconButton
                cursor="pointer"
                data-testid="cue-view__icon--edit"
                label={commonT('editIcon')}
                onClick={() => {
                  setEditId(uuid);
                }}
                sizeVariant={mediaQuery.xl ? 's' : 'm'}
              >
                <EditAltIcon />
              </IconButton>
            </Draft.Tooltip>
            <Draft.Tooltip content={<>{prefixNS('deleteCue')}</>}>
              <IconButton
                colorVariant="negative"
                cursor="pointer"
                data-testid="cue-view__icon--delete"
                label={prefixNS('deleteCue')}
                onClick={() => {
                  setModal(() => <DeleteCueModal apiCall={deleteCue.callApi} {...cue} />, {
                    dataTestId: 'cue-view__delete-modal',
                    minHVariant: 's',
                  });
                }}
                sizeVariant={mediaQuery.xl ? 's' : 'm'}
              >
                <DeleteOutlined />
              </IconButton>
            </Draft.Tooltip>
          </>
        )}
      </>
    );
  }, [
    canShareCues,
    commonT,
    cue,
    deleteCue.callApi,
    mediaQuery.xl,
    name,
    position,
    prefixNS,
    setCopyCue,
    setEditId,
    setModal,
    setVideoTime,
    uuid,
  ]);

  return (
    <div css={(theme) => css([S.segment(theme), blinkId === uuid && S.segmentFlash(theme)])} data-testid="cue-view">
      <TimelineSegment {...segmentProps} />
      <Stack dataTestId="cue-view__info" gap="m" overflow="hidden" pb="m" pl={{ xs: 'm', lg: 'l' }} pr="m" pt="xs">
        <Text
          css={(theme) => css`
            margin-top: ${theme.spacing.s};
          `}
          dataTestId="cue-view__timestamp"
          variant="body1"
        >
          {position}
        </Text>
        <Stack gap="xs">
          <Text title={name} truncate>
            {name}
          </Text>
          <Text colorVariant="secondary">{user}</Text>
        </Stack>
        {showIcons && !mediaQuery.lg && (
          <Inline alignItems="center" dataTestId="cue-view__actions--mobile" gap="m" justifyContent="flex-start">
            {actionMenu}
          </Inline>
        )}
      </Stack>
      <Inline
        alignItems={mediaQuery.lg ? 'center' : 'flex-start'}
        dataTestId="cue-view__actions"
        gap={{ xs: 'm', xl: 'xs' }}
        justifyContent="flex-end"
        mt={{ xs: 's', lg: 0 }}
        pt={{ xs: 'xs', lg: 0 }}
      >
        {showIcons && mediaQuery.lg ? (
          actionMenu
        ) : (
          <Text as="div" dataTestId="cue-view__badge" variant={mediaQuery.xl ? 'label' : 'body1'}>
            <Inline alignItems="center">
              {match(visibility)
                .with(Cues.CUE_VISIBILITY.PRIVATE, () => (
                  <>
                    <LockOutlined />
                    {mediaQuery.xl && prefixNS('onlyMe')}
                  </>
                ))
                .with(Cues.CUE_VISIBILITY.SHARED, () => (
                  <>
                    <GlobalOutlined />
                    {mediaQuery.xl && commonT('organization')}
                  </>
                ))
                .otherwise(() => (
                  <>
                    <HomeOutlined />
                    {mediaQuery.xl && commonT('public')}
                  </>
                ))}
            </Inline>
          </Text>
        )}
      </Inline>
    </div>
  );
};

CueView.displayName = 'CueView';

export default CueView;
