import type { Option } from '@resi-media/resi-ui';

export interface FBAuthResponse extends fb.AuthResponse {
  data_access_expiration_time?: number;
}

export interface FBStatusResponse extends fb.StatusResponse {
  authResponse: FBAuthResponse;
  name?: string;
}

export interface FacebookMeResponse {
  email: string;
  id: string;
  name: string;
}

export interface FBAuthInfo {
  accessToken?: string;
  dataAccessExpirationTime?: number;
}

export interface FacebookAccount {
  accessToken?: string;
  description?: string;
  expirationTime?: number;
  name?: string;
  // API calls it refreshToken, but accessToken is getting passed.
  refreshToken?: string;
  type: SocialMediaTypes;
  userId?: string;
}

export interface YouTubeChannel {
  accessToken?: string;
  description?: string;
  name?: string;
  partnerChannelId?: string;
  // API calls it refreshToken, but accessToken is getting passed.
  refreshToken?: string;
  type: SocialMediaTypes;
}

export interface UpdateSocialMediaAccountAccess {
  accessToken?: string;
  expirationTime?: number;
  // API calls it refreshToken, but accessToken is getting passed.
  refreshToken?: string;
}

export interface YouTubeChannelInfo extends UpdateSocialMediaAccountAccess {
  description?: string;
  id?: string;
  title: string;
}

export interface SocialMediaAccountState {
  error: Error | null;
  fbAuthError: Error | null;
  fbAuthInfo: FBAuthInfo | null;
  filter: string;
  isFetching: boolean;
  items: SocialMediaAccount[];
  timeStamp: number;
}

export interface SocialMediaAccount {
  analyticDataCollection: boolean;
  customerId: string;
  description: string;
  expiresAt?: string | null;
  name: string;
  partnerChannelId: string | null;
  status?: string;
  type: 'fb' | 'yt';
  userId: string | null;
  uuid: string;
  viewUrl: string | null;
}

export interface SocialMediaAccountOptions extends Option {
  data?: SocialMediaAccount;
  invalidStatus?: string;
}

export interface SocialMediaAccountStatusResponse {
  expiresAt: string | null;
  simulcastCode: string;
}

interface YouTubeThumbnail {
  height: string;
  url: string;
  width: string;
}

interface YouTubeSnippet {
  description: string;
  localized: {
    description: string;
    title: string;
  };
  publishedAt: string;
  thumbnails: {
    default: YouTubeThumbnail;
    high: YouTubeThumbnail;
    medium: YouTubeThumbnail;
  };
  title: string;
}

interface YouTubeResponseItems {
  etag: string;
  id: string;
  kind: string;
  snippet?: YouTubeSnippet;
}

export interface YouTubeResponse {
  etag: string;
  items: YouTubeResponseItems[];
  kind: string;
  pageInfo: {
    resultsPerPage: number;
    totalResults: number;
  };
}

export type ModalState = {
  account: SocialMediaAccount;
  errorMessage?: string;
  isGrantingAccess?: boolean;
  isSubmitting: boolean;
  onSuccess: () => void;
};

export const SOCIAL_MEDIA_TYPES = {
  FACEBOOK: 'fb',
  YOUTUBE: 'yt',
} as const;
export type SocialMediaTypes = (typeof SOCIAL_MEDIA_TYPES)[keyof typeof SOCIAL_MEDIA_TYPES];

export const ACCOUNT_SIMULCAST_CODE = {
  OK: 'OK',
  TOKEN_INVALID: 'TOKEN_INVALID',
  NOT_ENABLED: 'NOT_ENABLED',
} as const;

export const ACCOUNT_ACCESS_STATUS = {
  EXPIRED: 'accessExpired',
  WILL_EXPIRE: 'accessExpires',
  NO_ACCESS: 'noAccess',
  NOT_ENABLED: 'notEnabled',
  ACCESS_VERIFIED: 'accessVerified',
  LOADING: 'loading',
} as const;

export enum ActionTypes {
  FB_AUTH_ERROR = '@@socialmediaaccounts/FB_AUTH_ERROR',
  FB_AUTH_RESET = '@@socialmediaaccounts/FB_AUTH_RESET',
  FB_AUTH_SUCCESS = '@@socialmediaaccounts/FB_AUTH_SUCCESS',
  FETCH_REQUEST = '@@socialmediaaccounts/FETCH_REQUEST',
  FETCH_REQUEST_CANCEL = '@@socialmediaaccounts/FETCH_REQUEST_CANCEL',
  FETCH_REQUEST_ERROR = '@@socialmediaaccounts/FETCH_REQUEST_ERROR',
  FETCH_REQUEST_SUCCESS = '@@socialmediaaccounts/FETCH_REQUEST_SUCCESS',
  FETCH_REQUEST_TIMESTAMP = '@@socialmediaaccounts/FETCH_REQUEST_TIMESTAMP',
  FETCH_STATUS_ERROR = '@@socialmediaaccounts/FETCH_STATUS_ERROR',
  FETCH_STATUS_REQUEST = '@@socialmediaaccounts/FETCH_STATUS_REQUEST',
  FETCH_STATUS_SUCCESS = '@@socialmediaaccounts/FETCH_STATUS_SUCCESS',
  FILTER_ITEMS = '@@socialmediaaccounts/FILTER_ITEMS',
}
