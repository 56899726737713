import { HTTP_METHODS } from '@studio/constants/axios';
import { CENTRAL_API_V4 } from '@studio/constants/env-variables';
import type { DestinationGroups } from '@studio/types';

export const root = {
  [HTTP_METHODS.GET]: {
    url: `${CENTRAL_API_V4}/customers/@customerId/destinationgroups`,
    query: {} as {
      channelId?: string;
      rtmpDestinationId?: string;
      webEventProfileId?: string;
    },
    response: [] as DestinationGroups.Get.DestinationGroup[],
    method: HTTP_METHODS.GET,
  },
  [HTTP_METHODS.POST]: {
    url: `${CENTRAL_API_V4}/customers/@customerId/destinationgroups`,
    request: {} as DestinationGroups.Post.DestinationGroup,
    method: HTTP_METHODS.POST,
  },
};

export const id = {
  [HTTP_METHODS.DELETE]: {
    url: `${CENTRAL_API_V4}/customers/@customerId/destinationgroups/@id`,
    params: {} as { id: string },
    method: HTTP_METHODS.DELETE,
  },
  [HTTP_METHODS.GET]: {
    url: `${CENTRAL_API_V4}/customers/@customerId/destinationgroups/@id`,
    params: {} as { id: string },
    response: {} as DestinationGroups.Get.DestinationGroup,
    method: HTTP_METHODS.GET,
  },
  [HTTP_METHODS.PUT]: {
    url: `${CENTRAL_API_V4}/customers/@customerId/destinationgroups/@id`,
    params: {} as { id: string },
    request: {} as DestinationGroups.Put.DestinationGroup,
    method: HTTP_METHODS.PUT,
  },
};
