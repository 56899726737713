import { HTTP_METHODS } from '@studio/constants/axios';
import { CENTRAL_API_V3 } from '@studio/constants/env-variables';
import type { Library } from '@studio/types';

export const root = {
  [HTTP_METHODS.GET]: {
    url: `${CENTRAL_API_V3}/vodPlans`,
    response: {} as Library.Get.VodPlan[],
    method: HTTP_METHODS.GET,
  },
};

export const id = {
  [HTTP_METHODS.GET]: {
    url: `${CENTRAL_API_V3}/vodPlans/@id`,
    response: {} as Library.Get.VodPlan,
    params: {} as { id: string },
    method: HTTP_METHODS.GET,
  },
};
