import { produce } from 'immer';
import type { ActionType } from 'typesafe-actions';
import { createReducer } from 'typesafe-actions';
import type { Encoders } from '@studio/types';
import { set } from './actions';
import type { EncodersState } from './types';

type Action = ActionType<typeof set>;

export const initialState: EncodersState = {
  items: [],
  updatingEncoders: new Map<string, Encoders.Derived.CombinedEncoder>(),
};

const reducer = createReducer<EncodersState, Action>(initialState)
  .handleAction(
    set.encoders,
    produce((draft: EncodersState, action: ActionType<typeof set.encoders>) => {
      draft.items = action.payload;
    })
  )
  .handleAction(
    set.updatingEncoders,
    produce((draft: EncodersState, action: ActionType<typeof set.updatingEncoders>) => {
      draft.updatingEncoders = draft.updatingEncoders.set(action.payload.uuid, action.payload);
    })
  )
  .handleAction(
    set.removeUpdatingEncoderId,
    produce((draft: EncodersState, action: ActionType<typeof set.removeUpdatingEncoderId>) => {
      draft.updatingEncoders.delete(action.payload);
    })
  );

export default reducer;
