import type { SagaIterator } from 'redux-saga';
import type { AllEffect, ForkEffect } from 'redux-saga/effects';
import { all, cancelled, put, fork, call, select, takeLatest } from 'redux-saga/effects';
import { client } from '@studio/api/api-client/client';
import { CENTRAL_API_V2 } from '@studio/constants/env-variables';
import type { Monitors } from '@studio/types';
import type { ApiClientConfig } from '@studio/types/axios';
import { selectAccessToken } from '../../authentication';
import { fetch } from './actions';
import { ActionTypes } from './types';

function* handleFetchEncoders(): SagaIterator {
  const source = new AbortController();
  const token: string = yield select(selectAccessToken);

  const url = `${CENTRAL_API_V2}/monitors/encoders`;
  const config: ApiClientConfig = { token };

  try {
    const response: Monitors.Get.V2.EncoderResponse = yield call(client, url, config);

    yield put(
      fetch.encoders.success({
        value: response.onlineEncoders,
        timestamp: Date.now(),
      })
    );
  } catch (error) {
    console.log({ error });
    if (error instanceof Error) {
      yield put(fetch.encoders.failure(error));
    }
  } finally {
    if (yield cancelled()) {
      source.abort('cancelled');
      yield put(fetch.encoders.cancel());
    }
  }
}

export function* watchFetchEncodersRequest<T>(): Generator<ForkEffect<never>, void, T> {
  yield takeLatest(ActionTypes.FETCH_ENCODERS_REQUEST, handleFetchEncoders);
}

function* handleFetchErroredEncoders(): SagaIterator {
  const source = new AbortController();
  const token: string = yield select(selectAccessToken);

  const url = `${CENTRAL_API_V2}/monitors/encoders/state/error`;
  const config: ApiClientConfig = { token };

  try {
    const response: Monitors.Get.V2.ErroredEncoders[] = yield call(client, url, config);

    yield put(
      fetch.erroredEncoders.success({
        value: response,
        timestamp: Date.now(),
      })
    );
  } catch (error) {
    console.log({ error });
    if (error instanceof Error) {
      yield put(fetch.erroredEncoders.failure(error));
    }
  } finally {
    if (yield cancelled()) {
      source.abort('cancelled');
      yield put(fetch.erroredEncoders.cancel());
    }
  }
}

export function* watchFetchErroredEncodersRequest<T>(): Generator<ForkEffect<never>, void, T> {
  yield takeLatest(ActionTypes.FETCH_ERRORED_ENCODERS_REQUEST, handleFetchErroredEncoders);
}

function* handleFetchDecoders(): SagaIterator {
  const source = new AbortController();
  const token: string = yield select(selectAccessToken);

  const url = `${CENTRAL_API_V2}/monitors/players`;
  const config: ApiClientConfig = { token };

  try {
    const response: Monitors.Get.V2.DecoderResponse = yield call(client, url, config);

    yield put(
      fetch.decoders.success({
        value: response.onlinePlayers,
        timestamp: Date.now(),
      })
    );
  } catch (error) {
    console.log({ error });
    if (error instanceof Error) {
      yield put(fetch.decoders.failure(error));
    }
  } finally {
    if (yield cancelled()) {
      source.abort('cancelled');
      yield put(fetch.decoders.cancel());
    }
  }
}

export function* watchFetchDecodersRequest<T>(): Generator<ForkEffect<never>, void, T> {
  yield takeLatest(ActionTypes.FETCH_DECODERS_REQUEST, handleFetchDecoders);
}

export function* monitorSaga<T>(): Generator<AllEffect<ForkEffect<void>>, void, T> {
  yield all([fork(watchFetchEncodersRequest), fork(watchFetchErroredEncodersRequest), fork(watchFetchDecodersRequest)]);
}
