import { createAsyncAction } from 'typesafe-actions';
import type { Decoders, Encoders, Monitors } from '@studio/types';
import { ActionTypes } from './types';

export const fetch = {
  all: createAsyncAction(
    ActionTypes.FETCH_REQUEST,
    ActionTypes.FETCH_REQUEST_SUCCESS,
    ActionTypes.FETCH_REQUEST_ERROR,
    ActionTypes.FETCH_REQUEST_CANCEL
  )<undefined, Monitors.Get.Response, Error, undefined>(),
  encoders: createAsyncAction(
    ActionTypes.FETCH_ENCODERS_REQUEST,
    ActionTypes.FETCH_ENCODERS_REQUEST_SUCCESS,
    ActionTypes.FETCH_ENCODERS_REQUEST_ERROR,
    ActionTypes.FETCH_ENCODERS_REQUEST_CANCEL
  )<undefined, Encoders.Get.V2.Status[], Error, undefined>(),
  decoders: createAsyncAction(
    ActionTypes.FETCH_DECODERS_REQUEST,
    ActionTypes.FETCH_DECODERS_REQUEST_SUCCESS,
    ActionTypes.FETCH_DECODERS_REQUEST_ERROR,
    ActionTypes.FETCH_DECODERS_REQUEST_CANCEL
  )<undefined, Decoders.Get.Status[], Error, undefined>(),
};
