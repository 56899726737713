import * as React from 'react';
import { useForkRef } from '@resi-media/resi-ui';
import type { Union } from 'ts-toolbelt';
import { webplayerEnvAttribute, webplayerStudioParam } from '@studio/constants/env-variables';
import { usePlayer } from '@studio/contexts';
import { useIsMounted, useThrottle } from '@studio/hooks';

const EMBED_TYPE = {
  LIBRARY: 'library',
  PLAYLIST: 'playlist',
  WEB_BROADCAST: 'event',
  WEB_CHANNEL: 'webchannel',
} as const;
type _EmbedType = (typeof EMBED_TYPE)[keyof typeof EMBED_TYPE];

const KIND = {
  EMBED_ID: 'embed_id',
  MANIFEST: 'manifest',
  MANIFESTS: 'manifests',
  PLAYLIST: 'playlist',
} as const;
type _Kind = (typeof KIND)[keyof typeof KIND];

type _Props = Union.Strict<
  | {
      kind: Extract<_Kind, 'manifests'>;
      locator: {
        dash: string;
        hls: string;
      };
    }
  | { kind: Exclude<_Kind, 'manifests'>; locator: string }
> & {
  autoplay?: boolean;
  dataTestId?: string;
  startPos?: string;
  type?: _EmbedType;
};

const PlayerScriptInternal = (
  { autoplay, dataTestId: dataTestIdProp, kind, locator, startPos, type }: _Props,
  ref: React.ForwardedRef<HTMLDivElement>
) => {
  const videoPlayer = React.useRef<HTMLDivElement>(null);
  const handleRef = useForkRef(ref, videoPlayer);
  const dataTestId = dataTestIdProp ?? 'player-script';
  const isMounted = useIsMounted();
  const throttle = useThrottle();
  const scriptLoadCounter = React.useRef(0);
  const { setCurrentTime, setPlayerDetails, setPlayerState } = usePlayer();

  React.useEffect(
    // Unable to test observers currently
    /* istanbul ignore next */
    function observeVideoPlayer() {
      if (!videoPlayer.current) {
        return;
      }
      const observer = new MutationObserver(() => {
        const videoElement = document.getElementById('resi-video') as HTMLVideoElement | null;
        // Can't test but need this check in here if for some reason the player script is modified
        if (!videoElement) {
          return;
        }

        videoElement.onloadedmetadata = () => {
          if (!isMounted()) {
            return;
          }
          setPlayerDetails({
            timeRange: videoElement.seekable,
            duration: videoElement.duration,
            videoElement: videoElement,
            videoContainer: document.getElementById('resi-videoContainer') as HTMLElement,
          });

          videoElement.onpause = () => setPlayerState('pause');
          videoElement.onplay = () => setPlayerState('play');
          videoElement.ontimeupdate = () => {
            const { currentTime, paused, seekable } = videoElement;
            const timeRangesObject = seekable;
            const timeRanges = [];
            //Go through the object and output an array
            for (let count = 0; count < timeRangesObject.length; count++) {
              timeRanges.push([timeRangesObject.start(count), timeRangesObject.end(count)]);
            }
            const recordCurrentTime = () => {
              setCurrentTime(currentTime);
            };
            if (paused) {
              recordCurrentTime();
            } else {
              throttle(recordCurrentTime, 2000);
            }
          };
        };
        observer.disconnect();
      });

      observer.observe(videoPlayer.current, { childList: true, subtree: true });

      return () => {
        observer.disconnect();
      };
    },
    [setCurrentTime, setPlayerDetails, setPlayerState, isMounted, throttle]
  );

  const LOADER_ID = 'resi-loader-script';
  const getLoaderScript = React.useCallback(() => document.getElementById(LOADER_ID), []);

  React.useEffect(() => {
    if (!getLoaderScript() && !scriptLoadCounter.current) {
      scriptLoadCounter.current = 1;
      setPlayerState('load');
      const script = document.createElement('script');
      script.setAttribute('src', 'https://control.resi.io/webplayer/loader.min.js');
      script.setAttribute('id', LOADER_ID);
      script.setAttribute('data-testid', LOADER_ID);
      script.setAttribute('type', 'application/javascript');
      script.setAttribute('crossOrigin', 'anonymous');
      document.body.append(script);
    }

    return () => {
      const scripts = document.body.getElementsByTagName('script');
      let i = scripts.length - 1;
      while (i) {
        if (scripts[i].id !== 'pendo__script') {
          document.body.removeChild(scripts[i]);
        }
        i--;
      }
    };
  }, [setPlayerState, getLoaderScript]);

  const dataEncodedProp = React.useMemo(() => {
    if (kind === KIND.MANIFEST) {
      return { 'data-encoded-manifest': window.btoa(locator) };
    } else if (kind === KIND.MANIFESTS) {
      return {
        'data-encoded-manifests': window.btoa(JSON.stringify(locator)),
      };
    }
    return { 'data-embed-id': locator };
  }, [kind, locator]);

  return (
    <div
      style={{
        width: '100%',
        background: 'black',
        position: 'relative',
        overflow: 'hidden',
        paddingTop: '56.25%',
      }}
    >
      <div
        ref={handleRef}
        data-autoplay={autoplay}
        data-env={webplayerEnvAttribute}
        data-iframe-id="player-frame"
        data-mute={false}
        data-start-pos={startPos}
        data-studio={webplayerStudioParam}
        data-testid={dataTestId}
        data-type={type}
        id="resi-video-player"
        style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', border: 'none' }}
        {...dataEncodedProp}
      />
    </div>
  );
};

PlayerScriptInternal.displayName = 'PlayerScriptInternal';

const PlayerScript = Object.assign(React.forwardRef(PlayerScriptInternal), {
  displayName: 'PlayerScript',
  EMBED_TYPE,
  KIND,
});
/* eslint-disable import/export */
export default PlayerScript;

// eslint-disable-next-line no-redeclare
export namespace PlayerScript {
  export type EmbedType = _EmbedType;
  export type Kind = _Kind;
}
