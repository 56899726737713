import type { CombinedState } from 'redux';
import { createSelector } from 'reselect';
import { DESTINATION, VIDEO_STATUS } from '@studio/constants/content-destination';
import { sortByAlpha } from '@studio/helpers/sortByAlpha';
import { sortByAlphaIgnoreCase } from '@studio/helpers/sortByAlphaIgnoreCase';
import type { ContentDestinations } from '@studio/types';
import type { RootState } from '../root-reducer';
import type { ContentDestinationsState } from './types';
import { SortOptions } from './types';

const validType: ContentDestinations.Get.Destination[] = [DESTINATION.EMBED, DESTINATION.SAVEONLY];

const validStatus: ContentDestinations.Get.VideoStatus[] = [
  VIDEO_STATUS.STARTED,
  VIDEO_STATUS.STARTING,
  VIDEO_STATUS.STOPPED,
  VIDEO_STATUS.STOPPING,
  VIDEO_STATUS.ABORTED,
  VIDEO_STATUS.ERROR,
];

const validSaveOnlyStatus: ContentDestinations.Get.VideoStatus[] = [
  VIDEO_STATUS.STOPPED,
  VIDEO_STATUS.ABORTED,
  VIDEO_STATUS.ERROR,
];
export const selectContentDestinationsState = (state: RootState): CombinedState<ContentDestinationsState> =>
  state.contentDestinations;

export const selectItems = createSelector(
  selectContentDestinationsState,
  (state): ContentDestinations.Get.ContentDestination[] =>
    state.items.filter((item) => {
      if (validType.includes(item.type)) {
        if (item.type === DESTINATION.SAVEONLY) {
          if (validSaveOnlyStatus.includes(item.status)) {
            return true;
          }
        } else if (validStatus.includes(item.status)) {
          return true;
        }
      }
      return false;
    })
);

export const selectStartedItems = createSelector(
  selectContentDestinationsState,
  (state): ContentDestinations.Get.ContentDestination[] =>
    state.items.filter((item) => {
      return item.status === 'STARTED' || item.status === 'STARTING';
    })
);

export const selectSortBy = createSelector(selectContentDestinationsState, (state): string => state.sortBy);

export const sortItems = createSelector(
  selectItems,
  selectSortBy,
  (items, sortBy): ContentDestinations.Get.ContentDestination[] => {
    if (sortBy === SortOptions.NAME_AtoZ) {
      return items
        .slice()
        .sort(sortByAlphaIgnoreCase((destination: ContentDestinations.Get.ContentDestination) => destination.title));
    } else if (sortBy === SortOptions.NAME_ZtoA) {
      return items
        .slice()
        .sort(
          sortByAlphaIgnoreCase((destination: ContentDestinations.Get.ContentDestination) => destination.title, true)
        );
    } else if (sortBy === SortOptions.MOST_RECENT) {
      return items
        .slice()
        .sort(
          sortByAlpha(
            (destination: ContentDestinations.Get.ContentDestination) => destination.startRequestTime ?? '',
            true
          )
        );
    } else if (sortBy === SortOptions.OLDEST) {
      return items
        .slice()
        .sort(
          sortByAlpha((destination: ContentDestinations.Get.ContentDestination) => destination.startRequestTime ?? '')
        );
    }

    return items;
  }
);

export const selectStoppedItems = createSelector(selectContentDestinationsState, (state): string[] => state.stopped);
