export const buildQueryString = <T extends Record<PropertyKey, unknown>>(params: T, initQueryString = ''): string => {
  const searchParams = new URLSearchParams(initQueryString);
  for (const param in params) {
    const value = params[param];
    if (value === null || value === undefined) {
      continue;
    }
    const valueString = String(value);
    if (valueString.length === 0) {
      continue;
    }
    searchParams.set(param, valueString);
  }
  return decodeURIComponent(searchParams.toString());
};
