import * as React from 'react';
import { produce } from 'immer';
import { parseTimeIntoSeconds } from '@studio/helpers';
import { AudioContextProvider } from './contexts/audio';
import type { playerStateActions, PlayerState } from './reducer';
import { PlayerReducer, PLAYER_ACTION_TYPES } from './reducer';

type PlayerDetails = {
  duration: number;
  timeRange: TimeRanges;
  videoContainer: HTMLElement;
  videoElement: HTMLVideoElement;
};

type PlayerContextHook = PlayerState & {
  setCurrentTime: (v: number) => void;
  setPlayerDetails: (v: PlayerDetails) => void;
  setPlayerState: (v: playerStateActions) => void;
  setVideoTime: (v: number | string) => void;
};
/**
 * A helper to create a Context and Provider with no upfront default value, and
 * without having to check for undefined all the time.
 */
const createCtx = <A extends Record<string, unknown> | null>() => {
  const ctx = React.createContext<A | undefined>(undefined);

  const useCtx = () => {
    const c = React.useContext(ctx);

    if (c === undefined) throw new Error('usePlayer must be used within PlayerProvider');

    return c;
  };

  return [useCtx, ctx] as const; // 'as const' makes TypeScript infer a tuple
};

const [usePlayer, PlayerContext] = createCtx<PlayerContextHook>();

const curriedReducerFunction = produce(PlayerReducer);

const PlayerProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, dispatch] = React.useReducer(curriedReducerFunction, {
    currentTime: 0,
    playerState: 'loading',
    videoContainer: null,
  });
  const videoRef = React.useRef<HTMLVideoElement | null>(null);

  const setVideoTime = React.useCallback((time: number | string) => {
    if (!videoRef.current) return;

    videoRef.current.currentTime = parseTimeIntoSeconds(time);
  }, []);

  const setCurrentTime = React.useCallback(
    (time: number) =>
      dispatch({
        type: PLAYER_ACTION_TYPES.SET_CURRENT_TIME,
        payload: time,
      }),
    []
  );

  const setPlayerState = React.useCallback(
    (playerState: playerStateActions) =>
      dispatch({
        type: PLAYER_ACTION_TYPES.SET_PLAYER_STATE,
        payload: playerState,
      }),
    []
  );

  const setPlayerDetails = React.useCallback(({ videoElement, ...details }: PlayerDetails) => {
    videoRef.current = videoElement;
    const spreadDetails: Omit<PlayerDetails, 'videoElement'> = details;

    dispatch({
      type: PLAYER_ACTION_TYPES.SET_PLAYER_DETAILS,
      payload: spreadDetails,
    });
  }, []);

  return (
    <PlayerContext.Provider
      value={{
        setCurrentTime,
        setPlayerState,
        setPlayerDetails,
        setVideoTime,
        videoElement: videoRef.current,
        ...state,
      }}
    >
      <AudioContextProvider isPlaying={state.playerState === 'playing'} videoElement={videoRef.current}>
        {children}
      </AudioContextProvider>
    </PlayerContext.Provider>
  );
};

const PlayerConsumer = PlayerContext.Consumer;

export default PlayerContext;

export { PlayerProvider, PlayerConsumer, usePlayer };
export type { PlayerContextHook };
