import * as React from 'react';
import { Draft, Inline } from '@resi-media/resi-ui';
import { isMobile, isSafari } from 'react-device-detect';
import { useAudioContext } from '@studio/contexts';
import { usePrefix } from '@studio/hooks';
import { AudioMeter } from '../AudioMeter';

const AudioMeters = () => {
  const { prefixNS } = usePrefix('components:', 'audioMeter');
  const { audio = [0, 0], startAudioCapture, stopAudioCapture } = useAudioContext();

  React.useEffect(() => {
    startAudioCapture();

    return () => {
      stopAudioCapture();
    };
  }, [startAudioCapture, stopAudioCapture]);

  return (
    <>
      {isMobile || isSafari ? (
        <Draft.AlertBanner dataTestId="audio-meters__banner" variant="warning">
          {prefixNS('notSupported')}
        </Draft.AlertBanner>
      ) : (
        <Inline gap="m">
          {audio.map((channel, idx) => {
            return (
              <AudioMeter key={`${idx + 1}-${idx}`} channelNumber={idx + 1} showLabels={idx === 0} value={channel} />
            );
          })}
        </Inline>
      )}
    </>
  );
};

AudioMeters.displayName = 'AudioMeters';

export default AudioMeters;
