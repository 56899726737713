import { useSelector } from 'react-redux';
import { match } from 'ts-pattern';
import { isValidEmail } from '@studio/helpers/isValidEmail';
import { useIsEmailUnverified } from '@studio/hooks';
import { selectUserName } from '@studio/store';
import { InvalidEmailBanner } from '../InvalidEmailBanner';
import { VerifyEmailBanner } from '../VerifyEmailBanner';

const PageBanner = () => {
  const userName = useSelector(selectUserName);
  const isEmailUnverified = useIsEmailUnverified();

  return match({ isEmailInvalid: !isValidEmail(userName), isEmailUnverified })
    .with({ isEmailInvalid: true }, () => <InvalidEmailBanner />)
    .with({ isEmailUnverified: true }, () => <VerifyEmailBanner />)
    .otherwise(() => null);
};

PageBanner.displayName = 'PageBanner';

export default PageBanner;
