import { Draft, Inline, Stack, Text } from '@resi-media/resi-ui';
import { CopyToClipboard } from '@studio/components/CopyToClipboard';
import { usePrefix } from '@studio/hooks';

type _Props = {
  downloadUrl: string;
};

const DownloadLink = ({ downloadUrl }: _Props) => {
  const { onCloseReset } = Draft.ModalContext.useModal();
  const { commonT, prefixNS } = usePrefix('components:', 'downloadMedia');

  return (
    <>
      <Draft.ModalBody>
        <Stack dataTestId="download-link" gap="l">
          <Text>{prefixNS('downloadLinkCreated')}</Text>
          <Draft.FormField fieldLabel={prefixNS('videoDownloadUrl')}>
            <Inline alignItems="center">
              <Draft.TextInput defaultValue={downloadUrl} id="iframe-embed-code" readOnly />
              <CopyToClipboard name={prefixNS('downloadLink')} text={downloadUrl} />
            </Inline>
          </Draft.FormField>
        </Stack>
      </Draft.ModalBody>
      <Draft.ModalFooter>
        <Draft.Button data-testid="submit-cancel" label={commonT('close')} onClick={onCloseReset} />
      </Draft.ModalFooter>
    </>
  );
};

DownloadLink.displayName = 'DownloadLink';

export default DownloadLink;
