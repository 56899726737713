import { HTTP_METHODS } from '@studio/constants/axios';
import { MEDIA_METADATA_API_V1 } from '@studio/constants/env-variables';
import type { Playlists } from '@studio/types';

export const root = {
  [HTTP_METHODS.GET]: {
    url: `${MEDIA_METADATA_API_V1}/customers/@customerId/playlists/@playlistId/media`,
    response: {} as Playlists.Get.PlaylistDetailed,
    params: {} as { playlistId: string },
    method: HTTP_METHODS.GET,
  },
  [HTTP_METHODS.POST]: {
    url: `${MEDIA_METADATA_API_V1}/customers/@customerId/playlists/@playlistId/media`,
    request: [] as string[],
    params: {} as { playlistId: string },
    method: HTTP_METHODS.POST,
  },
};

// ORIGINAL NOTE: mediaPlaylistId is the unique entry ID for media in a playlist -- as opposed to the mediaId of the video. A
// unique entry ID is used since a video can be added to a playlist more than once.
export const id = {
  [HTTP_METHODS.DELETE]: {
    url: `${MEDIA_METADATA_API_V1}/customers/@customerId/playlists/@playlistId/media/@mediaPlaylistId`,
    params: {} as { mediaPlaylistId: string; playlistId: string },
    method: HTTP_METHODS.DELETE,
  },
};
