import type { AxiosResponse } from 'axios';
import type { AllEffect, ForkEffect } from 'redux-saga/effects';
import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';
import { client } from '@studio/api/api-client/client';
import { MEDIA_METADATA_API_V1 } from '@studio/constants/env-variables';
import { SAVED_VIDEO_STATE } from '@studio/constants/library';
import { LINKS } from '@studio/constants/shared';
import { parseHeaderLink } from '@studio/helpers';
import type { Media } from '@studio/types';
import type { ApiClientConfig } from '@studio/types/axios';
import { selectCustomerId, selectAccessToken } from '../authentication';
import { fetchLibraryItemsAsync, fetchLibraryProcessingItemsAsync, fetchMoreLibraryItemsAsync } from './actions';
import { selectListInfo } from './selectors';
import type { SavedVideoListInfo } from './types';
import { ActionTypes } from './types';

const PAGE_SIZE = 50;

const filterVideos = (videos: Media.Get.LibraryItem[]): Media.Get.LibraryItem[] => {
  // videos in some states should be hidden from users
  return videos.filter((video) => video.state !== SAVED_VIDEO_STATE.DELETE_PENDING);
};

function* handleFetch(action: ReturnType<typeof fetchLibraryItemsAsync.request>) {
  try {
    const customerId: string = yield select(selectCustomerId);
    const url = `${MEDIA_METADATA_API_V1}/customers/${customerId}/media?size=${PAGE_SIZE}&sort=${action.payload?.sort}`;
    const token: string = yield select(selectAccessToken);
    const config: ApiClientConfig = { token, returnResponse: true };
    const response: AxiosResponse = yield call(client, url, config);
    const nextUrl = parseHeaderLink(response.headers.link);
    const filteredVideos = filterVideos(response.data);

    yield put(
      fetchLibraryItemsAsync.success({
        videos: filteredVideos,
        nextUrl: nextUrl[LINKS.NEXT],
      })
    );
  } catch (error) {
    if (error instanceof Error) {
      yield put(fetchLibraryItemsAsync.failure(error));
    }
  }
}

function* handleFetchMore() {
  try {
    const listInfo: SavedVideoListInfo = yield select(selectListInfo);

    if (listInfo.nextUrl) {
      const token: string = yield select(selectAccessToken);
      const config: ApiClientConfig = { token, returnResponse: true };
      const response: AxiosResponse = yield call(client, listInfo.nextUrl, config);
      const nextUrl = parseHeaderLink(response.headers.link);
      const filteredVideos = filterVideos(response.data);

      yield put(
        fetchMoreLibraryItemsAsync.success({
          videos: listInfo.videos ? listInfo.videos.concat(filteredVideos) : filteredVideos,
          nextUrl: nextUrl[LINKS.NEXT],
        })
      );
    }
  } catch (error) {
    if (error instanceof Error) {
      yield put(fetchMoreLibraryItemsAsync.failure(error));
    }
  }
}

// fetches updated info only for the library items that are "processing"; payload.items contains the list of mediaId's that need updating
function* handleFetchProcessingOnly(action: ReturnType<typeof fetchLibraryProcessingItemsAsync.request>) {
  const customerId: string = yield select(selectCustomerId);
  const baseUrl = `${MEDIA_METADATA_API_V1}/customers/${customerId}/media`;
  const token: string = yield select(selectAccessToken);
  const config: ApiClientConfig = { token };
  const updatedItems = [];
  try {
    if (action.payload.items) {
      for (const mediaId of action.payload.items) {
        const mediaUrl = `${baseUrl}/${mediaId}`;
        const response: Media.Get.LibraryItem = yield call(client, mediaUrl, config);
        updatedItems.push(response);
      }
    }
    yield put(fetchLibraryProcessingItemsAsync.success(updatedItems));
  } catch (error) {
    if (error instanceof Error) {
      yield put(fetchLibraryProcessingItemsAsync.failure(error));
    }
  }
}

export function* watchFetchRequest<T>(): Generator<ForkEffect<never>, void, T> {
  yield takeLatest(ActionTypes.FETCH_REQUEST, handleFetch);
}

export function* watchFetchMoreRequest<T>(): Generator<ForkEffect<never>, void, T> {
  yield takeLatest(ActionTypes.FETCH_MORE_REQUEST, handleFetchMore);
}

export function* watchFetchRequestProcessingOnly<T>(): Generator<ForkEffect<never>, void, T> {
  yield takeLatest(ActionTypes.FETCH_REQUEST_PROCESSING_ONLY, handleFetchProcessingOnly);
}

export function* librarySaga<T>(): Generator<AllEffect<ForkEffect<void>>, void, T> {
  yield all([fork(watchFetchRequest), fork(watchFetchMoreRequest), fork(watchFetchRequestProcessingOnly)]);
}
