import { produce } from 'immer';
import type { ActionType } from 'typesafe-actions';
import { createReducer } from 'typesafe-actions';
import { fetch, set } from './actions';
import type { DestinationGroupsState } from './types';

type _Action = ActionType<typeof fetch | typeof set>;

export const initialState: DestinationGroupsState = {
  isFetching: false,
  items: [],
  timestamp: 0,
  error: null,
  filter: '',
};

const reducer = createReducer<DestinationGroupsState, _Action>(initialState)
  .handleAction(
    fetch.destinationGroup.success,
    produce((draft: DestinationGroupsState, action: ActionType<typeof fetch.destinationGroup.success>) => {
      const updatedItems = [...draft.items];
      let iteration = 0;
      let updated = false;

      while (!updated) {
        if (updatedItems[iteration].id === action.payload.destinationId) {
          updatedItems[iteration] = action.payload.response;
          updated = true;
          break;
        }

        iteration++;
      }

      draft.items = updatedItems;
    })
  )
  .handleAction(
    fetch.destinationGroup.failure,
    produce((draft: DestinationGroupsState, action: ActionType<typeof fetch.destinationGroup.failure>) => {
      draft.isFetching = false;
      draft.error = action.payload;
    })
  )
  .handleAction(
    fetch.destinationGroups.request,
    produce((draft: DestinationGroupsState) => {
      draft.isFetching = true;
      draft.error = null;
    })
  )
  .handleAction(
    fetch.destinationGroups.success,
    produce((draft: DestinationGroupsState, action: ActionType<typeof fetch.destinationGroups.success>) => {
      draft.isFetching = false;
      draft.items = action.payload;
    })
  )
  .handleAction(
    fetch.destinationGroups.failure,
    produce((draft: DestinationGroupsState, action: ActionType<typeof fetch.destinationGroups.failure>) => {
      draft.isFetching = false;
      draft.error = action.payload;
    })
  )
  .handleAction(
    fetch.destinationGroups.cancel,
    produce((draft: DestinationGroupsState) => {
      draft.isFetching = false;
    })
  )
  .handleAction(
    set.timestamp,
    produce((draft: DestinationGroupsState, action: ActionType<typeof set.timestamp>) => {
      draft.timestamp = action.payload;
    })
  )
  .handleAction(
    set.filter,
    produce((draft: DestinationGroupsState, action: ActionType<typeof set.filter>) => {
      draft.filter = action.payload;
    })
  );

export default reducer;
