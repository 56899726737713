import type { Venues } from '@studio/types';

export enum ActionTypes {
  FETCH_REQUEST = '@@venues/FETCH_REQUEST',
  FETCH_REQUEST_CANCEL = '@@venues/FETCH_REQUEST_CANCEL',
  FETCH_REQUEST_ERROR = '@@venues/FETCH_REQUEST_ERROR',
  FETCH_REQUEST_SUCCESS = '@@venues/FETCH_REQUEST_SUCCESS',
  FETCH_REQUEST_TIMESTAMP = '@@venues/FETCH_REQUEST_TIMESTAMP',
  FETCH_VENUE_LICENSES = '@@venues/FETCH_VENUE_LICENSES',
  FETCH_VENUE_LICENSES_CANCEL = '@@venues/FETCH_VENUE_LICENSES_CANCEL',
  FETCH_VENUE_LICENSES_ERROR = '@@venues/FETCH_VENUE_LICENSES_ERROR',
  FETCH_VENUE_LICENSES_SUCCESS = '@@venues/FETCH_VENUE_LICENSES_SUCCESS',
  FILTER_RESULTS = '@@venues/FILTER_RESULTS',
}

export type VenueState = {
  error: Error | null;
  filter: string;
  isFetching: boolean;
  items: Venues.Derived.Response[];
  timestamp: number;
  venueLicenses: number;
};
