import * as React from 'react';
import { produce } from 'immer';
import { createContainer } from 'react-tracked';
import { match } from 'ts-pattern';
import { zuoraCcPageId } from '@studio/constants/env-variables';
import { TRANSLATION_IDS } from '@studio/constants/onboarding';
import type { Onboarding } from '@studio/types';

type _OnboardingContextState = {
  acceptMsaTerms: boolean;
  billingAddress: Onboarding.Post.CustomerBillingAddress;
  customerInfo: Onboarding.Post.Customer;
  isMonthlyBilling: boolean;
  isNonProfit: boolean;
  paymentRefId: string;
  rsaSignature: Onboarding.Get.Signature | null;
  selectedPlan?: Onboarding.Components.WebPlan;
  webPlans: Onboarding.Components.WebPlan[];
  zuoraPageId: string;
};

export const initialOnboardingState: _OnboardingContextState = {
  acceptMsaTerms: false,
  customerInfo: {
    firstName: '',
    lastName: '',
    email: '',
    phone: '+1',
    companyName: '',
  },
  billingAddress: {
    line1: '',
    line2: '',
    city: '',
    state: '',
    postalCode: '',
    countryCode: null,
  },
  isMonthlyBilling: false,
  isNonProfit: true,
  paymentRefId: '',
  rsaSignature: null,
  zuoraPageId: zuoraCcPageId,
  selectedPlan: undefined,
  webPlans: [],
};

type OnboardingContextHook = _OnboardingContextState & {
  getWebPlans: () => Onboarding.Components.WebPlan[];
  setAcceptMsaTerms: (key: boolean) => void;
  setBillingAddress: (key: Onboarding.Post.CustomerBillingAddress) => void;
  setCustomerInfo: (key: Onboarding.Post.Customer) => void;
  setIsMonthlyBilling: (key: boolean) => void;
  setIsNonProfit: (key: boolean) => void;
  setPaymentRefId: (key: string) => void;
  setRsaSignature: (key: Onboarding.Get.Signature | null) => void;
  setSelectedPlan: (key: Onboarding.Components.WebPlan) => void;
  setWebPlans: (key: Onboarding.Components.WebPlan[]) => void;
};

const ONBOARDING_ACTION = {
  ACCEPT_MSA_TERMS: 'ACCEPT_MSA_TERMS',
  BILLING_ADDRESS: 'BILLING_ADDRESS',
  CUSTOMER_INFO: 'CUSTOMER_INFO',
  IS_MONTHLY_BILLING: 'IS_MONTHLY_BILLING',
  IS_NON_PROFIT: 'IS_NON_PROFIT',
  PAYMENT_REF_ID: 'PAYMENT_REF_ID',
  RSA_SIGNATURE: 'RSA_SIGNATURE',
  SELECTED_PLAN: 'SELECTED_PLAN',
  WEB_PLANS: 'WEB_PLANS',
} as const;

type OnboardingAction =
  | { payload: boolean; type: typeof ONBOARDING_ACTION.ACCEPT_MSA_TERMS }
  | { payload: boolean; type: typeof ONBOARDING_ACTION.IS_MONTHLY_BILLING }
  | { payload: boolean; type: typeof ONBOARDING_ACTION.IS_NON_PROFIT }
  | { payload: Onboarding.Components.WebPlan; type: typeof ONBOARDING_ACTION.SELECTED_PLAN }
  | { payload: Onboarding.Components.WebPlan[]; type: typeof ONBOARDING_ACTION.WEB_PLANS }
  | { payload: Onboarding.Get.Signature | null; type: typeof ONBOARDING_ACTION.RSA_SIGNATURE }
  | { payload: Onboarding.Post.Customer; type: typeof ONBOARDING_ACTION.CUSTOMER_INFO }
  | { payload: Onboarding.Post.CustomerBillingAddress; type: typeof ONBOARDING_ACTION.BILLING_ADDRESS }
  | { payload: string; type: typeof ONBOARDING_ACTION.PAYMENT_REF_ID };

const onboardingReducer = produce(
  (draft: _OnboardingContextState, action: OnboardingAction): _OnboardingContextState =>
    match<OnboardingAction, _OnboardingContextState>(action)
      .with({ type: ONBOARDING_ACTION.ACCEPT_MSA_TERMS }, ({ payload }) => {
        draft.acceptMsaTerms = payload;
        return draft;
      })
      .with({ type: ONBOARDING_ACTION.BILLING_ADDRESS }, ({ payload }) => {
        draft.billingAddress = payload;
        return draft;
      })
      .with({ type: ONBOARDING_ACTION.CUSTOMER_INFO }, ({ payload }) => {
        draft.customerInfo = payload;
        return draft;
      })
      .with({ type: ONBOARDING_ACTION.IS_MONTHLY_BILLING }, ({ payload }) => {
        draft.isMonthlyBilling = payload;
        return draft;
      })
      .with({ type: ONBOARDING_ACTION.IS_NON_PROFIT }, ({ payload }) => {
        draft.isNonProfit = payload;
        return draft;
      })
      .with({ type: ONBOARDING_ACTION.PAYMENT_REF_ID }, ({ payload }) => {
        draft.paymentRefId = payload;
        return draft;
      })
      .with({ type: ONBOARDING_ACTION.RSA_SIGNATURE }, ({ payload }) => {
        draft.rsaSignature = payload;
        return draft;
      })
      .with({ type: ONBOARDING_ACTION.SELECTED_PLAN }, ({ payload }) => {
        draft.selectedPlan = payload;
        return draft;
      })
      .with({ type: ONBOARDING_ACTION.WEB_PLANS }, ({ payload }) => {
        draft.webPlans = payload;
        return draft;
      })
      .exhaustive()
);

const useValues = (): readonly [OnboardingContextHook, () => void] => {
  const [state, dispatch] = React.useReducer(onboardingReducer, { ...initialOnboardingState });

  const getWebPlans = React.useCallback(() => {
    if (!state.isNonProfit) {
      return state.webPlans.filter((plan) => plan.monthlyId !== TRANSLATION_IDS.BASIC_EMBED_MONTHLY);
    }
    const webPlansClone = [...state.webPlans];
    return webPlansClone.sort((a, b) => a.sort - b.sort);
  }, [state.isNonProfit, state.webPlans]);

  const setAcceptMsaTerms = React.useCallback(
    (acceptMsaTerms: boolean) =>
      dispatch({
        type: ONBOARDING_ACTION.ACCEPT_MSA_TERMS,
        payload: acceptMsaTerms,
      }),
    []
  );

  const setBillingAddress = React.useCallback(
    (billingAddress: Onboarding.Post.CustomerBillingAddress) =>
      dispatch({
        type: ONBOARDING_ACTION.BILLING_ADDRESS,
        payload: billingAddress,
      }),
    []
  );

  const setCustomerInfo = React.useCallback(
    (customerInfo: Onboarding.Post.Customer) =>
      dispatch({
        type: ONBOARDING_ACTION.CUSTOMER_INFO,
        payload: customerInfo,
      }),
    []
  );

  const setRsaSignature = React.useCallback(
    (rsaSignature: Onboarding.Get.Signature | null) =>
      dispatch({
        type: ONBOARDING_ACTION.RSA_SIGNATURE,
        payload: rsaSignature,
      }),
    []
  );

  const setIsMonthlyBilling = React.useCallback(
    (isMonthlyBilling: boolean) =>
      dispatch({
        type: ONBOARDING_ACTION.IS_MONTHLY_BILLING,
        payload: isMonthlyBilling,
      }),
    []
  );

  const setIsNonProfit = React.useCallback(
    (isNonProfit: boolean) =>
      dispatch({
        type: ONBOARDING_ACTION.IS_NON_PROFIT,
        payload: isNonProfit,
      }),
    []
  );

  const setPaymentRefId = React.useCallback(
    (paymentRefId: string) =>
      dispatch({
        type: ONBOARDING_ACTION.PAYMENT_REF_ID,
        payload: paymentRefId,
      }),
    []
  );

  const setSelectedPlan = React.useCallback((webPlan: Onboarding.Components.WebPlan) => {
    dispatch({ type: ONBOARDING_ACTION.SELECTED_PLAN, payload: webPlan });
  }, []);

  const setWebPlans = React.useCallback((webPlans: Onboarding.Components.WebPlan[]) => {
    dispatch({ type: ONBOARDING_ACTION.WEB_PLANS, payload: webPlans });
  }, []);

  const stateToPass = React.useMemo(
    () => ({
      ...state,
      getWebPlans,
      setAcceptMsaTerms,
      setBillingAddress,
      setCustomerInfo,
      setIsMonthlyBilling,
      setIsNonProfit,
      setPaymentRefId,
      setSelectedPlan,
      setRsaSignature,
      setWebPlans,
    }),
    [
      getWebPlans,
      setAcceptMsaTerms,
      setBillingAddress,
      setCustomerInfo,
      setIsMonthlyBilling,
      setIsNonProfit,
      setPaymentRefId,
      setRsaSignature,
      setSelectedPlan,
      setWebPlans,
      state,
    ]
  );

  return [
    stateToPass,
    () => {
      throw new Error('use functions in the state');
    },
  ];
};

const {
  Provider: OnboardingContextProvider,
  useTrackedState: useOnboardingContext,
  useUpdate: useOnboardingContextUpdate,
} = createContainer<OnboardingContextHook, () => void, { children: React.ReactNode }>(useValues);

export { OnboardingContextProvider, useOnboardingContext, useOnboardingContextUpdate };
export type { OnboardingContextHook };
