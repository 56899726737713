import type { CombinedState } from 'redux';
import { createSelector } from 'reselect';
import type { RootState } from '../../root-reducer';
import type { Organization, State, SearchBy, Contact } from './types';

export const selectAdminSearchState = (state: RootState): CombinedState<State> => state.admin.search;

export const selectOrgs = createSelector(selectAdminSearchState, (state): Organization[] => state.orgs);

export const selectContacts = createSelector(selectAdminSearchState, (state): Contact[] => state.contacts);

export const selectFilter = createSelector(selectAdminSearchState, (state): string => state.filter);

export const selectSearchBy = createSelector(selectAdminSearchState, (state): SearchBy => state.searchBy);
