import * as React from 'react';
import { Draft } from '@resi-media/resi-ui';
import { usePrefix } from '@studio/hooks';
import { VerifyEmailModal } from '../VerifyEmailModal';

const VerifyEmailBanner = () => {
  const { commonT, prefixNS } = usePrefix('components:', 'verifyEmailBanner');
  const { onClose, setModal } = Draft.ModalContext.useModal();

  return (
    <Draft.AlertBanner
      buttonLabel={commonT('verifyEmail')}
      buttonProps={{
        onClick: () =>
          setModal(() => <VerifyEmailModal />, {
            minHVariant: 's',
            onClose,
          }),
      }}
      dataTestId="verify-email-banner"
      marginBottom="m"
      variant="warning"
    >
      {prefixNS('description')}
    </Draft.AlertBanner>
  );
};

VerifyEmailBanner.displayName = 'VerifyEmailBanner';

export default VerifyEmailBanner;
