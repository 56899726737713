import { produce } from 'immer';
import md5 from 'md5';
import type { ActionType, PayloadAction } from 'typesafe-actions';
import { createReducer } from 'typesafe-actions';
import { STUDIO_URL } from '@studio/constants/env-variables';
import { WrongUserTypeError } from '@studio/types';
import {
  fetchMeAsync,
  fetchTokenAsync,
  loginAsync,
  logoutAsync,
  setAccessTokenTimestamp,
  setAuthToken,
  setTokenExpired,
  setIsRefreshing,
  setIsLoggingIn,
} from './actions';
import type { AuthenticationState, AuthenticationActionTypes, TokenResponse, AuthenticatedUser } from './types';

type AuthenticationAction = ActionType<
  | typeof fetchMeAsync
  | typeof fetchTokenAsync
  | typeof loginAsync
  | typeof logoutAsync
  | typeof setAccessTokenTimestamp
  | typeof setAuthToken
  | typeof setIsLoggingIn
  | typeof setIsRefreshing
  | typeof setTokenExpired
>;

export const initialState: AuthenticationState = {
  isLoggingIn: false,
  isLoggingOut: false,
  isRefreshing: false,
  isVenue: false,
  accessToken: undefined,
  expired: false,
  expiresIn: 0,
  user: null,
  error: null,
  refreshTokenError: null,
};

const gravatarUrl = 'https://www.gravatar.com/avatar';
const encodedDefaultAvatarUrl = encodeURIComponent(`${STUDIO_URL}/profile-avatar.jpg`);

const reducer = createReducer<AuthenticationState, AuthenticationAction>(initialState)
  .handleAction(
    loginAsync.request,
    produce((draft: AuthenticationState) => {
      draft.error = null;
      draft.expired = false;
    })
  )
  .handleAction(
    loginAsync.success,
    produce((draft: AuthenticationState, action: PayloadAction<AuthenticationActionTypes, TokenResponse>) => {
      draft.accessToken = action.payload.access_token;
    })
  )
  .handleAction(
    loginAsync.failure,
    produce((draft: AuthenticationState, action: PayloadAction<AuthenticationActionTypes, Error>) => {
      draft.error = action.payload;
    })
  )
  .handleAction(
    loginAsync.cancel,
    produce((draft: AuthenticationState) => {
      draft.error = null;
    })
  )
  .handleAction(
    fetchTokenAsync.request,
    produce((draft: AuthenticationState) => {
      draft.refreshTokenError = null;
    })
  )
  .handleAction(
    fetchTokenAsync.success,
    produce((draft: AuthenticationState, action: PayloadAction<AuthenticationActionTypes, TokenResponse>) => {
      draft.accessToken = action.payload.access_token;
    })
  )
  .handleAction(
    fetchTokenAsync.failure,
    produce((draft: AuthenticationState, action: PayloadAction<AuthenticationActionTypes, Error>) => {
      draft.refreshTokenError = action.payload;
    })
  )
  .handleAction(
    setAccessTokenTimestamp,
    produce((draft: AuthenticationState, action: PayloadAction<AuthenticationActionTypes, number>) => {
      draft.expiresIn = action.payload;
    })
  )
  .handleAction(
    setAuthToken,
    produce((draft: AuthenticationState, action: PayloadAction<AuthenticationActionTypes, string>) => {
      draft.accessToken = action.payload;
    })
  )
  .handleAction(
    setTokenExpired,
    produce((draft: AuthenticationState) => {
      draft.expired = true;
    })
  )
  .handleAction(
    fetchMeAsync.request,
    produce((draft: AuthenticationState) => {
      draft.error = null;
      draft.isVenue = false;
    })
  )
  .handleAction(
    fetchMeAsync.success,
    produce((draft: AuthenticationState, action: PayloadAction<AuthenticationActionTypes, AuthenticatedUser>) => {
      draft.user = {
        ...action.payload,
        userImage: `${gravatarUrl}/${md5(action.payload.userName.trim().toLowerCase())}?d=${encodedDefaultAvatarUrl}`,
      };
    })
  )
  .handleAction(
    fetchMeAsync.failure,
    produce((draft: AuthenticationState, action: PayloadAction<AuthenticationActionTypes, Error>) => {
      if (action.payload instanceof WrongUserTypeError) {
        draft.isVenue = true;
      }
      draft.error = action.payload;
      draft.isLoggingIn = false;
      draft.isRefreshing = false;
    })
  )
  .handleAction(
    setIsLoggingIn,
    produce((draft: AuthenticationState, action: PayloadAction<AuthenticationActionTypes, boolean>) => {
      draft.isLoggingIn = action.payload;
    })
  )
  .handleAction(
    setIsRefreshing,
    produce((draft: AuthenticationState, action: PayloadAction<AuthenticationActionTypes, boolean>) => {
      draft.isRefreshing = action.payload;
    })
  )
  .handleAction(
    logoutAsync.request,
    produce((draft: AuthenticationState) => {
      draft.isLoggingOut = true;
      draft.error = null;
    })
  )
  .handleAction(
    logoutAsync.success,
    produce((draft: AuthenticationState) => {
      draft.isLoggingOut = false;
      draft.user = null;
    })
  )
  .handleAction(
    logoutAsync.cancel,
    produce((draft: AuthenticationState) => {
      draft.isLoggingOut = false;
    })
  )
  .handleAction(
    logoutAsync.failure,
    produce((draft: AuthenticationState, action: PayloadAction<AuthenticationActionTypes, Error>) => {
      draft.isLoggingOut = false;
      draft.error = action.payload;
    })
  );

export default reducer;
