import type { EncoderChannels } from '@studio/types';

export enum ActionTypes {
  FETCH_REQUEST = '@@encoderchannels/FETCH_REQUEST',
  FETCH_REQUEST_CANCEL = '@@encoderchannels/FETCH_REQUEST_CANCEL',
  FETCH_REQUEST_ERROR = '@@encoderchannels/FETCH_REQUEST_ERROR',
  FETCH_REQUEST_SUCCESS = '@@encoderchannels/FETCH_REQUEST_SUCCESS',
  FETCH_REQUEST_TIMESTAMP = '@@encoderchannels/FETCH_REQUEST_TIMESTAMP',
  FETCH_USERS_ERROR = '@@encoderchannels/FETCH_USERS_ERROR',
  FETCH_USERS_REQUEST = '@@encoderchannels/FETCH_USERS_REQUEST',
  FETCH_USERS_SUCCESS = '@@encoderchannels/FETCH_USERS_SUCCESS',
  FILTER_RESULTS = '@@encoderchannels/FILTER_RESULTS',
  FORCE_FETCH_REQUEST = '@@encoderchannels/FORCE_FETCH_REQUEST',
  FORCE_FETCH_REQUEST_ERROR = '@@encoderchannels/FORCE_FETCH_REQUEST_ERROR',
  FORCE_FETCH_REQUEST_SUCCESS = '@@encoderchannels/FORCE_FETCH_REQUEST_SUCCESS',
}

export interface EncoderChannelsState {
  error: Error | null;
  filter: string;
  isFetching: boolean;
  items: EncoderChannels.Derived.EncoderChannel[];
  timestamp: number;
}
