import * as idEndpoints from './id';
import { root, id } from './stream-profiles';

export const streamProfiles = {
  ...root,
  id: {
    ...id,
    ...idEndpoints,
  },
};
