import { produce } from 'immer';
import type { ActionType, PayloadAction } from 'typesafe-actions';
import { createReducer } from 'typesafe-actions';
import { fetchRtmpDestinations, setFilter } from './actions';
import type { ActionTypes, RtmpDestination, RtmpDestinationsState } from './types';

export const initialState: RtmpDestinationsState = {
  filter: '',
  rtmpDestinations: [],
  isFetchingDestinations: false,
  fetchDestinationsError: null,
  timestamp: 0,
};

type Action = ActionType<typeof fetchRtmpDestinations | typeof setFilter>;

const reducer = createReducer<RtmpDestinationsState, Action>(initialState)
  .handleAction(
    fetchRtmpDestinations.request,
    produce((draft: RtmpDestinationsState) => {
      draft.isFetchingDestinations = true;
      draft.fetchDestinationsError = null;
    })
  )
  .handleAction(
    fetchRtmpDestinations.success,
    produce(
      (
        draft: RtmpDestinationsState,
        action: PayloadAction<ActionTypes.FETCH_RTMP_DESTINATIONS_SUCCESS, RtmpDestination[]>
      ) => {
        draft.rtmpDestinations = action.payload;
        draft.isFetchingDestinations = false;
        draft.timestamp = Date.now();
      }
    )
  )
  .handleAction(
    fetchRtmpDestinations.failure,
    produce((draft: RtmpDestinationsState, action: PayloadAction<ActionTypes.FETCH_RTMP_DESTINATIONS_ERROR, Error>) => {
      draft.fetchDestinationsError = action.payload;
      draft.isFetchingDestinations = false;
    })
  )
  .handleAction(
    setFilter,
    produce((draft: RtmpDestinationsState, action: PayloadAction<ActionTypes.FILTER_ITEMS, string>) => {
      draft.filter = action.payload;
    })
  );

export default reducer;
