import { HTTP_METHODS } from '@studio/constants/axios';
import { SEARCH_API_V1 } from '@studio/constants/env-variables';

export const root = {
  [HTTP_METHODS.GET]: {
    url: `${SEARCH_API_V1}/vod/@customerId/tags`,
    response: [] as string[],
    method: HTTP_METHODS.GET,
    query: {} as {
      query?: string;
    },
  },
};

export const id = {};
