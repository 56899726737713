/* eslint-disable @typescript-eslint/ban-ts-comment */
import * as React from 'react';
import { produce } from 'immer';

/**
 * A helper to create a Context and Provider with no upfront default value, and
 * without having to check for undefined all the time.
 */
const createCtx = <A extends Record<string, unknown> | null>() => {
  const ctx = React.createContext<A | undefined>(undefined);

  const useCtx = () => {
    const c = React.useContext(ctx);

    if (c === undefined) throw new Error('usePageMetaGlobal must be used within PageMetaProvider');

    return c;
  };

  return [useCtx, ctx] as const; // 'as const' makes TypeScript infer a tuple
};

const [usePageMetaGlobal, PageMetaContext] = createCtx<_HookReturnValues>();

enum _ACTION_TYPES {
  SET_GLOBAL_VIEWPORT = 'SET_GLOBAL_VIEWPORT',
}

type _Action = {
  payload: boolean;
  type: _ACTION_TYPES.SET_GLOBAL_VIEWPORT;
};

type _State = {
  forceMobile: boolean;
};

const initialState: _State = {
  forceMobile: false,
};

type _HookReturnValues = _State & {
  setDesktopViewport: (bool: boolean) => void;
};

const reducerFunction = (draft: _State, action: _Action) => {
  switch (action.type) {
    case _ACTION_TYPES.SET_GLOBAL_VIEWPORT:
      draft.forceMobile = action.payload;

      break;
  }
};

const curriedReducerFunction = produce(reducerFunction);

type _Props = {
  children: React.ReactNode;
  forceMobile?: boolean;
};

const PageMetaProvider = ({ children, forceMobile }: _Props) => {
  const [state, dispatch] = React.useReducer(curriedReducerFunction, {
    forceMobile: forceMobile ?? initialState.forceMobile,
  });

  const setDesktopViewport = React.useCallback((forceMobileBool: boolean) => {
    if (forceMobileBool) {
      document.querySelector("meta[name='viewport']")?.setAttribute('content', '');
    } else {
      document.querySelector("meta[name='viewport']")?.setAttribute('content', 'width=device-width, initial-scale=1.0');
    }

    dispatch({
      type: _ACTION_TYPES.SET_GLOBAL_VIEWPORT,
      payload: forceMobileBool,
    });
  }, []);

  return (
    <PageMetaContext.Provider
      value={{
        setDesktopViewport,
        ...state,
      }}
    >
      {children}
    </PageMetaContext.Provider>
  );
};

export default PageMetaContext;

export { PageMetaProvider, usePageMetaGlobal };
