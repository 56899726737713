import * as React from 'react';
import { WarningOutlined } from '@ant-design/icons';
import { css, useTheme } from '@emotion/react';
import type { MergeElementProps } from '@resi-media/resi-ui';
import { Draft, Inline, Link, Stack, Text, FieldLabel, mergeDefaultProps, Box } from '@resi-media/resi-ui';
import { useSelector } from 'react-redux';
import { TIME_WINDOW_TYPES } from '@studio/constants/analytics';
import { EMAIL_SUCCESS } from '@studio/constants/external-links-constants';
import UrlPaths from '@studio/constants/url-paths';
import { dateMinusTimezoneToIso, formatFloat } from '@studio/helpers';
import { useClient, usePrefix } from '@studio/hooks';
import { WidgetHeader } from '@studio/pages/Dashboard/components/WidgetHeader';
import { bytesToTerabytes } from '@studio/pages/OrganizationDetails/helpers/bytes-to-terabytes';
import { selectCustomerData } from '@studio/store';
import { CardBody } from '../CardBody';
import { LoadingUsageCard } from '../LoadingUsageCard';
import type { CustomCss as _CustomCss } from './styles';
import { S } from './styles';

type _Props = MergeElementProps<
  'div',
  {
    /**
     * Specific children type to allow
     */
    children?: React.ReactNode;
    /**
     * Style Override
     */
    customCss?: _CustomCss;
    /**
     * Flag for error state
     */
    hasError?: boolean;
    /**
     * Flag for rendering link to Org Details page
     */
    hasRoute?: boolean;
    /**
     * callback that will be called when user clicks "Retry" on error state
     */
    onRetry?: () => void;
    /**
     * Flag for preventing api call on mount (allows us to prevent duplicate calls on Org Details page)
     */
    preventFetch?: boolean;
    /**
     * Controlled value for bytes
     */
    totalDataUsageBytes?: number;
  }
>;

const defaultProps = {};

const BandwidthWidgetInternal = (props: _Props, ref: React.ForwardedRef<HTMLDivElement>) => {
  const propsInternal: _Props = mergeDefaultProps(props, defaultProps);
  const {
    children,
    customCss,
    hasError: isError,
    hasRoute,
    onRetry,
    preventFetch,
    totalDataUsageBytes,
  } = propsInternal;
  const { commonT, prefixNS, PrefixTrans } = usePrefix('pages:', 'bandwidthWidget');
  const customer = useSelector(selectCustomerData);
  const theme = useTheme();
  const utils = Draft.useUtils<Date>();
  const mounted = React.useRef(false);
  const today = React.useMemo(() => new Date(), []);
  const daysRemaining = utils.getDaysInMonth(today) - Number(utils.format(today, 'dayOfMonth'));
  const [totalDataUsed, setTotalDataUsed] = React.useState('0');
  const totalDataAllowed = formatFloat(customer?.webBandwidth);
  const dataUsedPercentage = Math.floor((Number(totalDataUsed) / Number(totalDataAllowed)) * 100);
  const isNearMax = dataUsedPercentage >= 90 && dataUsedPercentage <= 99;
  const isMax = dataUsedPercentage >= 100;
  const getBandwidthUsage = useClient({
    config: useClient.dataUsage.v1.statistics.GET,
    params: {
      timeWindow: TIME_WINDOW_TYPES.MONTH,
    },
    query: { startDate: dateMinusTimezoneToIso(utils.startOfMonth(today)) },
  });
  const hasError = React.useMemo(() => isError || getBandwidthUsage.error, [isError, getBandwidthUsage.error]);

  // Needs to remain in separate useEffect to work properly
  React.useEffect(() => {
    return () => {
      mounted.current = false;
    };
  }, []);

  React.useEffect(() => {
    if (totalDataUsageBytes) {
      setTotalDataUsed(formatFloat(totalDataUsageBytes));
    }
  }, [totalDataUsageBytes]);

  const fetchBandwidthUsage = React.useCallback(async () => {
    try {
      const response = await getBandwidthUsage.callApi();

      setTotalDataUsed(formatFloat(bytesToTerabytes(response.totalDataUsageBytes)));
    } catch (e) {
      console.error(e);
    }
  }, [getBandwidthUsage]);

  React.useEffect(() => {
    if (!mounted.current && !totalDataUsageBytes && !preventFetch) {
      fetchBandwidthUsage();
      mounted.current = true;
    }
  }, [fetchBandwidthUsage, preventFetch, totalDataUsageBytes]);

  const getProgressColorVariant = React.useCallback(() => {
    if (isNearMax) {
      return 'warning';
    } else if (isMax) {
      return 'negative';
    }
    return 'navy';
  }, [isNearMax, isMax]);

  const renderCardBody = React.useCallback(
    () => (
      <CardBody
        data={[{ totalDataUsageBytes }]}
        hasError={hasError}
        onRetry={() => (onRetry ? onRetry() : fetchBandwidthUsage())}
        stackProps={{
          ...(hasError && {
            bgColor: theme.palette.background.table,
          }),
          style: {
            minHeight: '100%',
          },
        }}
      >
        <Stack gap="l" w="scale">
          <Stack>
            <FieldLabel fieldLabel={commonT('bandwidthUsed')} hint={prefixNS('bandwidthUsedHint')} />
            <Inline justifyContent="space-between">
              <Inline alignItems="center">
                <Text variant="body1">
                  {prefixNS('bandwidthUsage', {
                    dataUsed: totalDataUsed,
                    dataAllowed: totalDataAllowed,
                  })}
                </Text>
                {(isNearMax || isMax) && (
                  <Draft.Tooltip
                    content={
                      <div>
                        <PrefixTrans i18nKey={isNearMax ? 'nearingBandwidthLimit' : 'exceededBandwidthLimit'}>
                          fill
                          <Link
                            colorVariant="inherit"
                            css={css`
                              text-decoration: underline;
                            `}
                            dataTestId="email-success-link"
                            href={EMAIL_SUCCESS}
                            variant="body4"
                          >
                            fill
                          </Link>
                        </PrefixTrans>
                      </div>
                    }
                    dataTestId="email-success-tooltip"
                    style={{ width: '200px' }}
                  >
                    <WarningOutlined
                      data-testid="warning-icon"
                      style={{ color: isNearMax ? theme.palette.warning.main : theme.palette.negative.main }}
                    />
                  </Draft.Tooltip>
                )}
              </Inline>
              <Text variant="body1">{dataUsedPercentage}%</Text>
            </Inline>
          </Stack>
          <Draft.ProgressBar
            colorVariant={getProgressColorVariant()}
            customCss={{
              progress: css`
                width: inherit;
              `,
            }}
            dataTestId="bandwidth-widget-progressbar"
            progressTestId={`bandwidth-widget-progressbar__${getProgressColorVariant()}`}
            value={dataUsedPercentage}
          />
          {children ?? <Text>{commonT('daysRemaining', { count: daysRemaining })}</Text>}
        </Stack>
      </CardBody>
    ),
    [
      children,
      commonT,
      dataUsedPercentage,
      daysRemaining,
      fetchBandwidthUsage,
      getProgressColorVariant,
      hasError,
      isMax,
      isNearMax,
      onRetry,
      prefixNS,
      PrefixTrans,
      totalDataAllowed,
      totalDataUsed,
      totalDataUsageBytes,
      theme,
    ]
  );

  return (
    <Draft.Card
      ref={ref}
      customCss={{
        container: customCss?.container ?? S.container(theme, propsInternal),
      }}
      dataTestId="bandwidth-widget"
    >
      <Draft.CardSection display="flex" p="0">
        <Stack gap="0" w="scale">
          <WidgetHeader
            header={prefixNS('title')}
            px={{ xs: 'm', lg: 'l', xxl: 'xl' }}
            showHr
            {...(hasRoute && {
              linkText: commonT('seeMore'),
              to: UrlPaths.SETTINGS.ORGANIZATION_DETAILS_BANDWIDTH_USAGE,
            })}
          />
          {getBandwidthUsage.isFetching && (
            <Box px={{ xs: 'm', lg: 'l', xxl: 'xl' }}>
              <LoadingUsageCard />
            </Box>
          )}
          {!getBandwidthUsage.isFetching && renderCardBody()}
        </Stack>
      </Draft.CardSection>
    </Draft.Card>
  );
};

BandwidthWidgetInternal.displayName = 'BandwidthWidgetInternal';

/* eslint-disable import/export */
export const BandwidthWidget = Object.assign(React.forwardRef(BandwidthWidgetInternal), {
  displayName: 'BandwidthWidget',
});

// eslint-disable-next-line no-redeclare
export namespace BandwidthWidget {
  export type Props = _Props;
  export type CustomCss = _CustomCss;
}
