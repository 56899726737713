import * as React from 'react';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { isFunction, isNumber } from '@resi-media/resi-ui';
import createResizeCallback from './create-resize-callback';

type _Props = {
  children: React.ReactNode | (({ width }: { width: number }) => React.ReactNode);
  columns?: number;
  spacing?: string;
};

type _StylingProps = {
  columns: number;
  gap: string;
};

const MasonryRoot = styled.div<_StylingProps>`
  display: flex;
  flex-flow: column wrap;
  align-content: flex-start;
  box-sizing: border-box;
  margin: calc(0px - ${(props) => props.gap} / 2);

  & > * {
    box-sizing: border-box;
    margin: calc(${(props) => props.gap} / 2);
    width: calc(${(props) => 100 / props.columns}% - ${(props) => props.gap});
  }
`;

const Masonry = (props: _Props) => {
  const theme = useTheme();
  const container = React.useRef<HTMLDivElement>(null);
  const [height, setHeight] = React.useState<number>();
  const [width, setWidth] = React.useState<number>(0);
  const [breaks, setBreaks] = React.useState<number>(0);
  const { children, columns = 3, spacing = theme.spacing.l } = props;

  const observerRef = React.useRef<ResizeObserver | undefined>(
    typeof ResizeObserver === 'undefined'
      ? undefined
      : new ResizeObserver(
          createResizeCallback(container, ({ breaks: rsBreaks, height: rsHeight, width: rsWidth }) => {
            setHeight(rsHeight);
            setBreaks(rsBreaks);
            setWidth(rsWidth);
          })
        )
  );

  React.useEffect(() => {
    if (!container.current || !observerRef.current) return;
    const observer = observerRef.current;
    const nodes = Array.from(container.current.children);
    nodes.forEach((child) => observer.observe(child));
    return () => observer.disconnect();
  }, [children, columns, spacing]);

  const BreaksMarkup = new Array(breaks).fill('').map((_, index) => (
    <span
      key={index}
      data-masonry-break
      style={{
        flexBasis: '100%',
        width: 0,
        margin: 0,
        padding: 0,
        order: index + 1,
      }}
    />
  ));

  return (
    <MasonryRoot
      ref={container}
      columns={columns}
      gap={spacing}
      style={{ height: height ? `calc(${height}px + ${spacing})` : undefined }}
    >
      {isFunction(children) ? children({ width: isNumber(width) ? width : 0 }) : children}
      {BreaksMarkup}
    </MasonryRoot>
  );
};

export default Masonry;
