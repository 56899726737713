import type { Option } from '@resi-media/resi-ui';
import type { Immutable } from 'immer';
import type { UnionOf } from 'ts-toolbelt/out/Object/UnionOf';
import { OFFLINE_OPTIONS } from '@studio/constants/web-channels';
import type { Get } from './get';

export namespace Components {
  export type Row = Immutable<Get.WebChannel>;

  export type WebChannelsOptions = Option & {
    data: Get.WebChannel;
  };

  export type OfflineOptionTypes = UnionOf<typeof OFFLINE_OPTIONS>;

  export const offlineOptionTypesPredicate = (value: unknown): value is OfflineOptionTypes =>
    Object.values(OFFLINE_OPTIONS).includes(value as OfflineOptionTypes);

  export type FormInitialState = {
    delaySeconds: number;
    deleteAfter: number;
    description: string;
    detectQrCodes: boolean;
    include?: boolean;
    loadBalancer?: string;
    name: string;
    offlineContentSelection?: OfflineOptionTypes;
    offlineImageEnabled?: boolean;
    offlineImageUrl?: string | null;
    offlinePlaylistEnabled: boolean;
    offlinePlaylistId: string | null;
    offlineVideoEnabled: boolean;
    offlineVideoId: string | null;
    persistentUrls?: {
      dashUrlEnabled: boolean;
      hlsUrlEnabled: boolean;
    };
    regionId: string;
    showResiLogo: boolean;
    streamUrls: boolean;
    subtitlesEnabled: boolean;
    type?: string;
  };

  export type RemoveModalState = {
    error: unknown;
    isSubmitting: boolean;
    onSuccess: () => void;
    uuid: string;
  };

  export type OfflineImageUpload = {
    currentDownloadUrl: string | null;
    fileExtension: string;
  };

  export type RemoveActiveContentState = {
    webChannelName?: string;
    webChannelUuid: string;
  };
}
