import type { SerializedStyles, Theme } from '@emotion/react';
import { css } from '@emotion/react';
import type { Draft, StyleOverride } from '@resi-media/resi-ui';
import type { BandwidthWidget } from './bandwidth-widget';

export type CustomCss = {
  container?: StyleOverride<Draft.Card.InternalProps>;
};

export const S = {
  container: (theme: Theme, _props: BandwidthWidget.Props): SerializedStyles => css`
    min-height: 240px;
    ${theme.mq.md} {
      min-height: 272px;
    }
  `,
};
