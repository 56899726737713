import type { AllEffect, ForkEffect } from 'redux-saga/effects';
import { all, fork } from 'redux-saga/effects';
import { adminSaga } from './admin/sagas';
import { analyticsSaga } from './analytics/sagas';
import { authenticationSaga } from './authentication/sagas';
import { contentDestinationSaga } from './content-destinations/sagas';
import { contentScheduleSaga } from './content-schedule/sagas';
import { customerSaga } from './customer/sagas';
import { destinationGroupsSaga } from './destination-groups/sagas';
import { encoderChannelsSaga } from './encoder-channels/sagas';
import { encoderLogsSaga } from './encoder-logs/sagas';
import { encoderPresetsSaga } from './encoder-presets/sagas';
import { encoderVideoSaga } from './encoder-videos/sagas';
import { librarySaga } from './library/sagas';
import { librarySearchSaga } from './library-search/sagas';
import { monitorSaga } from './monitors/sagas';
import { playerProfilesSaga } from './player-profiles';
import { playlistsSaga } from './playlists/sagas';
import { regionsSaga } from './regions/sagas';
import { rtmpDestinationsSaga } from './rtmp-destinations';
import { siteSaga } from './sites/sagas';
import { socialMediaAccountsSaga } from './social-media/sagas';
import { systemEventsSaga } from './system-events/sagas';
import { timezonesSaga } from './timezones/sagas';
import { trackingSaga } from './tracking/sagas';
import { allUsersSaga } from './users/sagas';
import { venueSaga } from './venues/sagas';
import { webChannelsSaga } from './web-channels/sagas';
import { webPresetsSaga } from './web-presets/sagas';

export function* rootSaga<T>(): Generator<AllEffect<ForkEffect<void>>, void, T> {
  yield all([
    fork(adminSaga),
    fork(allUsersSaga),
    fork(analyticsSaga),
    fork(authenticationSaga),
    fork(contentDestinationSaga),
    fork(contentScheduleSaga),
    fork(customerSaga),
    fork(destinationGroupsSaga),
    fork(encoderChannelsSaga),
    fork(encoderLogsSaga),
    fork(encoderPresetsSaga),
    fork(encoderVideoSaga),
    fork(librarySaga),
    fork(librarySearchSaga),
    fork(monitorSaga),
    fork(playerProfilesSaga),
    fork(playlistsSaga),
    fork(regionsSaga),
    fork(rtmpDestinationsSaga),
    fork(siteSaga),
    fork(socialMediaAccountsSaga),
    fork(systemEventsSaga),
    fork(timezonesSaga),
    fork(trackingSaga),
    fork(venueSaga),
    fork(webChannelsSaga),
    fork(webPresetsSaga),
  ]);
}
