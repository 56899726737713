import { createAction, createAsyncAction } from 'typesafe-actions';
import type { PlaylistListInfo, FetchOptions } from './types';
import { ActionTypes } from './types';

export const fetchPlaylistsAsync = createAsyncAction(
  ActionTypes.FETCH_REQUEST,
  ActionTypes.FETCH_REQUEST_SUCCESS,
  ActionTypes.FETCH_REQUEST_ERROR,
  ActionTypes.FETCH_REQUEST_CANCEL
)<FetchOptions | undefined, PlaylistListInfo, Error, undefined>();

export const fetchMorePlaylistsAsync = createAsyncAction(
  ActionTypes.FETCH_MORE_REQUEST,
  ActionTypes.FETCH_MORE_REQUEST_SUCCESS,
  ActionTypes.FETCH_MORE_REQUEST_ERROR
)<FetchOptions | undefined, PlaylistListInfo, Error>();

export const setFetchRequestTimestamp = createAction(ActionTypes.FETCH_REQUEST_TIMESTAMP)<number>();

export const deletePlaylist = createAction(ActionTypes.DELETE_ITEM)<string>();

export const setSortOrder = createAction(ActionTypes.SORT_RESULTS)<string>();
