import type { Regions } from '@studio/types';

export interface LatencyDetails {
  cancelToken?: string;
  duration: number;
  isPassing: boolean;
  region: Regions.Get.Region;
}

export const MAX_ACCEPTABLE_LATENCY = 150;
export const ONBOARDING_SET_DATA_CENTER = 'onboarding - set data center';
export const ONBOARDING_GOTO_CONTROL = 'onboarding - go to Control';
export const REQUEST_CANCELLED = 'request cancelled';
export const REQUIRED_SAMPLES = 7;
