import styled from '@emotion/styled';

export type StyledPanelProps = {
  children?: React.ReactNode;
  maxWidth: string;
};

export const StyledPanel = styled.div<StyledPanelProps>`
  ${({ maxWidth, theme }) => `
  background: ${theme.palette.background.paper};
  border-radius: ${theme.shape.borderRadius.s};
  margin: 0 ${theme.spacing.xl};
  width: 95vw;

  ${theme.mq.md} {
    width: 85vw;
  }

  ${theme.mq.lg} {
    max-width: ${maxWidth};
  }
  `}
`;
