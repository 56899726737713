export namespace Result {
  export const OK = 'ok';
  export const ERR = 'err';

  type OkType<T> = Readonly<{ type: typeof OK; value: T }>;
  type ErrType<T> = Readonly<{ type: typeof ERR; value: T }>;
  export type t<T, U> = ErrType<U> | OkType<T>;

  export function Ok<T>(value: T): OkType<T> {
    return { type: OK, value } as const;
  }
  export function Err<T>(value: T): ErrType<T> {
    return { type: ERR, value } as const;
  }
  export function isErr<T, U>(value: t<T, U>): value is ErrType<U> {
    return value.type === ERR;
  }
  export function isOk<T, U>(value: t<T, U>): value is OkType<T> {
    return value.type === OK;
  }
  export function map<T, U>(result: t<T, U>, predicate: (arg: T) => T): t<T, U> {
    if (isErr(result)) {
      return result;
    }
    return Ok(predicate(result.value));
  }
}
