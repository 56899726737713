import * as React from 'react';
import { ContentContainer, FooterContainer, KeepFooterDown } from '@studio/styles/global';
import { Footer } from '../Footer';

type _Props = {
  children?: React.ReactNode;
  dataTestid?: string;
  showFooter?: boolean;
  showOssLink?: boolean;
  version?: string;
};

const HeaderlessContent = ({
  children,
  dataTestid = 'content-container',
  showFooter,
  showOssLink,
  version,
}: _Props) => {
  return (
    <ContentContainer data-testid={dataTestid}>
      {showFooter ? (
        <>
          <KeepFooterDown>{children}</KeepFooterDown>
          <FooterContainer>
            <Footer showOssLink={showOssLink} version={version} />
          </FooterContainer>
        </>
      ) : (
        children
      )}
    </ContentContainer>
  );
};

HeaderlessContent.displayName = 'HeaderlessContent';

export default HeaderlessContent;
