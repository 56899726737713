import { produce } from 'immer';
import type { ActionType } from 'typesafe-actions';
import { createReducer } from 'typesafe-actions';
import { fetch, set } from './actions';
import type { PlayerProfileState } from './types';

type Action = ActionType<typeof fetch | typeof set>;

export const initialPlayerProfileState: PlayerProfileState = {
  error: null,
  filter: '',
  isFetching: false,
  timestamp: 0,
  items: [],
};

const reducer = createReducer<PlayerProfileState, Action>(initialPlayerProfileState)
  .handleAction(
    fetch.request,
    produce((draft: PlayerProfileState) => {
      draft.isFetching = true;
      draft.error = null;
    })
  )
  .handleAction(
    fetch.success,
    produce((draft: PlayerProfileState, action: ActionType<typeof fetch.success>) => {
      draft.isFetching = false;
      draft.items = action.payload;
    })
  )
  .handleAction(
    fetch.failure,
    produce((draft: PlayerProfileState, action: ActionType<typeof fetch.failure>) => {
      draft.isFetching = false;
      draft.error = action.payload;
    })
  )
  .handleAction(
    fetch.cancel,
    produce((draft: PlayerProfileState) => {
      draft.isFetching = false;
    })
  )
  .handleAction(
    set.filter,
    produce((draft: PlayerProfileState, action: ActionType<typeof set.filter>) => {
      draft.filter = action.payload;
    })
  )
  .handleAction(
    set.timestamp,
    produce((draft: PlayerProfileState, action: ActionType<typeof set.timestamp>) => {
      draft.timestamp = action.payload;
    })
  );

export default reducer;
