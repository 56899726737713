import type { CombinedState } from 'redux';
import { createSelector } from 'reselect';
import type { EncoderChannels } from '@studio/types';
import type { RootState } from '../root-reducer';
import type { EncoderChannelsState } from './types';

export const selectEncoderChannelsState = (state: RootState): CombinedState<EncoderChannelsState> =>
  state.encoderChannels;

export const selectFilter = createSelector(selectEncoderChannelsState, (state): string => state.filter);

export const selectIsFetching = createSelector([selectEncoderChannelsState], (state): boolean => state.isFetching);

export const selectItems = createSelector(
  [selectEncoderChannelsState],
  (state): EncoderChannels.Get.EncoderChannel[] => state.items
);

export const selectOptions = createSelector([selectItems], (items) => {
  return items.reduce<Map<string, EncoderChannels.Derived.EncoderChannelsOptions>>(
    (agg, profile) => agg.set(profile.uuid, { label: profile.name, value: profile.uuid, data: profile }),
    new Map()
  );
});

export const filterItems = createSelector(
  selectItems,
  selectFilter,
  (items, filter): EncoderChannels.Get.EncoderChannel[] => {
    return items.filter((item) => item.name.toLowerCase().includes(filter.toLowerCase()));
  }
);

export const selectTimestamp = createSelector(selectEncoderChannelsState, (state): number => state.timestamp);

export const selectItem = createSelector(
  selectItems,
  (_: unknown, uuid: string) => uuid,
  (items, uuid): EncoderChannels.Get.EncoderChannel => {
    return items.reduce<EncoderChannels.Get.EncoderChannel>(
      (agg: EncoderChannels.Get.EncoderChannel, profile: EncoderChannels.Get.EncoderChannel) => {
        return !agg.uuid && profile.uuid === uuid ? profile : agg;
      },
      {
        bucket: '',
        deleteAfter: 0,
        description: '',
        lanOnly: false,
        name: '',
        regionId: null,
        uuid: '',
      }
    );
  }
);
