import type { Theme, SerializedStyles } from '@emotion/react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { HEADER_HEIGHT, LOGO_DESKTOP_WIDTH } from '@studio/constants/dashboard-style-constants';

// TODO: add sizing for mobile and tablet breakpoints
export const AppLogo = styled.div`
  font-size: ${LOGO_DESKTOP_WIDTH}px;
  margin-right: ${(props) => props.theme.spacing.s};

  ${(props) => props.theme.mq.xl} {
    margin-right: ${(props) => props.theme.spacing.m};
  }
`;

// setting the base font size value here so we scale down on breakpoints
export const Header = styled.div`
  grid-area: header;
  display: flex;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  padding: 0 ${(props) => props.theme.spacing.m};
  height: ${HEADER_HEIGHT}px;
  background-color: ${(props) => props.theme.palette.background.paper};
  color: ${(props) => props.theme.palette.primary.main};
  box-shadow: ${(props) => props.theme.shadows[1]};
  font-size: 14px;
  overflow: hidden;
  position: absolute;
  width: 100%;
  z-index: ${(props) => props.theme.zIndex.header};

  ${(props) => props.theme.mq.xl} {
    font-size: 16px;
    position: static;
    width: unset;
    overflow: visible;
  }
`;

// set base to 16px so icons maintain size
export const IconWrap = styled.div<{ isActiveTab: boolean }>`
  display: flex;
  font-size: 16px;
  cursor: pointer;
  padding: ${(props) => props.theme.spacing.xs};
  border-radius: 2px;
  background-color: ${({ isActiveTab, ...props }) =>
    isActiveTab ? props.theme.palette.primary.background : 'transparent'};
`;

export const IconAnchor = styled.button`
  text-decoration: inherit;
  color: inherit;
  cursor: inherit;
  background-color: transparent;
  border: none;
  padding: 0;
`;

export const hamburgerButtonStyles = (theme: Theme, isOpen: boolean): SerializedStyles => css`
  display: block;
  width: 18.75px;
  height: 12px;
  position: absolute;

  &:after,
  &:before {
    content: '';
    position: absolute;
    top: 0;
    height: 0;
    border-bottom: 2px solid ${theme.palette.text.title};
    width: 100%;
    left: 0;
    right: 0;
    transition: all ease-out 0.3s;
  }

  &:after {
    top: 100%;
  }

  span {
    display: block;
    text-indent: 100%;
    overflow: hidden;
    height: 2px;
    background-color: ${theme.palette.text.title};
    width: 100%;
    position: absolute;
    top: 50%;
    transition: all ease-out 0.1s;
  }

  ${isOpen &&
  `&:after {
    transform: rotate(-45deg);
    transform-origin: center;
    top: 50%;
  }

  &:before {
    transform: rotate(45deg);
    transform-origin: center;
    top: 50%
  }

  span {
    opacity: 0;
  }`}
`;
