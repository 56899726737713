import type * as React from 'react';
import type { Theme } from '@emotion/react';
import styled from '@emotion/styled';

const getPadding = (size: string, theme: Theme) => {
  switch (size) {
    case 'xs':
      return theme.spacing.xs;
    case 's':
      return theme.spacing.s;
    case 'm':
      return theme.spacing.m;
    case 'l':
      return theme.spacing.l;
    case 'xl':
      return theme.spacing.xl;
    default:
      return theme.spacing.m;
  }
};

const S = {
  header: (theme: Theme) => `
    background-color: ${theme.palette.background.table};
    color: ${theme.palette.text.primary};
    text-transform: uppercase;
    text-align: left;
    padding: ${theme.spacing.m};
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    ${theme.mq.xl} {
      padding: ${theme.spacing.m} ${theme.spacing.l};
    }
  `,
  stickyHeader: (theme: Theme) => `
    position: sticky;
    top: 0;
    box-shadow: inset 0 1px 0 ${theme.palette.divider};
  `,
  table: (theme: Theme) => `
    width: fit-content;
    box-sizing: border-box;
    border-collapse: collapse;
    font-size: 16px;
    min-width: 100%;
    box-shadow: ${theme.shadows[1]};
  `,
};

export const ListTableData = styled.div<{ height?: React.CSSProperties['height']; rowSpacing: string }>`
  ${({ height, rowSpacing, theme }) => `
  padding: ${theme.spacing.m};
  &:last-of-type {
    padding-right: ${theme.spacing.xs};
  }
  vertical-align: middle;
  ${height && `height: ${height};`}
  ${theme.mq.lg} {
    padding: ${theme.spacing.m};
    &:last-of-type {
      padding-right: ${theme.spacing.m};
    }
  }
  ${theme.mq.xl} {
    padding: ${getPadding(rowSpacing, theme)} ${theme.spacing.l};
    &:last-of-type {
      padding-right: ${theme.spacing.l};
    }
  }
  `}
`;

export const SubComponentWrapper = styled.div<{ isExpanded: boolean }>`
  max-height: ${({ isExpanded }) => (isExpanded ? '100%' : '0')};
  transform: ${({ isExpanded }) => (isExpanded ? 'scaleY(1) translateY(0px)' : 'scaleY(0) translateY(-100px)')};
  transition: transform 50ms;
`;

export { S };
