import type { CombinedState } from 'redux';
import { createSelector } from 'reselect';
import type { EncoderLogs } from '@studio/types';
import type { RootState } from '../root-reducer';
import type { EncoderLogsState } from './types';

export const selectEncoderLogsState = (state: RootState): CombinedState<EncoderLogsState> => state.encoderLogs;

export const selectItems = createSelector(
  selectEncoderLogsState,
  (state): Map<string, EncoderLogs.Get.LogEntry[]> => state.items
);

export const selectError = createSelector(selectEncoderLogsState, (state): Error | null => state.error);

export const selectFilter = createSelector(selectEncoderLogsState, (state): string => state.filter);

export const selectCurrentEncoderId = createSelector(selectEncoderLogsState, (state): string => state.currentEncoderId);

export const selectItemsByCurrentEncoderId = createSelector(selectItems, selectCurrentEncoderId, (items, id) =>
  items.get(id)
);

export const filterItems = createSelector(selectItemsByCurrentEncoderId, selectFilter, (items, filter) => {
  return items?.filter((item) => item.entry.toLowerCase().includes(filter.toLowerCase()));
});

export const selectEncoderLogsFetching = createSelector(selectEncoderLogsState, (state) => state.isFetching);
