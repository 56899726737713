import type { LatencyDetails } from '@studio/constants/data-center-constants';
import { MAX_ACCEPTABLE_LATENCY, REQUIRED_SAMPLES } from '@studio/constants/data-center-constants';
import type { Regions } from '@studio/types';
import { checkDataCenterLatency } from './client';

async function getRegionSampleLatencies(
  includedRegions: readonly Regions.Get.Region[],
  source: AbortController
): Promise<LatencyDetails[]> {
  let regionList: LatencyDetails[] = [];
  // throw away first sample for each
  console.log('==BEGIN REGION LATENCY SAMPLES==');
  if (includedRegions.length === 1) {
    console.log('Only one region to check. Skipping latency checks.');
    const [region] = includedRegions;
    return [
      {
        region,
        isPassing: true,
        duration: 0,
      },
    ];
  }

  for (const region of includedRegions) {
    let iteration = 0;
    while (iteration <= REQUIRED_SAMPLES) {
      try {
        const timer = new Promise<Promise<null>>((resolve) =>
          setTimeout(resolve, MAX_ACCEPTABLE_LATENCY + 1, { data: { duration: 999 } })
        );

        if (region.latencyCheckUrl) {
          const res = await Promise.race([
            checkDataCenterLatency(region.latencyCheckUrl, source),
            ...(iteration === 0 ? [] : [timer]),
          ]);

          // if cannot get response, break loop and throw out samples for this region
          if (res === null) {
            console.error(`Failed to receive required samples for ${region.name}`);
            regionList = regionList.filter((r) => r.region.uuid !== region.uuid);
            break;
          }
          if (iteration > 0) {
            console.info(`%c ${res.data.duration}ms`, 'margin-left: 5px');
            regionList.push({
              region,
              duration: res.data.duration,
              isPassing: res.data.duration <= MAX_ACCEPTABLE_LATENCY,
            });
          } else {
            console.log(`${region.name} Latencies:`);
          }
        }
      } catch (e) {
        console.log('error', e);
        // catching if axios instance was cancelled here
        break;
      }
      // if iteration is 0, skip adding the responses its only purpose is for dns resolution
      iteration++;
    }
  }
  return regionList;
}

function findRegionWithBestMedianSample(
  regionList: LatencyDetails[],
  includedDataCenters: readonly Regions.Get.Region[]
): LatencyDetails | null {
  let lowestMedianLatencyRegion: LatencyDetails | null = null;
  console.log('==REGION MEDIAN LATENCIES==');
  for (const { name, uuid } of includedDataCenters) {
    const regionSamples = regionList
      .filter((r) => r.region.uuid === uuid)
      .sort((a, b) => {
        return a.duration > b.duration ? 1 : -1;
      });

    if (regionSamples.length < REQUIRED_SAMPLES) {
      continue;
    }
    // remove highest two responses to account for potential high outliers
    regionSamples.splice(-2, 2);
    const medianIndex = Math.floor(regionSamples.length / 2);
    const medianSample = regionSamples[medianIndex];
    console.log(
      `%c Median latency:  ${name}: ${medianSample.duration}ms`,
      `color: ${medianSample.duration > MAX_ACCEPTABLE_LATENCY ? 'red' : 'green'}; font-weight: 700 `
    );

    if (!lowestMedianLatencyRegion || medianSample.duration < lowestMedianLatencyRegion.duration) {
      lowestMedianLatencyRegion = medianSample;
    }
  }
  console.log(
    `%c Best Median Latency:  ${lowestMedianLatencyRegion?.region.name}: ${lowestMedianLatencyRegion?.duration}ms`,
    `font-weight: 700`
  );
  return lowestMedianLatencyRegion;
}

export async function getClosestRegion(
  includedRegionIds: string[],
  regions: Regions.Get.Region[],
  source: AbortController
): Promise<LatencyDetails | null> {
  const includedDataCenters = regions.filter(({ uuid }) => includedRegionIds.includes(uuid));
  const dataCentersToTest = includedDataCenters.length ? includedDataCenters : regions;

  const regionList = await getRegionSampleLatencies(dataCentersToTest, source);

  // if length is 0, return null to let React component know that no region completed all required latency samples
  if (!regionList.length) {
    return null;
  }
  // if only one data center, we don't need to find best median sample and can just return the one data center we have
  if (regionList.length === 1) {
    return regionList[0];
  }

  // Find best median latency
  return findRegionWithBestMedianSample(regionList, dataCentersToTest);
}
