import * as React from 'react';
import { useTheme } from '@emotion/react';
import { Progress } from '@resi-media/resi-ui';
import InfiniteScroll from 'react-infinite-scroll-component';
import { ErrorBlock } from '../ErrorBlock';

type InfiniteScrollPagedProps<T> = {
  error?: Error | null;
  getMore: () => void;
  hasMore: () => boolean;
  items: T[];
  renderList: (visibleList: T[]) => React.ReactNode;
  scrollableTarget: string;
};

// This infinite scroll component should be used when you are loading the list of items using a api that supports pagination.
// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
const InfiniteScrollPaged = <T extends unknown>({
  error,
  getMore,
  hasMore,
  items,
  renderList,
  scrollableTarget,
}: InfiniteScrollPagedProps<T>) => {
  const theme = useTheme();

  return (
    <div data-testid="infinite-scroll-paged">
      <InfiniteScroll
        dataLength={items.length}
        hasMore={hasMore()}
        loader={
          error ? null : (
            <div style={{ textAlign: 'center', paddingBottom: theme.spacing.xl }}>
              <Progress sizeVariant="m" />
            </div>
          )
        }
        next={getMore}
        scrollableTarget={scrollableTarget}
        style={{ overflow: 'none' }}
      >
        {renderList(items)}
        <ErrorBlock error={error} />
      </InfiniteScroll>
    </div>
  );
};

InfiniteScrollPaged.displayName = 'InfiniteScrollPaged';

export default InfiniteScrollPaged;
