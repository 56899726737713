import * as React from 'react';
import { useTheme } from '@emotion/react';
import GlobalContext, { Breakpoint, GLOBAL_ACTION_TYPES } from '@studio/contexts/global';

const GlobalListeners = ({ children }: { children: React.ReactNode }) => {
  const mounted = React.useRef(false);
  const theme = useTheme();
  const { dispatch } = React.useContext(GlobalContext);

  const resizeHandler = React.useCallback(() => {
    const width = window.innerWidth;

    if (width <= theme.breakpoints.values.md) {
      dispatch({ type: GLOBAL_ACTION_TYPES.CURRENT_BREAKPOINT, payload: Breakpoint.MOBILE_SMALL });
      return;
    }
    if (width <= theme.breakpoints.values.lg) {
      dispatch({ type: GLOBAL_ACTION_TYPES.CURRENT_BREAKPOINT, payload: Breakpoint.MOBILE });
      return;
    }
    if (width <= theme.breakpoints.values.xl) {
      dispatch({ type: GLOBAL_ACTION_TYPES.CURRENT_BREAKPOINT, payload: Breakpoint.TABLET });
      return;
    }
    dispatch({ type: GLOBAL_ACTION_TYPES.CURRENT_BREAKPOINT, payload: Breakpoint.DESKTOP });
  }, [dispatch, theme.breakpoints.values.lg, theme.breakpoints.values.md, theme.breakpoints.values.xl]);

  React.useEffect(() => {
    if (!mounted.current) {
      resizeHandler();
      window.addEventListener('resize', resizeHandler);
      mounted.current = true;
    }
    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, [resizeHandler]);

  // Needs to remain in separate useEffect to work properly
  React.useEffect(() => {
    return () => {
      mounted.current = false;
    };
  }, []);

  return <>{children}</>;
};

GlobalListeners.displayName = 'GlobalListeners';

export default GlobalListeners;
