import type { Draft } from '@resi-media/resi-ui';
import { parseISO, isValid } from 'date-fns';
import type { Timezone } from '@studio/store/timezones';

export function convertPxToNumber(pxString: string): number {
  const height: number = +pxString.slice(0, pxString.length - 2);
  if (isNaN(height)) {
    console.error(`Could not get number from px value ${pxString}`);
    return 0;
  }
  return height;
}

// time should be a string specified in one of the following formats:
// hour/min/sec: 2:33:44.123
// min/sec:        33:44.123
// sec only:          44.123
// large seconds:   9224
// decimal part of seconds is optional
// returns a float; returns NaN if time is invalid
export function parseTimeIntoSeconds(time: number | string): number {
  enum TimeFormat {
    SECONDS,
    MINUTES_SECONDS,
    HOURS_MINUTES_SECONDS,
  }

  if (!time) {
    return NaN;
  }

  if (typeof time === 'number') {
    return time / 1000;
  }

  const parsedTime = time.split(':');
  const colonCount = parsedTime.length - 1;
  const [seconds, minutes, hours] = parsedTime.reverse();
  switch (colonCount) {
    case TimeFormat.SECONDS:
      return +time;
    case TimeFormat.MINUTES_SECONDS:
      return +minutes * 60 + +seconds;
    case TimeFormat.HOURS_MINUTES_SECONDS:
      return +hours * 3600 + +minutes * 60 + +seconds;
    default:
      return NaN;
  }
}

export const secondsToTimeDisplay = (seconds: number): string => {
  let hour = Math.floor(seconds / 3600);
  let min = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = (seconds % 3600) % 60;
  // if seconds still above 30, round up minutes
  if (remainingSeconds >= 30) {
    const roundedMinute = ++min;
    if (roundedMinute === 60) {
      min = 0;
      hour = ++hour;
    } else {
      min = roundedMinute;
    }
  }
  return `(${hour} hr ${min} min)`;
};

export namespace SecondsToTimerDisplay {
  const getHoursMinSeconds = (seconds: number): [number, number, number] => {
    const hour = Math.floor(seconds / 3600);
    const min = Math.floor((seconds % 3600) / 60);
    const sec = (seconds % 3600) % 60;
    return [hour, min, sec];
  };

  interface TimerDisplayOptions {
    prependLeadingZero: boolean;
  }

  const defaultOptions: TimerDisplayOptions = {
    prependLeadingZero: true,
  };

  const formatNumbersToDisplay = (numericValues: number[], options = defaultOptions) =>
    numericValues
      .map((n, i) => {
        if (!options.prependLeadingZero && i === 0) {
          return n;
        }
        if (n < 10) {
          return `0${n}`;
        }
        return n;
      })
      .join(':');

  export const hoursMinutesSeconds = (seconds: number, options = defaultOptions): string => {
    return formatNumbersToDisplay(getHoursMinSeconds(seconds), options);
  };

  export const hoursMinutesRounded = (seconds: number, options = defaultOptions): string => {
    const [hour, min, sec] = getHoursMinSeconds(seconds);
    if (sec < 30) {
      return formatNumbersToDisplay([hour, min], options);
    }
    const roundedMinute = min + 1;
    if (roundedMinute === 60) {
      return formatNumbersToDisplay([hour + 1, 0], options);
    }
    return formatNumbersToDisplay([hour, roundedMinute], options);
  };
  export const minutesSeconds = (seconds: number, options = defaultOptions): string => {
    const [_, ...minSec] = getHoursMinSeconds(seconds);
    return formatNumbersToDisplay(minSec, options);
  };
}

export const secondsToVerboseTimeDisplay = (seconds: number): string => {
  const timerDisplay = SecondsToTimerDisplay.hoursMinutesSeconds(seconds);
  return `${timerDisplay}`.split(':')[0].length === 1 ? `0${timerDisplay}` : `${timerDisplay}`;
};

export const getMeridianStatus = (time: string): { isAm: boolean; isMeridian: boolean; isPm: boolean } => {
  const [hour] = time.split(':').map((timeChunk) => parseInt(timeChunk));
  const isAm = time.toLowerCase().includes('a');
  const isPm = time.toLowerCase().includes('p');
  return {
    isAm,
    isPm,
    isMeridian: (isAm || isPm) && hour <= 12,
  };
};

export const meridianToMilitary = (meridianTime: string): string => {
  const { isAm, isMeridian, isPm } = getMeridianStatus(meridianTime);
  const [hour, min, second] = meridianTime.split(':').map((timeChunk) => parseInt(timeChunk));
  if (!isMeridian) console.log(`Time passed was not meridian time: ${meridianTime}`);
  let finalHour = hour;
  if (isPm && finalHour < 12) {
    finalHour += 12;
  }
  if (isAm && hour === 12) {
    finalHour = 0;
  }
  const finalSecond = second ? `:${second}` : '';
  return `${finalHour < 10 ? `0${finalHour}` : finalHour}:${min < 10 ? `0${min}` : min}${finalSecond}`;
};

export const createDateTime = (date: Date | number | string, time?: string): Date => {
  if (!time) return new Date(date);
  const { isMeridian } = getMeridianStatus(time);
  let newTime = time;
  if (isMeridian) {
    newTime = meridianToMilitary(time);
  }
  const newDayTime = new Date(date);
  const [timeHour, timeMinute, timeSecond] = newTime.split(/[:\s]/);
  newDayTime.setHours(+timeHour, +timeMinute, timeSecond ? +timeSecond : 0);
  return newDayTime;
};

export const formatDateToSlashes = (date?: string): string => {
  const formattedDate = date ? date.replace(/-/g, '/') : '';
  if (date?.includes('T')) {
    return formattedDate.split('T')[0];
  }
  return formattedDate;
};

export const getFormattedISODate = (
  utils: Draft.LocalizationProvider.Utils<Date>,
  dateInIsoFormat: string,
  dateFormat: keyof Draft.LocalizationProvider.Formats
): string => {
  const parsedDate = utils.parseISO(dateInIsoFormat);
  if (utils.isValid(parsedDate)) {
    return utils.format(parsedDate, dateFormat);
  }
  return '';
};

export const getValidDate = (dateInIsoFormat: string): Date => {
  const parsedDate = parseISO(dateInIsoFormat);
  if (isValid(parsedDate)) {
    return parsedDate;
  }
  return new Date();
};

export const convertKebabToDateTime = (kebabDate?: string, time?: string): Date => {
  if (kebabDate && !kebabDate.includes('-')) {
    throw Error(
      `Required input for this function is in the kebab "yyyy-mm-dd" format, but received: date: ${kebabDate}, time: ${time}`
    );
  }
  const dateStringWithSlashes = formatDateToSlashes(kebabDate);
  return createDateTime(dateStringWithSlashes, time);
};

export const militaryTimeToSeconds = (militaryTime: string): number => {
  const { isMeridian } = getMeridianStatus(militaryTime);
  const [hour, min] = militaryTime.split(':').map((timeChunk) => parseInt(timeChunk));
  if (isMeridian) {
    console.error(`Time passed is meridian time, this requires military time, but received ${militaryTime}.`);
    const _militaryTime = meridianToMilitary(militaryTime);
    return militaryTimeToSeconds(_militaryTime);
  }
  return hour * 3600 + min * 60;
};

export const getOffsetMinutes = (offsetString?: string): number | undefined =>
  offsetString ? militaryTimeToSeconds(offsetString) / 60 : undefined;

export const getTimezoneOffsetMinutes = (timezones: Timezone[], selectedTimezone: string): number =>
  getOffsetMinutes(timezones.find((zone) => zone.name === selectedTimezone)?.offset) ?? 0;

export const getTimeUnits = (
  secondsProp: number
): { days: number; hours: number; minutes: number; seconds: number } => {
  const d = Math.floor(secondsProp / 86_400);
  const h = Math.floor(secondsProp / 3_600) - d * 24;
  const m = Math.floor(secondsProp / 60) - h * 60 - d * 24 * 60;
  const s = secondsProp % 60;
  return {
    days: d,
    hours: h,
    minutes: m,
    seconds: s,
  };
};

export const getByteSizeFromString = (str?: string): number => (str ? new Blob([str]).size : 0);

export const formatFloat = (num?: number): string => {
  if (num === undefined) {
    return '0';
  }
  const fixedNum = num.toFixed(2);
  return fixedNum.endsWith('0') ? num.toFixed(1) : fixedNum;
};

export const convertKbpsToMbps = (kbps: number | null | undefined): number => {
  return kbps ? kbps / 1000 : 0;
};

export const convertMbpsToKbps = (mbps: number | null | undefined): number => {
  return mbps ? mbps * 1000 : 0;
};
