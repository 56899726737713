import type { AxiosResponse } from 'axios';
import { HTTP_METHODS } from '@studio/constants/axios';
import { CENTRAL_API_V3 } from '@studio/constants/env-variables';
import type { ContentDestinations } from '@studio/types';

export const root = {
  [HTTP_METHODS.POST]: {
    url: `${CENTRAL_API_V3}/customers/@customerId/contentdestinations/@destinationId/republish`,
    request: {} as ContentDestinations.Post.Republish,
    response: {} as AxiosResponse,
    params: {} as { destinationId: string },
    method: HTTP_METHODS.POST,
  },
};
