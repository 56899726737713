import * as React from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Draft } from '@resi-media/resi-ui';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import * as Yup from 'yup';
import { usePrefix } from '@studio/hooks';
import type { SwitchOrgModalFormState } from '../types';

type _Props = {
  onSubmit: (password: string) => void;
};

const SwitchOrgModalForm = ({ onSubmit }: _Props) => {
  const { prefixNS } = usePrefix('pages:', 'adminSearch');

  const switchOrgModalFormSchema = Yup.object().shape({
    password: Yup.string().required(),
  });

  const methods = useForm<SwitchOrgModalFormState>({
    mode: 'all',
    defaultValues: { password: '' },
    resolver: yupResolver(switchOrgModalFormSchema),
  });

  const handleSubmit = (data: SwitchOrgModalFormState) => {
    onSubmit(data.password);
  };

  return (
    <FormProvider {...methods}>
      <form
        data-testid="switch-org-modal-form"
        id="switch-org-modal-form"
        onSubmit={methods.handleSubmit(handleSubmit)}
      >
        <Controller
          name="password"
          render={({
            field: { name, onBlur, onChange, ref, value },
            fieldState: { error, isTouched },
            formState: { isSubmitted },
          }) => {
            return (
              <Draft.FormField
                error={error && prefixNS('fields.password.required')}
                fieldLabel={prefixNS('fields.password.label')}
                htmlFor={name}
                touched={isTouched || isSubmitted}
              >
                <Draft.TextInput<'input'>
                  ref={ref}
                  // eslint-disable-next-line jsx-a11y/no-autofocus
                  autoFocus
                  data-testid="password"
                  hasError={Boolean(error)}
                  id={name}
                  name={name}
                  onBlur={onBlur}
                  onChange={onChange}
                  type="password"
                  value={value}
                />
              </Draft.FormField>
            );
          }}
        />
      </form>
    </FormProvider>
  );
};

SwitchOrgModalForm.displayName = 'SwitchOrgModalForm';

export default SwitchOrgModalForm;
