import { useSelector } from 'react-redux';
import { selectCustomerData } from '@studio/store';

const useHasApiAccess = (): boolean => {
  const customerInfo = useSelector(selectCustomerData);
  const vodPlan = customerInfo?.vodPlan;
  return Boolean(vodPlan && vodPlan.apiRequestsPerMinute > 0);
};

export default useHasApiAccess;
