import { createAsyncAction } from 'typesafe-actions';
import type { SystemEvents } from '@studio/types';
import { ActionTypes } from './types';

export const fetch = {
  subjectEvents: createAsyncAction(
    ActionTypes.FETCH_SUBJECT_EVENTS_REQUEST,
    ActionTypes.FETCH_SUBJECT_EVENTS_REQUEST_SUCCESS,
    ActionTypes.FETCH_SUBJECT_EVENTS_REQUEST_ERROR,
    ActionTypes.FETCH_SUBJECT_EVENTS_REQUEST_CANCEL
  )<
    {
      subjectId: string;
    },
    SystemEvents.Derived.SubjectEvents,
    Error,
    undefined
  >(),
};
