import { HTTP_METHODS } from '@studio/constants/axios';
import { CENTRAL_API_V3 } from '@studio/constants/env-variables';
import type { UserInvites } from '@studio/types';

export const root = {
  [HTTP_METHODS.POST]: {
    url: `${CENTRAL_API_V3}/customers/@customerId/userinvites?isStudio=true`,
    request: {} as UserInvites.Post.User,
    method: HTTP_METHODS.POST,
  },
};

export const id = {
  [HTTP_METHODS.DELETE]: {
    url: `${CENTRAL_API_V3}/customers/@customerId/userinvites/@id`,
    params: {} as { id: string },
    method: HTTP_METHODS.DELETE,
  },
  [HTTP_METHODS.GET]: {
    url: `${CENTRAL_API_V3}/userinvites/@id`,
    response: {} as UserInvites.Get.UserInvite,
    params: {} as { id: string },
    method: HTTP_METHODS.GET,
  },
};
