import { Draft, Inline, PopoutAlt, Stack, Text, useBreakpoint } from '@resi-media/resi-ui';
import { useSelector } from 'react-redux';
import { useIsPlanType, useIsVodPlanType, usePrefix } from '@studio/hooks';
import { selectCustomerData } from '@studio/store/customer';
import NoLibraryPlanSvg from '@svg/NoLibraryPlan.svg';
import { LibraryTrialModal } from '../LibraryTrialModal';

type _Props = {
  description: string;
  title: string;
};

const NoLibraryPlan = ({ description, title }: _Props) => {
  const mediaQuery = useBreakpoint();
  const { linkT, prefixNS } = usePrefix('pages:', 'library.noPlan');
  const { setModal } = Draft.ModalContext.useModal();
  const customerInfo = useSelector(selectCustomerData);
  const { isSocialOnly } = useIsPlanType();
  const isMultiSiteOnly = !customerInfo?.vodPlanId && !customerInfo?.webPlanId;
  const isMultiOrSocialOnly = isSocialOnly || isMultiSiteOnly;
  const ResponsiveWrapper = mediaQuery.xxl ? Inline : Stack;
  const ResponsiveContainer = mediaQuery.lg ? Inline : Stack;
  const { isStarter } = useIsVodPlanType();

  return (
    <Draft.Card dataTestId="no-library-plan">
      <Draft.CardSection
        dataTestId="library-no-access-card__section"
        display="flex"
        flexDir={mediaQuery.xxl ? 'row' : 'column'}
        flexWrap="nowrap"
        gap="xl"
      >
        <ResponsiveWrapper data-testid="no-library-plan__container--text" gap="m">
          <Stack gap={{ xs: 'l', lg: 'xl' }}>
            <Text as="h4">{title}</Text>
            <Text variant="body2">{description}</Text>
            {!isMultiOrSocialOnly && !isStarter ? (
              <ResponsiveContainer data-testid="no-library-plan__container--cta" gap="m">
                <Draft.Button
                  dataTestId="free-trial-button"
                  label={prefixNS('tryFree')}
                  onClick={() => {
                    setModal(() => <LibraryTrialModal />, {
                      dataTestId: 'library-trial-modal',
                      minHVariant: 's',
                    });
                  }}
                />
                <Draft.Button
                  as="a"
                  dataTestId="learn-more-button"
                  endNode={<PopoutAlt />}
                  href={linkT('libraryFeaturePage')}
                  label={prefixNS('learnMore')}
                  target="_blank"
                  variant="outlined"
                />
              </ResponsiveContainer>
            ) : (
              <Stack>
                {isMultiOrSocialOnly && (
                  <Text variant="body2" weightVariant="bold">
                    {prefixNS('socialOnly')}
                  </Text>
                )}
                <Text variant="body2">{prefixNS('contactCustomerSuccess')}</Text>
              </Stack>
            )}
            {!mediaQuery.xl && <NoLibraryPlanSvg height="300" width="400" />}
          </Stack>
          {mediaQuery.xl && <NoLibraryPlanSvg height="300" width="400" />}
        </ResponsiveWrapper>
      </Draft.CardSection>
    </Draft.Card>
  );
};

NoLibraryPlan.displayName = 'NoLibraryPlan';

export default NoLibraryPlan;
