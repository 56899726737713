import * as React from 'react';

const useScrollIntoView = <T extends HTMLElement>(shouldScroll: boolean) => {
  const ref = React.useRef<T>(null);
  React.useEffect(() => {
    if (shouldScroll && ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [shouldScroll]);

  return ref;
};

export default useScrollIntoView;
