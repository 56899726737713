import type { SerializedStyles, Theme } from '@emotion/react';
import { css } from '@emotion/react';
import { HEADER_HEIGHT, SIDE_MENU_WIDTH_DESKTOP } from '@studio/constants/dashboard-style-constants';

export const S = {
  container: (theme: Theme, isOpen: boolean): SerializedStyles => css`
    background-color: ${theme.palette.background.paper};
    box-sizing: border-box;
    z-index: ${theme.zIndex.drawer};
    transition:
      left ${theme.transitions.duration.standard}ms ${theme.transitions.easing.sharp},
      visibility ${theme.transitions.duration.standard}ms ${theme.transitions.easing.sharp},
      width ${theme.transitions.duration.standard}ms ${theme.transitions.easing.sharp};
    height: 100%;
    max-height: calc(100vh - ${HEADER_HEIGHT}px);
    @supports (height: 100dvh) {
      max-height: calc(100dvh - ${HEADER_HEIGHT}px);
    }
    overflow-y: auto;
    position: relative;
    left: ${isOpen ? '0' : '100vw'};
    width: ${isOpen ? '100vw' : '0'};
    visibility: ${isOpen ? 'visible' : 'hidden'};

    ${theme.mq.md} {
      left: ${isOpen ? `calc(100vw - ${SIDE_MENU_WIDTH_DESKTOP}px)` : '100vw'};
      width: ${isOpen ? `${SIDE_MENU_WIDTH_DESKTOP}px` : '0'};
    }
  `,
  overlay: (theme: Theme, isOpen: boolean): SerializedStyles => css`
    position: fixed;
    left: 0;
    width: 100vw;
    height: 100%;
    transition:
      background-color ${theme.transitions.duration.standard}ms ${theme.transitions.easing.sharp},
      visibility ${theme.transitions.duration.standard}ms ${theme.transitions.easing.sharp};
    background-color: ${isOpen ? theme.palette.overlay : 'transparent'};
    visibility: ${isOpen ? 'visible' : 'hidden'};
    animation-name: ${isOpen ? 'fadeIn' : 'fadeOut'};
    z-index: ${theme.zIndex.drawer - 1};

    @keyframes fadeIn {
      0% {
        display: none;
      }

      1% {
        display: block;
      }
    }

    @keyframes fadeOut {
      99% {
        display: block;
      }

      100% {
        display: none;
      }
    }
  `,
  subMenuItemContainer: (theme: Theme, isSelectedRoute: boolean, isHoverable = true): SerializedStyles => css`
    background-color: ${isSelectedRoute && theme.palette.primary.background};
    color: ${theme.palette.primary.main};
    cursor: ${isHoverable && 'pointer'};
    padding: ${theme.spacing.s} ${theme.spacing.m};

    &:hover {
      background-color: ${isHoverable && theme.palette.primary.background};
    }
  `,
};
