import { yupResolver } from '@hookform/resolvers/yup';
import { Draft, Progress, Stack, Text } from '@resi-media/resi-ui';
import { useForm, FormProvider } from 'react-hook-form';
import { usePrefix } from '@studio/hooks';
import type { Users } from '@studio/types';
import { ConfirmPassword } from '../ConfirmPassword';
import { HeaderlessContent } from '../HeaderlessContent';
import { LogoHeader } from '../LogoHeader';
import { S } from './styles';

type _Props = {
  buttonText: string;
  email?: string;
  error?: string;
  onSubmit: (password: string) => void;
  subtitle: string;
  title: string;
};

const SetPassword = ({ buttonText, email, error, onSubmit, subtitle, title }: _Props) => {
  const { commonT, prefixNS } = usePrefix('pages:', 'setPassword');

  const initialValues = {
    password: '',
    confirmPassword: '',
    passwordStrength: 0,
  };

  const methods = useForm<Users.Components.PasswordForm>({
    mode: 'all',
    defaultValues: initialValues,
    resolver: yupResolver(ConfirmPassword.useFormValidation()),
  });
  const {
    // Read the formState before render to subscribe the form state through the Proxy
    formState: {
      dirtyFields: _dF,
      isDirty: isFormDirty,
      isSubmitting: isFormSubmitting,
      isValid: isFormValid,
      touchedFields: _tF,
    },
  } = methods;

  const handleSubmit = (data: Users.Components.PasswordForm) => {
    onSubmit(data.password);
  };

  return (
    <HeaderlessContent showFooter version={import.meta.env.VITE_APP_VERSION}>
      <Stack alignItems="center">
        <LogoHeader />
        <FormProvider {...methods}>
          <div css={S.boxWrap}>
            <form onSubmit={methods.handleSubmit(handleSubmit)}>
              <Stack gap="xl">
                <Stack gap="m">
                  <Stack alignItems="center" gap="m">
                    <Text as="h4" dataTestId="set-password-title">
                      {title}
                    </Text>
                    <Text>{subtitle}</Text>
                  </Stack>
                  {error && <Draft.AlertBanner>{error}</Draft.AlertBanner>}
                  <Stack gap="m">
                    {email && email.length > 0 && (
                      <Stack gap="xs">
                        <Text variant="label">{commonT('email')}</Text>
                        <Text dataTestId="set-password__email">{email}</Text>
                      </Stack>
                    )}
                    <ConfirmPassword hintText={prefixNS('passwordHint')} />
                  </Stack>
                </Stack>
                <Draft.Button
                  disabled={isFormSubmitting || !isFormDirty || !isFormValid}
                  isFullWidth
                  label={buttonText}
                  type="submit"
                  {...(isFormSubmitting && {
                    startNode: <Progress colorVariant="inherit" sizeVariant="inherit" />,
                  })}
                />
              </Stack>
            </form>
          </div>
        </FormProvider>
      </Stack>
    </HeaderlessContent>
  );
};

SetPassword.displayName = 'SetPassword';

export default SetPassword;
