import isBefore from 'date-fns/isBefore';
import i18next from 'i18next';
import { PUBLISH_STATUS } from '@studio/constants/schedules';
import type { PublishStatus } from '@studio/types/shared';

export const getIsAccessExpired = (expiresAt?: string | null): boolean => {
  if (!expiresAt) {
    return false;
  }

  const currentTime = Date.now();
  const expiration = Date.parse(expiresAt);

  // Is expiration before current date?
  return isBefore(expiration, currentTime);
};

export const getStringForPrivacyOption = (privacy: string): string => {
  switch (privacy) {
    case 'public':
      return i18next.t('common:public');
    case 'private':
      return i18next.t('common:private');
    case 'friends_of_friends':
      return i18next.t('common:friendsOfFriends');
    case 'all_friends':
      return i18next.t('common:allFriends');
    case 'unlisted':
      return i18next.t('common:unlisted');
    default:
      return privacy;
  }
};

export const getStringForPublishStatus = (publishStatus: PublishStatus): string => {
  switch (publishStatus) {
    case PUBLISH_STATUS.SCHEDULED_PUBLISHED:
      return i18next.t('common:scheduledPost');
    case PUBLISH_STATUS.PUBLISHED:
      return i18next.t('common:published');
    case PUBLISH_STATUS.UNPUBLISHED:
      return i18next.t('common:unpublished');
    default:
      return publishStatus;
  }
};
