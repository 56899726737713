import type { CombinedState } from 'redux';
import { createSelector } from 'reselect';
import { DECODER_STATUS } from '@studio/constants/decoders';
import { ENCODER_STATUS } from '@studio/constants/encoders';
import { ALL_VERSIONS } from '@studio/constants/monitors';
import { compareSemanticVersions } from '@studio/helpers';
import type { RootState } from '@studio/store/root-reducer';
import type { Monitors } from '@studio/types';
import { selectCustomerNameData } from '../../customer/selectors';
import type { MonitorState } from './types';

export const selectMonitorsState = (state: RootState): CombinedState<MonitorState> => state.admin.monitors;

const encoders = {
  cache: createSelector(selectMonitorsState, (state) => state.encoders.dataCache),
  data: createSelector(selectMonitorsState, (state) => state.encoders.data),
  isFetching: createSelector(selectMonitorsState, (state) => state.encoders.isFetching),
  error: createSelector(selectMonitorsState, (state) => state.encoders.error),
  errored: createSelector(selectMonitorsState, (state) => state.erroredEncoders.data),
  erroredCache: createSelector(selectMonitorsState, (state) => state.erroredEncoders.dataCache),
  filter: createSelector(selectMonitorsState, (state): string => state.encoders.filter),
  online: createSelector(
    selectMonitorsState,
    selectCustomerNameData,
    (state, customerNames): Monitors.Get.V2.Encoders[] => {
      return state.encoders.data.map((encoder) => {
        return {
          ...encoder,
          customerName: customerNames?.find((name) => encoder.customerId === name.uuid)?.name,
        };
      });
    }
  ),
  version: createSelector(selectMonitorsState, (state): string => state.encoders.version),
};

const decoders = {
  cache: createSelector(selectMonitorsState, (state) => state.decoders.dataCache),
  data: createSelector(selectMonitorsState, (state) => state.decoders.data),
  isFetching: createSelector(selectMonitorsState, (state) => state.decoders.isFetching),
  error: createSelector(selectMonitorsState, (state) => state.decoders.error),
  filter: createSelector(selectMonitorsState, (state) => state.decoders.filter),
  version: createSelector(selectMonitorsState, (state) => state.decoders.version),
  online: createSelector(
    selectMonitorsState,
    selectCustomerNameData,
    (state, customerNames): Monitors.Get.V2.Decoders[] => {
      return state.decoders.data.map((decoder) => {
        return {
          ...decoder,
          customerName: customerNames?.find((name: { name: string; uuid: string }) => decoder.fkCustomer === name.uuid)
            ?.name,
        };
      });
    }
  ),
};

const active = {
  encoders: createSelector(encoders.online, (state) => state.filter((e) => e.status === ENCODER_STATUS.STARTED)),
  decoders: createSelector(decoders.online, (state) => state.filter((e) => e.status === DECODER_STATUS.PLAYING)),
};

const filter = {
  decoders: {
    online: createSelector(
      decoders.online,
      decoders.filter,
      decoders.version,
      (currentDecoders, filter, playerVersion) =>
        currentDecoders
          .filter(
            (decoder) =>
              decoder.userName.toLowerCase().includes(filter.toLowerCase()) &&
              (decoder.playerVersion === playerVersion || playerVersion === ALL_VERSIONS)
          )
          .sort((a, b) => a.userName.localeCompare(b.userName))
    ),
    playing: createSelector(
      active.decoders,
      decoders.filter,
      decoders.version,
      (currentDecoders, filter, playerVersion) =>
        currentDecoders
          .filter(
            (decoder) =>
              decoder.userName.toLowerCase().includes(filter.toLowerCase()) &&
              (decoder.playerVersion === playerVersion || playerVersion === 'All Versions')
          )
          .sort((a, b) => a.userName.localeCompare(b.userName))
    ),
  },
  encoders: {
    errored: createSelector(encoders.errored, encoders.filter, encoders.version, (encoders, filter, encoderVersion) =>
      encoders
        .filter(
          (encoder) =>
            encoder.name.toLowerCase().includes(filter.toLowerCase()) &&
            (encoder.version === encoderVersion || encoderVersion === 'All Versions')
        )
        .sort((a, b) => a.name.localeCompare(b.name))
    ),
    online: createSelector(encoders.online, encoders.filter, encoders.version, (encoders, filter, encoderVersion) =>
      encoders
        .filter(
          (encoder) =>
            encoder.encoderName.toLowerCase().includes(filter.toLowerCase()) &&
            (encoder.encoderVersion === encoderVersion || encoderVersion === 'All Versions')
        )
        .sort((a, b) => a.encoderName.localeCompare(b.encoderName))
    ),
    started: createSelector(active.encoders, encoders.filter, encoders.version, (encoders, filter, encoderVersion) =>
      encoders
        .filter(
          (encoder) =>
            encoder.encoderName.toLowerCase().includes(filter.toLowerCase()) &&
            (encoder.encoderVersion === encoderVersion || encoderVersion === 'All Versions')
        )
        .sort((a, b) => a.encoderName.localeCompare(b.encoderName))
    ),
  },
};

const options = {
  decoders: {
    playing: createSelector(active.decoders, (state) =>
      [...new Set(state.map((e) => (e.playerVersion !== null ? e.playerVersion : '')))]
        .sort(compareSemanticVersions)
        .map((e) => {
          return { label: e, value: e };
        })
    ),
    online: createSelector(decoders.online, (state) =>
      [...new Set(state.map((e) => (e.playerVersion !== null ? e.playerVersion : '')))]
        .sort(compareSemanticVersions)
        .map((e) => {
          return { label: e, value: e };
        })
    ),
  },
  encoders: {
    online: createSelector(encoders.online, (state) =>
      [...new Set(state.map((e) => (e.encoderVersion !== null ? e.encoderVersion : '')))]
        .sort(compareSemanticVersions)
        .map((e) => {
          return { label: e, value: e };
        })
    ),
    started: createSelector(active.encoders, (state) =>
      [...new Set(state.map((e) => (e.encoderVersion !== null ? e.encoderVersion : '')))]
        .sort(compareSemanticVersions)
        .map((e) => {
          return { label: e, value: e };
        })
    ),
    errored: createSelector(encoders.errored, (state) =>
      [...new Set(state.map((e) => (e.version !== null ? e.version : '')))].sort(compareSemanticVersions).map((e) => {
        return { label: e, value: e };
      })
    ),
  },
};

export const select = {
  encoders,
  decoders,
  active,
  filter,
  options,
};
