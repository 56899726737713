import * as React from 'react';
import type { Theme } from '@emotion/react';
import { css } from '@emotion/react';
import { useBreakpoint, Draft, Link, Stack, Text, Inline } from '@resi-media/resi-ui';
import { Link as ReactRouterLink } from 'react-router-dom';
import { usePrefix } from '@studio/hooks';

const AppFooter = () => {
  const mediaQuery = useBreakpoint();
  const utils = Draft.useUtils<Date>();
  const { commonT, linkT, prefixNS } = usePrefix('navigation:');

  const ResponsiveWrapper = mediaQuery.lg ? Inline : Stack;
  const SubResponsiveWrapper = mediaQuery.md ? Inline : Stack;
  const ExternalLink = ({ label, url }: { label: React.ReactNode; url: string }) => (
    <Link href={url} rel="noopener noreferrer" target="_blank" variant="body3">
      {label}
    </Link>
  );
  const Bullet = () =>
    mediaQuery.md ? (
      <Text as="span" colorVariant="disabled" variant="body3">
        &#8226;
      </Text>
    ) : null;

  return (
    <footer
      css={(theme: Theme) => css`
        border-top: 1px solid ${theme.palette.border};
        ${theme.mixins.insetSquish('m')(theme)}
      `}
      data-testid="app-footer"
    >
      <ResponsiveWrapper dataTestId="app-footer__responsive" gap="m">
        <SubResponsiveWrapper
          alignItems="center"
          css={css`
            flex: 1 1 100%;
          `}
          dataTestId="app-footer__sub-responsive"
          flexWrap="wrap"
          gap="m"
          justifyContent={mediaQuery.lg ? 'flex-start' : 'center'}
        >
          <ExternalLink label={commonT('termsPrivacy')} url="https://resi.io/privacy" />
          <Bullet />
          <ExternalLink label={prefixNS('helpCenter')} url={linkT('helpDocs')} />
          <Bullet />
          <ExternalLink label={commonT('contactUs')} url="https://resi.io/support" />
          <Bullet />
          <Link as={ReactRouterLink} isLink to="/opensource" variant="body3">
            {prefixNS('openSource')}
          </Link>
        </SubResponsiveWrapper>

        <Inline
          css={css`
            flex: 1 1 10%;
          `}
          justifyContent={mediaQuery.lg ? 'flex-end' : 'center'}
        >
          <Text as="span" colorVariant="secondary" variant="body3" whiteSpace="nowrap">
            <span>{`© ${utils.format(new Date(), 'year')} Resi Media LLC`}</span>
          </Text>
        </Inline>
      </ResponsiveWrapper>
    </footer>
  );
};

AppFooter.displayName = 'AppFooter';

export default AppFooter;
