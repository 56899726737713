import { createAction, createAsyncAction } from 'typesafe-actions';
import type { WebChannels } from '@studio/types';
import { ActionTypes } from './types';

export const fetchWebChannelsAsync = createAsyncAction(
  ActionTypes.FETCH_REQUEST,
  ActionTypes.FETCH_REQUEST_SUCCESS,
  ActionTypes.FETCH_REQUEST_ERROR,
  ActionTypes.FETCH_REQUEST_CANCEL
)<undefined, WebChannels.Get.WebChannel[], Error, undefined>();

export const setFetchRequestTimestamp = createAction(ActionTypes.FETCH_REQUEST_TIMESTAMP)<number>();

export const setFilter = createAction(ActionTypes.FILTER_RESULTS)<string>();
