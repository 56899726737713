import { Badge } from '@resi-media/resi-ui';
import { usePrefix } from '@studio/hooks';
import type { Decoders } from '@studio/types';

type _Props = Badge.Props & {
  active?: boolean;
  status: Decoders.Derived.Status['status'];
};

export function getBadgeColor(status: _Props['status'], active?: boolean): Badge.ColorOptions {
  if (!active) return 'negative';

  switch (status) {
    case 'playing':
      return 'positive';
    case 'paused':
      return 'warning';
    case 'idle':
      return 'grey';
    case 'offline':
    default:
      return 'inactive';
  }
}

const DecoderStatusBadge = ({ active, status: statusProp, ...rest }: _Props) => {
  const { prefixNS } = usePrefix('pages:', 'decoders');
  const status = statusProp ?? 'offline';
  const color = getBadgeColor(status, active);

  return (
    <Badge colorVariant={color} dataTestId="decoder-status-badge" {...rest}>
      {prefixNS(active ? status : 'inactive')}
    </Badge>
  );
};

DecoderStatusBadge.displayName = 'DecoderStatusBadge';
export default DecoderStatusBadge;
