import type { SerializedStyles, Theme } from '@emotion/react';
import { css } from '@emotion/react';

export const S = {
  boxWrap: (theme: Theme): SerializedStyles => css`
    padding: ${theme.spacing.xl} ${theme.spacing.l};
    margin: auto;
    width: 95vw;
    background-color: ${theme.palette.background.paper};
    border-radius: ${theme.shape.borderRadius.s};
    text-align: center;

    ${theme.mq.lg} {
      width: 36.688rem;
    }
  `,
  spinnerWrapper: (_theme: Theme): SerializedStyles => css`
    display: flex;
    justify-content: center;
    padding: 1em;
  `,
};
