import type { LibrarySearch } from '@studio/types';

export default function getFacetCounts(
  facets: LibrarySearch.Get.Facet[] = [],
  selectedTags: string[] = []
): LibrarySearch.Derived.FacetCounts {
  const facetCounts: LibrarySearch.Derived.FacetCounts = {
    durationRangeCounts: {
      UNDER_FIVE_MINUTES: 0,
      FIVE_TO_THIRTY_MINUTES: 0,
      OVER_THIRTY_MINUTES: 0,
    },
    thumbnailCounts: {
      false: 0,
      true: 0,
    },
    mediaSourceCounts: {
      LIVE: 0,
      UPLOADED: 0,
    },
    tags: [],
  };

  facets.forEach((facet) => {
    if (facet.name === 'durationRange') {
      facet.values.forEach((value) => Object.assign(facetCounts.durationRangeCounts, { [value.name]: value.count }));
    }
    if (facet.name === 'hasThumbnail') {
      facet.values.forEach((value) => Object.assign(facetCounts.thumbnailCounts, { [value.name]: value.count }));
    }
    if (facet.name === 'mediaSource') {
      facet.values.forEach((value) => Object.assign(facetCounts.mediaSourceCounts, { [value.name]: value.count }));
    }
    if (facet.name === 'tags') {
      facetCounts.tags = selectedTags.reduce(
        (acc, cur) => {
          if (!acc.find((tag) => tag.name === cur)) {
            acc.push({ name: cur, count: 0 });
          }
          return acc;
        },
        [...facet.values]
      );
    }
  });
  return facetCounts;
}
