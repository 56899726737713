import type { AxiosResponse } from 'axios';
import { HTTP_METHODS } from '@studio/constants/axios';
import { MEDIA_METADATA_API_V1 } from '@studio/constants/env-variables';
import type { Feeds } from '@studio/types';

export const root = {
  [HTTP_METHODS.POST]: {
    url: `${MEDIA_METADATA_API_V1}/customers/@customerId/feeds`,
    request: {} as Feeds.Post.Feed,
    response: {} as AxiosResponse,
    method: HTTP_METHODS.POST,
  },
};

export const id = {
  [HTTP_METHODS.DELETE]: {
    method: HTTP_METHODS.DELETE,
    params: {} as { id: string },
    url: `${MEDIA_METADATA_API_V1}/customers/@customerId/feeds/@id`,
  },
  [HTTP_METHODS.PUT]: {
    url: `${MEDIA_METADATA_API_V1}/customers/@customerId/feeds/@id`,
    request: {} as Feeds.Put.Feed,
    params: {} as { id: string },
    method: HTTP_METHODS.PUT,
  },
};
