import { useSelector } from 'react-redux';
import { selectCustomerData } from '@studio/store';

const useRtmpAccess = (): boolean => {
  const customerInfo = useSelector(selectCustomerData);
  const count = customerInfo?.subscriptionDetails.concurrentRtmpStreams ?? 0;
  return count > 0;
};

export default useRtmpAccess;
