import { zxcvbnOptions } from '@zxcvbn-ts/core';

const loadOptions = async () => {
  const zxcvbnCommonPackage = await import(/* webpackChunkName: "zxcvbnCommonPackage" */ '@zxcvbn-ts/language-common');
  const zxcvbnEnPackage = await import(/* webpackChunkName: "zxcvbnEnPackage" */ '@zxcvbn-ts/language-en');

  return {
    dictionary: {
      ...zxcvbnCommonPackage.dictionary,
      ...zxcvbnEnPackage.dictionary,
    },
    graphs: zxcvbnCommonPackage.adjacencyGraphs,
  };
};

export const initZxcvbnOptions = async (): Promise<void> => {
  const options = await loadOptions();
  zxcvbnOptions.setOptions(options);
};
