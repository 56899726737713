import { HTTP_METHODS } from '@studio/constants/axios';
import { ONBOARDING_API_V1 } from '@studio/constants/env-variables';
import type { Subscriptions } from '@studio/types';

export const root = {
  [HTTP_METHODS.POST]: {
    url: `${ONBOARDING_API_V1}/subscriptions/@customerId/salesforce/create`,
    request: {} as Subscriptions.Post.Trial,
    method: HTTP_METHODS.POST,
  },
};

export const id = {};
