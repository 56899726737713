import { HTTP_METHODS } from '@studio/constants/axios';
import { ONBOARDING_API_V1 } from '@studio/constants/env-variables';
import type { Onboarding } from '@studio/types';

export const root = {
  [HTTP_METHODS.POST]: {
    url: `${ONBOARDING_API_V1}/carts`,
    response: {} as Onboarding.Post.CartToken,
    method: HTTP_METHODS.POST,
  },
};

export const id = {};
