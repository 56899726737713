import { Stack, Text } from '@resi-media/resi-ui';
import { usePrefix } from '@studio/hooks';

const NoResults = () => {
  const { prefixNS } = usePrefix('components:', 'noResults');

  return (
    <Stack dataTestId="no-results" gap="m">
      <Text colorVariant="heading" variant="body1" weightVariant="semiBold">
        {prefixNS('noSearchResultsFound')}
      </Text>
      <Text colorVariant="secondary">{prefixNS('tryAgain')}</Text>
    </Stack>
  );
};

NoResults.displayName = 'NoResults';

export default NoResults;
