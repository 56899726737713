import { createAsyncAction, createAction } from 'typesafe-actions';
import type { ContentDestinations } from '@studio/types';
import type { CachedItems } from './types';
import { ActionTypes } from './types';

export const fetchContentSchedules = createAsyncAction(
  ActionTypes.FETCH_REQUEST,
  ActionTypes.FETCH_REQUEST_SUCCESS,
  ActionTypes.FETCH_REQUEST_ERROR,
  ActionTypes.FETCH_REQUEST_CANCEL
)<ContentDestinations.Get.FetchOptions, Map<string, CachedItems>, Error, undefined>();

export const setFetchRequestTimestamp = createAction(ActionTypes.FETCH_REQUEST_TIMESTAMP)<number>();
export const setFilter = createAction(ActionTypes.FILTER_RESULTS)<string>();
export const resetCachedItems = createAction(ActionTypes.FETCH_REQUEST_CACHE_RESET)();
