import { HTTP_METHODS } from '@studio/constants/axios';
import { CENTRAL_API_V3 } from '@studio/constants/env-variables';
import type { Encoders } from '@studio/types';

export const root = {
  [HTTP_METHODS.GET]: {
    url: `${CENTRAL_API_V3}/customers/@customerId/encoders/status`,
    response: [] as Encoders.Get.Status[],
    query: {} as { after?: string; wide?: boolean },
    method: HTTP_METHODS.GET,
  },
};

export const id = {};
