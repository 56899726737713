import * as React from 'react';
import { LockOutlined, StarOutlined } from '@ant-design/icons';
import { css, useTheme } from '@emotion/react';
import { Inline, ListItem, RightAlt, Stack, Text } from '@resi-media/resi-ui';
import { NavLink } from 'react-router-dom';
import NavContext from '@studio/contexts/nav';
import { usePrefix } from '@studio/hooks';
import { useTypedSelector } from '@studio/store/root-reducer';
import type { SideMenuConfig, SideMenuSection } from '@studio/types';
import { Authorized } from '../Authorized';
import { S } from './styles';

type _Props = {
  config: SideMenuConfig;
  hideOrg?: boolean;
  shouldForceCollapse?: boolean;
};

const SideNavMenu = ({ config, hideOrg, shouldForceCollapse }: _Props) => {
  const { commonT, prefixT } = usePrefix('navigation:');
  const theme = useTheme();
  const [openedSubMenu, setOpenedSubMenu] = React.useState(['webSettings', 'deviceSettings']);
  const { user } = useTypedSelector((state) => state.authentication);
  const { state: navState } = React.useContext(NavContext);

  const handleSectionLabelClick = (section: SideMenuSection) => {
    if (section.subMenu?.length) {
      openedSubMenu.includes(section.label)
        ? setOpenedSubMenu((state) => state.filter((v: string) => v !== section.label))
        : setOpenedSubMenu((state) => (shouldForceCollapse ? [section.label] : [...state, section.label]));
    }
  };

  return (
    <div css={S.container(theme, navState.showMobileNav, navState.showTabletNav)} data-testid="side-nav-menu">
      {!hideOrg && (
        <Inline
          alignItems="center"
          css={css`
            padding: 0 ${theme.spacing.xs};
          `}
          dataTestId="org-name"
        >
          {user && <Text as="h5">{user.customerName}</Text>}
        </Inline>
      )}
      {config.categories.map((section, i) => (
        <Stack key={`section-${i}-${section.sectionCategory}`} mt={theme.spacing.l}>
          <Authorized includesAny requiredPerms={section.requiredPerms ?? []}>
            <Text colorVariant="secondary" variant="label">
              {commonT(section.sectionCategory ?? '')}
            </Text>
          </Authorized>
          {section.sections.map((s, j) => (
            <Authorized
              key={`section-child-${j}-${s.label}`}
              includesAny
              requiredPerms={s.requiredPerms}
              requiredToggles={s.requiredToggles}
            >
              <div data-testid="side-nav-menu-section">
                {/* 
                  TODO: Need to fix this logic/type because currently a route based link
                  and a submenu based link cannot coexist in the same section
                 */}
                {s.routes?.length && !s.isDisabled ? (
                  <NavLink data-testid={s.dataTestId} end={s.end} style={{ textDecoration: 'none' }} to={s.routes[0]}>
                    {({ isActive }) => (
                      <ListItem isSelected={isActive}>
                        <Inline alignItems="center" gap="m">
                          {s.icon}
                          <Text as="span" colorVariant="brand">
                            {prefixT(s.label)}
                          </Text>
                          {s.isGated && (
                            <LockOutlined
                              css={(theme) => S.menuIcon(theme, isActive)}
                              data-testid={`${s.label}__icon--gated`}
                            />
                          )}
                        </Inline>
                        {s.subMenu?.length && !s.isNew && !s.isGated && (
                          <RightAlt
                            data-testid={`${s.label}__icon--link-end`}
                            style={{
                              fontSize: theme.typography.pxToRem(12),
                              transform: openedSubMenu.includes(s.label) ? 'rotate(90deg)' : '',
                              transition: 'transform .1s',
                            }}
                          />
                        )}
                        {s.isNew && <StarOutlined style={{ ...S.menuIcon, color: theme.palette.warning.main }} />}
                      </ListItem>
                    )}
                  </NavLink>
                ) : (
                  <ListItem
                    data-testid={s.dataTestId}
                    disabled={s.isDisabled}
                    {...(!s.isDisabled && {
                      endNode: (
                        <RightAlt
                          data-testid={`${s.label}__icon--end`}
                          style={{
                            fontSize: theme.typography.pxToRem(12),
                            transform: openedSubMenu.includes(s.label) ? 'rotate(90deg)' : '',
                            transition: 'transform .1s',
                          }}
                        />
                      ),
                      onClick: () => handleSectionLabelClick(s),
                    })}
                    startNode={s.icon}
                  >
                    <Text as="span" colorVariant={s.isDisabled ? 'disabled' : 'brand'}>
                      {prefixT(s.label)}
                    </Text>
                  </ListItem>
                )}
                {openedSubMenu.includes(s.label) && s.subMenu?.length && (
                  <div
                    css={css`
                      margin: ${theme.spacing.s} 0;
                    `}
                  >
                    {s.subMenu.map((sm, k) => (
                      <Authorized
                        key={`sub-menu-${sm}-${k}`}
                        includesAny
                        requiredPerms={sm.requiredPerms}
                        requiredToggles={sm.requiredToggles}
                        requiredTogglesIncludesAny={sm.requiredTogglesIncludesAny}
                      >
                        <NavLink data-testid={sm.dataTestId} style={{ textDecoration: 'none' }} to={sm.routes[0]}>
                          {({ isActive }) => (
                            <ListItem isSelected={isActive} nestedLevel={1}>
                              <Inline alignItems="center" gap="m">
                                <Text as="span" colorVariant="brand">
                                  {prefixT(sm.label)}
                                </Text>
                                {sm.isGated && (
                                  <LockOutlined
                                    css={(theme) => S.menuIcon(theme, isActive)}
                                    data-testid={`${sm.label}__icon--gated`}
                                  />
                                )}
                              </Inline>
                            </ListItem>
                          )}
                        </NavLink>
                      </Authorized>
                    ))}
                  </div>
                )}
              </div>
            </Authorized>
          ))}
        </Stack>
      ))}
    </div>
  );
};

SideNavMenu.displayName = 'SideNavMenu';

export default SideNavMenu;
