import { HTTP_METHODS } from '@studio/constants/axios';
import { CENTRAL_API_V3 } from '@studio/constants/env-variables';
import type { UploadPOSTUrls } from '@studio/types';

export const root = {
  [HTTP_METHODS.POST]: {
    method: HTTP_METHODS.POST,
    url: `${CENTRAL_API_V3}/customers/@customerId/socialmedia/fileupload`,
    request: {} as { extension: string },
    response: {} as UploadPOSTUrls,
  },
};
