import { Parser } from '@json2csv/plainjs';
import type { SagaIterator } from 'redux-saga';
import type { AllEffect, ForkEffect } from 'redux-saga/effects';
import { all, call, cancelled, fork, put, select, takeLatest } from 'redux-saga/effects';
import { client } from '@studio/api/api-client/client';
import { API_ROOTS, MAX_FETCH_CALLS_FOR_CSV, MAX_FETCH_LIMIT_FOR_CSV_EXPORT } from '@studio/constants/analytics';
import { TELEMETRY_API_V1 } from '@studio/constants/env-variables';
import { downloadAsCsv } from '@studio/helpers';
import { chartTabs, contentLibraryTabs } from '@studio/pages/Analytics/analytics-config';
import type { Analytics } from '@studio/types';
import type { ApiClientConfig } from '@studio/types/axios';
import { selectAccessToken, selectCustomerId } from '../authentication/selectors';
import { fetch } from './actions';
import { ACTION_TYPES } from './types';

function* handleExport(action: ReturnType<typeof fetch.export.request>): SagaIterator {
  const {
    api,
    key,
    max = MAX_FETCH_LIMIT_FOR_CSV_EXPORT,
    validationKey1,
    validationKey2,
    ...queryParams
  } = action.payload;
  const source = new AbortController();
  const token: string = yield select(selectAccessToken);
  const customerId: string = yield select(selectCustomerId);
  const isContentLibrary = api === API_ROOTS.CONTENT_LIBRARY;
  const navigationKey = isContentLibrary ? contentLibraryTabs.grab(key) : chartTabs.grab(key);

  const url = `${TELEMETRY_API_V1}/customers/${customerId}/${api}/export`;
  let responseArray: Analytics.Get.Export[] = [];
  let currentFetchCount = 0;
  let shouldFetchAgain = true;
  let offsetValue1: number | string | undefined = '';
  let offsetValue2: number | string | undefined = '';
  while (shouldFetchAgain) {
    const config: ApiClientConfig = {
      token,
      signal: source.signal,
      params: {
        max,
        ...(offsetValue1 && {
          offset: [offsetValue1, offsetValue2].join(','),
        }),
        ...queryParams,
      },
    };
    try {
      const response: Analytics.Get.Export[] = yield call(client, url, config);
      currentFetchCount = currentFetchCount + 1;
      responseArray = [...responseArray, ...response];
      shouldFetchAgain =
        currentFetchCount < MAX_FETCH_CALLS_FOR_CSV && response.length >= MAX_FETCH_LIMIT_FOR_CSV_EXPORT;
      if (response.length && validationKey1 && validationKey2 && shouldFetchAgain) {
        offsetValue1 = response[response.length - 1][validationKey1];
        offsetValue2 = response[response.length - 1][validationKey2];
        if (!offsetValue1 && !offsetValue2) {
          shouldFetchAgain = false;
        }
      }
    } catch (error) {
      shouldFetchAgain = false;
      yield put(fetch.export.failure(error));
    }
  }
  try {
    const json2csvParser = new Parser({ fields: navigationKey.exportKeys });
    const csv = json2csvParser.parse(responseArray);
    downloadAsCsv(csv, navigationKey.exportFileName);
    yield put(fetch.export.success(responseArray));
  } catch (error) {
    yield put(fetch.export.failure(error));
  } finally {
    if (yield cancelled()) {
      source.abort('cancelled');
      yield put(fetch.export.cancel());
    }
  }
}

export function* watchExportFetch<T>(): Generator<ForkEffect<never>, void, T> {
  yield takeLatest(ACTION_TYPES.FETCH_EXPORT, handleExport);
}

function* handleFetchLatLong(action: ReturnType<typeof fetch.latLong.request>): SagaIterator {
  const { api = 'webevents', ...queryParams } = action.payload;
  const source = new AbortController();

  const token: string = yield select(selectAccessToken);
  const customerId: string = yield select(selectCustomerId);

  const config: ApiClientConfig = {
    token,
    signal: source.signal,
    params: queryParams,
  };
  const url = `${TELEMETRY_API_V1}/customers/${customerId}/${api}/statistics/viewers/locations`;

  try {
    const response: Analytics.Get.LegacyViewersLocations = yield call(client, url, config);
    const positions: Analytics.Derived.HeatMap[] = [];

    response.viewersLocations.forEach((element) => {
      positions.push({
        city: element.city,
        country: element.country,
        lat: Number(element.latitude),
        lng: Number(element.longitude),
        weight: element.none.viewers || 1,
      });
    });

    yield put(fetch.latLong.success(positions));
  } catch (error) {
    yield put(fetch.latLong.failure(error));
  } finally {
    if (yield cancelled()) {
      source.abort('cancelled');
      yield put(fetch.latLong.cancel());
    }
  }
}

export function* watchLatLongFetch<T>(): Generator<ForkEffect<never>, void, T> {
  yield takeLatest(ACTION_TYPES.FETCH_LATLONG, handleFetchLatLong);
}

function* handleFetchContentLibraryLatLong(
  action: ReturnType<typeof fetch.contentLibraryLatLong.request>
): SagaIterator {
  const { api = 'contentLibrary', ...queryParams } = action.payload;
  const source = new AbortController();

  const token: string = yield select(selectAccessToken);
  const customerId: string = yield select(selectCustomerId);

  const config: ApiClientConfig = {
    token,
    signal: source.signal,
    params: queryParams,
  };
  const url = `${TELEMETRY_API_V1}/customers/${customerId}/${api}/statistics/viewers/locations`;

  try {
    const response: Analytics.Get.LatLong[] = yield call(client, url, config);
    const positions: Analytics.Derived.HeatMap[] = [];

    response.forEach((element) => {
      positions.push({
        city: element.city,
        country: element.country,
        lat: Number(element.latitude),
        lng: Number(element.longitude),
        weight: element.viewers || 1,
      });
    });

    yield put(fetch.contentLibraryLatLong.success(positions));
  } catch (error) {
    yield put(fetch.contentLibraryLatLong.failure(error));
  } finally {
    if (yield cancelled()) {
      source.abort('cancelled');
      yield put(fetch.contentLibraryLatLong.cancel());
    }
  }
}

export function* watchContentLibraryLatLongFetch<T>(): Generator<ForkEffect<never>, void, T> {
  yield takeLatest(ACTION_TYPES.FETCH_CONTENT_LIBRARY_LATLONG, handleFetchContentLibraryLatLong);
}

function* handleFetchSummary(action: ReturnType<typeof fetch.summary.request>): SagaIterator {
  const { api, ...queryParams } = action.payload;
  const source = new AbortController();

  const token: string = yield select(selectAccessToken);
  const customerId: string = yield select(selectCustomerId);

  const config: ApiClientConfig = {
    token,
    signal: source.signal,
    params: queryParams,
  };
  const url = `${TELEMETRY_API_V1}/customers/${customerId}/${api}/statistics/summary`;

  try {
    const response: Analytics.Get.Summary[] = yield call(client, url, config);
    yield put(fetch.summary.success(response));
  } catch (error) {
    yield put(fetch.summary.failure(error));
  } finally {
    if (yield cancelled()) {
      source.abort('cancelled');
      yield put(fetch.summary.cancel());
    }
  }
}

export function* watchSummaryFetch<T>(): Generator<ForkEffect<never>, void, T> {
  yield takeLatest(ACTION_TYPES.FETCH_SUMMARY, handleFetchSummary);
}

export function* analyticsSaga<T>(): Generator<AllEffect<ForkEffect<void>>, void, T> {
  yield all([
    fork(watchContentLibraryLatLongFetch),
    fork(watchExportFetch),
    fork(watchLatLongFetch),
    fork(watchSummaryFetch),
  ]);
}
