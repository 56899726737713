import axios from 'axios';
import { CENTRAL_API_V3 } from '@studio/constants/env-variables';
import type { TokenResponse } from '@studio/store/authentication/types';

const onBehalfOfUrl = `${CENTRAL_API_V3}/auth/customers/:uuid/token`;

export async function proxyAuthenticate(
  customerId: string,
  username: string,
  password: string
): Promise<TokenResponse | undefined> {
  const response = await axios.post(
    onBehalfOfUrl.replace(':uuid', customerId),
    { username, password, grant_type: 'password_cookie_on_behalf_of' },
    { withCredentials: true }
  );
  return response.data;
}
