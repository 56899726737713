import * as React from 'react';
import { sanitizeProps, useBreakpoint } from '@resi-media/resi-ui';
import { LinearGradient } from '../LinearGradient';

export const LineChartLoading = (props: { height?: number; width?: number }) => {
  const mediaQuery = useBreakpoint();

  return (
    <svg
      fill="none"
      viewBox={`0 0 ${!mediaQuery.lg ? 431 : 1243} 431`}
      width={props.width ?? '100%'}
      {...sanitizeProps(props)}
    >
      <LinearGradient id="line-chart-loading" />
      <defs>
        <clipPath id="line-chart-clip-path">
          <rect fill="#E8E8E8" height="1" width="1242" x="1" y="307.5" />
          <path
            clipRule="evenodd"
            d="M1187.24 96.717C1093.35 61.394 988.32 72.2885 903.684 126.129L858.689 154.752C843.899 164.16 828.364 172.341 812.239 179.213L721.069 218.065C661.49 243.454 595.625 250.229 532.126 237.498L422.306 215.481L422.303 215.481L325.952 196.457C261.936 183.818 195.585 191.115 135.846 217.362L9.40215 272.916L8.59766 271.085L135.041 215.53C195.155 189.119 261.923 181.777 326.339 194.495L422.696 213.52L532.519 235.537C595.623 248.189 661.078 241.456 720.285 216.225L811.455 177.373C827.48 170.544 842.918 162.414 857.615 153.065L902.611 124.441C987.777 70.2635 1093.47 59.3007 1187.94 94.8451L1240.35 114.564L1239.65 116.436L1187.24 96.717Z"
            fill="#E8E8E8"
            fillRule="evenodd"
          />
          <rect fill="#E8E8E8" height="322" width="1" x="9" />
          <rect fill="#E8E8E8" height="24" rx="4" width="120" y="407" />
          <rect fill="#E8E8E8" height="24" rx="4" width="120" x="152" y="407" />
        </clipPath>
      </defs>
      <rect
        clipPath="url(#line-chart-clip-path)"
        fill="url(#line-chart-loading)"
        height="100%"
        width="100%"
        x={0}
        y={0}
      />
    </svg>
  );
};

LineChartLoading.displayName = 'LineChartLoading';
