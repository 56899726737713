import type { Theme } from '@emotion/react';
import { css } from '@emotion/react';
import { Draft, useBreakpoint, Link as RUILink, Text, Inline } from '@resi-media/resi-ui';
import { Link } from 'react-router-dom';
import UrlPaths from '@studio/constants/url-paths';
import { usePrefix } from '@studio/hooks';

type _Props = {
  showOssLink?: boolean;
  version: string | undefined;
};

const Footer = (props: _Props) => {
  const mediaQuery = useBreakpoint();
  const utils = Draft.useUtils<Date>();
  const { commonT } = usePrefix('common:');

  return (
    <Text
      colorVariant="primary"
      css={(theme: Theme) => css`
        ${theme.mixins.insetSquish('m')(theme)}
      `}
      dataTestId="footer"
      variant={mediaQuery.lg ? 'body3' : 'body4'}
      whiteSpace="nowrap"
    >
      <Inline gap="unset" justifyContent={mediaQuery.lg ? 'space-around' : 'center'}>
        {mediaQuery.lg && (
          <span
            css={css`
              flex: 1 1 10%;
            `}
          />
        )}
        <Inline
          css={css`
            flex: 1 1 80%;
          `}
          flexWrap="wrap"
          gap="unset"
          justifyContent="center"
        >
          <span>© {utils.format(new Date(), 'year')}</span>
          &#160;
          <RUILink
            colorVariant="negative"
            href="https://resi.io"
            target="_blank"
            variant={mediaQuery.lg ? 'body3' : 'body4'}
          >
            Resi Media LLC
          </RUILink>
          &#46;&#160;
          <span>{commonT('copyright.pushpayCompany')}</span>
          &#46;&#160;
          <span>{commonT('copyright.allRightsReserved')}</span>
          {props.showOssLink && (
            <RUILink
              as={Link}
              data-testid="oss-link"
              isLink
              to={UrlPaths.OPEN_SOURCE_INFO}
              variant={mediaQuery.lg ? 'body3' : 'body4'}
            >
              {commonT('copyright.openSourceLicenseInfo')}
            </RUILink>
          )}
          {!mediaQuery.lg && (
            <span
              css={(theme: Theme) => css`
                margin-left: ${theme.spacing.m};
              `}
            >
              {props.version ?? ''}
            </span>
          )}
        </Inline>
        {mediaQuery.lg && (
          <Inline
            css={css`
              flex: 1 1 10%;
            `}
            justifyContent="flex-end"
          >
            {props.version ?? ''}
          </Inline>
        )}
      </Inline>
    </Text>
  );
};

Footer.displayName = 'Footer';

export default Footer;
