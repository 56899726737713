import { useTheme } from '@emotion/react';
import { Stack, useBreakpoint } from '@resi-media/resi-ui';

const ResponsiveStack = ({ children, ...rest }: Stack.Props) => {
  const mediaQuery = useBreakpoint();
  const theme = useTheme();

  return (
    <Stack flex={`1 1 ${mediaQuery.lg ? `calc(50% - ${theme.spacing.l})` : '100%'}`} {...rest}>
      {children}
    </Stack>
  );
};

ResponsiveStack.displayName = 'ResponsiveStack';

export default ResponsiveStack;
