import type { Media } from '@studio/types';

export enum ActionTypes {
  DELETE_ITEM = '@@library/DELETE_ITEM',
  FETCH_MORE_REQUEST = '@@library/FETCH_MORE_REQUEST',
  FETCH_MORE_REQUEST_ERROR = '@@library/FETCH_MORE_REQUEST_ERROR',
  FETCH_MORE_REQUEST_SUCCESS = '@@library/FETCH_MORE_REQUEST_SUCCESS',
  FETCH_REQUEST = '@@library/FETCH_REQUEST',
  FETCH_REQUEST_CANCEL = '@@library/FETCH_REQUEST_CANCEL',
  FETCH_REQUEST_ERROR = '@@library/FETCH_REQUEST_ERROR',
  FETCH_REQUEST_PROCESSING_ONLY = '@@library/FETCH_REQUEST_PROCESSING_ONLY',
  FETCH_REQUEST_PROCESSING_ONLY_ERROR = '@@library/FETCH_REQUEST_PROCESSING_ONLY_ERROR',
  FETCH_REQUEST_PROCESSING_ONLY_SUCCESS = '@@library/FETCH_REQUEST_PROCESSING_ONLY_SUCCESS',
  FETCH_REQUEST_SUCCESS = '@@library/FETCH_REQUEST_SUCCESS',
  FETCH_REQUEST_TIMESTAMP = '@@library/FETCH_REQUEST_TIMESTAMP',
  SORT_RESULTS = '@@library/SORT_RESULTS',
}

export type LibraryState = {
  error: Error | null;
  isFetching: boolean;
  isFetchingInitial: boolean;
  isFetchingMore: boolean;
  listInfo: SavedVideoListInfo;
  sortBy: SortOptions;
};

export type FetchOptions = {
  force?: boolean;
  sort?: SortOptions;
  uuid: string;
};

export enum SortOptions {
  MOST_RECENT = 'desc.start_time',
  NAME_AtoZ = 'asc.title',
  NAME_ZtoA = 'desc.title',
  OLDEST = 'asc.start_time',
}

export type LibraryFetchOptions = {
  items?: string[];
};

export type SavedVideoListInfo = {
  nextUrl?: string;
  videos: Media.Get.LibraryItem[] | null;
};
