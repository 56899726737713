import type { Analytics } from '@studio/types';

export const ACTION_TYPES = {
  FETCH_EVENT_ANALYTICS: '@@analytics/FETCH_EVENT_ANALYTICS',
  FETCH_EVENT_ANALYTICS_SUCCESS: '@@analytics/FETCH_EVENT_ANALYTICS_SUCCESS',
  FETCH_EVENT_ANALYTICS_ERROR: '@@analytics/FETCH_EVENT_ANALYTICS_ERROR',
  FETCH_EXPORT: '@@analytics/FETCH_EXPORT',
  FETCH_EXPORT_SUCCESS: '@@analytics/FETCH_EXPORT_SUCCESS',
  FETCH_EXPORT_ERROR: '@@analytics/FETCH_EXPORT_ERROR',
  FETCH_EXPORT_CANCEL: '@@analytics/FETCH_EXPORT_CANCEL',
  FETCH_CONTENT_LIBRARY_LATLONG: '@@analytics/FETCH_CONTENT_LIBRARY_LATLONG',
  FETCH_CONTENT_LIBRARY_LATLONG_SUCCESS: '@@analytics/FETCH_CONTENT_LIBRARY_LATLONG_SUCCESS',
  FETCH_CONTENT_LIBRARY_LATLONG_ERROR: '@@analytics/FETCH_CONTENT_LIBRARY_LATLONG_ERROR',
  FETCH_CONTENT_LIBRARY_LATLONG_CANCEL: '@@analytics/FETCH_CONTENT_LIBRARY_LATLONG_CANCEL',
  FETCH_LATLONG: '@@analytics/FETCH_LATLONG',
  FETCH_LATLONG_SUCCESS: '@@analytics/FETCH_LATLONG_SUCCESS',
  FETCH_LATLONG_ERROR: '@@analytics/FETCH_LATLONG_ERROR',
  FETCH_LATLONG_CANCEL: '@@analytics/FETCH_LATLONG_CANCEL',
  FETCH_SUMMARY: '@@analytics/FETCH_SUMMARY',
  FETCH_SUMMARY_SUCCESS: '@@analytics/FETCH_SUMMARY_SUCCESS',
  FETCH_SUMMARY_ERROR: '@@analytics/FETCH_SUMMARY_ERROR',
  FETCH_SUMMARY_CANCEL: '@@analytics/FETCH_SUMMARY_CANCEL',
  FETCH_YOUTUBE: '@@analytics/FETCH_YOUTUBE',
  FETCH_YOUTUBE_SUCCESS: '@@analytics/FETCH_YOUTUBE_SUCCESS',
  FETCH_YOUTUBE_ERROR: '@@analytics/FETCH_YOUTUBE_ERROR',
  FETCH_YOUTUBE_CANCEL: '@@analytics/FETCH_YOUTUBE_CANCEL',
  CLEAR_DATA: '@@analytics/CLEAR_DATA',
  SET_IS_CSV_ENABLED: '@@analytics/SET_IS_CSV_ENABLED',
} as const;

export type ActionTypes = (typeof ACTION_TYPES)[keyof typeof ACTION_TYPES];

export type AnalyticsState = {
  eventAnalytics: EventAnalyticsState;
  export: ExportState;
  latLong: LatLongState;
  summary: SummaryState;
};

export type EventAnalyticsState = {
  data: Analytics.Derived.Generic[];
  error: Error | null;
  isFetching: boolean;
};

export type ExportState = {
  data: Analytics.Get.Export[];
  error: Error | null;
  isEnabled: boolean;
  isFetching: boolean;
};

export type LatLongState = {
  data: Analytics.Derived.HeatMap[];
  error: Error | null;
  isFetching: boolean;
};

export type SummaryState = {
  data: Analytics.Get.Summary[];
  error: Error | null;
  isFetching: boolean;
};
