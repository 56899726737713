import type { Hardware } from '@studio/types';

export enum ActionTypes {
  FETCH_CYCLES_REQUEST = '@@hardware/FETCH_CYCLES_REQUEST',
  FETCH_CYCLES_REQUEST_CANCEL = '@@hardware/FETCH_CYCLES_REQUEST_CANCEL',
  FETCH_CYCLES_REQUEST_ERROR = '@@hardware/FETCH_CYCLES_REQUEST_ERROR',
  FETCH_CYCLES_REQUEST_SUCCESS = '@@hardware/FETCH_CYCLES_REQUEST_SUCCESS',
  FETCH_CYCLES_REQUEST_TIMESTAMP = '@@hardware/FETCH_CYCLES_REQUEST_TIMESTAMP',
  FETCH_LOCATIONS_REQUEST = '@@hardware/FETCH_LOCATIONS_REQUEST',
  FETCH_LOCATIONS_REQUEST_CANCEL = '@@hardware/FETCH_LOCATIONS_REQUEST_CANCEL',
  FETCH_LOCATIONS_REQUEST_ERROR = '@@hardware/FETCH_LOCATIONS_REQUEST_ERROR',
  FETCH_LOCATIONS_REQUEST_SUCCESS = '@@hardware/FETCH_LOCATIONS_REQUEST_SUCCESS',
  FETCH_LOCATIONS_REQUEST_TIMESTAMP = '@@hardware/FETCH_LOCATIONS_REQUEST_TIMESTAMP',
  FETCH_MODELS_REQUEST = '@@hardware/FETCH_MODELS_REQUEST',
  FETCH_MODELS_REQUEST_CANCEL = '@@hardware/FETCH_MODELS_REQUEST_CANCEL',
  FETCH_MODELS_REQUEST_ERROR = '@@hardware/FETCH_MODELS_REQUEST_ERROR',
  FETCH_MODELS_REQUEST_SUCCESS = '@@hardware/FETCH_MODELS_REQUEST_SUCCESS',
  FETCH_MODELS_REQUEST_TIMESTAMP = '@@hardware/FETCH_MODELS_REQUEST_TIMESTAMP',
  FETCH_STATUS_REQUEST = '@@hardware/FETCH_STATUS_REQUEST',
  FETCH_STATUS_REQUEST_CANCEL = '@@hardware/FETCH_STATUS_REQUEST_CANCEL',
  FETCH_STATUS_REQUEST_ERROR = '@@hardware/FETCH_STATUS_REQUEST_ERROR',
  FETCH_STATUS_REQUEST_SUCCESS = '@@hardware/FETCH_STATUS_REQUEST_SUCCESS',
  FETCH_STATUS_REQUEST_TIMESTAMP = '@@hardware/FETCH_STATUS_REQUEST_TIMESTAMP',
}

export type State = {
  cycles: CyclesState;
  locations: LocationsState;
  models: ModelsState;
  status: StatusState;
};

export type LocationsState = {
  data: Hardware.Get.HardwareLocation[];
  error: Error | null;
  isFetching: boolean;
  timestamp: number;
};

export type ModelsState = {
  data: Hardware.Get.HardwareModel[];
  error: Error | null;
  isFetching: boolean;
  timestamp: number;
};

export type CyclesState = {
  data: Hardware.Get.HardwareCycles[];
  error: Error | null;
  isFetching: boolean;
  timestamp: number;
};

export type StatusState = {
  data: Hardware.Get.HardwareStatus[];
  error: Error | null;
  isFetching: boolean;
  timestamp: number;
};
