export namespace Get {
  export type FetchOptions = {
    encoderId: string;
    end: string;
    minLevel: LogLevel;
    start: string;
  };

  export type LogEntry = {
    customerId: string;
    encoderID: string;
    entry: string;
    level: LogLevel;
    time: string;
    type: string;
  };

  export const LOG_LEVEL = {
    DEBUG: 'DEBUG',
    INFO: 'INFO',
    WARN: 'WARN',
    ERROR: 'ERROR',
  } as const;
  export type LogLevel = (typeof LOG_LEVEL)[keyof typeof LOG_LEVEL];
}
