import { DESTINATION } from '@studio/constants/content-destination';
import type { ContentDestinations } from '@studio/types';
import { sortByAlphaIgnoreCase } from '../sortByAlphaIgnoreCase';
export function sortDestinations<T extends ContentDestinations.Derived.Destination>(destinations?: T[]): T[] {
  if (!destinations || destinations.length <= 0) {
    return [];
  }
  const webSchedules = destinations
    .filter((s) => s.type === DESTINATION.EMBED)
    .sort(sortByAlphaIgnoreCase((item: T) => item.title));
  const fbSchedules = destinations
    .filter((s) => s.type === DESTINATION.FACEBOOK)
    .sort(sortByAlphaIgnoreCase((item: T) => item.title));
  const ytSchedules = destinations
    .filter((s) => s.type === DESTINATION.YOUTUBE)
    .sort(sortByAlphaIgnoreCase((item: T) => item.title));
  const rtmpSchedules = destinations
    .filter((s) => s.type === DESTINATION.RTMP)
    .sort(sortByAlphaIgnoreCase((item: T) => item.title));
  return [...webSchedules, ...fbSchedules, ...ytSchedules, ...rtmpSchedules];
}
