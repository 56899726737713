import { HTTP_METHODS } from '@studio/constants/axios';
import { TELEMETRY_API_V1 } from '@studio/constants/env-variables';
import type { Analytics } from '@studio/types';

export const root = {
  [HTTP_METHODS.GET]: {
    method: HTTP_METHODS.GET,
    response: [] as Analytics.Get.ContentLibraryAllVideos[],
    query: {} as {
      destinationType?: Analytics.Derived.WebDestinationTypes;
      endDate?: string;
      savedVideoId?: string;
      startDate?: string;
    },
    url: `${TELEMETRY_API_V1}/customers/@customerId/contentLibrary/statistics/views/allVideos`,
  },
};
