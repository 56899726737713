import type { AxiosError } from 'axios';
import { logoutAsync, setTokenExpired } from '@studio/store/authentication/actions';
import { axiosInstance } from '../axios-instance';
import { refreshAuthToken } from '../refresh-auth-token';

let isAlreadyFetchingAccessToken = false;
const pendingRetryApiQueue = new Map<string, (access_token: string) => void>();

function logoutOnRetryFailure(store: Store, abortController: AbortController) {
  return new Promise((res) => {
    // TODO: Good place to add sentry logging - bfbiggs
    store.dispatch(
      logoutAsync.request({
        onFinally: () => {
          store.dispatch(setTokenExpired());
          abortController.abort();
          pendingRetryApiQueue.clear();
          return res(null);
        },
      })
    );
  });
}

async function resetTokenReattempt(error: AxiosError, store: Store): Promise<unknown> {
  const pause = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));
  const abortController = new AbortController();

  try {
    const { response: errorResponse } = error;
    const originalRequest = error.config;

    // @ts-expect-error adding a property to the request
    if (originalRequest._retry) {
      return await logoutOnRetryFailure(store, abortController);
    }

    const retryOriginalRequest = new Promise((resolve) => {
      if (errorResponse) {
        addPendingApiQueue(errorResponse.config.url ?? '', (access_token) => {
          errorResponse.config = {
            ...errorResponse.config,
            signal: abortController.signal,
            headers: {
              ...errorResponse.config.headers,
              Authorization: 'X-Bearer ' + access_token,
            },
            // @ts-expect-error adding a property to the request
            _retry: true,
          };
          resolve(axiosInstance(errorResponse.config));
        });
      }
    });
    if (!isAlreadyFetchingAccessToken) {
      isAlreadyFetchingAccessToken = true;
      try {
        const newAuthToken = await refreshAuthToken(store);
        // Wait 2 seconds before retrying the call again
        await pause(2_000);
        onAccessTokenFetched(newAuthToken);
      } catch (fetchTokenError) {
        return Promise.reject(fetchTokenError);
      } finally {
        isAlreadyFetchingAccessToken = false;
      }
    }
    return retryOriginalRequest;
  } catch (err) {
    return Promise.reject(err);
  }
}

function onAccessTokenFetched(access_token: string) {
  Array.from(pendingRetryApiQueue.values()).forEach((callback) => {
    return callback(access_token);
  });

  pendingRetryApiQueue.clear();
}

function addPendingApiQueue(url: string, callback: (access_token: string) => void) {
  if (!url) return;
  if (!pendingRetryApiQueue.has(url)) {
    pendingRetryApiQueue.set(url, callback);
  }
}

export { resetTokenReattempt };
