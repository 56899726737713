import { HTTP_METHODS } from '@studio/constants/axios';
import { CENTRAL_API_V3 } from '@studio/constants/env-variables';
import type { Events } from '@studio/types';

export const root = {
  [HTTP_METHODS.POST]: {
    url: `${CENTRAL_API_V3}/customers/@customerId/contentdestinations/@destinationId/archive`,
    request: {} as Events.Post.AddMediaArchive,
    response: {} as Events.Post.Response.MediaArchive,
    params: {} as { destinationId: string },
    method: HTTP_METHODS.POST,
  },
};
