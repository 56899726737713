import { createAction, createAsyncAction } from 'typesafe-actions';
import type { Media } from '@studio/types';
import type { FetchOptions, LibraryFetchOptions, SavedVideoListInfo, SortOptions } from './types';
import { ActionTypes } from './types';

export const fetchLibraryItemsAsync = createAsyncAction(
  ActionTypes.FETCH_REQUEST,
  ActionTypes.FETCH_REQUEST_SUCCESS,
  ActionTypes.FETCH_REQUEST_ERROR,
  ActionTypes.FETCH_REQUEST_CANCEL
)<FetchOptions | undefined, SavedVideoListInfo, Error, undefined>();

export const fetchMoreLibraryItemsAsync = createAsyncAction(
  ActionTypes.FETCH_MORE_REQUEST,
  ActionTypes.FETCH_MORE_REQUEST_SUCCESS,
  ActionTypes.FETCH_MORE_REQUEST_ERROR
)<FetchOptions, SavedVideoListInfo, Error>();

export const fetchLibraryProcessingItemsAsync = createAsyncAction(
  ActionTypes.FETCH_REQUEST_PROCESSING_ONLY,
  ActionTypes.FETCH_REQUEST_PROCESSING_ONLY_SUCCESS,
  ActionTypes.FETCH_REQUEST_PROCESSING_ONLY_ERROR
)<LibraryFetchOptions, Media.Get.LibraryItem[], Error>();

export const deleteSavedVideo = createAction(ActionTypes.DELETE_ITEM)<string>();

export const setSortOrder = createAction(ActionTypes.SORT_RESULTS)<SortOptions>();
