import { produce } from 'immer';
import type { ActionType, PayloadAction } from 'typesafe-actions';
import { createReducer } from 'typesafe-actions';
import {
  deletePlaylist,
  fetchMorePlaylistsAsync,
  fetchPlaylistsAsync,
  setFetchRequestTimestamp,
  setSortOrder,
} from './actions';
import type { ActionTypes, PlaylistListInfo, PlaylistsState } from './types';
import { SortOptions } from './types';

type Action = ActionType<
  | typeof deletePlaylist
  | typeof fetchMorePlaylistsAsync
  | typeof fetchPlaylistsAsync
  | typeof setFetchRequestTimestamp
  | typeof setSortOrder
>;

export const initialState: PlaylistsState = {
  isFetching: false,
  listInfo: {
    playlists: [],
  },
  timestamp: 0,
  error: null,
  sortBy: SortOptions.MOST_RECENT,
};

const reducer = createReducer<PlaylistsState, Action>(initialState)
  .handleAction(
    fetchPlaylistsAsync.request,
    produce((draft: PlaylistsState) => {
      draft.isFetching = true;
      draft.error = null;
    })
  )
  .handleAction(
    fetchPlaylistsAsync.success,
    produce((draft: PlaylistsState, action: PayloadAction<ActionTypes.FETCH_REQUEST_SUCCESS, PlaylistListInfo>) => {
      draft.isFetching = false;
      draft.listInfo = action.payload;
    })
  )
  .handleAction(
    fetchPlaylistsAsync.failure,
    produce((draft: PlaylistsState, action: PayloadAction<ActionTypes.FETCH_REQUEST_ERROR, Error>) => {
      draft.isFetching = false;
      draft.error = action.payload;
    })
  )
  .handleAction(
    fetchPlaylistsAsync.cancel,
    produce((draft: PlaylistsState) => {
      draft.isFetching = false;
    })
  )
  .handleAction(
    fetchMorePlaylistsAsync.request,
    produce((draft: PlaylistsState) => {
      draft.error = null;
    })
  )
  .handleAction(
    fetchMorePlaylistsAsync.success,
    produce(
      (draft: PlaylistsState, action: PayloadAction<ActionTypes.FETCH_MORE_REQUEST_SUCCESS, PlaylistListInfo>) => {
        draft.listInfo.nextUrl = action.payload.nextUrl;
        draft.listInfo.playlists = draft.listInfo.playlists.concat(action.payload.playlists);
      }
    )
  )
  .handleAction(
    fetchMorePlaylistsAsync.failure,
    produce((draft: PlaylistsState, action: PayloadAction<ActionTypes.FETCH_MORE_REQUEST_ERROR, Error>) => {
      draft.error = action.payload;
    })
  )
  .handleAction(
    setFetchRequestTimestamp,
    produce((draft: PlaylistsState, action: PayloadAction<ActionTypes.FETCH_REQUEST_TIMESTAMP, number>) => {
      draft.timestamp = action.payload;
    })
  )
  .handleAction(
    deletePlaylist,
    produce((draft: PlaylistsState, action: PayloadAction<ActionTypes.DELETE_ITEM, string>) => {
      if (action.payload && draft.listInfo.playlists.length > 0) {
        const index = draft.listInfo.playlists.findIndex((playlist) => playlist.id === action.payload);
        if (index !== -1) {
          const updatedItems = [...draft.listInfo.playlists];
          updatedItems.splice(index, 1);
          draft.listInfo.playlists = updatedItems;
        }
      }
    })
  )
  .handleAction(
    setSortOrder,
    produce((draft: PlaylistsState, action: PayloadAction<ActionTypes.SORT_RESULTS, string>) => {
      draft.sortBy = action.payload;
    })
  );

export default reducer;
