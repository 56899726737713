export namespace Derived {
  export const MEDIA_STATUS = {
    PENDING: 'PENDING',
    STARTING: 'STARTING',
    IN_PROGRESS: 'IN_PROGRESS',
    SEGMENTING: 'SEGMENTING',
    TRANSCODING: 'TRANSCODING',
    RE_SEGMENTING: 'RE_SEGMENTING',
    RETRYING: 'RETRYING',
    FAILED: 'FAILED',
    COMPLETE: 'COMPLETE',
    CANCELLED: 'CANCELLED',
    TIMED_OUT: 'TIMED_OUT',
  } as const;

  export type MediaStatus = (typeof MEDIA_STATUS)[keyof typeof MEDIA_STATUS];

  export type TrimMediaForm = {
    endTime: string;
    isDeletingUnusedContent: boolean;
    startTime: string;
  };
}
