import { createAction, createAsyncAction } from 'typesafe-actions';
import type { EncoderProfiles } from '@studio/types';
import { ActionTypes } from './types';

export const fetch = {
  options: createAsyncAction(
    ActionTypes.FETCH_ENCODER_OPTIONS,
    ActionTypes.FETCH_ENCODER_OPTIONS_SUCCESS,
    ActionTypes.FETCH_ENCODER_OPTIONS_ERROR,
    ActionTypes.FETCH_ENCODER_OPTIONS_CANCEL
  )<undefined, EncoderProfiles.Get.Options, Error, undefined>(),

  presets: createAsyncAction(
    ActionTypes.FETCH_REQUEST,
    ActionTypes.FETCH_REQUEST_SUCCESS,
    ActionTypes.FETCH_REQUEST_ERROR,
    ActionTypes.FETCH_REQUEST_CANCEL
  )<undefined, EncoderProfiles.Get.Preset[], Error, undefined>(),
};

export const set = {
  options: {
    timestamp: createAction(ActionTypes.FETCH_ENCODER_OPTIONS_TIMESTAMP)<number>(),
  },
  presets: {
    timestamp: createAction(ActionTypes.FETCH_REQUEST_TIMESTAMP)<number>(),
    preset: createAction(ActionTypes.SET_PRESET)<EncoderProfiles.Get.Preset | null>(),
    filter: createAction(ActionTypes.FILTER_RESULTS)<string>(),
  },
};
