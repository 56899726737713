import * as React from 'react';

function useThrottle() {
  const throttled = React.useRef(false);

  return React.useCallback((callback: () => void, timeout: number) => {
    if (throttled.current) {
      return;
    }
    throttled.current = true;
    callback();

    window.setTimeout(() => {
      throttled.current = false;
    }, timeout);
  }, []);
}

export default useThrottle;
