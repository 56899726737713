import { parse as parseDuration, normalize as normalizeDuration } from 'duration-fns';

const formatSecondsToTimeString = (currentTime: number | string, isMilliseconds = true): string => {
  if (typeof currentTime === 'string') {
    return currentTime;
  }
  const time = normalizeDuration(parseDuration(Math.floor(currentTime * 1000)));
  const hours = String(time.days * 24 + time.hours).padStart(2, '0');
  const minutes = String(time.minutes).padStart(2, '0');
  const seconds = String(time.seconds).padStart(2, '0');
  const milliseconds = String(time.milliseconds).padStart(3, '0');

  if (isMilliseconds) {
    return `${hours}:${minutes}:${seconds}.${milliseconds}`;
  }
  return `${hours}:${minutes}:${seconds}`;
};

export default formatSecondsToTimeString;
