import { createAction, createAsyncAction } from 'typesafe-actions';
import type { EncoderChannels } from '@studio/types';
import { ActionTypes } from './types';

export const fetchEncoderChannelsAsync = createAsyncAction(
  ActionTypes.FETCH_REQUEST,
  ActionTypes.FETCH_REQUEST_SUCCESS,
  ActionTypes.FETCH_REQUEST_ERROR,
  ActionTypes.FETCH_REQUEST_CANCEL
)<undefined, EncoderChannels.Derived.EncoderChannel[], Error, undefined>();

export const forceFetchEncoderChannelsAsync = createAsyncAction(
  ActionTypes.FORCE_FETCH_REQUEST,
  ActionTypes.FORCE_FETCH_REQUEST_SUCCESS,
  ActionTypes.FORCE_FETCH_REQUEST_ERROR
)<undefined, EncoderChannels.Derived.EncoderChannel[], Error, undefined>();

export const fetchEncoderChannelUserAsync = createAsyncAction(
  ActionTypes.FETCH_USERS_REQUEST,
  ActionTypes.FETCH_USERS_SUCCESS,
  ActionTypes.FETCH_USERS_ERROR
)<string, EncoderChannels.Derived.FetchUsersResponse, Error>();

export const setFetchRequestTimestamp = createAction(ActionTypes.FETCH_REQUEST_TIMESTAMP)<number>();

export const setFilter = createAction(ActionTypes.FILTER_RESULTS)<string>();
