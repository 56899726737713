import { HTTP_METHODS } from '@studio/constants/axios';
import { CENTRAL_API_V3 } from '@studio/constants/env-variables';
import type { Events } from '@studio/types';

export const root = {
  [HTTP_METHODS.GET]: {
    url: `${CENTRAL_API_V3}/customers/@customerId/events`,
    response: [] as Events.Get.EncoderEvent[],
    method: HTTP_METHODS.GET,
  },
};

export const id = {};
