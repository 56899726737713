import { validate as uuidValidate } from 'uuid';
import { CENTRAL_API_V2 } from '@studio/constants/env-variables';
import { isValidEcgString } from '../is-valid-ecg-string';

export const getOrgSearchUrl = (searchString: string): string => {
  if (uuidValidate(searchString)) {
    return `${CENTRAL_API_V2}/customers?uuid=${searchString}`;
  }
  if (isValidEcgString(searchString)) {
    return `${CENTRAL_API_V2}/customers/masterecg/${searchString.toUpperCase()}`;
  }
  return `${CENTRAL_API_V2}/customers?name=${searchString}`;
};
