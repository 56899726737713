import { CloudOutlined, ApartmentOutlined, DisconnectOutlined } from '@ant-design/icons';
import { css } from '@emotion/react';
import type { Decoders } from '@studio/types';

type _AntIconProps = Parameters<typeof CloudOutlined>[0];

type _Props = _AntIconProps & {
  active: boolean;
  lan?: boolean | null;
  status: Decoders.Derived.Status['status'];
};

const DecoderIcon = ({ active, lan, status, ...rest }: _Props) => {
  if (!status || !active || status === 'offline') {
    return (
      <DisconnectOutlined
        css={(theme) => css`
          color: ${theme.palette.text.secondary};
        `}
        data-testid={!status ? 'decoder-offline-icon' : 'decoder-inactive-icon'}
        {...rest}
      />
    );
  }

  if (lan) {
    return (
      <ApartmentOutlined
        css={(theme) => css`
          color: ${theme.palette.text.primary};
        `}
        data-testid="decoder-lan-icon"
        {...rest}
      />
    );
  }

  return (
    <CloudOutlined
      css={(theme) => css`
        color: ${theme.palette.text.primary};
      `}
      data-testid="decoder-cloud-icon"
      {...rest}
    />
  );
};

DecoderIcon.displayName = 'DecoderIcon';

export default DecoderIcon;
