import * as React from 'react';
import { Text } from '@resi-media/resi-ui';
import { differenceInCalendarDays } from 'date-fns';
import { usePrefix } from '@studio/hooks';

type _Props = {
  expirationDate?: string | null;
};

const today = new Date();

const ExpirationText = ({ expirationDate = '' }: _Props) => {
  const { commonT, prefixNS } = usePrefix('components:', 'expirationText');

  const getExpirationText = () => {
    const removeTime = new Date(expirationDate ?? '');
    const daysTillExpiration = differenceInCalendarDays(removeTime, today);
    if (isNaN(daysTillExpiration)) {
      return commonT('invalidDate');
    }
    if (daysTillExpiration === 0) {
      return prefixNS('expiresLessThan24Hours');
    }
    return prefixNS('expiresText', { count: daysTillExpiration });
  };

  return (
    <Text colorVariant="negative" data-testid="expiration-date" isUppercase variant="body3" weightVariant="semiBold">
      {getExpirationText()}
    </Text>
  );
};

ExpirationText.displayName = 'ExpirationText';

export default ExpirationText;
