import { HTTP_METHODS } from '@studio/constants/axios';
import { DATA_USAGE_API_V1 } from '@studio/constants/env-variables';
import type { Analytics } from '@studio/types';

export const root = {
  [HTTP_METHODS.GET]: {
    method: HTTP_METHODS.GET,
    params: {} as {
      timeWindow: Analytics.Derived.TimeWindowTypes;
    },
    query: {} as {
      endDate?: string;
      startDate: string;
      wide?: boolean;
    },
    response: {} as Analytics.Get.DataUsage,
    url: `${DATA_USAGE_API_V1}/customers/@customerId/statistics/@timeWindow`,
  },
};
