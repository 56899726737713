import type { RangeBase, RangeBaseItems } from '@studio/helpers/saga-cache';
import type { Schedules } from '@studio/types';

export enum ActionTypes {
  FETCH_REQUEST = '@@contentschedule/FETCH_REQUEST',
  FETCH_REQUEST_CACHE_RESET = '@@contentschedule/FETCH_REQUEST_CACHE_RESET',
  FETCH_REQUEST_CANCEL = '@@contentschedule/FETCH_REQUEST_CANCEL',
  FETCH_REQUEST_ERROR = '@@contentschedule/FETCH_REQUEST_ERROR',
  FETCH_REQUEST_SUCCESS = '@@contentschedule/FETCH_REQUEST_SUCCESS',
  FETCH_REQUEST_TIMESTAMP = '@@contentschedule/FETCH_REQUEST_TIMESTAMP',
  FILTER_RESULTS = '@@contentschedule/FILTER_RESULTS',
}

export interface ContentScheduleInitialState {
  cachedItems: Map<string, CachedItems>;
  error: Error | null;
  filter: string;
  hasFetched: boolean;
  isFetching: boolean;
  timestamp: number;
}

export type ContentScheduleWithDate = RangeBase & Schedules.Get.ContentSchedule;

export type CachedItems = RangeBaseItems<ContentScheduleWithDate>;
