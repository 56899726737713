import * as React from 'react';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { LD_ANONYMOUS_CONTEXT } from '@studio/constants/api-constants';
import { TrackEventName } from '@studio/constants/tracking-constants';
import UrlPaths from '@studio/constants/url-paths';
import { logoutAsync, tracking } from '@studio/store';

type _Props = {
  redirect?: boolean;
};

const Logout = ({ redirect }: _Props) => {
  const dispatch = useDispatch();
  const ldClient = useLDClient();
  const navigate = useNavigate();

  React.useEffect(() => {
    dispatch({
      type: tracking.ActionTypes.TRACK_SEGMENT_REQUEST,
      payload: {
        eventName: TrackEventName.USER_LOGOUT,
      },
    });
    window.analytics.reset();
    ldClient?.identify(LD_ANONYMOUS_CONTEXT)?.catch((error) => {
      console.error('error initializing Launch Darkly:', error.message);
    });
    dispatch(logoutAsync.request({ onSuccess: () => redirect && navigate(UrlPaths.ROOT) }));
  }, [dispatch, ldClient, navigate, redirect]);

  return null;
};

Logout.displayName = 'Logout';

export default Logout;
