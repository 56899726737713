export const ROLE_TYPES = {
  RESI_ADMIN: 'e5566d12-e6dc-4ea7-b994-b83cc01d9ab5',
  CUSTOMER_ADMIN: '63566d12-e6dc-2ea7-b994-a83cc01d9ab3',
  EVENT_ADMIN: '75984d8c-79de-46b0-a8aa-762446f8fc89',
  CONTROL_ENCODER: 'a5366d12-e6dc-2ea7-a994-b83cc01d9ab6',
  MEDIA_LIB_ADMIN: 'aa13006f-a8a9-49a4-a99a-7eeccb7b1c49',
  CAMPUS_REVIEW: 'f9996d12-e7dc-54a7-b995-b83cc01d9dde',
} as const;

export const availableRoles = [
  ROLE_TYPES.CONTROL_ENCODER,
  ROLE_TYPES.CUSTOMER_ADMIN,
  ROLE_TYPES.EVENT_ADMIN,
  ROLE_TYPES.MEDIA_LIB_ADMIN,
  ROLE_TYPES.RESI_ADMIN,
  ROLE_TYPES.CAMPUS_REVIEW,
];

export const PERMISSIONS_GROUPS = {
  viewDashboard: [
    ROLE_TYPES.RESI_ADMIN,
    ROLE_TYPES.CUSTOMER_ADMIN,
    ROLE_TYPES.EVENT_ADMIN,
    ROLE_TYPES.MEDIA_LIB_ADMIN,
    ROLE_TYPES.CONTROL_ENCODER,
  ],
  manageEncoderAndWebSettings: [
    ROLE_TYPES.RESI_ADMIN,
    ROLE_TYPES.CUSTOMER_ADMIN,
    ROLE_TYPES.EVENT_ADMIN,
    ROLE_TYPES.CONTROL_ENCODER,
  ],
  manageSched: [ROLE_TYPES.RESI_ADMIN, ROLE_TYPES.CUSTOMER_ADMIN, ROLE_TYPES.EVENT_ADMIN, ROLE_TYPES.CONTROL_ENCODER],
  manageUsers: [ROLE_TYPES.RESI_ADMIN, ROLE_TYPES.CUSTOMER_ADMIN],
  manageVenues: [ROLE_TYPES.RESI_ADMIN, ROLE_TYPES.CUSTOMER_ADMIN],
  manageSocialMedia: [ROLE_TYPES.RESI_ADMIN, ROLE_TYPES.CUSTOMER_ADMIN, ROLE_TYPES.EVENT_ADMIN],
  manageDestinationGroups: [
    ROLE_TYPES.RESI_ADMIN,
    ROLE_TYPES.CUSTOMER_ADMIN,
    ROLE_TYPES.EVENT_ADMIN,
    ROLE_TYPES.CONTROL_ENCODER,
  ],
  manageCues: [
    ROLE_TYPES.RESI_ADMIN,
    ROLE_TYPES.CUSTOMER_ADMIN,
    ROLE_TYPES.EVENT_ADMIN,
    ROLE_TYPES.MEDIA_LIB_ADMIN,
    ROLE_TYPES.CONTROL_ENCODER,
  ],
  bandwidthAnalytics: [ROLE_TYPES.RESI_ADMIN, ROLE_TYPES.CUSTOMER_ADMIN],
  videoReview: availableRoles,
  accountDetails: [
    ROLE_TYPES.RESI_ADMIN,
    ROLE_TYPES.CUSTOMER_ADMIN,
    ROLE_TYPES.EVENT_ADMIN,
    ROLE_TYPES.MEDIA_LIB_ADMIN,
    ROLE_TYPES.CONTROL_ENCODER,
  ],
};
