import { HTTP_METHODS } from '@studio/constants/axios';
import { CENTRAL_API_V3 } from '@studio/constants/env-variables';
import type { EncoderProfiles } from '@studio/types';

export const root = {
  [HTTP_METHODS.POST]: {
    url: `${CENTRAL_API_V3}/customers/@customerId/encoderprofiles`,
    request: {} as EncoderProfiles.Post.Preset,
    method: HTTP_METHODS.POST,
  },
};

export const id = {
  [HTTP_METHODS.DELETE]: {
    url: `${CENTRAL_API_V3}/customers/@customerId/encoderprofiles/@id`,
    params: {} as { id: string },
    method: HTTP_METHODS.DELETE,
  },
  [HTTP_METHODS.GET]: {
    url: `${CENTRAL_API_V3}/customers/@customerId/encoderprofiles/@id`,
    response: {} as EncoderProfiles.Get.Preset,
    params: {} as { id: string },
    method: HTTP_METHODS.GET,
  },
  [HTTP_METHODS.PATCH]: {
    url: `${CENTRAL_API_V3}/customers/@customerId/encoderprofiles/@id`,
    request: {} as EncoderProfiles.Patch.Preset,
    params: {} as { id: string },
    method: HTTP_METHODS.PATCH,
  },
};
