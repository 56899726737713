import { composeWithDevTools } from '@redux-devtools/extension';
import { applyMiddleware, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import rootReducer, { initialRootState } from './root-reducer';
import { rootSaga } from './root-saga';

const composeEnhancers = composeWithDevTools({});
const sagaMiddleware = createSagaMiddleware();

const store = createStore(rootReducer, initialRootState, composeEnhancers(applyMiddleware(sagaMiddleware)));

sagaMiddleware.run(rootSaga);

export * from './authentication';
export * from './customer';
export * as admin from './admin';
export * as analytics from './analytics';
export * as contentDestinations from './content-destinations';
export * as destinationGroups from './destination-groups';
export * as encoderChannels from './encoder-channels';
export * as encoderLogs from './encoder-logs';
export * as encoders from './encoders';
export * as encoderPresets from './encoder-presets';
export * as encoderVideos from './encoder-videos';
export * as contentSchedule from './content-schedule';
export * as library from './library';
export * as librarySearch from './library-search';
export * as monitors from './monitors';
export * as playlists from './playlists';
export * as playerProfiles from './player-profiles';
export * as regions from './regions';
export * as rtmpDestinations from './rtmp-destinations';
export * as sites from './sites';
export * as socialMedia from './social-media';
export * as systemEvents from './system-events';
export * as timezones from './timezones';
export * as tracking from './tracking';
export * as users from './users';
export * as venues from './venues';
export * as webChannels from './web-channels';
export * as webPresets from './web-presets';
export * from './root-reducer';
export { rootReducer, rootSaga };

export default store;
