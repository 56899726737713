import { HTTP_METHODS } from '@studio/constants/axios';
import { CENTRAL_API_V3 } from '@studio/constants/env-variables';
import type { GoLive } from '@studio/types';

export const root = {
  [HTTP_METHODS.GET]: {
    url: `${CENTRAL_API_V3}/customers/@customerId/golive/@id/status`,
    response: [] as GoLive.Post.Status[],
    params: {} as { id?: string },
    method: HTTP_METHODS.GET,
  },
};
