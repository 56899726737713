import type { CombinedState } from 'redux';
import { createSelector } from 'reselect';
import type { Playlists } from '../../types';
import type { RootState } from '../root-reducer';
import type { PlaylistListInfo, PlaylistsState } from './types';

export const selectPlaylistsState = (state: RootState): CombinedState<PlaylistsState> => state.playlists;

export const selectItems = createSelector(
  selectPlaylistsState,
  (state): Playlists.Get.Playlist[] => state.listInfo.playlists
);

export const selectListInfo = createSelector(selectPlaylistsState, (state): PlaylistListInfo => state.listInfo);

export const selectSortBy = createSelector(selectPlaylistsState, (state): string => state.sortBy);

export const selectTimestamp = createSelector(selectPlaylistsState, (state): number => state.timestamp);
