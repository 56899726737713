import { HTTP_METHODS } from '@studio/constants/axios';
import { CENTRAL_API_V3 } from '@studio/constants/env-variables';
import type { EncoderChannels } from '@studio/types';

export const root = {
  [HTTP_METHODS.POST]: {
    url: `${CENTRAL_API_V3}/customers/@customerId/eventprofiles`,
    request: {} as EncoderChannels.Post.EncoderChannel,
    method: HTTP_METHODS.POST,
  },
};

export const id = {
  [HTTP_METHODS.DELETE]: {
    url: `${CENTRAL_API_V3}/customers/@customerId/eventprofiles/@id`,
    params: {} as { id: string },
    method: HTTP_METHODS.DELETE,
  },
  [HTTP_METHODS.GET]: {
    url: `${CENTRAL_API_V3}/customers/@customerId/eventprofiles/@id`,
    response: {} as EncoderChannels.Get.EncoderChannel,
    params: {} as { id: string },
    method: HTTP_METHODS.GET,
  },
  [HTTP_METHODS.PATCH]: {
    url: `${CENTRAL_API_V3}/customers/@customerId/eventprofiles/@id`,
    request: {} as EncoderChannels.Patch.EncoderChannel,
    params: {} as { id: string },
    method: HTTP_METHODS.PATCH,
  },
};
