import type { SerializedStyles, Theme } from '@emotion/react';
import { css } from '@emotion/react';

const BOOKMARK_RADIUS = 22;

const line = (theme: Theme, isHighlighted: boolean): SerializedStyles => css`
  width: ${isHighlighted ? '2px' : '1px'};
  background-color: ${isHighlighted ? theme.palette.primary.active : theme.palette.text.disabled};
`;

const S = {
  lineTop: (theme: Theme, isHighlighted: boolean): SerializedStyles => css`
    ${line(theme, isHighlighted)}
    height: 16px;
  `,
  iconContainer: (theme: Theme, isHighlighted: boolean): SerializedStyles => css`
    border-radius: ${theme.shape.borderRadius.circle};
    background-color: ${isHighlighted ? theme.palette.primary.active : theme.palette.text.disabled};
    height: ${BOOKMARK_RADIUS}px;
    width: ${BOOKMARK_RADIUS}px;
    display: grid;
    place-items: center;
  `,
  lineBottom: (theme: Theme, isHighlighted: boolean): SerializedStyles => css`
    ${line(theme, isHighlighted)}
    min-height: 42px;
    height: 100%;
  `,
  lineContainer: (): SerializedStyles => css`
    display: grid;
    place-items: center;
  `,
};

export default S;
